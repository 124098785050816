import {
  DONE_GET_ALL_LIFT,
  SET_ALL_WORKPLANS,
  SET_ALL_WORKPLANS_OBJ,
  SET_WORKPLAN_BYID,
  SET_TRIGGER_LOADING,
  GET_ALL_COLLABORATORS,
  DONE_GET_ALL_COLLABORATORS,
  SET_ALL_COLLABORATORS,
  GET_ALL_DEPARTMENTS,
  DONE_GET_ALL_DEPARTMENTS,
  SET_ALL_DEPARTMENTS,
  GET_ALL_PROVIDERS,
  DONE_GET_ALL_PROVIDERS,
  SET_ALL_PROVIDERS,
  GET_ALL_PROVIDERTYPES,
  DONE_GET_ALL_PROVIDERTYPES,
  SET_ALL_PROVIDERTYPES,
  GET_ALL_EXPENDITURETYPES,
  DONE_GET_ALL_EXPENDITURETYPES,
  SET_ALL_EXPENDITURETYPES,
  GET_ALL_EXPENSES,
  DONE_GET_ALL_EXPENSES,
  SET_ALL_EXPENSES,
  GET_ALL_FUNCTIONS,
  SET_ALL_FUNCTIONS,
  DONE_GET_ALL_FUNCTIONS,
  GET_ALL_WORKPLANS,
  DONE_GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES,
  SET_ALL_CATEGORIES,
  GET_ALL_PROBABILITIES,
  SET_ALL_PROBABILITIES,
  GET_ALL_BUSINESSES,
  SET_ALL_BUSINESSES,
  GET_ALL_PROPOSALS,
  SET_ALL_PROPOSALS,
  GET_ALL_PROJECT_TYPES,
  SET_ALL_PROJECT_TYPES,
  DONE_GET_ALL_PROJECT_TYPES,
  GET_ALL_STATES,
  SET_ALL_STATES,
  DONE_GET_ALL_BUSINESSES,
  DONE_GET_ALL_PROBABILITIES,
  DONE_GET_ALL_PROPOSALS,
  DONE_GET_ALL_STATES,
  DONE_GET_PROPOSAL,
  SET_PROPOSAL,
  DONE_GET_DOCUMENT_INFO,
  SET_DOCUMENT_INFO,
  SET_CLIENT_DIRECTORS,
  GET_ALL_CLIENT_DIRECTORS,
  DONE_GET_ALL_CLIENT_DIRECTORS,
  GET_PROPOSAL,
  GET_DOCUMENT_INFO,
  GET_ALL_PARTNERS,
  SET_ALL_PARTNERS,
  DONE_GET_ALL_PARTNERS,
  GET_ALL_PAYMENT_TYPES,
  DONE_GET_ALL_PAYMENT_TYPES,
  SET_ALL_PAYMENT_TYPES,
  GET_ALL_TEAMS,
  SET_ALL_TEAMS,
  DONE_GET_ALL_TEAMS,
  DELETE_WORKPLAN,
  DONE_DELETE_WORKPLAN,
  DELETE_DOCUMENT,
  DONE_DELETE_DOCUMENT,
  GET_ALL_CLIENTS,
  SET_ALL_CLIENTS,
  DONE_GET_ALL_CLIENTS,
  DONE_GET_ALL_FACTORING,
  GET_ALL_FACTORING,
  SET_ALL_FACTORING,
  GET_ALL_INVOICING_MODE,
  DONE_GET_ALL_INVOICING_MODE,
  SET_ALL_INVOICING_MODE,
  GET_ALL_PROPOSAL_TYPES,
  DONE_GET_ALL_PROPOSAL_TYPES,
  SET_ALL_PROPOSAL_TYPES,
  SET_CONTACT,
  SET_PROVIDER,
  GET_ALL_COMPANIES,
  SET_ALL_COMPANIES,
  DONE_GET_ALL_COMPANIES,
  SET_ADD_WORKPLAN_TRIGGER,
  SET_WORKPLAN,
  SET_ERROR_UPDATNG,
  SET_NUM_GANHO,
  GET_DOCUMENT,
  SET_DOCUMENT,
  DONE_GET_DOCUMENT,
  SET_CURRENT_DOCUMENTS,
  SET_CLIENTS,
  SET_PROVIDERS,
  UPDATE_CLIENT_ID,
  DONE_UPDATE_CLIENT_ID,
  UPDATE_PROVIDER_ID,
  DONE_UPDATE_PROVIDER_ID,
  SAVE_CLIENTS,
  DONE_SAVE_CLIENTS,
  SAVE_PROVIDERS,
  DONE_SAVE_PROVIDERS,
  DELETE_CLIENT_DATA,
  DONE_DELETE_CLIENT_DATA,
  DELETE_PROVIDER_DATA,
  DONE_DELETE_PROVIDER_DATA,
  STOP_EDITING_CLIENTS,
  STOP_EDITING_PROVIDERS,
  GET_ALL_BY_CLIENT_DIRECTOR,
  DONE_GET_ALL_BY_CLIENT_DIRECTOR,
  DONE_MAKE_ACTIVE,
  MAKE_WORKPLAN_ACTIVE,
  SET_UPDATE_DONE,
  SET_UPDATE_SUBMIT,
} from "./actionTypes";

const initialState = {
  workplans: [],
  workplan: [],
  workplansObj:[],
  workplanById:{},
  collaborators: [],
  departments: [],
  providers: [],
  expenses: [],
  functions: [],
  providerTypes: [],
  expenditureTypes: [],
  paymentTypes: [],
  categories: [],
  projectTypes: [],
  proposal: [],
  states: [],
  probabilities: [],
  documentInfo: {},
  businesses: [],
  teams: [],
  partners: [],
  proposals: [],
  proposalTypes: [],
  clientDirectors: [],
  clients: [],
  contact: {},
  provider: {},
  factoring: [],
  invoicingModes: [],
  companies: [],
  triggerLoading: false,
  errorUpdating: null,
  numGanho: null,
  currentDocument: null,
  documents: [],
  allClients: [],
  allProviders: [],
  savedClientData: [],
  savedProviderData: [],
  addWorkplanTrigger: false,
  isLoadingGetAllLift: false,
  isLoadingGetAllCollaborators: false,
  isLoadingGetAllDepartments: false,
  isLoadingGetAllProviders: false,
  isLoadingGetAllProviderTypes: false,
  isLoadingGetAllExpenditureTypes: false,
  isLoadingGetAllFunctions: false,
  isLoadingGetAllExpenses: false,
  isLoadingGetAllCategories: false,
  isLoadingGetAllProposals: false,
  isLoadingGetAllProjectTypes: false,
  isLoadingGetAllProbabilities: false,
  isLoadingGetAllBusinesses: false,
  isLoadingGetAllStates: false,
  isLoadingGetProposal: false,
  isLoadingGetDocumentInfo: false,
  isLoadingGetDocument: false,
  isLoadingGetAllClientDirectors: false,
  isLoadingGetAllPartners: false,
  isLoadingGetAllPaymentTypes: false,
  isLoadingGetAllTeams: false,
  successDelete: false,
  isLoadingDelete: false,
  successDeleteDocument: false,
  isLoadingDeleteDocument: false,
  isLoadingGetAllFactoring: false,
  isLoadingGetAllInvoicingModes: false,
  isLoadingGetAllProposalTypes: false,
  isLoadingGetAllCompanies: false,
  isLoadingEditClient: false,
  isLoadingEditProviders: false,
  stopDeletingClients: false,
  stopDeletingProviders: false,
  isLoadingSaveClients: false,
  isLoadingSaveProviders: false,
  isLoadingGetAllClientByClientDirector: false,
  isLoadingMakeActive: false,
  updateDoen: null,
  updateSubmit : null,
};

export const budgetManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WORKPLANS:
      return { ...state, isLoadingGetAllLift: true };
    case DONE_GET_ALL_LIFT:
      return { ...state, isLoadingGetAllLift: false };
    case SET_ALL_WORKPLANS:
      return { ...state, workplans: action.workplans };
      case SET_ALL_WORKPLANS_OBJ:
        return { ...state, workplansObj: action.workplansObj };
    case SET_WORKPLAN_BYID: return{...state, workplanById: action.workplanById}
    case GET_ALL_COLLABORATORS:
      return { ...state, isLoadingGetAllCollaborators: true };
    case DONE_GET_ALL_COLLABORATORS:
      return { ...state, isLoadingGetAllCollaborators: false };
    case SET_ALL_COLLABORATORS:
      return { ...state, collaborators: action.collaborators };

    case MAKE_WORKPLAN_ACTIVE:
      return { ...state, isLoadingMakeActive: true };
    case DONE_MAKE_ACTIVE:
      return { ...state, isLoadingMakeActive: false };

    case GET_ALL_PROJECT_TYPES:
      return { ...state, isLoadingGetAllProjectTypes: true };
    case DONE_GET_ALL_PROJECT_TYPES:
      return { ...state, isLoadingGetAllProjectTypes: false };
    case SET_ALL_PROJECT_TYPES:
      return { ...state, projectTypes: action.projectTypes };

    case GET_ALL_PROPOSAL_TYPES:
      return { ...state, isLoadingGetAllProposalTypes: true };
    case DONE_GET_ALL_PROPOSAL_TYPES:
      return { ...state, isLoadingGetAllProposalTypes: false };
    case SET_ALL_PROPOSAL_TYPES:
      return { ...state, proposalTypes: action.proposalTypes };

    case SET_UPDATE_DONE:
      return { ...state, updateDone: action.message };

    case SET_UPDATE_SUBMIT:
      return { ...state, updateSubmit: action.message };

    case GET_ALL_TEAMS:
      return { ...state, isLoadingGetAllTeams: true };
    case DONE_GET_ALL_TEAMS:
      return { ...state, isLoadingGetAllTeams: false };
    case SET_ALL_TEAMS:
      return { ...state, teams: action.teams };

    case GET_ALL_COMPANIES:
      return { ...state, isLoadingGetAllCompanies: true };
    case DONE_GET_ALL_COMPANIES:
      return { ...state, isLoadingGetAllCompanies: false };
    case SET_ALL_COMPANIES:
      return { ...state, companies: action.companies };

    case GET_ALL_CLIENTS:
      return { ...state, isLoadingGetAllClients: true };
    case DONE_GET_ALL_CLIENTS:
      return { ...state, isLoadingGetAllClients: false };
    case SET_ALL_CLIENTS:
      return { ...state, clients: action.clients };

    case GET_ALL_FACTORING:
      return { ...state, isLoadingGetAllFactoring: true };
    case DONE_GET_ALL_FACTORING:
      return { ...state, isLoadingGetAllFactoring: false };
    case SET_ALL_FACTORING:
      return { ...state, factoring: action.factoring };

    case GET_ALL_PAYMENT_TYPES:
      return { ...state, isLoadingGetAllPaymentTypes: true };
    case DONE_GET_ALL_PAYMENT_TYPES:
      return { ...state, isLoadingGetAllPaymentTypes: false };
    case SET_ALL_PAYMENT_TYPES:
      return { ...state, paymentTypes: action.paymentTypes };

    case GET_ALL_PARTNERS:
      return { ...state, isLoadingGetAllPartners: true };
    case DONE_GET_ALL_PARTNERS:
      return { ...state, isLoadingGetAllPartners: false };
    case SET_ALL_PARTNERS:
      return { ...state, partners: action.partners };

    case GET_ALL_DEPARTMENTS:
      return { ...state, isLoadingGetAllDepartments: true };
    case DONE_GET_ALL_DEPARTMENTS:
      return { ...state, isLoadingGetAllDepartments: false };
    case SET_ALL_DEPARTMENTS:
      return { ...state, departments: action.departments };

    case GET_ALL_FUNCTIONS:
      return { ...state, isLoadingGetAllFunctions: true };
    case DONE_GET_ALL_FUNCTIONS:
      return { ...state, isLoadingGetAllFunctions: false };
    case SET_ALL_FUNCTIONS:
      return { ...state, functions: action.functions };

    case GET_ALL_INVOICING_MODE:
      return { ...state, isLoadingGetAllInvoicingModes: true };
    case DONE_GET_ALL_INVOICING_MODE:
      return { ...state, isLoadingGetAllInvoicingModes: false };
    case SET_ALL_INVOICING_MODE:
      return { ...state, invoicingModes: action.invoicingModes };

    case GET_ALL_PROVIDERS:
      return { ...state, isLoadingGetAllProviders: true };
    case DONE_GET_ALL_PROVIDERS:
      return { ...state, isLoadingGetAllProviders: false };
    case SET_ALL_PROVIDERS:
      return { ...state, providers: action.providers };

    case GET_ALL_PROVIDERTYPES:
      return { ...state, isLoadingGetAllProviderTypes: true };
    case DONE_GET_ALL_PROVIDERTYPES:
      return { ...state, isLoadingGetAllProviderTypes: false };
    case SET_ALL_PROVIDERTYPES:
      return { ...state, providerTypes: action.providerTypes };

    case GET_ALL_EXPENSES:
      return { ...state, isLoadingGetAllExpenses: true };
    case DONE_GET_ALL_EXPENSES:
      return { ...state, isLoadingGetAllExpenses: false };
    case SET_ALL_EXPENSES:
      return { ...state, expenses: action.expenses };

    case GET_ALL_EXPENDITURETYPES:
      return { ...state, isLoadingGetAllExpenditureTypes: true };
    case DONE_GET_ALL_EXPENDITURETYPES:
      return { ...state, isLoadingGetAllExpenditureTypes: false };
    case SET_ALL_EXPENDITURETYPES:
      return { ...state, expenditureTypes: action.expenditureTypes };

    case GET_ALL_CATEGORIES:
      return { ...state, isLoadingGetAllCategories: true };
    case DONE_GET_ALL_CATEGORIES:
      return { ...state, isLoadingGetAllCategories: false };
    case SET_ALL_CATEGORIES:
      return { ...state, categories: action.categories };

    case GET_ALL_PROPOSALS:
      return { ...state, isLoadingGetAllProposals: true };
    case DONE_GET_ALL_PROPOSALS:
      return { ...state, isLoadingGetAllProposals: false };
    case SET_ALL_PROPOSALS:
      return { ...state, proposals: action.proposals };

    case GET_ALL_PROBABILITIES:
      return { ...state, isLoadingGetAllProbabilities: true };
    case DONE_GET_ALL_PROBABILITIES:
      return { ...state, isLoadingGetAllProbabilities: false };
    case SET_ALL_PROBABILITIES:
      return { ...state, probabilities: action.probabilities };

    case GET_ALL_BUSINESSES:
      return { ...state, isLoadingGetAllBusinesses: true };
    case DONE_GET_ALL_BUSINESSES:
      return { ...state, isLoadingGetAllBusinesses: false };
    case SET_ALL_BUSINESSES:
      return { ...state, businesses: action.businesses };

    case GET_ALL_STATES:
      return { ...state, isLoadingGetAllStates: true };
    case DONE_GET_ALL_STATES:
      return { ...state, isLoadingGetAllStates: false };
    case SET_ALL_STATES:
      return { ...state, states: action.states };

    case GET_ALL_CATEGORIES:
      return { ...state, isLoadingGetAllCategories: true };
    case DONE_GET_ALL_CATEGORIES:
      return { ...state, isLoadingGetAllCategories: false };
    case SET_ALL_CATEGORIES:
      return { ...state, categories: action.categories };

    case GET_ALL_CLIENT_DIRECTORS:
      return { ...state, isLoadingGetAllClientDirectors: true };
    case DONE_GET_ALL_CLIENT_DIRECTORS:
      return { ...state, isLoadingGetAllClientDirectors: false };
    case SET_CLIENT_DIRECTORS:
      return { ...state, clientDirectors: action.clientDirectors };

    case GET_ALL_BY_CLIENT_DIRECTOR:
      return { ...state, isLoadingGetAllClientByClientDirector: true };
    case DONE_GET_ALL_BY_CLIENT_DIRECTOR:
      return { ...state, isLoadingGetAllClientByClientDirector: false };

    case GET_PROPOSAL:
      return { ...state, isLoadingGetProposal: true };
    case SET_PROPOSAL:
      return { ...state, proposal: action.proposal };
    case DONE_GET_PROPOSAL:
      return { ...state, isLoadingGetProposal: false };

    case GET_DOCUMENT_INFO:
      return { ...state, isLoadingGetDocumentInfo: true };
    case SET_DOCUMENT_INFO:
      return { ...state, documentInfo: action.documentInfo };
    case DONE_GET_DOCUMENT_INFO:
      return { ...state, isLoadingGetDocumentInfo: false };

    case GET_DOCUMENT:
      return { ...state, isLoadingGetDocument: true };
    case SET_DOCUMENT:
      return { ...state, currentDocument: action.currentDocument };
    case DONE_GET_DOCUMENT:
      return { ...state, isLoadingGetDocument: false };

    case SET_CURRENT_DOCUMENTS:
      return { ...state, documents: action.documents };

    case SET_WORKPLAN:
      return { ...state, workplans: [...state.workplans, action.workplan] };

    case SET_CONTACT:
      return { ...state, contact: action.client };

    case SET_PROVIDER:
      return { ...state, provider: action.provider };

    case SET_TRIGGER_LOADING:
      return { ...state, triggerLoading: action.triggerLoading };

    case SET_ERROR_UPDATNG:
      return { ...state, errorUpdating: action.errorUpdating };

    case SET_NUM_GANHO:
      return { ...state, numGanho: action.numGanho };

    case SET_ADD_WORKPLAN_TRIGGER:
      return { ...state, addWorkplanTrigger: action.addWorkplanTrigger };

    case DELETE_WORKPLAN:
      return {
        ...state,
        isLoadingDelete: true,
        successDelete: false,
      };
    case DONE_DELETE_WORKPLAN:
      return {
        ...state,
        isLoadingDelete: false,
        successDelete: action.successDelete,
      };

    case DELETE_DOCUMENT:
      return {
        ...state,
        isLoadingDeleteDocument: true,
        successDeleteDocument: false,
      };
    case DONE_DELETE_DOCUMENT:
      return {
        ...state,
        isLoadingDeleteDocument: false,
        successDeleteDocument: action.successDeleteDocument,
      };
    case SET_CLIENTS:
      return {
        ...state,
        allClients: action.data,
      };
    case SET_PROVIDERS:
      return {
        ...state,
        allProviders: action.data,
      };
    case UPDATE_CLIENT_ID:
      return {
        ...state,
        isLoadingEditClient: true,
      };
    case DONE_UPDATE_CLIENT_ID:
      return {
        ...state,
        isLoadingEditClient: false,
      };
    case UPDATE_PROVIDER_ID:
      return {
        ...state,
        isLoadingEditProviders: true,
      };
    case DONE_UPDATE_PROVIDER_ID:
      return {
        ...state,
        isLoadingEditProviders: false,
      };
    case SAVE_CLIENTS:
      return {
        ...state,
        isLoadingSaveClients: true,
      };
    case DONE_SAVE_CLIENTS:
      return {
        ...state,
        isLoadingSaveClients: false,
        savedClientData: action.data,
      };
    case SAVE_PROVIDERS:
      return {
        ...state,
        isLoadingSaveProviders: true,
      };
    case DONE_SAVE_PROVIDERS:
      return {
        ...state,
        isLoadingSaveProviders: false,
        savedProviderData: action.date,
      };
    case DELETE_CLIENT_DATA:
      return {
        ...state,
        stopDeletingClients: true,
      };

    case DONE_DELETE_CLIENT_DATA:
      return {
        ...state,
        stopDeletingClients: false,
      };
    case DELETE_PROVIDER_DATA:
      return {
        ...state,
        stopDeletingProviders: true,
      };

    case DONE_DELETE_PROVIDER_DATA:
      return {
        ...state,
        stopDeletingProviders: false,
      };
    case STOP_EDITING_CLIENTS:
      return {
        ...state,
        stopDeletingClients: false,
      };
    case STOP_EDITING_PROVIDERS:
      return {
        ...state,
        stopDeletingProviders: false,
      };
    default:
      return state;
  }
};
