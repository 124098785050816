import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import oauth from "~/utils/oauth";
import Header from "~/pages/Easypay/components/Header";
import CreateForm from "~/pages/Easypay/CreateForm";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";

import "~/pages/Easypay/assets/css/styles.css";

import { getEasypayFormdata } from "~/store/ducks/easypay/actionTypes";

const BackofficeUser = ({ application }) => {
  const dispatch = useDispatch();

  const { easypayFormdata } = useSelector((state) => state.easypayReducer);

  useEffect(() => {
    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=311" +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    dispatch(getEasypayFormdata(payload));
  }, []);

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-backoffice-content">
        <CreateForm jsonData={easypayFormdata} flowid={application?.flowid} />
      </div>
    </div>
  );
};
export default withNamespaces()(BackofficeUser);
