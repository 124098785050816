import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { find } from "lodash";

import Tasks from "~/components/Tasks";
import Processes from "~/components/Processes";
import ProtectedPage from "~/components/security/ProtectedPage";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";
import AllIframesComponent from "~/components/AllIframesComponent";
import IframeHandler from "~/components/IframeHandler";
import WidgetContainer from "~/components/WidgetContainer";

import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import { BPM_MIDDLEWARE, RH_MIDDLEWARE } from "~/utils/constants";

import {
  getMenusByApplication,
  addItemsToMenu,
} from "~/store/ducks/applicationMenu/actionTypes";
import ContentContainer from "~/containers/ContentContainer";
const devLogConsole = require("~/utils/devLog");
const RH = (props) => {
  const { isMongoOn } = props;
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      dispatch(getMenusByApplication(username, RH_MIDDLEWARE.context));
    }
  }, [user, dispatch]);

  /*
  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.rh.processos" });
    const items = rawMenus.map((item) => ({
      name: item.label,
      url: item.url,
      route: `${RH_MIDDLEWARE.context}/processos/form/${item.label}`,
      menuParent: processosMenu ? processosMenu.id : null,
    }));
    dispatch(addItemsToMenu(items));
  }, [rawMenus, menu, dispatch]);
  */

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.rh.processos" });
    let items = [];
    let submenus;
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      submenus = extraConfiguration_.submenus
        ? extraConfiguration_.submenus
        : null;
      if (submenus) {
        Object.keys(submenus).forEach((key) => {
          //check if submenu has children
          let hasChildren = false;
          if (submenus[key].length == 0) hasChildren = false;
          else {
            submenus[key].every((flowid) => {
              const res = rawMenus.every((rm) => {
                //find flowid
                if (rm.url) {
                  let arr = rm.url.split("flowid=");
                  const flowid_ = arr[1];
                  if (flowid_ == flowid) {
                    hasChildren = true;
                    return false;
                  }
                  return true;
                }
                return true;
              });
              return res;
            });
          }
          if (hasChildren) {
            items.push({
              id: key,
              name: key,
              submenu: true,
              route: `${RH_MIDDLEWARE.context}/processos/submenu/${key}`,
              menuParent: processosMenu ? processosMenu.id : null,
            });
          }
        });
      }
      const getProcessosMenuId = () =>
        processosMenu ? processosMenu.id : null;
      const getSubMenuParentId = (url) => {
        if (url) {
          let arr = url.split("flowid=");
          const flowId = arr[1];
          let id = getProcessosMenuId();
          Object.keys(submenus).forEach((key) => {
            if (submenus[key].some((i) => i == flowId)) {
              id = key;
            }
          });
          return id;
        } else return getProcessosMenuId();
      };
      rawMenus.forEach((item) => {
        items.push({
          name: item.label,
          url: item.url,
          route: `${RH_MIDDLEWARE.context}/processos/form/${item.label}`,
          menuParent: submenus
            ? getSubMenuParentId(item.url)
            : getProcessosMenuId(),
        });
      });
      dispatch(addItemsToMenu(items));
    }
  }, [rawMenus, menu, dispatch]);

  /*Get response from Portal  iframe*/
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    function handleSUEvent(e) {
      devLogConsole(e.detail);
      devLogConsole("constructing menu...");
      setShowMenu(true);
    }
    function handleWaitAppResponse(e) {
      devLogConsole("constructing menu from response...");
      setShowMenu(true);
    }
    window.addEventListener("SUEvent", handleSUEvent);
    window.addEventListener("WaitAppResponse", handleWaitAppResponse);
  }, []);

  useEffect(() => {
    if (document.getElementById("hr")) {
      if (document.getElementById("hr").contentWindow) {
        document
          .getElementById("hr")
          .contentWindow.dispatchEvent(
            new CustomEvent("GetAppResponse", { detail: "from: hr" })
          );
        devLogConsole("after disptaching GetAppResponse to iframe hr");
      }
    }
  });
  /*Get response from Portal  iframe end*/

  return (
    <>
      <AllIframesComponent name="rh" />
      <ApplicationMenu applicationName={RH_MIDDLEWARE.context} />
      <Switch>
        <Route path={`${path}/portal`}>
          <ProtectedPage roles={["ADMIN", "RHUser"]}>
            <IframeHandler name="rh" />
          </ProtectedPage>
        </Route>

        <Route path={`${path}/tarefas`} exact>
          <ProtectedPage roles={["ADMIN", "RHUser"]}>
            <ContentContainer>
              <Tasks app={"rh"} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/pesquisar`} exact>
          <ProtectedPage roles={["ADMIN", "RHUser"]}>
            <Processes app={"rh"} />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/processos`}>
          <ProtectedPage roles={["ADMIN", "RHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/submenu`}>
          <ProtectedPage roles={["ADMIN", "RHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`}>
          <ProtectedPage roles={["ADMIN", "RHUser"]}>
            <ProcessosFormHandler app={"rh"} className="main-card-v2" />
          </ProtectedPage>
        </Route>

        <Route path={path}>
          {isMongoOn && (
            <ProtectedPage roles={["ADMIN", "RHUser", "RHBackoffice"]}>
              <ContentContainer>
                <WidgetContainer />
              </ContentContainer>
            </ProtectedPage>
          )}
        </Route>
      </Switch>
    </>
  );
};
export default RH;
