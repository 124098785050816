import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import DatePicker from "~/components/DatePicker";
import CreateAnnotateForwardTask from "./CreateAnnotateForwardTask";
import CreateEndProcessBadge from "./CreateEndProcessBadge";
import CreateCloseProcessButton from "./CreateCloseProcessButton";
import CreateAlert from "./CreateAlert";
import { withNamespaces } from "react-i18next";
import { parseHtmlText } from "../utils";

const CreateForwardPage = (props) => {
  const { t, jsonData, styleSheet, isModal, handleClose } = props;
  const { user } = useSelector((state) => state.globalReducer);

  const [names, setNames] = useState([]);
  const [badgeText, setBadgeText] = useState("");

  const fullName = user?.detail?.firstName + " " + user?.detail?.lastName;

  useEffect(() => {
    if (jsonData && jsonData.responseMessage) {
      if (jsonData.responseMessage.includes('<td class="tab_row_odd">')) {
        const names_ = [];

        const parseTd = (stringArray) => {
          stringArray.forEach((i) => {
            if (i.includes("</td>")) {
              const name = i.split("</td>")[0].replaceAll("&nbsp;", " ");
              if (name && !name.includes("<td")) {
                names_.push(name);
              }
            }
          });
        };
        //get table
        let table = jsonData.responseMessage.split(
          '<table class="item_list table">'
        )[1];
        table = table.split("</table>")[0];

        parseTd(table.split('<td class="tab_row_odd">'));
        parseTd(table.split('<td class="tab_row_even">'));

        setNames(names_);
      }

      const text = parseHtmlText(jsonData.responseMessage, "<p>", "</p>");

      if (text) {
        setBadgeText(text);
      }
    }
  }, [jsonData]);

  const divStyle = {
    display: "flex",
    flexWrap: "nowrap",
  };

  let field = {
    text: badgeText ? badgeText : t("taskforward.badgeTitle"),
  };

  const pid = () => {
    if (jsonData?.url) {
      const arr1 = jsonData.url.split("pid=");
      return arr1[1].split("&")[0];
    }
    return "";
  };

  return (
    <>
      {!isModal && styleSheet?.processCloseButton && (
        <CreateCloseProcessButton className="close-button_custom" />
      )}
      <div
        style={{
          marginTop: "0",
        }}
      >
        {styleSheet?.badgesMessage ? (
          <div
            style={{
              marginTop: "0",
              display: "flex",
            }}
          >
            <CreateEndProcessBadge
              badgeText={badgeText}
              badgeTextDefault={t("taskforward.badgeTitle")}
              className="badge-success"
            />
          </div>
        ) : (
          <CreateAlert field={field} variant="info" />
        )}

        <br />
        {!styleSheet?.quietForwardProcess && (
          <div style={divStyle}>
            <Form.Label column className="iflow-form-label-fix">
              {t("taskPage.general.processNumber")}
            </Form.Label>
            <div
              style={{
                fontWeight: "500",
                paddingTop: "0.375rem",
                paddingBottom: "0.375rem",
              }}
            >
              {pid()}
            </div>
          </div>
        )}
        <div style={divStyle}>
          <Form.Label column className="iflow-form-label-fix">
            {t("taskforward.sentBy")}
          </Form.Label>
          <div
            style={{
              fontWeight: "500",
              paddingTop: "0.375rem",
              paddingBottom: "0.375rem",
            }}
          >
            {fullName}
          </div>
        </div>
        {styleSheet?.highLightRows && <hr />}

        {names && names.length > 0 && (
          <>
            <div style={divStyle}>
              <Form.Label column className="iflow-form-label-fix">
                {t("taskforward.sentTo")}
              </Form.Label>
              <div
                style={{
                  fontWeight: "500",
                  paddingTop: "0.375rem",
                  paddingBottom: "0.375rem",
                }}
              >
                {names.map((n, i) => (
                  <div key={i}>
                    {n.includes("-") ? n.split("-")[1]?.trim() : n}
                  </div>
                ))}
              </div>
            </div>
            {styleSheet?.highLightRows && <hr />}
          </>
        )}

        <div style={divStyle}>
          <Form.Label column className="iflow-form-label-fix">
            {t("taskforward.sendDate")}
          </Form.Label>
          <DatePicker value={new Date()} format={"dd/MM/yyyy"} disabled />
        </div>
        {styleSheet?.highLightRows ? <hr /> : <br />}

        {styleSheet?.annotateForwardProcess && (
          <CreateAnnotateForwardTask
            jsonData={jsonData}
            isModal={isModal}
            handleClose={handleClose}
          />
        )}
      </div>
    </>
  );
};

export default withNamespaces()(CreateForwardPage);
