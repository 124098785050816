import {
  FIND_USAGE_REPORT,
  SET_USAGE_REPORT,
  GET_REPORT_TO_EXTRACT,
  SET_REPORT_TO_EXTRACT,
  SUCCESS_DOWNLOAD_REPORT_EXTRACT,
} from "./actionTypes";

const initialState = {
  usageReport: [],
  isLoadingUsageReport: false,
  isLoadingReports: false,
  reportsToExport: [],
  reportDownloadSuccess: false,
};

export function reportReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_USAGE_REPORT:
      return { ...state, isLoadingUsageReport: true };
    case SET_USAGE_REPORT:
      return {
        ...state,
        usageReport: action.data,
        isLoadingUsageReport: false,
      };
    case GET_REPORT_TO_EXTRACT:
      return { ...state, isLoadingReports: true };
    case SET_REPORT_TO_EXTRACT:
      return {
        ...state,
        reportsToExport: action.reports,
        isLoadingReports: false,
      };
    case SUCCESS_DOWNLOAD_REPORT_EXTRACT:
      return {
        ...state,
        reportDownloadSuccess: action.value,
        isLoadingReports: false,
      };
    default:
      return state;
  }
}
