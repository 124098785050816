import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  FIND_USAGE_REPORT,
  GET_REPORT_TO_EXTRACT,
  BUTTON_BACKEND_CALL
} from "../ducks/reports/actionTypes";

import {
  findUsageReport,
  getReportToExtract,
  buttonBackendCall
} from "../ducks/reports/sagas";

export const reportSagas = [
  takeEvery(FIND_USAGE_REPORT, findUsageReport),
  takeLatest(GET_REPORT_TO_EXTRACT, getReportToExtract),
  takeLatest(BUTTON_BACKEND_CALL, buttonBackendCall)
]
