import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

import avatarDefault from "~/assets/img/avatar/avatar-default.png";
import "../notifications.scss";
import "~/assets/css/styles.css";
import { toLocaleString } from "~/utils";
import { Badge } from "react-bootstrap";
import oauth from "~/utils/oauth";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import FlowModal from "~/components/FlowModal";
import { useDispatch } from "react-redux";
const devLogConsole = require("~/utils/devLog");
const NotificationDropdownItem = ({
  t,
  item,
  handleSeen,
  handleDelete,
  styleSheet,
  countItemNotSeen,
}) => {
  const [evenRow, setEvenRow] = useState(false);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(undefined);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [pidSelected, setPidSelected] = useState("");
  const [itemSubject, setItemSubject] = useState("");

  const renderActions = () => {
    return (
      <div style={{ display: "flex" }}>
        {styleSheet?.notificationsCma ? (
          <>
            {" "}
            <Badge
              pill
              className="badge-secondary"
              style={{
                backgroundColor: "#24579e",
                marginRight: "15px",
                marginTop: "10px",
                fontSize: "10px",
                cursor: "pointer",
              }}
              onClick={() => handleClickSeen()}
            >
              {item.seen
                ? t("notifications.markAsUnRead")
                : t("notifications.markAsRead")}
            </Badge>
            <Badge
              pill
              className="badge-secondary"
              style={{
                marginTop: "10px",
                fontSize: "10px",
                backgroundColor: "#f46f70",
                cursor: "pointer",
              }}
              onClick={() => handleClickDelete()}
            >
              {t("notifications.delete")}
            </Badge>{" "}
          </>
        ) : (
          <>
            <div className="mark-as-read" onClick={() => handleClickSeen()}>
              {item.seen
                ? t("notifications.markAsUnRead")
                : t("notifications.markAsRead")}
            </div>
            <div
              className="mark-as-read"
              style={{ paddingLeft: "10px" }}
              onClick={() => handleClickDelete()}
            >
              {t("notifications.delete")}
            </div>{" "}
          </>
        )}
      </div>
    );
  };
  const handleClickSeen = () => {
    handleSeen(item);
  };
  const handleClickDelete = () => {
    handleDelete(item);
  };

  useEffect(() => {
    if (!item.seen) {
      if (countItemNotSeen % 2 == 0) {
        setEvenRow(true);
      } else {
        setEvenRow(false);
      }
    }
  }, [item.seen, countItemNotSeen]);

  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.link && item?.link !== null) {
      let values = item?.link.split(",");
      let pid = parseInt(values[1]);
      setPidSelected(pid);
    }
  });

  const handleClickItem = () => {
    if (item?.link && item?.link !== null) {
      let values = item?.link.split(",");
      let flowid = parseInt(values[0]);
      let pid = parseInt(values[1]);
      let subpid = parseInt(values[2]);

      const url =
        `/Form/detail.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&procStatus=2&fwSearch=true` +
        "&Authorization=" +
        oauth.getAccessToken();

      //dispatch(getIflowFormdata({ url }));
      const row = {
        application: "bpm",
        flowid: flowid,
        pid: pid,
        subpid: subpid,
        url: url,
      };

      setShowFlowModal(true);
      setSelectedUrl(url);
      setSelectedProcess(row);
    }
  };

  useEffect(() => {
    if (item?.subject) {
      let process;
      let number;
      if (item?.subject?.includes("process")) {
        if (item?.subject.indexOf("/") !== -1) {
          const splitString = item?.subject?.split(":");
          process = splitString[0]?.split("/")[1];
          number = splitString[1]?.split(",")[0];
        } else if (item?.subject?.indexOf("_") !== -1) {
          const splitString = item?.subject?.split(":");
          process = splitString[0]?.split("_")[1];
          number = splitString[1]?.split(",")[0];
        }
        if (/\d/.test(number)) {
          setItemSubject(`${process}: ${number}`);
          devLogConsole(number, "number");
        } else {
          setItemSubject(`${process}`);
        }
      } else {
        setItemSubject(item?.subject);
      }
    }
  }, [item?.subject]);

  return (
    <>
      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        process={selectedProcess}
        src={selectedUrl}
        app={"bpm"}
        styleSheetLink={true}
      />
      <div
        style={
          styleSheet?.notificationsCma && evenRow
            ? { backgroundColor: "#e7e6e6" }
            : {}
        }
        className={
          styleSheet?.notificationsCma
            ? item.seen
              ? "notification-container_CMA"
              : "notification-container-unread_CMA"
            : item.seen
            ? "notification-container"
            : "notification-container-unread"
        }
      >
        <img
          className="img-profile-header"
          src={avatarDefault}
          alt=""
          width="30px"
          height="30px"
        />
        <div className="message-container">
          <div>
            {item?.link != null && pidSelected !== -10 ? (
              <b
                onClick={handleClickItem}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                {itemSubject && itemSubject}
              </b>
            ) : (
              <span style={{ fontWeight: "bold" }}>
                {itemSubject && itemSubject}
              </span>
            )}
            {" - "}
            {toLocaleString(item.createdDate && item.createdDate)}
            <br />
            {item.message && item.message}
          </div>

          {renderActions()}
        </div>
      </div>
      {!styleSheet?.notificationsCma && item?.seen ? (
        <hr style={{ margin: "0px" }} />
      ) : null}
    </>
  );
};
export default withNamespaces()(NotificationDropdownItem);
