import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import arrowDown from "~/pages/Easypay/assets/img/icons/arrow-down-grey.png";
import arrowUp from "~/pages/Easypay/assets/img/icons/arrow-up-blue.png";
import "~/pages/Easypay/assets/scss/_dropdown2.scss";
import "../assets/scss/_inputDropdown.scss";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/

const InputDropdown = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar",
    disabled,
    name,
    position,
    size,
    initialSelected,
  } = props;

  /*
        Sorts an array by name, or number (both ascending or descending)
    */
  const sortBy = {
    nameAscending: (prop) =>
      prop.sort((a, b) => a.label.localeCompare(b.label)),
    nameDescending: (prop) =>
      prop.sort((a, b) => b.label.localeCompare(a.label)),
    numberAscending: (prop) => prop.sort((a, b) => a - b),
    numberDescending: (prop) => prop.sort((a, b) => b - a),
    noOrder: (prop) => prop,
  };

  function repeat() {
    var arr = [];
    for (var i = 0; i < optionList.length; i++) {
      arr.push("");
    }
    return arr;
  }

  //Sorts list
  const sortedList = sortBy.nameAscending(list);

  //Translates labels
  const optionList = sortedList.map((i) => {
    return { label: t(i.label), value: i.value, selected: i.selected };
  });
  const emptyArray = repeat();
  const [selectedValue, setSelectedValue] = useState("");
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState([optionList]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedElements, setSelectedElements] = useState(
    initialSelected?.length > 0 ? initialSelected : emptyArray
  );

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    if (initialSelected) {
      const filteredLabels = initialSelected
        .filter((item) => item && item.length > 0)
        .join(",");
      setLabel(filteredLabels);
      setSelectedValue(initialSelected);
    }
  }, [list]);

  /*
    Function that runs when clicking any option
  */
  const handleSelect = (i) => {
    let remove = false;
    let newArray = [...selectedElements]; // Copiar o estado atual

    if (newArray.includes(optionList[i].value)) {
      remove = true;
      newArray[i] = "";
    } else {
      newArray[i] = optionList[i]?.value;
    }

    setSelectedElements(newArray);

    if (!handleSubmit) {
      const labelString = newArray.filter((item) => item !== "").join(",");
      setLabel(labelString);
      return;
    }
    handleSubmit({
      selected: optionList[i],
      list: newArray,
      remove: remove,
    });
  };

  /* 
    Sorts list to display selected element in first place. Also deletes it from the displayed list
  */
  const sortListAfterSelect = (list, selected) => {
    const newList = list.map((item) => ({
      ...item,
      selected: selected.includes(item.value),
    }));
    setDisplayList(newList);
    return newList;
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected liftworld-shows it highlighted
  */
  const toggleList = () => {
    const dropDownElement = document.getElementById(`dropDown-${id.current}`);
    dropDownElement.classList.toggle("liftworld-show");

    if (dropDownElement.classList.contains("liftworld-show")) {
      const newList = sortListAfterSelect(optionList, selectedValue);
      setDisplayList(newList.length ? newList : optionList);
    }
  };

  const hideDropDown = (desiredId = id.current) => {
    document
      .getElementById(`dropDown-${desiredId}`)
      .classList.remove("liftworld-show");
  };

  /*<
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = (e) => {
    hideDropDown(parseInt(e.target.id.match(/\d+/)[0]));
  };

  function getDefaultChecked(i) {
    if (initialSelected[i]?.length > 0) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <Form.Group controlId={`formText-field`} style={{ margin: "0px" }}>
        <div className={"liftworld-input-dropdown-wrapper"}>
          <div
            className={`liftworld-input-dropdown-field-wrapper ${position} ${size}`}
          >
            <input
              disabled={disabled}
              autoComplete="off"
              onFocus={() => {
                setShowDropdown(true);
              }}
              className={`liftworld-input-dropdown-field ${
                label ? "liftworld-filled" : ""
              } `}
              placeholder={`${placeholder}`}
              id={`formInput-${id.current}`}
              value={label}
              required={required}
              name={name}
              readOnly
              onMouseDown={() => {
                toggleList();
                setShowDropdown(!showDropdown);
              }}
            />
            <div
              className="liftworld-input-dropdown-icon-wrapper"
              onMouseDown={() => {
                toggleList();
                setShowDropdown(!showDropdown);
              }}
            >
              <img
                className="liftworld-input-dropdown-icon"
                src={showDropdown ? arrowUp : arrowDown}
                alt="search-icon"
              />
            </div>
          </div>

          {
            <div id={`dropDown-${id.current}`} className="liftworld-dropdown">
              {displayList.map((option, i) => (
                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                  <div
                    id={`selected-${i}`}
                    value={option.value}
                    className="liftworld-dropdown-option"
                  >
                    {option.label}
                  </div>
                  <div className="liftworld-dropdown-checkbox-wrapper">
                    <input
                      name="isGoing"
                      type="checkbox"
                      onChange={() => handleSelect(i)}
                      className="liftworld-checkbox-input"
                      checked={!!selectedElements[i]}
                    />
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
        <input type="hidden" name={name} value={selectedValue} />
        <Form.Control.Feedback type="invalid">
          {`O campo field é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default withNamespaces()(InputDropdown);
