import React, { useEffect, useState } from "react";
import { Badge, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import ButtonDownload from "~/components/Buttons/DownloadButton";
import ButtonUpload from "~/components/Buttons/UploadButton";
import pagination from "~/components/pagination/currentSizePerPage/10";
import Spinner from "~/components/Spinner";
import WarningModal from "~/components/WarningModal/WarningModal";
import {
  deleteDocument,
  findAllBatchs,
  getMetadataDocument,
  submitToiFlow,
} from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import { toLocaleString } from "~/utils";
import { getColorByStatusDocument, translateStatusDocument } from "../utils";
import { ADocumentStatus } from "../utils/ADatacaptureEnum";
import "./AutomatedDocumentTable.css";
import DigestModal from "./DigestModal";
import ExportModal from "./ExportModal";
import MetadataPreviewModal from "./MetadataPreviewModal";
import UploadModal from "./UploadModal";
import MobileCardNonFinDataCapture from "../utils/MobileCardNonFinDataCapture";

const NonFinAutomatedDocumentTable = ({
  t,
  openSettingsModal,
  simpleFlow,
  datacaptureConfiguration,
  showUploadModal,
  setShowUploadModal,
  docTypeSelected,
  setFromTabUploadModal,
  defaultTab,
  setDocTypeSelected,
}) => {
  const dispatch = useDispatch();
  const { batchs, isUpdatedDocuments, isLoadingBatchs } = useSelector(
    (state) => state.dataCaptureReducer
  );

  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { isRefreshing, isSavingMetadataDocument } = useSelector(
    (state) => state.dataCaptureReducer
  );
  const { user } = useSelector((state) => state.globalReducer);

  const isProductionMode = configuration ? configuration.productionMode : true;

  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDigestModal, setShowDigestModal] = useState(false);
  const [showDigest, setShowDigest] = useState(false);
  const [showTerminated, setShowTerminated] = useState(false);

  useEffect(() => {
    if (simpleFlow) {
      if (user && user.organization) {
        setShowDigest(user?.organization?.emailDigest);
      }
    } else {
      setShowDigest(false);
    }
  }, [user, simpleFlow]);

  const updateAfterDelete = () => {
    dispatch(findAllBatchs());
    setShowMetadataModal(false);
  };

  const cron = [
    { label: "Every 1 hour", value: "0 * * * *" },
    { label: "Every 2 hours", value: "0 */2 * * *" },
    { label: "Every 4 hours", value: "*/4 * * *" },
    { label: "Every 8 hours", value: "*/8 * * *" },
    { label: "Daily", value: "0 8 * * *", selected: true },
    { label: "Weekly", value: "0 8 * * MON" },
  ];

  const getFrequency = (value, cron) =>
    cron.find((e) => e.value === value)?.label;

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseDetailModal = () => {
    setShowMetadataModal(false);
  };
  const handleCloseExportModal = () => {
    setShowExportModal(false);
    if (isUpdatedDocuments) dispatch(findAllBatchs());
  };
  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
    setFromTabUploadModal(defaultTab);
  };
  const handleCloseDigestModal = () => {
    setShowDigestModal(false);
  };

  const handleOpenMetadataModal = (row) => {
    setShowMetadataModal(true);
    setSelectedDocument(row);
    dispatch(getMetadataDocument(row.id));
  };

  const getStatus = (row) => {
    let status = ADocumentStatus.ERROR;
    const processing = [
      DocumentStatus.PENDING,
      DocumentStatus.LOADED,
      DocumentStatus.WAITING_SUBMISSION,
      ADocumentStatus.PENDING_IFLOW,
      ADocumentStatus.PENDING_REJ_IFLOW,
    ];
    const error = [DocumentStatus.FAILED, DocumentStatus.REJECTED];
    const processed = [
      DocumentStatus.SUCCESS,
      DocumentStatus.REVISED,
      DocumentStatus.REVIEWED,
    ];

    if (processed.some((item) => item === row.statusV1)) {
      if (
        row.isDuplicated &&
        row.isDuplicated !== false &&
        row.isDuplicated !== "Duplicate Accepted" &&
        simpleFlow
      ) {
        status = ADocumentStatus.DUPLICATED;
      } else {
        status = ADocumentStatus.PROCESSED;
      }
    } else if (processing.some((item) => item === row.statusV1))
      status = ADocumentStatus.PROCESSING;
    else if (error.some((item) => item === row.statusV1))
      status = ADocumentStatus.ERROR;
    else if (row.statusV1 === ADocumentStatus.TERMINATED)
      status = ADocumentStatus.TERMINATED;
    else if (row.statusV1 === ADocumentStatus.REJECTED_IFLOW)
      status = ADocumentStatus.REJECTED_IFLOW;
    return status;
  };

  const buildStatusColumn = (cell, row) => {
    const status = getStatus(row);
    const variant = getColorByStatusDocument(status);
    const translatedStatus = translateStatusDocument(status);
    return (
      <Badge pill variant={variant}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const buildStatusSort = (cell, row) => {
    const status = getStatus(row);
    return translateStatusDocument(status);
  };

  const buildDocTypeColumn = (cell, row) => {
    if (
      row.metadata &&
      (row.metadata["documentClass"] ||
        (row.metadata && row.metadata["class"]) ||
        row.metadata["document_type"] ||
        row.metadata["document:class"])
    ) {
      function checkForHuman(value) {
        return value.includes("human") ? true : false;
      }
      if (row.metadata["documentClass"])
        return checkForHuman(row.metadata["documentClass"])
          ? ""
          : row.metadata["documentClass"];
      else if (row.metadata["class"])
        return checkForHuman(row.metadata["class"])
          ? ""
          : row.metadata["class"];
      else if (row.metadata["document_type"])
        return checkForHuman(row.metadata["document_type"])
          ? ""
          : row.metadata["document_type"];
      else if (row.metadata["document:class"])
        return checkForHuman(row.metadata["document:class"])
          ? ""
          : row.metadata["document:class"];
    } else return "";
  };

  const batchs_ = batchs.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );
  //TODO show terminated only or without it
  let docs = [];
  batchs_.forEach((batch) => {
    batch.documents.forEach((doc) => {
      doc.batchFlow = batch.flow;
      doc.batchId = batch.id;
      /*doc._datcaptureConfig is used to submit for iFlow, 
      since  bootstrap table is not updating after datacaptureConfiguration changes*/
      doc._datcaptureConfig =
        datacaptureConfiguration &&
        datacaptureConfiguration.find((c) => c.documentType === batch.flow);
      if (doc.statusV1 === DocumentStatus.TERMINATED) {
        if (showTerminated && doc?.metadata?.class !== "financial-document")
          docs.push(doc);
      } else {
        if (doc?.metadata?.class === undefined) {
          if (doc?.batchFlow !== "financial-document") {
            docs.push(doc);
          }
        } else {
          if (doc?.metadata?.class !== "financial-document") docs.push(doc);
        }
      }
    });
  });

  const reviewedDocs = docs.filter((doc) => doc.statusV1 === "REVIEWED");
  const reviewedWithoutDuplicates = reviewedDocs.filter(
    (doc) =>
      doc.isDuplicated === "Duplicate Accepted" || doc.isDuplicated === ""
  );

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const canSubmitToIflow = (batchId) => {
    let cansubmit = false;
    const savedBatch = batchs.find((b) => b.id === batchId);
    if (savedBatch) {
      cansubmit = true;
      savedBatch.documents.forEach((doc) => {
        if (doc.statusV1 !== ADocumentStatus.REJECTED_IFLOW) {
          //TODO or Terminated
          cansubmit = false;
        }
      });
    }
    return cansubmit;
  };

  const handleSubmitToiFlow = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    if (canSubmitToIflow(row.batchId)) {
      const flowId = row._datcaptureConfig && row._datcaptureConfig.flowId;
      flowId && dispatch(submitToiFlow(row, flowId));
    }
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      {(DocumentStatus.REVISED === row.statusV1 ||
        ADocumentStatus.TERMINATED === row.statusV1 ||
        DocumentStatus.REVIEWED === row.statusV1) && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("datacapture.general.detailDocument"))}
        >
          <i
            className="icon-detail table-action-icon"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleOpenMetadataModal(row, false);
            }}
          />
        </OverlayTrigger>
      )}
      {ADocumentStatus.REJECTED_IFLOW === row.statusV1 && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("datacapture.general.submitBatch"))}
        >
          <i
            className="icon-submit table-action-icon"
            onClick={(e) => handleSubmitToiFlow(e, row)}
          />
        </OverlayTrigger>
      )}
    </>
  );

  const calculateSlaTime = (_, row, {}) => {
    const createdDate = new Date(row.createdDate).getTime();
    const revisionDate = new Date(row.revisionDate).getTime();
    const slaSeconds = (revisionDate - createdDate) / 1000;

    if (slaSeconds >= 60 && slaSeconds < 120) {
      return `${slaSeconds}sec`;
    }
    if (slaSeconds >= 120 && slaSeconds < 3600) {
      return `${Math.round(slaSeconds / 60)}min`;
    }
    if (slaSeconds >= 3600 && slaSeconds < 86400) {
      return `${Math.floor(slaSeconds / 3600)}h ${Math.round(
        ((slaSeconds / 3600) % 1) * 60
      )}min `;
    }
    if (slaSeconds >= 86400) {
      return `${Math.floor(slaSeconds / 86400)}d ${Math.round(
        ((slaSeconds / 86400) % 1) * 60
      )}h `;
    }
  };
  const isMobile = window.innerWidth < 992 ? true : false;

  const getMobileCardFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <MobileCardNonFinDataCapture
        row={row}
        rowIndex={rowIndex}
        formatExtraData={formatExtraData}
        buildStatusColumn={buildStatusColumn}
        //  buildApprovedColumn={buildApprovedColumn}
        //   buildDocTypeColumn={buildDocTypeColumn}
        buildActionsColumn={buildActionsColumn}
      />
    );
  };

  const mobileColumns = [
    {
      dataField: "mobileCard",
      text: "",
      formatter: getMobileCardFormatter,
      // formatExtraData: {
      //   handleOpenFlowModal: handleOpenFlowModal,
      // },
      hidden: isMobile ? false : true,
      headerStyle: { display: "none" },
      style: { padding: "0px" },
    },
  ];

  const columns = [
    {
      dataField: "metadata.numero_documento",
      text: t("datacapture.general.numero_documento"),
      style: {
        overflowWrap: "break-word",
      },
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "metadata.data",
      text: t("datacapture.general.reclamacao_data"),
      sort: true,
      formatter: (_, row, {}) => toLocaleString(row?.metadata?.data),
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleString(row?.metadata?.data).replace(/,/g, ""),
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "metadata.1_nome",
      text: t("datacapture.general.nome_fornecedor"),
      style: {
        overflowWrap: "break-word",
      },
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "metadata.1_localidade",
      text: t("datacapture.general.nome_localidade"),
      style: {
        overflowWrap: "break-word",
      },
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "metadata.2_nome",
      text: t("datacapture.general.nome_reclamante"),
      style: {
        overflowWrap: "break-word",
      },
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "metadata.2_tel",
      text: t("datacapture.general.telemovel_reclamante"),
      style: {
        overflowWrap: "break-word",
      },
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "statusV1",
      text: t("datacapture.general.status"),
      sort: true,
      formatter: (cell, row) => buildStatusColumn(cell, row),
      sortValue: (cell, row) => buildStatusSort(cell, row),
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      align: "center",
      csvExport: false,
    },
  ];

  if (!isProductionMode) {
    const buildDelete = (_, row, {}) => {
      return (
        <button
          className="button-delete-document"
          onClick={() => {
            dispatch(deleteDocument(row.id));
          }}
        >
          Delete
        </button>
      );
    };
    const deleteColumn = {
      dataField: "delete",
      text: "Delete",
      formatter: buildDelete,
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
      align: "center",
      csvExport: false,
    };

    const slaColumn = {
      dataField: "sla",
      text: t("admin.organization.list.sla"),
      formatter: calculateSlaTime,
      headerStyle: () => {
        return { width: "130px", textAlign: "center" };
      },
    };
    columns.push(slaColumn);
    columns.push(deleteColumn);
  }

  const rowEvents = {
    onClick: (e, row) => {
      e.stopPropagation();
      e.preventDefault();
      if (
        !e.target.className.includes("icon-refresh") &&
        !e.target.className.includes("button-delete-document") &&
        !isRefreshing &&
        (row.statusV1.includes("REVISED") || row.statusV1.includes("REVIEWED"))
      ) {
        handleOpenMetadataModal(row);
      }
    },
  };

  const MyExport = () => {
    const handleClick = () => {
      setShowExportModal(true);
    };
    return (
      <div style={{ marginLeft: "10px" }}>
        <ButtonDownload
          text={t("datacapture.general.downloadDocuments")}
          onClick={handleClick}
        />
      </div>
    );
  };

  const { SearchBar } = Search;

  datacaptureConfiguration = datacaptureConfiguration.filter(
    (e) => !e?.deprecated
  );


  return (
    <>
      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={docs}
          columns={isMobile ? mobileColumns : columns}
          search={{ searchFormatted: true }}
        >
          {(props) => (
            <div>
              <div className="dataCapture_container">
                {!isMobile
                  ? docs.length > 0 && (
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    )
                  : null}
                <div className="dataCapture_secondContainer">
                  <div className="mobile_group_buttons">
                    <div className="mobile_buttons">
                      <div className="button_upload">
                        <ButtonUpload
                          text={t("datacapture.general.upload")}
                          onClick={() => {
                            setFromTabUploadModal(docTypeSelected);
                            setShowUploadModal(true);
                            setDocTypeSelected(docTypeSelected);
                          }}
                        />
                      </div>
                      <div className="button_export">
                        {docs.length > 0 && simpleFlow && <MyExport />}
                      </div>
                    </div>

                    <div className="mobile_buttons">
                      <Button
                        className="card-button"
                        style={
                          isMobile
                            ? { marginBottom: "10px" }
                            : { marginLeft: "10px" }
                        }
                        onClick={() => setShowDigestModal(true)}
                      >
                        Report
                      </Button>

                      {showDigest && (
                        <Button
                          className="card-button"
                          style={
                            isMobile
                              ? { marginBottom: "10px" }
                              : { marginLeft: "10px" }
                          }
                          onClick={() => openSettingsModal()}
                        >
                          {user?.settings?.digestFrequency
                            ? `Digest ${getFrequency(
                                user.settings.digestFrequency,
                                cron
                              )}`
                            : "Digest Frequency"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                {!simpleFlow && (
                  <Form.Check
                    type="switch"
                    id="custom-switch-terminated"
                    label={t("datacapture.general.showTerminated")}
                    style={{ marginBottom: "0.5rem", marginLeft: "10px" }}
                    checked={showTerminated}
                    onChange={() =>
                      setShowTerminated((showTerminated) => !showTerminated)
                    }
                  />
                )}
              </div>

              <div className="searchbar">
                {isMobile
                  ? docs.length > 0 && (
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    )
                  : null}
              </div>
              <BootstrapTable
                {...props.baseProps}
                id="document-table"
                responsive
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("datacapture.general.dataNotFound")}
                rowEvents={rowEvents}
                pageSize={5}
                striped={isMobile ? false : true}
                hover={isMobile ? false : true}
              />
              <Spinner
                spinning={
                  isRefreshing || isSavingMetadataDocument || isLoadingBatchs
                }
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ExportModal
        showModal={showExportModal}
        closeModal={handleCloseExportModal}
        reviewedDocs={reviewedWithoutDuplicates}
        buildDocTypeColumn={buildDocTypeColumn}
        datacaptureConfiguration={datacaptureConfiguration}
      />
      {/*<MetadataModal
        showModal={showMetadataModal}
        closeModal={handleCloseDetailModal}
        isEditing={isEditingMetadata}
        documents={docs}
        document={selectedDocument}
        menuId={selectedDocument && selectedDocument.menuId}
        updateAfterDelete={updateAfterDelete}
        simpleFlow={simpleFlow}
      />*/}
      <MetadataPreviewModal
        showModal={showMetadataModal}
        closeModal={handleCloseDetailModal}
        documents={docs}
        document={selectedDocument}
        updateAfterDelete={updateAfterDelete}
        simpleFlow={simpleFlow}
        datacaptureConfiguration={datacaptureConfiguration}
      />
      {/* <UploadModal
        docTypeSelected={docTypeSelected}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        simpleFlow={simpleFlow}
        datacaptureConfiguration={datacaptureConfiguration}
      /> */}
      <WarningModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
      <DigestModal
        showDigestModal={showDigestModal}
        handleCloseDigestModal={handleCloseDigestModal}
        reviewedDocs={reviewedDocs}
      />
    </>
  );
};
export default withNamespaces()(NonFinAutomatedDocumentTable);
