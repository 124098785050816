import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import iconSearchDefault from "../assets/img/icon-search-default.png";
import iconSearchDisabled from "../assets/img/icon-search-disabled.png";
import iconSearchFocus from "../assets/img/icon-search-focus.png";
import iconSearch from "../assets/img/icon-search.png";
import "../assets/scss/_inputSearch.scss";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/

const InputSearch = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar opção",
    disabled,
    text,
    name,
    countryList = false,
    position,
    initialSelected
  } = props;

  /*
        Sorts an array by name, or number (both ascending or descending)
    */
  const sortBy = {
    nameAscending: (prop) =>
      prop.sort((a, b) => a.label.localeCompare(b.label)),
    nameDescending: (prop) =>
      prop.sort((a, b) => b.label.localeCompare(a.label)),
    numberAscending: (prop) => prop.sort((a, b) => a - b),
    numberDescending: (prop) => prop.sort((a, b) => b - a),
    noOrder: (prop) => prop,
  };

  //Sorts list
  const sortedList = sortBy.nameAscending(list);

  //Translates labels
  const optionList = sortedList.map((i) => {
    return { label: t(i.label), value: i.value, selected: i.selected };
  });

  const [selectedValue, setSelectedValue] = useState("");
  const [inputSelected, setInputSelected] = useState(false);
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState([optionList]);
  const [icon, setIcon] = useState(disabled ? iconSearchDisabled : iconSearch);

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    const defaultItem = optionList.find((item) => item.selected === true);
    if (defaultItem) {
      setLabel(t(defaultItem.label));
      setSelectedValue(defaultItem.value);
    }
    if (initialSelected) {
      setLabel(initialSelected);
      setSelectedValue(initialSelected);
    }
  }, [list]);

  /*
        Type ahead feature where list gets refreshed when user is typing
        If there isn't any option correlated to input easypay-shows "data not found"
    */
  const typeFunction = (e) => {
    let input = document
      .getElementById(`formInput-${id.current}`)
      .value.toUpperCase()
      .trim();
    let currentId = parseInt(e.target.id.match(/\d+/)[0]);
    let nextInput = document.getElementById(`formInput-${currentId + 1}`);

    //Shifts to next input when the user presses enter key, also selects first element
    if (e.key === "Enter") {
      if (displayList.length > 0) {
        if (displayList[0].label === t("general.dataNotFound")) {
          setLabel("");
          setSelectedValue("");
        } else {
          setLabel(displayList[0].label);
          setSelectedValue(displayList[0].label);
        }
      }
      hideDropDown();
      //If there is a next input field focus it
      if (nextInput !== null) {
        nextInput.focus();
      }
      return;
    }

    /*
       Necessary condition to make drop down work even if input is selected by using "shift" key
    */
    if (
      !document
        .getElementById(`dropDown-${id.current}`)
        .classList.contains("liftworld-show")
    ) {
      document
        .getElementById(`dropDown-${id.current}`)
        .classList.add("liftworld-show");
    }

    /*
      Gets array of filtered options that contain the input
    */
    let filtered = optionList.filter((option) => {
      if (option.label.toUpperCase().includes(input)) {
        return input;
      }
    });

    /*
       Puts filtered option list in displayList variable
    */
    if (filtered.length > 0) {
      setDisplayList(filtered);
    } else if (
      document.getElementById(`formInput-${id.current}`).value.length > 0
    ) {
      setDisplayList([{ label: t("general.dataNotFound") }]);
    }

    //All options are easypay-shown when input is empty
    if (document.getElementById(`formInput-${id.current}`).value === "") {
      setDisplayList(optionList);
    }
  };

  /*
    Functions that runs when clicking any option
  */
  const handleSelect = (e) => {
    //Changes input to the selected option
    setSelectedValue(e.target.value);
    const element = optionList.find(
      (element) => element.value === e.target.value
    );

    if (element) {
      setLabel(element.label);
    }
    //Sorts list to display selected first
    sortListAfterSelect(optionList, e.target.value);
    //Hides dropdown after selecting any option
    hideDropDown();
    const defaultItem = optionList.find((item) => item.selected === true);

    if (!handleSubmit) {
      setSelectedValue(e.target.value);
      setLabel(e.target.label);
      return;
    }

    if (defaultItem) {
      if (defaultItem.value !== e.target.value) {
        handleSubmit(e.target.value);
      }
    } else {
      handleSubmit(e.target.value);
    }
  };

  /* 
    Sorts list to display selected element in first place. Also deletes it from the displayed list
  */
  const sortListAfterSelect = (list, selected) => {
    let newList = [];
    newList[0] = list.filter((e) => e.value == selected)[0];

    newList = newList.concat(list.filter((e) => e.value != selected));
    setDisplayList(newList);
    return newList;
  };

  /*
    Clears out the input whenever the option isn't in list
  */
  const handleClose = () => {
    const inputValue = document
      .getElementById(`formInput-${id.current}`)
      .value.trim();

    if (
      optionList.some(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      )
    ) {
      const option = optionList.find(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      );
      setSelectedValue(option.value);
      setLabel(option.label);
      if (!handleSubmit) {
        return;
      }
      handleSubmit(option.value);

      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem && defaultItem.value !== option.value) {
        handleSubmit(option.value);
      }
    } else {
      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem) {
        setSelectedValue(defaultItem.value ? defaultItem.value : "");
        setLabel(defaultItem.label ? defaultItem.label : "");
      } else {
        setSelectedValue("");
        setLabel("");
      }
    }
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected easypay-shows it highlighted
  */
  const toggleList = () => {
    document
      .getElementById(`dropDown-${id.current}`)
      .classList.toggle("liftworld-show");

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("liftworld-show")
    ) {
      let newList = sortListAfterSelect(optionList, selectedValue);

      if (newList[0] === undefined) {
        setDisplayList(optionList);
      } else {
        setDisplayList(sortListAfterSelect(optionList, selectedValue));
      }
    } else {
      handleClose();
    }
  };

  const hideDropDown = (desiredId) => {
    desiredId === undefined
      ? document
          .getElementById(`dropDown-${id.current}`)
          .classList.remove("liftworld-show")
      : document
          .getElementById(`dropDown-${desiredId}`)
          .classList.remove("liftworld-show");
  };

  /*
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = (e) => {
    handleClose();
    hideDropDown(parseInt(e.target.id.match(/\d+/)[0]));
  };

  return (
    <>
      <Form.Group controlId={`formText-field`} style = {{margin : "0px"}}>
        <div className={"liftworld-input-search-wrapper"}>
          <div className="liftworld-input-label">{t(text)}</div>
          <div
            className={`liftworld-input-search-field-wrapper ${
              label ? "liftworld-filled" : ""
            } ${inputSelected ? "liftworld-input-selected" : ""} ${position}`}
            disabled={disabled}
            tabIndex="1"
          >
            <input
              autoComplete="off"
              onFocus={() => {
                setIcon(iconSearchFocus);
                setInputSelected(true);
              }}
              className={`liftworld-input-search-field`}
              placeholder={t(placeholder)}
              id={`formInput-${id.current}`}
              onMouseDown={() => {
                toggleList();
              }}
              onKeyUp={(e) => typeFunction(e)}
              value={t(label)}
              required={required}
              onBlur={(e) => {
                outOfFocus(e);
                setIcon(iconSearchDefault);
                setInputSelected(false);
              }}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              name={name + "Label"}
            />
            <div className="liftworld-input-search-icon-wrapper">
              <img
                className="liftworld-input-search-icon"
                src={icon}
                alt="search-icon"
              />
            </div>
          </div>

          <div id={`dropDown-${id.current}`} className="liftworld-dropdown">
            {displayList.map((option, i) => {
              return option.label === t("general.dataNotFound") ? (
                <option id="liftworld-dropdown-no-result" key={i}>
                  {t("general.dataNotFound")}
                </option>
              ) : i === 0 ? (
                <div
                  className={
                    "liftworld-dropdown-option-wrapper liftworld-option-selected"
                  }
                >
                  <option
                    id={`selected-${id.current}`}
                    key={i}
                    onMouseDown={(e) => handleSelect(e)}
                    value={option.value}
                    className={`liftworld-dropdown-option liftworld-option-selected ${
                      countryList ? "liftworld-country-option" : ""
                    }`}
                  >
                    {option.label}
                  </option>
                </div>
              ) : (
                <div className={"liftworld-dropdown-option-wrapper"}>
                 <option
                    className={`liftworld-dropdown-option`}
                    key={i}
                    onMouseDown={(e) => handleSelect(e)}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                </div>
              );
            })}
          </div>
        </div>
        <input type="hidden" name={name} value={selectedValue} />
        <Form.Control.Feedback type="invalid">
          {`O campo field é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
export default withNamespaces()(InputSearch);
