import { takeLatest } from "redux-saga/effects";

import {
  GET_ALL_CATEGORIES,
  GET_MANUAL_CLAIM,
  GET_COMPLAINTS,
  EXPORT_CLAIMS,
  GET_ALL_RESULTS_CATEGORIES,
  GET_SUBCLAIMS,
  GET_DATA_RESULTS,
  GET_SUBDATA_RESULTS,
  GET_DATA_TOPS,
} from "../ducks/claimsManagement/actionTypes";

import {
  getAllCategories,
  getManualClaim,
  getComplaints,
  exportClaims,
  getAllResultsCategories,
  getDataResults,
  getSubDataResults,
  getDataTops,
  getSubClaims
} from "../ducks/claimsManagement/sagas";

export const claimsManagementSagas = [
  takeLatest(GET_ALL_CATEGORIES, getAllCategories),
  takeLatest(GET_MANUAL_CLAIM, getManualClaim),
  takeLatest(GET_COMPLAINTS, getComplaints),
  takeLatest(EXPORT_CLAIMS, exportClaims),
  takeLatest(GET_ALL_RESULTS_CATEGORIES, getAllResultsCategories),
  takeLatest(GET_DATA_RESULTS, getDataResults),
  takeLatest(GET_SUBDATA_RESULTS, getSubDataResults),
  takeLatest(GET_DATA_TOPS, getDataTops),
  takeLatest(GET_SUBCLAIMS, getSubClaims)
];
