import React, { useState, useEffect } from "react";
import { Card, Button, Form, Col, Table } from "react-bootstrap";
import "~/assets/css/icons.css";
import "~/assets/css/styles.css";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RoleTable from "~/components/role/RoleTable";
import { defaultApps } from "./defaultApps";
import { getRoles, clearRoles } from "~/pages/Admin/Role/actions";
import Dropdown from "~/components/Dropdown";
import { FaMinusCircle } from "react-icons/fa";

const PersistSource = ({ selectedSource, editSource, ...props }) => {
  const { t } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [sourceName, setSourceName] = useState(
    selectedSource ? selectedSource.name : ""
  );
  const [url, setUrl] = useState(selectedSource ? selectedSource.url : "");
  const [groupId, setGroupId] = useState(
    selectedSource ? selectedSource.url.split(",")[0] : ""
  );
  const [reportId, setReportId] = useState(
    selectedSource ? selectedSource.url.split(",")[1] : ""
  );
  const [isAuthentication, setisAuthentication] = useState(
    selectedSource ? selectedSource.authentication : ""
  );
  const [languages, setLanguages] = useState( selectedSource ? selectedSource?.languages :[]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [optLanguages, setoptLanguages] = useState([]);
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const { roles } = useSelector((state) => state.roleReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  useEffect(() => {
    dispatch(getRoles());
    return () => {
      dispatch(clearRoles());
    };
  }, [dispatch]);

  const languageOptions = [
    { label: "English", value: "en", error: false, selected: false },
    { label: "Spanish", value: "es", error: false, selected: false },
    { label: "Portuguese", value: "pt", error: false, selected: false },
  ];
  useEffect(()=>{
    setoptLanguages(createLanguageMapping(languageOptions, selectedSource));
  },[selectedSource, languages])

  useEffect(() => {
    if (selectedSource && selectedSource.roles) {
      const selectedRoleIds = selectedSource.roles;
      const selectedRoles_ = roles.filter((role) =>
        selectedRoleIds.some((id) => id === role.id)
      );
      const availableRoles_ = roles.filter(
        (role) => !selectedRoleIds.some((id) => id === role.id)
      );
      setSelectedRoles(selectedRoles_);
      setAvailableRoles(availableRoles_);
    } else {
      setAvailableRoles(roles);
    }
  }, [selectedSource, roles]);

  const [apps, setApps] = useState(defaultApps);
  const [isAllAppChosen, setIsAllAppChosen] = useState(false);

  useEffect(() => {
    if (selectedSource) {
      defaultApps.forEach((item) => (item.toggle = false)); //clear state
      let savedApps = defaultApps.map((item) => {
        if (
          selectedSource.apps.some(
            (app) => app.description === item.description
          )
        ) {
          item.toggle = true;
          return item;
        } else return item;
      });
      setApps(savedApps);
    }
  }, [selectedSource]);

  useEffect(() => {
    if (apps) {
      if (apps.some((app) => app.toggle === false)) setIsAllAppChosen(false);
      else setIsAllAppChosen(true);
    }
  }, [apps]);

  const toggleApp = (appname) => {
    let updatedApps = apps.map((item) => {
      if (item.description === appname) {
        item.toggle = !item.toggle;
        return item;
      } else return item;
    });
    setApps(updatedApps);
  };

  const toggleAll = () => {
    let toggledApps;
    if (isAllAppChosen) {
      toggledApps =
        apps &&
        apps.map((item) => {
          item.toggle = false;
          return item;
        });
    } else {
      toggledApps =
        apps &&
        apps.map((item) => {
          item.toggle = true;
          return item;
        });
    }
    setApps(toggledApps);
  };

  const toggleAuthentication = () => {
    setisAuthentication(!isAuthentication);
    setUrl("");
  };

  const getTableRows = () => {
    return (
      apps &&
      apps.map((item, index) => {
        return (
          <tr key={index + 1}>
            <th>{item.description}</th>
            <th>
              <Form.Check
                type="switch"
                id={index + 1}
                label=""
                checked={item.toggle}
                onChange={() => toggleApp(item.description)}
              />
            </th>
          </tr>
        );
      })
    );
  };

  const addRole = (role) => {
    setAvailableRoles((roles) => roles.filter((r) => r.id !== role.id));
    if (!selectedRoles.some((r) => r.id === role.id)) {
      let newRoles = [...selectedRoles, role];
      newRoles.sort((a, b) => a.id - b.id);
      setSelectedRoles(newRoles);
    }
  };

  const removeRole = (role) => {
    setSelectedRoles((roles) => roles.filter((r) => r.id !== role.id));
    if (!availableRoles.some((r) => r.id === role.id)) {
      let newRoles = [...availableRoles, role];
      newRoles.sort((a, b) => a.id - b.id);
      setAvailableRoles(newRoles);
    }
  };

  const [validated, setValidated] = useState(false);
  const [notequal, setnotequal] = useState(false);
  const [alreadyexistequal, setalreadyexistequal] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const languageApp = userSettings?.language
        ? userSettings.language.trim()
        : "en";
      if (languages != undefined) {
        const isSourceNameEqualToTranslation = languages.some(
          (lang) => lang.translation === sourceName
        );
        const languageExists = languages.some(
          (lang) => lang.language === languageApp
        );
        const hasEmptyLanguage = languages.some((lang) => !lang.language);
        
        if (hasEmptyLanguage) {
          setnotequal(true);
          return;
        }

        const hasDuplicateLanguage = languages.reduce(
          (acc, lang, index, arr) => {
            if (
              arr.findIndex((item) => item.language === lang.language) !== index
            ) {
              return true;
            }
            return acc;
          },
          false
        );

        if (hasDuplicateLanguage) {
          setalreadyexistequal(true);
          return;
        }

        // if (!languageExists) {
        //   const newLanguageEntry = {
        //     language: languageApp,
        //     translation: sourceName,
        //     error: false,
        //   };
        //   const updatedLanguages = [...languages, newLanguageEntry];
        //   const newConfiguration = createNewConfiguration(
        //     sourceName,
        //     updatedLanguages
        //   );
        //   editSource(newConfiguration);
        // } else {
            const newConfiguration = createNewConfiguration(
              sourceName,
              languages
            );
            editSource(newConfiguration);
          //}
        //}
      } else {
        const newLanguageEntry = {
          language: languageApp,
          translation: sourceName,
          error: false,
        };
         const newConfiguration = createNewConfiguration(
          sourceName,
          newLanguageEntry
        );
        editSource(newConfiguration);
      }
    }
  };

  const createNewConfiguration = (sourceName, languages) => {
    let newConfiguration = {
      url: isAuthentication ? `${groupId},${reportId}` : url,
      name: sourceName,
      authentication: isAuthentication,
      apps: apps.filter((app) => app.toggle === true),
      languages: languages,
    };
    if (selectedRoles.length > 0) {
      newConfiguration.roles = selectedRoles.map((role) => role.id);
    }
    const hasDashboard = newConfiguration.apps.some(
      (app) => app.description === "Dashboard"
    );
    let numberOfDashboards =
      configuration?.dashboard && configuration?.dashboard != 0
        ? configuration.dashboard
        : 10;
    if (hasDashboard) {
      for (let i = 1; i <= numberOfDashboards; i++) {
        newConfiguration.apps.push({
          description: `Dashboard ${i}`,
          pathname: `/uniksystem/dashboard/page/${i}`,
          toggle: true,
        });
      }
    } else {
      newConfiguration.apps = newConfiguration.apps.filter(
        (app) => !app.description.includes("Dashboard")
      );
    }

    return newConfiguration;
  };

  const backToList = () => {
    history.push("/uniksystem/admin/widgetsettings/ServicoGenericoWidget");
  };

  const handleAddLanguage = () => {
    setnotequal(false);
    setalreadyexistequal(false);
    setLanguages([...languages, { language: "", translation: "" }]);
  };

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
    setalreadyexistequal(false)
    setnotequal(false)
  };

  const handleLanguageChange = (index, language) => {
    const updatedLanguages = [...languages];
    const alreadyexistthisLang = updatedLanguages.some(
      (lang) => lang.language === language
    );
    if(alreadyexistthisLang){
      return
    }
    updatedLanguages[index].language = language;
    const languagenotExists = updatedLanguages.some(
      (lang) => lang.language === language
    );
    const languageExists = updatedLanguages.some(
      (lang, i) => i !== index && lang.language === language
    );
    if (!languagenotExists) {
      setnotequal(true);
    } else {
      setnotequal(false);
    }
    if (languageExists) {
      setalreadyexistequal(true);
    } else {
      setalreadyexistequal(false);
    }
    setLanguages(updatedLanguages);
  };

  const handleTranslationChange = (index, translation) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index].translation = translation;
    setLanguages(updatedLanguages);
  };

  const handleChangeName = (e) => {
    setSourceName(e.target.value);
  };
  
  function createLanguageMapping(languageOptions, backendData) {
    let languageMapping = [];
  
    if (backendData?.languages == undefined || backendData == undefined) {
      languageMapping = [...languageOptions];
    } else {
      backendData.languages.forEach((backendItem) => {
        const matchingOption = languageOptions.find((option) => option.value === backendItem.language);
        if (matchingOption) {
          languageMapping.push({
            label: matchingOption.label,
            value: matchingOption.value,
            error: matchingOption.error,
            selected: true,
          });
        }
      });
    }
    languageOptions.forEach((option) => {
      const isAlreadyAdded = languageMapping.some((mapping) => mapping.value === option.value);
      if (!isAlreadyAdded) {
        languageMapping.push({
          label: option.label,
          value: option.value,
          error: option.error,
          selected: false,
        });
      }
    });
    return languageMapping;
  }
  
  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        method="post"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Card.Body>
          <Form.Row style={{ alignItems: "center" }}>
            <Col>
              <Form.Group controlId="sourcename">
                <Form.Label>{t("ServicoGenericoWidget.sourceName")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("ServicoGenericoWidget.typeSource")}
                  value={sourceName}
                  onChange={(e) => handleChangeName(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("ServicoGenericoWidget.validSource")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Button variant="secondary" onClick={handleAddLanguage}>
                Add Language
              </Button>
            </Col>
          </Form.Row>
          <Form.Row>
            {notequal ? (
              <div style={{ color: "#dc3545" }}>Linguagem em falta</div>
            ) : (
              ""
            )}
            {alreadyexistequal ? (
              <div style={{ color: "#dc3545" }}>Linguagem ja existente</div>
            ) : (
              ""
            )}
          </Form.Row>
          {languages &&
            languages.length > 0 &&
            languages.map((language, index) => (
              <Form.Row style={{ alignItems: "center" }}>
                <Col key={index} lg="1">
                  <FaMinusCircle
                    style={{ fill: "#dc3545" }}
                    onClick={() => handleRemoveLanguage(index)}
                  />
                </Col>
                <Col lg="2">
                  <Form.Group controlId={`language${index}`}>
                    <Form.Label>Language</Form.Label>
                      <Dropdown
                        list={optLanguages}
                        handleSubmit={(e) => handleLanguageChange(index, e)}
                        emptyDefault={true}
                        lang={language}
                      />
                  </Form.Group>
                </Col>
                <Col lg="9">
                  <Form.Group controlId={`translation${index}`}>
                    <Form.Label>Translation</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter translation"
                      value={language.translation}
                      className={language.error ? "invalid" : ""}
                      onChange={(e) =>
                        handleTranslationChange(index, e.target.value)
                      }
                      required
                      onInput={(e) => setnotequal(false)}
                    />
                    {language.error ? (
                      <div style={{ color: "#dc3545" }}>
                        Nomes diferentes ou sem linguagem
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
              </Form.Row>
            ))}
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Autenticação"
              checked={isAuthentication}
              onChange={() => toggleAuthentication()}
            />
          </Form.Group>

          {isAuthentication ? (
            <Form.Row>
              <Col>
                <Form.Group controlId="url">
                  <Form.Label>Group Id</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={"Group Id"}
                    required
                    value={groupId}
                    onChange={(e) => setGroupId(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("ServicoGenericoWidget.validURL")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="url">
                  <Form.Label>Report Id</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={"Report Id"}
                    required
                    value={reportId}
                    onChange={(e) => setReportId(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("ServicoGenericoWidget.validURL")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          ) : (
            <Form.Row>
              <Col>
                <Form.Group controlId="url">
                  <Form.Label>{t("general.URL")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("ServicoGenericoWidget.typeURL")}
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("ServicoGenericoWidget.validURL")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          )}

          <Form.Group controlId="formBasicCheckbox1">
            <Form.Check
              type="checkbox"
              label={t("ServicoGenericoWidget.chooseAll")}
              checked={isAllAppChosen}
              onChange={() => toggleAll()}
            />
          </Form.Group>

          <Table id="user-table" striped bordered hover>
            <thead>
              <tr>
                <th>{t("ServicoGenericoWidget.application")}</th>
                <th>{t("ServicoGenericoWidget.access")}</th>
              </tr>
            </thead>
            <tbody>{getTableRows()}</tbody>
          </Table>

          <Form.Row>
            <Col sm="12" md="12" lg="6">
              <Form.Group controlId="label">
                <Form.Label>{t("admin.role.list.availableRoles")}</Form.Label>
                <RoleTable
                  roles={availableRoles}
                  action={addRole}
                  actionIcon="icon-add"
                />
              </Form.Group>
            </Col>

            <Col sm="12" md="12" lg="6">
              <Form.Group controlId="label">
                <Form.Label>{t("admin.role.list.selectedRoles")}</Form.Label>
                <RoleTable
                  roles={selectedRoles}
                  action={removeRole}
                  actionIcon="icon-trash"
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Card.Body>
        <Card.Footer>
          <Button variant="danger" onClick={() => backToList()}>
            {t("general.back")}
          </Button>
          <Button
            type="submit"
            className="card-button"
            style={{ marginLeft: "10px", marginRight: "55px" }}
          >
            {t("general.save")}
          </Button>
        </Card.Footer>
      </Form>
    </>
  );
};

export default withNamespaces()(PersistSource);
