import React, { useEffect } from "react";

const IframeHandler = ({ name }) => {
  useEffect(() => {
    if (name && document.getElementById(name)) {
      /*Display iframe*/
      document.getElementById(name).style.display = "block";
    }
  }, [name, window.location.href]);
  return <></>;
};
export default IframeHandler;
