import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { Modal, Button } from "react-bootstrap";
import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { toLocaleDateString } from "~/utils";
import { buildExpiredLicensePeriod } from "../utils/index";

import {
  deleteOrganization,
  getOrganizations,
} from "~/store/ducks/organizations/actionTypes";

const OrganizationTable = ({ organizations, t, persistOrganization }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(undefined);

  const { isLoadingOrganizations, isDeleting } = useSelector(
    (state) => state.organizationsReducer
  );

  const buildActionsColumn = (_, row, {}) => {
    return row && row.label !== "uniksystem" ? (
      <>
        <i
          className="icon-trash table-action-icon"
          onClick={() => openModal(row)}
        />
        <i
          className="icon-edit table-action-icon"
          onClick={() =>
            history.push(`/uniksystem/admin/organization/update/${row.id}`)
          }
        />
      </>
    ) : (
      <i
        className="icon-edit table-action-icon"
        onClick={() =>
          history.push(`/uniksystem/admin/organization/update/${row.id}`)
        }
      />
    );
  };

  const messageFromLicensePeriod = (_, row, {}) => {
    if (row && row.end_at && row.label !== "uniksystem") {
      const result = buildExpiredLicensePeriod(row.end_at);
      if (result) {
        if (result.message) {
          if (result.message === 1) {
            return t("admin.organization.license.lastDay");
          }
          if (result.message === 2) {
            return t("admin.organization.license.expired");
          }
        }
        if (result.days || result.months || result.years) {
          return `${
            result.years === ""
              ? ""
              : result.years > 1
              ? `${result.years} ${t("admin.organization.license.years")}`
              : `${result.years} ${t("admin.organization.license.year")}`
          }${
            result.months === ""
              ? ""
              : result.months > 1
              ? ` ` +
                `${result.months} ${t("admin.organization.license.months")}`
              : ` ` +
                `${result.months} ${t("admin.organization.license.month")}`
          }${
            result.days === ""
              ? ""
              : result.days > 1
              ? ` ` + `${result.days} ${t("admin.organization.license.days")}`
              : ` ` + `${result.days} ${t("admin.organization.license.day")}`
          }`;
        }
      }
    } else return "";
  };

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "description",
      text: t("admin.permission.list.description"),
      sort: true,
    },
    {
      dataField: "start_at",
      text: t("admin.organization.list.startAt"),
      formatter: (_, row, {}) => {
        if (row.start_at !== null && row.start_at !== undefined) {
          return row && row.label === "uniksystem"
            ? null
            : toLocaleDateString(row.start_at);
        }
      },
      sort: true,
    },
    {
      dataField: "end_at",
      text: t("admin.organization.list.endAt"),
      formatter: (_, row, {}) => {
        if (row.end_at !== null && row.end_at !== undefined) {
          return row && row.label === "uniksystem"
            ? null
            : toLocaleDateString(row.end_at);
        }
      },
      sort: true,
    },
    {
      dataField: "expired",
      text: t("admin.organization.list.expired"),
      formatter: messageFromLicensePeriod,
    },
    {
      dataField: "accao",
      text: t("admin.organization.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const openModal = (row) => {
    setSelectedOrganization(row);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const _deleteOrganization = () => {
    dispatch(deleteOrganization(selectedOrganization.id));
    handleClose();
  };

  return (
    <>
      <div className="custom-table custom-table-small">
        <ToolkitProvider
          keyField="id"
          data={organizations}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ display: "flex" }}>
                <SearchBar
                  {...props.searchProps}
                  placeholder={t("admin.organization.list.searchInput")}
                />
                <div style={{ paddingLeft: "30px" }}>
                  <ButtonAdd
                    text={t("general.add")}
                    onClick={persistOrganization}
                  />
                </div>
              </div>
              <Spinner spinning={isLoadingOrganizations} />
              {!isLoadingOrganizations && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("admin.organization.list.emptyTable")}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Spinner spinning={isDeleting} wrapper />
      <Messages afterHideSuccess={() => dispatch(getOrganizations())} />

      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {selectedOrganization !== undefined ? (
          <Modal.Body>
            {`${t("general.remove")}: ${selectedOrganization.description}?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button
            className="card-button"
            variant="primary"
            onClick={handleClose}
          >
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => _deleteOrganization()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(OrganizationTable);
