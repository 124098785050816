import React from "react";
import uuid from "uuid/v1";
import Accordion from "~/pages/Easypay/components/Accordion";
import CountrySelected from "~/pages/Easypay/components/CountrySelected";
import RadioButtonWrapper from "~/pages/Easypay/components/RadioButtonWrapper";
import TabButtonWrapper from "~/pages/Easypay/components/TabButtonWrapper";
import TabWrapper from "~/pages/Easypay/components/TabWrapper";
import Text from "~/pages/Easypay/components/Text";
import ToggleAccordion from "~/pages/Easypay/components/ToggleAccordion";
import ToggleCertidao from "~/pages/Easypay/components/ToggleCertidao";
import VerticalNavWrapper from "~/pages/Easypay/components/VerticalNavWrapper";
const devLogConsole = require("~/utils/devLog");
const CreateWrapper = ({ type, tabs, variant, setShowCountryModal, activeTab,setActiveTab }) => {
  function getWrapperType() {
    switch (type) {
      case "vertical-nav":
        devLogConsole(tabs);
        return <VerticalNavWrapper activeTab = {activeTab} setActiveTab = {setActiveTab}>{tabs}</VerticalNavWrapper>;
      case "accordion":
        return <Accordion list={tabs[0]}></Accordion>;
      case "tab":
        return <TabWrapper variant={variant}>{tabs}</TabWrapper>;
      case "radio-button":
        return (
          <RadioButtonWrapper key={uuid()} variant={variant}>
            {tabs}
          </RadioButtonWrapper>
        );
      case "toggle-accordion":
        return <ToggleAccordion list={tabs[0]}></ToggleAccordion>;
      case "card":
        return (
          <div key={uuid()} className="easypay-card">
            {tabs}
          </div>
        );
      case "country":
        return (
          <div>
            <Text
              text={tabs[0]?.tab?.blockdivision?.columndivision?.field[0]?.text}
              align={
                tabs[0]?.tab?.blockdivision?.columndivision?.field[0]?.align
              }
            />
            <CountrySelected
              icon={
                tabs[0]?.tab?.blockdivision?.columndivision?.field[1]?.value
              }
              label={
                tabs[0]?.tab?.blockdivision?.columndivision?.field[2]?.value
              }
              setShowCountryModal={setShowCountryModal}
            />
          </div>
        );
      case "modal-country":
        return <div key={uuid()}>{tabs}</div>;
      case "modal-add-repleg":
        return <div key={uuid()}>{tabs}</div>;
      case "modal-add-benef":
        return <div key={uuid()}>{tabs}</div>;
      case "modal-edit-organizacao":
        return <div key={uuid()}>{tabs}</div>;
      case "modal-approved-account":
        devLogConsole(tabs);
        return <div key={uuid()}>{tabs}</div>;
      case "modal-edit-negocio":
        return <div key={uuid()}>{tabs}</div>;
      case "toggle-certidão-permanente":
        return <ToggleCertidao>{tabs}</ToggleCertidao>;
      default:
        return <TabButtonWrapper>{tabs}</TabButtonWrapper>;
    }
  }

  return getWrapperType();
};
export default CreateWrapper;
