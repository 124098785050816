import React from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Form, Col, Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import {
  adaptListForDocType,
  adaptListForOperations
} from "~pages/PortalRH/components/DocumentManagement/handleDropdownList";

const DeleteModal = (props) => {
  const {
    //Specific modal functionalities
    selectedEmailTemplate,
    showDeleteModal,
    closeDeleteModal,
    HandleDeleteEmailTemplate,
    //Email template data
    operations,
    selectedOperationId,
    t,
  } = props;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  return (
    <>
      <Modal show={showDeleteModal} onHide={closeDeleteModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="default-modal-title">{t("general.remove") + " " + t("portalrh.emailTemplate.templateID") + " " + selectedEmailTemplate?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Col sm={3}>
              <Form.Label style={{ marginTop: "7px" }} className="default-label">
                {t("portalrh.emailTemplate.operation")} {asterisk}
              </Form.Label>
            </Col>
            <Col sm={9}>
              <Dropdown
                list={adaptListForOperations(
                  operations,
                  t("portalrh.documentManagement.selectOperationPlaceholder")
                )}
                defaultValue={selectedEmailTemplate?.operation}
                disabled
                readOnly
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={3}>
              <Form.Label style={{ marginTop: "7px" }} className="default-label">
                {t("portalrh.emailTemplate.docType")} {asterisk}
              </Form.Label>
            </Col>
            <Col sm={9}>
              <Dropdown
                list={adaptListForDocType(
                  operations, ((selectedOperationId) ? selectedOperationId : selectedEmailTemplate?.operationId),
                  t("portalrh.documentManagement.selectOperationPlaceholder")
                )}
                defaultValue={selectedEmailTemplate?.docType}
                disabled
                readOnly
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label>
              {t("portalrh.emailTemplate.subject")}
            </Form.Label>
            <Form.Control
              type="text"
              value={selectedEmailTemplate ? selectedEmailTemplate?.subject : ""}
              disabled
            />
          </Form.Row>
          <Form.Row>
            <Form.Label>
              {t("portalrh.emailTemplate.message")} {asterisk}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={selectedEmailTemplate ? selectedEmailTemplate?.message : ""}
              disabled
            />
            <Form.Control.Feedback type="invalid">
              {t("admin.userManagement.persist.requiredUsername")}
            </Form.Control.Feedback>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <p style={{color: "black", textAlign: "left", flex: 1 }}>{t("portalrh.emailTemplate.removeWarning")}</p>
          <Button variant="secondary" className="btn" onClick={closeDeleteModal}>
            {t("general.cancel")}
          </Button>
          <Button variant="danger" className="btn" onClick={() => HandleDeleteEmailTemplate(selectedEmailTemplate?.id)}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default withNamespaces()(DeleteModal);