import React from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

const WarningModal = ({
  t,
  showDeleteModal,
  handleDelete,
  handleCloseDeleteModal,
  handleCloseParentModal,
  handleShowParentModal,
  message,
}) => {
  return (
    <Modal
      show={showDeleteModal}
      backdrop="static"
      dialogClassName="custom-modal"
      onHide={() => handleCloseDeleteModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("messages.warning")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px" }}>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            handleCloseDeleteModal(false);
            if (handleShowParentModal) {
              handleShowParentModal(true);
            }
          }}
        >
          {t("general.no")}
        </Button>
        <Button
          className="modal-submit-button"
          onClick={() => {
            if (handleDelete) {
              handleDelete();
            }
            handleCloseDeleteModal(false);
            if (handleCloseParentModal) {
              handleCloseParentModal(true);
            }
            if (handleShowParentModal) {
              handleShowParentModal(true);
            }
          }}
        >
          {t("general.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default withNamespaces()(WarningModal);
