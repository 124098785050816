import { find, some } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, Redirect} from "react-router-dom";
import { withNamespaces } from "react-i18next";
import AllIframesComponent from "~/components//AllIframesComponent";
import IframeHandler from "~/components/IframeHandler";
import Processes from "~/components/Processes";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";
import ProtectedPage from "~/components/security/ProtectedPage";
import Tasks from "~/components/Tasks";
import WidgetContainer from "~/components/WidgetContainer";
import ContentContainer from "~/containers/ContentContainer";
import { addLegacyPermissions } from "~/containers/Layout/actions";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import {
  addItemsToMenu,
  getMenusByApplication,
} from "~/store/ducks/applicationMenu/actionTypes";
import { findLegacyPermissions } from "~/store/ducks/legacyPermission/actionTypes";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { ROLE_ADMIN } from "~/utils/constants/roles";
import Delegacoes from "~/components/Delegacoes";
const devLogConsole = require("~/utils/devLog");
const BPM = (props) => {
  let { path } = useRouteMatch();
  const { t, isMongoOn, styleSheet } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { legacyPermissions } = useSelector(
    (state) => state.legacyPermissionReducer
  );
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );
  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      username &&
        dispatch(getMenusByApplication(username, BPM_MIDDLEWARE.context));
    }
  }, [user, dispatch]);

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.bpm.processos" });
    let items = [];
    let submenus;
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      submenus = extraConfiguration_.submenus
        ? extraConfiguration_.submenus
        : null;
      if (submenus) {
        Object.keys(submenus).forEach((key) => {
          //check if submenu has children
          let hasChildren = false;
          if (submenus[key].length == 0) hasChildren = false;
          else {
            submenus[key].every((flowid) => {
              const res = rawMenus.every((rm) => {
                //find flowid
                if (rm.url) {
                  let arr = rm.url.split("flowid=");
                  const flowid_ = arr[1];
                  if (flowid_ == flowid) {
                    hasChildren = true;
                    return false;
                  }
                  return true;
                }
                return true;
              });
              return res;
            });
          }
          if (hasChildren) {
            items.push({
              id: key,
              name: key,
              submenu: true,
              route: `${BPM_MIDDLEWARE.context}/processos/submenu/${key}`,
              menuParent: processosMenu ? processosMenu.id : null,
            });
          }
        });
      }
      const getProcessosMenuId = () =>
        processosMenu ? processosMenu.id : null;
      const getSubMenuParentId = (url) => {
        if (url) {
          let arr = url.split("flowid=");
          const flowId = arr[1];
          let id = getProcessosMenuId();
          Object.keys(submenus).forEach((key) => {
            if (submenus[key].some((i) => i == flowId)) {
              id = key;
            }
          });
          return id;
        } else return getProcessosMenuId();
      };
      rawMenus.forEach((item) => {
        items.push({
          name: item.label,
          url: item.url,
          route: `${BPM_MIDDLEWARE.context}/processos/form/${item.label}`,
          menuParent: submenus
            ? getSubMenuParentId(item.url)
            : getProcessosMenuId(),
        });
      });
      dispatch(addItemsToMenu(items));
    }

    const delegacoesMenu = find(menu, { name: "menu.bpm.delegacoes" });
    let items_ = [];
    const sidebarOptions = [
      { text: "bpm.delegacoes.menu.manage", route: "gerir" },
      { text: "bpm.delegacoes.menu.define", route: "requisitar" },
      { text: "bpm.delegacoes.menu.other", route: "reatribuir" },
    ];

    devLogConsole(user)

    if (delegacoesMenu) {
      sidebarOptions.forEach((option, index) => {
        items_.push({
          id: index,
          name: option.text,
          submenu: false,
          route: `${BPM_MIDDLEWARE.context}/delegacoes/${option.route}`,
          menuParent: delegacoesMenu ? delegacoesMenu.id : null,
          permissions:
            option.route === "reatribuir"
              ? [
                  {
                    id: 30,
                    description: "BPM Menu Delegações",
                    label: "app-bpm-menu-admin",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                ]
              : [],
        });
      });

      //only adds items with unique route
      dispatch(addItemsToMenu(items_));
    }
  }, [rawMenus, menu, dispatch]);

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      username && dispatch(findLegacyPermissions(username, BPM_MIDDLEWARE));
    }
  }, [dispatch, user.legacyUsers]);

  useEffect(() => {
    if (!some(user.roles, { label: ROLE_ADMIN }))
      dispatch(addLegacyPermissions(legacyPermissions, "BPMUser"));
  }, [dispatch, legacyPermissions]);

  /* Used to preLoad menu Tarefas*/
  useEffect(() => {
    function handleSUEvent(e) {
      devLogConsole(e.detail);
      devLogConsole("constructing menu...");
    }

    function handleWaitAppResponse() {
      devLogConsole("constructing menu from response...");
    }
    window.addEventListener("SUEvent", handleSUEvent);
    window.addEventListener("WaitAppResponse", handleWaitAppResponse);
  }, []);

  useEffect(() => {
    if (document.getElementById("bpm")) {
      if (document.getElementById("bpm").contentWindow) {
        document
          .getElementById("bpm")
          .contentWindow.dispatchEvent(
            new CustomEvent("GetAppResponse", { detail: "from: bpm" })
          );
        devLogConsole("after disptaching GetAppResponse to iframe bpm");
      }
    }
  });

  return (
    <>
      <ApplicationMenu
        applicationName={BPM_MIDDLEWARE.context}
        showMenu={true}
      />
      <AllIframesComponent name="bpm" />
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/tarefas`} />
        </Route>
        <Route exact path={`${path}/processos`}>
          <ProtectedPage
            roles={["ADMIN", "BPMUser"]}
            permissions={[{ label: "app-bpm-menu-processos" }]}
          >
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/submenu`}>
          <ProtectedPage
            roles={["ADMIN", "BPMUser"]}
            permissions={[{ label: "app-bpm-menu-processos" }]}
          >
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`}>
          <ProtectedPage
            roles={["ADMIN", "BPMUser"]}
            permissions={[{ label: "app-bpm-menu-processos" }]}
          >
            <ProcessosFormHandler
              app={BPM_MIDDLEWARE.context}
              className="main-card-v2"
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/pesquisar`}>
          <ProtectedPage
            roles={["ADMIN", "BPMUser"]}
            permissions={[{ label: "app-bpm-menu-pesquisa" }]}
          >
            <ContentContainer>
              <Processes app={"bpm"} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/delegacoes`}>
          <ProtectedPage
            roles={["ADMIN", "BPMUser"]}
            permissions={[{ label: "app-bpm-menu-delegacoes" }]}
          >
            <Delegacoes />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/admin`}>
          <ProtectedPage
            roles={["ADMIN", "BPMUser"]}
            permissions={[{ label: "app-bpm-menu-admin" }]}
          >
            <IframeHandler name="bpm" />
          </ProtectedPage>
        </Route>

        <Route exact path={path}>
          <ProtectedPage roles={["ADMIN", "BPMUser"]}>
            <>
              {isMongoOn && (
                <ContentContainer>
                  <WidgetContainer />
                </ContentContainer>
              )}
            </>
          </ProtectedPage>
        </Route>

        <Route path={`${path}/tarefas*`}>
          <ContentContainer>
            <Tasks
              app={"bpm"}
              className="main-card-v2"
              styleSheet={styleSheet}
            />
          </ContentContainer>
        </Route>
      </Switch>
    </>
  );
};
export default withNamespaces()(BPM);

/*
"submenus": {
  "menu1": [284,292],
  "menu2": [86,84]
}
*/
