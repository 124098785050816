import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import "~/assets/css/styles.css";
import Spinner from "~/components/Spinner";
import WarningModal from "~/components/WarningModal/WarningModal";
import {
  acceptDuplicate,
  deleteDocument,
  getDocument,
  savePoNumber,
} from "~/pages/DataCapture/actions";
import { toLocaleString } from "~/utils";

const MetadataPreviewModal = (props) => {
  const {
    t,
    showModal,
    closeModal,
    document,
    documents,
    updateAfterDelete,
    simpleFlow,
    datacaptureConfiguration,
  } = props;

  const dispatch = useDispatch();

  const { metadata, isLoadingMetadata, previewDocument, foundDocument } =
    useSelector((state) => state.dataCaptureReducer);
  const [showAlert, setShowAlert] = useState(false);
  //const [originalDoc, setOriginalDoc] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMetadataModal, setShowMetadataModal] = useState(true);
  const [refreshBatches, setRefreshBatches] = useState(false);
  const [ordernumberText, setordernumberText] = useState("");
  const [blobToShow, setBlobtoShow] = useState(undefined);

  useEffect(() => {
    if (
      document?.isDuplicated !== "" &&
      document?.isDuplicated !== false &&
      document?.isDuplicated !== "Duplicate Accepted" &&
      simpleFlow
    ) {
      const doc = findOriginal(documents);
      if (doc && doc.id) {
        //setOriginalDoc(doc);
        dispatch(getDocument(doc.id));
        setShowAlert(true);
      }
    } else {
      setShowAlert(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (metadata != undefined)
      if (metadata.order_number != null && metadata.order_number.length > 0)
        setordernumberText(metadata.order_number[0].value);
      else setordernumberText("");

    setBlobtoShow(
      generatePdfView(previewDocument ? previewDocument : document)
    );
  }, [metadata]);

  const handlerSavePoNumber = () => {
    if (
      document.metadata.order_number != undefined ||
      document.metadata.order_number != null
    )
      document.metadata.order_number[0].value = ordernumberText;
    else {
      document.metadata.order_number = [];
      document.metadata.order_number.push({ value: ordernumberText });
    }
    if (ordernumberText == "") return;
    dispatch(savePoNumber(document));
  };

  const nullValueFormatter = (value, text) => (value ? value : text);

  const handleClickDeleteDuplicate = () => {
    handleShowMetadataModal(false);
    setShowDeleteModal(true);
  };

  const handleClickKeepDuplicate = () => {
    dispatch(acceptDuplicate(document));
    setShowAlert(false);
    setRefreshBatches(true);
  };

  const handleDeleteDocument = () => {
    setShowAlert(false);
    dispatch(deleteDocument(document.id));
    setRefreshBatches(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowMetadataModal = (boolean) => {
    setShowMetadataModal(boolean);
  };

  const generateImageView = (doc) =>
    doc ? "data:image/jpg;base64, " + doc.data : "";
  const generatePdfView = (doc) => {
    if (doc && doc.data !== "") {
      function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1];
        try {
          var bstr = atob(arr[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        } catch (e) {
          return new Blob(["No data found"], { type: "text/html" });
        }
      }
      //convert to blob because of Data protocol URL size limitations
      let blob = dataURLtoBlob("data:application/pdf;base64, " + doc.data);
      const temp_url = window.URL.createObjectURL(blob);
      return temp_url;
    }
  };

  const findOriginal = (docs) => {
    return docs.find(
      (doc) =>
        document?.isDuplicated === doc?.document_id && doc?.isDuplicated === ""
    );
  };

  const getLabel = (value, parentLabel) => {
    if (datacaptureConfiguration && document) {
      const foundConfig = datacaptureConfiguration.find(
        (config) => config.documentType === document.batchFlow
      );
      if (datacaptureConfiguration && foundConfig && foundConfig.metadata) {
        if (parentLabel) {
          const foundNestedMetadata = foundConfig.metadata.find(
            (d) => d.value === parentLabel
          );
          if (foundNestedMetadata && foundNestedMetadata.children) {
            const foundChild = foundNestedMetadata.children.find(
              (d) => d.value === value
            );
            if (value == "value") return;
            return foundChild
              ? foundChild.label
              : t(`datacapture.metadata.` + value);
          } else return value;
        } else {
          const foundMetadata = foundConfig.metadata.find(
            (d) => d.value === value
          );
          if (foundMetadata) {
            if (foundMetadata.value == "order_number") return;
            return t(`datacapture.metadata.` + foundMetadata.value);
          } else return value;
        }
      }
    }
    return value;
  };
  const renderMetadata = () => {
    return (
      metadata &&
      Object.keys(metadata).length > 0 &&
      Object.keys(metadata).map((key, index) => {
        let dados = [];
        const createItem = (label, value, index, parentLabel) => {
          if (label != "value")
            return (
              <>
                <div key={index} className="adatacapture-metadata-item-row">
                  <div className="adatacapture-metadata-item-label">
                    <strong style={{ whiteSpace: "nowrap" }}>
                      {getLabel(label, parentLabel)}
                    </strong>
                  </div>
                  <div className="adatacapture-metadata-item-value">
                    <div>
                      {Array.isArray(value) &&
                      value.length > 0 &&
                      !value[0].value ? (
                        <div className="adatacapture-metadata-nesteditems">
                          {value.map((item, i) => {
                            return (
                              <div
                                className="adatacapture-metadata-nesteditem"
                                key={i}
                              >
                                {Object.keys(item).map((key, i_) => {
                                  return createItem(key, item[key], i_, label);
                                })}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        typeof value !== "object" && (
                          <small>{nullValueFormatter(value, "")}</small>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
        };
        metadata[key] && dados.push(createItem(key, metadata[key], index));
        return dados;
      })
    );
  };

  return (
    <>
      {showMetadataModal && (
        <Modal
          dialogClassName="modal-datacapture-detail"
          show={showModal}
          onHide={() => {
            closeModal();
            refreshBatches && updateAfterDelete();
          }}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {/*capitalize(t("datacapture.general.invoice"))*/}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!isLoadingMetadata && (
              <>
                {document?.isDuplicated !== "" &&
                  document?.isDuplicated !== "Duplicate Accepted" && (
                    <Alert show={showAlert} variant="warning">
                      <Alert.Heading>
                        This document might be duplicated
                      </Alert.Heading>
                      <p>
                        Please confirm if the document is duplicated, in that
                        case click the 'Delete Document' button.
                      </p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={() => handleClickDeleteDuplicate(document)}
                          variant="danger"
                        >
                          Delete Duplicate Document
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() => handleClickKeepDuplicate()}
                          variant="warning"
                        >
                          Keep Document. Not Duplicate
                        </Button>
                      </div>
                    </Alert>
                  )}
                {!showAlert && (
                  <Row>
                    <>
                      <Col lg="5">
                        <Row>
                          <Col sm="6" style={{ minWidth: "fit-content" }}>
                            {document && (
                              <h4>
                                {` ${
                                  t("datacapture.general.status") +
                                  " " +
                                  t(`datacapture.batch_status.${document.statusV1}`)
                                }`}
                              </h4>
                            )}
                            {document && (
                              <small>
                                {t("datacapture.metadata.uploaded_at")} &nbsp;
                                {`${toLocaleString(document.createdDate)}`}
                              </small>
                            )}
                          </Col>
                        </Row>
                        <hr />
                        {metadata != undefined &&
                          Object.keys(metadata).length > 0 && (
                            <div className="adatacapture-metadata-item-row">
                              <div
                                className="adatacapture-metadata-item-label"
                                style={{ margin: "0px" }}
                              >
                                <strong style={{ whiteSpace: "nowrap" }}>
                                  {t("datacapture.metadata.order_number")}
                                </strong>
                              </div>
                              <div className="adatacapture-metadata-item-value">
                                <Form.Control
                                  type="text"
                                  value={ordernumberText}
                                  onChange={(e) =>
                                    setordernumberText(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        {renderMetadata()}
                      </Col>

                      <Col lg="7">
                        {document &&
                          document.filetype === "application/pdf" && (
                            <>
                              <iframe
                                id="pdf-document"
                                title="pdf-document"
                                src={blobToShow}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  minHeight: "50vh",
                                }}
                              />
                            </>
                          )}
                        {document &&
                          document.filetype !== "application/pdf" && (
                            <img
                              src={generateImageView(
                                previewDocument ? previewDocument : document
                              )}
                              className="document-preview"
                              alt="document-preview"
                            />
                          )}
                      </Col>
                    </>
                  </Row>
                )}
                {showAlert && (
                  <div style={{ display: "flex", minHeight: "50vh" }}>
                    <Col lg="6" style={{ paddingLeft: "0" }}>
                      <>
                        <span>
                          <h3 style={{ textAlign: "center" }}>
                            Possible Duplicate
                          </h3>
                        </span>
                        <iframe
                          id="pdf-document"
                          title="pdf-document"
                          src={blobToShow}
                          style={{ height: "90%", width: "100%" }}
                        />
                      </>

                      {document && document.filetype !== "application/pdf" && (
                        <img
                          src={generateImageView(previewDocument)}
                          className="document-preview"
                          alt="document-preview"
                        />
                      )}
                    </Col>
                    <Col lg="6" style={{ paddingRight: "0" }}>
                      {document && document.filetype === "application/pdf" && (
                        <>
                          <span>
                            <h3 style={{ textAlign: "center" }}>Original</h3>
                          </span>

                          <iframe
                            id="pdf-document"
                            title="pdf-document"
                            src={blobToShow}
                            style={{ height: "90%", width: "100%" }}
                          />
                        </>
                      )}
                      {document && document.filetype !== "application/pdf" && (
                        <img
                          src={generateImageView(foundDocument)}
                          className="document-preview"
                          alt="document-preview"
                        />
                      )}
                    </Col>
                  </div>
                )}
              </>
            )}
            <Spinner spinning={isLoadingMetadata} />
          </Modal.Body>

          <Modal.Footer>
            {metadata != undefined && Object.keys(metadata).length > 0 && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  handlerSavePoNumber();
                }}
              >
                {t("general.save")}
              </Button>
            )}
            <Button
              variant="danger"
              onClick={() => {
                closeModal();
                refreshBatches && updateAfterDelete();
              }}
            >
              {t("general.close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <WarningModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDeleteDocument}
        handleCloseDeleteModal={handleCloseDeleteModal}
        message={
          "This will delete permantly the possible duplicate document. Do you confirm?"
        }
        handleShowParentModal={handleShowMetadataModal}
      />
    </>
  );
};

export default withNamespaces()(MetadataPreviewModal);
