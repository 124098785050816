import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import { TabsKey } from "./utils/TabsEnum";
import UsageReport from "./UsageReport";
import { findUsageReport } from "~/store/ducks/reports/actionTypes";
import ProtectedPage from "~/components/security/ProtectedPage";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const Report = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const [key, setKey] = useState(TabsKey.USAGE_REPORT);

  useEffect(() => {
    dispatch(findUsageReport());
  }, []);

  return (
    <ProtectedPage roles={[ROLE_ADMIN, ROLE_ORG_ADMIN]}>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-gear mr-2" />
              {t("menu.admin.report")}
            </h6>
          </Card.Header>
          <Card.Body>
            <Tabs
              id="adminSettingsTab"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="custom-tabs"
            >
              <Tab
                eventKey={TabsKey.USAGE_REPORT}
                title={t("admin.report.usageReport")}
              >
                <br />
                <UsageReport />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(Report);
