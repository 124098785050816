import { put } from 'redux-saga/effects';
import http from '../../../../utils/config/http';
import {
  setBaseWidgets,
  setUpdated,
  setPuzzleItems,
  setPuzzleItemsByOrgId,
  
  setConfiguration,
  setAuthModes
} from '../actions';

import {
  activeSuccess
} from '../../../../components/Messages/actions';
const devLogConsole = require("~/utils/devLog");
export function* getBaseWidgets() {
  try {
    const resp = yield http.get(`/api/mongo/widget/find/all`);
    yield put(setBaseWidgets(resp.data));
  } catch (e) {
  }
}

export function* createBaseWidget({ widget }) {
  try {
    yield put(setUpdated(false));
    yield http.post('/api/mongo/widget/save', widget);
    yield put(setUpdated(true));
  } catch (e) {
    yield put(setUpdated(false));
  }
}

export function* updateBaseWidget({ widget }) {
  try {
    yield put(setUpdated(false));
    yield http.put('/api/mongo/widget/update', widget);
    yield put(setUpdated(true));
  } catch (e) {
    yield put(setUpdated(false));
  }
}

export function* deleteBaseWidget({ id }) {
  try {
    yield put(setUpdated(false));
    yield http.delete(`/api/mongo/widget/delete/${id}`);
    yield put(setUpdated(true));
  } catch (e) {
    yield put(setUpdated(false));
  }
}


export function* getPuzzleItems() {
  try {
    const resp = yield http.get(`/api/puzzle/find/all`);
    yield put(setPuzzleItems(resp.data));
  } catch (e) {
  }
}

export function* getPuzzleItemsByOrgId({ organizationId }) {
  try {
    const resp = yield http.get(`/api/puzzle/admin/${organizationId}`);
    yield put(setPuzzleItemsByOrgId(resp.data));
  } catch (e) {
  }
}

export function* savePuzzleItems({ data, organizationId }) {
  try {
    const resp = yield http.post(`/api/puzzle/save/${organizationId}`, data);
    yield put(setPuzzleItems(resp.data));
    yield put(activeSuccess("admin.puzzle.successMessage"))
    //devLogConsole(resp);
  } catch (e) {
  }
}


export function* requestConfiguration() {
  try {
    const resp = yield http.get('/api/open/uniksystem-configuration');
    const data = { configuration: resp.data, success: true }
    yield put(setConfiguration(data));
  } catch (e) {
    const data = { configuration: null, success: false }
    yield put(setConfiguration(data));
  }
}

export function* getAuthModes() {
  try {
    const resp = yield http.get(`/api/auth-domain/find/all`);
    yield put(setAuthModes(resp.data));
  } catch (e) {
  }
}

export function* updateConfiguration({ configuration }) {
  yield put(setUpdated(false))
  try {
    yield http.put(`/api/uniksystem-configuration/update/${configuration.autoKeycloak === undefined ? 0 : configuration.autoKeycloak}/${configuration.autoWindows === undefined ? 0 : configuration.autoWindows}`, configuration);
    yield put(setUpdated(true));
  } catch (e) {
    yield put(setUpdated(false))
  }
}
