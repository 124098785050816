import React from "react";

const RhRecibos = ()=>{
return (
    <>
    recibos
    </>
)
}

export default RhRecibos;