import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_tabSeparator.scss";

const TabSeparator = ({
  name,
  text,
  setTheActiveTab,
  activeTab,
  tabsNumber,
  redirect,
  variant,
  disabled,
  t,
}) => {
  return (
    <>
      <div
        className={`easypay-tab-separator ${
          name === activeTab ? "easypay-active-tab" : "easypay-unactive-tab"
        }`}
      >
        <button
          id={name}
          className={`${(disabled ? "liftworld-tab-wrapper-disabled": variant === "liftworld-tab-wrapper"
            ? `liftworld-tab-wrapper`
            : "easypay-tab-button")} ${
            name === activeTab && "easypay-active"
          } ${tabsNumber > 2 ? "easypay-separator" : ""}`}
          onClick={disabled ? "" : () => {
            redirect
              ? (window.location.href = window.location.origin + redirect)
              : setTheActiveTab(name);
          }}
          type="button"
        >
          {t(text)}
        </button>{" "}
      </div>
    </>
  );
};

export default withNamespaces()(TabSeparator);
