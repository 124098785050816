import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getColorByStatus, timeConversion } from "~/components/Tasks/utils";
import warning_alert from "~/assets/img/icons/alert_amber.png";
import danger_alert from "~/assets/img/icons/alert_red.png";
import { toLocaleString } from "~/utils";

export const getColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  const buildFlowInitialColumn = (row, rowIndex) => {
    const abbreviateInitials = (row, initials, rowIndex, numLetters) => {
      const spantext = () => {
        if (initials) {
          if (initials.length >= numLetters)
            return initials.slice(0, numLetters);
          else {
            return initials;
          }
        } else {
          if (row && row.flowname && row.flowname.length > 2) {
            const words = row.flowname.split(" ");
            if (words.length == 1) {
              return row.flowname.slice(0, 2);
            } else {
              if (words.length == 2 && words[1].length > 3) {
                return words[0].slice(0, 1) + words[1].slice(0, 1);
              } else if (words.length > 2) {
                return words[0].slice(0, 1) + words[2].slice(0, 1);
              }
            }
          } else return row.flowname.slice(0, numLetters);
        }
      };
      return (
        <OverlayTrigger
          key={rowIndex}
          placement="right"
          overlay={
            <Tooltip
              id={`tooltip-${rowIndex}`}
              style={{
                display: row && row?.flowname ? "block" : "none",
              }}
            >
              {row?.flowname}
            </Tooltip>
          }
        >
          <span>{spantext()}</span>
        </OverlayTrigger>
      );
    };

    return (
      <div className="tasks-pnumber-wrapper">
        <div
          className="tasks-table-badge task-badge-bc"
          style={{
          }}
        >
          <div className="tasks-badge-content">
            {abbreviateInitials(row, row?.initials, rowIndex, 3)}
          </div>
        </div>
      </div>
    );
  };

  const buildStatusColumn = () => {
    if (
      row.metadata &&
      row.metadata.some((item) => item.name === "PROCESS_STATUS")
    ) {
      const status = row.metadata.find(
        (item) => item.name === "PROCESS_STATUS"
      ).value;
      if (status) {
        const variant = getColorByStatus(status);
        //const translatedStatus = translateStatus(status);
        return (
          <Badge pill variant={variant}>
            {status.toUpperCase()}
          </Badge>
        );
      }
    }
    return (
      <Badge pill variant="warning">
        INDETERMINADO
      </Badge>
    );
  };

  const buildSLAColumn = () => {
    if (row.SLA) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2);
        const month = dateStr.slice(2, 4);
        const year = dateStr.slice(4, 8);
        const hour = dateStr.slice(8, 10);
        const min = dateStr.slice(10, 12);
        const sec = dateStr.slice(12, 14);
        return new Date(year, month - 1, day, hour, min, sec);
      };
      const date = toDate(row.SLA);
      return date ? date.toLocaleString() : "";
    }
    return "";
  };

  const buildTTLColumn = () => {
    if (row.TTL) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2);
        const month = dateStr.slice(2, 4);
        const year = dateStr.slice(4, 8);
        const hour = dateStr.slice(8, 10);
        const min = dateStr.slice(10, 12);
        const sec = dateStr.slice(12, 14);
        return new Date(year, month - 1, day, hour, min, sec);
      };
      const date = toDate(row.TTL);
      if (date) {
        const diff = Date.now() - date;
        let variant = "info";
        if (diff > 0) /*86400000 1 day in milliseconds*/ variant = "danger";
        if (diff > -86400000 && diff < 0) variant = "warning";
        return (
          <Badge pill variant={variant}>
            <div
              style={{
                width: "70px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                padding: "0.3em",
              }}
            >
              {date.toLocaleString()}
            </div>
          </Badge>
        );
      } else return "";
    }
    return "";
  };

  const buildTTLColumnDemo = () => {
    if (row.created) {
      const toDate = () => {
        let myDate = new Date(row.created);
        return myDate.setDate(myDate.getDate() + 1);
      };
      const TTLdate = toDate();
      if (TTLdate) {
        const diff = Date.now() - TTLdate;
        let variant = "info";
        if (diff > 0) variant = "danger";
        if (diff > -3600000 * 4 && diff < 0)
          /*86400000 1 day in milliseconds, 3600000 1 hour in milliseconds*/
          variant = "warning";
        const renderTTLAlert = () => {
          if (variant === "danger") {
            return (
              <div
                style={{
                  width: "85px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {toLocaleString(TTLdate)}
                <img
                  src={danger_alert}
                  height="20"
                  alt=""
                  style={{ marginLeft: "0.3em" }}
                />
              </div>
            );
          } else if (variant === "warning") {
            return (
              <div
                style={{
                  width: "85px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {toLocaleString(TTLdate)}
                <img
                  src={warning_alert}
                  height="20"
                  alt=""
                  style={{ marginLeft: "0.3em" }}
                />
              </div>
            );
          } else
            return (
              <div
                style={{
                  width: "85px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {toLocaleString(TTLdate)}
              </div>
            );
        };
        return renderTTLAlert();
      } else return "";
    }
    return "";
  };

  const abbreviateTextColumn = (text, rowIndex, numLetters, t) => {
    let textToShow = "";
    if (text === "Process closed" && t != undefined) {
      textToShow = t("general.closedProcess");
    } else if (text === "ProcDetail" && t != undefined) {
      textToShow = t("general.detailProc");
    } else if (text === "Comment" && t != undefined) {
      textToShow = t("general.commentProc");
    }  else {
      textToShow = text;
    }
    return (
      <OverlayTrigger
        key={rowIndex}
        placement="right"
        overlay={<Tooltip id={`tooltip-${rowIndex}`}>{text}</Tooltip>}
      >
        <span>
          {textToShow && textToShow.length > numLetters
            ? textToShow.slice(0, numLetters - 1) + "..."
            : textToShow}
        </span>
      </OverlayTrigger>
    );
  };

  const buildPendingColumn = () => {
    if (row.created) {
      const millis = Date.now() - new Date(row.created);
      return timeConversion(millis);
    }
    return "";
  };

  if (formatExtraData.value === "PROCESS_STATUS") {
    return buildStatusColumn();
  }
  if (formatExtraData.value === "SLA") {
    return buildSLAColumn();
  }
  if (formatExtraData.value === "TTL") {
    //return buildTTLColumn();
    return buildTTLColumnDemo();
  }
  if (formatExtraData.value === "description") {
    return abbreviateTextColumn(
      row.description,
      rowIndex,
      80,
      formatExtraData?.t
    );
  }
  if (formatExtraData.value === "pnumber") {
    return abbreviateTextColumn(row.pnumber, rowIndex, 25);
  }
  if (formatExtraData.value === "pendingPeriod") {
    return buildPendingColumn();
  }
  if (formatExtraData.value === "initials") {
    return buildFlowInitialColumn(row, rowIndex);
  } else return row[formatExtraData.value];
};
