import React from "react";
import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const SheduleTable = (props) => {
  const {
    logs,
    isLoadingLogs,
    t,
  } = props;

  //table and toolkit creation
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "errorMessage",
      text: t("portalrh.exportSchedule.logsTable.errorMessage"),
      sort: true,
      headerStyle: () => {
        return { width: "35%" };
      },
    },
    {
      dataField: "fileErrorLine",
      text: t("portalrh.exportSchedule.logsTable.fileErrorLine"),
      sort: true,
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "date",
      text: t("portalrh.exportSchedule.logsTable.date"),
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "fileName",
      text: t("portalrh.exportSchedule.logsTable.fileName"),
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];

  return (
    <div className="custom-table custom-table-small cursor-pointer">
      <ToolkitProvider
        keyField="id"
        data={logs}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <div style={{ paddingTop: "10px", display: "flex" }}>
              <h6 className="default-title" style={{ paddingRight: "20px" }}>
                {t("Logs")}
              </h6>
              <SearchBar {...props.searchProps} placeholder={t("Search")} />
            </div>
            {isLoadingLogs ? <Spinner spinning={isLoadingLogs} /> :
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("portalrh.exportSchedule.logsTable.noLogsAvailable")}
                striped
                hover
              />
            }
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(SheduleTable);