import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import axios from "axios";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

import {
  setUDWSettings,
  setUDWUsers,
  setLegacyUsers,
  setEmailTemplates,
  setEmailTemplateById,
  setCountEmailTemplates,
  stopEditingEmailTemplates,
  setSSOInfo,
} from "./actionTypes";

import { activeError, activeSuccess } from "~/components/Messages/actions";
import { el } from "date-fns/locale";
const devLogConsole = require("~/utils/devLog");
export function* findUDWSettings() {
  try {
    const resp = yield http.get("/api/open/info");
    yield put(setUDWSettings(resp.data));
  } catch (e) {
    yield put(activeError());
  }
}

export function* findUDWUsers({ legacyUsers }) {
  try {
    const resp = yield http.post("/api/legacy-user/find-users", legacyUsers);
    yield put(setUDWUsers(resp.data));
  } catch (e) {
    /*
    const { response } = e;
    if (response?.status === 412) {
      yield put(
        activeError(
          "Existem mapeamentos duplicados para utilizadores. Não foi possivel obter os dados para campo 'Enviado por'. Os dados apresentados são pertencentes a aplicação BPM."
        )
      );
    } else yield put(activeError("Ocorreu um erro"));
    */
  }
}

export function* findLegacyUsers({ username }) {
  try {
    const resp = yield http.get(`api/user/find-by-username/${username}`);
    yield put(setLegacyUsers(resp.data.legacyUsers));
  } catch (e) {
    yield put(activeError("Utilizador não existe"));
  }
}

export function* getEmbedUrl({ token }) {
  try {
    const resp = yield axios.get(
      "https://api.powerbi.com/v1.0/myorg/groups/5f1fafbe-9811-4312-a81f-37b6ede16450/reports/dadec391-0ef6-45cb-8046-91e41c7e44a4",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    yield put(activeError(e));
  }
}

export function* loginADFS({ payload }) {
  try {
    const resp = yield http.post("api/open/auth/adfs/signin/getadfs", payload);
    let req = resp.data;
    const newWindow = window.open(req, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
    devLogConsole(newWindow);
  } catch (e) {
    devLogConsole(devLogConsole(e));
  }
}

export function* easyPaySSO({ payload }) {
  devLogConsole("CAME TO EASYPAY SSO");
  try {
    const json = JSON.parse(JSON.stringify(payload));
    const locale = json.locale;
    const resp = yield http.get("api/open/onboarding/sso?locale=" + locale);
    const req = resp.data;
    devLogConsole(resp.data);
    yield put(setSSOInfo(req));
  } catch (e) {
    devLogConsole(e);
    yield put(setSSOInfo({ name: "TESTE" }));
    yield put(activeError(e));
  }
}

//EMAIL TEMPLATES ###########################################
export function* getEmailTemplates({ appLabel, t }) {
  try {
    const resp1 = yield http.get(`api/email-template/all/${appLabel}`);
    let resp2 = null;
    switch (appLabel) {
      case PORTALRH_MIDDLEWARE.context:
        resp2 = yield http.get(`api/email-message/all`, PORTALRH_MIDDLEWARE);
        resp2.data.map(
          (el) => (el.operation = t("portalrh.operations." + el.operation))
        );
    }

    let result = [];
    for (var i = 0; i < resp1.data.length; i++) {
      result.push({ ...resp1.data[i], ...resp2.data[i] });
    }

    yield put(setEmailTemplates(result));
  } catch (e) {
    yield put(setEmailTemplates([]));
    // yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getEmailTemplateById({ emailTemplateId, appLabel, t }) {
  try {
    const resp1 = yield http.get(`api/email-template/${emailTemplateId}`);

    let resp2 = null;
    switch (appLabel) {
      case PORTALRH_MIDDLEWARE.context:
        resp2 = yield http.get(
          `api/email-message/${emailTemplateId}`,
          PORTALRH_MIDDLEWARE
        );
        resp2.data.operation = t("portalrh.operations." + el.operation);
    }

    const result = { ...resp1.data, ...resp2.data };

    yield put(setEmailTemplateById(result));
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* countEmailTemplates() {
  try {
    const resp = yield http.get(`api/email-template/count`);
    yield put(setCountEmailTemplates(resp.data));
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* createEmailTemplate({ udwData, appData, appLabel }) {
  try {
    switch (appLabel) {
      case PORTALRH_MIDDLEWARE.context:
        yield http.post(`api/email-message/`, appData, PORTALRH_MIDDLEWARE);
    }
    yield http.post(`api/email-template/`, udwData);

    yield put(stopEditingEmailTemplates());
    yield put(
      activeSuccess(
        "portalrh.emailTemplate.warnnings.templateCreatedSuccessfully"
      )
    );
  } catch (e) {
    yield put(stopEditingEmailTemplates());
    yield put(
      activeError("portalrh.emailTemplate.warnnings.errorCreatingTemplate")
    );
  }
}

export function* updateEmailTemplate({ udwData, appData, appLabel }) {
  try {
    switch (appLabel) {
      case PORTALRH_MIDDLEWARE.context:
        yield http.put(`api/email-message/`, appData, PORTALRH_MIDDLEWARE);
    }
    yield http.put(`api/email-template/`, udwData);

    yield put(stopEditingEmailTemplates());
    yield put(
      activeSuccess(
        "portalrh.emailTemplate.warnnings.templateUpdatedSuccessfully"
      )
    );
  } catch (e) {
    yield put(stopEditingEmailTemplates());
    yield put(
      activeError("portalrh.emailTemplate.warnnings.errorUpdatingTemplate ")
    );
  }
}

export function* deleteEmailTemplate({ emailTemplateId, appLabel }) {
  try {
    switch (appLabel) {
      case PORTALRH_MIDDLEWARE.context:
        yield http.delete(
          `api/email-message/${emailTemplateId}`,
          PORTALRH_MIDDLEWARE
        );
    }
    yield http.delete(`api/email-template/${emailTemplateId}`);

    yield put(stopEditingEmailTemplates());
    yield put(
      activeSuccess(
        "portalrh.emailTemplate.warnnings.templateDeletedSuccessfully"
      )
    );
  } catch (e) {
    yield put(stopEditingEmailTemplates());
    yield put(
      activeError("portalrh.emailTemplate.warnnings.errorDeletingTemplate")
    );
  }
}

export function* sendEmail({ email, files, msgVariables }) {
  try {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    data.append(
      "email",
      new Blob([JSON.stringify(email)], { type: "application/json" })
    );

    data.append(
      "msgVariables",
      new Blob([JSON.stringify(msgVariables)], { type: "application/json" })
    );

    yield http.post("api/email-template/send-email", data);

    yield put(
      activeSuccess("portalrh.emailTemplate.warnnings.emailsSentSuccessfully")
    );
  } catch (e) {
    yield put(activeError("portalrh.emailTemplate.warnnings.errorEmailsSent"));
  }
}
