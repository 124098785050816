import React from "react";
import { startOf, add } from "date-arithmetic";
import { navigate } from "react-big-calendar/lib/utils/constants";
import YearCalendarComponent from "./Calendario/YearCalendarComponent";

function Year({
  date,
  events,
  setAllEvents,
  setAllEventsTemp,
  t,
  isEditable,
  singleDaySelection,
  allEventsTemp,
}) {
  const firstMonth = startOf(date, "year");
  const singleDayValue = singleDaySelection === "single";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const months = Array.from({ length: 12 }, (_, i) => (
    <YearCalendarComponent
      key={i + 1}
      date={add(firstMonth, i, "month")}
      events={events}
      setAllEvents={setAllEvents}
      setAllEventsTemp={setAllEventsTemp}
      isEditable={isEditable}
      singleDaySelection={singleDayValue}
      t={t}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      allEventsTemp={allEventsTemp}
    />
  ));

  return <div className="year scrollBar-visible-year">{months}</div>;
}

Year.range = (date) => {
  return [startOf(date, "year")];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, "year");

    case navigate.NEXT:
      return add(date, 1, "year");

    default:
      return date;
  }
};

Year.title = (date, { localizer }) =>
  localizer.format(date, "yearHeaderFormat");

export default Year;
