import React, { useEffect } from "react";
import oauth from "~/utils/oauth";
import uuid from "uuid/v1";
import { useSelector } from "react-redux";

const ProcessosIframe = (props) => {
  const { className, urlFormData } = props;

  const style = {
    frameborder: "0px",
    width: "100%",
    border: "none",
    display: "block",
    margin: className ? 0 : "10px",
  };
  const { currentFormUrl } = useSelector((state) => state.globalReducer);
  const [url, setUrl] = React.useState("");

  const token = oauth.getAccessToken();
  const authtoken = "&Authorization=" + token;

  useEffect(() => {
    if (url === "") {
      let urlToSet = urlFormData || currentFormUrl;
      if (urlToSet.includes("/iFlow/")) {
        const startIndex = urlToSet.indexOf("/iFlow/");
        urlToSet = "/iFlow/" + urlToSet.substring(startIndex + 7);
      } else {
        urlToSet = "/iFlow/" + urlToSet;
      }
      setUrl(urlToSet + authtoken);
    }
  }, [urlFormData]);

  return (
    <>
      {url !== "" && (
        <iframe
          key={uuid()}
          id="processos"
          title="processos"
          src={url}
          style={style}
        />
      )}
    </>
  );
};
export default ProcessosIframe;
