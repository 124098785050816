import {
  SET_ADDRESS,
  SET_PERSONAL_DATA,
  SET_PERSONALDATADROPDOWN,
  UPDATE_PERSONAL_DATA,
  DONE_UPDATE_PERSONAL_DATA,
  SET_ACADEMICQUALIFICATIONS,
  SET_ACADEMICDROPDOWN,
  UPDATE_ACADEMICQUALIFICATIONS,
  DONE_UPDATE_ACADEMICQUALIFICATIONS,
  SET_TAXDATA,
  SET_TAXDATA_DROPDOWN,
  UPDATE_TAXDATA,
  DONE_UPDATE_TAXDATA,
  SET_HOUSEHOLD,
  SET_HOUSEHOLD_DROPDOWN,
  UPDATE_HOUSEHOLD,
  DONE_UPDATE_HOUSEHOLD,
  SET_PAYMENTSDATA,
  SET_PAYMENTS_DROPDOWN,
  UPDATE_PAYMENTSDATA,
  DONE_UPDATE_PAYMENTSDATA,
  SET_TRAINING,
  SET_TRAINING_DROPDOWN,
  UPDATE_TRAINING,
  DONE_UPDATE_TRAINING,
  SET_PER_EV_FORM,
  SET_PER_EV_FORM_DROPDOWN,
  UPDATE_PER_EV_FORM,
  DONE_UPDATE_PER_EV_FORM,
  SET_MEDICALRECORD,
  UPDATE_MEDICALRECORD,
  DONE_UPDATE_MEDICALRECORD,
  GET_PERSONAL_DATA,
  UPDATE_ADDRESS,
  DONE_UPDATE_ADDRESS,
  CREATE_PERSONAL_DATA,
  DONE_CREATE_PERSONAL_DATA,
  CREATE_ADDRESS,
  DONE_CREATE_ADDRESS,
  CREATE_ACADEMICQUALIFICATIONS,
  DONE_CREATE_ACADEMICQUALIFICATIONS,
  CREATE_TAXDATA,
  DONE_CREATE_TAXDATA,
  CREATE_HOUSEHOLD,
  DONE_CREATE_HOUSEHOLD,
  CREATE_PAYMENTSDATA,
  DONE_CREATE_PAYMENTSDATA,
  CREATE_TRAINING,
  DONE_CREATE_TRAINING,
  CREATE_PER_EV_FORM,
  DONE_CREATE_PER_EV_FORM,
  CREATE_MEDICALRECORD,
  DONE_CREATE_MEDICALRECORD,
  SET_HOUSEHOLD_AGFCODE_LAST,
  SET_PERSONALDATA_ERROR,
  SET_COLLABORATOR_DATA,
  UPDATE_NOT_MAIN_ADDRESS,
  DONE_UPDATE_NOT_MAIN_ADDRESS,
  UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS,
  DONE_UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS,
} from "./actionTypes";

const initialState = {
  collaboratorData: [],
  personalData: [],
  addressData: [],
  notAddressMainData: [],
  personalDataDropdown: [],
  academicQualifications: [],
  notMainAcademicQualifications: [],
  academicDropdown: [],
  taxData: [],
  taxDataDropdown: [],
  houseHold: [],
  houseHoldAgfCode: "",
  houseHoldDropdown: [],
  paymentsData: [],
  paymentsDropdown: [],
  trainingData: [],
  trainingDropdown: [],
  perEvFormData: [],
  perEvFormDropdown: [],
  medicalData: [],
  isLoadingCollaboratorsRh: false,
  isLoadingUpdateAddress: false,
  isLoadingUpdateNotAddressMain: false,
  isLoadingUpdatePersonalData: false,
  isLoadingUpdateAcademicQualifications: false,
  isLoadingUpdateNotMainAcademicQualifications: false,
  isLoadingUpdateTaxData: false,
  isLoadingUpdateHousehold: false,
  isLoadingUpdatePaymentsData: false,
  isLoadingUpdateTraining: false,
  isLoadingUpdatePerEvForm: false,
  isLoadingUpdateMedicalRecord: false,

  personalDataError: false,
};

export const personalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLABORATOR_DATA:
      return {
        ...state,
        collaboratorData: action.data,
      };
    case GET_PERSONAL_DATA:
      return {
        ...state,
        isLoadingCollaboratorsRh: true,
      };
    case SET_PERSONAL_DATA:
      return {
        ...state,
        isLoadingCollaboratorsRh: false,
        personalData: action.data,
      };
    case UPDATE_PERSONAL_DATA:
      return {
        ...state,
        isLoadingUpdatePersonalData: true,
      };
    case DONE_UPDATE_PERSONAL_DATA:
      return {
        ...state,
        personalData: action.data,
        isLoadingUpdatePersonalData: false,
      };

    case CREATE_PERSONAL_DATA:
      return {
        ...state,
        isLoadingUpdatePersonalData: true,
      };
    case DONE_CREATE_PERSONAL_DATA:
      return {
        ...state,
        personalData: action.data,
        isLoadingUpdatePersonalData: false,
      };

    case SET_PERSONALDATA_ERROR:
      return {
        ...state,
        personalDataError: action.boollean,
      };

    case SET_ADDRESS:
      return { ...state, addressData: action.data };

    case UPDATE_ADDRESS:
      return {
        ...state,
        isLoadingUpdateAddress: true,
      };
    case DONE_UPDATE_ADDRESS:
      return {
        ...state,
        isLoadingUpdateAddress: false,
        addressData: action.data,
      };

    case UPDATE_NOT_MAIN_ADDRESS:
      return {
        ...state,
        isLoadingUpdateNotAddressMain: true,
      };
    case DONE_UPDATE_NOT_MAIN_ADDRESS:
      return {
        ...state,
        isLoadingUpdateNotAddressMain: false,
        notAddressMainData: action.data,
      };

    case CREATE_ADDRESS:
      return {
        ...state,
        isLoadingUpdateAddress: true,
      };
    case DONE_CREATE_ADDRESS:
      return {
        ...state,
        isLoadingUpdateAddress: false,
        addressData: action.data,
      };

    case SET_PERSONALDATADROPDOWN:
      return { ...state, personalDataDropdown: action.data };
    case SET_ACADEMICQUALIFICATIONS:
      return { ...state, academicQualifications: action.data };
    case SET_ACADEMICDROPDOWN:
      return { ...state, academicDropdown: action.data };
    case UPDATE_ACADEMICQUALIFICATIONS:
      return {
        ...state,
        academicQualifications: action.data,
        isLoadingUpdateAcademicQualifications: true,
      };
    case DONE_UPDATE_ACADEMICQUALIFICATIONS:
      return {
        ...state,

        isLoadingUpdateAcademicQualifications: false,
      };

    case UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS:
      return {
        ...state,
        notMainAcademicQualifications: action.data,
        isLoadingUpdateNotMainAcademicQualifications: true,
      };
    case DONE_UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS:
      return {
        ...state,

        isLoadingUpdateNotMainAcademicQualifications: false,
      };

    case CREATE_ACADEMICQUALIFICATIONS:
      return {
        ...state,
        isLoadingUpdateAcademicQualifications: true,
      };
    case DONE_CREATE_ACADEMICQUALIFICATIONS:
      return {
        ...state,
        academicQualifications: action.data,
        isLoadingUpdateAcademicQualifications: false,
      };
    case SET_TAXDATA:
      return { ...state, taxData: action.data };
    case SET_TAXDATA_DROPDOWN:
      return { ...state, taxDataDropdown: action.data };
    case UPDATE_TAXDATA:
      return {
        ...state,
        isLoadingUpdateTaxData: true,
      };
    case DONE_UPDATE_TAXDATA:
      return { ...state, taxData: action.data, isLoadingUpdateTaxData: false };

    case CREATE_TAXDATA:
      return {
        ...state,
        isLoadingUpdateTaxData: true,
      };
    case DONE_CREATE_TAXDATA:
      return { ...state, taxData: action.data, isLoadingUpdateTaxData: false };
    case SET_HOUSEHOLD:
      return { ...state, houseHold: action.data };
    case SET_HOUSEHOLD_DROPDOWN:
      return { ...state, houseHoldDropdown: action.data };
    case UPDATE_HOUSEHOLD:
      return {
        ...state,
        isLoadingUpdateHousehold: true,
      };
    case DONE_UPDATE_HOUSEHOLD:
      return {
        ...state,
        houseHold: action.data,
        isLoadingUpdateHousehold: false,
      };

    case CREATE_HOUSEHOLD:
      return {
        ...state,
        isLoadingUpdateHousehold: true,
      };
    case DONE_CREATE_HOUSEHOLD:
      return {
        ...state,
        houseHold: action.data,
        isLoadingUpdateHousehold: false,
      };
    case SET_HOUSEHOLD_AGFCODE_LAST:
      return { ...state, houseHoldAgfCode: action.data };
    case SET_PAYMENTSDATA:
      return { ...state, paymentsData: action.data };
    case SET_PAYMENTS_DROPDOWN:
      return { ...state, paymentsDropdown: action.data };
    case UPDATE_PAYMENTSDATA:
      return {
        ...state,
        isLoadingUpdatePaymentsData: true,
      };
    case DONE_UPDATE_PAYMENTSDATA:
      return {
        ...state,
        paymentsData: action.data,
        isLoadingUpdatePaymentsData: false,
      };

    case CREATE_PAYMENTSDATA:
      return {
        ...state,
        isLoadingUpdatePaymentsData: true,
      };
    case DONE_CREATE_PAYMENTSDATA:
      return {
        ...state,
        paymentsData: action.data,
        isLoadingUpdatePaymentsData: false,
      };
    case SET_TRAINING:
      return { ...state, trainingData: action.data };
    case SET_TRAINING_DROPDOWN:
      return { ...state, trainingDropdown: action.data };
    case UPDATE_TRAINING:
      return {
        ...state,
        isLoadingUpdateTraining: true,
      };
    case DONE_UPDATE_TRAINING:
      return {
        ...state,
        trainingData: action.data,
        isLoadingUpdateTraining: false,
      };
    case CREATE_TRAINING:
      return {
        ...state,
        isLoadingUpdateTraining: true,
      };
    case DONE_CREATE_TRAINING:
      return {
        ...state,
        trainingData: action.data,
        isLoadingUpdateTraining: false,
      };
    case SET_PER_EV_FORM:
      return { ...state, perEvFormData: action.data };
    case SET_PER_EV_FORM_DROPDOWN:
      return { ...state, perEvFormDropdown: action.data };
    case UPDATE_PER_EV_FORM:
      return {
        ...state,
        isLoadingUpdatePerEvForm: true,
      };
    case DONE_UPDATE_PER_EV_FORM:
      return {
        ...state,
        perEvFormData: action.data,
        isLoadingUpdatePerEvForm: false,
      };
    case CREATE_PER_EV_FORM:
      return {
        ...state,
        isLoadingUpdatePerEvForm: true,
      };
    case DONE_CREATE_PER_EV_FORM:
      return {
        ...state,
        perEvFormData: action.data,
        isLoadingUpdatePerEvForm: false,
      };
    case SET_MEDICALRECORD:
      return { ...state, medicalData: action.data };

    case UPDATE_MEDICALRECORD:
      return {
        ...state,
        isLoadingUpdateMedicalRecord: true,
      };
    case DONE_UPDATE_MEDICALRECORD:
      return {
        ...state,
        medicalData: action.data,
        isLoadingUpdateMedicalRecord: false,
      };
    case CREATE_MEDICALRECORD:
      return {
        ...state,
        isLoadingUpdateMedicalRecord: true,
      };
    case DONE_CREATE_MEDICALRECORD:
      return {
        ...state,
        medicalData: action.data,
        isLoadingUpdateMedicalRecord: false,
      };

    default:
      return state;
  }
};
