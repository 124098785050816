import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import Dropdown from "~/components/Dropdown";
import { findBatchsByFilters } from "~/pages/DataCapture/actions";
import { translateStatusBatch } from "~/pages/DataCapture/utils";
import { BatchStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";

const BatchFilters = ({ t }) => {
  const dispatch = useDispatch();

  const { isDeletedBatch, updatedBatch, isSavingMetadataDocument } =
    useSelector((state) => state.dataCaptureReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const [menuId, setMenuId] = useState(undefined);
  const [status, setStatus] = useState(BatchStatus.WAITING_SUBMISSION);
  const [changeFilter, setChangeFilter] = useState(undefined);

  useEffect(() => {
    return () => setChangeFilter("");
  }, []);

  useEffect(() => {
    dispatch(findBatchsByFilters({ menuId, status }));
  }, [dispatch, changeFilter, updatedBatch, status, menuId]);

  useEffect(() => {
    if (isDeletedBatch) {
      dispatch(findBatchsByFilters({ menuId, status }));
    }
  }, [dispatch, isDeletedBatch]);

  useEffect(() => {
    dispatch(findBatchsByFilters({ menuId, status }));
  }, [dispatch, isSavingMetadataDocument]);

  const handleMenuId = (e) => {
    setChangeFilter(e);
    setMenuId(e || undefined);
  };

  const handleStatus = (e) => {
    setChangeFilter(e);
    setStatus(e || undefined);
  };

  const getOptions = () => {
    const menuItems = menu.filter(
      (item) =>
        item.route === "datacapture/submissaodespesas" ||
        item.route === "datacapture/onboarding"
    );

    const newList = menuItems.map((item) => {
      return {
        value: item.id.toString(),
        label: t(item.name).toUpperCase(),
        selected: false,
      };
    });

    newList.push({ label: "", value: "", selected: false });
    return newList;
  };

  const adaptDropdownStatusList = () => {
    const newList = Object.keys(BatchStatus).map((e) => {
      return {
        value: e,
        label: t(translateStatusBatch(BatchStatus[e])),
        selected: false,
      };
    });
    newList.push({ label: "", value: "", selected: false });
    return newList;
  };

  return (
    <Form style={{ height: "fit-content" }}>
      <Form.Row>
        <Col lg="12">
          <h5>{t("general.filters")}</h5>
          <hr />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg="3">
          <Form.Label>{t("datacapture.general.flow")}</Form.Label>

          <Dropdown
            list={menu && getOptions()}
            handleSubmit={handleMenuId}
            emptyDefault={true}
          />
        </Col>
        <Col lg="3">
          <Form.Label>Status</Form.Label>
          <Dropdown
            list={adaptDropdownStatusList()}
            handleSubmit={handleStatus}
            emptyDefault={true}
          />
        </Col>
      </Form.Row>
    </Form>
  );
};

export default withNamespaces()(BatchFilters);
