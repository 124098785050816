import React from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col } from "react-bootstrap";

const Output = ({ t, output }) => {
  return (
    <div className="onboarding-output">
      <h3 className="onboarding-form-header">
        {t("onboarding.general.output")}
      </h3>
      <Col sm="12" md="8" lg="8" style={{ padding: "0px" }}>
        <Form.Control
          className="output-textarea"
          as="textarea"
          rows={8}
          readOnly
          value={output}
        />
      </Col>
    </div>
  );
};
export default withNamespaces()(Output);
