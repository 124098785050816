import React from "react";
import { Col, Row } from "react-bootstrap";
import MiddleWares from "./MiddleWares";
import Encrypt from "./Encrypt";

const Syncronization = ({ tabKey, sync, encr }) => {
  return (
    <Row gutter={12}>
      {sync && (
        <Col xs="12" sm="12" md="12" lg="12" x="12" style={{ marginTop: 15 }}>
          <MiddleWares tabKey={tabKey} />
        </Col>
      )}
      {encr && (
        <Col xs="12" sm="12" md="12" lg="12" x="12" style={{ marginTop: 15 }}>
          <Encrypt tabKey={tabKey} />
        </Col>
      )}
    </Row>
  );
};

export default Syncronization;
