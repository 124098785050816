import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import TemplateTable from "./TemplateTable";

import { Card } from "react-bootstrap";

import { getTemplatesList } from "store/ducks/processes/actionTypes";

const RHGestaoTemplates = ({ t }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplatesList());
  }, []);

  return (
    <div className="main-card-v2" style={{ height: "fit-content" }}>
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>{t("portalrh.rhadmin.gestaoTemplates.cardTitle")}</h6>
        </Card.Header>
        <Card.Body> 
          <TemplateTable />
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(RHGestaoTemplates);
