import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

const ButtonsCreate = ({
  OnTakingBolean,
  createNewData,
  addressPage,
  personalDataPage,
  academicQualificatonsPage,
  taxDataPage,
  houseHoldPage,
  paymentsDataPage,
  trainingSheetPage,
  performancePage,
  medicalRecordPage,
  handleNewButtonClicked,
  t,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButton = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsClicked(!isClicked);
    OnTakingBolean(isClicked);
    handleNewButtonClicked(isClicked);
  };

  return (
    <>
      {isClicked ? (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            type="submit"
            className="card-button"
            //onClick={handleSaveButton}
            style={{ marginLeft: 15 }}
          >
            {t(`portalrh.NavBar.ButtonSave`)}
          </Button>

          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => handleButton(e)}
          >
            {t(`portalrh.NavBar.ButtonCancel`)}
          </Button>
        </div>
      ) : (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => handleButton(e)}
          >
            {t(`portalrh.NavBar.ButtonNew`)}
          </Button>
        </div>
      )}
    </>
  );
};
export default withNamespaces()(ButtonsCreate);
