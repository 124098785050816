import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Alert } from "react-bootstrap";

const CreateAlert = ({ field, index, variant, translations, language }) => {
  const translateText = (textField) => {
    if (typeof textField === "string") {
      if (!textField.includes("::t::")) {
        return textField;
      }
      const lingua = language.trim();
      const cleanTextField = textField.replace(/'/g, "").trim();
      let splittext = undefined;

      if (textField.includes("::nDP::")) {
        const removeNDP = cleanTextField.split("::nDP::");
        splittext = removeNDP[0].split("::t");
      } else {
        splittext = cleanTextField.split("::t::");
      }

      const textoFinal = splittext
        .map((tt) => {
          const splitSplitedText = tt.split("bmp.");
          if (splitSplitedText.length > 1 && translations?.[lingua]) {
            return (
              splitSplitedText[0] +
              translations[lingua]["bmp." + splitSplitedText[1]]
            );
          } else {
            return tt;
          }
        })
        .join("");
      return textoFinal;
    }
  };

  return field && field.text && field.cssclass ? (
    <Alert
      key={index}
      variant={variant || "info"}
      style={{
        backgroundColor:
          field.cssclass === "background:transparent" ? "transparent" : "",
        border:
          field.cssclass === "background:transparent" ? "transparent" : "",
        color: field.cssclass === "background:transparent" ? "#4c4b4b" : "",
      }}
    >
      {ReactHtmlParser(
        translateText(
          field.text ? (field.text.b ? field.text.b : field.text) : field.text
        )
      )}
    </Alert>
  ) : field && field.text ? (
    <Alert key={index} variant={variant || "info"}>
      {ReactHtmlParser(
        translateText(
          field.text ? (field.text.b ? field.text.b : field.text) : field.text
        )
      )}
    </Alert>
  ) : null;
};
export default CreateAlert;
