import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { createMerchant } from "~/store/ducks/onboarding/actionTypes";

/**
 * Create Client
 *
 * This service tests an API from easyPay
 *
 * It allows to create a merchant.
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingSendSmsOTP, merchant } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if(merchant)
    {
      Object.keys(merchant)?.length !== 0 &&
      setOutput(JSON.stringify(merchant, null, 2));
    }
  }, [merchant]);

  useEffect(() => {
      setOutput("")
  }, []);

  const [name, setName] = useState("Feverencio de Sousa");
  const [validated, setValidated] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [output, setOutput] = useState("");
  const [company, setCompany] = useState("Couves & Couves, Lda");
  const [tin, setTin] = useState("PT");
  const [type, setType] = useState("company");
  const [company_type, setCompanyType] = useState("SA");
  const [address, setAddress] = useState("Rua das Couves, n° 420, 2");
  const [zip_code, setZipCode] = useState("1234-567");
  const [city, setCity] = useState("Goulegã");
  const [country, setCountry] = useState("PT");
  const [email, setEmail] = useState("feverencio@couves.pt");
  const [mobile, setMobile] = useState("915990552");
  const [iban, setIban] = useState("PT50000201231234567890154");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testCreateClient();
    }
  };

  const testCreateClient = () => {
    const user = {
      "name": name,
      "email": email,
      "mobile": mobile
    }
    const payload = {
      name,
      company,
      tin,
      type,
      company_type,
      address,
      zip_code,
      city,
      country,
      email,
      mobile,
      iban,
      user
    };
    dispatch(createMerchant(payload, apiSettings.id));
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationClientName2">
              <Form.Label>{t("onboarding.persist.name")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCompany">
              <Form.Label>{t("onboarding.persist.company")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredCompany")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationTin">
              <Form.Label>{t("onboarding.persist.tin")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={tin}
                onChange={(e) => {
                  setTin(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredTin")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationClientType">
              <Form.Label>{t("onboarding.persist.type")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredType")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationClientCompanyType">
              <Form.Label>{t("onboarding.persist.companyType")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={company_type}
                onChange={(e) => {
                  setCompanyType(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredCompanyType")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationClientAdress">
              <Form.Label>{t("onboarding.persist.address")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredAddress")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationZipCode">
              <Form.Label>{t("onboarding.persist.zipCode")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={zip_code}
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredZipCode")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationCity">
              <Form.Label>{t("onboarding.persist.city")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredCity")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCountry">
              <Form.Label>{t("onboarding.persist.country")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredCountry")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationEmail">
              <Form.Label>{t("onboarding.persist.email")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredEmail")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationMobile">
              <Form.Label>{t("onboarding.persist.mobile")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredMbile")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationIban">
              <Form.Label>{t("onboarding.persist.iban")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                value={iban}
                onChange={(e) => {
                  setIban(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredIban")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          className="card-button onboarding-button"
          type="submit"
        >
          {isLoadingSendSmsOTP ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
