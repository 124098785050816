import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import DatePicker from "~/components/DatePicker";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import "~/assets/css/icons.css";
import Dropdown from "~/components/Dropdown";
import SelectFilter from "./SelectFilter";
import TextFilter from "./TextFilter";
import { useDispatch } from "react-redux";
import {
  setRequestsMyTasksWidget,
  setRequestsMyTasksWidgetPIDs,
  setFlowIdMyTasksRequest,
} from "~/store/ducks/portalrh/actionTypes";

const TaskFilters = ({ app, ...props }) => {
  const {
    t,
    flowId,
    startAt,
    endAt,
    nProcess,
    description,
    previousUser,
    flowFiltersValues,
    hiddenDefaults,
    handleFlowFiltersValues,
    handleFlowId,
    handleEndAt,
    handleStartAt,
    handleNProcess,
    handleDescription,
    handlePreviousUser,
    handleSearch,
    handleReset,
    activeKeys,
    activeFilters
  } = props;

  const dispatch = useDispatch();

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { rawMenus } = useSelector((state) => state.applicationMenuReducer);
  const { requestsMyTasksWidget } = useSelector(
    (state) => state.portalrhReducer
  );

  const [flowFilters, setFlowFilters] = useState(undefined);
  const [fluxos, setFluxos] = useState([]); //active
  const [inactiveFlows, setInactiveFlows] = useState([]);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [listDropdownFromWidget, setListDropdownFromWidget] = useState({});
  /*Get flow Filters defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/tarefas` || item.route === `${app}/tasks`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        let flowFilters_;
        if (flowId && extraConfiguration_[flowId]) {
          flowFilters_ = extraConfiguration_[flowId].filters; //filters for a  specific flow
        } else {
          flowFilters_ = extraConfiguration_.defaultExtraFilters; //extra filters for all flows
        }
        setFluxos(Object.keys(extraConfiguration_));

        const inactiveFlows_ = [];
        Object.keys(extraConfiguration_).forEach((key) => {
          if (extraConfiguration_[key]?.inactive === true)
            inactiveFlows_.push({
              value: key,
              ...extraConfiguration_[key],
            });
        });
        setInactiveFlows(inactiveFlows_);
        setFlowFilters(flowFilters_ ? flowFilters_ : undefined);
        handleFlowFiltersValues(undefined); /*Clean flow filters*/
      }
    }
  }, [menu, flowId]);

  useEffect(() => {
    if (requestsMyTasksWidget && requestsMyTasksWidget.length > 0) {
      let matchingRequest = requestsMyTasksWidget.find(
        (request) => request.flowid === flowId
      );
      if (matchingRequest) {
        setListDropdownFromWidget({
          label: matchingRequest.flowname,
          value: matchingRequest.flowid,
          selected: true,
        });
      }
    }
  }, [requestsMyTasksWidget, flowId]);

  const renderFlowOptions = () => {
    let filtered = rawMenus.filter((menu) => {
      const flowId = menu.url && menu.url.split("flowid=")[1];
      return fluxos.some((f) => f === flowId);
    });
    filtered = filtered.map((e) => {
      return {
        label: e.label,
        value: e.url.split("flowid=")[1],
        selected: false,
      };
    });

    //add inactive flows
    inactiveFlows.forEach((f) => {
      filtered.push({
        label: f.label,
        value: f.value,
        selected: false,
      });
    });
    if (Object.keys(listDropdownFromWidget).length > 0) {
      filtered.push(listDropdownFromWidget);
    }
    //add first empty option
    filtered.push({
      label: "",
      value: "",
      selected: false,
    });

    return filtered;
  };

  const renderFlowFilters = () => {
    if (flowFilters) {
      const getValue = (variable) => {
        return flowFiltersValues && flowFiltersValues[variable]
          ? flowFiltersValues[variable]
          : "";
      };
      const handleChange = (variable, value) => {
        const newValues = { ...flowFiltersValues, [variable]: value };
        handleFlowFiltersValues(newValues);
      };
      const filters = flowFilters.map((filter, index) => {
        if (filter.type === "text" && filter.variable === "from")
          return (
            <Col lg="3" key={index}>
              <Form.Label>
                {t(`taskPage.general.${filter.variable}`)}
              </Form.Label>
              <Form.Control
                type="text"
                value={previousUser}
                onChange={(e) => handlePreviousUser(e.target.value)}
              />
            </Col>
          );
        if (filter.type === "text" && filter.variable === "description")
          return (
            <Col lg="3" key={index}>
              <Form.Label>
                {t(`taskPage.general.${filter.variable}`)}
              </Form.Label>
              <Form.Control
                type="text"
                value={description}
                onChange={(e) => handleDescription(e.target.value)}
              />
            </Col>
          );
        if (filter.type === "text")
          return (
            <TextFilter
              filter={filter}
              key={index}
              value={getValue(filter.variable)}
              onChange={handleChange}
            />
          );
        if (filter.type === "select")
          return (
            <SelectFilter
              filter={filter}
              key={index}
              value={getValue(filter.variable)}
              onChange={handleChange}
              resetDropdown={cleanDropdown}
              setResetDropdown={setCleanDropdown}
            />
          );
      });
      return <Form.Row>{filters}</Form.Row>;
    }
  };

  const isHidden = (filter) => {
    if (hiddenDefaults) {
      return hiddenDefaults.some((f) => f === filter) ? true : false;
    } else return false;
  };

  const handleClearDropdownStateFromWidget = () => {
    setListDropdownFromWidget({});
    renderFlowOptions();
    dispatch(setFlowIdMyTasksRequest(null));
    dispatch(setRequestsMyTasksWidget([]));
  };

  return (
    <Accordion activeKey={activeKeys?.filter || activeFilters}>
      <Accordion.Collapse eventKey="0">
        <Form>
          {/*Default Filters start */}
          <Form.Row>
            {!isHidden("flowMenu") && (
              <Col lg="4">
                <Form.Label>{t("taskPage.general.flow")}</Form.Label>
                <Dropdown
                  list={renderFlowOptions()}
                  handleSubmit={handleFlowId}
                  emptyDefault={true}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                />
              </Col>
            )}
          </Form.Row>
          <br />
          <Form.Row>
            {!isHidden("from") && (
              <Col lg="4">
                <Form.Label>{t("taskPage.general.dataRegisto")}</Form.Label>
                <DatePicker
                  value={startAt}
                  onChange={(e) => handleStartAt(e)}
                  format="dd/MM/yyyy"
                />
              </Col>
            )}
            {!isHidden("to") && (
              <Col lg="4">
                <Form.Label> &nbsp;</Form.Label>

                <DatePicker
                  value={endAt}
                  onChange={(e) => handleEndAt(e)}
                  format="dd/MM/yyyy"
                />
              </Col>
            )}
            {!isHidden("pnumber") && (
              <Col lg="3">
                <Form.Label>{t("taskPage.general.processNumber")}</Form.Label>
                <Form.Control
                  type="text"
                  value={nProcess}
                  onChange={(e) => handleNProcess(e.target.value)}
                />
              </Col>
            )}
          </Form.Row>
          <br />
          {/*Default Filters end */}
          {renderFlowFilters()}

          <Button className="card-button" onClick={() => handleSearch()}>
            {t("general.search")}
          </Button>
          <Button
            className="card-button"
            onClick={() => {
              handleClearDropdownStateFromWidget();
              handleReset();
              setCleanDropdown(true);
            }}
            style={{ marginLeft: "15px" }}
          >
            {t("general.clean")}
          </Button>
          <hr />
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default withNamespaces()(TaskFilters);
