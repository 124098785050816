import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "~/assets/css/icons.css";
import questionmark from "~/assets/img/icons/questionmark.png";
import GeneralDocument from "~/pages/ADataCapture/components/GeneralDocument";
import HelperContent from "~/pages/ADataCapture/components/HelperContent";
import { findAllBatchs } from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";

const UploadModal = (props) => {
  const {
    showUploadModal,
    handleCloseUploadModal,
    datacaptureConfiguration,
    docTypeSelected,
    docTypeExtraConfiguration
  } = props;

  const dispatch = useDispatch();

  const [showHelperContent, setShowHelperContent] = useState(false);

  const [modalClassName, setModalClassName] = useState("modal-datacapture-80w");
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");

  const handleClick = () => {
    modalClassName === "modal-datacapture-80w"
      ? setModalClassName("modal-datacapture-100w")
      : setModalClassName("modal-datacapture-80w");
    iconClassName === "icon-maximize mr-4"
      ? setIconClassName("icon-restore mr-4")
      : setIconClassName("icon-maximize mr-4");
  };

  const handleClose = () => {
    setModalClassName("modal-datacapture-80w");
    setIconClassName("icon-maximize mr-4");
    handleCloseUploadModal();
    dispatch(findAllBatchs());
  };
  return (
    <Modal
      show={showUploadModal}
      dialogClassName={modalClassName}
      backdrop="static"
      onHide={() => handleCloseUploadModal()}
    >
      <Modal.Body style={{ padding: "0px" }}>
        <div
          style={{
            position: "absolute",
            top: "23px",
            right: "10px",
            zIndex: "2",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={questionmark}
            style={{ height: "22px", marginRight: "20px" }}
            alt="help"
            onClick={() => setShowHelperContent(true)}
          />
          <i className={iconClassName} onClick={() => handleClick()} />
          <i className="icon-remove mr-4" onClick={() => handleClose()} />
        </div>
        <>
          <HelperContent
            setShowHelperContent={setShowHelperContent}
            experiencedUser={true}
            show={showHelperContent}
          />
          <GeneralDocument
            docTypeSelected={docTypeSelected}
            show={!showHelperContent}
            handleCloseUploadModal={handleCloseUploadModal}
            experiencedUser={true}
            datacaptureConfiguration={datacaptureConfiguration}
            docTypeExtraConfiguration={docTypeExtraConfiguration}
          />
        </>
      </Modal.Body>
    </Modal>
  );
};
export default UploadModal;
