export const GET_PROCESSES = 'GET_PROCESSES';
export const SET_PROCESSES = 'SET_PROCESSES';
export const SET_HISTORY = 'SET_HISTORY';
export const SET_PROCESSES_HISTORY = 'SET_PROCESSES_HISTORY';
export const SET_PROCESSES_COUNT_TOTAL = 'SET_PROCESSES_COUNT_TOTAL';
export const SET_PROCESSES_COUNT = 'SET_PROCESSES_COUNT';
export const FIND_PROCESSES_BY_FILTERS = 'FIND_PROCESSES_BY_FILTERS';

export const getProcesses = (username, application) => {
  return { type: GET_PROCESSES, data: { username, application } }
}

export const setProcesses = (processes) => {
  return { type: SET_PROCESSES, data: processes }
}
export const setProcessesHistory = (processes) => {
  return { type: SET_PROCESSES_HISTORY, data: processes }
}
export const setProcessesCountTotal = (processes) => {
  return { type: SET_PROCESSES_COUNT_TOTAL, data: processes }
}
export const setProcessesCount = (processes) => {
  return { type: SET_PROCESSES_COUNT, data: processes }
}
export const setHistory = (processes) => {
  return { type: SET_HISTORY, data: processes }
}

export const findProcessesByFilters = (username, application, nProcess, startAt, endAt, PROCESS_STATUS) => {
  return { type: FIND_PROCESSES_BY_FILTERS, data: { username, application, nProcess, startAt, endAt, PROCESS_STATUS } };
}

