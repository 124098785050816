import { takeLatest } from 'redux-saga/effects';

import { RESET_PASSWORD, VALIDATE_TOKEN, SAVE_PASSWORD, REQUEST_RESET_ADMIN_PASSWORD, VALIDATE_ADMIN_CODE, VALIDATE_ADMIN_RESET } from "../ducks/resetPassword/actionTypes";
import { resetPassword, validateToken, savePassword, requestResetAdminPassword, validateAdminCode, validateAdminReset } from "../ducks/resetPassword/sagas";

export const resetPasswordSagas = [
  takeLatest(RESET_PASSWORD, resetPassword),
  takeLatest(VALIDATE_TOKEN, validateToken),
  takeLatest(SAVE_PASSWORD, savePassword),
  takeLatest(VALIDATE_ADMIN_CODE, validateAdminCode),
  takeLatest(REQUEST_RESET_ADMIN_PASSWORD, requestResetAdminPassword),
  takeLatest(VALIDATE_ADMIN_RESET, validateAdminReset),
]
