import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import {
  PORTALRH_MIDDLEWARE,
  RH_MIDDLEWARE,
  IFLOW_APP,
} from "~/utils/constants";

import { setGraphInfo } from "./actionTypes";

import { activeError } from "~/components/Messages/actions";

export function* getGraphInfoBySelectedItem({ selectedItem }) {
  try {
    // const resp = yield http.get("/api/datacapture/reports/all");
    const resp = {
      type: "bar",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40, 70, 75, 60, 50, 45],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
            ],
            borderWidth: 1,
            barThickness: 20, // Adjust the bar thickness to make the bars thinner
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            formatter: function (value, context) {
              return value;
            },
            font: {
              weight: "bold",
            },
          },
        },
      },
    };

    yield put(setGraphInfo(resp));
  } catch (e) {
    yield put(setGraphInfo({}));
    yield put(activeError(e));
  }
}
