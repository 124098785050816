import React from "react";
import { withNamespaces } from "react-i18next";
import {Button} from "react-bootstrap";

const SearchButton = (props) => {

  const {
    docs,
    setFilteredDocs,
    expirationDateStart,
    expirationDateEnd,
    selectedOperation,
    docType,
    seenStatus,
    children
  }=props;


  const handleSearch = () => {
    let documents = [];
    const d_start = new Date(expirationDateStart);
    const d_end = new Date(expirationDateEnd);

    /*filter by expiration date*/
    docs.forEach((doc) => {
      const d = new Date(doc.expirationDate);
      if (expirationDateStart && expirationDateEnd) {
        if (
          d.getTime() >= d_start.getTime() &&
          d.getTime() <= d_end.getTime()
        ) {
          documents.push(doc);
        }
      } else if (expirationDateStart) {
        if (d.getTime() >= d_start.getTime()) {
          documents.push(doc);
        }
      } else if (expirationDateEnd) {
        if (d.getTime() <= d_end.getTime()) {
          documents.push(doc);
        }
      } else {
        documents.push(doc);
      }
    });

    /*Filter by seen status*/
    if(seenStatus === "seen"){
      documents = documents.filter((doc) => { return doc.seen === null || doc.seen === true })
    }else if(seenStatus === "unseen"){
      documents = documents.filter((doc) => { return doc.seen === false })
    }

    if (!docType && !selectedOperation) {
      setFilteredDocs(documents);
    } else if(selectedOperation){
      /*filter by  operation */
      const operationFilteredDocs = documents.filter(
        (doc) => doc.operationId == selectedOperation
      );
      if(docType){
        /*filter by document type*/
        const docTypeFilteredDocs = operationFilteredDocs.filter(
          (doc) => doc.classId == docType
        );
        setFilteredDocs(docTypeFilteredDocs);
      }
      else
        setFilteredDocs(operationFilteredDocs);
    }
  };

  return(
    <Button
      className="card-button"
      onClick={() => handleSearch()}
    >
      {children}
    </Button>
  )

}
export default withNamespaces()(SearchButton);