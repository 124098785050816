import React, { useEffect, useState } from "react";
import { findDocument } from "~/store/ducks/iflow/actionTypes";
import { useSelector, useDispatch } from "react-redux";
const PreviewComponent = (props) => {
  const { type, file } = props;
  const dispatch = useDispatch();
  const fileExtension = file?.filename?.split(".").pop();
  const isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtension);
  const { currentDocument } = useSelector((state) => state.iflowReducer);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    var url = file.link_url;
    var regex = /docid=(\d+)/;
    var match = regex.exec(url);
    var id;
    if (match) {
      id = match[1];
    }

    if (!currentDocument) {
      dispatch(findDocument(id));
    }
  }, [file, currentDocument]);

  const generatePdfView = () => {
    function createPdfBlob(datadoc) {
      const base64String = atob(datadoc);
      const bytes = new Uint8Array(base64String.length);
      for (let i = 0; i < base64String.length; i++) {
        bytes[i] = base64String.charCodeAt(i);
      }
      return new Blob([bytes.buffer], { type: "application/pdf" });
    }

    const datadoc = currentDocument.datadoc;
    const pdfBlob = createPdfBlob(datadoc);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return pdfUrl;
  };
  const generateImageView = (doc) =>
    doc ? "data:image/jpg;base64, " + doc.datadoc : "";

  const getPreviewContent = () => {
    if (isImage) {
      return (
        <>
          {currentDocument && (
            <img
              src={generateImageView(currentDocument)}
              alt={currentDocument.filename}
              style={{ maxWidth: "100%", marginBottom: "10px", maxHeight: "600px"}}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {currentDocument && !isMobile && (
            <iframe
              id="pdf-document"
              title="pdf-document"
              src={generatePdfView()}
              width="100%"
              height="500px"
            />
          )}
        </>
      );
    }
  };

  return <>{getPreviewContent()}</>;
};

export default PreviewComponent;
