import {
  FIND_MENU_BY_APP,
  SET_MENU_APP,
  FIND_MENU_BY_APP_NAME,
  SET_MENU_APP_NAME,
  FIND_SETTINGS_MENU_BY_APP,
  SET_SETTINGS_MENU_APP,
  CLEAR_SETTINGS_MENU_APP,
  SAVE_APPLICATION_MENU,
  SET_SAVE_APPLICATION_MENU,
  SET_RAW_MENUS,
  SET_RAW_FLOWS,
  SET_MENUS,

  ADD_ITEMS_TO_MENU,
  EDIT_APP_MENU,
  DONE_EDIT_APP_MENU,
  DELETE_APP_MENU,
  DONE_DELETE_APP_MENU,

  TOGGLE_SIDE_BAR,
  CLOSE_SIDE_BAR,
  OPEN_SIDE_BAR,

  UPDATE_REDUX_MENU
} from "./actionTypes";

const initialState = {
  menu: [],
  isLoadingMenu: false,

  settingsMenu: [],
  isLoadingSettingsMenu: false,
  isLoadingSave: false,
  isLoadingDelete: false,
  successDelete: false,
  isLoadingEdit: false,
  successEdit: false,

  rawMenus: [],
  rawFlows:[],
  showSideBar: true
}

export const applicationMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_MENU_BY_APP:
      return { ...state, isLoadingMenu: true }
    case SET_MENU_APP:
      return { ...state, isLoadingMenu: false, menu: action.menu }
    case FIND_MENU_BY_APP_NAME:
      return { ...state, isLoadingMenu: true }
    case SET_MENU_APP_NAME:
      return { ...state, isLoadingMenu: false, menu: action.menu }
    case FIND_SETTINGS_MENU_BY_APP:
      return { ...state, isLoadingSettingsMenu: true }
    case SET_SETTINGS_MENU_APP:
      return {
        ...state,
        isLoadingSettingsMenu: false,
        settingsMenu: action.settingsMenu
      }
    case CLEAR_SETTINGS_MENU_APP:
      return {
        ...state,
        settingsMenu: initialState.settingsMenu
      }
    case SAVE_APPLICATION_MENU:
      return {
        ...state,
        isLoadingSave: true,
      }
    case SET_SAVE_APPLICATION_MENU:
      return {
        ...state,
        isLoadingSave: false,
        settingsMenu: action.applicationMenus
      }
    case DELETE_APP_MENU:
      return {
        ...state,
        isLoadingDelete: true,
        successDelete: false
      }
    case DONE_DELETE_APP_MENU:
      return {
        ...state,
        isLoadingDelete: false,
        successDelete: action.successDelete
      }
    case EDIT_APP_MENU:
      return {
        ...state,
        isLoadingEdit: true,
        successEdit: false
      }
    case DONE_EDIT_APP_MENU:
      return {
        ...state,
        isLoadingEdit: false,
        successEdit: action.successEdit
      }

    case ADD_ITEMS_TO_MENU:
      const menu_ = state.menu;
      action.items.forEach(item => {
        if (!menu_.some(savedItem => savedItem.route === item.route))
          menu_.push(item);
      })
      return { ...state, menu: menu_ };

    case UPDATE_REDUX_MENU:
      const menuRef = state.menu;
      menuRef.splice(0, state.menu.length);
      action.menu.forEach(item => {
        menuRef.push(item);
      })
    return { ...state, menu: menuRef };
    case SET_RAW_MENUS:
      return { ...state, rawMenus: action.data };
    case SET_RAW_FLOWS:
      return { ...state, rawFlows: action.data };
    case SET_MENUS:
      return { ...state, menus: action.data };
    case TOGGLE_SIDE_BAR:
      return { ...state, showSideBar: !state.showSideBar };
    case CLOSE_SIDE_BAR:
      return { ...state, showSideBar: false };
    case OPEN_SIDE_BAR:
      return { ...state, showSideBar: true };
    default:
      return state;
  }
}
