import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import "../../scss/calculos.scss";

function searchNode(id, currentNode) {
  var result = [];

  for (const [key, value] of Object.entries(currentNode)) {
    if (value == id) {
      result.push(currentNode);
      return result;
    }
    if (
      (value !== null && typeof value === "object") ||
      typeof value === "array"
    )
      result = [...result, ...searchNode(id, value)];
  }
  return result;
}

const TabelaProspecaoIVRow = ({ i, block }) => {
  return (
    <tr bgcolor="#FFFFFF">
      <td id={"63_" + (i + 3) + "_1"} align="left" className="txt ">
        {block[0]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_2"} className="txt " align="left">
        {block[1]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_3"} className="txt " align="left">
        {block[2]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_4"} className="txt " align="left">
        {block[3]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_5"} className="txt " align="left">
        {block[4]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_6"} className="txt " align="left">
        {block[5]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_7"} className="txt " align="left">
        {block[6]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_8"} className="txt " align="left">
        {block[7]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_9"} className="txt " align="left">
        {block[8]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_10"} className="txt " align="left">
        {block[9]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_11"} className="txt " align="left">
        {block[10]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_12"} className="txt " align="left">
        {block[11]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_13"} className="txt " align="left">
        {block[12]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_14"} className="txt " align="left">
        {block[13]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_15"} className="txt " align="left">
        {block[14]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_16"} className="txt " align="left">
        {block[i].value}
      </td>
      <td id={"63_" + (i + 3) + "_17"} className="txt " align="left">
        {block[15]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_18"} className="txt " align="left">
        {block[16]?.value}
      </td>
      <td id={"63_" + (i + 3) + "_19"} className="txt " align="left">
        {block[17]?.value}
      </td>
    </tr>
  );
};

const TabelaProspecaoIV = ({ block }) => {
  return (
    <table
      style={{ borderSpacing: 0, borderCollapse: "collapse", width: "100%" }}
      className="iflow-calculos-table"
    >
      <thead>
        <tr bgcolor="#FFFFFF" className="header">
          <td id="63_1_1" align="center" colSpan="11">
            P - PROSPEÇÃO - IMÓVEIS CONSTRUÍDOS
          </td>
          <td id="63_1_2" align="center" colSpan="8">
            Coeficientes de Homogeneização
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="table-head">
          <td id="63_2_1" align="left">
            Tipo Imóvel
          </td>
          <td id="63_2_2" align="left">
            Tipologia T
          </td>
          <td id="63_2_3" align="left">
            Tipo Negócio
          </td>
          <td id="63_2_4" align="left">
            Fonte
          </td>
          <td id="63_2_5" align="left">
            Morada/ Localização
          </td>
          <td id="63_2_6" align="left">
            Ano
          </td>
          <td id="63_2_7" align="left">
            Estado
          </td>
          <td id="63_2_8" align="left">
            Área Terreno (m2)
          </td>
          <td id="63_2_9" align="left">
            Área Bruta (m2)
          </td>
          <td id="63_2_10" align="left">
            Valor €
          </td>
          <td id="63_2_11" align="left">
            IV €/m2
          </td>
          <td id="63_2_12" align="left">
            Negoc.
          </td>
          <td id="63_2_13" align="left">
            Localiz.
          </td>
          <td id="63_2_14" align="left">
            Área
          </td>
          <td id="63_2_15" align="left">
            Estado Conserv.
          </td>
          <td id="63_2_16" align="left">
            Acab.
          </td>
          <td id="63_2_17" align="left">
            Outros
          </td>
          <td id="63_2_18" align="left">
            Coef. Global
          </td>
          <td id="63_2_19" align="left">
            Índice Homog €/m2
          </td>
        </tr>
      </thead>
      <tbody className="table-content">
        {block?.row.map((e, i) =>
          i > 1 ? (
            <TabelaProspecaoIVRow i={i - 2} block={e.col} key={i} />
          ) : (
            <div key={i}></div>
          )
        )}
      </tbody>
    </table>
  );
};

const VendaTable = ({ block }) => {
  return (
    <table
      style={{ borderSpacing: 0, borderCollapse: "collapse", flex: "1" }}
      className="iflow-calculos-table arraytable sortable scrollableTable scrollTable"
    >
      <thead className="table-head">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_1_1" className="table_sub_header" align="left">
            Venda
          </td>
          <td id="68_1_2" className="table_sub_header" align="left">
            &Iacute;NDICE M&Aacute;XIMO
          </td>
          <td id="68_1_3" className="table_sub_header" align="left">
            &Iacute;NDICE M&Iacute;NIMO
          </td>
          <td id="68_1_4" className="table_sub_header" align="left">
            M&Eacute;DIA
          </td>
          <td id="68_1_5" className="table_sub_header" align="left">
            DESVIO PADR&Atilde;O
          </td>
          <td id="68_1_6" className="table_sub_header" align="left">
            &Iacute;NDICE ADOTADO
          </td>
        </tr>
      </thead>
      <tbody className="table-content">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_2_1" className="txt " align="left">
            Apartamento
          </td>
          <td id="68_2_2" className="txt " align="left">
            {block?.row[1]?.col[1]?.value}
          </td>
          <td id="68_2_3" className="txt " align="left">
            {block?.row[1]?.col[2]?.value}
          </td>
          <td id="68_2_4" className="txt " align="left">
            {block?.row[1]?.col[3]?.value}
          </td>
          <td id="68_2_5" className="txt " align="left">
            {block?.row[1]?.col[4]?.value}
          </td>
          <td id="68_2_6" align="left">
            {block?.row[1]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_3_1" className="txt " align="left">
            Moradia
          </td>
          <td id="68_3_2" className="txt " align="left">
            {block?.row[2]?.col[1]?.value}
          </td>
          <td id="68_3_3" className="txt " align="left">
            {block?.row[2]?.col[2]?.value}
          </td>
          <td id="68_3_4" className="txt " align="left">
            {block?.row[2]?.col[3]?.value}
          </td>
          <td id="68_3_5" className="txt " align="left">
            {block?.row[2]?.col[4]?.value}
          </td>
          <td id="68_3_6" align="left">
            {block?.row[2]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_4_1" className="txt " align="left">
            Terreno
          </td>
          <td id="68_4_2" className="txt " align="left">
            {block?.row[3]?.col[1]?.value}
          </td>
          <td id="68_4_3" className="txt " align="left">
            {block?.row[3]?.col[2]?.value}
          </td>
          <td id="68_4_4" className="txt " align="left">
            {block?.row[3]?.col[3]?.value}
          </td>
          <td id="68_4_5" className="txt " align="left">
            {block?.row[3]?.col[4]?.value}
          </td>
          <td id="68_4_6" align="left">
            {block?.row[3]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_5_1" className="txt " align="left">
            Loja
          </td>
          <td id="68_5_2" className="txt " align="left">
            {block?.row[4]?.col[1]?.value}
          </td>
          <td id="68_5_3" className="txt " align="left">
            {block?.row[4]?.col[2]?.value}
          </td>
          <td id="68_5_4" className="txt " align="left">
            {block?.row[4]?.col[3]?.value}
          </td>
          <td id="68_5_5" className="txt " align="left">
            {block?.row[4]?.col[4]?.value}
          </td>
          <td id="68_5_6" align="left">
            {block?.row[4]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_6_1" className="txt " align="left">
            Garagem/Estacionamento
          </td>
          <td id="68_6_2" className="txt " align="left">
            {block?.row[5]?.col[1]?.value}
          </td>
          <td id="68_6_3" className="txt " align="left">
            {block?.row[5]?.col[2]?.value}
          </td>
          <td id="68_6_4" className="txt " align="left">
            {block?.row[5]?.col[3]?.value}
          </td>
          <td id="68_6_5" className="txt " align="left">
            {block?.row[5]?.col[4]?.value}
          </td>
          <td id="68_6_6" align="left">
            {block?.row[5]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_7_1" className="txt " align="left">
            Edif&iacute;cio
          </td>
          <td id="68_7_2" className="txt " align="left">
            {block?.row[6]?.col[1]?.value}
          </td>
          <td id="68_7_3" className="txt " align="left">
            {block?.row[6]?.col[2]?.value}
          </td>
          <td id="68_7_4" className="txt " align="left">
            {block?.row[6]?.col[3]?.value}
          </td>
          <td id="68_7_5" className="txt " align="left">
            {block?.row[6]?.col[4]?.value}
          </td>
          <td id="68_7_6" className="txt " align="left">
            {block?.row[6]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_8_1" className="txt " align="left">
            Escrit&oacute;rio
          </td>
          <td id="68_8_2" className="txt " align="left">
            {block?.row[7]?.col[1]?.value}
          </td>
          <td id="68_8_3" className="txt " align="left">
            {block?.row[7]?.col[2]?.value}
          </td>
          <td id="68_8_4" className="txt " align="left">
            {block?.row[7]?.col[3]?.value}
          </td>
          <td id="68_8_5" className="txt " align="left">
            {block?.row[7]?.col[4]?.value}
          </td>
          <td id="68_8_6" align="left">
            {block?.row[7]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_9_1" className="txt " align="left">
            Armaz&eacute;m
          </td>
          <td id="68_9_2" className="txt " align="left">
            {block?.row[8]?.col[1]?.value}
          </td>
          <td id="68_9_3" className="txt " align="left">
            {block?.row[8]?.col[2]?.value}
          </td>
          <td id="68_9_4" className="txt " align="left">
            {block?.row[8]?.col[3]?.value}
          </td>
          <td id="68_9_5" className="txt " align="left">
            {block?.row[8]?.col[4]?.value}
          </td>
          <td id="68_9_6" className="txt " align="left">
            {block?.row[8]?.col[5]?.value}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ArrendamentoTable = ({ block }) => {
  return (
    <table
      style={{ borderSpacing: 0, borderCollapse: "collapse", flex: "1" }}
      className="iflow-calculos-table arraytable sortable scrollableTable scrollTable"
    >
      <thead className="table-head">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_1_1" className="table_sub_header" align="left">
            Arrendamento
          </td>
          <td id="71_1_2" className="table_sub_header" align="left">
            &Iacute;NDICE M&Aacute;XIMO
          </td>
          <td id="71_1_3" className="table_sub_header" align="left">
            &Iacute;NDICE M&Iacute;NIMO
          </td>
          <td id="71_1_4" className="table_sub_header" align="left">
            M&Eacute;DIA
          </td>
          <td id="71_1_5" className="table_sub_header" align="left">
            DESVIO PADR&Atilde;O
          </td>
          <td id="71_1_6" className="table_sub_header" align="left">
            &Iacute;NDICE ADOTADO
          </td>
        </tr>
      </thead>
      <tbody className="table-content">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_2_1" className="txt " align="left">
            Apartamento
          </td>
          <td id="71_2_2" className="txt " align="left">
            {block?.row[1]?.col[1]?.value}
          </td>
          <td id="71_2_3" className="txt " align="left">
            {block?.row[1]?.col[2]?.value}
          </td>
          <td id="71_2_4" className="txt " align="left">
            {block?.row[1]?.col[3]?.value}
          </td>
          <td id="71_2_5" className="txt " align="left">
            {block?.row[1]?.col[4]?.value}
          </td>
          <td id="71_2_6" align="left">
            {block?.row[1]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_3_1" className="txt " align="left">
            Moradia
          </td>
          <td id="71_3_2" className="txt " align="left">
            {block?.row[2]?.col[1]?.value}
          </td>
          <td id="71_3_3" className="txt " align="left">
            {block?.row[2]?.col[2]?.value}
          </td>
          <td id="71_3_4" className="txt " align="left">
            {block?.row[2]?.col[3]?.value}
          </td>
          <td id="71_3_5" className="txt " align="left">
            {block?.row[2]?.col[4]?.value}
          </td>
          <td id="71_3_6" align="left">
            {block?.row[2]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_4_1" className="txt " align="left">
            Terreno
          </td>
          <td id="71_4_2" className="txt " align="left">
            {block?.row[3]?.col[1]?.value}
          </td>
          <td id="71_4_3" className="txt " align="left">
            {block?.row[3]?.col[2]?.value}
          </td>
          <td id="71_4_4" className="txt " align="left">
            {block?.row[3]?.col[3]?.value}
          </td>
          <td id="71_4_5" className="txt " align="left">
            {block?.row[3]?.col[4]?.value}
          </td>
          <td id="71_4_6" align="left">
            {block?.row[3]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_5_1" className="txt " align="left">
            Loja
          </td>
          <td id="71_5_2" className="txt " align="left">
            {block?.row[4]?.col[1]?.value}
          </td>
          <td id="71_5_3" className="txt " align="left">
            {block?.row[4]?.col[2]?.value}
          </td>
          <td id="71_5_4" className="txt " align="left">
            {block?.row[4]?.col[3]?.value}
          </td>
          <td id="71_5_5" className="txt " align="left">
            {block?.row[4]?.col[4]?.value}
          </td>
          <td id="71_5_6" align="left">
            {block?.row[4]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_6_1" className="txt " align="left">
            Garagem/Estacionamento
          </td>
          <td id="71_6_2" className="txt " align="left">
            {block?.row[5]?.col[1]?.value}
          </td>
          <td id="71_6_3" className="txt " align="left">
            {block?.row[5]?.col[2]?.value}
          </td>
          <td id="71_6_4" className="txt " align="left">
            {block?.row[5]?.col[3]?.value}
          </td>
          <td id="71_6_5" className="txt " align="left">
            {block?.row[5]?.col[4]?.value}
          </td>
          <td id="71_6_6" align="left">
            {block?.row[5]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_7_1" className="txt " align="left">
            Edif&iacute;cio
          </td>
          <td id="71_7_2" className="txt " align="left">
            {block?.row[6]?.col[1]?.value}
          </td>
          <td id="71_7_3" className="txt " align="left">
            {block?.row[6]?.col[2]?.value}
          </td>
          <td id="71_7_4" className="txt " align="left">
            {block?.row[6]?.col[3]?.value}
          </td>
          <td id="71_7_5" className="txt " align="left">
            {block?.row[6]?.col[4]?.value}
          </td>
          <td id="71_7_6" align="left">
            {block?.row[6]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_8_1" className="txt " align="left">
            Escrit&oacute;rio
          </td>
          <td id="71_8_2" className="txt " align="left">
            {block?.row[7]?.col[1]?.value}
          </td>
          <td id="71_8_3" className="txt " align="left">
            {block?.row[7]?.col[2]?.value}
          </td>
          <td id="71_8_4" className="txt " align="left">
            {block?.row[7]?.col[3]?.value}
          </td>
          <td id="71_8_5" className="txt " align="left">
            {block?.row[7]?.col[4]?.value}
          </td>
          <td id="71_8_6" align="left">
            {block?.row[7]?.col[5]?.value}
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_9_1" className="txt " align="left">
            Armaz&eacute;m
          </td>
          <td id="71_9_2" className="txt " align="left">
            {block?.row[8]?.col[1]?.value}
          </td>
          <td id="71_9_3" className="txt " align="left">
            {block?.row[8]?.col[2]?.value}
          </td>
          <td id="71_9_4" className="txt " align="left">
            {block?.row[8]?.col[3]?.value}
          </td>
          <td id="71_9_5" className="txt " align="left">
            {block?.row[8]?.col[4]?.value}
          </td>
          <td id="71_9_6" align="left">
            {block?.row[8]?.col[5]?.value}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const CreateCalculosProspecaoConsulta = ({ blockdivision }) => {
  const [seccao, setFormData10] = React.useState("");
  const [state, setState] = React.useState([]);

  useEffect(() => {
    const setValue = (f) => {
      if (f?.variable == "seccao") setFormData10(f);
    };

    setState(searchNode("arraytable", blockdivision));

    blockdivision.forEach((b) => {
      if (Array.isArray(b.columndivision)) {
        b.columndivision.forEach((c) => {
          if (Array.isArray(c.field)) {
            c.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(c.field);
          }
        });
      } else {
        if (Array.isArray(b.columndivision?.field)) {
          b.columndivision.field.forEach((f) => {
            setValue(f);
          });
        } else {
          setValue(b.columndivision.field);
        }
      }
    });
  }, [blockdivision]);

  return (
    <>
      {seccao && (
        <div style={{ padding: "0 5px" }}>
          <CreateButtonsFromSelection field={seccao} />
        </div>
      )}
      <div className="custom-table-header">
        <TabelaProspecaoIV block={state[0]} />
      </div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          marginTop: "10px",
          justifyContent: "center",
        }}
      >
        {<VendaTable block={state[1]} />}
        {<ArrendamentoTable block={state[2]} />}
      </div>
      <br />
      <div
        style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
      >
        <div style={{ margin: "0px", flexGrow: "0.5", padding: "0px 5px" }}>
          <label className="textarea field multicol" htmlFor="obsVenda">
            OBS:
          </label>
          <Form.Label
            column
            style={{
              fontWeight: "500",
              paddingRight: "0px",
              paddingLeft: "0px",
            }}
          >
            {searchNode("obsVenda", blockdivision)[0]?.value}
          </Form.Label>
        </div>
        <div style={{ margin: "0px", flexGrow: "0.5", padding: "0px 5px" }}>
          <label className="textarea field multicol" htmlFor="obsArrendamento">
            OBS:
          </label>
          <Form.Label
            column
            style={{
              fontWeight: "500",
              paddingRight: "0px",
              paddingLeft: "0px",
            }}
          >
            {searchNode("obsArrendamento", blockdivision)[0]?.value}
          </Form.Label>
        </div>
      </div>

      <div>
        <div
          className="textarea  field"
          style={{ margin: "0px", flexGrow: "1", padding: "0px 5px" }}
        >
          <label className="textarea field" htmlFor="obsProp">
            Observa&ccedil;&otilde;es
          </label>
          <Form.Label
            column
            style={{
              fontWeight: "500",
              paddingRight: "0px",
              paddingLeft: "0px",
            }}
          >
            {searchNode("obsProp", blockdivision)[0]?.value}
          </Form.Label>
        </div>
      </div>
    </>
  );
};

export default CreateCalculosProspecaoConsulta;
