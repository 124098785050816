import React from "react";
import ProtectedPage from "~/components/security/ProtectedPage";
import EasypayBackOffice from "./EasypayBackOffice";


const EasypayBackOfficePage = () => {

  return (
    <>
      <ProtectedPage roles={["ADMIN"]}>
        <EasypayBackOffice />
      </ProtectedPage>
    </>
  );
};

export default EasypayBackOfficePage;
