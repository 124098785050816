export const pieChart =
{
  type: 'doughnut',
  data: {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: [],
    }],
  },
  options: {
    animation: false,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
      callbacks: {
        label: (tooltipItem, chart) => {
          let datasetLabel = chart.labels[tooltipItem.index] || '';
          return datasetLabel + " " +  chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        }
      }
    },
    legend: {
      display: true
    },
    cutoutPercentage: 0
  },
}