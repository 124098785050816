import { React, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import uuid from "uuid/v1";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import "~/pages/Easypay/assets/css/styles.css";
import Header from "~/pages/Easypay/components/Header";
import "~/pages/Easypay/assets/css/styles.css";
import "~/pages/Easypay/assets/scss/_backOfficeRequestDocumentSection.scss";
import BackOfficeCheckbox from "~/pages/Easypay/components/BackOfficeCheckbox";
import Breadcrumb from "~/pages/Easypay/components/Breadcrumb";
import Button from "~/pages/Easypay/components/Button";
import Input from "~/pages/Easypay/components/Input";
import InputSearch from "~/pages/Easypay/components/InputSearch";
import InputSearchChips from "~/pages/Easypay/components/InputSearchChips";

const AddNewDocument = ({ application }) => {
  const history = useHistory();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [test, setTest] = useState(true);
  const [checkboxList, setCheckboxList] = useState([]);
  const [chipsArrays, setChipsArrays] = useState([[]]);
  const [showAddDocument, setShowAddDocument] = useState(false);
  function getBackgorundColor(index) {
    if (index % 2 === 0) {
      return "#F6F6F6";
    } else {
      return "white";
    }
  }
  const inputSearchList = [
    {
      label: "HORT98023",
      value: "HORT98023",
      selected: true,
    },
    {
      label: "ANT0231364",
      value: "ANT0231364",
      selected: false,
    },
    { label: "FUNDO00198", value: "FUNDO00198", selected: false },
  ];

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }
  const namesList = [
    {
      label: "Carlos Manuel",
      value: "Carlos Manuel",
      selected: false,
    },
    {
      label: "João Silva",
      value: "João Silva",
      selected: false,
    },
    { label: "Maria Monteiro", value: "Maria Monteiro", selected: false },
    { label: "Joana Carvalho", value: "Joana Carvalho", selected: false },
  ];

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const breadcrumbItems = [
    { text: "Voltar", link: "http://localhost/login#/uniksystem/easypay" },
    {
      text: "Solicitar novo documento",
      link: "http://localhost/login#/uniksystem/easypay",
    },
  ];

  function handleSelectChange(e, index) {
    var newArray = chipsArrays[index];
    newArray = [...newArray, e];
    const array = chipsArrays;
    if (chipsArrays[index].includes(e) != true) {
      array[index] = newArray;
      setChipsArrays(array);
      setTest(!test);
    }
  }

  function handleSelectChangeMain(e) {}

  const [docsList, setDocsList] = useState([
    "Cartão de Cidadão",
    "Passaporte",
    "Comprovativo IBAN",
    "Comprovativo de Morada",
    "Declaração de Beneficiários",
    "Comprovativo Abertura de Atividade",
    "Informação Empresarial Simplificada",
    "Cópia de Estatutos",
  ]);

  useEffect(() => {
    if (isFirstTime) {
      var array = new Array(docsList.length).fill(0);
      setCheckboxList(array);
      setIsFirstTime(false);
      array = new Array(docsList.length).fill([]);
      setChipsArrays(array);
    }
  });

  function checkboxChecked(index) {
    var newArray = checkboxList;
    if (newArray[index] === 0) {
      newArray[index] = 1;
    } else {
      newArray[index] = 0;
    }

    setCheckboxList(newArray);
    setTest(!test);
  }

  function removeChipFromArray(text, index) {
    var newArray = chipsArrays[index];
    var newIndex = newArray.indexOf(text);
    if (newIndex !== -1) {
      newArray.splice(newIndex, 1);
      var array = chipsArrays;
      array[index] = newArray;
      setChipsArrays(array);
      setTest(!test);
    }
  }

  let isEmpty = (a) => Array.isArray(a) && a.every(isEmpty);

  return (
    <div
      className="easypay-backoffice-document-preview-page-main-wrapper"
      style={{ overflow: "auto", width: "100%", height: "100%" }}
    >
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-backoffice-document-request-section">
        <div className="easypay-backoffice-document-request-header">
          <div>
            <Breadcrumb breadcrumbStates={breadcrumbItems}></Breadcrumb>
            <div className="easypay-backoffice-text-header-title">
              Solicitar documentos
            </div>
            <InputSearch
              field={{ text: "inputLabel" }}
              required={true}
              text="Indique o ID Lead ou Cliente"
              placeholder="Indique o ID"
              list={inputSearchList}
              handleSubmit={(value) => handleSelectChangeMain(value)}
            />
          </div>
        </div>
        <div className="easypay-backoffice-request-document-section-content-wrapper">
          <div className="easypay-backoffice-header">
            Escolha os documentos que pretende solicitar
          </div>
          {docsList &&
            Object.keys(docsList).length !== 0 &&
            docsList.map((doc, index) => {
              return (
                <div
                  className="easypay-backoffice-request-document-table-item-wrapper"
                  style={{ background: getBackgorundColor(index) }}
                >
                  <BackOfficeCheckbox
                    name={doc}
                    id={index}
                    value={doc}
                    onclick={() => {
                      checkboxChecked(index);
                    }}
                  />
                  {checkboxList[index] === 1 && (
                    <div className="easypay-backoffice-small-input-wrapper-fixed">
                      <InputSearchChips
                        field={{ text: "inputLabel" }}
                        required={true}
                        chipsArray={chipsArrays[index]}
                        handleRemoveChip={removeChipFromArray}
                        text="Nomes"
                        index={index}
                        placeholder="Escolha os nomes"
                        list={namesList}
                        handleSubmit={(value) =>
                          handleSelectChange(value, index)
                        }
                      />
                    </div>
                  )}
                </div>
              );
            })}
          {showAddDocument && (
            <div className="easypay-backoffice-requst-document-add-document-wrapper">
              <div className="easypay-backoffice-request-document-input-wrapper">
                <Input field={buildInputField("Nome do documento", "")}></Input>
              </div>
              <div className="easypay-backoffice-request-document-input-wrapper">
                <Input
                  field={buildInputField("Informação adicional", "")}
                ></Input>
              </div>
              <Button
                text={"Adicionar"}
                variant={"easypay-button-primary"}
                onclick={() => {
                  setShowAddDocument(false);
                }}
                value="Go to Google"
                key={uuid()}
                id={addButtonField.id}
                className="card-button"
                align={"right"}
                disabledColored={false}
                disabled={false}
                field={addButtonField}
              ></Button>
            </div>
          )}
          <div className="easypay-backoffice-request-document-buttons-wrapper">
            <Button
              text={"Adicionar"}
              variant={"easypay-button-add"}
              onclick={() => {
                setShowAddDocument(true);
              }}
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            ></Button>
            <div className="easypay-backoffice-button-wrapper">
              <Button
                text={"Solicitar"}
                variant={"easypay-button-primary"}
                value="Go to Google"
                onclick={() =>
                  history.push({
                    pathname: `/uniksystem/easypay/backoffice-onboarding-page/`,
                  })
                }
                key={uuid()}
                id={addButtonField.id}
                className="card-button"
                align={"right"}
                disabledColored={false}
                disabled={!checkboxList.includes(1) || isEmpty(chipsArrays)}
                field={addButtonField}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(AddNewDocument);
