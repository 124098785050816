import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_replegmodal.scss";
import CustomButton from "./Button";
import Link from "./Link";
import Modal from "./Modal";
import Text from "./Text";

function ClientModalContent({
  isModalShowing,
  setIsModalShowing,
  activeTab,
  t
}) {
  
  var contents;
  
  function getCurrentTabOperation()
  {
    if(activeTab === t(`bpm.onboarding.backoffice.user.tabOrganization`))
    {
      return ("'editClientOrganizacao'");
    }
    else if(activeTab === t(`bpm.onboarding.backoffice.user.tabBusiness`))
    {
      return ("'editClientNegocio'");
    }
    else
      return ("'submitMain'")
  }

  const buttonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href:
      "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value=" +
      getCurrentTabOperation() +
      ";document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };



  function createBody() {
    return (
      <div className="easypay-repleg-modal-body-wrapper">
        <div style={{ width: "420px" }}>
          <Text text = {t(`bpm.onboarding.backoffice.user.modalBodyChangeData`) + "::card-text-light"}></Text>
        </div>
        <div
          className="easypay-repleg-modal-footer-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            textAlign: "right",
          }}
        >
          <div
            className="easypay-repleg-modal-link-wrapper"
            style={{ paddingTop: "8px", textAlign: "right" }}
            onClick={(event) => {
              event.preventDefault();
              setIsModalShowing(false);
            }}
          >
            <Link
              text={t(`bpm.onboarding.backoffice.user.buttonCancel`)}
              disabledColored={false}
              disabled={false}
              link=""
              key={uuid()}
            />
          </div>
          <div
            className="easypay-repleg-modal-button-wrapper"
            style={{ paddingLeft: "25px" }}
          >
            <CustomButton
              text={t(`bpm.onboarding.backoffice.user.modalButtonChangeData`)}
              variant={"easypay-button-primary"}
              onclick={eval(buttonField.onclick)}
              key={uuid()}
              id={buttonField.id}
              className="card-button"
              align={buttonField.align}
              disabledColored={false}
              disabled={false}
              field={buttonField}
            />
          </div>
        </div>
      </div>
    );
  }

  contents = {
    modalTitle: t(`bpm.onboarding.backoffice.user.modalButtonChangeData`),
    modalBody: createBody(),
  };

  return (
    <Modal
      className="easypay-modal"
      showModal={isModalShowing}
      setShowModal={setIsModalShowing}
      headerContent={contents.modalTitle}
      bodyContent={contents.modalBody}
      key={uuid()}
    ></Modal>
  );
}

export default withNamespaces()(ClientModalContent);
