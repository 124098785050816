import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import ErrorLogsTable from "./ErrorLogsTable";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import NavTabs from "../NavTabs";
import SideBar from "../RhCollaborator/Sidebar";
import "~/assets/css/icons.css";

const ErrorLogs = ({portalRhMenus, designacao, path, t}) => {
  const [menuSideBar, setMenuSideBar] = useState(null)
  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
    portalRhMenus.map((menu)=>{
      if(menu.designacao == designacao){
        setMenuSideBar(menu)
      }
    })
  });

  return (
    <div style={{display:'flex', height:'100%'}}>
    {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between">
            <NavTabs
              titleMenu={t("portalrh.errorLogs.usersSynchronizationStatus")}
            />
          </Card.Header>
          <Card.Body>
            <ErrorLogsTable />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default withNamespaces()(ErrorLogs);
