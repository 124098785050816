import React, { useState, useEffect } from "react";
import { Editor, EditorState, convertFromRaw, ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import htmlToDraft from "html-to-draftjs";
import { Modal } from "react-bootstrap";
import { FaEllipsisH } from "react-icons/fa";
import { withNamespaces } from "react-i18next";

const CreateTableRichText = (props) => {
  const { field, alltext, header, moreinfo, t } = props;

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(blocksFromHtml);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorStates, setEditorStates] = useState(htmlToDraftBlocks(field));
  const [editorStatesForModal, setEditorStatesForModal] = useState(
    htmlToDraftBlocks(alltext)
  );
  const [modalOpenToAllText, setmodalOpenToAllText] = useState(false);

  useEffect(() => {
    const markupmodified = field.replace(/u&/g, "ins&")
    const markupmodifiedAlltext = alltext.replace(/u&/g, "ins&")
    const decodedHtml = htmlDecode(markupmodified);
    const decodedHtmlAlltext = htmlDecode(markupmodifiedAlltext);

    setEditorStates(htmlToDraftBlocks(decodedHtml));
    setEditorStatesForModal(htmlToDraftBlocks(decodedHtmlAlltext));
  }, [field]);


  const handleTextClick = () => {
    setmodalOpenToAllText(true);
  };
  
  const htmlDecode = (encodedText) => {
    return encodedText
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");
  };
  return (
    <>
      <div
        onClick={moreinfo ? handleTextClick : null}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <Editor editorState={editorStates} readOnly={true} />
        </div>
        {moreinfo && (
          <div
            style={{
              marginRight: "10px",
              alignSelf: "center",
              display: "inline-grid",
              justifyItems: "center",
            }}
          >
            <span
              style={{
                textDecoration: "underline",
                color: "#24579e",
                cursor: "pointer",
              }}
            >
              {t("general.restInfoRitchtext")}
            </span>
          </div>
        )}
      </div>
      <Modal
        show={modalOpenToAllText}
        dialogClassName="custom-modal"
        centered
        backdrop="static"
        onHide={() => setmodalOpenToAllText(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Editor editorState={editorStatesForModal} readOnly={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default withNamespaces()(CreateTableRichText);
