import React from "react";
import CustomTotal from "~/components/pagination/customTotal";

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div className="btn-group" role="group">
    {options.map((option) => {
      const isSelect = currSizePerPage === `${option.page}`;

      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? "btn-primary" : "btn-link"}`}
          style={{ minHeight: "0px", padding: "0px" }}
        >
          {option.text}
        </button>
      );
    })}
  </div>
);
export const pagination5 = {
  paginationSize: 4,
  pageStartIndex: 0,
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
};

export const pagination5_100 = {
  paginationSize: 5,
  pageStartIndex: 0,
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

export const pagination6 = {
  paginationSize: 4,
  pageStartIndex: 0,
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "6",
      value: 6,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
};

export const pagination7 = {
  paginationSize: 4,
  pageStartIndex: 0,
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "7",
      value: 7,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
};

export const pagination8 = {
  paginationSize: 4,
  pageStartIndex: 0,
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "8",
      value: 8,
    },
    {
      text: "15",
      value: 15,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
};

export const pagination9 = {
  paginationSize: 4,
  pageStartIndex: 0,
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "9",
      value: 9,
    },
    {
      text: "15",
      value: 15,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
};
