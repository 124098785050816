import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "~/components/Spinner/index";
import {
  getUserInformations,
  getUserPayData,
} from "~/store/ducks/portalrh/actionTypes";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import oauth from "~/utils/oauth";
import FlowModal from "~/components/FlowModal";
const devLogConsole = require("~/utils/devLog");
const MyInformationPayment = ({ t }) => {
  const [disabled, isDisabled] = useState(true);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [showFlowModal, setShowFlowModal] = useState(false);

  const dispatch = useDispatch();
  const portalrhReducer = useSelector((state) => state.portalrhReducer);
  const globalReducer = useSelector((state) => state.globalReducer);

  const {
    userPayData,
    isLoadingUserPayData,
    userInformations,
    isLoadingUserInformations,
  } = portalrhReducer;
  const { user } = globalReducer;

  const usernamePortalRh = user.legacyUsers.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;

  useEffect(() => {
    if (usernamePortalRh) {
      dispatch(getUserInformations(usernamePortalRh));
    }
  }, [usernamePortalRh]);

  useEffect(() => {
    if (userInformations && userInformations[0].funcionarioNum) {
      dispatch(getUserPayData(userInformations[0].funcionarioNum));
    }
  }, [userInformations]);

  devLogConsole(userPayData);
  const checkPaymentType = (data) => {
    if (data === "") {
      return t("portalrh.payments.others");
    } else {
      return t("portalrh.payments.bank");
    }
  };

  if (isLoadingUserPayData && isLoadingUserInformations) {
    return <Spinner />;
  }

  const src = selectedTask
    ? "iFlow/" + selectedTask.url + "&Authorization=" + oauth.getAccessToken()
    : "";

  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };
  const handleOpenFlowModal = () => {
    setShowFlowModal(true);
  };

  return (
    <>
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between">
            <h6>{t(`portalrh.payments.payment`)}</h6>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("portalrh.payments.paymentForm")}</Form.Label>
                <Form.Control
                  type="text"
                  value={checkPaymentType(userPayData.bancoCode) || ""}
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicIban">
                <Form.Label>IBAN</Form.Label>
                <Form.Control
                  type="text"
                  value={userPayData.iban || ""}
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={handleOpenFlowModal}>
                  {t("portalrh.payments.change")}
                </Button>
              </Form.Group>
            </Form>
            <FlowModal
              showModal={showFlowModal}
              closeModal={handleCloseFlowModal}
              processNumber={selectedTask && selectedTask.id}
              process={selectedTask}
              src={src}
              isPortal={true}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withNamespaces()(MyInformationPayment);
