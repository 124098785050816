import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "~/pages/Easypay/components/Spinner";
import {
  getAliceToken,
  setAliceUserToken,
  processAliceReport,
  setAliceReport,
} from "~/store/ducks/onboarding/actionTypes";
import Text from "~/pages/Easypay/components/Text";
import CustomButton from "~/pages/Easypay/components/Button";
import { useInterval } from "~/utils/useInterval.js";

import * as aliceonboarding from "aliceonboarding";
import "aliceonboarding/dist/aliceonboarding.css";
import "~/pages/Easypay/assets/scss/_responsive.scss";
const devLogConsole = require("~/utils/devLog");
const AliceProcess = () => {
  const [id, setId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [processReport, setProcessReport] = useState(false);
  const [currentRepId, setCurrentRepId] = useState(null);
  const [currentProc, setCurrentProc] = useState(null);
  const dispatch = useDispatch();

  const {
    aliceUserToken,
    aliceUserID,
    isLoadingGetAliceToken,
    isProcessingAliceReport,
    aliceReport,
    replegId,
  } = useSelector((state) => state.onboardingReducer);

  //devLogConsole(aliceReport);

  useEffect(() => {
    if (aliceUserToken != "") {
      devLogConsole(aliceUserToken)
      let config = new aliceonboarding.OnboardingConfig()
        .withUserToken(aliceUserToken)
        .withAddSelfieStage()
        .withAddDocumentStage(aliceonboarding.DocumentType.IDCARD);
      new aliceonboarding.Onboarding("alice-onboarding-mount", config).run(
        onSuccess,
        onFailure,
        onCancel
      );
    }
  }, [aliceUserToken]);

  useEffect(() => {
    const arr = window.location.href.split("::unik::");
    if (arr && arr.length > 2) {
      devLogConsole(arr);
      if (arr.length === 3) {
        setId(arr[arr.length - 2]);
        setOrgId(arr[arr.length - 1]);
      } else {
        setId(arr[arr.length - 4]);
        setOrgId(arr[arr.length - 3]);
        setCurrentRepId(arr[arr.length - 2]);
        setCurrentProc(arr[arr.length - 1]);
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    if (id && orgId) {
      dispatch(getAliceToken(id, orgId));
    }
  }, [id]);

  useEffect(() => {
    if (processReport) {
      if (aliceUserID != "" && orgId && id) {
        devLogConsole(aliceUserID);
        if (currentRepId != null && currentRepId != undefined) {
          dispatch(processAliceReport(aliceUserID, orgId, id, currentRepId,currentProc === "true" ? true : false));
        } else {
          dispatch(processAliceReport(aliceUserID, orgId, id, null,null));
        }
        setProcessReport(false);
      }
    }
  }, [processReport]);

  useInterval(() => {
    devLogConsole(aliceReport);
    if (aliceReport && aliceReport.toString().includes("Success")) {
      window.open("about:blank", "_self");
      const arr = window.location.href.split("::unik::");
      devLogConsole(arr.length)
      devLogConsole(arr)
      if (arr.length > 3) {
        window.history.go(-1);
      } else {
        window.close();
      }
    }
  }, 1000 * 10);

  function onSuccess() {
    setProcessReport(true);
    devLogConsole("Success");
    dispatch(setAliceUserToken(""));
  }

  function onFailure() {
    devLogConsole("Failure");
    dispatch(setAliceUserToken(""));
  }

  function onCancel() {
    devLogConsole("Cancel");
    dispatch(setAliceUserToken(""));
  }

  return (
    <div className="overlay">
      {(!id || isLoadingGetAliceToken || isProcessingAliceReport) && (
        <Spinner spinning={true} />
      )}
      <div
        className="alice-onboarding-wrapper"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          marginBottom: "10px",
        }}
      >
        <div id="alice-onboarding-mount" />
      </div>
      <div
        className={"easypay-card-wrapper"}
        style={{
          //marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {
          <Text
            text={
              Object.keys(aliceReport).length == 0 ? "" : aliceReport.toString()
            }
            align={"center"}
            icon={
              aliceReport.toString().toLocaleLowerCase().includes("success")
                ? "icon-green-checkmark"
                : aliceReport.toString().toLocaleLowerCase().includes("error")
                ? "icon-alert"
                : ""
            }
          />
        }

        {aliceUserToken === "" &&
          !isLoadingGetAliceToken &&
          !isProcessingAliceReport &&
          aliceReport &&
          !aliceReport.toString().includes("Success") && (
            <CustomButton
              text={"try again"}
              variant={"easypay-button-secondary-large"}
              onclick={() => {
                {
                  if (id && orgId) {
                    dispatch(getAliceToken(id, orgId));
                    dispatch(setAliceReport({}));
                  }
                }
              }}
              className="card-button"
            />
          )}
      </div>
    </div>
  );
};
export default withNamespaces()(AliceProcess);
