import { DocumentStatus, BatchStatus, FlowType } from './DataCaptureEnum';

export const getColorByStatusDocument = (status) => {
  switch (status) {
    case DocumentStatus.PENDING:
      return 'warning'
    case DocumentStatus.WAITING_SUBMISSION:
      return 'warning'
    case DocumentStatus.SUCCESS:
      return 'primary'
    case DocumentStatus.FAILED:
      return 'danger'
    case DocumentStatus.REVISED:
      return 'info'
    case DocumentStatus.LOADED:
      return 'info'
    case DocumentStatus.REVIEWED:
      return 'success'
    case DocumentStatus.REJECTED:
      return 'danger'
    default:
      return ''
  }
}

export const translateStatusDocument = (status) => {
  switch (status) {
    case DocumentStatus.PENDING:
      return 'datacapture.document_status.pending'
    case DocumentStatus.WAITING_SUBMISSION:
      return 'datacapture.document_status.pending'
    case DocumentStatus.SUCCESS:
      return 'datacapture.document_status.success'
    case DocumentStatus.FAILED:
      return 'datacapture.document_status.failed'
    case DocumentStatus.REVISED:
      return 'datacapture.document_status.revised'
    case DocumentStatus.LOADED:
      return 'datacapture.document_status.loaded'
    case DocumentStatus.REVIEWED:
      return 'datacapture.document_status.reviewed'
    case DocumentStatus.REJECTED:
      return 'datacapture.document_status.rejected'
    default:
      return ''
  }
}

export const getColorByStatusBatch = (status) => {
  switch (status) {
    case BatchStatus.WAITING_SUBMISSION:
      return 'warning'
    case BatchStatus.SUBMITTED:
      return 'success'
    default:
      return ''
  }
}

export const translateStatusBatch = (status) => {
  switch (status) {
    case BatchStatus.WAITING_SUBMISSION:
      return 'datacapture.batch_status.waiting_submission'
    case BatchStatus.SUBMITTED:
      return 'datacapture.batch_status.submitted'
    default:
      return ''
  }
}

export const translateFlow = (flow) => {
  switch (flow) {
    case FlowType.INVOICE:
      return 'datacapture.flow.invoice'
    case FlowType.CITIZEN_CARD:
      return 'datacapture.flow.citizen_card'
    default:
      return ''
  }
}
