import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  POST_OPENAI_CHAT,
  GET_OPENAI_CHAT,
  GET_OPENAI_CHAT_LIST,
  SAVE_DOCUMENT_EXPENSES,
  POST_CREATE_DOCUMENT_EXPENSES,
  POST_IFLOW_FORM_EXPENSES,
  GET_DOCUMENT_EXPENSES_LIST,
  GET_DOCUMENT_EXPENSES_SPECIFIC,
  GENERATE_PROMPT,
  GENERATE_PROMPT_FROM_PROMPTS,
  SAVE_FLOWID_PID_BATCH,
} from "../ducks/openai/actionTypes";

import {
  postOpenAiChat,
  getOpenAiChat,
  getOpenAiChatsList,
  saveDocumentExpenses,
  postCreateDocumentExpenses,
  postIflowFormExpenses,
  getDocumentExpensesList,
  getDocumentExpensesSpecific,
  generatePrompt,
  generatePromptFromPrompts,
  saveFlowIDPidBatch,
} from "../ducks/openai/sagas";

export const openaiSagas = [
  takeLatest(POST_OPENAI_CHAT, postOpenAiChat),
  takeLatest(GET_OPENAI_CHAT, getOpenAiChat),
  takeLatest(GET_OPENAI_CHAT_LIST, getOpenAiChatsList),
  takeLatest(SAVE_DOCUMENT_EXPENSES, saveDocumentExpenses),
  takeLatest(POST_CREATE_DOCUMENT_EXPENSES, postCreateDocumentExpenses),
  takeLatest(POST_IFLOW_FORM_EXPENSES, postIflowFormExpenses),
  takeLatest(SAVE_FLOWID_PID_BATCH, saveFlowIDPidBatch),
  takeLatest(GET_DOCUMENT_EXPENSES_LIST, getDocumentExpensesList),
  takeLatest(GET_DOCUMENT_EXPENSES_SPECIFIC, getDocumentExpensesSpecific),
  takeLatest(GENERATE_PROMPT, generatePrompt),
  takeLatest(GENERATE_PROMPT_FROM_PROMPTS, generatePromptFromPrompts),
];
