import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Spinner, Row, Container } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import {
  toggleCheck,
  getCheck,
  setblockCheckIn,
  clearCheck,
} from "~/store/ducks/portalrh/actionTypes";
import CustomDropdown from "../CustomDropdown";
import "./transparentCard.css";
import { updateUserWidget } from "~/pages/User/actions";
import Toast from "react-bootstrap/Toast";

const CheckInAndOutWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;

  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);

  const { isCheckedIn, isLoadingCheck, reachedMaximumChecks, isblockCheckIn } =
    useSelector((state) => state.portalrhReducer);

  useEffect(() => {
    if (isblockCheckIn) {
      setShowToast(true);
      dispatch(clearCheck());
    } else setShowToast(false);
  }, [isblockCheckIn]);

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
      dispatch(setblockCheckIn(false));
    }, 50000);
  }, [showToast]);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "4",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    dispatch(getCheck());
  }, []);

  const toastResult = () => {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-fixed"
        style={{
          zIndex: "100",
          padding: "0px 15px",
          backgroundColor: "transparent!important",
        }}
      >
        <Toast>
          <Toast.Body className="bg-danger" style={{ color: "white" }}>
            Não pode registar presença, tem dia de férias marcado!
            {/* {t("portalrh.abscenses.exportSucess")} */}
          </Toast.Body>
        </Toast>
      </div>
    );
  };

  return (
    // style={{background: "rgba(255,255,255,0.4)"}} ------ style={{opacity: ".4"}} ----- style={{border: "none", opacity: ".2"}}
    <div className="main-card-v2">
      <Card bsPrefix="card-flat" style={{ padding: "2rem" }}>
        <Card.Header className="justify-content-between">
          {" "}
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("CheckInAndOutWidget.title")}{" "}
          </h6>{" "}
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ display: "flex", placeContent: "space-evenly" }}>
            <div style={{ textAlignLast: "center", marginBottom: "1rem" }}>
              <Button
                size="lg"
                className="card-button btn btn-primary checkin-landing-button "
                style={{
                  fontSize: "1rem",
                }}
                onClick={() => {
                  dispatch(toggleCheck(t));
                }}
                disabled={isCheckedIn || isLoadingCheck || reachedMaximumChecks}
              >
                {!isLoadingCheck ? (
                  t("portalrh.login.checkin")
                ) : (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {t("portalrh.login.loading")}
                  </>
                )}
              </Button>
            </div>
            <div style={{ textAlignLast: "center" }}>
              <Button
                size="lg"
                className="card-button btn btn-primary checkin-landing-button"
                style={{
                  fontSize: "1rem",
                }}
                onClick={() => {
                  dispatch(toggleCheck(t));
                }}
                disabled={
                  !isCheckedIn || isLoadingCheck || reachedMaximumChecks
                }
              >
                {!isLoadingCheck ? (
                  t("portalrh.login.checkout")
                ) : (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {t("portalrh.login.loading")}
                  </>
                )}
              </Button>
            </div>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default withNamespaces()(CheckInAndOutWidget);
