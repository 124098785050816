export const DocumentStatus = {
  UPLOADING: 'UPLOADING',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  REVISED: 'REVISED',
  REVIEWED: 'REVIEWED',
  REJECTED: 'REJECTED',
  LOADED: 'LOADED',
  WAITING_SUBMISSION: 'WAITING_SUBMISSION',
  TERMINATED: 'TERMINATED',
  REJECTED_IFLOW: "REJECTED_IFLOW",
  REVIEWED_IFLOW: "REVIEWED_IFLOW",
  HUMAN_REVISION: "HUMAN_REVISION",
}

export const BatchStatus = {
  WAITING_SUBMISSION: 'WAITING_SUBMISSION',
  SUBMITTED: 'SUBMITTED'
}

export const FlowType = {
  INVOICE: 'INVOICE',
  /*CITIZEN_CARD: 'CITIZEN CARD'*/
}
