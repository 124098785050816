import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SimpleInput from "~/pages/Easypay/components/SimpleInput";
import BenefModalRadioButtonWrapper from "~/pages/Easypay/components/BenefModalRadioButtonWrapper";
import RadioButtonItem from "~/pages/Easypay/components/RadioButtonItem";
import uuid from "uuid/v1";

const CreatePepTypeDropdown = ({ field }) => {
  const [sitesList, setSitesList] = useState(["", "", "", "", ""]);
  const [showTextInput, setShowTextInput] = useState(false);
  const [currentPepType, setCurrentPepType] = useState("");

  function buildInputField(text, variable) {
    return {
      value: "",
      obligatory: false,
      variable: variable,
      maxlenght: 9,
      text: text,
    };
  }

  useEffect(() => {
    if (document.dados["risk_id"]) {
      if (currentPepType === "Sim") {
        if (field.text.split("::")[6] === "pagamentos")
          document.dados["risk_id"].value = 60;
        else document.dados["risk_id"].value = 87;
      } else {
        if (field.text.split("::")[6] === "pagamentos")
          document.dados["risk_id"].value = 12;
        else document.dados["risk_id"].value = 86;
      }
    }
  }, [currentPepType]);

  const handleInputChange = (e, index) => {
    var newArray = sitesList;
    newArray[index] = e.target.value;
    setSitesList(newArray);
    var currentStr = "";
    if (document.dados["infoAdicionalSingle"]) {
      for (var i = 0; i < sitesList.length; i++) {
        if (sitesList[i] != "") {
          currentStr = currentStr + sitesList[i] + ",";
        }
      }
      if (currentStr.length > 0) currentStr = currentStr.slice(0, -1);
      document.dados["infoAdicionalSingle"].value = currentStr;
    }
  };

  return (
    <Form.Group
      controlId={`formSelect-${field.variable}`}
      className={`${showTextInput ? "easypay-no-margin-bottom" : ""}`}
    >
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-column"}
        activeTab={currentPepType}
        setActivetab={setCurrentPepType}
        displayType={"column"}
      >
        <RadioButtonItem
          key={uuid()}
          text={"Não"}
          uniqueName={"pepTypeAccordion"}
          index={0}
          name={"Não"}
          subHeader={""}
          displayType={"column"}
        ></RadioButtonItem>
        <RadioButtonItem
          key={uuid()}
          text={"Sim"}
          uniqueName={"pepTypeAccordion"}
          index={1}
          name={"Sim"}
          subHeader={""}
          displayType={"column"}
        ></RadioButtonItem>
      </BenefModalRadioButtonWrapper>
      {currentPepType === "Sim" && (
        <>
          <SimpleInput
            field={buildInputField("Site 1", "")}
            key={uuid()}
            handleOnBlur={handleInputChange}
            index={0}
          />
          <SimpleInput
            field={buildInputField("Site 2", "")}
            key={uuid()}
            handleOnBlur={handleInputChange}
            index={1}
          />
          <SimpleInput
            field={buildInputField("LinkedIn", "")}
            key={uuid()}
            handleOnBlur={handleInputChange}
            index={2}
          />
          <SimpleInput
            field={buildInputField("Facebook", "")}
            key={uuid()}
            handleOnBlur={handleInputChange}
            index={3}
          />
          <SimpleInput
            field={buildInputField("Instagram", "")}
            key={uuid()}
            handleOnBlur={handleInputChange}
            index={4}
          />
        </>
      )}
    </Form.Group>
  );
};
export default CreatePepTypeDropdown;
