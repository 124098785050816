import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import { PORTALRH_MIDDLEWARE, RH_MIDDLEWARE } from "~/utils/constants";
import { activeError } from "~/components/Messages/actions";

import { setUOSiadapra, setUOSiadapraDropdown } from "./actionTypes";

export function* getUOSiadapra() {
  try {
    const resp = yield http.get("/api/unidade-organica/responsaveis/3/3", PORTALRH_MIDDLEWARE);
    yield put(setUOSiadapra(resp.data));
  } catch (e) {
    yield put(setUOSiadapra([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getUOSiadapraDropdown() {
  try {
    const resp = yield http.get("/api/sistema-avaliacao", PORTALRH_MIDDLEWARE);
    yield put(setUOSiadapraDropdown(resp.data));
  } catch (e) {
    yield put(setUOSiadapraDropdown([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getUOSiadapraDetail({ data }) {
  try {
    if (data.perId) {
      const resp = yield http.get(
        `/api/escala/tipo/${data.sisId}/${data.perId}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setUOSiadapra(resp.data));
    } else {
      const resp = yield http.get(
        `/api/escala/tipo-servicos/${data.sisId}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setUOSiadapra(resp.data));
    }
  } catch (e) {
    yield put(setUOSiadapra([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getUOSiadapraDetailOrg({ data }) {
  try {
      const resp = yield http.get(
        `/api/escala/tipo-detail-servicos/${data.sisId}/${data.perId}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setUOSiadapra(resp.data));
  } catch (e) {
    yield put(setUOSiadapra([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
