import React, { createRef, useEffect, useState } from "react"
import FocusWithin from './focus'
import './hourRange.scss'

const HourRange = ({ time = [ '', '' ], onChange }) => {

    const [ isHoursFocused, setIsHoursFocused ] = useState(false)
    const [ isMinFocused, setIsMinFocused ] = useState(false)
    const [ list, setList ] = useState({ selecting: '' })
    const hourRef = createRef()
    const minRef = createRef()

    const clampHours = (hour) => (Number(hour) < 0 ? 0 : Number(hour) > 23 ? 23 : hour)
    const clampMinutes = (min) => (Number(min) < 0 ? 0 : Number(min) > 59 ? 59 : min)

    useEffect(() => {
        setList({...list, selecting: isHoursFocused ? 'hour' : isMinFocused ? 'min' : list.selecting })
    }, [ isHoursFocused, isMinFocused ])

    const onInputChange = (name, e) => {
        var newVal = e.replace(/[^\d]/g, '')
        
        if (newVal.length > 2 && name == 'hour') {
            minRef.current.focus()
            name = 'min'
            hourRef.current.value = clampHours(newVal.substring(0, 2))
            newVal = newVal.substring(newVal.length - 1)
        }

        if (newVal != '')
            newVal = (name == 'hour' ? clampHours(newVal) : clampMinutes(newVal))

        if (newVal != '' && Number(newVal) == NaN)
            newVal = 0

        if (newVal.length > 2)
            newVal = newVal.substring(0, 2)

        if (onChange) {
            if (newVal == '')
                hourRef.current.focus()
            onChange([name == 'hour' ? newVal : hourRef.current.value.substring(0, 2), name == 'min' ? newVal : minRef.current.value.substring(0, 2)])    
        }
    }

    return (
        <FocusWithin>
            {({focused, getRef}) => (
                <div tabIndex='0' ref={getRef} className="element-hour-range">
                    <input autoComplete="off" value={time[0]} placeholder="--" onFocus={() => setIsHoursFocused(true)} onBlur={() => setIsHoursFocused(false)} onChange={(e) => onInputChange('hour', e.target.value)} type='number' max='23' min='0' ref={hourRef}/>:
                    <input autoComplete="off" value={time[1]} placeholder="--" onFocus={() => setIsMinFocused(true)} onBlur={() => setIsMinFocused(false)} onChange={(e) => onInputChange('min', e.target.value)} type='number' max='59' min='0' ref={minRef} />
                    <div tabIndex='0'>
                        <div tabIndex='0' className={"element-hour-range-selector" + (focused ? " active" : "")}>
                            {[...Array(list.selecting == 'hour' ? 24 : 12).keys()].map((e, i) => {
                                var num = e * (list.selecting == 'hour' ? 1 : 5)
                                return <span key={i} onClick={() => { onInputChange(list.selecting, (num).toString()); document.body.focus() }} >{`${num}`.length == 1 ? ("0" + num) : num}</span>
                            })}
                        </div>
                    </div>
                </div>
            )}
        </FocusWithin>
    )
}

export default HourRange