import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_lineBreak.scss";
import "../assets/scss/_typography.scss";

const LineBreak = ({ text, t }) => {
  if (text != "none")
    return (
      <div className="easypay-line-break-wrapper">
        <div className="easypay-line-break-line"></div>
        <div className="easypay-line-break">{t(text)}</div>
        <div className="easypay-line-break-line"></div>
      </div>
    );
  else
    return (
      <div className="easypay-line-break-wrapper">
        <div className="easypay-line-break-line"></div>
      </div>
    );
};

export default withNamespaces()(LineBreak);
