import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import "dayjs/locale/es";
import { ptBR, enUS, esES } from "@mui/x-date-pickers/locales";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ModalDocument = ({
  openModal,
  setOpenModal,
  handleResetState,
  documentValues,
  setDocumentValues,
  image,
  t,
}) => {
  const { documentExpensesSpecific } = useSelector(
    (state) => state.openaiReducer
  );

  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  const [localeTranslations, setlocaleTranslations] = useState("en");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      if (userSettings?.language?.trim() == "pt")
        setlocaleTranslations("pt-br");
      if (userSettings?.language?.trim() == "es") setlocaleTranslations("es");
      if (userSettings?.language?.trim() == "en") setlocaleTranslations("en");
    }
  }, [userSettings]);

  useEffect(() => {
    if (
      Object.keys(documentExpensesSpecific).length > 0 &&
      documentExpensesSpecific.base64
    ) {
      setDocumentValues({
        ...documentExpensesSpecific.metadata,
        base64: documentExpensesSpecific.base64,
      });
    }
  }, [documentExpensesSpecific]);

  const generatePdfView = () => {
    function createPdfBlob(datadoc) {
      const base64String = atob(datadoc);
      const bytes = new Uint8Array(base64String.length);
      for (let i = 0; i < base64String.length; i++) {
        bytes[i] = base64String.charCodeAt(i);
      }
      return new Blob([bytes.buffer], { type: "application/pdf" });
    }

    const datadoc = documentValues.base64;
    const pdfBlob = createPdfBlob(datadoc);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return pdfUrl;
  };

  const generateImageView = () => {
    return "data:image/jpg;base64, " + documentValues.base64;
  };

  const getLocaleText = () => {
    let lang = enUS;
    if (userSettings && userSettings.language != undefined) {
      if (userSettings?.language.trim() === "pt") {
        lang = ptBR;
      }
      if (userSettings?.language.trim() === "es") {
        lang = esES;
      }
    }
    return lang.components.MuiLocalizationProvider.defaultProps.localeText;
  };

  return (
    <>
      <Modal
        show={openModal && Object.keys(documentExpensesSpecific).length > 0}
        dialogClassName={"modal-tarefa-60w custom-modal"}
        centered
        backdrop="static"
        onHide={handleResetState}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalhe da Despesa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "600px",
              marginBottom: "1rem",
            }}
          >
            <Form>
              <Row>
                <Col md={6}>
                  {Object.keys(documentValues).map(
                    (key, index) =>
                      key !== "base64" && (
                        <Form.Group className="mb-3" key={index}>
                          <Form.Label>{t(`openai.${key}`)}</Form.Label>
                          {key === "invoiceDate" ? (
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={localeTranslations}
                              localeText={getLocaleText()}
                            >
                              <input
                                type="hidden"
                                name="date"
                                value={dayjs(documentValues[key]).format(
                                  "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                )}
                              />

                              <DatePicker
                                sx={{
                                  height: "44px",
                                  borderRadius: "23px",
                                  backgroundColor: "#e9ecef",
                                }}
                                className="datePickerExpenses"
                                value={dayjs(documentValues[key])}
                                ampm="false"
                                DateTimeFormat={Intl.DateTimeFormat}
                                disabled
                              />
                            </LocalizationProvider>
                          ) : (
                            <Form.Control
                              autoComplete="off"
                              required
                              type="text"
                              value={documentValues[key] || ""}
                              disabled
                              style={{ color: "rgba(0, 0, 0, 0.38)" }}
                            />
                          )}
                        </Form.Group>
                      )
                  )}
                </Col>
                <Col md={6}>
                  {!isMobile && documentValues.base64 && (
                    <>
                      <br />
                      {image ? (
                        <div style={{ textAlignLast: "center" }}>
                          <Zoom>
                            <img
                              src={generateImageView()}
                              style={{ width: "auto", height: "435px" }}
                              alt="Document"
                            />
                          </Zoom>
                        </div>
                      ) : (
                        <iframe
                          id="pdf-document"
                          title="pdf-document"
                          src={generatePdfView()}
                          width="100%"
                          height="500px"
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            margin="0px"
            justifyContent="flex-end"
          >
            <Button className="card-button" onClick={handleResetState}>
              {t("openai.close")}
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withNamespaces()(ModalDocument);
