import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button as ButtonBoot,
  Card,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import Calendario from "./Calendario";
import { withNamespaces } from "react-i18next";
import { Button, Stack } from "@mui/material";

import EventItem from "./EventItem";

const AlertMaterial = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Scheduler = (props) => {
  const {
    allEvents,
    setAllEvents,
    isEditable,
    designacao,
    selectedLocale,
    hideChangeView,
    localizer,
    callUpdateMarcacaoFerias,
    callSaveWorkDays,
    t,
  } = props;
  const [messageToSnackBar, setMessageToSnackBar] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severitySnackBar, setSeveritySnackBar] = useState("");
  const [allEventsTemp, setAllEventsTemp] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalToRemove, setShowModalToRemove] = useState(false);

  const handleExport = (file) => {};

  const removeDayFromEvents = (day) => {
    const updatedEvents = allEventsTemp.map((event) => {
      if (event.type === day.type && event.id === day.id) {
        return { ...event, removeDay: true };
      }
      return event;
    });
    setAllEventsTemp(updatedEvents);
  };

  const syncEventsWithTemp = () => {
    // Cria uma cópia de allEvents para modificar
    let updatedEvents = [...allEvents];

    // Itera sobre allEventsTemp
    allEventsTemp.forEach((tempEvent) => {
      // Encontra o índice do evento correspondente em allEvents
      const eventIndex = updatedEvents.findIndex(
        (event) => event.id === tempEvent.id
      );

      if (tempEvent.removeDay) {
        if (eventIndex !== -1) updatedEvents.splice(eventIndex, 1);
      } else {
        if (eventIndex !== -1) updatedEvents[eventIndex] = { ...tempEvent };
        else updatedEvents.push({ ...tempEvent });
      }
    });

    setAllEvents(updatedEvents);
  };

  const closeModal = () => {
    setShowModal(false);
    syncEventsWithTemp();
  };
  const closeModalToRemove = () => {
    setShowModalToRemove(false);
    setAllEventsTemp([]);
  };

  const updateEventState = (eventId, updatedProperties) => {
    const updatedEvents = allEventsTemp.map((event) => {
      if (event.id === eventId) {
        return { ...event, ...updatedProperties };
      }
      return event;
    });

    setAllEventsTemp(updatedEvents);
  };

  const handleSaveWorkDays = () => {
    callSaveWorkDays(allEventsTemp);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        //onClose={handleCloseExport}
      >
        <AlertMaterial
          //onClose={handleCloseExport}
          severity={severitySnackBar}
          sx={{ width: "100%" }}
        >
          {messageToSnackBar}
        </AlertMaterial>
      </Snackbar>

      <>
        <div
          style={{
            padding: !isEditable ? "" : !hideChangeView ? "" : "10px",
          }}
        >
          <Row>
            <Col
              lg={!isEditable ? "8" : "12"}
              md={!isEditable ? "8" : "12"}
              sm="12"
              style={{ padding: 0 }}
            >
              {selectedLocale && (
                <Calendario
                  allEvents={allEvents}
                  isEditable={isEditable}
                  selectedLocale={selectedLocale}
                  localizer={localizer}
                  setAllEvents={setAllEvents}
                  handleExport={handleExport}
                  setAllEventsTemp={setAllEventsTemp}
                  callUpdateMarcacaoFerias={callUpdateMarcacaoFerias}
                  setShowModal={setShowModal}
                  setShowModalToRemove={setShowModalToRemove}
                  allEventsTemp={allEventsTemp}
                />
              )}
            </Col>
          </Row>
        </div>
        {showModal && (
          <Modal
            show={showModal}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h4>{t("portalrh.workDays.listmarkedDays")}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup
                style={{
                  overflowY: "auto",
                  maxHeight: "350px",
                  padding: "10px",
                }}
                className="scrollBar-visible-year"
              >
                {allEventsTemp
                  .filter((event) => !event.removeDay)
                  .map((event) => (
                    <EventItem
                      key={event.id}
                      event={event}
                      updateEventState={updateEventState}
                      removeDayFromEvents={removeDayFromEvents}
                      disabled={false}
                    />
                  ))}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={closeModal}
                >
                  {t("portalrh.workDays.close")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={handleSaveWorkDays}
                >
                  {t("portalrh.workDays.submitDay")}
                </Button>
              </Stack>
            </Modal.Footer>
          </Modal>
        )}
        {showModalToRemove && (
          <Modal
            show={showModalToRemove}
            onHide={closeModalToRemove}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h4>
                  {allEventsTemp.some(
                    (event) => event.type === "feriasPendentes"
                  )
                    ? t("portalrh.workDays.reuqestCanceled")
                    : t("portalrh.workDays.requestFeriasCanceled")}
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!allEventsTemp.some(
                (event) => event.type === "feriasPendentes"
              ) && (
                <Form.Label>
                  {" "}
                  {t("portalrh.workDays.selectDaysToRemove")}
                </Form.Label>
              )}
              <ListGroup
                style={{
                  overflowY: "auto",
                  maxHeight: "350px",
                  padding: "10px",
                }}
                className="scrollBar-visible-year"
              >
                {allEventsTemp.map((event) => (
                  <EventItem
                    key={event.id}
                    event={event}
                    updateEventState={updateEventState}
                    removeDayFromEvents={removeDayFromEvents}
                    disabled={true}
                  />
                ))}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={closeModalToRemove}
                >
                  {t("portalrh.workDays.close")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={handleSaveWorkDays}
                >
                  {t("portalrh.workDays.submitDay")}
                </Button>
              </Stack>
            </Modal.Footer>
          </Modal>
        )}
      </>
    </>
  );
};
export default withNamespaces()(Scheduler);
