import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setProcessFormMenuItemClicked } from "~/containers/Layout/actions";

const PortalIframeHandler = ({ name }) => {

  const dispatch = useDispatch();
  const { currentFormMenuItemClicked } = useSelector(state => state.globalReducer);

    useEffect(() => {
        if (name && document.getElementById(name) && currentFormMenuItemClicked===true) {
            /*Display iframe*/
            document.getElementById(name).style.display = "block";
            dispatch(setProcessFormMenuItemClicked(false));
        }
       
    }, [name, window.location.href, currentFormMenuItemClicked])
    return (
        <></>
    )
}
export default PortalIframeHandler