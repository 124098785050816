import {
  SET_NOTIFICATION_UPDATED,
  DELETE_NOTIFICATION,
  SET_DELETED_NOTIFICATION,

  SET_USER_PORTALRH_NOTIFICATIONS,
  SET_USER_BPM_NOTIFICATIONS,
} from "./actionTypes";

const initialState = {
  notificationUpdated: false,
  isDeletingNotification: false,

  portalRHNotifications: [],
  bpmNotifications: [],
}

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_UPDATED:
      return { ...state, notificationUpdated: action.updated }
    case DELETE_NOTIFICATION:
      return { ...state, isDeletingNotification: true }
    case SET_DELETED_NOTIFICATION:
      return { ...state, isDeletingNotification: false }

    case SET_USER_PORTALRH_NOTIFICATIONS:
      return { ...state, portalRHNotifications: action.portalRHNotifications }
    case SET_USER_BPM_NOTIFICATIONS:
      return { ...state, bpmNotifications: action.bpmNotifications }
    
    default:
      return state;
  }
}