import {
  SET_RULES,
  GET_RULES,
  CLEAR_RULES,
  GET_RULE_INFORMATION_BY_ID,
  SET_RULE_INFORMATION_BY_ID,
  UPDATE_UNIK_USERS_RULE,
  UPDATE_UNIK_USERS_DONE_RULE,
  PERSIST_RULE,
  PERSIST_RULE_DONE,
  FIND_USERS_BY_RULE,
  SET_FIND_USERS_BY_RULE,
  DELETE_RULE,
  DELETE_RULE_DONE,
} from "../actions";
import update from "react-addons-update";
import { findIndex, sortBy, find } from "lodash";

const initialState = {
  rules: [],
  isLoadingRules: false,
  ruleById: [],
  isLoadingRulesByID: false,
  isPersisting: false,
  isLoadingUsers: false,
  users: [],
  isDeleting: false,
};

export const ruleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RULES:
      return { ...state, isLoadingRules: true };
    case SET_RULES:
      return {
        ...state,
        rules: action.rules,
        isLoadingRules: false,
      };
    case CLEAR_RULES:
      return { ...state, rules: [] };
    case GET_RULE_INFORMATION_BY_ID:
      return { ...state, isLoadingRulesByID: true };
    case SET_RULE_INFORMATION_BY_ID:
      return { ...state, isLoadingRulesByID: false, ruleById: action.rule };
    case UPDATE_UNIK_USERS_RULE:
      return { ...state, isPersisting: true };
    case UPDATE_UNIK_USERS_DONE_RULE:
      return { ...state, isPersisting: false };
    case PERSIST_RULE:
      return { ...state, isPersisting: true };
    case PERSIST_RULE_DONE:
      return { ...state, isPersisting: false };
    case FIND_USERS_BY_RULE:
      return { ...state, isLoadingUsers: true };
    case SET_FIND_USERS_BY_RULE:
      return {
        ...state,
        isLoadingUsers: false,
        users: action.users,
      };
    case DELETE_RULE:
      return { ...state, isDeleting: true };
    case DELETE_RULE_DONE:
      return { ...state, isDeleting: false };
    default:
      return state;
  }
};
