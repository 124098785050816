import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { msalInstance, msalInstanceNB } from "~/utils/msalOAuth";
import { loginRequest } from "~/utils/msalOAuth/AuthConfig";
import { microsoftSignIn } from "../actions";

const MicrosoftAuthentication = ({ t, isNB }) => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    if(isNB){
      msalInstanceNB
      .loginPopup(loginRequest)
      .then((response) => {
        if (response !== null) {
          if (response.idToken) {
            dispatch(microsoftSignIn(response));
          }
        } else {
          const currentAccounts = msalInstanceNB.getAllAccounts();
          if (currentAccounts === null) {
            return;
          } else if (currentAccounts.length > 1) {
            // Add choose account code here
            console.warn("Multiple accounts detected.");
          } else if (currentAccounts.length === 1) {
            const username = currentAccounts[0].username;
            //test this if
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    } else{
      msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        if (response !== null) {
          if (response.idToken) {
            dispatch(microsoftSignIn(response));
          }
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts === null) {
            return;
          } else if (currentAccounts.length > 1) {
            // Add choose account code here
            console.warn("Multiple accounts detected.");
          } else if (currentAccounts.length === 1) {
            const username = currentAccounts[0].username;
            //test this if
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  return (
    <button
      className="btn-microsoft"
      onClick={() => handleLogin()}
      style={isNB ? { backgroundColor: "#00b9b2", color:'white' } : {}}
    >
      <div style={{ display: "flex" }}>
        <object
          type="image/svg+xml"
          data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
          className="x-icon"
        ></object>
        <div
          style={{
            flexGrow: "1",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isNB ? "Login Integrado" : "Microsoft"}
        </div>
      </div>
    </button>
  );
};

export default withNamespaces()(MicrosoftAuthentication);
