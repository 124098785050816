import React, { useState } from "react";

import logo from "~/assets/img/logos/unik-logo.png";
import keycloak from "../../pages/Login/keycloak";

const Error404 = () => {
  const [isError, setIsError] = useState(false);

  setTimeout(function () {
    setIsError(!keycloak.authenticated);
  }, 1000);

  return isError ? (
    <div style={{ position: "fixed", bottom: "0" }}>
      <div
        style={{
          transform: "translate(150%, -50%)",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            className="img-fluid unik-logo-500"
            src={logo}
            alt="Uniksystem"
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: "150px", marginBottom: -60 }}>404</div>
          <div style={{ fontSize: "50px", fontWeight: 700, color: "gray" }}>
            Page not found
          </div>
          <small style={{ color: "gray" }}>
            The page you are looking for doesn't exist or another error
            occurred.
          </small>
        </div>
      </div>
    </div>
  ) : null;
};
export default Error404;
