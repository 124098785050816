import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Card } from "react-bootstrap";

import { getRules, clearRules } from "./actions";
import RulesTable from "./components/RulesTable";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const Rules = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rules } = useSelector((state) => state.ruleReducer);

  useEffect(() => {
    dispatch(getRules());
    return () => {
      dispatch(clearRules());
    };
  }, [dispatch]);

  const persistRule = () => {
    history.push("/uniksystem/portalrh/administration/rulesRemoteWork/create");
  };
  

  return (
    <>
      <div className="main-card-v2" style={{ height: "fit-content" }}>
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>{t("admin.rule.list.cardTitle")}</h6>
          </Card.Header>
          <Card.Body>
            <RulesTable rules={rules} persistRule={persistRule} />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withNamespaces()(Rules);
