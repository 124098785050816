import React from "react";
import { withNamespaces } from "react-i18next";
import Spinner from "~/pages/Easypay/components/Spinner";

const LoadingPage = () => {
  return (
    <div className="overlay">
      <Spinner spinning={true} />
    </div>
  );
};
export default withNamespaces()(LoadingPage);
