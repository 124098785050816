import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { GDPR_MIDDLEWARE } from "~/utils/constants";
import { TabsKey } from "../../utils/TabsEnum";
import ApplicationCard from "./ApplicationCard";


const GDPRComponent = ({ tabKey }) => {
  const dispatch = useDispatch();

  const { isGDPRON, loadingGDPR } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(actions.pingMiddlewareApplication(GDPR_MIDDLEWARE));
      const payloadIFlow = {
        url: "/main.jsp",
        application: {
          context: "GDPR",
        },
      };
      dispatch(actions.pingIFlowBasedApplication(payloadIFlow));
    }
  }, [tabKey]);

  return (
    <ApplicationCard
      name="GDPR"
      status={isGDPRON}
      loading={loadingGDPR}
      showIFlow={true}
      showMW={true}
    />
  );
};

export default withNamespaces()(GDPRComponent);
