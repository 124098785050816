import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Alert as AlertBoot,
  Card,
  Button,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import "~/assets/css/icons.css";
import PlanoPreviewCard from "./PlanoPreviewCard";
import ButtonDownload from "~/components/Buttons/DownloadButton";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Dropdown from "~/components/Dropdown";

import {
  getPlanoFormacao,
  uploadPlanoFormacao,
  setUploadPlanoFormacao,
  setDownloadPlanoFormacao,
  getEntitiesFormacao,
} from "~/store/ducks/processes/actionTypes";
import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
const devLogConsole = require("~/utils/devLog");

const RHPlanosFormacao = ({ t }) => {
  const SUPPORTED_FILES_TYPES = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const {
    uploadPlanoFormacaoMessage,
    isLoadingPlanoFormacao,
    downloadPlanoFormacaoMessage,
    entitiesFormacao,
  } = useSelector((state) => state.processesReducer);

  const [showExtraFields, setShowExtraFields] = useState(false);
  const [plano, setplano] = useState();
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [onDragOver, setOnDragOver] = useState(false);
  const [base64File, setBase64File] = useState("");
  const [selectedEntityId, setSelectedEntityId] = useState("");
  const [resetEntitiesDropdown, setResetEntitiesDropdown] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [severityMessage, setSeverityMessage] = useState("error");
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [selectedEntitie, setSelectedEntitie] = useState("");

  const maxFileSize = 1024 * 1024 * 15; //15 Mb

  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
    setAlertModal(false);
    dispatch(setUploadPlanoFormacao(null));
    dispatch(setDownloadPlanoFormacao(null));
    setSelectedEntitie("");
    dispatch(getEntitiesFormacao());
  }, []);

  useEffect(() => {
    let message = null;
    let severity = null;

    if (uploadPlanoFormacaoMessage !== null) {
      message = uploadPlanoFormacaoMessage
        ? t("portalrh.documentManagement.uploadSuccess")
        : t("portalrh.documentManagement.uploadError");
      severity = uploadPlanoFormacaoMessage ? "success" : "error";
    } else if (downloadPlanoFormacaoMessage !== null) {
      message = downloadPlanoFormacaoMessage
        ? "Download efetuado com sucesso"
        : "Erro ao efetuar download";
      severity = downloadPlanoFormacaoMessage ? "success" : "error";
    }

    if (message && severity) {
      setMessageAlert(message);
      setSeverityMessage(severity);
      setAlertModal(true);
    }
  }, [uploadPlanoFormacaoMessage, downloadPlanoFormacaoMessage]);

  useEffect(() => {
    if (plano !== undefined && plano !== null) {
      getBase64(plano, (result) => {
        setBase64File(result.split(";base64,")[1]);
      });
    }
  }, [plano]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertModal(false);
    dispatch(setUploadPlanoFormacao(null));
    dispatch(setDownloadPlanoFormacao(null));
  };

  function getBase64(file, cb) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      devLogConsole("Error: ", error);
    };
  }

  const resetStates = () => {
    setShowExtraFields(false);
    setplano(undefined);
    setWarningMessage("");
    setShowWarning(false);
    setOnDragOver(false);
    setCleanDropdown(true);
    setSelectedEntitie("");
    // setSelectedEntityId("");
    // setResetEntitiesDropdown(true);
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    handlePlanoFile(event.dataTransfer.files[0]);
    event.preventDefault();
  };

  const handlePlanoFile = (file) => {
    if (!SUPPORTED_FILES_TYPES.includes(file.type)) {
      setWarningMessage(t("portalrh.documentManagement.checkFileType"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    } else {
      if (file.size > maxFileSize) {
        setWarningMessage(t("portalrh.documentManagement.exceedFileSize"));
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 10000);
      } else {
        setShowExtraFields(true);
        setplano(file);
      }
    }
  };

  const handleSaveplano = () => {
    const uploadData = {
      fileName: plano.name,
      suffix: "xlsx",
      base64File: base64File,
      empCode: selectedEntitie,
    };
    dispatch(uploadPlanoFormacao(uploadData));
    resetStates();
  };

  const handleDownload = () => {
    const payload = {
      empCode: selectedEntitie,
    };
    dispatch(getPlanoFormacao(payload));
    setCleanDropdown(true);
    setSelectedEntitie("");
  };

  const handleEntitieChange = (entitie) => {
    setSelectedEntitie(entitie);
  };

  const renderList = () => {
    return entitiesFormacao.map((entitie) => ({
      label: entitie?.designacao,
      value: entitie?.code,
      selected: false,
    }));
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingPlanoFormacao}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={alertModal}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severityMessage}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header
            className="justify-content-between"
            style={{ marginBottom: "20px" }}
          >
            <h6>{t("portalrh.rhadmin.formacoes.planosTitle")}</h6>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col>
                    {showWarning && (
                      <AlertBoot
                        variant="warning"
                        onClose={() => setShowWarning(false)}
                        dismissible
                      >
                        <strong>Alert: </strong>
                        {warningMessage}
                      </AlertBoot>
                    )}
                  </Col>
                </Row>
                {entitiesFormacao.length > 0 && (
                  <Row>
                    <Col md="4">
                      <Row>
                        <Col>
                          <Form.Label>
                            {t("portalrh.exportSchedule.entity")} *
                          </Form.Label>
                          <Dropdown
                            list={renderList()}
                            handleSubmit={handleEntitieChange}
                            emptyDefault={true}
                            resetDropdown={cleanDropdown}
                            setResetDropdown={setCleanDropdown}
                            flowId={selectedEntitie}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonDownload
                            text={t("tools.download")}
                            onClick={handleDownload}
                            disabled={selectedEntitie === ""}
                          />{" "}
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      {!showExtraFields ? (
                        <div>
                          <div
                            className="dropArea"
                            onDrop={(e) => handleOnDrop(e)}
                            onClick={() => handleClick()}
                            onDragEnter={() => setOnDragOver(true)}
                            onDragLeave={() => setOnDragOver(false)}
                            style={
                              selectedEntitie === ""
                                ? {
                                    background: "#f6f6f6",
                                    height: "10rem",
                                  }
                                : onDragOver
                                ? {
                                    background: "#fff",
                                    height: "10rem",
                                  }
                                : {
                                    height: "10rem",
                                  }
                            }
                          >
                            <span
                              style={{
                                pointerEvents:
                                  selectedEntitie === "" ? "none" : "",
                              }}
                            >
                              <i
                                className={
                                  "icon-submit portal-image-upload-icon-active"
                                }
                              />
                              <div
                                className={
                                  "portal-label-main-active slide-in-bottom"
                                }
                              >
                                {t("portalrh.documentManagement.dropToUpload")}
                              </div>
                            </span>
                            <input
                              id="file"
                              type="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handlePlanoFile(e.target.files[0])
                              }
                              disabled={selectedEntitie === ""}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <div className="dc-label">
                              {t("portalrh.documentManagement.totalFileSize") +
                                " 15 Mb, " +
                                t("portalrh.documentManagement.maxFiles") +
                                " 1"}
                            </div>
                            <small>
                              {t("portalrh.exportSchedule.youCanUpload")}
                            </small>
                          </div>
                        </div>
                      ) : (
                        plano !== undefined &&
                        plano !== null && (
                          <div
                            style={{
                              minHeight: "200px",
                              caretColor: "transparent",
                              overflowY: "scroll",
                              textAlign: "center",
                            }}
                          >
                            <PlanoPreviewCard
                              plano={plano}
                              resetStates={resetStates}
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "3rem" }}>
              <Col style={{ textAlignLast: "right" }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    resetStates();
                  }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  className="card-button"
                  style={{ marginLeft: "10px" }}
                  onClick={handleSaveplano}
                  disabled={
                    plano === undefined ||
                    plano === null ||
                    selectedEntitie === ""
                  }
                >
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <footer className="simulator__footer">
                  <h6>
                    {t(
                      `portalrh.rhadmin.formacoes.planosTutorialProcedimentoTitle`
                    )}
                  </h6>
                  <p>
                    {t(`portalrh.rhadmin.formacoes.planosTutorialProcedimento`)}
                  </p>
                </footer>
              </Col>
            </Row>
            <Row>
              <Col>
                <footer className="simulator__footer">
                  <h6>
                    {" "}
                    {t(`portalrh.rhadmin.formacoes.planosTutorialNotasTitle`)}
                  </h6>
                  <p>{t(`portalrh.rhadmin.formacoes.planosNotas`)}</p>
                </footer>
              </Col>
            </Row>
            <Row>
              <Col>
                <footer className="simulator__footer">
                  <h6>
                    {" "}
                    {t(`portalrh.rhadmin.formacoes.planosTutorialLegendaTitle`)}
                  </h6>
                  <p>{t(`portalrh.rhadmin.formacoes.planosLegenda`)}</p>
                </footer>
              </Col>
            </Row>
            <Messages />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(RHPlanosFormacao);
