import { takeLatest } from "redux-saga/effects";

import {
  PERSIST_ORG_ADMIN, VALIDATE_ORG_ADMIN,
} from "../ducks/registerOrgAdmin/actionTypes";
import {
  persistOrgAdmin, validateOrgAdmin,
} from "../ducks/registerOrgAdmin/sagas";

export const registerOrgAdminSagas = [
  takeLatest(PERSIST_ORG_ADMIN, persistOrgAdmin),
  takeLatest(VALIDATE_ORG_ADMIN, validateOrgAdmin)
];