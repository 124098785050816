import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import CreateForm from "~/components/CreateForm";
import "./demo-process.scss";

const NativeProcessesWrapper = (props) => {
  const { isModal, className, handleHeaderName, handleClose } = props;
  const { formdata } = useSelector((state) => state.processesReducer);
  const myRef = useRef(null);
  const { focusElement } = useSelector((state) => state.iflowReducer);

  useEffect(() => {
    if (formdata) {
      if (!focusElement) {
        myRef.current.scrollIntoView();
      }
    }
  }, [formdata, focusElement]);

  return (
    <div
      id="demo-process"
      ref={myRef}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <CreateForm
        jsonData={formdata}
        isModal={isModal}
        className={className}
        handleHeaderName={handleHeaderName}
        handleClose={handleClose}
      />
    </div>
  );
};
export default NativeProcessesWrapper;
