import React, { useState, useEffect } from "react";
import {useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import DateTimePicker from "react-datetime-picker";
import { Col,Form,Button } from "react-bootstrap";
import {updateDocument} from "~/store/ducks/portalrh/actionTypes";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

const UpdateDocument = (props) => {
  const {
    t,
    selectedDocument,
    canUpdate
  } = props;

  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [expirationDate, setExpirationDate] = useState(new Date());

  function getDate(dateString){
    //transform date string(2021-10-19T23:00:00.000+000) into Date
    const dateArray = dateString.slice(0,16).split("-");
    let time="";
    let timeArray=[];
    try{
      time=dateArray[2].split("T")[1]; 
      timeArray=time.split(":");
    }catch(e){
      //TODO
    }
    let month=dateArray[1];
    if(month==="10"|| month==="11"|| month==="12"){
      try{
        month=Number.parseInt(month)-1;
      }catch(e){
        month=0;
      }
    }
    else if(month.includes("0")){
      month=month.split("0");
      try{
        month=Number.parseInt(month[1])-1;
      }catch(e){
        month=0;
      }
    }
    const hour=timeArray[0] ||0;
    const minute=timeArray[1] ||0;
    const day=dateArray[2].split("T")[0];
    const year= dateArray[0];
    return new Date(year, month ||0,day,hour ||0, minute ||0)
  };

  useEffect(()=>{
    if(selectedDocument){
      selectedDocument.comment && setComment(selectedDocument.comment);
      selectedDocument.expirationDate && setExpirationDate(getDate(selectedDocument.expirationDate));
    }
  },[selectedDocument])

  const handleUpdateDocument = () =>{
    if(selectedDocument){
      const payload={
        id: selectedDocument.id,
        comment: comment,
        expirationDate: expirationDate
      }
      dispatch(updateDocument(payload));
    }
  };

  const handleCancel = () =>{
    selectedDocument.comment && setComment(selectedDocument.comment);
    selectedDocument.expirationDate && setExpirationDate(getDate(selectedDocument.expirationDate));
  }

  return (
        <Col xs={4} md={4} lg={4}>
          <Form.Label>
            {t("portalrh.documentManagement.expirationDate")}
          </Form.Label>
          <DateTimePicker
            value={expirationDate}
            onChange={(e) => setExpirationDate(e)}
            locale="pt-PT"
            format="dd/MM/yyyy"
            className="form-control"
            disabled={!canUpdate}
          />
          <br/>
          <Form.Label>
            {t(`portalrh.documentManagement.comment`)}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="2"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={!canUpdate}
          />
          {canUpdate &&
          <>
            <br/>
            <Form.Label>
            {t(`portalrh.documentManagement.saveUpdates`)}
            </Form.Label>
            <br/>
            <Button
              variant="danger"
              onClick={() => {
                handleCancel();
              }}
            >
              {t("general.no")}
            </Button>
            <Button
              className="modal-submit-button"
              style={{ marginLeft: "10px" }}
              onClick={() => {
              handleUpdateDocument();
              }}
            >
              {t("general.yes")}
            </Button>
          </>}
        </Col>   
  )
};

export default withNamespaces()(UpdateDocument);
