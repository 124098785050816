import {
  SAVE_USER_DATA,
  GET_USER_DATA,
  SET_USER_DATA,
  GET_COLLABORATOR_BOOLEAN,
  PUT_USER_ID,
  DELETE_USER_DATA,
  SET_COLLABORATOR_DATA,
  SAVE_USER_DATA_EXCEL,
  GET_CODES_SIMULATOR,
  SET_CODES_SIMULATOR,
} from "./actionTypes";

const initialState = {
  userData: [],
  savedUserData: [],
  collaboratorBoolean: false,
  userId: undefined,
  collaboratorDatas: [],
  codes: [],
};

export const simulatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return { ...state, userData: action.userData };
    case SAVE_USER_DATA:
      return { ...state };
    case SAVE_USER_DATA_EXCEL:
      return { ...state };
    case GET_CODES_SIMULATOR:
      return { ...state };
    case SET_CODES_SIMULATOR:
      return { ...state, codes: action.codes };
    case SET_USER_DATA:
      return { ...state, userData: action.savedUserData };
    case GET_COLLABORATOR_BOOLEAN:
      return {
        ...state,
        collaboratorBoolean: !state.collaboratorBoolean,
      };
    case PUT_USER_ID:
      return { ...state, userId: action.userId };
    case DELETE_USER_DATA:
      return { ...state, userId: action.userId };
    case SET_COLLABORATOR_DATA:
      return {
        ...state,
        collaboratorDatas: action.collaborators,
      };
    default:
      return state;
  }
};
