import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { easyPaySSO } from "~/store/ducks/udw/actionTypes";
const devLogConsole = require("~/utils/devLog");
const EasyPaySSO = ({ t })  => {
  const dispatch = useDispatch();
  let payload;
  try{
    let easyPayCksLOCALE;
      easyPayCksLOCALE = document.cookie
      .split('; ')
      .find(row => row.startsWith('locale='))
      .split('=')[1];
      
      devLogConsole(easyPayCksLOCALE)
      //TEST COOKIE
      //let easyPayCksID = 1;
      //let easyPayCksLOCALE = 'US';

      payload = {
         "locale":easyPayCksLOCALE
      }
      if(payload.locale != null && payload.locale != undefined)
        dispatch(easyPaySSO(payload));
  } catch(error){
    devLogConsole("Cookies not present")
  }
    return ("Cookies confirmed");
};


export default withNamespaces()(EasyPaySSO);