import React, { useState, useEffect, useRef } from "react";

const CreateTableCheckbox = (props) => {
  const { field, id, linha, onBlur, zoomcheckbox, fontSize } = props;

  const checkboxRef = useRef("");
  const [checkbox, setCheckbox] = useState(0);

  useEffect(() => {
    if (field && field.checked) {
      field.checked === "false"
        ? (checkboxRef.current.checked = false)
        : (checkboxRef.current.checked = true);
      field.checked === "false" ? setCheckbox(0) : setCheckbox(1);
    }
  }, [field]);

  useEffect(() => {
    onBlur(linha, 66);
  }, [checkbox]);

  return (
    <td id={id}>
      <input
        type="checkbox"
        value={checkbox}
        style={{ width: "100%", paddingTop: "0px", paddingBottom: "0px", fontSize: `${fontSize}px`, transform:`scale(${zoomcheckbox})` }}
        ref={checkboxRef}
        disabled={field.disabled === "true" || field.readonly === "true"}
        onChange={() => {
          setCheckbox(checkboxRef.current.checked === true ? 1 : 0);
          onBlur(linha, 66);
        }}
        onBlur={() => {
          onBlur(linha, 66);
        }}
      />
      <input type="hidden" name={field.name} value={checkbox} />
    </td>
  );
};
export default CreateTableCheckbox;
