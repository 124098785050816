import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Header from "~/pages/Easypay/components/Header";
import CreateForm from "~/pages/Easypay/CreateForm";
import { getEasypayFormdata } from "~/store/ducks/easypay/actionTypes";
import oauth from "~/utils/oauth";
import "~pages/Easypay/assets/css/styles.css";

const EasypayBackOffice = () => {
  const dispatch = useDispatch();

  const { easypayFormdata } = useSelector((state) => state.easypayReducer);

  useEffect(() => {
    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=311" +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    dispatch(getEasypayFormdata(payload));
  }, []);

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Header />
      <div className="easypay-backoffice-content">
        <CreateForm jsonData={easypayFormdata} />
      </div>
    </div>
  );
};
export default withNamespaces()(EasypayBackOffice);
