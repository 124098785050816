import {
  flatMap,
  map,
  find,
  intersection,
  intersectionBy,
  isEmpty,
  some,
} from "lodash";
import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

export const hasRoles = (user, roles, adminMenu = false) => {
  if (some(user.roles, { label: ROLE_ADMIN })) return true;
  //OrgAdmin has Master Admin role expect in the admin menu where some items are hidden or changed
  if (!adminMenu && some(user.roles, { label: ROLE_ORG_ADMIN })) return true;
  if (isEmpty(roles)) return true;
  let userRoles = map(user.roles, "label");
  return !isEmpty(intersection(userRoles, roles));
};

export const hasPermission = (
  user,
  permission,
  canCreate,
  canRead,
  canUpdate,
  canDelete
) => {
  if (some(user.roles, { label: ROLE_ADMIN })) return true;
  //if (some(user.roles, { label: ROLE_ORG_ADMIN })) return true;
  let userPermissions = map(user.roles, "permissions").flat();
  let foundPermission = find(userPermissions, { label: permission });
  if (!foundPermission) return true;
  if (canCreate && canCreate !== foundPermission.create) return false;
  if (canRead && canRead !== foundPermission.read) return false;
  if (canUpdate && canUpdate !== foundPermission.update) return false;
  if (canDelete && canDelete !== foundPermission.delete) return false;
  return true;
};

export const hasPermissions = (user, permissions) => {
  if (isEmpty(permissions)) return true;
  if (some(user.roles, { label: ROLE_ADMIN })) return true;
  //if (some(user.roles, { label: ROLE_ORG_ADMIN })) return true;
  let userPermissions = flatMap(user.roles, "permissions");
  let foundedPermissions = intersectionBy(
    userPermissions,
    permissions,
    "label"
  );
  return !isEmpty(foundedPermissions);
};
