import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ModalTemplate from "./ModalTemplate";
import Stack from "@mui/material/Stack";
import { Button } from "react-bootstrap";
import {
  getTemplateDetail,
  setTemplateDetail,
  deleteTemplate,
  getTemplatesList,
  setTemplateDocumentsAll,
} from "store/ducks/processes/actionTypes";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FaCircle } from "react-icons/fa";

const TemplateTable = ({ t }) => {
  const { templatesList, isLoadingTemplates } = useSelector(
    (state) => state.processesReducer
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);

  const buildActionsColumn = (_, row, {}) => (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "center" }}
      >
        <i
          className={`icon-trash table-action-icon`}
          onClick={() => handleDeleteDialog(row)}
        />
        <i
          className={`icon-edit table-action-icon`}
          onClick={() => handleOpenModalTemplate(row)}
        />
      </Stack>
    </>
  );

  const buildAtiveColumn = (_, row, {}) => (
    <>
      {row.ativo === 1 ? (
        <FaCircle style={{ color: "green", fontSize: "larger" }} />
      ) : (
        <FaCircle style={{ color: "red", fontSize: "larger" }} />
      )}
    </>
  );

  const handleDeleteDialog = (row) => {
    setOpenDialog(true);
    setSelectedTemplate(row);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedTemplate(undefined);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteTemplate(selectedTemplate.id));
    dispatch(getTemplatesList());
    handleClose();
  };

  const handleOpenModalTemplate = (template) => {
    setSelectedTemplate(template);
    setOpenModalTemplate(true);
    dispatch(getTemplateDetail(template.id));
  };

  const handleCloseModalTemplate = () => {
    setSelectedTemplate(undefined);
    setOpenModalTemplate(false);
    dispatch(setTemplateDetail([]));
    dispatch(setTemplateDocumentsAll([]));
  };

  const handleCreate = () => {
    setOpenModalTemplate(true);
    dispatch(getTemplateDetail());
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
      hidden: true,
    },
    {
      dataField: "ativo",
      text: t("portalrh.rhadmin.gestaoTemplates.active"),
      sort: true,
      formatter: buildAtiveColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
      hidden: true,
    },
    {
      dataField: "descricao",
      text: t("portalrh.rhadmin.gestaoTemplates.description"),
      sort: true,
    },
    {
      dataField: "accao",
      text: t("portalrh.rhadmin.gestaoTemplates.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
  ];

  const { SearchBar } = Search;
  return (
    <>
      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={templatesList}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "20px", display: "flex" }}>
                <SearchBar
                  {...props.searchProps}
                  placeholder={t(
                    "portalrh.rhadmin.gestaoTemplates.searchInput"
                  )}
                />
                <div style={{ paddingLeft: "30px" }}>
                  <ButtonAdd
                    text={t("portalrh.rhadmin.gestaoTemplates.add")}
                    onClick={handleCreate}
                  />
                </div>
              </div>

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoadingTemplates}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {!isLoadingTemplates && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t(
                    "portalrh.rhadmin.gestaoTemplates.emptyTable"
                  )}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalTemplate
        openModalTemplate={openModalTemplate}
        handleCloseModalTemplate={handleCloseModalTemplate}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      {selectedTemplate && (
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("portalrh.rhadmin.gestaoTemplates.deleteTemp")}
            {""}
            {selectedTemplate.descricao}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleClose}
            >
              {t("portalrh.rhadmin.gestaoTemplates.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button"
              onClick={handleDeleteConfirm}
            >
              {t("portalrh.rhadmin.gestaoTemplates.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withNamespaces()(TemplateTable);
