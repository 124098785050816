export const generateRequestMenu = (menu, designacao, setContainMenu) => {
  const treeMenu = {};
  if (menu != undefined) {
    setContainMenu(true);
    menu.forEach((item) => {
      if (designacao === "Colaboradores") {
        if (!item.id) {
          item.forEach((itemChild) => {
            if (!treeMenu[itemChild.designacao]) {
              treeMenu[itemChild.designacao] = [];
            }
            treeMenu[itemChild.designacao].push(itemChild);
          });
        }
      } else if (item.children)
        item.children.forEach((firstChild) => {
          if (firstChild.designacao === designacao)
            firstChild.children.forEach((secondChild) => {
              if (!treeMenu[secondChild.designacao]) {
                treeMenu[secondChild.designacao] = [];
              }
              treeMenu[secondChild.designacao].push(secondChild);
            });
        });
    });
  }
  return treeMenu;
};