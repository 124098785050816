import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_backOfficeRepLegTable.scss";
import BackofficeAddBenefModal from "./BackofficeAddBenefModal";
import Button from "./Button";
import Table from "~/pages/Easypay/components/Table";
import {
  getUser,
  updateUser,
  setTriggerLoading,
  setPrecario,
  getDetailFile,
  setDetailFile,
  setFileScreen,
} from "~/store/ducks/onboarding/actionTypes";
import SimpleButton from "./SimpleButton";
import { useDispatch, useSelector } from "react-redux";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import ButtonUpload from "./ButtonUpload";
import { saveDetailFile } from "~/store/ducks/onboarding/actionTypes";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import { some } from "lodash";

const BackOfficeBenefSection = ({ t, data, pid }) => {
  const { precario, savePrecarioResult, detailFile, screen } = useSelector(
    (state) => state.onboardingReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  const [isAddModalShowing, setIsAddModalShowing] = useState(false);
  const dispatch = useDispatch();
  const addButtonField = {
    type: "link",
    text: "",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitMain';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function handleSetAddModal() {
    setIsAddModalShowing(true);
  }

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const idFormatterOnboarding = (cell) => {
    return cell;
  };

  const statusFormatter = () => {
    //return <img src={iconWarning} />;
  };

  const resultFormatter = (cell) => {
    return cell; // <Tag text={cell} variant="easypay-tag-label" type="easypay-approved" />
  };

  const calcDays = (cell) => {
    if (!cell) {
      return "";
    }
    const modifiedDate = new Date(cell);
    const currentDate = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = currentDate.getTime() - modifiedDate.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const stageFormatter = (cell) => {
    return cell;
  };

  const capitalizeText = (cell) =>
    cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : "";

  const pepFormatter = (cell) => {
    return cell === 1 ? "PEP" : "-";
  };

  const nameFormatter = (cell) => {
    for (var i = 0; i < data?.effectiveBeneficiary?.length; i++) {
      if (data?.effectiveBeneficiary[i]?.id === cell) {
        return (
          <div>
            <div>{data?.effectiveBeneficiary[i]?.nome}</div>
            <div style={{ color: "#6d6d6d", fontSize: "14px" }}>
              NIF: {data?.effectiveBeneficiary[i]?.nif}
            </div>
          </div>
        );
      }
    }
    return <div></div>;
  };

  function makePep(cell) {
    for (var i = 0; i < data?.effectiveBeneficiary?.length; i++) {
      if (data?.effectiveBeneficiary[i]?.id === cell) {
        data.effectiveBeneficiary[i].pep = 1;
        dispatch(updateUser({ user: data, pid: pid }));
      }
    }
  }

  const makePepFormatter = (cell, row) => {

    if (cell != "null") {
      if (row?.pep === 0 || row?.pep === null && (some(user.roles, { label: "ONBOARDINGCompliance" })) ||
      some(user.roles, { label: "ADMIN" }) ||
      some(user.roles, { label: "ONBOARDINGUser" })) {
        return (
          <SimpleButton
            text={"Tornar PEP"}
            variant={"easypay-button-link"}
            onClick={() => makePep(cell)}
          />
        );
      } else {
        return (
          <SimpleButton
            text={"Tornar PEP"}
            variant={"easypay-button-link-disabled"}
          />
        );
      }
    } else return cell;
  };

  const inputFile = useRef(null);
  const [currentBase64, setCurrentBase64] = useState("");
  var currentId = -1;
  function onButtonClick(index) {
    // `current` points to the mounted file input element
    currentId = index;
    inputFile.current.click();
  }

  function getBase64(file) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setCurrentBase64(base64String);

      const payload = {
        idProcesso: pid,
        idUserLegal: currentId,
        file: base64String,
        type : "dnb"
      };
      dispatch(saveDetailFile(payload));
      setIsModalDocShowing(true)

    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];

    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";

    if (
      !(
        type.includes("pdf") ||
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      )
    ) {
      //setIsModalShowing(true)
      return;
    }

    getBase64(currentFile);
  };

  const uploadFileFormatter = (cell, row) => {
    if (cell != "null") {
      return (
        <ButtonUpload
          text={t(`bpm.onboarding.backoffice.user.buttonUploadDocument`)}
          variant={"easypay-button-secondary-icon"}
          onclick={() => onButtonClick(cell)}
          onChange={handleChange}
          key={uuid()}
          inputFile={inputFile}
          id={"id1"}
          className="card-button"
          align={"right"}
          disabledColored={false}
          disabled={false}
        />
      );
    } else return cell;
  };

  useEffect(() => {
    if (detailFile != null && detailFile != undefined && screen === "BENEF") {
      if (detailFile?.length === 0) {
        dispatch(setDetailFile(null));
        dispatch(setFileScreen(null));
        setIsModalDocErrorShowing(true)
        return;
      }

      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${detailFile[0].file}`;
      a.download = "details.pdf";
      a.click();
      dispatch(setDetailFile(null));
      dispatch(setFileScreen(null));
    }
  }, [detailFile, screen]);

  function downloadFile(cell) {
    currentId = cell;
    const payload = {
      idProcesso: data?.client?.pid,
      idUserLegal: cell,
      type: "BENEF",
      docType : "dnb"
    };
    dispatch(getDetailFile(payload));
  }

  const downloadFileFormatter = (cell, row) => {
    if (cell != "null") {
      return (
        <SimpleButton
          text={"Baixar documento"}
          variant={"easypay-button-primary"}
          onClick={() => downloadFile(cell)}
        />
      );
    } else return cell;
  };

  const columns = [
    {
      dataField: "id",
      text: t(`bpm.onboarding.backoffice.user.labelName`),
      sort: true,
      formatter: nameFormatter,
    },
    {
      dataField: "pep",
      text: "PEP",
      sort: false,
      headerStyle: { width: "150px" },
      formatter: pepFormatter,
      style: {
        "font-weight": "bold",
      },
    },
    {
      dataField: "percentagem",
      text: t("bpm.onboarding.backoffice.user.labelDirectCapital"),
      sort: false,
    },
    {
      dataField: "tipoDocumento",
      text: t("bpm.onboarding.backoffice.administration.headerDocType"),
      sort: false,
    },
    {
      dataField: "numDocumento",
      text: t("bpm.onboarding.backoffice.user.labelCivNumber"),
      sort: true,
    },
    {
      dataField: "morada",
      text: t("bpm.onboarding.backoffice.administration.headerAddress"),
      sort: false,
    },
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: makePepFormatter,
    },
  ];

  
  const [isModalDocShowing, setIsModalDocShowing] = useState(false);
  const [isModalDocErrorShowing, setIsModalDocErrorShowing] = useState(false);

  function createBodyDocSuccess() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Documento guardado" status="success" />
      </div>
    );
  }

  function createBodyDocError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Erro ao baixar documento!" status="error" />
      </div>
    );
  }


  return (
    <div className="easypay-backoffice-sections">
      <BackofficeAddBenefModal
        title={"Adicionar"}
        isModalShowing={isAddModalShowing}
        setIsModalShowing={setIsAddModalShowing}
        user={data}
        pid={pid}
      />
            <Modal
        className="easypay-modal"
        showModal={isModalDocShowing}
        setShowModal={setIsModalDocShowing}
        headerContent={"Upload documento"}
        bodyContent={createBodyDocSuccess()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalDocErrorShowing}
        setShowModal={setIsModalDocErrorShowing}
        headerContent={"Baixar documento"}
        bodyContent={createBodyDocError()}
        key={uuid()}
      ></Modal>
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.verticalTabBenef`)}
      </div>
      <Table
        sort={{ dataField: "client.pid", order: "desc" }}
        docs={data?.effectiveBeneficiary ? data?.effectiveBeneficiary : []}
        columns={columns}
        key="onboarding-table"
        hidePagination={true}
      />
      <Button
        text={t(`bpm.onboarding.backoffice.user.modalHeaderAdd`)}
        variant={"easypay-button-add"}
        onclick={handleSetAddModal}
        key={uuid()}
        id={addButtonField.id}
        className="card-button"
        align={"left"}
        disabledColored={false}
        disabled={false}
        field={addButtonField}
      />
    </div>
  );
};

export default withNamespaces()(BackOfficeBenefSection);
