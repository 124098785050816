import React from "react";
import "../assets/scss/_liftworldTable.scss";
import LiftworldTableInput from "./LiftworldTableInput";

const LiftworldTable = ({ list, typeList }) => {
    function buildInputField(text) {
        return {
          value: text,
          obligatory: false,
          variable: "",
          maxlenght: 100,
          text : ""
        };
      }

  function getPosition(index) {
    if (index === 0) return "start";
    if (index === list.length - 1) return "end";
  }

  function getInputPosition(index,i,item) {
    if (index === typeList.length - 1 && i === 0) return "start";
    if (index === typeList.length - 1 && i === Object.keys(item).length - 1) return "end";
  }

  function buildTableInput(item,index) {
    const array = []
    var i = 0;
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        array.push(<LiftworldTableInput
          field={buildInputField(item[key])}
          position={getInputPosition(index,i,item)}
          size="normal"
        />);
      }
      i++;
    }
    return array
  }
  return (
    <div className="lifworld-table-wrapper">
      <div className="liftworld-table-header-wrapper">
        {list &&
          Object.keys(list).length !== 0 &&
          list.map((item, index) => {
            return (
              <div
                className={
                  `${"liftworld-table-header-element"} ` + getPosition(index)
                }
              >
                {item}
              </div>
            );
          })}
      </div>
      <div>
        {typeList &&
          Object.keys(typeList).length !== 0 &&
          typeList.map((item, index) => {
            return <div className="liftworld-table-header-wrapper">{buildTableInput(item,index)}</div>;
          })}
      </div>
    </div>
  );
};
export default LiftworldTable;
