import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ButtonDownload from "~/components/Buttons/DownloadButton";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { getIesDocument } from "~/store/ducks/onboarding/actionTypes";

/**
 * IES Empresas
 *
 * This service tests an API from Infosistema
 *
 * The response is shown in the output text area in JSON format
 * and through a download button to get the document in pdf format
 *
 */

const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingGetIesDocument, getIesDocumentResponse } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (Object.keys(getIesDocumentResponse).length > 0) {
      setOutput(JSON.stringify(getIesDocumentResponse, null, 2));
    }
  }, [getIesDocumentResponse]);

  const [validated, setValidated] = useState(false);
  const [nif, setNif] = useState("503585491");
  const [year, setYear] = useState("2019");
  const [output, setOutput] = useState("");
  const [iesDocument, setIesDocument] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testGetIesDocument();
    }
  };

  const testGetIesDocument = () => {
    const payload = {
      nif,
      year,
    };
    dispatch(getIesDocument(payload, apiSettings.id));
  };

  const downloadPdf = (filename = "IES_Document") => {
    const linkSource = `data:application/pdf;base64,${iesDocument}`;
    const downloadLink = document.createElement("a");
    const fileName = `${filename}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationIesNif">
              <Form.Label>{t("onboarding.persist.nif")}</Form.Label>
              <Form.Control
                type="text"
                autofill="false"
                required
                autoComplete="off"
                value={nif}
                onChange={(e) => setNif(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredNif")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationIesYear">
              <Form.Label>{t("onboarding.persist.year")}</Form.Label>
              <Form.Control
                type="text"
                autofill="false"
                autoComplete="off"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredYear")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          className="card-button onboarding-button"
          type="submit"
        >
          {isLoadingGetIesDocument ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
      <ButtonDownload
        text={t("onboarding.general.downloadPdf")}
        onClick={() => downloadPdf()}
        disabled={!iesDocument}
      />
    </div>
  );
};
export default withNamespaces()(Test);
