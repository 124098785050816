import { takeLatest } from "redux-saga/effects";

import {
  GET_AUSENCIAS_BY_EMAIL,
  GET_FERIAS_BY_EMAIL,
  GET_LOGINS_BY_USERNAME,
  GET_AUTORIZACOES_BY_USERNAME,
} from "../ducks/webTimeWidget/actionTypes";

import {
  getAusenciasByEmail,
  getFeriasByEmail,
  getLoginsByUsername,
  getAutorizacoesByUsername,
} from "../ducks/webTimeWidget/sagas";

export const innuxSagas = [
  takeLatest(GET_AUSENCIAS_BY_EMAIL, getAusenciasByEmail),
  takeLatest(GET_FERIAS_BY_EMAIL, getFeriasByEmail),
  takeLatest(GET_LOGINS_BY_USERNAME, getLoginsByUsername),
  takeLatest(GET_AUTORIZACOES_BY_USERNAME, getAutorizacoesByUsername),
];
