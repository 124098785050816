import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import GeneralDocumentOpenAi from "~/pages/ADataCapture/components/GeneralDocumentOpenAi";
import { findAllBatchs } from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";

import { deleteBatch, clearSavedBatch } from "~/pages/DataCapture/actions";

const UploadModalOpenAi = (props) => {
  const {
    selectedDocType,
    datasetSelected,
    showModalOpenAi,
    handleCloseUploadModalOpenAi,
  } = props;

  const dispatch = useDispatch();

  const { batch } = useSelector((state) => state.dataCaptureReducer);

  const [modalClassName, setModalClassName] = useState("modal-datacapture-80w");
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");

  const handleClick = () => {
    modalClassName === "modal-datacapture-80w"
      ? setModalClassName("modal-datacapture-100w")
      : setModalClassName("modal-datacapture-80w");
    iconClassName === "icon-maximize mr-4"
      ? setIconClassName("icon-restore mr-4")
      : setIconClassName("icon-maximize mr-4");
  };

  const handleClose = () => {
    setModalClassName("modal-datacapture-80w");
    setIconClassName("icon-maximize mr-4");
    if (batch.id) {
      dispatch(deleteBatch(batch.id));
    }
    handleCloseUploadModalOpenAi();
    dispatch(clearSavedBatch());
    dispatch(findAllBatchs());
  };
  return (
    <Modal
      show={showModalOpenAi}
      dialogClassName={modalClassName}
      backdrop="static"
      onHide={() => handleCloseUploadModalOpenAi()}
    >
      <Modal.Body style={{ padding: "0px" }}>
        <div
          style={{
            position: "absolute",
            top: "23px",
            right: "10px",
            zIndex: "2",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i className={iconClassName} onClick={() => handleClick()} />
          <i className="icon-remove mr-4" onClick={() => handleClose()} />
        </div>
        <>
          <GeneralDocumentOpenAi
            selectedDocType={selectedDocType}
            datasetSelected={datasetSelected}
            handleCloseUploadModalOpenAi={handleCloseUploadModalOpenAi}
            experiencedUser={true}
          />
        </>
      </Modal.Body>
    </Modal>
  );
};
export default UploadModalOpenAi;
