import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import Header from "~/pages/Easypay/components/Header";
import CreateForm from "~/pages/Easypay/CreateForm";
import { getEasypayFormdata } from "~/store/ducks/easypay/actionTypes";
import oauth from "~/utils/oauth";
import "~pages/Easypay/assets/css/styles.css";
const devLogConsole = require("~/utils/devLog");
const EasypayRegisterForm = ({ application }) => {
  const dispatch = useDispatch();

  const { easypayFormdata } = useSelector((state) => state.easypayReducer);
  devLogConsole("INICIO DO FLOW")
  useEffect(() => {
    devLogConsole("INICIO DO FLOW")
    if (application) {
      const pid = window.localStorage.getItem("pid");
      let payload = "";
      if (pid) {
        payload = {
          url: `/Forward/forward.jsp?flowid=311&pid=${pid}&subpid=1&Authorization=${oauth.getAccessToken()}`,
        };
      } else {
        payload = {
          url:
            "/inicio_flow.jsp?" +
            "flowid=" +
            application?.pid +
            "&Authorization=" +
            oauth.getAccessToken(),
        };
      }
      dispatch(getEasypayFormdata(payload));
    }
  }, [application]);

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Header
        redirect={true}
        logo={
          application && application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
      />
      <div className="easypay-content">
        <CreateForm jsonData={easypayFormdata} flowid={application?.flowid} />
      </div>
    </div>
  );
};
export default withNamespaces()(EasypayRegisterForm);
