export const ADocumentStatus = {
  PROCESSED: "PROCESSED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
  DUPLICATED: "DUPLICATED",
  TERMINATED: "TERMINATED",
  REJECTED_IFLOW: "REJECTED_IFLOW",
  PENDING_IFLOW: "PENDING_IFLOW",
  PENDING_REJ_IFLOW: "PENDING_REJ_IFLOW",
  HUMAN_REVISION: "HUMAN_REVISION",
};
