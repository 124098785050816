import qs from "qs";
import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "../../../../utils/config/http";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

import {
  setRules,
  setRuleInformationById,
  updateUnikUsersRuleDone,
  persistRuleDone,
  setFindByRule,
  deleteRuleDone,
} from "../actions";

import {
  activeSuccess,
  activeError,
} from "../../../../components/Messages/actions";

export function* getRules() {
  try {
    const resp = yield http.get(
      "/api/trabalho-remoto/regras-remote-work",
      PORTALRH_MIDDLEWARE
    );
    yield put(setRules(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRuleInformationById({ ruleId }) {
  try {
    const resp = yield http.get(
      `/api/trabalho-remoto/regras-remote-work-detail?idregra=${ruleId}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRuleInformationById(resp.data));
  } catch (error) {
    yield put(setRuleInformationById([]));
  }
}

export function* updateUnikUsersRule({ roleUsers }) {
  try {
    const { ruleId, grupos } = roleUsers;

    yield http.post(
      `/api/trabalho-remoto/update-grupos-remote-work?roleid=${ruleId}`,
      grupos,
      PORTALRH_MIDDLEWARE
    );
    yield put(activeSuccess("admin.rule.persist.successMessage"));
    yield put(updateUnikUsersRuleDone());
  } catch (error) {
    yield put(activeError("admin.rule.persist.errorMessage"));
    yield put(updateUnikUsersRuleDone());
  }
}

export function* persistRule({ rule }) {
  try {
    yield http.post(
      "/api/trabalho-remoto/cria-regra-remote-work",
      rule,
      PORTALRH_MIDDLEWARE
    );
    yield put(activeSuccess("admin.rule.persist.successMessage"));
    yield put(persistRuleDone());
  } catch (error) {
    yield put(activeError("admin.rule.persist.errorMessage"));
    yield put(persistRuleDone());
  }
}

export function* findUsersByRule({ id }) {
  try {
    yield put(setFindByRule([]));
    const resp = yield http.get(
      `/api/user/find-by-role?${qs.stringify({ id })}`
    );
    yield put(setFindByRule(resp.data));
  } catch (error) {
    yield put(setFindByRule([]));
  }
}

export function* deleteRule({ id }) {
  try {
    yield http.delete(
      `/api/trabalho-remoto/delete-regra-remote-work?id=${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(activeSuccess("admin.rule.delete.successMessage"));
    yield put(deleteRuleDone());
  } catch (error) {
    yield put(activeError("admin.rule.delete.errorMessage"));
    yield put(deleteRuleDone());
  }
}
