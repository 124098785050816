import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_input.scss";

const Input = ({
  field,
  type,
  submitOnChange,
  handleSubmitWrapper,
  t,
  name,
  onChange,
}) => {
  const [textInputValue, setTextInputValue] = useState("");
  const [required, setRequired] = useState(false);
  

  useEffect(() => {
    if (field) {
      if (field.value) setTextInputValue(field.value);
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
    }
  }, [field]);


  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      style={{ margin: "0px"}}
    >
      <div className="easypay-input-wrapper-wrapper">
        <div
          className={`easypay-input-wrapper ${
            field.maxlength && "easypay-input-size-" + field.maxlength
          }  `}
          tabIndex="1"
        >
          <div className={"easypay-input-label"}>{`${
            submitOnChange
              ? t(field.text.split("::")[0])
              : t(field.text.split("::")[0])
          }`}</div>
          <div
            className={`easypay-input-field-wrapper ${
              field.text.split("::")[1] === "disabled"
                ? `disabled`
                : textInputValue
                ? `easypay-input-filled`
                : ""
            }`}
          >
            <input
              className={`easypay-input-field`}
              type={type && type}
              name={name !== undefined ? name : field.variable}
              value={textInputValue}
              maxLength={field.maxlength}
              onChange={(e) => {
                setTextInputValue(e.target.value);
                onChange && onChange(e.target.value, name);
              }}
              required={required}
              onBlur={(e) => {
                if (submitOnChange) {
                  handleSubmitWrapper(e, {
                    id: "5",
                    name: "_guardar",
                    operation: "disableForm(); document.dados.op.value=2;",
                    text: "Continuar mais tarde",
                    tooltip: "Save",
                  });
                }
              }}
              autoComplete={type === "password" ? "new-password" : "off"}
              disabled={field.text.split("::")[1] === "disabled"}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {`O campo ${
              field.text.split("::")[0]
            } é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </div>
      </div>
    </Form.Group>
  );
};
export default withNamespaces()(Input);
