export const GET_LIST_SIADAPRA_UO = "SIADAP@GET_LIST_SIADAPRA_UO";
export const SET_LIST_SIADAPRA_UO = "SIADAP@SET_LIST_SIADAPRA_UO";
export const GET_LIST_SIADAPRA_UO_DROPDOWN =
  "SIADAP@GET_LIST_SIADAPRA_UO_DROPDOWN";
export const SET_LIST_SIADAPRA_UO_DROPDOWN =
  "SIADAP@SET_LIST_SIADAPRA_UO_DROPDOWN";
export const GET_LIST_SIADAPRA_UO_DETAIL = "SIADAP@GET_LIST_SIADAPRA_UO_DETAIL";
export const GET_LIST_SIADAPRA_UO_ORG_DETAIL = "SIADAP@GET_LIST_SIADAPRA_UO_ORG_DETAIL";

export const getUOSiadapra = () => {
  return { type: GET_LIST_SIADAPRA_UO };
};
export const setUOSiadapra = (unidadesOrganicas) => ({
  type: SET_LIST_SIADAPRA_UO,
  unidadesOrganicas,
});

export const getUOSiadapraDropdown = () => {
  return { type: GET_LIST_SIADAPRA_UO_DROPDOWN };
};

export const setUOSiadapraDropdown = (dropdown) => ({
  type: SET_LIST_SIADAPRA_UO_DROPDOWN,
  dropdown,
});

export const getUOSiadapraDetail = (payload) => {
  return { type: GET_LIST_SIADAPRA_UO_DETAIL, data: payload };
};

export const getUOSiadapraDetailOrg = (payload) => {
  return { type: GET_LIST_SIADAPRA_UO_ORG_DETAIL, data: payload };
};
