import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import arrowLeft from "../assets/img/icons/arrow-left.png";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import arrowGrey from "../assets/img/icons/left-arrow-grey.png";
import "../assets/scss/_documentNavigation.scss";

function DocumentNavigation({
  selected,
  setSelected,
  disabled,
  documentsList,
  onClick
}) {
  const [isActive, setIsActive] = useState(false);

  const [didSelect, setDidSelect] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(0);

  const wrapperRef = useRef(null);
  var isFirsTime = true;

  var newArray = documentsList;

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    if (isFirsTime && newArray != null) {
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i].name === "") newArray.splice(i,1);
      }
      isFirsTime = false;
    }
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        event.target.id != `easypay-btn-dropdown-${id.current}` &&
        !document
          .getElementById(`easypay-btn-dropdown-${id.current}`)
          .contains(event.target)
      ) {
        setIsActive(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="easypay-document-navigation">
      <div className="easypay-document-navigation-header">
        <div
          className={
            disabled
              ? `${"easypay-left-img-container"} disabled`
              : "easypay-left-img-container"
          }
          onClick={() => {
            disabled
              ? void 0
              : currentDocument === 0
              ? setSelected(documentsList[documentsList.length - 1])
              : setSelected(documentsList[currentDocument - 1]);
            currentDocument === 0
              ? setCurrentDocument(documentsList.length - 1)
              : setCurrentDocument(currentDocument - 1);
            setDidSelect(true);
            disabled
            ? void 0
            : currentDocument === 0
            ? onClick(documentsList[documentsList.length - 1])
            : onClick(documentsList[currentDocument - 1]);
            
          }}
        >
          <img
            className="easypay-left-doc-arrow"
            src={disabled ? arrowGrey : arrowLeft}
          ></img>
        </div>
        <div
          className={
            disabled
              ? `${"easypay-document-dropdown-btn"} disabled`
              : isActive
              ? `${"easypay-document-dropdown-btn"} active`
              : didSelect
              ? "easypay-document-dropdown-btn"
              : `${"easypay-document-dropdown-btn"} not-selected`
          }
          onClick={disabled ? null : () => setIsActive(!isActive)}
          id={`easypay-btn-dropdown-${id.current}`}
        >
          <div className="easypay-document-btn-text">
            {selected && (
              <div className="easypay-main-document-dropdown-item-name">
                {selected.documentName}
              </div>
            )}
            {selected && (
              <div className="easypay-main-document-dropdown-item-holder-name">
                {selected.holderName}
              </div>
            )}
          </div>
          <img
            className={
              isActive
                ? `${"easypay-dropdown-img"} rotate`
                : "easypay-dropdown-img"
            }
            src={isActive ? arrowUpBlue : arrowDownGrey}
            height="6px"
            width="12px"
          />
        </div>
        <div
          className={
            disabled
              ? `${"easypay-right-img-container"} disabled`
              : "easypay-right-img-container"
          }
          onClick={() => {
            disabled
              ? void 0
              : currentDocument === documentsList.length - 1
              ? setSelected(documentsList[0])
              : setSelected(documentsList[currentDocument + 1]);
            currentDocument === documentsList.length - 1
              ? setCurrentDocument(0)
              : setCurrentDocument(currentDocument + 1);
            setDidSelect(true);
            currentDocument === documentsList.length - 1
              ? onClick(documentsList[0])
              : onClick(documentsList[currentDocument + 1]);
            
          }}
        >
          <img
            className="easypay-right-doc-arrow"
            src={disabled ? arrowGrey : arrowLeft}
          ></img>
        </div>
      </div>
      {isActive && (
        <div
          className="easypay-document-dropdown-content"
          ref={wrapperRef}
          id={`easypay-dropdown-content-${id.current}`}
        >
          {documentsList &&
            Object.keys(documentsList).length !== 0 &&
            documentsList.map((option, index) => (
              <div>
                {option.documentName != "" && (
                  <div
                    onClick={() => {
                      setSelected({
                        documentName: option.documentName,
                        holderName: option.holderName,
                        documentId : option.documentId
                      });
                      onClick({
                        documentName: option.documentName,
                        holderName: option.holderName,
                        documentId : option.documentId
                      })
                      setCurrentDocument(index);
                      setDidSelect(true);
                      setIsActive(false);
                    }}
                    className={
                      index === documentsList.length - 1
                        ? `${"easypay-document-dropdown-item"} last`
                        : "easypay-document-dropdown-item"
                    }
                  >
                    <div className="easypay-document-dropdown-item-name">
                      {option.documentName}
                    </div>
                    <div className="easypay-document-dropdown-item-holder-name">
                      {option.holderName}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default DocumentNavigation;
