import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import PaperYear from "./PaperYear";
import PaperYearToRemove from "./PaperYearToRemove";
import { TbBeach } from "react-icons/tb";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import YearCalendarDate from "./YearCalendarDate";
import { marcacaoEvents, convertToEvents } from "../utils";

const PaperPopover = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
}));

const YearCalendarComponent = ({
  date,
  events,
  setAllEvents,
  setAllEventsTemp,
  allEventsTemp,
  isEditable,
  singleDaySelection,
  t,
  anchorEl,
  setAnchorEl,
  open,

  convertToEventsMarkedDays,
  setRemoveAll,
  setRemoveSomeDays,
  userNamePortalRH,
}) => {
  const [calendar, setCalendar] = useState(null);
  const [firstClickDate, setFirstClickDate] = useState(null);
  const [dateToCompare, setDateToCompare] = useState(undefined);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [eventToRemove, setEventToRemove] = useState(null);
  const [lang, setLang] = useState("");
  const [weekdays, setWeekdays] = useState({
    pt: ["D", "S", "T", "Q", "Q", "S", "S"],
    en: ["S", "M", "T", "W", "T", "F", "S"],
    es: ["D", "L", "M", "X", "J", "V", "S"],
  });

  useEffect(() => {
    setCalendar(createCalendar(date));
  }, [date]);

  useEffect(() => {
    if (calendar) {
      setLang(calendar.currentDate._locale._abbr);
    }
  }, [calendar]);

  function createCalendar(currentDate) {
    currentDate = moment(currentDate || undefined);
    const first = currentDate.clone().startOf("month");
    const last = currentDate.clone().endOf("month");
    const weeksCount = Math.ceil((first.day() + last.date()) / 7);
    const calendar = [];

    calendar.currentDate = currentDate;
    calendar.first = first;
    calendar.last = last;
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
      const week = [];
      for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
        const date = currentDate.clone().set("date", day + 1 - first.day());
        date.calendar = calendar;
        week.push(date);
      }
      calendar.push(week);
    }

    return calendar;
  }

  const handleClick = (day, event) => {
    setDateToCompare(day);
    if (event) {
      setEventToRemove(event);
    }
    const { x, y } = mousePosition;

    const top = y;
    const left = x;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    setAnchorEl({ top: top, left: left });
  };
  const isEventOnDate = (date, types) =>
    events.some(
      (event) =>
        types.includes(event.type) && moment(event.start).isSame(date, "day")
    );

  const createSingleDayEvent = (date) => ({
    id: uuidv4(),
    start: moment(date).toDate(),
    end: moment(date).endOf("day").toDate(),
    type: "feriasmarked",
    title: t("portalrh.workDays.selectedDay"),
    color: "#44A49B",
    intervals: [],
    checked: true,
    checkedHalfDay: false,
    multi: true,
  });

  const createIntervalEvents = (startDate, endDate) => {
    const intervals = [];
    let currentDay = startDate.clone();
    while (currentDay.isSameOrBefore(endDate, "day")) {
      const formattedDate = currentDay.format("YYYY-MM-DD");
      if (
        !isEventOnDate(formattedDate, [
          "feriados",
          "folgas",
          "ferias",
          "intervals",
          "feriasmarked",
        ])
      ) {
        intervals.push({
          id: uuidv4(),
          start: moment(formattedDate).toDate(),
          end: moment(formattedDate).endOf("day").toDate(),
          type: "intervals",
          title: "Hovered Interval",
          color: "#44A49B",
          multi: true,
        });
      }
      currentDay.add(1, "day");
    }
    return intervals;
  };

  const handleAddIntervalEvent = (dateToRender) => {
    if (isEventOnDate(dateToRender, ["feriados", "folgas", "ferias"])) return;
    if (isEventOnDate(dateToRender, ["feriasmarked", "intervals"])) {
      handleClick(dateToRender);
      return;
    }
    let newEvents = [...events];
    if (!firstClickDate) {
      newEvents.push(createSingleDayEvent(dateToRender));
      setFirstClickDate(moment(dateToRender));
    } else {
      const startDate = moment.min(firstClickDate, dateToRender);
      const endDate = moment.max(firstClickDate, dateToRender);
      newEvents = [...newEvents, ...createIntervalEvents(startDate, endDate)];
      setFirstClickDate(null);
    }
    setAllEvents(newEvents);
  };

  function handleAddSingleEvent(date) {
    const isEventAlreadyExists = events.some(
      (event) =>
        event.type !== "feriasmarked" &&
        event.type != "intervals" &&
        moment(event.start).isSame(date, "day")
    );
    const eventFound = events.find(
      (event) =>
        ["ferias", "datasMarcacoes", "feriasPendentes"].includes(event.type) &&
        moment(event.start).isSame(date, "day")
    );

    if (isEventAlreadyExists && eventFound) {
      if (eventFound.type === "feriasPendentes")
        handleClick(eventFound.start, eventFound);
      return;
    }

    if (isEditable) {
      const daysMarked = marcacaoEvents(date, t).flatMap(convertToEvents)[0];
      const isEventSolo = events.some(
        (event) =>
          event.type === "intervals" &&
          moment(event.start).isSame(daysMarked.start, "day") &&
          event.multi
      );
      const isEventSoloFeriasMarked = events.some(
        (event) =>
          event.type === "feriasmarked" &&
          moment(event.start).isSame(daysMarked.start, "day") &&
          event.multi
      );

      if (isEventSolo) handleClick(daysMarked.start);
      else if (isEventSoloFeriasMarked) {
        setAllEvents((prevEvents) =>
          prevEvents.filter(
            (event) =>
              new Date(event.start).getTime() !==
              new Date(daysMarked.start).getTime()
          )
        );
      } else {
        setAllEvents((prevEvents) => [...prevEvents, daysMarked]);
      }
    }
  }

  const handleClosePopOver = () => {
    setAnchorEl(null);
    setDateToCompare(undefined);
    setAllEventsTemp([]);
    document.body.style.overflow = "";
    document.body.style.position = "";
  };

  return (
    <>
      {calendar && lang && (
        <>
          <div className="month">
            <div className="month-name">
              {calendar.currentDate.format("MMMM").toUpperCase()}
            </div>
            {weekdays[lang].map((day, index) => (
              <span key={index} className="day">
                {day}
              </span>
            ))}
            {calendar.map((week, index) => (
              <div key={index}>
                {week.map((date) => (
                  <YearCalendarDate
                    key={date.date()}
                    isEditable={isEditable}
                    dateToRender={date}
                    dateOfMonth={calendar.currentDate}
                    onClick={(date) => {
                      if (isEditable) {
                        !singleDaySelection
                          ? handleAddIntervalEvent(date)
                          : handleAddSingleEvent(date);
                      }
                    }}
                    events={events}
                    setAllEvents={setAllEvents}
                    firstClickDate={firstClickDate}
                    setMousePosition={setMousePosition}
                    allEventsTemp={allEventsTemp}
                  />
                ))}
              </div>
            ))}
          </div>
          {dateToCompare !== undefined && (
            <Popover
              open={open}
              anchorReference="anchorPosition"
              anchorPosition={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <PaperPopover elevation={8} variant="elevation">
                {eventToRemove === null ? (
                  <PaperYear
                    events={events}
                    dateToCompare={dateToCompare}
                    handleAddIntervalEvent={handleAddIntervalEvent}
                    setAllEvents={setAllEvents}
                    singleDaySelection={singleDaySelection}
                    handleClosePopOver={handleClosePopOver}
                  />
                ) : (
                  <PaperYearToRemove
                    events={events}
                    setAllEvents={setAllEvents}
                    handleClosePopOver={handleClosePopOver}
                    eventToRemove={eventToRemove}
                    dateToCompare={dateToCompare}
                    setRemoveSomeDays={setRemoveSomeDays}
                  />
                )}
              </PaperPopover>
            </Popover>
          )}
        </>
      )}
    </>
  );
};

export default YearCalendarComponent;
