import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "~/components/Spinner";
import { checkToken } from "~/store/ducks/oauth/actionTypes";

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(state => state.loginReducer);
  const { isCheckingToken } = useSelector(state => state.oauthReducer);

  useEffect(() => {
    if (isAuthenticated === undefined) {
      dispatch(checkToken());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
            isCheckingToken !== undefined && !isCheckingToken ?
              <Redirect
                to={{
                  pathname: "/login"
                }}
              /> :
              <Spinner spinning={isCheckingToken} />
          )
      }
    />
  )

}

export default ProtectedRoute;
