import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

const SavedSuccessfullyModal = ({
  t,
  showSuccessModal,
  handleCloseSuccessModal,
  handleCloseParentModal,
}) => {
  return (
    <Modal
      show={showSuccessModal}
      backdrop="static"
      onHide={() => {
        handleCloseParentModal();
        handleCloseSuccessModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("datacapture.settingsModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px" }}>
        Changes saved successfully!
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="card-button"
          onClick={() => {
            handleCloseParentModal();
            handleCloseSuccessModal(false);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default withNamespaces()(SavedSuccessfullyModal);
