import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { pagination } from "~/components/pagination/currentSizePerPage/10_wideRange";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";

const TableList = ({
  docs,
  columns,
  t,
  hidePagination,
  sort,
  takeObject,
  idClient,
  idProvider,
}) => {
  const { SearchBar } = Search;

  // const rowStyle = (row, rowIndex) => {
  //   if (row) {
  //     return { backgroundColor: "#b8dce4" };
  //   }
  // };

  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      takeObject(row);
    },
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    clickToEdit: true,
    hideSelectColumn: true,
    // style: { backgroundColor: "#c8e6c9" },
  };

  return (
    <>
      <div className="easypay-table table-responsive">
        <ToolkitProvider
          keyField={idClient ? idClient : idProvider}
          data={docs ? docs : null}
          columns={columns}
          search
        >
          {(props) => (
            <div className="custom-table custom-table-small cursor-pointer">
              <div style={{ marginLeft: "50px" }}>
                <h5 style={{ marginLeft: "15px", marginTop: "20px" }}>
                  Procurar
                </h5>
                <SearchBar
                  {...props.searchProps}
                  style={{ width: "300px" }}
                  placeholder={idClient ? "Cliente, NIF..." : "Fornecedor..."}
                />
              </div>

              <div>
                <BootstrapTable
                  {...props.baseProps}
                  id="document-table-2"
                  pagination={
                    hidePagination ? false : paginationFactory(pagination)
                  }
                  hover
                  sort={sort}
                  bootstrap4
                  noDataIndication={t("onboarding.general.noData")}
                  selectRow={selectRow}
                  rowEvents={rowEventEditMod}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default withNamespaces()(TableList);
