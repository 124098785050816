import React from 'react';
import { withNamespaces } from 'react-i18next';
import oauth from '~/utils/oauth';

import { Modal } from 'react-bootstrap';
import '~/assets/css/styles.css';
import '~/assets/css/icons.css';

const FlowModal = props => {

  const { t, showModal, closeModal, process } = props;

  const token = oauth.getAccessToken();
  const authtoken = "&Authorization=" + token;
  const src = process ? '/iFlow/' + process.url + authtoken : "";
  return (
    <Modal
      dialogClassName="modal-flow-detail"
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('ProcessesWidget.processNumber')} &nbsp;{process && process.pnumber}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <iframe id="process"
          src={src}
          style={{
            frameborder: "0px",
            width: "100%",
            height: "100%",
            minHeight: "80vh",
            border: "none",
            display: "flex",
            overflowY: "scroll",
            margin: "0px"
          }}
        />
      </Modal.Body >
    </Modal >
  )

}

export default withNamespaces()(FlowModal);
