import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Button, Accordion } from "react-bootstrap";
import Spinner from "~/components/Spinner";
import infoIcon from "~/assets/img/icons/info-icon.png";
import Modal from "~/pages/Easypay/components/Modal";
import uuid from "uuid/v1";
import { FaClipboard } from "react-icons/fa";

const Encrypt = ({ tabKey, t }) => {
  const dispatch = useDispatch();
  const { resEncyptMWList, isLoadingEncrypt } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );
  const [password, setPassword] = useState("");
  const [responseEncryptMessage, setresponseEncryptMessage] = useState("");
  const [isModalShowing, setIsModalShowing] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setresponseEncryptMessage(resEncyptMWList);
  }, [resEncyptMWList]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(resEncyptMWList);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleSubmitEncrypt = () => {
    if (password == "") return;
    dispatch(actions.getSyncEncrypt(password));
    setIsModalShowing(true);
    setPassword("");
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper-30">
        <div>
          {responseEncryptMessage != "" ? (
            <span>
              Aqui está a sua password encriptada para o valor facultado.
              <br /> No ficheiro application.yml, substituir o{" "}
              <b style={{ color: "green" }}>placeholder</b> na última linha da
              seguinte configuração pela password encriptada
            </span>
          ) : (
            <span>
              A password encriptada resultante da chamada ao serviço no botão
              acima, deverá ser colocada no ficheiro application.yml,
              substituindo o <b style={{ color: "green" }}>placeholder</b> na
              ultima linha da seguinte configuração pela password encriptada.
            </span>
          )}

          <br />
          <br />
          <pre>
            <span>synchronization: </span>
            <br />
            <span style={{ marginLeft: "25px" }}>configuration:</span>
            <br />
            <span style={{ marginLeft: "55px" }}>check-alive:</span>
            <br />
            <span style={{ marginLeft: "85px" }}>middlewares-to-udw:</span>
            <br />
            <span style={{ marginLeft: "110px" }}>timer: 120000</span>
            <br />
            <span style={{ marginLeft: "85px" }}>udw-to-middlewares:</span>
            <br />
            <span style={{ marginLeft: "110px" }}>timer: 120000</span>
            <br />
            <span style={{ marginLeft: "55px" }}>organization:</span>
            <br />
            <span style={{ marginLeft: "85px" }}>id: 1</span>
            <br />
            <span style={{ marginLeft: "85px" }}>
              name: Unik Digital Workboard
            </span>
            <br />
            <span style={{ marginLeft: "55px" }}>middlewares:</span>
            <br />
            <span style={{ marginLeft: "85px" }}>list: portalrh, bpm</span>
            <br />
            <span style={{ marginLeft: "55px" }}>user:</span>
            <br />
            <span style={{ marginLeft: "85px" }}>
              username: synchronizationuser
            </span>
            <br />
            <span style={{ marginLeft: "85px" }}>
              password:{" "}
              <b style={{ color: "green" }}>meterPasswordEncriptadaAqui</b>
            </span>
            <br />
            <span style={{ marginLeft: "55px" }}>encryption:</span>
            <br />
            <span style={{ marginLeft: "85px" }}>salt: Xpto456!</span>
            <br />
          </pre>
          {responseEncryptMessage != "" && (
            <div>
              <span>
                Guarde a password num local seguro, só a irá ver uma vez:{" "}
              </span>
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <input
                  type="text"
                  value={resEncyptMWList}
                  readOnly
                  style={{ flex: 1, marginRight: "10px" }}
                />
                <button onClick={handleCopyClick} className="copy-button">
                  {isCopied ? "Copied!" : <FaClipboard />}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Spinner wrapper spinning={isLoadingEncrypt} />
      <Form.Row className="d-flex align-items-center">
        <Col lg="6">
          <h6 htmlFor="inputPassword5">
            Encrypt configuration Password
            <img
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                width: "20px",
                height: "20px",
              }}
              className="info-icon"
              onClick={() => {
                setIsModalShowing(true);
                setresponseEncryptMessage("");
              }}
              src={infoIcon}
              alt="Info Icon"
            ></img>
          </h6>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={password}
            placeholder="Password12345"
            onChange={handlePasswordChange}
          />
        </Col>
      </Form.Row>
      <Form.Row className="d-flex align-items-center">
        <Col lg="12" style={{ textAlignLast: "end", marginTop: "20px" }}>
          <Button
            className="card-button-red"
            onClick={() => handleSubmitEncrypt()}
          >
            Encrypt
          </Button>
        </Col>
      </Form.Row>
      {!isLoadingEncrypt && (
        <Modal
          className="easypay-modal"
          showModal={isModalShowing}
          setShowModal={setIsModalShowing}
          headerContent={"Encryption Info"}
          bodyContent={createBody()}
          key={uuid()}
        ></Modal>
      )}
    </>
  );
};

export default withNamespaces()(Encrypt);
