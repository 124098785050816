import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "~/pages/Easypay/components/Button";
import "../assets/scss/_header.scss";
import Link from "./Link";
import SelectLanguageMenu from "./SelectLanguageMenu";

const Header = ({
  redirect,
  logo,
  height,
  exit,
  hideExtraLinks = false,
  addDocument,
  id,
  t,
}) => {
  const [selected, setSelected] = useState(
    window.localStorage.getItem("lng")
      ? window.localStorage.getItem("lng").toUpperCase()
      : "PT"
  );
  const languages = ["PT", "EN", "ES"];

  const location = window.location.hash;

  const history = useHistory();

  return (
    <section className="easypay-header" id="easypay-header">
      <div className="easypay-header-top_logo">
        {redirect ? (
          <a
            className="easypay-header-logo-link"
            onClick={() => {
              window.localStorage.setItem("pid", "");
              window.localStorage.setItem("aliceProcessLink", "");
              window.location.reload();
            }}
          >
            <img
              className={`easypay-header-logo easypay-logo-height-${height}`}
              src={logo}
            />
          </a>
        ) : (
          <img
            className={`easypay-header-logo easypay-logo-height-${height}`}
            src={logo}
          />
        )}
      </div>
      <div className="easypay-header-top_navigation">
        {addDocument ? (
          <divs className = "easypay-header-add-doc-button-wrapper">
            <Button
              variant="easypay-document easypay-button-add"
              text={t(`bpm.onboarding.backoffice.user.labelAddNewDocument`)}
              onclick={() => {
                history.push({
                  pathname: `/uniksystem/easypay/backoffice-request-document-page/${id}`,
                });
              }}
            />
          </divs>
        ) : (
          ""
        )}
        <SelectLanguageMenu
          className="easypay-select-language"
          languages={languages}
          selected={selected}
          setSelected={setSelected}
        />
        <div className="easypay-header-links">
          {!hideExtraLinks ? (
            <>
              <div style={{ marginRight: "10px", height: "fit-content" }}>
                <Link
                  text={
                    location.includes("componentes") ? "Forms" : "Componentes"
                  }
                  link={
                    location.includes("componentes")
                      ? "#/uniksystem/easypay"
                      : "#/uniksystem/easypay/componentes"
                  }
                />
              </div>
              <div style={{ marginRight: "10px", height: "fit-content" }}>
                <Link
                  text={
                    location.includes("backoffice") ? "Forms" : "Backoffice"
                  }
                  link={
                    location.includes("backoffice")
                      ? "#/uniksystem/easypay"
                      : "#/uniksystem/easypay/backoffice"
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}
          <div style={{ height: "fit-content" }}>
            <Link
              text={t("bpm.onboarding.user.linkExit")}
              link={exit ? exit : "#/uniksystem"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withNamespaces()(Header);
