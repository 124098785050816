import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useSelector, useDispatch } from "react-redux";
import { getColumnFormatter } from "./columnFormatter";
import { Button, Form, Col } from "react-bootstrap";
import AddButton from "~/components/Buttons/AddButton";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import Modal from "~/pages/Easypay/components/Modal";
import CreateActionColumn from "./CreateActionColumn";

const ExpensesTable = ({
  t,
  app,
  expensesData,
  handleRefresh,
  setaddEditNewExpense,
  handleOpenEditModal,
  handleOpenDeleteModal,
  setshowEditModal,
  filterToggle,
  setFilterToggle,
  expensesToRender,
  setexpensesToRender,
}) => {
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  const [flowColumns, setFlowColumns] = useState(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/pesquisar` || item.route === `${app}/search`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        let flowColumns_;
        let hiddenDefaults_;
        // if (flowId && extraConfiguration_[flowId]) {
        //   flowColumns_ = extraConfiguration_[flowId].columns; //columns for a specific flow
        //   hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default columns for a specific flow
        // } else {
        flowColumns_ = extraConfiguration_.defaultExtraColumns; //extra columns for all flows
        hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default columns for all flows
        //}
        setFlowColumns(flowColumns_ ? flowColumns_ : undefined);
        setHiddenDeafultColumns(hiddenDefaults_ ? hiddenDefaults_ : undefined);
      }
    }
  }, [menu]);

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };
  if (flowColumns) {
    getColumns(flowColumns).forEach((column, index) =>
      columns.splice(1 + index, 0, column)
    );
  }
  const getColumns = (columns) => {
    const getCSVFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (formatExtraData.value === "SLA")
        return getColumnFormatter(cell, row, rowIndex, formatExtraData).replace(
          /,/g,
          ""
        );
      else
        return row[formatExtraData.value] !== null &&
          row[formatExtraData.value] !== undefined
          ? row[formatExtraData.value]
          : "";
    };
  };
  const isMobile = window.innerWidth < 992 ? true : false;
  const columns = [
    {
      dataField: "idGastos",
      text: "ID Gasto",
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "idGastos",
      },
      hidden: true,
      csvExport: !isHidden("idGastos"),
    },
    {
      dataField: "empresa",
      text: t(`budgetManagement.expenses.empresa`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "empresa",
      },
      hidden: isHidden("empresa"),
      csvExport: !isHidden("empresa"),
    },
    {
      dataField: "tipo",
      text: t(`budgetManagement.expenses.tipo`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "tipo",
      },
      hidden: isHidden("tipo"),
      csvExport: !isHidden("tipo"),
    },
    {
      dataField: "percentagem",
      text: t(`budgetManagement.expenses.percentagem`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "percentagem",
      },
      hidden: isHidden("percentagem"),
      csvExport: !isHidden("percentagem"),
    },
    // {
    //   dataField: "estado",
    //   text: t(`budgetManagement.expenses.estado`),
    //   sort: true,
    //   formatter: (cell, row) => (
    //     <span style={{ color: row.estado === 1 ? "green" : "red" }}>
    //       {row.estado === 1
    //         ? t(`budgetManagement.expenses.ativo`)
    //         : t(`budgetManagement.expenses.inativo`)}
    //     </span>
    //   ),
    //   headerStyle: () => {
    //     return { width: "20%", textAlign: "center" };
    //   },
    //   style: {
    //     textAlign: "-webkit-center",
    //   },
    //   csvExport: true,
    // },
    {
      dataField: "actions",
      text: t("validprocs.general.action"),
      formatter: (cell, row) => (
        <CreateActionColumn
          app={app}
          row={row}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      ),
      align: "center",
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
      style: {
        textAlign: "-webkit-center",
      },
      csvExport: false,
    },
  ];

  useEffect(() => {
    setexpensesToRender(expensesData);
  }, [expensesData]);

  const handleSwitchChange = (checked) => {
    switch (checked) {
      case true:
        setexpensesToRender(
          expensesData.filter((expense) => expense.estado === 1)
        );
        break;
      default:
        setexpensesToRender(expensesData);
    }
  };
  const { SearchBar } = Search;
  return (
    <>
      {expensesToRender && (
        <div className="custom-table">
          <ToolkitProvider
            keyField="idGastos"
            data={expensesToRender}
            columns={columns}
            search
            exportCSV={{
              fileName: "process.csv",
              separator: ";",
              noAutoBOM: true,
              blobType: "text/csv;charset=ansi",
            }}
          >
            {(props) => (
              <div>
                {/* <Form.Group
                  controlId="switchFilter"
                  style={{ display: "inline-flex" }}
                >
                  <Form.Label style={{ margin: "5px" }}>
                    {t(`budgetManagement.expenses.showactive`)}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={filterToggle}
                    style={{ margin: "5px" }}
                    onChange={(e) => setFilterToggle(e.target.checked)}
                    onClick={(e) => handleSwitchChange(e.target.checked)}
                  />
                </Form.Group> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <SearchBar
                    {...props.searchProps}
                    style={{ paddingLeft: "20px" }}
                    placeholder={t("general.search")}
                  />
                  <Button
                    style={{ marginBottom: "8px" }}
                    className="card-button"
                    onClick={() => handleRefresh()}
                  >
                    {t(`budgetManagement.expenses.btnUpdate`)}
                  </Button>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("taskPage.general.processesNotFound")}
                  sort={{
                    dataField: "tipo",
                    order: "asc",
                  }}
                  striped
                  hover
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      )}
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ProtectedComponent
          permissions={[{ label: "app-budgetmgr-expenses-create" }]}
        >
          <AddButton
            text={t(`budgetManagement.expenses.addNew`)}
            onClick={() => {
              setshowEditModal(true);
              setaddEditNewExpense(true);
            }}
          />
        </ProtectedComponent>
      </div>
    </>
  );
};

export default withNamespaces()(ExpensesTable);
