import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const CreateConfirmationModal = (props) => {
  const { t, handleCancelSubmit, handleConfirm, language, translations } =
    props;
  const [showModal, setShowModal] = useState(false);

  const { confirmMessage } = useSelector((state) => state.iflowReducer);

  useEffect(() => {
    if (confirmMessage) setShowModal(true);
  }, [confirmMessage]);

  const handleCancelSubmit_ = () => {
    handleCancelSubmit();
    setShowModal(false);
  };

  const handleConfirm_ = () => {
    handleConfirm();
    setShowModal(false);
  };
  const createMessage = (confirmMessage) => {
    if (!confirmMessage.includes("::t::")) {
      return confirmMessage;
    }
    const lingua = language.trim();
    const cleanTextField = confirmMessage.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal;
  };

  return confirmMessage ? (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>{createMessage(confirmMessage)}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleCancelSubmit_()}>
          {t("general.no")}
        </Button>
        <Button
          className="modal-submit-button"
          onClick={() => handleConfirm_()}
        >
          {t("general.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default withNamespaces()(CreateConfirmationModal);
