import React from "react";
import { Button } from "react-bootstrap";
import { IoCloudDownloadOutline } from "react-icons/io5";

const DownloadButton = ({ disabled, text, onClick, style }) => {
  return (
    <Button
      style={{ marginLeft: style ? "10px" : "" }}
      disabled={disabled}
      variant="outline-primary"
      onClick={onClick}
      className="custom-button"
    >
      {text}
      <IoCloudDownloadOutline
        style={{ fontSize: "x-large", marginLeft: "10px" }}
      />
    </Button>
  );
};

export default DownloadButton;
