import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "~/components/DatePicker";
import AnnotationIcons from "~/components/Tasks/AnnotationIcons";
import { updateAnnotation } from "~/store/ducks/processAnnotations/actionTypes";
import { withNamespaces } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { getUrlVars, getApplication } from "../utils";
const devLogConsole = require("~/utils/devLog");
const CreateAnnotateForwardTask = (props) => {
  const { t, jsonData, isModal, handleClose } = props;
  const { user } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  let { path } = useRouteMatch();

  const [dateValue, setDateValue] = useState(null);
  const [comment, setComment] = useState("");
  const [iconId, setIconId] = useState("");

  const handleEditAnnotation = () => {
    let transformedDate = "";
    if (dateValue) {
      //convert to example 09/12/2021, 00:00:00
      const dateValue_ = dateValue.toLocaleDateString("pt-PT");
      const dateArray = dateValue_.split("/");
      const day = dateArray[0];
      const month = dateArray[1];
      const year = dateArray[2];
      transformedDate = year + "/" + month + "/" + day;
    }
    devLogConsole(jsonData.url);
    //get flowid, pid, subpid from url
    if (jsonData.url) {
      const flowid = getUrlVars(jsonData.url)["flowid"];
      const pid = getUrlVars(jsonData.url)["pid"];
      const subpid = getUrlVars(jsonData.url)["subpid"];

      if (flowid && pid && subpid) {
        const payload = {
          flowid: flowid,
          pid: pid,
          subpid: subpid,
          deadline: transformedDate,
          iconid:
            (iconId === 0 && transformedDate === "") ||
            (iconId === 99999 && comment === "")
              ? ""
              : iconId, //dont save iconid when no deadline
          comment: comment,
        };

        const userBPM = user.legacyUsers.find(
          (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
        )?.username;
        dispatch(updateAnnotation(payload, userBPM));

        if (isModal && handleClose) {
          handleClose();
        } else {
          //find app from url
          const app = getApplication(window.location.href);

          if (app === "portalrh") {
            history.push(`/uniksystem/${app}/tasks`);
          } else {
            history.push(`/uniksystem/${app}/tarefas`);
          }
        }
      }
    }
  };

  return (
    <div style={{ padding: "0 15px" }}>
      <div className="annotation-preview-row">
        <span className="annotation-preview-header">
          {t(`taskPage.annotation.selectLabel`)}
        </span>
        <div className="tasks-annotation-icons-wrapper">
          <AnnotationIcons iconId={iconId} setIconId={setIconId} />
        </div>
      </div>
      <br />

      <div className="annotation-preview-row">
        <span className="annotation-preview-header">
          {t(`taskPage.annotation.setDeadline`)}
        </span>
        <DatePicker
          value={dateValue}
          onChange={(value) => {
            setDateValue(value);
          }}
          name={"annotation-deadline"}
          format={"dd/MM/yyyy"}
        />
      </div>

      <br />
      <p> {t(`taskforward.commentMessage`)}</p>
      <textarea
        className="form-control"
        style={{ width: "50%" }}
        type="textarea"
        maxLength="125"
        placeholder={t(`taskPage.annotation.typeComment`)}
        rows={3}
        cols={42}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <br />
      <button
        className="card-button btn btn-primary"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleEditAnnotation();
        }}
      >
        {t(`taskforward.saveButton`)}
      </button>
    </div>
  );
};

export default withNamespaces()(CreateAnnotateForwardTask);
