import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { withNamespaces } from "react-i18next";
import { getUOSiadapraDropdown, getUOSiadapraDetail } from "~/store/ducks/siadapra/actionTypes";
import { useHistory } from "react-router-dom";

const Organizacao = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { unidadesOrganicasDropdown, unidadesOrganicas } = useSelector(
    (state) => state.siadapraReducer
  );
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [tableData, setTableData] = useState([]);
  const { t } = props;
  useEffect(() => {
    dispatch(getUOSiadapraDropdown());
  }, []);
  useEffect(() => {
    if (unidadesOrganicas.length > 0) {
      setTableData(unidadesOrganicas);
    } else{
      setTableData([]);
    }
  }, [unidadesOrganicas]);
  useEffect(() => {
    if (unidadesOrganicasDropdown.length > 0) {
      setDropdownData(unidadesOrganicasDropdown);
    }
  }, [unidadesOrganicasDropdown]);

  const options =
    dropdownData.length > 0 &&
    dropdownData.map((option) => {
      return {
        value: option.descricao,
        label: option.descricao,
        selected: false,
      };
    });

  const handleSubmit = (value) => {
    const matchingItem = dropdownData.find((item) => item.descricao === value);
    if (matchingItem) {
      const id = matchingItem.id;
      setDropdownValue(id);
    }
  };
  useEffect(() => {
    if (dropdownValue !== null) {
      const payload = { sisId: dropdownValue };
      dispatch(getUOSiadapraDetail(payload));
    }
  }, [dropdownValue]);

  const actionsFormatter = (cell, row) => {
    const handleRedirect = () => {
      history.push(`/uniksystem/portalrh/siadapra/organizacao-detail/${row.id}/${dropdownValue}`);
    };

    return (
      <div>
        <i className="fa fa-arrow-right" onClick={handleRedirect}></i>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "objetivo",
      text: "Objetivo",
      sort: true,
    },
    {
      dataField: "superado",
      text: "Superado",
      sort: true,
    },
    {
      dataField: "atingido",
      text: "Atingido",
      sort: true,
    },
    {
      dataField: "naoAtingido",
      text: "Não Atingido",
      sort: true,
    },

    { dataField: "actions", text: "Ações", formatter: actionsFormatter },
  ];
  
  const tableRowEvents = {
    onClick: (_, row, __) => {
      history.push(`/uniksystem/portalrh/siadapra/organizacao-detail/${row.id}/${dropdownValue}`);
    },
  };

  return (
    <>
      <Row>
        <Col lg="4">
          {options.length > 0 && (
            <Dropdown
              list={options}
              handleSubmit={handleSubmit}
              emptyDefault={true}
            />
          )}
        </Col>
      </Row>
      {dropdownValue !== null && (
        <Row>
          <div className={"scrollBar-visibleModal"}>
            <ToolkitProvider
              keyField="id"
              data={tableData}
              columns={columns}
              search
            >
              {(props) => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    noDataIndication={t("portalrh.personalData.noRequests")}
                    striped
                    hover
                    classes="table-desempenho"
                    rowEvents={tableRowEvents}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </Row>
      )}
    </>
  );
};

export default withNamespaces()(Organizacao);
