import React from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../../../i18n";
import "../assets/scss/_replegmodal.scss";
import CustomButton from "./Button";
import Input from "./Input";
import Link from "./Link";
import Modal from "./Modal";

function RepLegModalContent({
  title,
  isModalShowing,
  setIsModalShowing,
  handleSubmitWrapperLink,
  info,
  t,
}) {
  var contents;

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function buildInputField(text, input, variable) {
    return {
      value: input.value,
      obligatory: false,
      variable: variable,
      maxlenght: 9,
      text: text,
    };
  }

  function createBody() {
    return (
      <div className="easypay-repleg-modal-body-wrapper">
        <div style={{ width: "420px" }}>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              info.get("Nome"),
              "repleg_nome_edit"
            )}
            key={"repleg_nome_edit"}
            index={0}
          ></Input>
        </div>
        <div style={{ width: "240px" }}>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelNIF`),
              info.get("NIF"),
              "repleg_nif_edit"
            )}
            key={"repleg_nif_edit"}
            index={1}
          ></Input>
        </div>
        <div style={{ width: "420px" }}>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelRole`),
              info.get("Cargo"),
              "repleg_cargo_edit"
            )}
            key={"repleg_cargo_edit"}
            index={2}
          ></Input>
        </div>
        <div
          className="easypay-repleg-modal-footer-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            textAlign: "right",
          }}
        >
          <div
            className="easypay-repleg-modal-link-wrapper"
            style={{ paddingTop: "8px", textAlign: "right" }}
            onClick={(event) => {
              event.preventDefault();
              setIsModalShowing(false);
            }}
          >
            <Link
              text={t(`bpm.onboarding.backoffice.user.buttonCancel`)}
              disabledColored={false}
              disabled={false}
              link=""
              key={uuid()}
            />
          </div>
          <div
            className="easypay-repleg-modal-button-wrapper"
            style={{ paddingLeft: "25px" }}
          >
            <CustomButton
              text={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
              variant={"easypay-button-primary"}
              onclick={eval(addButtonField.onclick)}
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={addButtonField.align}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            />
          </div>
        </div>
      </div>
    );
  }

  contents = {
    modalTitle: title,
    modalBody: createBody(),
  };

  return (
    <Modal
      className="easypay-modal"
      showModal={isModalShowing}
      setShowModal={setIsModalShowing}
      headerContent={contents.modalTitle}
      bodyContent={contents.modalBody}
      key={uuid()}
    ></Modal>
  );
}

export default withNamespaces()(RepLegModalContent);
