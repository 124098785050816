import React from "react";
import {
  FormControlLabel,
  Switch,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import { Button, Row, Col, Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import RemoveButton from "~/components/Buttons/RemoveButton";
import Divider from "@mui/material/Divider";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";

const OpenAiDocumentConfiguration = (props) => {
  const {
    setIsEditing,
    setOpenAIID,
    openAiConfigurationState,
    handleUpdate,
    openAIID,
    deleteDoc,
    index,
    handlePromptButtonClick,
  } = props;

  return (
    <>
      <div
        style={{
          background: "whitesmoke",
          borderRadius: "10px",
          padding: "1rem",
          margin: "1rem 0rem",
        }}
      >
        <Row>
          <Col lg="3" md="12" sm="12">
            <Form.Label>Tipo de Documento</Form.Label>
            <Form.Control
              type="text"
              value={openAiConfigurationState.documentType}
              onChange={(e) => {
                handleUpdate("documentType", e.target.value);
                setIsEditing(true);
              }}
              autoComplete="off"
            />
          </Col>
          <Col lg="7" md="12" sm="12">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              type="text"
              value={openAiConfigurationState.documentDescription}
              onChange={(e) => {
                handleUpdate("documentDescription", e.target.value);
                setIsEditing(true);
              }}
              autoComplete="off"
            />
          </Col>
          <Col style={{ textAlignLast: "right" }}>
            <RemoveButton text="Remove" onClick={() => deleteDoc(index)} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="2" md="12" sm="12">
            <Form.Label>Flow ID</Form.Label>
            <Form.Control
              type="text"
              value={openAiConfigurationState.flowId}
              onChange={(e) => {
                handleUpdate("flowId", e.target.value);
                setIsEditing(true);
              }}
              autoComplete="off"
            />
          </Col>
          <Col lg="4" md="12" sm="12">
            <FormControlLabel
              control={
                <Switch
                  checked={openAiConfigurationState.extraction || false}
                  onChange={() => {
                    handleUpdate(
                      "extraction",
                      !openAiConfigurationState.extraction
                    );
                    setIsEditing(true);
                  }}
                  color={
                    openAiConfigurationState.extraction
                      ? "primary"
                      : "secondary"
                  }
                />
              }
              label="Extraction"
              labelPlacement="top"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={openAiConfigurationState.preview || false}
                  onChange={() => {
                    handleUpdate("preview", !openAiConfigurationState.preview);
                    setIsEditing(true);
                  }}
                  color={
                    openAiConfigurationState.preview ? "primary" : "secondary"
                  }
                />
              }
              label="Preview"
              labelPlacement="top"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={openAiConfigurationState.correction || false}
                  onChange={() => {
                    handleUpdate(
                      "correction",
                      !openAiConfigurationState.correction
                    );
                    setIsEditing(true);
                  }}
                  color={
                    openAiConfigurationState.correction
                      ? "primary"
                      : "secondary"
                  }
                />
              }
              label="Correction"
              labelPlacement="top"
            />
          </Col>
          <Col>
            <Form.Label>Campos para Prompt</Form.Label>
            <div
              style={{
                border: "1px solid #ced4da",
                borderRadius: "23px",
                padding: "1rem",
                backgroundColor: "white",
              }}
            >
              <TextField
                multiline
                placeholder="Chave: Valor;"
                rows={5}
                variant="outlined"
                fullWidth
                value={openAiConfigurationState.fields}
                onChange={(e) => {
                  handleUpdate("fields", e.target.value);
                  setIsEditing(true);
                }}
              />
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="10" md="12" sx="12">
            <Form.Label>Prompt</Form.Label>
            <div
              style={{
                border: "1px solid #ced4da",
                borderRadius: "23px",
                padding: "1rem",
                backgroundColor: "white",
              }}
            >
              <TextField
                multiline
                placeholder="Prompt..."
                rows={3}
                variant="outlined"
                fullWidth
                value={openAiConfigurationState.prompt}
                onChange={(e) => {
                  handleUpdate("prompt", e.target.value);
                  setIsEditing(true);
                }}
              />
            </div>
          </Col>
          <Col style={{ placeSelf: "center" }}>
            <Button
              className="card-button"
              onClick={() => handlePromptButtonClick(index)}
            >
              Generate Prompt
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OpenAiDocumentConfiguration;
