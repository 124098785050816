import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding/text.min";

import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "./i18n";
import App from "./containers/App";
import "./assets/scss/layout.scss"; //SHolanda
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store/store";
import Keycloak from "keycloak-js";
import LoadingKeycloak from '~/components/LoadingKeycloak'
import "./fonts/fonts.css"

const AppWrapper = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const SERVER_URL = window.location.origin;
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(SERVER_URL + '/uniksystem/api/open/keycloak-settings');
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error("Error fetching keycloak data:", error);
      }
    };
 
    setTimeout(function () {
      if(data == undefined || data == null) 
        fetchData();
    }, 1000);
    setTimeout(function () {
      if(data != undefined || data != null) 
      setIsLoading(false);
    }, 3000);
  }, []);
 
  if (!data && isLoading) {
    // Render a loading state or fallback component while data is being fetched
    return <LoadingKeycloak />;
  }
 
  return (
    <Provider store={store}>
      <App isCma={data.isCma} keycloak={new Keycloak(data.keycloakData)} />
    </Provider>
  );
};
 
render(<AppWrapper />, document.getElementById("root"));
