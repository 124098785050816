import React, { useEffect, useState, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { pagination200_1000 } from "~/components/pagination/currentSizePerPage/200";
import "~/assets/css/icons.css";
import { Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

const TaskTablePag200 = (props) => {
  const {
    t,
    tasksToRender,
    columns,
    field,
    order,
    isMobile,
    rowEvents,
    handleSearch,
    searchBarValue,
    setSearchBarValue,
  } = props;

  const { SearchBar } = Search;
  const [tasksToRenderFiltered, setTaskToRenderFiltered] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row?.read_flag === 0) {
      style.fontWeight = "bold";
    }

    return style;
  };

  const clearFilter = () => {
    setShowFilter(false);
    setTaskToRenderFiltered([]);
    setSearchBarValue("");
  };

  useEffect(() => {
    if (searchBarValue && tasksToRender) {
      let newArrFiltered = tasksToRender.filter(
        (element) => element?.from === searchBarValue
      );
      setTaskToRenderFiltered(newArrFiltered);
      setShowFilter(true);
    } else {
      setTaskToRenderFiltered([]);
      setShowFilter(false);
    }
  }, [searchBarValue, tasksToRender]);


  return (
    <>
      <div className="custom-table task-table">
        <ToolkitProvider
          keyField="pnumber"
          data={
            tasksToRenderFiltered.length > 0
              ? tasksToRenderFiltered
              : tasksToRender
          }
          columns={columns}
          search
        >
          {(props) => (
            <div style={{ paddingBottom: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SearchBar
                    {...props.searchProps}
                    style={{ paddingLeft: "20px" }}
                    placeholder={t("general.search")}
                  />
                  {searchBarValue && showFilter ? (
                    <div
                      style={{
                        border: "1px solid",
                        borderRadius: "10px",
                        marginLeft: "15px",
                        padding: " 6px 18px",
                        minHeight: "42px",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ marginLeft: "15px" }}>Enviado por: </span>
                      <span style={{ fontWeight: "bold" }}>
                        {searchBarValue}
                      </span>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() => clearFilter()}
                      >
                        <AiOutlineClose
                          size="25px"
                          style={{ marginBottom: "2px" }}
                        />
                      </i>
                    </div>
                  ) : null}
                </div>
                <Button
                  style={{ marginBottom: "8px" }}
                  className="card-button"
                  onClick={() => handleSearch()}
                >
                  {t(`taskPage.general.update`)}
                </Button>
              </div>
              <div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination200_1000)}
                  noDataIndication={t("taskPage.general.tasksNotFound")}
                  sort={{
                    dataField: field,
                    order: order,
                  }}
                  striped={isMobile ? false : true}
                  hover={isMobile ? false : true}
                  rowEvents={rowEvents}
                  rowStyle={rowStyle}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(TaskTablePag200);
