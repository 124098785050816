import React from "react";
import "../assets/scss/_proposal.scss";
import "../assets/scss/_option.scss";
import { useDispatch, useSelector } from "react-redux";
import ProposalResumeTable from "./ProposalResumeTable";
const ProposalResumeSection = ({
  info,
  workplanIndex,
  percentage,
  hoursPercentage,
  suppliersPercentage,
  costsPercentage,
  simulator
}) => {
  const dispatch = useDispatch();


  const { workplans, clients } = useSelector(
    (state) => state.budgetManagementReducer
  );

  function convertIdToName(id, name) {
    if (name === "clients" && clients != null) {
      const client = clients.find((x) => x.idCliente === parseInt(id));
      if (client != null) return client?.nome;
    }
  }

  return (
    <div className="liftworld-proposal-resume-wrapper">
      <ProposalResumeTable
        workplanIndex={workplanIndex}
        percentage={percentage}
        hoursPercentage={hoursPercentage}
        suppliersPercentage={suppliersPercentage}
        costsPercentage={costsPercentage}
        simulator = {simulator}
      />
    </div>
  );
};
export default ProposalResumeSection;
