import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card, Col, Button, Form, Table, Alert } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";

const ExtendedAttributes = (props) => {
    
    const { t, element, readOnly, addLabelToArr, index} = props;

    const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

    const [inputValue, setInputValue] = useState();
    const [selectedValue, setSelectedValue] = useState();

    function dataChanged(data) {
       addLabelToArr(element?.key, data.target.value);
    }

    function selectDataChanged(data) {
     addLabelToArr(element?.key, data);
  }

    return (<div style={index == 0 ? {flex: 1, width: '40%'} : {flex: 1, paddingLeft: 10}}>
                    {  
                    element.list ?
                    <>
                        <Form.Label>
                    { t(`admin.userManagement.externalAttributes.${element?.key}`)} {asterisk}
                      </Form.Label>
                       <Dropdown
                            list={element?.list}
                            handleSubmit={selectDataChanged}
                            emptyDefault={true}
                            placeholder={t("signature.selectOption")}
                            disabled={readOnly}
                            required
                    />
                    </>
                    :
                    <>
                    <Form.Label>
                    { t(`admin.userManagement.externalAttributes.${element?.key}`)} {asterisk}
                      </Form.Label>
                    <br></br>
                    <Form.Control style={index == 0 ? {width: '50%'} : {width: '75%', paddingLeft: 10}}
                        type="text"
                        value={readOnly ? element?.value : inputValue} 
                        onChange={dataChanged}
                        placeholder={ t(`admin.userManagement.externalAttributes.placeholder`)}
                        disabled={readOnly}
                        required
                      />
                    </>    
                }
            </div>)
}

export default withNamespaces()(ExtendedAttributes);