import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonDownload from "~/components/Buttons/DownloadButton";
import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
import DatePicker from "~/components/DatePicker";
import {
  findAllBatchsWithBinary,
  updateDocuments,
  findAllCustomBatchs,
} from "~/pages/DataCapture/actions";
import { toLocaleString } from "~/utils";
import { exportCSV } from "~/utils/exportCSV";
import ExportFilter from "./ExportFilter";

const ExportModal = (props) => {
  const {
    t,
    showModal,
    closeModal,
    reviewedDocs,
    buildDocTypeColumn,
    datacaptureConfiguration,
  } = props;
  const moment = require("moment");
  const {
    isUpdatedDocuments,
    batchsWithBinary,
    isLoadingBatchsWithBinary,
    custombatchs,
  } = useSelector((state) => state.dataCaptureReducer);

  const [modalClassName, setModalClassName] = useState("modal-tarefa-80w");
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");
  const [filteredDocuments, setFilteredDocuments] = useState(reviewedDocs);
  const [filteredDocumentsDate, setFilteredDocumentsDate] = useState(reviewedDocs);
  const [filter, setFilter] = useState("");
  const [message, setMessage] = useState(false);
  const [showDates, setshowDates] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  let filteredDocsWithBinary = [];

  const filterDocuments = {
    today: (docs) => {
      const currentDay = new Date();
      const range = [currentDay];

      return applyFilterInDocuments(range, docs);
    },

    week: (docs) => {
      const currentDay = new Date();
      const first = currentDay.getDate() - currentDay.getDay() + 1; // First day is the day of the month - the day of the week
      const last = first + 6; // last day is the first day + 6
      const firstDay = new Date(currentDay.setDate(first));
      const lastDay = new Date(currentDay.setDate(last));
      const range = [firstDay, lastDay];

      return applyFilterInDocuments(range, docs);
    },

    month: (docs) => {
      const currentDay = new Date();
      const year = currentDay.getFullYear();
      const month = currentDay.getMonth();
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const range = [firstDay, lastDay];

      return applyFilterInDocuments(range, docs);
    },
    all: (docs) => {
      return applyFilterInDocuments("all", docs);
    },
    new: (docs) => {
      return applyFilterInDocuments("new", docs);
    },
  };

  useEffect(() => {
    if (custombatchs) {
      setMessage(false);
    }
  }, [custombatchs]);

  useEffect(() => {
    if (!isLoadingBatchsWithBinary) {
      batchsWithBinary.forEach((batchBinary) => {
        batchBinary.documents.forEach((docs_) => {
          if (
            docs_?.statusV1?.includes("REVISED") ||
            docs_?.statusV1?.includes("REVIEWED")
          ) {
            docs_.batchFlow = batchBinary.flow;
            filteredDocsWithBinary.push(docs_);
          }
        });
      });
      if (batchsWithBinary.length) {
        if (filteredDocsWithBinary && filter) {
          let filteredDocsWithBinary_ = [];
          filteredDocsWithBinary_ = filterDocuments[filter](
            filteredDocsWithBinary
          );
          const docTypes = collectDocTypes(filteredDocsWithBinary_);
          const docSortedByDocType = sortDocumentsByType(docTypes);
          const columnsByDocType = createColumns(docTypes, docSortedByDocType);

          if (filteredDocsWithBinary_.length > 0 && docTypes.length > 0) {
            docTypes.forEach((docT) => {
              const fileName = getDoctypeLabel(docT);
              const data =
                docSortedByDocType &&
                docSortedByDocType.find((i) => i.type === docT)?.items;
              const columns =
                columnsByDocType &&
                columnsByDocType.find((i) => i.type === docT)?.items;
              data &&
                columns &&
                exportCSV(data, columns, "", fileName, ";", true);
            });
          }
        }
      }
    }
  }, [batchsWithBinary]);

  const dispatch = useDispatch();

  const handleClick = () => {
    modalClassName === "modal-tarefa-80w"
      ? setModalClassName("modal-tarefa-full-screen")
      : setModalClassName("modal-tarefa-80w");

    iconClassName === "icon-maximize mr-4"
      ? setIconClassName("icon-restore mr-4")
      : setIconClassName("icon-maximize mr-4");
  };

  const handleClose = () => {
    setModalClassName("modal-tarefa-80w");
    setIconClassName("icon-maximize mr-4");
    closeModal();
  };

  const options = {
    paginationSize: 5,
    pageStartIndex: 0,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
    ],
  };

  let processedColumns = [
    {
      dataField: "createdDate",
      text: t("datacapture.general.created"),
      formatter: (_, row, {}) => toLocaleString(row.createdDate),
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleString(row.createdDate).replace(/,/g, ""),
      headerStyle: () => {
        return { width: "180px", textAlign: "center" };
      },
      csvExport: false,
    },
    {
      dataField: "filename",
      text: t("datacapture.general.filename"),
      style: {
        overflowWrap: "break-word",
      },
      csvExport: false,
    },
    {
      dataField: "Document", //"metadata.documentClass",
      text: t("datacapture.general.documentClass"),
      formatter: buildDocTypeColumn,
      headerStyle: () => {
        return { width: "180px", textAlign: "center" };
      },
      csvExport: false,
    },
  ];

  const commonExportCSVColumns = [
    {
      dataField: "id",
      text: t("datacapture.exportCSV.id"),
      hidden: true,
    },
    {
      dataField: "document_id",
      text: t("datacapture.exportCSV.document_id"),
      hidden: true,
    },
    {
      dataField: "filename",
      text: t("datacapture.exportCSV.document_name"),
      hidden: true,
    },
    {
      dataField: "createdDate",
      text: t("datacapture.exportCSV.submission_date"),
      hidden: true,
    },
    {
      dataField: "revisionDate",
      text: t("datacapture.exportCSV.revision_date"),
      hidden: true,
    },
  ];

  const handleChangeDate = (value) => {
    if (startDate != "" && endDate != "") {
      const documentosNoIntervalo = filteredDocumentsDate.filter((documento) => {
        const documentDate = moment(
          documento.createdDate,
          "YYYY-MM-DD"
        ).startOf("day");

        const formattedStartDate = moment(startDate, "YYYY-MM-DD").startOf(
          "day"
        );
        const formattedEndDate = moment(endDate, "YYYY-MM-DD").startOf("day");
        return (
          documentDate.isSameOrAfter(formattedStartDate) &&
          documentDate.isSameOrBefore(formattedEndDate)
        );
      });

      setFilteredDocuments(documentosNoIntervalo);
    }
  };

  const handleReset = () => {
    setendDate("");
    setstartDate("");
    setshowDates(false);
  };

  const getLabel = (value) => {
    if (
      datacaptureConfiguration &&
      datacaptureConfiguration[0] &&
      datacaptureConfiguration[0].metadata
    ) {
      const foundMetadata = datacaptureConfiguration[0].metadata.find(
        (d) => d.value === value
      );
      if (foundMetadata) {
        return foundMetadata.label;
      } else return value;
    }
    return value;
  };

  const getDoctypeLabel = (docType) => {
    if (datacaptureConfiguration) {
      const foundObj = datacaptureConfiguration.find(
        (config) => config.documentType === docType
      );
      return foundObj ? foundObj.label : "spreadsheet";
    }
  };

  const collectDocTypes = (filteredDocuments) => {
    let docTypes = [];
    filteredDocuments.forEach((d) => {
      if (!docTypes.some((doc) => doc === d.batchFlow)) {
        docTypes.push(d.batchFlow);
      }
    });
    return docTypes;
  };

  const sortDocumentsByType = (docTypes) => {
    let docSortedByDocType = [];
    if (docTypes.length > 0) {
      docTypes.forEach((docT) => {
        const clonedDocuments = JSON.parse(JSON.stringify(filteredDocuments));
        const arr = clonedDocuments.filter((doc) => doc.batchFlow === docT);
        //prepare nested fields for export csv
        arr.forEach((item) => {
          if (item.metadata && Object.keys(item.metadata).length > 0) {
            Object.keys(item.metadata).forEach((key) => {
              if (
                Array.isArray(item.metadata[key]) ||
                typeof item.metadata[key] === "object"
              ) {
                item.metadata[key] = JSON.stringify(item.metadata[key]);
              }
            });
          }
        });
        arr.length > 0 &&
          docSortedByDocType.push({
            type: docT,
            items: arr,
          });
      });
    }
    return docSortedByDocType;
  };

  const createColumns = (docTypes, docSortedByDocType) => {
    const columnsByDocType = [];
    if (docTypes.length > 0 && docSortedByDocType.length > 0) {
      docTypes.forEach((docT) => {
        const obj = docSortedByDocType.find((d) => d.type === docT);
        let columns = [...commonExportCSVColumns];
        obj &&
          obj.items &&
          obj.items.forEach((i) => {
            i.metadata &&
              Object.keys(i.metadata).forEach((key) => {
                if (Array.isArray(i.metadata[key])) {
                  !columns.some((c) => c.dataField === `metadata.${key}`) &&
                    columns.push({
                      dataField: `metadata.${key}`,
                      text: getLabel(key),
                      hidden: true,
                    });
                } else {
                  !columns.some((c) => c.dataField === `metadata.${key}`) &&
                    columns.push({
                      dataField: `metadata.${key}`,
                      text: getLabel(key),
                      hidden: true,
                    });
                }
              });
          });
        columns.length > 0 &&
          columnsByDocType.push({
            type: docT,
            items: columns,
          });
      });
    }
    return columnsByDocType;
  };

  const docTypes = collectDocTypes(filteredDocuments);
  const docSortedByDocType = sortDocumentsByType(docTypes);
  const columnsByDocType = createColumns(docTypes, docSortedByDocType);

  const MyExportCSV = () => {
    const handleClick = () => {
      if (filteredDocuments.length > 0 && docTypes.length > 0) {
        docTypes.forEach((docT) => {
          const fileName = getDoctypeLabel(docT);
          const data =
            docSortedByDocType &&
            docSortedByDocType.find((i) => i.type === docT).items;
          const columns =
            columnsByDocType &&
            columnsByDocType.find((i) => i.type === docT).items;
          data && columns && exportCSV(data, columns, "", fileName, ";", false);
        });
      }
    };

    return (
      <div style={{ paddingRight: "20px" }}>
        <ButtonDownload
          text={t("datacapture.general.downloadCSV")}
          onClick={handleClick}
        />
      </div>
    );
  };

  const download = (content, fileName, contentType) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  const onDownload = (files, fileName) => {
    const filteredFiles = files.map((field) => {
      delete field.data;
      delete field.batchFlow;
      delete field._datcaptureConfig;
      return field;
    });
    download(JSON.stringify(filteredFiles, null, 4), fileName, "text/plain");
  };

  const callBack = (filterFromChild) => {
    //filterFromChild possible values in string: "today", "week", "month", "all", "new"
    setFilter(filterFromChild);
    const filteredDocs = filterDocuments[filterFromChild](reviewedDocs);

    if (isUpdatedDocuments) {
      setFilteredDocuments(
        filteredDocs.map((doc) => {
          doc.marked_timestamp = "exported";
          return doc;
        })
      );
    } else {
      setFilteredDocuments(filteredDocs);
    }
  };

  const applyFilterInDocuments = (range, docs) => {
    return docs.filter((doc) => {
      let utc = new Date(doc.createdDate);
      if (range.length === 1) {
        if (
          utc.toISOString().split("T")[0] ===
          range[0].toISOString().split("T")[0]
        ) {
          return doc;
        }
      } else if (range.length === 2) {
        if (
          Date.parse(doc.createdDate.split("T")[0]) >=
            Date.parse(range[0].toISOString().split("T")[0]) &&
          Date.parse(doc.createdDate.split("T")[0]) <=
            Date.parse(range[1].toISOString().split("T")[0])
        ) {
          return doc;
        }
      } else if (range === "new") {
        if (!doc.marked_timestamp) {
          return doc;
        }
      } else if (range === "all") {
        return doc;
      }
    });
  };

  const markExportedDocuments = (documents) => {
    dispatch(updateDocuments(documents));
    setFilteredDocuments([]);
  };

  const getBatchsWithBinary = () => {
    dispatch(findAllBatchsWithBinary());
  };
  const isMobile = window.innerWidth < 992 ? true : false;

  const handldownloadData = (value) => {
    switch (value) {
      case "OLD":
        if (filteredDocuments.length > 0) {
          onDownload(filteredDocuments, "dataCapture.json");
          if (filter === "new") markExportedDocuments(filteredDocuments);
        }
        break;
      case "NEW":
        setTimeout(() => {
          dispatch(findAllCustomBatchs(filteredDocuments));
        }, 1000);
        setMessage(true);
        break;
      default:
        if (filteredDocuments.length > 0) {
          onDownload(filteredDocuments, "dataCapture.json");
          if (filter === "new") markExportedDocuments(filteredDocuments);
        }
        break;
    }
  };

  // const handldownloadData=()=>{
  //   dispatch(findAllCustomBatchs());
  //   if(custombatchs == null){
  //     if (filteredDocuments.length > 0) {
  //       onDownload(filteredDocuments, "dataCapture.json");
  //       if (filter === "new") markExportedDocuments(filteredDocuments);
  //     }
  //   } else {
  //     if (isUpdatedDocuments) {
  //       let filteredsutomdoc = custombatchs.map((doc) => {
  //         doc.marked_timestamp = "exported";
  //       })
  //       onDownload(filteredsutomdoc, "custom_dataCapture.json");
  //   } else {
  //     onDownload(custombatchs, "custom_dataCapture.json");
  //   }
  //   }
  // }

  return (
    <>
      <Modal
        dialogClassName={modalClassName}
        show={showModal}
        onHide={closeModal}
        backdrop="static"
      >
        <Modal.Header
          className="justify-content-between"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title style={isMobile ? { fontSize: "18px" } : {}}>
            {t("datacapture.general.modalTitle")}
          </Modal.Title>

          <div>
            <i className={iconClassName} onClick={() => handleClick()} />
            <i className="icon-remove mr-4" onClick={() => handleClose()} />
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "1.25rem", minHeight: "60vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
              height: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <span>
                <h6>Filter by:</h6>
                <ExportFilter
                  callBack={callBack}
                  setshowDates={setshowDates}
                  handleReset={handleReset}
                />
              </span>
            </div>
            {showDates && (
              <div style={{ display: "flex", margin:'20px' }}>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <span className="annotation-preview-header" style={{margin:'10px'}}>
                    {t(`admin.organization.list.startAt`)}
                    </span>
                    <DatePicker
                      value={startDate}
                      onChange={(value) => {
                        setstartDate(value);
                        handleChangeDate(value);
                      }}
                      name={"annotation-deadline"}
                      format={"dd/MM/yyyy"}
                    />
                    <span className="annotation-preview-header" style={{margin:'10px'}}>
                      {t(`admin.organization.list.endAt`)}
                    </span>
                    <DatePicker
                      value={endDate}
                      onChange={(value) => {
                        setendDate(value);
                        handleChangeDate(value);
                      }}
                      name={"annotation-deadline"}
                      format={"dd/MM/yyyy"}
                    />
                </div>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <span>
                <h6>Sample preview</h6>
              </span>
            </div>

            <div
              className="custom-table"
              style={
                isMobile
                  ? {
                      transform: "scale(0.8)",
                      transformOrigin: "0 0",
                      minHeight: "0",
                    }
                  : {}
              }
            >
              <ToolkitProvider
                keyField="id"
                data={filteredDocuments}
                columns={processedColumns}
                exportCSV={{
                  fileName: "spreadsheet.csv",
                  separator: ";",
                  noAutoBOM: true,
                  blobType: "text/csv;charset=ansi",
                }}
              >
                {(props) => (
                  <>
                    <BootstrapTable
                      {...props.baseProps}
                      id="document-table-filtered"
                      bootstrap4
                      pagination={paginationFactory(options)}
                      noDataIndication={t("datacapture.general.dataNotFound")}
                      hover
                      striped
                    />
                  </>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex" }}>
            <MyExportCSV />
            <ButtonDownload
              text={t("datacapture.general.downloadZIP")}
              onClick={() => {
                if (filteredDocuments.length > 0) {
                  getBatchsWithBinary();
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonDownload
              text={t("datacapture.general.downloadDocuments")}
              onClick={() => {
                handldownloadData("OLD");
              }}
            />
            <ButtonDownload
              style={true}
              text={t("datacapture.general.newdownloadDocuments")}
              onClick={() => {
                handldownloadData("NEW");
              }}
            />
            {message && (
              <p style={{ margin: "0px 0px 0px 10px", color: "#4484f4" }}>
                {t("datacapture.general.waitfordownload")}
              </p>
            )}
          </div>
        </Modal.Footer>
        <Spinner spinning={isLoadingBatchsWithBinary} />
      </Modal>
      <Messages />
    </>
  );
};

export default withNamespaces()(ExportModal);
