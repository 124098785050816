import React, { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import { getAddress } from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import ButtonsUpdate from "./ButtonsUpdate";
const devLogConsole = require("~/utils/devLog");
const RhAddress = ({ rowCollaborator, t }) => {
  const [addressValue, setAddressValue] = useState("");
  const [location, setLocacion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCode2, setPostalCode2] = useState("");
  const [phone, setPhone] = useState("");
  const [cellphone, setcellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [checkActive, setCheckActive] = useState(false);
  const [checkMain, setCheckMain] = useState(false);
  const [rowClicked, setRowClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const { addressData } = useSelector((state) => state.personalReducer);
  devLogConsole("ES data", addressData);

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(
        getAddress(rowCollaborator.funcionarioNum, rowCollaborator.codEntidade)
      );
    }
  }, [rowCollaborator]);

  const handleAdressValue = (e) => {
    setAddressValue(e.target.value);
  };

  const handleLocation = (e) => {
    setLocacion(e.target.value);
  };

  const handlePostalCode = (e) => {
    setPostalCode(e.target.value);
  };

  const handlePostalCode2 = (e) => {
    setPostalCode2(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleCellPhone = (e) => {
    setcellPhone(e.target.value);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (data.length > 0) {
        setAddressValue(data[0].address);
        setLocacion(data[0].location);
        setEmail(data[0].email);
        setcellPhone(data[0].cellphone);
        setPhone(data[0].phone);
        setPostalCode(data[0].postCard);
        setPostalCode2(data[0].postCard2);

        if (data[0].main == "1") {
          setCheckMain(true);
        } else {
          setCheckMain(false);
        }

        if (data[0].situation == "1") {
          setCheckActive(true);
        } else {
          setCheckActive(false);
        }
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(addressData);
    }
  }, [addressData, rowClicked]);

  const formatWithCheckIcon = (cell, row) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const columns = [
    {
      dataField: "address",
      text: t(`portalrh.Address.Address`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "location",
      text: t(`portalrh.Address.Location`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "main",
      text: t(`portalrh.Address.Main`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
    {
      dataField: "situation",
      text: t(`portalrh.Address.Active`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Address`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={addressValue == null ? "" : addressValue}
              onChange={handleAdressValue}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Location`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={location == null ? "" : location}
              onChange={handleLocation}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xs="6" xl="2" md="3">
            <Form.Label className="rhcollaborator__labels__before">
              {t(`portalrh.Address.Location`)}
            </Form.Label>
            <Form.Label className="rhcollaborator__labels__after">
              {t(`portalrh.Address.Zip-Code`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={postalCode == null ? "" : postalCode}
              onChange={handlePostalCode}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xs="6" xl="2" md="3">
            <Form.Label className="rhcollaborator__labels__postalCode">
              {" "}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={postalCode2 == null ? "" : postalCode2}
              onChange={handlePostalCode2}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="5">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Email`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={email == null ? "" : email}
              onChange={handleEmail}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Phone`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={phone == null ? "" : phone}
              onChange={handlePhone}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Mobile`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={cellphone == null ? "" : cellphone}
              onChange={handleCellPhone}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div>
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.Address.Main`)}
            checked={checkMain == null ? "" : checkMain}
            style={{ paddingBottom: 10 }}
            onChange={() => setCheckMain((checkMain) => !checkMain)}
            disabled={isClicked ? false : true}
          />
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.Address.Active`)}
            checked={checkActive == null ? "" : checkActive}
            style={{ paddingBottom: 10, paddingLeft: 50 }}
            onChange={() => setCheckActive((checkActive) => !checkActive)}
            disabled={isClicked ? false : true}
          />
        </div>
        <RhTableList
          tableId={"32"}
          columns={columns}
          data={addressData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhAddress);
