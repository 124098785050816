/*
 * Development variables.
 */
//export const BACKEND_DEV_SERVER_URL = "https://unikint.uniksystem.pt";
export const BACKEND_DEV_SERVER_URL = "http://localhost"; /*Local proxy*/
//export const BACKEND_DEV_SERVER_URL = "http://20.166.64.185"; /*EDENRED*/
//export const BACKEND_DEV_SERVER_URL = "http://172.20.0.102" /*DGADR*/
//export const BACKEND_DEV_SERVER_URL = "http://10.101.8.99/"; /*CMA DSV*/
//export const BACKEND_DEV_SERVER_URL = "https://mapei-testes.cl.uniksystem.com/";
export const NODE_DEV_SERVER_URL = "http://localhost:5000";
export const SIGN_PDF_LOCAL_APP_URL = "http://localhost:8378";

/*
 * Deploy constants.
 *
 * IMPORTANT: DON'T CHANGE VARIABLES BELLOW!
 */
export const BACKEND_SERVER_URL = () =>
  process.env.NODE_ENV === "development"
    ? BACKEND_DEV_SERVER_URL
    : window.location.origin;

export const NODE_SERVER_URL = () =>
  process.env.NODE_ENV === "development"
    ? NODE_DEV_SERVER_URL
    : window.location.origin;

export const UNIKSYSTEM_BACKEND = { context: "uniksystem" };
export const BPM_MIDDLEWARE = { context: "bpm" };
export const FIN_MIDDLEWARE = { context: "financeira" };
export const PORTALRH_MIDDLEWARE = { context: "portalrh" };
export const DASHBOARD_APP = { context: "dashboard" };
export const RH_MIDDLEWARE = { context: "rh" };
export const GDPR_MIDDLEWARE = { context: "gdpr" };
export const COMPLIANCE_MIDDLEWARE = { context: "compliance" };
export const GAI_MIDDLEWARE = { context: "gai" };
export const GLOBAL_SOLVER_MIDDLEWARE = {
  context: "globalsolver",
  iframe: "https://globalsolver.eu/solve/2020/unik.php",
};
export const DATA_CAPTURE_APP = "datacapture";
export const AUTOMATED_DATACAPTURE_APP = "adatacapture";
export const VALIDPROCS_APP = "validprocs";
export const GAI_APP = "gai";
export const IFLOW_APP = { context: "iFlow" };
export const SYS_ADMIN = "system_administrator";
export const BUDGETMGR_APP = "budgetmgr";
export const CLAIMSMGT_APP = "claimsmgt";

//JWT
export const TOKEN_KEY = "jwtToken";
export const USER_INFO = "userInfo";

export const TOKEN_KEY_GLOBAL_SOLVER = "globalSolverToken";
export const TOKEN_KEY_MICROSOFT = "microsoftToken";

export const TOKEN_KEY_DNB = "dnbToken";

//OAuth2
export const USERNAME = "uniksystem";
export const PASSWORD = "secret";

export const GOOGLE_CLIENT_ID =
  "787163352722-3v5s4au3nn21vt6svobne4fqntdog3aq.apps.googleusercontent.com";

export const SCOPE = "read write";
export const GRANT_TYPE_PASSWORD = "password";
export const GRANT_TYPE_REFRESH_TOKEN = "refresh_token";
export const INVALID_TOKEN = "invalid_token";

export const AUTHORIZATION = {
  username: USERNAME,
  password: PASSWORD,
};

export const AUTH_HEADER = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const JSON_HEADER = {
  "Content-Type": "application/json",
};

export const FORM_DATA_HEADER = {
  "Content-Type": "multipart/form-data",
};
