import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import {
  getRequestsByUsernameWithType,
  setGenericAuthorizationState,
  setapproveDeleteRemoveDayRemoteWork,
  setapproveDeleteRemoveMarcacaoFerias,
} from "~/store/ducks/portalrh/actionTypes";

import { Tabs, Tab } from "react-bootstrap";

import {
  AuthorizerApproves,
  RequestState,
} from "~/components/widgets/Requests/TabsEnum.js";
const devLogConsole = require("~/utils/devLog");
const MyTeamRequestTable = ({
  t,
  handleOpenFlowModal,
  searchFilter,
  handleOpenModalRemoteWork,
  handleOpenModalMarcacaoFerias,
  makeUpdate,
  setmakeUpdate,
  setmessageToast,
  setTypeRequest,
  callGetRequestsByUsername,
  handleOpenModalAuthorizationsRequests,
}) => {
  const {
    genericAuthorizationState,
    requests,
    requestsWithType,
    isLoadingRequests,
    infoApproveDeleteRemoveDayRemoteWork,
    infoApproveDeleteRemoveMarcacaoFerias,
  } = useSelector((state) => state.portalrhReducer);

  const { user } = useSelector((state) => state.globalReducer);
  const [requestsValid, setRequestsValid] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);

  const dispatch = useDispatch();
  const usernamePortalRh = user.legacyUsers.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;

  useEffect(() => {
    const updateRequests = (info) => {
      const updatedRequestsValid = { ...requestsValid };

      Object.keys(updatedRequestsValid).forEach((key) => {
        updatedRequestsValid[key] = updatedRequestsValid[key].map((request) => {
          if (request.id === info[0].id) {
            return info[0];
          }
          return request;
        });
      });

      setmessageToast(
        t(
          `portalrh.requestTable.${
            info[0].state === "Recusado" ? "reprovedMessage" : "approvedMessage"
          }`
        )
      );
      setTypeRequest(info[0].state === "Recusado" ? "danger" : "success");
      setRequestsValid(updatedRequestsValid);
      callGetRequestsByUsername(searchFilter, 0);
      dispatch(setapproveDeleteRemoveDayRemoteWork({}));
      dispatch(setapproveDeleteRemoveMarcacaoFerias({}));
    };

    if (Object.keys(infoApproveDeleteRemoveDayRemoteWork).length > 0) {
      updateRequests(infoApproveDeleteRemoveDayRemoteWork);
    } else if (Object.keys(infoApproveDeleteRemoveMarcacaoFerias).length > 0) {
      updateRequests(infoApproveDeleteRemoveMarcacaoFerias);
    } else {
      setRequestsValid(requestsWithType);
    }
  }, [
    infoApproveDeleteRemoveMarcacaoFerias,
    infoApproveDeleteRemoveDayRemoteWork,
    requestsWithType,
  ]);

  useEffect(() => {
    setRequestsValid(requestsWithType);
  }, [requestsWithType]);

  const checkValidateProc = () => {
    switch (searchFilter) {
      case "absenceRecord":
        return false;
      case "extraTime":
        return false;
      case "insertPresence":
        return false;
      case "pagamentosBolsa":
        return false;
      case "remoteWork":
        return false;
      case "marcacaoFerias":
        return false;
      case "authorizations":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    callGetRequestsByUsername(searchFilter, 0, makeUpdate);
  }, [makeUpdate]);

  useInterval(() => {
    callGetRequestsByUsername(searchFilter, 0);
  }, 1000 * 60);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: t("portalrh.requestTable.requestId"),
      sort: true,
    },
    {
      dataField: "username",
      text: t("portalrh.requestTable.user"),
      sort: true,
    },
    {
      dataField: "employee_num",
      text: t("portalrh.requestTable.employeeNum"),
      sort: true,
    },
    {
      dataField: "auth_designation",
      text: t("portalrh.requestTable.type"),
      sort: true,
    },
    {
      dataField: "creation_date",
      text: t("portalrh.requestTable.date"),
      sort: true,
    },
    {
      dataField: "change_type",
      text: t("portalrh.requestTable.type"),
      sort: true,
      hidden: true,
      // formatter: (cellContent, row) => {
      //   return t(`portalrh.workDays.${row.type}`);
      // },
    },
    {
      dataField: "state",
      text: t("portalrh.requestTable.state"),
      sort: true,
      formatter: (cellContent, row) => {
        return t(`portalrh.workDays.${row.state}`);
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();

      if (searchFilter === "remoteWork") {
        handleOpenModalRemoteWork(row);
      } else if (searchFilter === "marcacaoFerias") {
        handleOpenModalMarcacaoFerias(row);
      } else if (searchFilter === "authorizations") {
        handleOpenModalAuthorizationsRequests(row);
      } else {
        handleOpenFlowModal(row);
      }
    },
  };

  useEffect(() => {
    let filteredData = [];
    const stateToKeyMap = {
      [AuthorizerApproves.YES]: "Por Aprovar",
      [AuthorizerApproves.NO]: "Tomar Conhecimento",
      [RequestState.APPROVED]: "Aprovados",
      [RequestState.TRATADOS]: "Pendentes",
      [RequestState.PENDING]: "Pendentes",
      [RequestState.REJECTED]: "Recusados",
    };
    const keys = stateToKeyMap[genericAuthorizationState];
    if (keys) {
      filteredData = requestsValid[keys];
    }
    setFilteredRequests(filteredData);
  }, [searchFilter, genericAuthorizationState, requestsValid]);

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">
        <ToolkitProvider
          keyField="id"
          data={
            filteredRequests && filteredRequests.length > 0
              ? filteredRequests.sort((a, b) => b.id - a.id)
              : []
          }
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  display: "flex",
                }}
              >
                <SearchBar {...props.searchProps} placeholder={t("Search")} />
              </div>

              <Tabs
                id="authorizationTabs"
                defaultActiveKey="first"
                className="custom-tabs"
                activeKey={genericAuthorizationState}
                onSelect={(k) => {
                  dispatch(setGenericAuthorizationState(k));
                  setFilteredRequests([]);
                }}
              >
                <Tab
                  eventKey={AuthorizerApproves.YES}
                  title={t("portalrh.requests.authorizationsToApprove")}
                ></Tab>
                <Tab
                  eventKey={AuthorizerApproves.NO}
                  title={t("portalrh.requests.otherAuthorizations")}
                ></Tab>
                <Tab
                  eventKey={RequestState.PENDING}
                  title={t("portalrh.requests.tratadosRequestsRemoteWork")}
                ></Tab>
                <Tab
                  eventKey={RequestState.APPROVED}
                  title={t("portalrh.requests.approvedRequests")}
                ></Tab>
                <Tab
                  eventKey={RequestState.REJECTED}
                  title={t("portalrh.requests.refusedRequests")}
                ></Tab>
              </Tabs>

              <Spinner spinning={isLoadingRequests} wrapper />
              {!isLoadingRequests && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("No team requests available")}
                  rowEvents={rowEvents}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(MyTeamRequestTable);
