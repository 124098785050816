import React from "react";
import { GiPlainCircle } from "react-icons/gi";
import { Bs0CircleFill } from "react-icons/bs";
import { FaRegCircle } from "react-icons/fa6";
import { Button as ButtonMaterial } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { FaClock, FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const Legend = ({ isEditable, haveMarcacao, haveMarcacaoCancelar, t }) => {
  const buttons = [
    {
      condition: isEditable,
      color: "#188804",
      label: "portalrh.workDays.feriasTranslated.aprovadas",
    },
    {
      condition: isEditable,
      color: "#E6CD32",
      label: "portalrh.workDays.feriasTranslated.pendentes",
    },
    {
      condition: haveMarcacao,
      color: "rgba(255, 165, 0, 0.5)",
      label: "portalrh.workDays.feriasTranslated.paraAprovacao",
    },
    {
      condition: isEditable,
      color: "#E6CD32",
      isPending: true,
      label: "portalrh.workDays.feriasTranslated.selecao",
    },
    {
      condition: haveMarcacaoCancelar || isEditable,
      color: "#d35555",
      label: "portalrh.workDays.feriasTranslated.pedidoCancelamento",
    },
    {
      condition: isEditable,
      color: "#C9FFCC",
      label: "portalrh.workDays.feriasTranslated.gozadas",
    },
    {
      condition: !isEditable,
      approvedDay: true,
      color: "green",
      label: "portalrh.workDays.feriasTranslated.approved",
    },
    {
      condition: !isEditable,
      pendingDay: true,
      color: "goldenrod",
      label: "portalrh.workDays.feriasTranslated.pending",
    },
    {
      condition: !isEditable,
      rejectedDay: true,
      color: "red",
      label: "portalrh.workDays.feriasTranslated.rejected",
    },
    {
      condition: !isEditable,
      isOnlyMyTeam: true,
      color: "#007bff",
      label: "portalrh.workDays.feriasTranslated.equipa",
    },
    {
      condition: true,
      isDayCurrent: true,
      label: "portalrh.workDays.feriasTranslated.diaCorrente",
    },
    {
      condition: true,
      color: "#E2E2E2",
      label: "portalrh.workDays.feriasTranslated.folgasFeriados",
    },
    {
      condition: !isEditable,
      color: "#ed6c02",
      label: "portalrh.workDays.feriasTranslated.pessoas",
    },
  ];

  return (
    <div
      id="legenda"
      style={{
        padding: "0px 10px",
        textAlignLast: "end",
        marginTop: "1rem",
      }}
    >
      {buttons
        .filter(({ condition }) => condition)
        .map(
          (
            {
              color,
              label,
              isDayCurrent,
              isPending,
              rejectedDay,
              isOnlyMyTeam,
              approvedDay,
              pendingDay,
            },
            index
          ) => (
            <ButtonMaterial
              key={index}
              size="small"
              variant="text"
              style={{
                pointerEvents: "none",
                padding: "0px 0px 0px 10px",
                color: "#4C4B4B",
              }}
            >
              {isDayCurrent ? (
                <Bs0CircleFill style={{ margin: "0px 5px" }} />
              ) : isPending ? (
                <FaRegCircle style={{ margin: "0px 5px", color }} />
              ) : isOnlyMyTeam ? (
                <FaRegCircle style={{ margin: "0px 5px", color }} />
              ) : rejectedDay ? (
                <FaTimesCircle style={{ margin: "0px 5px", color }} />
              ) : approvedDay ? (
                <FaCheckCircle style={{ margin: "0px 5px", color }} />
              ) : pendingDay ? (
                <FaClock style={{ margin: "0px 5px", color }} />
              ) : (
                <GiPlainCircle
                  style={{
                    margin: "0px 5px",
                    color,
                  }}
                />
              )}

              {t(label)}
            </ButtonMaterial>
          )
        )}
    </div>
  );
};

export default withNamespaces()(Legend);
