import React, { useState, useEffect } from "react";
import SearchableDropDown from "~/components/SearchableDropDown";

const CreateTableSelect = (props) => {
  const { field, id, linha, onBlur, fontSize } = props;

  const [list, setList] = useState([]);
  const [required, setRequired] = useState(false);

  useEffect(() => {
    if (field) {
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
      if (field.option) {
        if (!Array.isArray(field.option)) {
          field.option = [field.option];
        }
        const optionList = field.option.map((option) => ({
          label: option.text,
          value: option.value,
          selected: option.selected === "yes" ? true : false,
        }));

        if (optionList) {
          if (
            !optionList.some((op) => op.selected === true) &&
            optionList.length > 0
          ) {
            //set first element as default
            optionList[0].selected = true;
          }
          setList(optionList);
        }
      }
    }
  }, [field]);

  const handleSelectChange = () => {
    onBlur(linha, 66);
  };

  return (
    <td id={id} style={{ padding: "0 10px 0 2px" }}>
      <SearchableDropDown
        name={field?.variable}
        list={list}
        required={required}
        handleSubmit={(value) => handleSelectChange(value)}
        exitOnClose={true}
        nomargin={true}
        fontSize={fontSize}
      />
    </td>
  );
};
export default CreateTableSelect;
