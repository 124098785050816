import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';

import { models } from "powerbi-client";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from '~/utils/msalOAuth';
import { tokenRequest } from '~/utils/msalOAuth/AuthConfig';

import { withNamespaces } from 'react-i18next';
import '~/assets/css/icons.css';
import "./powerbi.css";
import oauth from '~/utils/oauth';
import enlarge from '~/assets/img/icons/enlarge2.svg';
import shrink from '~/assets/img/icons/shrink2.svg';

import { service, factories } from "powerbi-client";
const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
const devLogConsole = require("~/utils/devLog");
const PowerBIComponent = (props) => {

  const {
    widgetId,
    widget,
    setErrorMessage
  } = props;

  const username = oauth.get("MSAL_USERNAME");

  const [showModal, setShowModal] = useState(false)
  const closeModal = () => {
    setShowModal(false)
  }
  const openModal = () => {
    setShowModal(true)
  }

  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Report container
  const loading = () => {
    return (
      <div
        id={`reportContainer${widgetId}`}
        className={showModal ? "reportContainer-fullscreen" : "reportContainer"}
      >
        {!username && "Faça login na sua conta do Microsoft"}
      </div>
    )
  }

  useEffect(() => {
    const getEmbedUrl = (accessToken, workspaceId_, reportId_) => {
      return fetch("https://api.powerbi.com/v1.0/myorg/groups/" + workspaceId_ + "/reports/" + reportId_, {
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        method: "GET"
      })
        .then(function (response) {
          const errorMessage = [];
          errorMessage.push("Error occurred while fetching the embed URL of the report.")
          return response.json()
            .then(function (body) {
              if (body.embedUrl) {// Successful response
                return body.embedUrl;
              }
              else {
                if (response.status === 401)
                  errorMessage.push("\n You don't have permission to access the report.");
                else
                  errorMessage.push("\n Error " + response.status + ": " + body.error.code);
                setErrorMessage(errorMessage)
              }
            })
            .catch(function () {
              if (response.status === 401)
                errorMessage.push("401");
              else
                errorMessage.push("\n Error " + response.status);
              setErrorMessage(errorMessage)
            });
        })
        .catch(function (error) {
          setErrorMessage(error)
        })
    };

    const getTokenPopup = (request) => {
      request.account = msalInstance.getAccountByUsername(username);
      return msalInstance.acquireTokenSilent(request)
        .then(resp => {
          return resp
        })
        .catch(error => {
          console.warn("silent token acquisition fails. acquiring token using redirect");
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenPopup(request).then(tokenResponse => {
              return tokenResponse;
            }).catch(error => {
              console.error(error);
            });
          } else {
            console.warn(error);
          }
        });
    };

    const embedReport = (embedUrl, accessToken, reportId_) => {
      if (accessToken !== undefined && embedUrl !== "" && embedUrl !== undefined) {
        const embedConfiguration = {
          type: "report",
          tokenType: models.TokenType.Aad,
          accessToken,
          embedUrl,
          id: reportId_
        };
        const reportContainer = document.getElementById(`reportContainer${widgetId}`)
        if (reportContainer) {
          const report = powerbi.embed(reportContainer, embedConfiguration);
          // Clear any other loaded handler events
          report.off("loaded");
          // Triggers when a content schema is successfully loaded
          report.on("loaded", function () {
            //devLogConsole("Report load successful");
          });
          // Clear any other rendered handler events
          report.off("rendered");
          // Triggers when a content is successfully embedded in UI
          report.on("rendered", function () {
            //devLogConsole("Report render successful");
          });
          // Clear any other error handler event
          report.off("error");
          // Below patch of code is for handling errors that occur during embedding
          report.on("error", function (event) {
            const errorMsg = event.detail;
            // Use errorMsg variable to log error in any destination of choice
            console.error(errorMsg);
          });
        }
      }
    };

    if (widget && isMounted && username) {
      if (widget.userConfiguration && widget.userConfiguration.configurationId) {
        const configId = widget.userConfiguration.configurationId;
        const source = widget.widget.configurations.find(item => item.id === configId);
        if (source.url) {
          const workspaceId_ = source.url.split(',')[0]
          const reportId_ = source.url.split(',')[1]

          if (reportId_ !== undefined && workspaceId_ !== undefined && username !== undefined) {
            getTokenPopup(tokenRequest).then(response => {
              getEmbedUrl(response.accessToken, workspaceId_, reportId_).then(embedUrl => {

                embedReport(embedUrl, response.accessToken, reportId_)
              })
            }).catch(error => {
              console.error(error);
            });
          }
        }
      }
    }

  }, [username, showModal, widget, isMounted])


  return (!showModal ?
    <div>
      {loading()}
      <img src={enlarge} style={{ height: "15px", width: "15px", float: "right" }} onClick={() => openModal()} />
    </div >
    :
    <Modal
      dialogClassName="modal-powerbi-100w"
      show={showModal}
      onHide={closeModal}
      backdrop='static'
    >
      <Modal.Body style={{ padding: "0px" }}>
        {loading()}
        <img
          src={shrink}
          style={{ height: "15px", width: "15px", float: "right", marginRight: "15px", marginBottom: "15px" }}
          onClick={() => closeModal()}
        />
      </Modal.Body >
    </Modal >
  )
}

export default withNamespaces()(PowerBIComponent);