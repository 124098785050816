import React from 'react';
import { Form } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateConfiguration
} from '../actions';


const ProductionMode = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const { configuration } = useSelector(state => state.adminConfigReducer);

  const toggleProdMode = () => {
    if (configuration) {
      const newConfiguration = {
        ...configuration,
        productionMode: !configuration.productionMode
      }
      dispatch(updateConfiguration(newConfiguration))
    };
  };

  const getProdModeChecked = () => {
    if (configuration)
      return configuration.productionMode;
    else
      return false;
  };

  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      label={t('SystemSettings.productionMode')}
      checked={getProdModeChecked()}
      onChange={() => toggleProdMode()}
    />
  )
};

export default withNamespaces()(ProductionMode)