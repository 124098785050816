import React from "react";
import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Button, Stack } from "@mui/material";
const devLogConsole = require("~/utils/devLog");
const PaperYear = ({
  events,
  dateToCompare,
  setAllEvents,
  singleDaySelection,
  handleClosePopOver,
}) => {
  const selectedEvent = events.find(
    (event) =>
      moment(event.start).format("YYYY-MM-DD") ===
      moment(dateToCompare).format("YYYY-MM-DD")
  );

  const handleRemoveInterval = () => {
    const eventsToKeep = events.filter(
      (event) => event.type !== "intervals" && event.type !== "feriasmarked"
    );

    const eventsToReplace = events.filter(
      (event) => event.type === "intervals" || event.type === "feriasmarked"
    );

    const updatedEvents = eventsToReplace
      .map((event) => {
        if (event.type === "feriasmarked") {
          if (
            event.intervals &&
            event.intervals.includes(moment(dateToCompare).format("YYYY-MM-DD"))
          ) {
            return event;
          }
          return null;
        }
        return null;
      })
      .filter(Boolean);

    if (updatedEvents.length > 0) {
      const filteredEventsToReplace = eventsToReplace.filter(
        (event) => event !== updatedEvents[0]
      );

      if (updatedEvents[0].intervals) {
        const intervalsToRemove = updatedEvents[0].intervals.map((interval) =>
          moment(interval).format("YYYY-MM-DD")
        );
        const filteredIntervals = filteredEventsToReplace.filter((event) => {
          if (event.type === "intervals") {
            return !intervalsToRemove.includes(
              moment(event.start).format("YYYY-MM-DD")
            );
          }
          return true;
        });

        setAllEvents([...eventsToKeep, ...filteredIntervals]);
      } else {
        setAllEvents([...eventsToKeep, ...filteredEventsToReplace]);
      }
    } else {
      setAllEvents([...eventsToKeep]);
    }

    handleClosePopOver();
  };

  const handleRemoveDay = () => {
    const eventsToKeep = events.filter(
      (event) => event.type !== "intervals" && event.type !== "feriasmarked"
    );

    const eventsToReplace = events.filter(
      (event) => event.type === "intervals" || event.type === "feriasmarked"
    );
    const updatedEvents = eventsToReplace
      .map((event) => {
        devLogConsole(event);
        if (event.type === "feriasmarked") {
          if (
            moment(event.start).format("YYYY-MM-DD") ===
            moment(dateToCompare).format("YYYY-MM-DD")
          ) {
            return null;
          }
          return {
            ...event,
            intervals: event.intervals
              ? event.intervals.filter(
                  (interval) =>
                    interval !== moment(dateToCompare).format("YYYY-MM-DD")
                )
              : [],
          };
        } else if (event.type === "intervals") {
          return moment(event.start).format("YYYY-MM-DD") !==
            moment(dateToCompare).format("YYYY-MM-DD")
            ? event
            : null;
        }
        return event;
      })
      .filter(Boolean);
    const hasFeriasmarked = updatedEvents.some(
      (event) => event.type === "feriasmarked"
    );
    if (!hasFeriasmarked) {
      const updatedEventsWithFeriasmarked = updatedEvents.map((event) => {
        if (event.type === "intervals") {
          return {
            ...event,
            type: "feriasmarked",
            multi: false,
          };
        }
        return event;
      });
      setAllEvents([...eventsToKeep, ...updatedEventsWithFeriasmarked]);
    } else {
      setAllEvents([...eventsToKeep, ...updatedEvents]);
    }

    handleClosePopOver();
  };

  return (
    <>
      {dateToCompare !== undefined && (
        <>
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "100%",
              justifyContent: "space-between",
              padding: "0rem 0rem 1rem 0rem",
            }}
          >
            <h4 style={{ color: "#4C4B4B" }}>
              {selectedEvent ? "Dia Selecionado" : "Nenhum Dia Selecionado"}
            </h4>
            <button type="button" className="close" onClick={handleClosePopOver}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Stack>
          {selectedEvent ? (
            <>
              <p>
                O dia {moment(dateToCompare).format("DD MMMM YYYY")} já tem
                uma marcação.
              </p>
              <Stack direction="row-reverse" alignItems="center" spacing={2}>
                {!singleDaySelection && (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      className="card-button-scheduler"
                      onClick={handleRemoveInterval}
                    >
                      Remover intervalo de dias
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={handleRemoveDay}
                >
                  Remover dia
                </Button>
              </Stack>
            </>
          ) : (
            <p>
              Nenhum evento selecionado para o dia{" "}
              {moment(dateToCompare).format("DD MMMM YYYY")}.
            </p>
          )}{" "}
        </>
      )}
    </>
  );
};

export default PaperYear;
