import qs from 'qs'
import { put } from 'redux-saga/effects';
import http from '~/utils/config/http';

import {
  setLegacyPermissions
} from './actionTypes';

import { activeError } from '~/components/Messages/actions';

export function* findLegacyPermissions({ username, context }) {
  try {
    yield put(setLegacyPermissions([]));
    const resp = yield http.get(`/permission/find-by-user?username=${username}`, context);
    yield put(setLegacyPermissions(resp.data));
  } catch (e) {
    yield put(activeError());
  }
}