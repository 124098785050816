import React from "react";

const RhAjudasCusto = ()=>{
return (
    <>
    ajudas custo
    </>
)
}

export default RhAjudasCusto;