import React from "react";
import uuid from "uuid/v1";

import "./spinner.css"

const Loading = ({ children, spinning, size = 'lg', wrapper = false, transparent = true }) => {

  const loaderWrapper = wrapper ? 'loader-wrapper' : 'loader-no-wrapper';
  const backgroundClass = transparent ? 'transparent' : 'opaque'
  const barWrapper = wrapper && transparent ? 'bar-wrapper' : 'bar-no-wrapper';
  const bars = Array.from(Array(5), (_, i) => i + 1);

  return (
    <>
      {
        spinning &&
        <div className={`${loaderWrapper} ${backgroundClass}`}>
          <div className="loader">
            {
              bars.map(bar =>
                <div
                  key={uuid()}
                  className={`${barWrapper} bar ${size} bar-${bar}`}
                />
              )
            }
          </div>
        </div>
      }
      {
        !spinning && <>{children}</>
      }
    </>
  )
}

export default Loading;
