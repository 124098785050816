import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { toLocaleString } from "~/utils";

export const CommentHistory = (props) => {
  const { t, commentHistory } = props;

  commentHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

  const renderRows = () => {
    return (
      commentHistory &&
      commentHistory.map((comment, index) => {
        return (
          index !== 0 && (
            <div className="annotation-preview-row" key={index}>
              <span className="annotation-preview-header">
                <div>
                  {toLocaleString(comment.date)}
                  <br />({comment.userid})
                </div>
              </span>
              <span className="annotation-preview-content">
                {comment.comment ? comment.comment : "-"}
              </span>
            </div>
          )
        );
      })
    );
  };
  return (
    <div style={{ maxHeight: "150px", overflow: "auto" }}>{renderRows()}</div>
  );
};
export default withNamespaces()(CommentHistory);
