import JSZip from "jszip";
import { saveAs } from "file-saver";

/**
 *  exportCSV function
 *
 *  IMPORTANT: WHEN YOU DON'T WANT TO USE A PROP PASS IT AS NULL
 *  OR SIMILAR
 *
 *  props:
 *  - columns (columns from  react boostrap table)
 *  - data (data used in reactbootstrap table, needs to be an object)
 *  - zipName (name of the ZIP folder to export)
 *  - fileName (name of the CSV file to export)
 *  - separator (data separator)
 *  - zip (set to true if you want to download csv inside a zip folder
 *  and data[n].data converted to pdfs and saved inside the zip folder
 *  too)
 */

export const exportCSV = (
  data,
  columns,
  zipName = "files",
  fileName = "file",
  separator = ",",
  zip = false
) => {
  if (
    typeof data === "object" ||
    data instanceof Object ||
    typeof columns === "object" ||
    columns instanceof Object
  ) {
    if (Object.keys(data).length > 0 || Object.keys(columns).length > 0) {
      const handleDataToExport = () => {
        /**
         *  Acess to nested information in object using a variable
         *  ex of usage : Object.byString(object, field1.field2)
         */
        Object.byString = function (o, s) {
          s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
          s = s.replace(/^\./, ""); // strip a leading dot
          let a = s.split(".");
          for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in o) {
              o = o[k];
            } else {
              return;
            }
          }
          return o;
        };

        // Gets all the rows that are going to be exported
        const rowsToExport = columns.filter(
          (row) => row.csvExport === undefined || row.csvExport === true
        );

        //Gets all the data inside the rows to be exported
        const dataToExport = data.map((doc) => {
          return rowsToExport.map((row) => {
            return [Object.byString(doc, row.dataField)];
          });
        });

        //Gets the first row of the table being exported
        const tableFields = rowsToExport.map((row) => row.text);
        dataToExport.unshift(tableFields);

        return dataToExport;
      };

      const downloadDocuments = (filename, rows, zip) => {
        const processRow = (row) => {
          let finalVal = "";
          for (let j = 0; j < row.length; j++) {
            let innerValue = row[j] === null ? "" : row[j].toString();
            if (row[j] instanceof Date) {
              innerValue = row[j].toLocaleString();
            }
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            if (j > 0) finalVal += separator;
            finalVal += result;
          }
          return finalVal + "\n";
        };

        let csvFile = "";
        for (let i = 0; i < rows.length; i++) {
          csvFile += processRow(rows[i]);
        }

        if (zip) {
          const documentData = data.map((doc) => doc.data);
          let zip = new JSZip();
          documentData.forEach((doc, i) => {
            zip.file(`${data[i].id}.${data[i].filename.split(".").pop()}`, doc, { base64: true });
          });
          zip.file(`${filename}.csv`, "\uFEFF" + csvFile);

          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `${zipName}.zip`);
          });
        } else {
          let blob = new Blob(["\uFEFF" + csvFile], {
            type: "text/csv;charset=ansi",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename + ".csv");
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename + ".csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }
      };
      downloadDocuments(fileName, handleDataToExport(), zip);
    }
  } else {
    return;
  }
};
