import React, { useEffect, useState } from "react";
import Title from "../components/Title";
import Input from "../components/Input";
import SingleTable from "../components/SingleTable";
import LiftworldTable from "../components/LiftworldTable";
import uuid from "uuid/v1";

const AllComponents = () => {
  const field = {
    type: "textbox",
    text: "TITULO",
    variable: "",
    maxlength: "500",
    obligatory: "false",
    disabled: "false",
  };

  const list1 = ["NOME EMPRESA"];
  const list2 = ["N CLIENTE FATURAÇÃO", "NOME CLIENTE FATURACAO"];
  const list3 = ["N CLIENTE PROJETO", "CLIENT OWNER", "PARTNER DO CLIENTE"];
  const list4 = ["N CLIENTE PROJETO", "CLIENT OWNER", "PARTNER DO CLIENTE","DATA INICIO DO PROJETO"];
  const tableList = ["COLABORADOR", "CATEGORIA", "DEPARTAMENTO","TAREFA","HORAS PROPOSTAS","VALOR ADICIONAL PROPOSTO","AJUSTO HORAS PROPOSTAS","VALOR DE VENDA PROPOSTA","MARGEM"];
  const horasList = [
    {
        "colaborador": "colab1",
        "categoria": "cat1",
        "departamentoHoras": "deph1",
        "tarefa": "t1",
        "horasPropostas": 12.03,
        "valorAdicional": 15.04,
        "custosHoras": 1.2,
        "valorVendaHoras": 3.2,
        "margemHoras": 5.1
    },
    {
        "colaborador": "colab2",
        "categoria": "cat2",
        "departamentoHoras": "deph2",
        "tarefa": "t2",
        "horasPropostas": 12.03,
        "valorAdicional": 15.04,
        "custosHoras": 1.2,
        "valorVendaHoras": 3.2,
        "margemHoras": 5.1
    },
    {
        "colaborador": "",
        "categoria": "",
        "departamentoHoras": "",
        "tarefa": "tarefa",
        "horasPropostas": 0,
        "valorAdicional": 0.1,
        "custosHoras": 0,
        "valorVendaHoras": 0,
        "margemHoras": 0
    }
]
  return (
    <div>
      <Title text={"SMALL TITLE"} type={"small"} />
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <Title text={"NORMAL TITLE"} type={"normal"} />
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <Title text={"LARGE TITLE"} type={"large"} />
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <Input field={field} key={uuid()} />;
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <SingleTable list = {list1} size = {"single"}/>
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <SingleTable list = {list2} size = {"normal"}/>
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <SingleTable list = {list3} size = {"normal"}/>
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <SingleTable list = {list3} size = {"large"}/>
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <SingleTable list = {list4} size = {"large"}/>
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <div style={{ paddingTop: 20, paddingBottom: 20 }} />
      <LiftworldTable list = {tableList} typeList = {horasList}/>
    </div>
  );
};
export default AllComponents;
