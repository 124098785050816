import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Simulator from "../Simulator";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

// const [isExternal, setIsExternal] = useState(false);

const Index = ({ t }) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  return (
    <>
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            <Simulator isExternal={true} />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(Index);
