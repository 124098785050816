import React, { useState, useEffect } from "react";
import { Tabs, Tab, Card } from "react-bootstrap";
import { handleAccrodionKey } from "../utils";

const CreateTab = (props) => {
  const { tabDiv, tabArray, renderBlockDivision } = props;
  const [tabKey, setTabKey] = useState("");

  useEffect(() => {
    //find active tab
    let arrays = JSON.parse(localStorage.getItem("iflowAccordion")) || [];
    //find config for this tabDiv
    let found = arrays.find((a) => a.id === tabDiv.id);
    if (found && found.key) {
      setTabKey(found.key);
    } else {
      //set first tab as default
      Array.isArray(tabArray) &&
        tabArray.length > 0 &&
        setTabKey(tabArray[0]?.id);
    }
  }, [tabArray]);

  const getName = (tabName) => {
    if (tabName.includes("::")) {
      const arr = tabName.split("::");
      return arr[0];
    }
    return tabName;
  };

  return (
    <Tabs
      className="custom-tabs"
      id="formTab"
      activeKey={tabKey}
      onSelect={(k) => {
        setTabKey(k);
        handleAccrodionKey(k, tabDiv);
      }}
    >
      {tabArray.map((tab, index) => {
        return (
          <Tab key={index} eventKey={tab.id} title={getName(tab.name)}>
            <br />
            <Card.Body>{renderBlockDivision(tab.blockdivision)}</Card.Body>
          </Tab>
        );
      })}
    </Tabs>
  );
};
export default CreateTab;
