import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { reactI18nextModule } from "react-i18next";
import { BACKEND_SERVER_URL } from "~/utils/constants";

// Saved in localstorage for quicker access when loading language
const lng = localStorage.getItem("lng") ? localStorage.getItem("lng").trim() : "pt";

i18n
  .use(ChainedBackend)
  .use(reactI18nextModule)
  .init({
    lng,
    fallbackLng: "pt",
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          // expirationTime: 60 * 1000 updates local storage from min to min
          expirationTime: 60 * 1000,
        },
        {
          loadPath: `${BACKEND_SERVER_URL()}/uniksystem/api/open/locale/{{lng}}`,
        },
      ],
    },
  });

export default i18n;