import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { sendSmsOTP } from "~/store/ducks/onboarding/actionTypes";

/**
 * Sms OTP
 *
 * This service tests an API from easyPay
 *
 * It allows to the user to send an Sms through the easyPay network.
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingSendSmsOTP, sendSmsOtpResponse } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    sendSmsOtpResponse !== output &&
      setOutput(JSON.stringify(sendSmsOtpResponse, null, 2));
  }, [sendSmsOtpResponse]);

  const [destinationNumber, setDestinationNumber] = useState("");
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [output, setOutput] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testSmsOTPService();
    }
  };

  const testSmsOTPService = () => {
    const payload = {
      phone: destinationNumber,
      message,
    };
    dispatch(sendSmsOTP(payload, apiSettings.id));
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationSmsOTPMobile">
              <Form.Label>{t("onboarding.persist.destination")}</Form.Label>
              <Form.Control
                required
                type="number"
                value={destinationNumber}
                onChange={(e) => {
                  setDestinationNumber(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredDestination")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationSmsOTPMessage">
              <Form.Label>{t("onboarding.persist.message")}</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredMessage")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          className="card-button onboarding-button"
          type="submit"
        >
          {isLoadingSendSmsOTP ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
