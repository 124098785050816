import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Form, Row } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "~/i18n.js";
import { useLabelFormated } from "../utils/useLabelFormated";

const CreateTextLabel = (props) => {
  const {
    field,
    isChild,
    styleSheet,
    t,
    setIsModalShowing,
    setCurrentInfo,
    translations,
  } = props;
  const { imageFormated, textFormated, language } = useLabelFormated(field);
  const [textToLabel, settextToLabel] = useState("");
  useEffect(() => {
    if (typeof field?.text === "string") {
      if (field?.text?.includes("::i::")) {
        settextToLabel(field?.text?.split("::i::")[0]);
      } else if (field?.text?.includes("::t::") && translations !== "") {
        settextToLabel(translateText(field?.text));
      } else {
        settextToLabel(field?.text);
      }
    }
  }, [field]);

  const translateText = (textField) => {
    if (!textField.includes("::t::")) {
      return textField;
    }
    const lingua = language.trim();
    const cleanTextField = textField.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal;
  };

  return (
    <>
      <Form.Group
        controlId={`formlabel-${field.type}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          marginBottom: styleSheet?.compact ? "5px" : "10px",
          flexGrow: "1",
        }}
      >
        <Form.Label column className="iflow-form-label-fix">
          {field.text.includes(":") ? textToLabel : t(textToLabel)}
          {imageFormated && (
            <img
              style={{
                width: "20px",
                height: "20px",
                marginRight: "20px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
              src={imageFormated.props.src}
              className={imageFormated.props.className}
              alt="..."
              onClick={() => {
                setIsModalShowing(true);
                setCurrentInfo(textFormated);
              }}
            />
          )}
        </Form.Label>
        <Form.Label
          column
          //className="iflow-form-label-fix"
          style={{
            fontWeight: "bolder",
            paddingRight: "0px",
            paddingLeft: "0px",
          }}
        >
          {" "}
          {ReactHtmlParser(translateText(field.value))}
          {ReactHtmlParser(field.suffix ? field.suffix : "")}
        </Form.Label>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && !styleSheet?.ultraCompact && <hr />}
    </>
  );
};
export default withNamespaces()(CreateTextLabel);
