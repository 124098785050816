const devLogConsole = require("~/utils/devLog");
export const transformFormData = (formdata) => {
  let pairsToAppend = []
  for (let pair of formdata.entries()) {
    if (pair[0].includes("DateToTransform")) {
      pairsToAppend.push(pair)
    }
  }
  pairsToAppend.forEach(pair => {
    const formKey = pair[0].split("&variable=")[1]
    devLogConsole("THIS IS KEY" + formKey)
    const arr1 = pair[0].split("dateformattext=")[1]
    const outputFormat = arr1.split("&variable=")[0]
    const timeArray = pair[1].split("-");
    let transformedValue
    if (outputFormat === "yyyy/MM/dd") {
      transformedValue = (timeArray[0] + "/" + timeArray[1] + "/" + timeArray[2]);
    } else {//"dd/MM/yyyy"
      transformedValue = (timeArray[2] + "/" + timeArray[1] + "/" + timeArray[0]);
    }
    if (transformedValue.includes("undefined"))
      transformedValue = "";
    formdata.append(formKey, transformedValue)
    formdata.delete(pair[0])
  })

  for (var pair of formdata.entries()) {
   //devLogConsole(pair[0] + ': ' + pair[1]);
  }

  return formdata
}