import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "react-addons-update";
import { withNamespaces } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal, Button, Form, Col, Alert } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import Dropdown from "~/components/Dropdown";
import {
  updateCreateCatalogoFormacao,
  getCatalogoFormacao,
} from "store/ducks/processes/actionTypes";

const ModalCatalogo = ({
  openModalTemplate,
  handleCloseModalTemplate,
  handleSaveAndCloseModalTemplate,
  selectedTemplate,
  setSelectedTemplate,
  t,
}) => {
  const dispatch = useDispatch();
  const { catalogoFormacaoDropdowns } = useSelector(
    (state) => state.processesReducer
  );

  //  O QUE PRECISAM DE FAZER:
  //  CRIAR ESTADOS
  const [cleanDropdownFormacao, setcleanDropdownFormacao] = useState(false);
  const [alertCode, setAlertCode] = useState(false);

  //  CRIAR OS CAMPOS DE TEXTO/DROPDOWNS
  //  IR VER SE ESTA OK O PAYLOAD QUE É ENVIADO export function* updateCreateCatalogoFormacao({ payload }) {

  const handleCloseModalCleanStates = () => {
    // setSelectedTemplates([]);
    // setUnselectedTemplates([]);
    // setDescription("");
    handleCloseModalTemplate();
  };

  const handleCodeChange = (event) => {
    setAlertCode(false);
    event.persist(); // Mantém o evento sintético original

    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      afrcode: event.target.value,
    }));
  };

  const handleDescriptionChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      afrdesignacao: event.target.value,
    }));
  };

  const handleDominioChange = (dfrcode) => {
    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      dfrcode,
    }));
  };

  const handleModalidadeChange = (mfrcode) => {
    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      mfrcode,
    }));
  };

  const handleNivelCeChange = (nfrcode) => {
    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      nfrcode,
    }));
  };

  const handleSituacaoChange = (event) => {
    if (event && event.target) {
      const isChecked = event.target.checked;
      setSelectedTemplate((prevFormacao) => ({
        ...prevFormacao,
        situacao: isChecked ? "1" : "0",
      }));
    }
  };

  const handleAreaChange = (fracode) => {
    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      fracode,
    }));
  };

  const handleRegimeChange = (rgfcode) => {
    setSelectedTemplate((prevFormacao) => ({
      ...prevFormacao,
      rgfcode,
    }));
  };

  const handleSave = () => {
    const afrcode = selectedTemplate?.afrcode;
    if (afrcode && afrcode.length >= 4) {
      const payload = {
        afrcode: selectedTemplate?.afrcode ?? "0000",
        afrdesignacao: selectedTemplate?.afrdesignacao,
        dfrcode: selectedTemplate?.dfrcode,
        mfrcode: selectedTemplate?.mfrcode,
        nfrcode: selectedTemplate?.nfrcode,
        situacao: selectedTemplate?.situacao ?? "1",
        fracode: selectedTemplate?.fracode,
        autoform: selectedTemplate?.autoform ?? "0",
        rgfcode: selectedTemplate?.rgfcode,
      };
      dispatch(updateCreateCatalogoFormacao(payload));
      handleSaveAndCloseModalTemplate();
    } else {
      setAlertCode(true);
    }
  };

  // Dropdown list values

  const renderFilterDominio = () => {
    if (!catalogoFormacaoDropdowns || !catalogoFormacaoDropdowns.dominios) {
      return [];
    }

    return catalogoFormacaoDropdowns?.dominios.map((dominio) => ({
      label: dominio?.designacao,
      value: dominio?.code,
      selected:
        selectedTemplate !== undefined &&
        selectedTemplate?.dfrcode === dominio?.code,
    }));
  };

  const renderFilterModalidade = () => {
    if (!catalogoFormacaoDropdowns || !catalogoFormacaoDropdowns.modalidades) {
      return [];
    }

    return catalogoFormacaoDropdowns?.modalidades.map((modalidade) => ({
      label: modalidade?.designacao,
      value: modalidade?.code,
      selected:
        selectedTemplate !== undefined &&
        selectedTemplate?.mfrcode === modalidade?.code,
    }));
  };

  const renderFilterNivelCE = () => {
    if (!catalogoFormacaoDropdowns || !catalogoFormacaoDropdowns.niveisCE) {
      return [];
    }

    return catalogoFormacaoDropdowns?.niveisCE.map((nivel) => ({
      label: nivel?.designacao,
      value: nivel?.code,
      selected:
        selectedTemplate !== undefined &&
        selectedTemplate?.nfrcode === nivel?.code,
    }));
  };

  const renderFilterArea = () => {
    if (!catalogoFormacaoDropdowns || !catalogoFormacaoDropdowns.areas) {
      return [];
    }

    return catalogoFormacaoDropdowns?.areas.map((area) => ({
      label: area?.designacao,
      value: area?.code,
      selected:
        selectedTemplate !== undefined &&
        selectedTemplate?.fracode === area?.code,
    }));
  };

  const renderFilterRegime = () => {
    if (!catalogoFormacaoDropdowns || !catalogoFormacaoDropdowns.regimes) {
      return [];
    }

    return catalogoFormacaoDropdowns?.regimes.map((regime) => ({
      label: regime?.designacao,
      value: regime?.code,
      selected:
        selectedTemplate !== undefined &&
        selectedTemplate?.rgfcode === regime?.code,
    }));
  };

  return (
    <>
      <Modal
        show={openModalTemplate}
        onHide={handleCloseModalCleanStates}
        dialogClassName="custom-modal modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(`portalrh.rhadmin.formacoes.formacao`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div style={{ marginBottom: "3rem", flexWrap: "wrap" }}>
              <div style={{ display: "flex", gap: "2rem" }}>
                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.code`)}:
                    </Form.Label>
                    <input
                      id={`code-input`}
                      className="form-control"
                      type="number"
                      value={selectedTemplate?.afrcode}
                      onChange={handleCodeChange}
                      pattern="\d{4}"
                      title="Por favor, insira 4 dígitos."
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: alertCode ? "block" : "none" }}
                    >
                      Por favor, insira 4 dígitos.
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.designation`)}:
                    </Form.Label>
                    <input
                      id={`designation-input`}
                      className="form-control"
                      type="text"
                      value={selectedTemplate?.afrdesignacao}
                      onChange={handleDescriptionChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "2rem" }}>
                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.domain`)}:
                    </Form.Label>
                    <Dropdown
                      nomargin="true"
                      width={"100%"}
                      list={renderFilterDominio()}
                      handleSubmit={handleDominioChange}
                      emptyDefault={true}
                      setResetDropdown={setcleanDropdownFormacao}
                      resetDropdown={cleanDropdownFormacao}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </div>
                </div>

                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.modality`)}:
                    </Form.Label>
                    <Dropdown
                      nomargin="true"
                      width={"100%"}
                      list={renderFilterModalidade()}
                      handleSubmit={handleModalidadeChange}
                      emptyDefault={true}
                      setResetDropdown={setcleanDropdownFormacao}
                      resetDropdown={cleanDropdownFormacao}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "2rem" }}>
                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.levelCE`)}:
                    </Form.Label>
                    <Dropdown
                      nomargin="true"
                      width={"100%"}
                      list={renderFilterNivelCE()}
                      handleSubmit={handleNivelCeChange}
                      emptyDefault={true}
                      setResetDropdown={setcleanDropdownFormacao}
                      resetDropdown={cleanDropdownFormacao}
                    />{" "}
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </div>
                </div>

                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.area`)}:
                    </Form.Label>
                    <Dropdown
                      nomargin="true"
                      width={"100%"}
                      list={renderFilterArea()}
                      handleSubmit={handleAreaChange}
                      emptyDefault={true}
                      setResetDropdown={setcleanDropdownFormacao}
                      resetDropdown={cleanDropdownFormacao}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "2rem" }}>
                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <div style={{ width: "100%" }}>
                    <Form.Label
                      column
                      style={{ minWidth: "120px", width: "120px" }}
                    >
                      {t(`portalrh.rhadmin.formacoes.regime`)}:
                    </Form.Label>
                    <Dropdown
                      nomargin="true"
                      width={"100%"}
                      list={renderFilterRegime()}
                      handleSubmit={handleRegimeChange}
                      emptyDefault={true}
                      setResetDropdown={setcleanDropdownFormacao}
                      resetDropdown={cleanDropdownFormacao}
                    />{" "}
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </div>
                </div>
                <div
                  className="inputsLiftCrud"
                  style={{ width: "48%", margin: "0px" }}
                >
                  <Form.Label
                    column
                    style={{ minWidth: "120px", width: "120px" }}
                  >
                    {t(`portalrh.rhadmin.formacoes.ativo`)}:
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="last-page"
                    style={{ marginBottom: "10px", marginLeft: "15px" }}
                    checked={
                      selectedTemplate &&
                      (selectedTemplate.situacao === "1" ||
                        selectedTemplate.situacao === true)
                    }
                    onChange={handleSituacaoChange}
                  />
                </div>
              </div>

              <hr />
            </div>
          </>
        </Modal.Body>{" "}
        <Modal.Footer>
          <Button
            variant="danger"
            style={{ minHeight: "44px" }}
            onClick={handleCloseModalCleanStates}
          >
            {t("portalrh.rhadmin.gestaoTemplates.close")}
          </Button>
          <Button className="card-button" onClick={handleSave}>
            {selectedTemplate
              ? t("portalrh.rhadmin.gestaoTemplates.save")
              : t("portalrh.rhadmin.gestaoTemplates.create")}
          </Button>
        </Modal.Footer>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Modal>
    </>
  );
};

export default withNamespaces()(ModalCatalogo);
