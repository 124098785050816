import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { sendEmailEndPoint } from "~/store/ducks/onboarding/actionTypes";

/**
 * Email EndPoint
 *
 * This service tests an API from easyPay
 *
 * It allows to the user to send an email through easyPay network
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingSendEmailEndPoint, sendEmailEndPointResponse } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    sendEmailEndPointResponse !== output &&
      setOutput(sendEmailEndPointResponse);
  }, [sendEmailEndPointResponse]);

  const [subject, setSubject] = useState("Teste email");
  const [emails, setEmails] = useState(["@uniksystem.com"]);
  const [extra, setExtra] = useState(
    JSON.stringify({ teste: "Hey", Year: "2021" })
  );
  const [metadata, setMetadata] = useState(JSON.stringify({ user_id: 4657 }));
  const [type, setType] = useState("email");
  const [output, setOutput] = useState();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (extra) {
      JSON.parse(extra);
    }
    try {
    } catch (e) {
      toast.error("❌ " + t("onboarding.persist.validateExtra"), {
        toastId: "1",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (metadata) {
      JSON.parse(metadata);
    }
    try {
      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        testEmailEndPointService();
      }
    } catch (e) {
      toast.error("❌ " + t("onboarding.persist.validateMetadata"), {
        toastId: "1",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const testEmailEndPointService = () => {
    const objExtra = JSON.parse(extra);
    const objMetadata = JSON.parse(metadata);
    const payload = {
      data: JSON.stringify({
        type,
        emails: [emails],
        subject,
        extra: objExtra,
        metadata: objMetadata,
      }),
    };
    dispatch(sendEmailEndPoint(payload, apiSettings.id));
  };

  return (
    <>
      <ToastContainer />
      <div className="onboarding-execute-form">
        <Form
          noValidate
          validated={validated}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          encType="multipart/form-data"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h3 className="onboarding-form-header">
            {t("onboarding.general.test")}
          </h3>
          <Form.Row>
            <Col sm="12" md="8" lg="8">
              <Form.Group controlId="validationEmailEndpointType">
                <Form.Label>{t("onboarding.persist.type")}</Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("onboarding.persist.requiredType")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationEmailEndpointEmails">
                <Form.Label>{t("onboarding.persist.email")}</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={emails}
                  onChange={(e) => setEmails(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("onboarding.persist.requiredEmail")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationEmailEndpointSubject">
                <Form.Label>{t("onboarding.persist.subject")}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="validationEmailEndpointExtra">
                <Form.Label>{t("onboarding.persist.extra")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={extra}
                  onChange={(e) => setExtra(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("onboarding.persist.requiredExtra")}
                </Form.Control.Feedback>
                <small>
                  <i>{t("onboarding.persist.detailExtraConfig")}</i>
                </small>
              </Form.Group>
              <Form.Group controlId="validationEmailEndpointMetadata">
                <Form.Label>{t("onboarding.persist.metadata")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={metadata}
                  onChange={(e) => setMetadata(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("onboarding.persist.requiredMetada")}
                </Form.Control.Feedback>
                <small>
                  <i>{t("onboarding.persist.detailMetadataConfig")}</i>
                </small>
              </Form.Group>
            </Col>
          </Form.Row>
          <Button
            disabled={
              !user?.organization?.id || Object.keys(apiSettings).length === 0
            }
            type="submit"
            className="card-button onboarding-button"
          >
            {isLoadingSendEmailEndPoint ? (
              <>
                {t("onboarding.general.loading")}
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              t("onboarding.general.test")
            )}
          </Button>
        </Form>
        <Output output={output} />
      </div>
    </>
  );
};
export default withNamespaces()(Test);
