import {
    GET_PERMISSIONS,
    SET_PERMISSIONS,
    PERSIST_PERMISSION,
    PERSIST_PERMISSION_DONE,
    GET_PERMISSION_BY_ID,
    SET_PERMISSION,
    CLEAR_PERMISSIONS,
    CLEAR_PERMISSION,
    DELETE_PERMISSION,
    DELETE_PERMISSION_DONE
} from "../actions";

const initialState = {
    permissions: [],
    isLoadingPermissions: false,
    permission: {},
    isLoadingPermission: false,
    isPersisting: false,
    isDeleting: false,
}

export const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERMISSIONS:
            return { ...state, isLoadingPermissions: true };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions,
                isLoadingPermissions: false
            };
        case PERSIST_PERMISSION:
            return { ...state, isPersisting: true };
        case PERSIST_PERMISSION_DONE:
            return { ...state, isPersisting: false };
        case GET_PERMISSION_BY_ID:
            return { ...state, isLoadingPermission: true }
        case SET_PERMISSION:
            return {
                ...state,
                permission: action.permission,
                isLoadingPermission: false
            };
        case CLEAR_PERMISSIONS:
            return { ...state, permissions: initialState.permissions };
        case CLEAR_PERMISSION:
            return { ...state, permission: initialState.permission };
        case DELETE_PERMISSION:
            return { ...state, isDeleting: true }
        case DELETE_PERMISSION_DONE:
            return { ...state, isDeleting: false }
        default:
            return state;
    }
}
