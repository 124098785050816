import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { activeError, activeSuccess } from "~/components/Messages/actions";
import { RH_MIDDLEWARE } from "~/utils/constants";
import {
  setPersonalData,
  setAddress,
  setPersonalDataDropdown,
  setAcademicQualifications,
  setAcademicDropdown,
  setTaxData,
  setTaxDataDropdown,
  setHouseHoldDropdown,
  setHouseHold,
  setPaymentsData,
  setTraining,
  setTrainingDropdown,
  setPaymentsDropdown,
  setPerEvForm,
  setPerEvFormDropdown,
  setMedicalRecord,
  setHouseHoldAgfCodeLast,
  doneUpdateAddress,
  doneUpdatePersonalData,
  doneUpdateAcademicQualifications,
  doneUpdateTaxData,
  doneUpdateHousehold,
  doneUpdatePaymentsData,
  doneUpdateTraining,
  doneUpdatePerEvForm,
  doneUpdateMedicalRecord,
  doneCreatePersonalData,
  doneCreateAddress,
  doneCreateAcademicQualifications,
  doneCreateTaxData,
  doneCreateHousehold,
  doneCreatePaymentsData,
  doneCreateTraining,
  doneCreatePerEvForm,
  doneCreateMedicalRecord,
  setPersonalDataError,
  setCollaboratorData,
  doneUpdateNotMainAddress,
  doneUpdateNotMainAcademicQualifications,
} from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
//Collaborators Data
export function* getCollaboratorData() {
  try {
    const resp = yield http.get(`/api/allCollaboratorsData`, RH_MIDDLEWARE);

    yield put(setCollaboratorData(resp.data));
  } catch (error) {
    // yield put(setPersonalDataError(error));
    devLogConsole(error);
  }
}

//Personal Data
export function* getPersonalData({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/personalData/${id}/${companyId} `,
      RH_MIDDLEWARE
    );

    yield put(setPersonalData(resp.data));
  } catch (error) {
    // yield put(setPersonalDataError(error));
    devLogConsole(error);
  }
}

export function* getPersonalDataDropdown() {
  try {
    const resp = yield http.get("/api/personal-data-dropdown", RH_MIDDLEWARE);
    yield put(setPersonalDataDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updatePersonalData({ data }) {
  try {
    yield http.put(`/api/personalData`, data, RH_MIDDLEWARE);
    yield put(doneUpdatePersonalData());
    yield put(activeSuccess("Personal data updated successfully!"));
    yield put(setPersonalDataError(false));
  } catch (error) {
    const { response } = error;
    yield put(setPersonalDataError(true));
    yield put(activeError(`Error updating personal data! ${response.data}`));
    //devLogConsole(error);
  }
}

export function* createPersonalData({ data }) {
  try {
    yield http.post(`/api/personalData`, data, RH_MIDDLEWARE);
    yield put(doneCreatePersonalData());
    yield put(activeSuccess("Personal data created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating personal data!"));
    devLogConsole(error);
  }
}

//Adress
export function* getAddress({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/address/${id}/${companyId} `,
      RH_MIDDLEWARE
    );

    if (Array.isArray(resp.data)) yield put(setAddress(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updateAddress({ data }) {
  try {
    yield http.put(`/api/address`, data, RH_MIDDLEWARE);
    yield put(doneUpdateAddress());
    yield put(activeSuccess("Address updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating address!"));
    devLogConsole(error);
  }
}

export function* updateNotMainAddress({ data }) {
  try {
    yield http.put(`/api/address/notMain`, data, RH_MIDDLEWARE);
    yield put(doneUpdateNotMainAddress());
  } catch (error) {
    devLogConsole(error);
  }
}

export function* createAddress({ data }) {
  try {
    yield http.post(`/api/address`, data, RH_MIDDLEWARE);
    yield put(doneCreateAddress());
    yield put(activeSuccess("Address created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating address!"));
    devLogConsole(error);
  }
}

//Academic Qualifications
export function* getAcademicQualifications({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/academic/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    if (Array.isArray(resp.data))
      yield put(setAcademicQualifications(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getAcademicDropdown() {
  try {
    const resp = yield http.get(
      "/api/academic-qualifications-dropdown",
      RH_MIDDLEWARE
    );
    yield put(setAcademicDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updateAcademicQualifications({ data }) {
  try {
    yield http.put(`/api/academic`, data, RH_MIDDLEWARE);
    yield put(doneUpdateAcademicQualifications());
    yield put(activeSuccess("Academic qualifications updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating academic qualifications!"));
    devLogConsole(error);
  }
}

export function* updateNotMainAcademicQualifications({ data }) {
  try {
    yield http.put(`/api/academic/notMain`, data, RH_MIDDLEWARE);
    yield put(doneUpdateNotMainAcademicQualifications());
  } catch (error) {
    devLogConsole(error);
  }
}

export function* createAcademicQualifications({ data }) {
  try {
    yield http.post(`/api/academic`, data, RH_MIDDLEWARE);
    yield put(doneCreateAcademicQualifications());
    yield put(activeSuccess("Academic qualifications created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating academic qualifications!"));
    devLogConsole(error);
  }
}

//Tax Data

export function* getTaxData({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/taxData/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    if (Array.isArray(resp.data)) yield put(setTaxData(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getTaxDataDropdown() {
  try {
    const resp = yield http.get("/api/tax-data-dropdown ", RH_MIDDLEWARE);
    yield put(setTaxDataDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updateTaxData({ effectiveDate, data }) {
  try {
    yield http.put(`/api/taxData/${effectiveDate}`, data, RH_MIDDLEWARE);
    yield put(doneUpdateTaxData());
    yield put(activeSuccess("Tax data updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating tax data!"));
    devLogConsole(error);
  }
}

export function* createTaxData({ data }) {
  try {
    yield http.post(`/api/taxData`, data, RH_MIDDLEWARE);
    yield put(doneCreateTaxData());
    yield put(activeSuccess("Tax data created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating tax data!"));
    devLogConsole(error);
  }
}

// HOUSE HOLD

export function* getHouseHold({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/house-hold/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    if (Array.isArray(resp.data)) yield put(setHouseHold(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getHouseHoldDropdown() {
  try {
    const resp = yield http.get("/api/household-dropdown ", RH_MIDDLEWARE);
    yield put(setHouseHoldDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updateHousehold({ data }) {
  try {
    yield http.put(`/api/house-hold`, data, RH_MIDDLEWARE);
    yield put(doneUpdateHousehold());
    yield put(activeSuccess("Household updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating household!"));
    devLogConsole(error);
  }
}

export function* createHousehold({ data }) {
  try {
    yield http.post(`/api/house-hold`, data, RH_MIDDLEWARE);
    yield put(doneCreateHousehold());
    yield put(activeSuccess("Household created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating household!"));
    devLogConsole(error);
  }
}

//HOUSEHOLD AGFCODE LAST
export function* getHouseHoldAgfCodeLast() {
  try {
    const resp = yield http.get("/api/house-hold-AgfCodeLast", RH_MIDDLEWARE);
    yield put(setHouseHoldAgfCodeLast(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}
//PAYMENTS DATA
export function* getPaymentsData({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/payments-data/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    if (Array.isArray(resp.data)) yield put(setPaymentsData(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getPaymentsDropdown() {
  try {
    const resp = yield http.get("/api/form-of-payment-dropdown", RH_MIDDLEWARE);
    yield put(setPaymentsDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updatePaymentsData({ data }) {
  try {
    yield http.put(`/api/payments-data`, data, RH_MIDDLEWARE);
    yield put(doneUpdatePaymentsData());
    yield put(activeSuccess("Payments data updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating payments data!"));
    devLogConsole(error);
  }
}

export function* createPaymentsData({ data }) {
  try {
    yield http.post(`/api/payments-data`, data, RH_MIDDLEWARE);
    yield put(doneCreatePaymentsData());
    yield put(activeSuccess("Payments data created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating payments data!"));
    devLogConsole(error);
  }
}

//TRAINING DATA

export function* getTraining({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/training-sheet/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    if (Array.isArray(resp.data)) yield put(setTraining(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getTrainingDropdown() {
  try {
    const resp = yield http.get("/api/training-sheet-dropdown", RH_MIDDLEWARE);
    yield put(setTrainingDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updateTraining({ data }) {
  try {
    yield http.put(`/api/training-sheet`, data, RH_MIDDLEWARE);
    yield put(doneUpdateTraining());
    yield put(activeSuccess("Training sheet updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating training sheet!"));
    devLogConsole(error);
  }
}

export function* createTraining({ data }) {
  try {
    yield http.post(`/api/training-sheet`, data, RH_MIDDLEWARE);
    yield put(doneCreateTraining());
    yield put(activeSuccess("Training sheet created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating training sheet!"));
    devLogConsole(error);
  }
}

//Performance Evaluation Sheet

export function* getPerEvForm({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/performance-ev-form/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    if (Array.isArray(resp.data)) yield put(setPerEvForm(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getPerEvFormDropdown() {
  try {
    const resp = yield http.get(
      "/api/performance-ev-form-dropdown",
      RH_MIDDLEWARE
    );
    yield put(setPerEvFormDropdown(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updatePerEvForm({ data }) {
  try {
    yield http.put(`/api/performance-ev-form`, data, RH_MIDDLEWARE);
    yield put(doneUpdatePerEvForm());
    yield put(activeSuccess("Performance ev. form updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating performance ev. form!"));
    devLogConsole(error);
  }
}

export function* createPerEvForm({ data }) {
  try {
    yield http.post(`/api/performance-ev-form`, data, RH_MIDDLEWARE);
    yield put(doneCreatePerEvForm());
    yield put(activeSuccess("Performance ev. form created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating performance ev. form!"));
    devLogConsole(error);
  }
}

//MEDICAL RECORD

export function* getMedicalRecord({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/medical-record/${id}/${companyId} `,
      RH_MIDDLEWARE
    );
    yield put(setMedicalRecord(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* updateMedicalRecord({ data }) {
  try {
    yield http.put(`/api/medical-record`, data, RH_MIDDLEWARE);
    yield put(doneUpdateMedicalRecord());
    yield put(activeSuccess("Medical record updated successfully!"));
  } catch (error) {
    yield put(activeError("Error updating medical record!"));
    devLogConsole(error);
  }
}

export function* createMedicalRecord({ data }) {
  try {
    yield http.post(`/api/medical-record`, data, RH_MIDDLEWARE);
    yield put(doneCreateMedicalRecord());
    yield put(activeSuccess("Medical record created successfully!"));
  } catch (error) {
    yield put(activeError("Error creating medical record!"));
    devLogConsole(error);
  }
}
