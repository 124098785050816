import React, { useEffect, useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_option.scss";
import { useDispatch } from "react-redux";
import TableTextbox from "./TableTextbox";
import { useSelector } from "react-redux";
import { saveChangesWorkplan } from "~/store/ducks/budgetManagement/actionTypes";
import CreateButtonsFromSelection from "~/components/CreateForm/components/CreateButtonsFromSelection";
const devLogConsole = require("~/utils/devLog");
const ProposalResumeTable = ({
  info,
  workplanIndex,
  percentage,
  hoursPercentage,
  suppliersPercentage,
  costsPercentage,
  simulator,
}) => {
  const dispatch = useDispatch();

  const {
    workplans,
    paymentTypes,
    departments,
    factoring,
    collaborators,
    expenses,
    projectTypes,
    providerTypes,
    expenditureTypes,
    triggerLoading,
  } = useSelector((state) => state.budgetManagementReducer);

  const [factureArrayState, setFactureArrayState] = useState([]);
  const [costArrayState, setCostArrayState] = useState([]);
  const [tableTitle, setTableTitle] = useState({});
  const [totalFacture, setTotalFacture] = useState({});
  const [totalCost, setTotalCost] = useState({});
  const [income, setIncome] = useState({});
  const [percIncome, setPercIncome] = useState({});
  const [costHours, setCostHours] = useState({});
  const [hours, setHours] = useState({});
  const [margin, setMargin] = useState({});
  const [percMargin, setPercMargin] = useState({});
  const [marginOk, setMarginOk] = useState({});

  const [factureArrayStateVerify, setFactureArrayStateVerify] = useState([]);
  const [costArrayStateVerify, setCostArrayStateVerify] = useState([]);

  const [totalFactureVerify, setTotalFactureVerify] = useState({});
  const [totalCostVerify, setTotalCostVerify] = useState({});

  const [incomeVerify, setIncomeVerify] = useState({});
  const [percIncomeVerify, setPercIncomeVerify] = useState({});

  const [marginVerify, setMarginVerify] = useState({});
  const [percMarginVerify, setPercMarginVerify] = useState({});

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  var factureArray = [];
  var factureArrayVerify = [];
  var costArray = [];
  var costArrayVerify = [];

  var costHoursElement = {};
  var hoursElement = {};

  var gpi = 1.0;
  var fse = 1.0;
  var gpd = 1.0;
  var pc = 1.0;
  var pv = 1.0;

  function getPosition(index, list) {
    if (index === 0) return "start";
    if (index === Object.keys(tableTitle).length - 1) return "end";
  }

  function getPositionBottom(index, list) {
    if (index === 0) return "start-end";
    if (index === Object.keys(list).length - 1) return "end";
  }

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  useEffect(() => {
    if (expenses?.length != 0 && expenses != null && expenses != undefined) {
      const expGPI = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = expenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;

      const expGPD = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Direto (GPD)"
      );
      if (expGPD != null) gpd = expGPD.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
      gpd = 0;
    }

    var factureHoursElement = {};
    var factureSubElement = {};
    var factureCostElement = {};
    var facturFactElement = {};

    var factureHoursVerifyElement = {};
    var factureSubVerifyElement = {};
    var factureCostVerifyElement = {};
    var facturFactVerifyElement = {};

    var titleElement = {};
    var totalFactureElement = {};
    var totalCostElement = {};

    var totalFactureElementVerify = {};
    var totalCostElementVerify = {};

    var costSubElement = {};
    var costCostElement = {};
    var costFactElement = {};

    var costSubVerifyElement = {};
    var costCostVerifyElement = {};
    var costFactVerifyElement = {};

    var incomeElement = {};
    var percentIncomeElement = {};

    var marginElement = {};
    var initialMarginElement = {};
    var percentMarginElement = {};

    var incomeElementVerify = {};
    var percentIncomeElementVerify = {};
    var marginElementVerify = {};
    var initialMarginElementVerify = {};
    var percentMarginElementVerify = {};
    var marginOkElement = {};

    marginOkElement.description = "MARGEM OK/NOT";
    titleElement.description = "Descriçao";

    initialMarginElementVerify.description = "Margem";

    factureHoursVerifyElement.description = "Horas";
    factureSubVerifyElement.description = "Subcontratos";
    factureCostVerifyElement.description = "Despesas Admin";
    facturFactVerifyElement.description = "Factoring";

    factureHoursElement.description = "Horas";
    factureSubElement.description = "Subcontratos";
    factureCostElement.description = "Despesas Admin";
    facturFactElement.description = "Factoring";

    costSubElement.description = "Subcontratos";
    costCostElement.description = "Despesas Admin";
    costFactElement.description = "Factoring";

    costSubVerifyElement.description = "Subcontratos";
    costCostVerifyElement.description = "Despesas Admin";
    costFactVerifyElement.description = "Factoring";

    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    totalFactureElementVerify.description = "Total a Faturar";
    totalCostElementVerify.description = "Total Custos";

    incomeElement.description = "Income";
    percentIncomeElement.description = "";

    costHoursElement.description = "Custo Horas";
    hoursElement.description = "Horas";

    marginElement.description = "Margem";
    initialMarginElement.description = "Margem";
    percentMarginElement.description = "";

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";
    marginElementVerify.description = "Margem";
    percentMarginElementVerify.description = "";

    for (var i = 0; i < departments.length; i++) {
      titleElement[departments[i].nome] = [departments[i].nome];

      marginOkElement[departments[i].nome] = 0;

      factureHoursElement[departments[i].nome] = 0;
      factureSubElement[departments[i].nome] = 0;
      factureCostElement[departments[i].nome] = 0;
      facturFactElement[departments[i].nome] = 0;

      initialMarginElementVerify[departments[i].nome] = 0;

      factureHoursVerifyElement[departments[i].nome] = 0;
      factureSubVerifyElement[departments[i].nome] = 0;
      factureCostVerifyElement[departments[i].nome] = 0;
      facturFactVerifyElement[departments[i].nome] = 0;

      initialMarginElement[departments[i].nome] = 0;

      costSubElement[departments[i].nome] = 0;
      costCostElement[departments[i].nome] = 0;
      costFactElement[departments[i].nome] = 0;

      costSubVerifyElement[departments[i].nome] = 0;
      costCostVerifyElement[departments[i].nome] = 0;
      costFactVerifyElement[departments[i].nome] = 0;

      totalFactureElement[departments[i].nome] = 0;
      totalCostElement[departments[i].nome] = 0;

      totalFactureElementVerify[departments[i].nome] = 0;
      totalCostElementVerify[departments[i].nome] = 0;

      incomeElement[departments[i].nome] = 0;
      percentIncomeElement[departments[i].nome] = 0;

      costHoursElement[departments[i].nome] = 0;
      hoursElement[departments[i].nome] = 0;

      marginElement[departments[i].nome] = 0;
      percentMarginElement[departments[i].nome] = 0;

      incomeElementVerify[departments[i].nome] = 0;
      percentIncomeElementVerify[departments[i].nome] = 0;

      marginElementVerify[departments[i].nome] = 0;
      percentMarginElementVerify[departments[i].nome] = 0;
    }

    titleElement.total = "Total";

    marginOkElement.total = 0;

    initialMarginElementVerify.total = 0;

    initialMarginElement.total = 0;

    costHoursElement.total = 0;

    hoursElement.total = 0;

    percentIncomeElement.total = 0;
    incomeElement.total = 0;
    percentMarginElement.total = 0;
    marginElement.total = 0;

    percentIncomeElementVerify.total = 0;
    incomeElementVerify.total = 0;
    percentMarginElementVerify.total = 0;
    marginElementVerify.total = 0;

    factureHoursElement.total = 0;
    factureSubElement.total = 0;
    factureCostElement.total = 0;
    facturFactElement.total = 0;

    factureHoursVerifyElement.total = 0;
    factureSubVerifyElement.total = 0;
    factureCostVerifyElement.total = 0;
    facturFactVerifyElement.total = 0;

    totalFactureElement.total = 0;

    totalFactureElementVerify.total = 0;

    costSubElement.total = 0;
    costCostElement.total = 0;
    costFactElement.total = 0;

    costSubVerifyElement.total = 0;
    costCostVerifyElement.total = 0;
    costFactVerifyElement.total = 0;

    totalCostElement.total = 0;

    totalCostElementVerify.total = 0;

    factureArray.push(factureHoursElement);
    factureArray.push(factureSubElement);
    factureArray.push(factureCostElement);
    factureArray.push(facturFactElement);

    factureArrayVerify.push(factureHoursVerifyElement);
    factureArrayVerify.push(factureSubVerifyElement);
    factureArrayVerify.push(factureCostVerifyElement);
    factureArrayVerify.push(facturFactVerifyElement);

    costArray.push(costSubElement);
    costArray.push(costCostElement);
    costArray.push(costFactElement);

    costArrayVerify.push(costSubVerifyElement);
    costArrayVerify.push(costCostVerifyElement);
    costArrayVerify.push(costFactVerifyElement);

    buildHoursToFacture();
    setFactureArrayState(factureArray);
    setCostArrayState(costArray);

    for (var i = 0; i < factureArray.length; i++) {
      Object.keys(factureArray[i]).map(
        (key, index) =>
          (totalFactureElement[key] =
            totalFactureElement[key] + factureArray[i][key])
      );
    }

    for (var i = 0; i < factureArrayVerify.length; i++) {
      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalFactureElementVerify[key] =
            totalFactureElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    for (var i = 0; i < costArray.length; i++) {
      Object.keys(costArray[i]).map(
        (key, index) =>
          (totalCostElement[key] = totalCostElement[key] + costArray[i][key])
      );
    }

    for (var i = 0; i < costArrayVerify.length; i++) {
      Object.keys(costArrayVerify[i]).map(
        (key, index) =>
          (totalCostElementVerify[key] =
            totalCostElementVerify[key] + costArrayVerify[i][key])
      );
    }

    Object.keys(totalFactureElement).map(
      (key, index) =>
        (incomeElement[key] =
          parseFloat(totalFactureElement[key]) -
          parseFloat(totalCostElement[key]))
    );

    Object.keys(costHoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        costHoursElement.total = costHoursElement.total + costHoursElement[key];
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursStringArray = hoursElement[key].toString().split(".");
        var minutes = "0." + hoursStringArray[1];
        minutes = Math.ceil(parseFloat(minutes) * 60);
        minutes = minutes.toString();
        if (minutes.length === 1) {
          minutes = "0" + minutes;
        }
        hoursElement[key] = hoursStringArray[0] + ":" + minutes;
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursArray = hoursElement[key].split(":");
        var newHoras =
          parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;
        hoursElement.total = hoursElement.total + newHoras;
      }
    });

    const hoursStringArray = hoursElement.total.toString().split(".");

    var minutes = "0." + hoursStringArray[1];
    minutes = Math.ceil(parseFloat(minutes) * 60);
    minutes = minutes.toString();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    hoursElement.total = hoursStringArray[0] + ":" + minutes;

    Object.keys(totalFactureElementVerify).map(
      (key, index) =>
        (incomeElementVerify[key] =
          parseFloat(totalFactureElementVerify[key]) -
          parseFloat(totalCostElementVerify[key]))
    );

    Object.keys(incomeElement).map(
      (key, index) =>
        (percentIncomeElement[key] =
          (parseFloat(incomeElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(incomeElementVerify).map((key, index) => {
      if (totalFactureElementVerify[key] != 0) {
        percentIncomeElementVerify[key] =
          (incomeElementVerify[key] / totalFactureElementVerify[key]) * 100;
      }
    });

    var didCalculateMargin = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  didCalculateMargin = true;
                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");
                  var newHoras =
                    parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

                  if (getNameFromId() === "Avença") {
                    //newHoras =
                    //  newHoras *
                    //  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //    .horas[m].horasPropostasArray.length;
                  }

                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 = value1;
                    //factureArrayVerify[0][departments[a].nome] * fse +
                    //factureArrayVerify[0][departments[a].nome] * gpi;
                  }

                  initialMarginElementVerify[departments[a].nome] =
                    initialMarginElementVerify[departments[a].nome] + value2;

                  if (
                    hoursPercentage != "" &&
                    hoursPercentage != null &&
                    hoursPercentage != undefined &&
                    hoursPercentage != 0 &&
                    hoursPercentage != "0"
                  ) {
                    initialMarginElement[departments[a].nome] =
                      initialMarginElement[departments[a].nome] +
                      parseFloat(currentHours.custosHoras) * hoursPercentage;
                  } else if (
                    percentage != "" &&
                    percentage != null &&
                    percentage != undefined &&
                    percentage != 0 &&
                    percentage != "0"
                  ) {
                    initialMarginElement[departments[a].nome] =
                      initialMarginElement[departments[a].nome] +
                      parseFloat(currentHours.custosHoras) * percentage;
                  } else {
                    initialMarginElement[departments[a].nome] =
                      initialMarginElement[departments[a].nome] +
                      parseFloat(currentHours.custosHoras);
                  }
                }
              }
            }
          }
        }
      }
    }

    if (didCalculateMargin === false) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    didCalculateMargin = true;
                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");
                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    if (getNameFromId() === "Avença") {
                      // newHoras =
                      //   newHoras *
                      //   workplans[workplanIndex].items[i].atividades[j].opcoes[
                      //     k
                      //   ].horas[m].horasPropostasArray.length;
                    }
                    var value1;
                    var value2;
                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 = value1;
                      //factureArrayVerify[0][departments[a].nome] * fse +
                      // factureArrayVerify[0][departments[a].nome] * gpi;
                    }

                    initialMarginElementVerify[departments[a].nome] =
                      initialMarginElementVerify[departments[a].nome] + value2;
                      devLogConsole(hoursPercentage)
                    if (
                      hoursPercentage != "" &&
                      hoursPercentage != null &&
                      hoursPercentage != undefined &&
                      hoursPercentage != 0 &&
                      hoursPercentage != "0"
                    ) {
                      initialMarginElement[departments[a].nome] =
                        initialMarginElement[departments[a].nome] +
                          parseFloat(currentHours.custosHoras) *
                        hoursPercentage;
                    devLogConsole(initialMarginElement[departments[a].nome])
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      initialMarginElement[departments[a].nome] =
                        initialMarginElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras) * percentage;
                    } else {
                      initialMarginElement[departments[a].nome] =
                        initialMarginElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras) * 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    devLogConsole(incomeElement);
    devLogConsole(initialMarginElement);
    Object.keys(initialMarginElement).map((key, index) => {
      if (key != "total" && key != "description") {
        marginElement[key] =
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);

        marginElement.total +=
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);
      }
    });

    Object.keys(marginElement).map((key, index) => {
      if (
        /*initialMarginElementVerify[key] != 0 &&*/
        key != "total" &&
        key != "description"
      ) {
        initialMarginElementVerify[key] =
          initialMarginElementVerify[key] +
          factureArrayVerify[0][key] * fse +
          factureArrayVerify[0][key] * gpi;

        marginElementVerify[key] =
          parseFloat(incomeElementVerify[key]) -
          initialMarginElementVerify[key];

        marginElementVerify.total +=
          parseFloat(incomeElementVerify[key]) -
          parseFloat(initialMarginElementVerify[key]);
      }
    });

    devLogConsole(incomeElementVerify);
    devLogConsole(initialMarginElementVerify);

    Object.keys(marginElement).map((key, index) => {
      if (parseFloat(totalFactureElement[key]) === 0) {
        percentMarginElement[key] = 0;
      } else {
        percentMarginElement[key] =
          (parseFloat(marginElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100;
      }
    });

    Object.keys(marginElementVerify).map((key, index) => {
      if (totalFactureElementVerify[key] === 0) {
        percentMarginElementVerify[key] = 0;
      } else {
        percentMarginElementVerify[key] =
          (marginElementVerify[key] / totalFactureElementVerify[key]) * 100;
      }
    });

    Object.keys(percentMarginElementVerify).map((key, index) => {
      if (
        parseFloat(percentMarginElement[key].toFixed(2)) === 0 &&
        marginElement[key].toFixed(2) != 0
      ) {
        marginOkElement[key] = 0;
      } else if (incomeElement[key] === 0) {
        marginOkElement[key] = -1;
      } else if (
        parseFloat(percentMarginElement[key].toFixed(2)) <
        parseFloat(percentMarginElementVerify[key].toFixed(2))
      ) {
        marginOkElement[key] = 0;
      } else {
        marginOkElement[key] = 1;
      }
    });

    totalFactureElementVerify.description = "Total a faturar";

    setFactureArrayStateVerify(factureArrayVerify);
    setTotalFactureVerify(totalFactureElementVerify);
    setCostArrayStateVerify(costArrayVerify);
    totalCostElementVerify.description = "Total Custos";
    setTotalCostVerify(totalCostElementVerify);

    setCostHours(costHoursElement);
    setHours(hoursElement);

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";

    setIncomeVerify(incomeElementVerify);
    setPercIncomeVerify(percentIncomeElementVerify);

    marginElementVerify.description = "Margem";

    percentMarginElementVerify.description = "";

    setMarginVerify(marginElementVerify);
    setPercMarginVerify(percentMarginElementVerify);

    setMarginOk(marginOkElement);

    marginElement.description = "Margem";
    percentMarginElement.description = "";
    incomeElement.description = "Income";
    percentIncomeElement.description = "";
    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    setPercMargin(percentMarginElement);
    setMargin(marginElement);
    setPercIncome(percentIncomeElement);
    setIncome(incomeElement);
    setTotalCost(totalCostElement);
    setTotalFacture(totalFactureElement);
    setTableTitle(titleElement);

    if (simulator != true) {
      workplans[workplanIndex].faturacao = totalFactureElement.total;
      workplans[workplanIndex].subcontratos = totalCostElement.total;
      workplans[workplanIndex].income = incomeElement.total;
      workplans[workplanIndex].percIncome = percentIncomeElement.total;

      // dispatch(
      //   saveChangesWorkplan({ content: workplans[workplanIndex], show: false })
      // );
    }
  }, [factureArray.length === 0, triggerLoading]);

  function convertIdToName(id, name) {
    if (name === "departments") {
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
    if (name === "paymentTypes" && paymentTypes != null) {
      const payType = paymentTypes.find(
        (x) => x.idTipoPagamento === parseInt(id)
      );
      if (payType != null) return payType?.nome;
    }
  }

  function buildHoursToFacture() {
    var hasSelected = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            hasSelected = true;

            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ]
              );

              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  if (
                    hoursPercentage != "" &&
                    hoursPercentage != null &&
                    hoursPercentage != undefined &&
                    hoursPercentage != 0 &&
                    hoursPercentage != "0"
                  ) {
                    factureArray[0].total =
                      factureArray[0].total +
                      parseFloat(currentHours.valorVendaHoras) *
                        hoursPercentage;
                    factureArray[0][departments[a].nome] =
                      factureArray[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras) *
                        hoursPercentage;
                  } else if (
                    percentage != "" &&
                    percentage != null &&
                    percentage != undefined &&
                    percentage != 0 &&
                    percentage != "0"
                  ) {
                    factureArray[0].total =
                      factureArray[0].total +
                      parseFloat(currentHours.valorVendaHoras) * percentage;
                    factureArray[0][departments[a].nome] =
                      factureArray[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras) * percentage;
                  } else {
                    factureArray[0].total =
                      factureArray[0].total +
                      parseFloat(currentHours.valorVendaHoras);
                    factureArray[0][departments[a].nome] =
                      factureArray[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras);
                  }

                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");
                  if (
                    hoursPercentage != "" &&
                    hoursPercentage != null &&
                    hoursPercentage != undefined &&
                    hoursPercentage != 0 &&
                    hoursPercentage != "0"
                  ) {
                    var newHoras =
                      (parseFloat(hoursArray[0]) +
                        parseFloat(hoursArray[1]) / 60) *
                      hoursPercentage;

                    hoursElement[departments[a].nome] = (
                      parseFloat(hoursElement[departments[a].nome]) + newHoras
                    ).toFixed(2);
                  } else if (
                    percentage != "" &&
                    percentage != null &&
                    percentage != undefined &&
                    percentage != 0 &&
                    percentage != "0"
                  ) {
                    var newHoras =
                      (parseFloat(hoursArray[0]) +
                        parseFloat(hoursArray[1]) / 60) *
                      percentage;

                    hoursElement[departments[a].nome] = (
                      parseFloat(hoursElement[departments[a].nome]) + newHoras
                    ).toFixed(2);
                  } else {
                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    hoursElement[departments[a].nome] = (
                      parseFloat(hoursElement[departments[a].nome]) + newHoras
                    ).toFixed(2);
                  }
                  if (
                    hoursPercentage != "" &&
                    hoursPercentage != null &&
                    hoursPercentage != undefined &&
                    hoursPercentage != 0 &&
                    hoursPercentage != "0"
                  ) {
                    if (
                      typeof currentHours.custosHoras === "string" ||
                      currentHours.custosHoras instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras) * hoursPercentage;
                    } else {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        currentHours.custosHoras * hoursPercentage;
                    }
                    if (
                      typeof costHoursElement[departments[a].nome] ===
                        "string" ||
                      costHoursElement[departments[a].nome] instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        parseFloat(costHoursElement[departments[a].nome]) *
                        percentage;
                    }
                  } else if (
                    percentage != "" &&
                    percentage != null &&
                    percentage != undefined &&
                    percentage != 0 &&
                    percentage != "0"
                  ) {
                    if (
                      typeof currentHours.custosHoras === "string" ||
                      currentHours.custosHoras instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras) * percentage;
                    } else {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        currentHours.custosHoras * percentage;
                    }
                    if (
                      typeof costHoursElement[departments[a].nome] ===
                        "string" ||
                      costHoursElement[departments[a].nome] instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        parseFloat(costHoursElement[departments[a].nome]) *
                        percentage;
                    }
                  } else {
                    if (
                      typeof currentHours.custosHoras === "string" ||
                      currentHours.custosHoras instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras);
                    } else {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        currentHours.custosHoras;
                    }
                    if (
                      typeof costHoursElement[departments[a].nome] ===
                        "string" ||
                      costHoursElement[departments[a].nome] instanceof String
                    ) {
                      costHoursElement[departments[a].nome] = parseFloat(
                        costHoursElement[departments[a].nome]
                      );
                    }
                  }

                  if (getNameFromId() === "Avença") {
                    // newHoras =
                    //   newHoras *
                    //   workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //     .horas[m].horasPropostasArray.length;
                  }
                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 = value1 / gpd;
                  }

                  if (value2 > parseFloat(currentHours.valorVendaHoras)) {
                    factureArrayVerify[0][departments[a].nome] =
                      factureArrayVerify[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras);
                    factureArrayVerify[0].total =
                      factureArrayVerify[0].total +
                      parseFloat(currentHours.valorVendaHoras);
                  } else {
                    factureArrayVerify[0][departments[a].nome] =
                      factureArrayVerify[0][departments[a].nome] +
                      parseFloat(value2);
                    factureArrayVerify[0].total =
                      factureArrayVerify[0].total + parseFloat(value2);
                  }
                }
              }
            }

            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                .fornecedores.length;
              m++
            ) {
              var currentSuppliers = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores[m]
              );
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentSuppliers.departamentoForn,
                    "departments"
                  ) === departments[a].nome
                ) {
                  if (
                    suppliersPercentage != "" &&
                    suppliersPercentage != null &&
                    suppliersPercentage != undefined &&
                    suppliersPercentage != 0 &&
                    suppliersPercentage != "0"
                  ) {
                    factureArray[1].total =
                      factureArray[1].total +
                      parseFloat(currentSuppliers.valorVendaForn) *
                        suppliersPercentage;

                    factureArray[1][departments[a].nome] =
                      factureArray[1][departments[a].nome] +
                      parseFloat(currentSuppliers.valorVendaForn) *
                        suppliersPercentage;

                    costArray[0].total =
                      costArray[0].total +
                      parseFloat(currentSuppliers.precoCompraForn) *
                        suppliersPercentage;

                    costArray[0][departments[a].nome] =
                      costArray[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn) *
                        suppliersPercentage;

                    costArrayVerify[0].total =
                      costArrayVerify[0].total +
                      parseFloat(currentSuppliers.precoCompraForn) *
                        suppliersPercentage;
                    costArrayVerify[0][departments[a].nome] =
                      costArrayVerify[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn) *
                        suppliersPercentage;
                  } else if (
                    percentage != "" &&
                    percentage != null &&
                    percentage != undefined &&
                    percentage != 0 &&
                    percentage != "0"
                  ) {
                    factureArray[1].total =
                      factureArray[1].total +
                      parseFloat(currentSuppliers.valorVendaForn) * percentage;

                    factureArray[1][departments[a].nome] =
                      factureArray[1][departments[a].nome] +
                      parseFloat(currentSuppliers.valorVendaForn) * percentage;

                    costArray[0].total =
                      costArray[0].total +
                      parseFloat(currentSuppliers.precoCompraForn) * percentage;

                    costArray[0][departments[a].nome] =
                      costArray[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn) * percentage;

                    costArrayVerify[0].total =
                      costArrayVerify[0].total +
                      parseFloat(currentSuppliers.precoCompraForn) * percentage;

                    costArrayVerify[0][departments[a].nome] =
                      costArrayVerify[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn) * percentage;
                  } else {
                    factureArray[1].total =
                      factureArray[1].total +
                      parseFloat(currentSuppliers.valorVendaForn);

                    factureArray[1][departments[a].nome] =
                      factureArray[1][departments[a].nome] +
                      parseFloat(currentSuppliers.valorVendaForn);

                    costArray[0].total =
                      costArray[0].total +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArray[0][departments[a].nome] =
                      costArray[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn);
                  }

                  if (
                    currentSuppliers.precoCompraForn != 0 &&
                    currentSuppliers.precoCompraForn != null &&
                    currentSuppliers.precoCompraForn != undefined
                  ) {
                    const currentSupplier = providerTypes.find(
                      (x) =>
                        x.idTipoFornecedor === currentSuppliers.tipoFornecedor
                    );
                    if (
                      suppliersPercentage != "" &&
                      suppliersPercentage != null &&
                      suppliersPercentage != undefined &&
                      suppliersPercentage != 0 &&
                      suppliersPercentage != "0"
                    ) {
                      factureArrayVerify[1].total =
                        factureArrayVerify[1].total +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        ) *
                          suppliersPercentage;

                      factureArrayVerify[1][departments[a].nome] =
                        factureArrayVerify[1][departments[a].nome] +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        ) *
                          suppliersPercentage;
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      factureArrayVerify[1].total =
                        factureArrayVerify[1].total +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        ) *
                          percentage;

                      factureArrayVerify[1][departments[a].nome] =
                        factureArrayVerify[1][departments[a].nome] +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        ) *
                          percentage;
                    } else {
                      factureArrayVerify[1].total =
                        factureArrayVerify[1].total +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        );

                      factureArrayVerify[1][departments[a].nome] =
                        factureArrayVerify[1][departments[a].nome] +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        );

                      costArrayVerify[0].total =
                        costArrayVerify[0].total +
                        parseFloat(currentSuppliers.precoCompraForn);

                      costArrayVerify[0][departments[a].nome] =
                        costArrayVerify[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn);
                    }
                  }
                }
              }
            }
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].despesas
                .length;
              m++
            ) {
              var currentCosts = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas[m]
              );
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentCosts.departamentoDesp,
                    "departments"
                  ) === departments[a].nome
                ) {
                  if (
                    costsPercentage != "" &&
                    costsPercentage != null &&
                    costsPercentage != undefined &&
                    costsPercentage != 0 &&
                    costsPercentage != "0"
                  ) {
                    factureArray[2].total =
                      factureArray[2].total +
                      parseFloat(currentCosts.valorVendaDesp) * costsPercentage;

                    factureArray[2][departments[a].nome] =
                      factureArray[2][departments[a].nome] +
                      parseFloat(currentCosts.valorVendaDesp) * costsPercentage;

                    costArray[1].total =
                      costArray[1].total +
                      parseFloat(currentCosts.precoCompraForn) *
                        costsPercentage;

                    costArray[1][departments[a].nome] =
                      costArray[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn) *
                        costsPercentage;

                    costArrayVerify[1].total =
                      costArrayVerify[1].total +
                      parseFloat(currentCosts.precoCompraForn) *
                        costsPercentage;

                    costArrayVerify[1][departments[a].nome] =
                      costArrayVerify[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn) *
                        costsPercentage;
                  } else if (
                    percentage != "" &&
                    percentage != null &&
                    percentage != undefined &&
                    percentage != 0 &&
                    percentage != "0"
                  ) {
                    factureArray[2].total =
                      factureArray[2].total +
                      parseFloat(currentCosts.valorVendaDesp) * percentage;

                    factureArray[2][departments[a].nome] =
                      factureArray[2][departments[a].nome] +
                      parseFloat(currentCosts.valorVendaDesp) * percentage;

                    costArray[1].total =
                      costArray[1].total +
                      parseFloat(currentCosts.precoCompraForn) * percentage;

                    costArray[1][departments[a].nome] =
                      costArray[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn) * percentage;

                    costArrayVerify[1].total =
                      costArrayVerify[1].total +
                      parseFloat(currentCosts.precoCompraForn) * percentage;

                    costArrayVerify[1][departments[a].nome] =
                      costArrayVerify[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn) * percentage;
                  } else {
                    factureArray[2].total =
                      factureArray[2].total +
                      parseFloat(currentCosts.valorVendaDesp);

                    factureArray[2][departments[a].nome] =
                      factureArray[2][departments[a].nome] +
                      parseFloat(currentCosts.valorVendaDesp);

                    costArray[1].total =
                      costArray[1].total +
                      parseFloat(currentCosts.precoCompraForn);

                    costArray[1][departments[a].nome] =
                      costArray[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn);
                  }

                  if (
                    currentCosts.precoCompraForn != 0 &&
                    currentCosts.precoCompraForn != null &&
                    currentCosts.precoCompraForn != undefined
                  ) {
                    const currentExpense = expenditureTypes.find(
                      (x) =>
                        x.idTipoDespesa === parseInt(currentCosts.tipoDespesa)
                    );
                    if (
                      costsPercentage != "" &&
                      costsPercentage != null &&
                      costsPercentage != undefined &&
                      costsPercentage != 0 &&
                      costsPercentage != "0"
                    ) {
                      factureArrayVerify[2].total =
                        factureArrayVerify[2].total +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        ) *
                          costsPercentage;

                      factureArrayVerify[2][departments[a].nome] =
                        factureArrayVerify[2][departments[a].nome] +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        ) *
                          costsPercentage;
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      factureArrayVerify[2].total =
                        factureArrayVerify[2].total +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        ) *
                          percentage;

                      factureArrayVerify[2][departments[a].nome] =
                        factureArrayVerify[2][departments[a].nome] +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        ) *
                          percentage;
                    } else {
                      factureArrayVerify[2].total =
                        factureArrayVerify[2].total +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        );

                      factureArrayVerify[2][departments[a].nome] =
                        factureArrayVerify[2][departments[a].nome] +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        );

                      costArrayVerify[1].total =
                        costArrayVerify[1].total +
                        parseFloat(currentCosts.precoCompraForn);

                      costArrayVerify[1][departments[a].nome] =
                        costArrayVerify[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (!hasSelected) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m]
                );

                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    if (
                      hoursPercentage != "" &&
                      hoursPercentage != null &&
                      hoursPercentage != undefined &&
                      hoursPercentage != 0 &&
                      hoursPercentage != "0"
                    ) {
                      factureArray[0].total =
                        factureArray[0].total +
                        parseFloat(currentHours.valorVendaHoras) *
                          hoursPercentage;
                      factureArray[0][departments[a].nome] =
                        factureArray[0][departments[a].nome] +
                        parseFloat(currentHours.valorVendaHoras) *
                          hoursPercentage;
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      factureArray[0].total =
                        factureArray[0].total +
                        parseFloat(currentHours.valorVendaHoras) * percentage;
                      factureArray[0][departments[a].nome] =
                        factureArray[0][departments[a].nome] +
                        parseFloat(currentHours.valorVendaHoras) * percentage;
                    } else {
                      factureArray[0].total =
                        factureArray[0].total +
                        parseFloat(currentHours.valorVendaHoras);
                      factureArray[0][departments[a].nome] =
                        factureArray[0][departments[a].nome] +
                        parseFloat(currentHours.valorVendaHoras);
                    }

                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");
                    if (
                      hoursPercentage != "" &&
                      hoursPercentage != null &&
                      hoursPercentage != undefined &&
                      hoursPercentage != 0 &&
                      hoursPercentage != "0"
                    ) {
                      var newHoras =
                        (parseFloat(hoursArray[0]) +
                          parseFloat(hoursArray[1]) / 60) *
                        hoursPercentage;

                      hoursElement[departments[a].nome] = (
                        parseFloat(hoursElement[departments[a].nome]) + newHoras
                      ).toFixed(2);
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      var newHoras =
                        (parseFloat(hoursArray[0]) +
                          parseFloat(hoursArray[1]) / 60) *
                        percentage;

                      hoursElement[departments[a].nome] = (
                        parseFloat(hoursElement[departments[a].nome]) + newHoras
                      ).toFixed(2);
                    } else {
                      var newHoras =
                        parseFloat(hoursArray[0]) +
                        parseFloat(hoursArray[1]) / 60;

                      hoursElement[departments[a].nome] = (
                        parseFloat(hoursElement[departments[a].nome]) + newHoras
                      ).toFixed(2);
                    }
                    if (
                      hoursPercentage != "" &&
                      hoursPercentage != null &&
                      hoursPercentage != undefined &&
                      hoursPercentage != 0 &&
                      hoursPercentage != "0"
                    ) {
                      if (
                        typeof currentHours.custosHoras === "string" ||
                        currentHours.custosHoras instanceof String
                      ) {
                        costHoursElement[departments[a].nome] =
                          costHoursElement[departments[a].nome] +
                          parseFloat(currentHours.custosHoras) *
                            hoursPercentage;
                      } else {
                        costHoursElement[departments[a].nome] =
                          costHoursElement[departments[a].nome] +
                          currentHours.custosHoras * hoursPercentage;
                      }
                      if (
                        typeof costHoursElement[departments[a].nome] ===
                          "string" ||
                        costHoursElement[departments[a].nome] instanceof String
                      ) {
                        costHoursElement[departments[a].nome] =
                          parseFloat(costHoursElement[departments[a].nome]) *
                          percentage;
                      }
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      if (
                        typeof currentHours.custosHoras === "string" ||
                        currentHours.custosHoras instanceof String
                      ) {
                        costHoursElement[departments[a].nome] =
                          costHoursElement[departments[a].nome] +
                          parseFloat(currentHours.custosHoras) * percentage;
                      } else {
                        costHoursElement[departments[a].nome] =
                          costHoursElement[departments[a].nome] +
                          currentHours.custosHoras * percentage;
                      }
                      if (
                        typeof costHoursElement[departments[a].nome] ===
                          "string" ||
                        costHoursElement[departments[a].nome] instanceof String
                      ) {
                        costHoursElement[departments[a].nome] =
                          parseFloat(costHoursElement[departments[a].nome]) *
                          percentage;
                      }
                    } else {
                      if (
                        typeof currentHours.custosHoras === "string" ||
                        currentHours.custosHoras instanceof String
                      ) {
                        costHoursElement[departments[a].nome] =
                          costHoursElement[departments[a].nome] +
                          parseFloat(currentHours.custosHoras);
                      } else {
                        costHoursElement[departments[a].nome] =
                          costHoursElement[departments[a].nome] +
                          currentHours.custosHoras;
                      }
                      if (
                        typeof costHoursElement[departments[a].nome] ===
                          "string" ||
                        costHoursElement[departments[a].nome] instanceof String
                      ) {
                        costHoursElement[departments[a].nome] = parseFloat(
                          costHoursElement[departments[a].nome]
                        );
                      }
                    }

                    var value1;
                    var value2;

                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      devLogConsole(newHoras);
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 = value1 / gpd;
                    }

                    if (value2 > parseFloat(currentHours.valorVendaHoras)) {
                      factureArrayVerify[0][departments[a].nome] =
                        factureArrayVerify[0][departments[a].nome] +
                        parseFloat(currentHours.valorVendaHoras);
                      factureArrayVerify[0].total =
                        factureArrayVerify[0].total +
                        parseFloat(currentHours.valorVendaHoras);
                    } else {
                      factureArrayVerify[0][departments[a].nome] =
                        factureArrayVerify[0][departments[a].nome] +
                        parseFloat(value2);
                      factureArrayVerify[0].total =
                        factureArrayVerify[0].total + parseFloat(value2);
                    }
                  }
                }
              }

              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores.length;
                m++
              ) {
                var currentSuppliers = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .fornecedores[m]
                );
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentSuppliers.departamentoForn,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    if (
                      suppliersPercentage != "" &&
                      suppliersPercentage != null &&
                      suppliersPercentage != undefined &&
                      suppliersPercentage != 0 &&
                      suppliersPercentage != "0"
                    ) {
                      factureArray[1].total =
                        factureArray[1].total +
                        parseFloat(currentSuppliers.valorVendaForn) *
                          suppliersPercentage;

                      factureArray[1][departments[a].nome] =
                        factureArray[1][departments[a].nome] +
                        parseFloat(currentSuppliers.valorVendaForn) *
                          suppliersPercentage;

                      costArray[0].total =
                        costArray[0].total +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          suppliersPercentage;

                      costArray[0][departments[a].nome] =
                        costArray[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          suppliersPercentage;

                      costArrayVerify[0].total =
                        costArrayVerify[0].total +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          suppliersPercentage;

                      costArrayVerify[0][departments[a].nome] =
                        costArrayVerify[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          suppliersPercentage;
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      factureArray[1].total =
                        factureArray[1].total +
                        parseFloat(currentSuppliers.valorVendaForn) *
                          percentage;

                      factureArray[1][departments[a].nome] =
                        factureArray[1][departments[a].nome] +
                        parseFloat(currentSuppliers.valorVendaForn) *
                          percentage;

                      costArray[0].total =
                        costArray[0].total +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          percentage;

                      costArray[0][departments[a].nome] =
                        costArray[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          percentage;

                      costArrayVerify[0].total =
                        costArrayVerify[0].total +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          percentage;

                      costArrayVerify[0][departments[a].nome] =
                        costArrayVerify[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn) *
                          percentage;
                    } else {
                      factureArray[1].total =
                        factureArray[1].total +
                        parseFloat(currentSuppliers.valorVendaForn);

                      factureArray[1][departments[a].nome] =
                        factureArray[1][departments[a].nome] +
                        parseFloat(currentSuppliers.valorVendaForn);

                      costArray[0].total =
                        costArray[0].total +
                        parseFloat(currentSuppliers.precoCompraForn);

                      costArray[0][departments[a].nome] =
                        costArray[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn);

                      costArrayVerify[0].total =
                        costArrayVerify[0].total +
                        parseFloat(currentSuppliers.precoCompraForn);

                      costArrayVerify[0][departments[a].nome] =
                        costArrayVerify[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn);
                    }

                    if (
                      currentSuppliers.precoCompraForn != 0 &&
                      currentSuppliers.precoCompraForn != null &&
                      currentSuppliers.precoCompraForn != undefined
                    ) {
                      const currentSupplier = providerTypes.find(
                        (x) =>
                          x.idTipoFornecedor === currentSuppliers.tipoFornecedor
                      );

                      if (
                        suppliersPercentage != "" &&
                        suppliersPercentage != null &&
                        suppliersPercentage != undefined &&
                        suppliersPercentage != 0 &&
                        suppliersPercentage != "0"
                      ) {
                        factureArrayVerify[1].total =
                          factureArrayVerify[1].total +
                          parseFloat(
                            currentSuppliers.precoCompraForn /
                              (1 - currentSupplier.percentagem / 100)
                          ) *
                            suppliersPercentage;

                        factureArrayVerify[1][departments[a].nome] =
                          factureArrayVerify[1][departments[a].nome] +
                          parseFloat(
                            currentSuppliers.precoCompraForn /
                              (1 - currentSupplier.percentagem / 100)
                          ) *
                            suppliersPercentage;
                      } else if (
                        percentage != "" &&
                        percentage != null &&
                        percentage != undefined &&
                        percentage != 0 &&
                        percentage != "0"
                      ) {
                        factureArrayVerify[1].total =
                          factureArrayVerify[1].total +
                          parseFloat(
                            currentSuppliers.precoCompraForn /
                              (1 - currentSupplier.percentagem / 100)
                          ) *
                            percentage;

                        factureArrayVerify[1][departments[a].nome] =
                          factureArrayVerify[1][departments[a].nome] +
                          parseFloat(
                            currentSuppliers.precoCompraForn /
                              (1 - currentSupplier.percentagem / 100)
                          ) *
                            percentage;
                      } else {
                        factureArrayVerify[1].total =
                          factureArrayVerify[1].total +
                          parseFloat(
                            currentSuppliers.precoCompraForn /
                              (1 - currentSupplier.percentagem / 100)
                          );

                        factureArrayVerify[1][departments[a].nome] =
                          factureArrayVerify[1][departments[a].nome] +
                          parseFloat(
                            currentSuppliers.precoCompraForn /
                              (1 - currentSupplier.percentagem / 100)
                          );
                      }
                    }
                  }
                }
              }
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas.length;
                m++
              ) {
                var currentCosts = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .despesas[m]
                );
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentCosts.departamentoDesp,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    if (
                      costsPercentage != "" &&
                      costsPercentage != null &&
                      costsPercentage != undefined &&
                      costsPercentage != 0 &&
                      costsPercentage != "0"
                    ) {
                      factureArray[2].total =
                        factureArray[2].total +
                        parseFloat(currentCosts.valorVendaDesp) *
                          costsPercentage;

                      factureArray[2][departments[a].nome] =
                        factureArray[2][departments[a].nome] +
                        parseFloat(currentCosts.valorVendaDesp) *
                          costsPercentage;

                      costArray[1].total =
                        costArray[1].total +
                        parseFloat(currentCosts.precoCompraForn) *
                          costsPercentage;

                      costArray[1][departments[a].nome] =
                        costArray[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn) *
                          costsPercentage;

                      costArrayVerify[1].total =
                        costArrayVerify[1].total +
                        parseFloat(currentCosts.precoCompraForn) *
                          costsPercentage;

                      costArrayVerify[1][departments[a].nome] =
                        costArrayVerify[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn) *
                          costsPercentage;
                    } else if (
                      percentage != "" &&
                      percentage != null &&
                      percentage != undefined &&
                      percentage != 0 &&
                      percentage != "0"
                    ) {
                      factureArray[2].total =
                        factureArray[2].total +
                        parseFloat(currentCosts.valorVendaDesp) * percentage;

                      factureArray[2][departments[a].nome] =
                        factureArray[2][departments[a].nome] +
                        parseFloat(currentCosts.valorVendaDesp) * percentage;

                      costArray[1].total =
                        costArray[1].total +
                        parseFloat(currentCosts.precoCompraForn) * percentage;

                      costArray[1][departments[a].nome] =
                        costArray[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn) * percentage;

                      costArrayVerify[1].total =
                        costArrayVerify[1].total +
                        parseFloat(currentCosts.precoCompraForn) * percentage;

                      costArrayVerify[1][departments[a].nome] =
                        costArrayVerify[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn) * percentage;
                    } else {
                      factureArray[2].total =
                        factureArray[2].total +
                        parseFloat(currentCosts.valorVendaDesp);

                      factureArray[2][departments[a].nome] =
                        factureArray[2][departments[a].nome] +
                        parseFloat(currentCosts.valorVendaDesp);

                      costArray[1].total =
                        costArray[1].total +
                        parseFloat(currentCosts.precoCompraForn);

                      costArray[1][departments[a].nome] =
                        costArray[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn);
                    }

                    if (
                      currentCosts.precoCompraForn != 0 &&
                      currentCosts.precoCompraForn != null &&
                      currentCosts.precoCompraForn != undefined
                    ) {
                      const currentExpense = expenditureTypes.find(
                        (x) =>
                          x.idTipoDespesa === parseInt(currentCosts.tipoDespesa)
                      );

                      if (
                        costsPercentage != "" &&
                        costsPercentage != null &&
                        costsPercentage != undefined &&
                        costsPercentage != 0 &&
                        costsPercentage != "0"
                      ) {
                        factureArrayVerify[2].total =
                          factureArrayVerify[2].total +
                          parseFloat(
                            currentCosts.precoCompraForn /
                              (1 - currentExpense.percentagem / 100)
                          ) *
                            costsPercentage;

                        factureArrayVerify[2][departments[a].nome] =
                          factureArrayVerify[2][departments[a].nome] +
                          parseFloat(
                            currentCosts.precoCompraForn /
                              (1 - currentExpense.percentagem / 100)
                          ) *
                            costsPercentage;
                      } else if (
                        percentage != "" &&
                        percentage != null &&
                        percentage != undefined &&
                        percentage != 0 &&
                        percentage != "0"
                      ) {
                        factureArrayVerify[2].total =
                          factureArrayVerify[2].total +
                          parseFloat(
                            currentCosts.precoCompraForn /
                              (1 - currentExpense.percentagem / 100)
                          ) *
                            percentage;

                        factureArrayVerify[2][departments[a].nome] =
                          factureArrayVerify[2][departments[a].nome] +
                          parseFloat(
                            currentCosts.precoCompraForn /
                              (1 - currentExpense.percentagem / 100)
                          ) *
                            percentage;
                      } else {
                        factureArrayVerify[2].total =
                          factureArrayVerify[2].total +
                          parseFloat(
                            currentCosts.precoCompraForn /
                              (1 - currentExpense.percentagem / 100)
                          );

                        factureArrayVerify[2][departments[a].nome] =
                          factureArrayVerify[2][departments[a].nome] +
                          parseFloat(
                            currentCosts.precoCompraForn /
                              (1 - currentExpense.percentagem / 100)
                          );

                        costArrayVerify[1].total =
                          costArrayVerify[1].total +
                          parseFloat(currentCosts.precoCompraForn);

                        costArrayVerify[1][departments[a].nome] =
                          costArrayVerify[1][departments[a].nome] +
                          parseFloat(currentCosts.precoCompraForn);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    var totalCostProportionElement = {};
    var totalCostProportionElementVerify = {};
    totalCostProportionElement.description = "DESCRIÇÃO";
    for (var i = 0; i < departments.length; i++) {
      totalCostProportionElement[departments[i].nome] = 0;
      totalCostProportionElementVerify[departments[i].nome] = 0;
    }
    totalCostProportionElement.total = 0;
    totalCostProportionElementVerify.total = 0;

    for (var i = 0; i < factureArray.length - 1; i++) {
      Object.keys(factureArray[i]).map(
        (key, index) =>
          (totalCostProportionElement[key] =
            totalCostProportionElement[key] + factureArray[i][key])
      );

      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalCostProportionElementVerify[key] =
            totalCostProportionElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    var factName = convertIdToName(
      workplans[workplanIndex].formaPagamento,
      "paymentTypes"
    );
    const fact = factoring.find((x) => x.nome === factName);

    if (fact != null && fact != undefined) {
      const juro =
        ((totalCostProportionElement.total *
          1.23 *
          (fact.percentAdiantamento / 100) *
          (fact.txJuroEfetiva / 100)) /
          360) *
        parseFloat(workplans[workplanIndex].prazoPagamento);
      const impstSJuro = juro * (fact.impostoSeloComissao / 100);
      const comGestao =
        totalCostProportionElement.total * 1.23 * (fact.comissaoGestao / 100);
      const impsCJuro = comGestao * (fact.impostoSeloComissao / 100);
      const totalFactoring = juro + impsCJuro + impstSJuro + comGestao;

      const juroVerify =
        ((totalCostProportionElementVerify.total *
          1.23 *
          (fact.percentAdiantamento / 100) *
          (fact.txJuroEfetiva / 100)) /
          360) *
        parseFloat(workplans[workplanIndex].prazoPagamento);
      const impstSJuroVerify = juroVerify * (fact.impostoSeloComissao / 100);
      const comGestaoVerify =
        totalCostProportionElementVerify.total *
        1.23 *
        (fact.comissaoGestao / 100);
      const impsCJuroVerify =
        comGestaoVerify * (fact.impostoSeloComissao / 100);
      const totalFactoringVerify =
        juroVerify + impsCJuroVerify + impstSJuroVerify + comGestaoVerify;

      Object.keys(costArray[2]).map((key, index) => {
        if (key != "total")
          costArray[2][key] =
            costArray[2][key] +
            parseFloat(
              (totalCostProportionElement[key] /
                totalCostProportionElement.total) *
                totalFactoring
            );
        if (key != "description" && key != "total")
          costArray[2].total = costArray[2].total + costArray[2][key];
      });

      Object.keys(costArrayVerify[2]).map((key, index) => {
        if (key != "total")
          costArrayVerify[2][key] =
            costArrayVerify[2][key] +
            parseFloat(
              (totalCostProportionElementVerify[key] /
                totalCostProportionElementVerify.total) *
                totalFactoringVerify
            );

        if (key != "description" && key != "total")
          costArrayVerify[2].total =
            costArrayVerify[2].total + costArrayVerify[2][key];
      });

      costArrayVerify[2].description = "Factoring";
      costArray[2].description = "Factoring";

      if (fact && workplans[workplanIndex].aceitarFormaPagamento === "Sim") {
        Object.keys(costArray[2]).map(
          (key, index) =>
            (factureArray[3][key] = costArray[2][key] / (1 - fact.margemPV))
        );

        Object.keys(costArrayVerify[2]).map(
          (key, index) =>
            (factureArrayVerify[3][key] =
              costArrayVerify[2][key] / (1 - fact.margemPV))
        );
      }
      factureArray[3].description = "Factoring";
      factureArrayVerify[3].description = "Factoring";
    }
  }

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function getPositionTop(index) {
    if (index === 0) return "start";
    if (index === Object.keys(tableTitle).length - 1) return "top-end";
  }

  return (
    <div className="liftworld-proposal-tables-wrapper">
      <div className="lifworld-table-wrapper">
        <div className="liftworld-table-header-wrapper">
          {Object.keys(tableTitle).map((key, index) => (
            <div
              className={
                `${"liftworld-table-header-element"} ` + getPosition(index)
              }
            >
              {tableTitle[key]}
            </div>
          ))}
        </div>
        <div>
          {factureArrayState &&
            Object.keys(factureArrayState).length !== 0 &&
            factureArrayState.map((item, index) => {
              return (
                <div style={{ display: "flex" }}>
                  {Object.keys(factureArrayState[index]).map((key, i) => (
                    <TableTextbox
                      value={
                        i === 0
                          ? factureArrayState[index][key]
                          : isNaN(parseFloat(factureArrayState[index][key])) ||
                            factureArrayState[index][key] === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(factureArrayState[index][key]).toFixed(
                                2
                              )
                            ) + "€"
                      }
                      position={"middle"}
                      number={i != 0 ? "number" : ""}
                    />
                  ))}
                </div>
              );
            })}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(totalFacture).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? totalFacture[key]
                    : isNaN(parseFloat(totalFacture[key])) ||
                      totalFacture[key] === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(totalFacture[key]).toFixed(2)
                      ) + "€"
                }
                position={"middle"}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div>
          {costArrayState &&
            Object.keys(costArrayState).length !== 0 &&
            costArrayState.map((item, index) => {
              return (
                <div style={{ display: "flex" }}>
                  {Object.keys(costArrayState[index]).map((key, i) => (
                    <TableTextbox
                      value={
                        i === 0
                          ? costArrayState[index][key]
                          : isNaN(parseFloat(costArrayState[index][key])) ||
                            costArrayState[index][key] === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(costArrayState[index][key]).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={i != 0 ? "number" : ""}
                    />
                  ))}
                </div>
              );
            })}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(totalCost).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? totalCost[key]
                    : isNaN(parseFloat(totalCost[key])) ||
                      totalCost[key] === undefined
                    ? "0,00 €"
                    : numberWithCommas(parseFloat(totalCost[key]).toFixed(2)) +
                      "€"
                }
                position={getPositionBottom(index, totalCost)}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(income).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? income[key]
                      : isNaN(parseFloat(income[key])) ||
                        income[key] === undefined
                      ? "0,00 €"
                      : numberWithCommas(parseFloat(income[key]).toFixed(2)) +
                        "€"
                  }
                  position={getPositionTop(index, income)}
                  color={"colored"}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(percIncome).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? percIncome[key]
                      : isNaN(parseFloat(percIncome[key])) ||
                        percIncome[key] === undefined
                      ? "0,00 %"
                      : numberWithCommas(
                          parseFloat(percIncome[key]).toFixed(2)
                        ) + "%"
                  }
                  position={getPositionBottom(index, percIncome)}
                  color={"colored"}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(costHours).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? costHours[key]
                      : isNaN(parseFloat(costHours[key])) ||
                        costHours[key] === undefined
                      ? "0,00 €"
                      : numberWithCommas(
                          parseFloat(costHours[key]).toFixed(2)
                        ) + "€"
                  }
                  position={getPositionTop(index, costHours)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(hours).map((key, index) => (
                <TableTextbox
                  value={hours[key]}
                  position={getPositionBottom(index, hours)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(margin).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? margin[key]
                      : isNaN(parseFloat(margin[key])) ||
                        margin[key] === undefined
                      ? "0,00 €"
                      : numberWithCommas(parseFloat(margin[key]).toFixed(2)) +
                        "€"
                  }
                  position={getPositionTop(index, margin)}
                  color={"colored"}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(percMargin).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? percMargin[key]
                      : isNaN(parseFloat(percMargin[key])) ||
                        percMargin[key] === undefined
                      ? "0,00 %"
                      : numberWithCommas(
                          parseFloat(percMargin[key]).toFixed(2)
                        ) + "%"
                  }
                  color={"colored"}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(marginOk).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? ""
                      : index === Object.keys(marginOk).length - 1
                      ? marginOk[key] === 0
                        ? "Não aprovada"
                        : "Aprovada"
                      : marginOk[key] === -1
                      ? ""
                      : marginOk[key] === 0
                      ? "Rever Margem"
                      : "Margem OK"
                  }
                  position={getPositionBottom(index, marginOk)}
                  color={"colored"}
                  textType={
                    index === 0
                      ? ""
                      : index === Object.keys(marginOk).length - 1
                      ? marginOk[key] === 0
                        ? "error"
                        : "bold"
                      : marginOk[key] === -1
                      ? ""
                      : marginOk[key] === 0
                      ? "error"
                      : "bold"
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {false && (
        <>
          <div className="liftworld-table-title-wrapper">VALORES MINIMOS</div>
          <div className="lifworld-table-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(tableTitle).map((key, index) => (
                <div
                  className={
                    `${"liftworld-table-header-element"} ` + getPosition(index)
                  }
                >
                  {tableTitle[key]}
                </div>
              ))}
            </div>
            <div>
              {factureArrayStateVerify &&
                Object.keys(factureArrayStateVerify).length !== 0 &&
                factureArrayStateVerify.map((item, index) => {
                  return (
                    <div style={{ display: "flex" }}>
                      {Object.keys(factureArrayStateVerify[index]).map(
                        (key, i) => (
                          <TableTextbox
                            value={
                              i === 0
                                ? factureArrayStateVerify[index][key]
                                : isNaN(
                                    parseFloat(
                                      factureArrayStateVerify[index][key]
                                    )
                                  ) ||
                                  factureArrayStateVerify[index][key] ===
                                    undefined
                                ? "0,00 €"
                                : numberWithCommas(
                                    parseFloat(
                                      factureArrayStateVerify[index][key]
                                    ).toFixed(2)
                                  ) + "€"
                            }
                            position={"middle"}
                            number={i != 0 ? "number" : ""}
                          />
                        )
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="liftworld-table-header-wrapper">
              <div className="liftworld-table-header-wrapper">
                {Object.keys(totalFactureVerify).map((key, index) => (
                  <TableTextbox
                    value={
                      index === 0
                        ? totalFactureVerify[key]
                        : isNaN(parseFloat(totalFactureVerify[key])) ||
                          totalFactureVerify[key] === undefined
                        ? "0,00 €"
                        : numberWithCommas(
                            parseFloat(totalFactureVerify[key]).toFixed(2)
                          ) + "€"
                    }
                    position={"middle"}
                    color={"colored"}
                    number={index != 0 ? "number" : ""}
                  />
                ))}
              </div>
            </div>
            <div>
              {costArrayStateVerify &&
                Object.keys(costArrayStateVerify).length !== 0 &&
                costArrayStateVerify.map((item, index) => {
                  return (
                    <div style={{ display: "flex" }}>
                      {Object.keys(costArrayStateVerify[index]).map(
                        (key, i) => (
                          <TableTextbox
                            value={
                              i === 0
                                ? costArrayStateVerify[index][key]
                                : isNaN(
                                    parseFloat(costArrayStateVerify[index][key])
                                  ) ||
                                  costArrayStateVerify[index][key] === undefined
                                ? "0,00 €"
                                : numberWithCommas(
                                    parseFloat(
                                      costArrayStateVerify[index][key]
                                    ).toFixed(2)
                                  ) + "€"
                            }
                            position={"middle"}
                            number={i != 0 ? "number" : ""}
                          />
                        )
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="liftworld-table-header-wrapper">
              <div className="liftworld-table-header-wrapper">
                {Object.keys(totalCostVerify).map((key, index) => (
                  <TableTextbox
                    value={
                      index === 0
                        ? totalCostVerify[key]
                        : isNaN(parseFloat(totalCostVerify[key])) ||
                          totalCostVerify[key] === undefined
                        ? "0,00 €"
                        : numberWithCommas(
                            parseFloat(totalCostVerify[key]).toFixed(2)
                          ) + "€"
                    }
                    position={getPositionBottom(index, totalCostVerify)}
                    color={"colored"}
                    number={index != 0 ? "number" : ""}
                  />
                ))}
              </div>
            </div>
            <div className="liftworld-proposal-wrapper-padding">
              <div className="liftworld-table-header-wrapper">
                <div className="liftworld-table-header-wrapper">
                  {Object.keys(incomeVerify).map((key, index) => (
                    <TableTextbox
                      value={
                        index === 0
                          ? incomeVerify[key]
                          : isNaN(parseFloat(incomeVerify[key])) ||
                            incomeVerify[key] === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(incomeVerify[key]).toFixed(2)
                            ) + "€"
                      }
                      position={getPositionTop(index, incomeVerify)}
                      color={"colored"}
                      number={index != 0 ? "number" : ""}
                    />
                  ))}
                </div>
              </div>
              <div className="liftworld-table-header-wrapper">
                <div className="liftworld-table-header-wrapper">
                  {Object.keys(percIncomeVerify).map((key, index) => (
                    <TableTextbox
                      value={
                        index === 0
                          ? percIncomeVerify[key]
                          : isNaN(parseFloat(percIncomeVerify[key])) ||
                            percIncomeVerify[key] === undefined
                          ? "0,00 %"
                          : numberWithCommas(
                              parseFloat(percIncomeVerify[key]).toFixed(2)
                            ) + "%"
                      }
                      position={getPositionBottom(index, percIncomeVerify)}
                      color={"colored"}
                      number={index != 0 ? "number" : ""}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="liftworld-proposal-wrapper-padding">
              <div className="liftworld-table-header-wrapper">
                <div className="liftworld-table-header-wrapper">
                  {Object.keys(marginVerify).map((key, index) => (
                    <TableTextbox
                      value={
                        index === 0
                          ? marginVerify[key]
                          : isNaN(parseFloat(marginVerify[key])) ||
                            marginVerify[key] === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(marginVerify[key]).toFixed(2)
                            ) + "€"
                      }
                      position={getPositionTop(index, marginVerify)}
                      color={"colored"}
                      number={index != 0 ? "number" : ""}
                    />
                  ))}
                </div>
              </div>
              <div className="liftworld-table-header-wrapper">
                <div className="liftworld-table-header-wrapper">
                  {Object.keys(percMarginVerify).map((key, index) => (
                    <TableTextbox
                      value={
                        index === 0
                          ? percMarginVerify[key]
                          : isNaN(parseFloat(percMarginVerify[key])) ||
                            percMarginVerify[key] === undefined
                          ? "0,00 %"
                          : numberWithCommas(
                              parseFloat(percMarginVerify[key]).toFixed(2)
                            ) + "%"
                      }
                      color={"colored"}
                      position={getPositionBottom(index, marginOk)}
                      number={index != 0 ? "number" : ""}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ProposalResumeTable;
