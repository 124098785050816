import React from "react";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";

const HelperContent = ({ t, setShowHelperContent, experiencedUser, show }) => {
  let showComponent = "none";
  if (show || !experiencedUser) {
    showComponent = "block";
  }

  return (
    <div
      className="main-card-v2"
      style={{ padding: "0", display: showComponent }}
    >
      <Card bsPrefix="card-flat">
        <Card.Header
          style={{ minHeight: "50px", padding: "16px" }}
          className="justify-content-between"
        >
          {experiencedUser ? (
            <h6>
              <i
                className="icon-left mr-2"
                onClick={() => {
                  setShowHelperContent(false);
                }}
              />{" "}
              Return
            </h6>
          ) : (
            <span>
              <h4 style={{ margin: "0" }}>
                {t("datacapture.general.chooseNextToProceed")}
              </h4>
            </span>
          )}
        </Card.Header>
        <Card.Body style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ height: "20vh" }}>
            {t("datacapture.general.modalHelperMessage")}
          </div>
        </Card.Body>
        {/*experiencedUser && (
          <Card.Footer style={{ minHeight: "50px" }}></Card.Footer>
        ) ESleptsova*/}
      </Card>
    </div>
  );
};
export default withNamespaces()(HelperContent);
