import React, { useState, useEffect, useCallback } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "~/assets/css/scheduler.css";
import "../styles.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import { GoPlusCircle, GoTrash } from "react-icons/go";
import { TbBeach } from "react-icons/tb";
import { isSameDay } from "date-fns";
import { convertToEvents, marcacaoEvents } from "../utils";
import Year from "../Year";

import { TextField } from "@material-ui/core";

import {
  Button as ButtonBoot,
  Card,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";

import Toolbar from "./Toolbar";

const Calendario = (props) => {
  const {
    allEvents,
    setAllEvents,
    isEditable,
    selectedLocale,
    localizer,
    exportBtn,
    handleExport,
    hideChangeView,
    setShowModal,
    setAllEventsTemp,
    callUpdateMarcacaoFerias,
    setShowModalToRemove,
    t,
  } = props;
  const [loadNewDateSelected, setLoadNewDateSelected] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(new Date());
  const [defaultView, setDefaultView] = useState("year");
  const [singleDaySelection, setSingleDaySelection] = useState("single");
  const [starEndtMonth, setstarEndtMonth] = useState({
    start: "",
    end: "",
  });
  const [rangesState, setrangesState] = useState({});
  const [blockIntervalSelections, setBlockIntervalSelections] = useState(false);

  const getDatesFromDays = (date) => {
    setDateSelected(date);
    const { start, end } = getMonthRange(date);
    rangeChange({ start, end });
    setstarEndtMonth({
      start: moment(dateSelected).startOf("month").startOf("week"),
      end: moment(dateSelected).endOf("month").endOf("week"),
    });
    if (date.getFullYear() !== loadNewDateSelected.getFullYear()) {
      setLoadNewDateSelected(date);
      callUpdateMarcacaoFerias(date);
    }
  };

  const handleSingleDaySelection = (event, newAlignment) =>
    newAlignment && setSingleDaySelection(newAlignment);

  const handleSendDays = () => {
    const eventsDays = allEvents.filter(
      (event) => event.type === "feriasmarked"
    );
    if (eventsDays.length > 0) {
      setAllEventsTemp(eventsDays);
      setShowModal(true);
    }
  };

  const getMonthRange = (selectedDate) => {
    const startOfMonth = moment(selectedDate).startOf("month").toDate();
    const endOfMonth = moment(selectedDate).endOf("month").toDate();
    return { start: startOfMonth, end: endOfMonth };
  };

  const dayPropGetter = useCallback(
    (date) => {
      const eventOnDate = allEvents.find((event) =>
        moment(event.start).isSame(date, "day")
      );
      const { start, end } = getMonthRange(dateSelected);

      const isOutsideRange = date < start || date > end;
      const dayOfWeek = moment(date).day();
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

      const eventStyles = {
        feriasPendentes: {
          backgroundColor: "transparent",
          color: "#666",
          pointerEvents: "none",
        },
        ferias: {
          backgroundColor: "transparent",
          color: "#666",
          pointerEvents: "none",
        },
        feriados: {
          backgroundColor: "#F8B4B4",
          color: "#666",
          pointerEvents: "none",
        },
        diasAprovar: {
          backgroundColor: "rgb(68, 164, 155)",
          color: "#666",
          pointerEvents: "none",
        },
      };

      const defaultStyle = { style: {} };
      const outsideRangeStyle = {
        style: {
          backgroundColor: "#F9F8F8",
          color: "#CECECE",
          pointerEvents: "none",
        },
      };
      const weekendStyle = {
        style: {
          backgroundColor: "#E2E2E2",
          color: "#666",
          pointerEvents: "none",
        },
      };

      // if (isOutsideRange) return outsideRangeStyle;
      if (isWeekend) return weekendStyle;
      if (eventOnDate)
        return { style: eventStyles[eventOnDate.type] || defaultStyle.style };

      return defaultStyle;
    },
    [allEvents, dateSelected]
  );

  const confirmCheckDay = (slotInfo) => {
    const dayMarked = marcacaoEvents(slotInfo, t).flatMap(convertToEvents)[0];
    setAllEvents((prevEvents) => {
      const eventIndex = prevEvents.findIndex(
        (event) =>
          event.type === "feriasmarked" &&
          moment(event.start).isSame(dayMarked.start, "day")
      );
      if (eventIndex >= 0) {
        return prevEvents.filter((_, index) => index !== eventIndex);
      } else {
        return [...prevEvents, dayMarked];
      }
    });
  };

  const onDrillDown = (slotInfo) => {
    if (!isEditable || slotInfo.getMonth() !== dateSelected.getMonth()) {
      return;
    }
    const eventOnThisDay = allEvents.find((event) =>
      moment(event.start).isSame(slotInfo, "day")
    );

    const shouldRemoveDays =
      eventOnThisDay &&
      ["ferias", "datasMarcacoes", "feriasPendentes"].includes(
        eventOnThisDay.type
      );

    if (eventOnThisDay && eventOnThisDay.type !== "feriasmarked") {
      if (shouldRemoveDays) {
        setAllEventsTemp([eventOnThisDay]);
        setShowModalToRemove(true);
      }
      return;
    }
    confirmCheckDay(slotInfo);
  };

  const eventPropGetter = (event) => {
    const defaultStyle = {
      border: event.color ? `2px solid ${event.color}` : "2px solid blue",
      cursor: "default",
      color: event.color || "blue",
      backgroundColor: event.color
        ? `${event.color}${Math.floor(0.3 * 255).toString(16)}`
        : "#0000ff4d", // blue com opacidade
    };

    const typeStyles = {
      ferias: {},
      feriasPendentes: {
        border: "2px solid rgb(206, 186, 59)",
      },
      datasMarcacoes: {},
      feriasmarked: {},
      diasAprovar: {},
    };

    const style = { ...defaultStyle, ...(typeStyles[event.type] || {}) };
    return event.type in typeStyles ? { className: event.type, style } : null;
  };

  function CustomDateHeader({ label, onDrillDown, isOffRange }) {
    const padNumber = (number) => (number < 10 ? `0${number}` : number);
    const hasEvents = allEvents.some((event) => {
      return (
        event.start.getMonth() === dateSelected.getMonth() &&
        padNumber(event.start.getDate().toString()) === label &&
        event.type !== "feriasmarked"
      );
    });

    const hasEventsMark = allEvents.some((event) => {
      return (
        event.start.getMonth() === dateSelected.getMonth() &&
        padNumber(event.start.getDate().toString()) === label &&
        event.type === "feriasmarked"
      );
    });

    const hasEventsMarkDiasMarcados = allEvents.some((event) => {
      return (
        event.start.getMonth() === dateSelected.getMonth() &&
        padNumber(event.start.getDate().toString()) === label &&
        (event.type === "ferias" ||
          event.type === "datasMarcacoes" ||
          event.type === "feriasPendentes")
      );
    });

    return (
      <div onClick={onDrillDown} className="custom-date-header">
        <div className="dh-item header-left">
          {!isOffRange &&
            !hasEvents &&
            (hasEventsMark ? (
              <MdOutlineRemoveCircleOutline color="#b1b1b1" />
            ) : (
              <GoPlusCircle color="#b1b1b1" />
            ))}
          {!isOffRange && hasEventsMarkDiasMarcados && (
            <GoTrash color="#b1b1b1" />
          )}
        </div>
        <div className="dh-item header-right">
          <a>{label}</a>
        </div>
      </div>
    );
  }

  const handleRestartMarkup = () => {
    const filteredEvents = allEvents.filter(
      (event) =>
        event.type !== "feriasmarked" &&
        event.type !== "hover" &&
        event.type !== "intervals"
    );
    setAllEvents(filteredEvents);
  };

  function CustomToolbar({
    localizer: { messages },
    label,
    onNavigate,
    onView,
  }) {
    const navigate = (action) => {
      onNavigate(action);
    };
    const changeView = (view) => {
      onView(view);
    };
    return (
      <Toolbar
        t={t}
        allEvents={allEvents}
        handleSendDays={handleSendDays}
        isEditable={isEditable}
        blockIntervalSelections={blockIntervalSelections}
        handleSingleDaySelection={handleSingleDaySelection}
        singleDaySelection={singleDaySelection}
        defaultView={defaultView}
        hideChangeView={hideChangeView}
        navigate={navigate}
        changeView={changeView}
        label={label}
        exportBtn={exportBtn}
        handleExport={handleExport}
        handleRestartMarkup={handleRestartMarkup}
      />
    );
  }

  const eventosDoDia = (date) =>
    allEvents.filter((evento) => isSameDay(evento.start, date));

  function Event({ event }) {
    // Função para criar o componente comum
    const createCommonComponent = (content, additionalStyle = {}) => (
      <div>
        <TbBeach
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: event.color,
            ...additionalStyle,
          }}
        />
        <span
          className="eventSpan"
          style={{ display: "inline-flex", marginTop: "2px" }}
        >
          {content}
        </span>
      </div>
    );

    switch (event.type) {
      case "feriasmarked":
      case "feriasPendentes":
      case "datasMarcacoes":
        return createCommonComponent(event.title);
      case "intervals":
      case "ferias":
        const eventos = eventosDoDia(event.start);
        if (event.type === "intervals" && eventos.length > 2) {
          // Lógica específica para "intervals"
          return (
            <div
            //   onClick={(e) =>
            //     handleClick(e, eventos)
            //   } /* Estilos e título omitidos para brevidade */
            >
              + {eventos.length}
            </div>
          );
        } else if (event.type === "ferias" && isEditable) {
          // Lógica específica para "ferias" editável
          return createCommonComponent(t("portalrh.scheduler.ferias"));
        }
        return createCommonComponent(event.title);
      default:
        return null;
    }
  }

  const rangeChange = useCallback(
    (range) => {
      setrangesState(range);
      return {};
    },
    [allEvents]
  );

  return (
    <>
      {isEditable ? (
        <Calendar
          localizer={localizer}
          events={allEvents}
          date={dateSelected}
          toolbar={true}
          defaultView={defaultView}
          setAllEvents={setAllEvents}
          setAllEventsTemp={setAllEventsTemp}
          isEditable={isEditable}
          t={t}
          views={{
            day: false,
            week: false,
            month: true,
            year: Year,
          }}
          max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
          min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
          startAccessor="start"
          endAccessor="end"
          selectable={true}
          popup
          onNavigate={getDatesFromDays}
          onView={(e) => setDefaultView(e)}
          dayPropGetter={dayPropGetter}
          onDrillDown={onDrillDown}
          singleDaySelection={singleDaySelection}
          messages={{ year: "Year" }}
          tooltipAccessor={(e) => e.name}
          onRangeChange={rangeChange}
          eventPropGetter={(e) => eventPropGetter(e)}
          components={{
            event: Event,
            month: {
              dateHeader: CustomDateHeader,
            },
            toolbar: CustomToolbar,
          }}
        />
      ) : null}
    </>
  );
};

export default withNamespaces()(Calendario);
