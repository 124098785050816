import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/scss/_selectLanguageMenu.scss";
import arrowDownPink from "../assets/img/icons/arrow-down.png";
import arrowUpPink from "../assets/img/icons/arrow-up.png";
import i18n from "~/i18n";
import { updateUserSettings } from "~/pages/User/actions";
import "../assets/scss/_selectLanguageMenu.scss";


function SelectLanguageMenu({ languages, selected, setSelected }) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  const { userSettings } = useSelector(
    (state) => state.userSettingsReducer
  );

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const payload = userSettings;
    payload.language = lng;
    dispatch(updateUserSettings(payload));
  };

  return (
    <div className="easypay-language-dropdown">
      <div
        className="easypay-language-dropdown-button"
        onClick={() => setIsActive(!isActive)}
      >
        {selected}
        <img
          className="easypay-language-button-img"
          src={isActive ? arrowUpPink : arrowDownPink}
          height="6px"
          width="12px"
        />
      </div>
      {isActive && (
        <div className="easypay-language-dropdown-content">
          {languages.map((option, index) => (
            <div
              onClick={() => {
                setSelected(option);
                setIsActive(false);
                handleChangeLanguage(option.toLowerCase());
              }}
              className={
                index === languages.length - 1
                  ? `${"easypay-language-dropdown-item"} easypay-last`
                  : "easypay-language-dropdown-item"
              }
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectLanguageMenu;
