import {
  SET_DATACAPTURE_CONFIGURATION,
  CLEAR_DATACAPTURE_CONFIGURATION,
  PERSIST_DATACAPTURE_CONFIGURATION,
  SUCCESS_PERSIST_DATACAPTURE_CONFIGURATION,
  DELETE_DATACAPTURE_CONFIGURATION
} from "./actionTypes";

const initialState = {
  datacaptureConfiguration: [],
  successPersistDataCaptureConfiguration: false
};

export const datacaptureConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {

    case PERSIST_DATACAPTURE_CONFIGURATION:
      return {
        ...state,
        successPersistDataCaptureConfiguration: false
      };
    case SUCCESS_PERSIST_DATACAPTURE_CONFIGURATION:
      return {
        ...state,
        successPersistDataCaptureConfiguration: true
      };
    case SET_DATACAPTURE_CONFIGURATION:
      return {
        ...state,
        datacaptureConfiguration: action.datacaptureConfiguration,
      };
    case DELETE_DATACAPTURE_CONFIGURATION:
      return {
        ...state,
        successPersistDataCaptureConfiguration: false
      };
    case CLEAR_DATACAPTURE_CONFIGURATION:
      return { ...state, datacaptureConfiguration: initialState.datacaptureConfiguration };
    default:
      return state;
  }
};
