import React, { useRef, useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_buttons.scss";
import uploadIcon from "../assets/img/icons/icon-upload.png";
import uploadIconDisabled from "../assets/img/icons/icon-upload-2.png";
import { useDispatch } from "react-redux";
import { transformFormData } from "~/components/CreateForm/utils";
import oauth from "~/utils/oauth";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import uuid from "uuid/v1";
const devLogConsole = require("~/utils/devLog");
const Button = ({
  text,
  variant = "easypay-button-secondary-icon",
  disabled = false,
  disabledColored = false,
  t,
  align,
}) => {
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [fileExpires, setFileExpires] = useState("");
  const [fileEmitted, setFileEmitted] = useState("");
  const [fileMime, setFileMime] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [fileContent, setFileContent] = useState("");

  const field = {
    type: "link",
    text: "Adicionar::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='useDocumentAccount';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  useEffect(() => {
    if (document.dados["documentIBAN"]) {
      document.dados["documentIBAN"].value = fileName;
    }
    if (document.dados["documentIBANContent"]) {
      devLogConsole(fileContent);
      document.dados["documentIBANContent"].value = fileContent;
    }
    if (document.dados["documentIBANExpires"]) {
      document.dados["documentIBANExpires"].value = fileExpires;
    }
    if (document.dados["documentIBANEmitted"]) {
      document.dados["documentIBANEmitted"].value = fileEmitted;
    }
    if (document.dados["documentIBANExtension"]) {
      document.dados["documentIBANExtension"].value = fileExtension;
    }
    if (document.dados["documentIBANMime"]) {
      document.dados["documentIBANMime"].value = fileMime;
    }
    if (document.dados["documentIBAN"].value != "") submit();
  },[fileContent]);

  function submit() {
    if (field.href.includes("javascript:")) {
      if (field.href.includes("document.dados.op.value='")) {
        const array = field.href.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.buttonResult.value='")) {
        const array = field.href.split("document.dados.buttonResult.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.buttonResult.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.acção.value='")) {
        const array = field.href.split("document.dados.acção.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.acção.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.voltar.value='")) {
        const array = field.href.split("document.dados.voltar.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.voltar.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.trocarDeContaTink.value='")) {
        const array = field.href.split(
          "document.dados.trocarDeContaTink.value='"
        );
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.trocarDeContaTink.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.botao_accao.value='")) {
        const array = field.href.split("document.dados.botao_accao.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.botao_accao.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.mostrardoc.value='")) {
        const array = field.href.split("document.dados.mostrardoc.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.mostrardoc.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.cancelardoc.value='")) {
        const array = field.href.split("document.dados.cancelardoc.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.cancelardoc.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.toggleCodigoCertidao.value='")) {
        const array = field.href.split(
          "document.dados.toggleCodigoCertidao.value='"
        );
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.toggleCodigoCertidao.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.botao.value='")) {
        const array = field.href.split("document.dados.botao.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.botao.value = array2[0];
          }
        }
      }

      if (document.dados?.cmdPath && document.dados?.cmdHost) {
        if (document.dados?.acção.value === "iniciarChaveMovelDigital") {
          document.dados.cmdHost.value = window.location.origin;
          document.dados.cmdPath.value = window.location.hash.slice(1); //To remove # that causes iflow some problems
        }
      }

      if (field.href.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postEasypayFormData(formdata, url));
      }
    }
  }

  const inputFile = useRef(null);
  function onButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  async function getBase64(file, _callback) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      devLogConsole(base64String);
      setFileContent(base64String);
    };
    reader.readAsDataURL(file);
    _callback();
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var currentFile = event.target.files[0];
    setFileName(currentFile.name);
    getBase64(currentFile, function () {
     // setFileName(currentFile.name);
      const emitted_at = currentFile.lastModifiedDate;
      const expires_at = new Date(
        new Date(currentFile.lastModifiedDate).setFullYear(
          new Date().getFullYear() + 1
        )
      );
      const is_private = true;
      const extension = "." + currentFile.name.split(".")[1];
      var newExpires = expires_at.toISOString().split(".")[0] + "Z";
      var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";
      const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";

      setFileEmitted(newEmitted);
      setFileExpires(newExpires);
      setFileMime(type);
      setFileExtension(extension);
    });
  };

  return (
    text !== "hidden-button" && (
      <div
        className="easypay-button-wrapper"
        key={uuid()}
        style={{ justifyContent: align }}
      >
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <button
          className={`${variant} ${
            disabledColored ? "easypay-button-disabledColored" : ""
          } `}
          disabled={disabled || disabledColored}
          onClick={onButtonClick}
          type="button"
        >
          <span
            className={`easypay-button-icon-text ${
              variant.includes("easypay-document") ? "easypay-22px-text" : ""
            } ${
              variant.includes("easypay-button-add") ? "easypay-20px-text" : ""
            }`}
          >
            {t(text)}
            {(variant === "easypay-button-secondary-icon" ||
              variant === "easypay-button-secondary-icon-large") && (
              <img
                className={`easypay-button-upload-img ${
                  disabledColored ? "easypay-button-img-disabledColored" : ""
                }`}
                src={disabled ? uploadIconDisabled : uploadIcon}
                alt="Upload Icon"
              />
            )}
          </span>
        </button>
      </div>
    )
  );
};

export default withNamespaces()(Button);
