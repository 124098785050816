import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { setUserData, setCollaboratorData, setCodesSimulator } from "./actionTypes";
import { RH_MIDDLEWARE } from "~/utils/constants";
const devLogConsole = require("~/utils/devLog");
export function* saveUserData({ data }) {
  devLogConsole("saveUserData");
  try {
    const resp = yield http.post("/api/mongo/simulators", data);
    devLogConsole(resp);
  } catch (error) {
    devLogConsole(error);
  }
}

export function* saveUserDataExcel({data}){
  try {
    const resp = yield http.post("/api/fecho-contas", data, RH_MIDDLEWARE);
    devLogConsole(resp);
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getCodesSimulator() {
  try {
    const resp = yield http.get("/api/fecho-contas/codes", RH_MIDDLEWARE);
    yield put(setCodesSimulator(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getUserData() {
  try {
    const resp = yield http.get("/api/mongo/simulators");
    yield put(setUserData(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* upDateUserData({ id, data }) {
  try {
    const resp = yield http.put(`/api/mongo/simulators/${id}`, data);
    devLogConsole(resp);
  } catch (error) {
    devLogConsole(error);
  }
}

export function* deleteUserData({ id }) {
  try {
    const resp = yield http.delete(`/api/mongo/simulators/${id}`);
    yield put(setUserData(resp.data));
    devLogConsole(resp);
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getCollaboratorData({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/simulator/${id}/${companyId}`,
      RH_MIDDLEWARE
    );
    devLogConsole(resp.data, "sagaaaData");
    yield put(setCollaboratorData(resp.data));
  } catch (error) {
    devLogConsole(error);
  }
}
