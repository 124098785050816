import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import GeneralDocument from "./components/GeneralDocument";
import ProtectedPage from "~/components/security/ProtectedPage";
import AutomatedDatacapture from "./components/AutomatedDatacapture";
import WidgetContainer from "~/components/WidgetContainer";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";

import ContentContainer from "~/containers/ContentContainer";
import Tasks from "~/components/Tasks";
import Processes from "~/components/Processes";
import {
  getDatacaptureConfiguration,
  clearDatacaptureConfiguration,
} from "~/store/ducks/datacaptureConfiguration/actionTypes";

import {
  getMenusByApplication,
  addItemsToMenu,
} from "~/store/ducks/applicationMenu/actionTypes";
import { clearBatchs, findAllBatchs } from "~/pages/DataCapture/actions";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import { find } from "lodash";

import { AUTOMATED_DATACAPTURE_APP, BPM_MIDDLEWARE } from "~/utils/constants";
const devLogConsole = require("~/utils/devLog");
const ADataCapture = (props) => {
  const { isMongoOn } = props;
  const dispatch = useDispatch();

  const { batchs, isLoadingBatchs } = useSelector(
    (state) => state.dataCaptureReducer
  );
  const { user } = useSelector((state) => state.globalReducer);
  const { datacaptureConfiguration } = useSelector(
    (state) => state.datacaptureConfigurationReducer
  );
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );
  //devLogConsole(rawMenus)
  let { path } = useRouteMatch();
  const history = useHistory();

  const [simpleFlow, setSimpleFlow] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadModalOpenAi, setShowUploadModalOpenAi] = useState(false);
  const isFirstRun = useRef(true);

  useEffect(() => {
    dispatch(findAllBatchs());
    return () => dispatch(clearBatchs());
  }, []);

  /*Get raw menus from iFlow (level 2 menus)*/
  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      dispatch(getMenusByApplication(username, AUTOMATED_DATACAPTURE_APP));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getDatacaptureConfiguration(user.organization.id));
    return () => dispatch(clearDatacaptureConfiguration());
  }, [user && user.organization && user.organization.id]);

  useEffect(() => {
    if (datacaptureConfiguration.length > 0) {
      if (
        datacaptureConfiguration.some(
          (config) =>
            config.flowId != "" &&
            config.flowId != null &&
            config.flowId != undefined
        )
      ) {
        setSimpleFlow(false);
      } else setSimpleFlow(true);
    } else {
      setSimpleFlow(true);
    }
  }, [datacaptureConfiguration]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const handleRedirect = (batchs) => {
      if (batchs.length > 0) {
        let docs = [];
        batchs.forEach((batch) => {
          batch.documents.forEach((docs_) => {
            docs.push(docs_);
          });
        });
        if (docs.length < 1) {
          history.push("/uniksystem/adatacapture/first-upload");
        } else {
          menu.forEach((menuItem)=>{
            if (menuItem.extraConfiguration) {
              const extraConfiguration_ = JSON.parse(
                menuItem.extraConfiguration || false
              );
              const applicationRoute_ = JSON.parse(
                (extraConfiguration_ && extraConfiguration_.applicationRoute) ||
                  false
              );
              if (applicationRoute_) {
                const location = { pathname: `/uniksystem/${menuItem.route}` };
                history.push(location);
              }
              // } else {
              //   history.push("/uniksystem/adatacapture/submit-document");
              // }
            }
          })

        }
      } else {
        history.push("/uniksystem/adatacapture/first-upload");
      }
    };

    if (simpleFlow) {
      handleRedirect(batchs);
    } else {
      if (
        !window.location.href.includes("adatacapture/tasks") &&
        !window.location.href.includes("adatacapture/pesquisar") &&
        !window.location.href.includes("adatacapture/process") &&
        !window.location.href.includes("adatacapture/submit-document") &&
        !window.location.href.endsWith("adatacapture")
      ) {
        handleRedirect(batchs);
      }
    }
  }, [window.location.href, simpleFlow, batchs]);

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.adatacapture.processos" });
    let items = [];
    let submenus;
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      submenus = extraConfiguration_.submenus
        ? extraConfiguration_.submenus
        : null;
      if (submenus) {
        Object.keys(submenus).forEach((key) => {
          //check if submenu has children
          let hasChildren = false;
          if (submenus[key].length == 0) hasChildren = false;
          else {
            submenus[key].every((flowid) => {
              const res = rawMenus.every((rm) => {
                //find flowid
                if (rm.url) {
                  let arr = rm.url.split("flowid=");
                  const flowid_ = arr[1];
                  if (flowid_ == flowid) {
                    hasChildren = true;
                    return false;
                  }
                  return true;
                }
                return true;
              });
              return res;
            });
          }
          if (hasChildren) {
            items.push({
              id: key,
              name: key,
              submenu: true,
              route: `${AUTOMATED_DATACAPTURE_APP}/processos/submenu/${key}`,
              menuParent: processosMenu ? processosMenu.id : null,
            });
          }
        });
      }
      const getProcessosMenuId = () =>
        processosMenu ? processosMenu.id : null;
      const getSubMenuParentId = (url) => {
        if (url) {
          let arr = url.split("flowid=");
          const flowId = arr[1];
          let id = getProcessosMenuId();
          Object.keys(submenus).forEach((key) => {
            if (submenus[key].some((i) => i == flowId)) {
              id = key;
            }
          });
          return id;
        } else return getProcessosMenuId();
      };
      rawMenus.forEach((item) => {
        items.push({
          name: item.label,
          url: item.url,
          route: `${AUTOMATED_DATACAPTURE_APP}/processos/form/${item.label}`,
          menuParent: submenus
            ? getSubMenuParentId(item.url)
            : getProcessosMenuId(),
        });
      });
      dispatch(addItemsToMenu(items));
    }
  }, [rawMenus, menu, dispatch]);

  let docs = [];
  const batchs_ = batchs.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );
  batchs_.forEach((batch) => {
    batch.documents.forEach((doc) => {
      docs.push(doc);
    });
  });

  const pendingDocs = docs.filter((doc) => {
    return (
      doc.statusV1 === "PENDING" ||
      doc.statusV1 === "LOADED" ||
      doc.statusV1 === "WAITING_SUBMISSION"
    );
  });

  useInterval(() => {
    if (
      pendingDocs ||
      (!simpleFlow &&
        docs.some((doc) => doc.statusV1 === DocumentStatus.REVIEWED))
    ) {
      !showUploadModal && !showUploadModalOpenAi &&
        window.location.href.includes("submit-document") &&
        dispatch(findAllBatchs());
    }
  }, 1000 * 60 * 2);

  return (
    <>
      <Switch>
        {!simpleFlow && (
          <Route path={`${path}/tasks`} exact>
            <>
              <ApplicationMenu applicationName="adatacapture" />
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <ContentContainer>
                  <Tasks app={"adatacapture"} className="main-card-v2" />
                </ContentContainer>
              </ProtectedPage>
            </>
          </Route>
        )}
        {!simpleFlow && (
          <Route exact path={`${path}/processos`}>
            <>
              <ApplicationMenu applicationName="adatacapture" />
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <div></div>
              </ProtectedPage>
            </>
          </Route>
        )}
        {!simpleFlow && (
          <Route path={`${path}/processos/submenu`}>
            <>
              <ApplicationMenu applicationName="adatacapture" />
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <div></div>
              </ProtectedPage>
            </>
          </Route>
        )}
        {!simpleFlow && (
        <Route exact path={`${path}/pesquisar`}>
          <ApplicationMenu applicationName="adatacapture" />
          <ProtectedPage
            roles={["ADMIN", "ADATACAPTUREUser"]}
            //permissions={[{ label: "app-adatacapture-menu-pesquisa" }]}
          >
            <ContentContainer>
              <Processes app={"adatacapture"} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        )}
        {!simpleFlow && (
          <Route path={`${path}/processos/form`}>
            <>
              <ApplicationMenu applicationName="adatacapture" />
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <ProcessosFormHandler
                  app={AUTOMATED_DATACAPTURE_APP}
                  className="main-card-v2"
                />
              </ProtectedPage>
            </>
          </Route>
        )}

        {!simpleFlow && (
          <Route path={`${path}/search`}>
            <>
              <ApplicationMenu applicationName="adatacapture" />
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <ContentContainer>
                  <Processes app={"adatacapture"} className="main-card-v2" />
                </ContentContainer>
              </ProtectedPage>
            </>
          </Route>
        )}
        <Route exact path={`${path}/submit-document`}>
          <>
            {!simpleFlow && <ApplicationMenu applicationName="adatacapture" />}
            {
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <AutomatedDatacapture
                  simpleFlow={simpleFlow}
                  datacaptureConfiguration={datacaptureConfiguration}
                  showUploadModal={showUploadModal}
                  setShowUploadModal={setShowUploadModal}
                  showUploadModalOpenAi={showUploadModalOpenAi}
                  setShowUploadModalOpenAi={setShowUploadModalOpenAi}
                />
              </ProtectedPage>
            }
          </>
        </Route>
        <Route exact path={`${path}/first-upload`}>
          <>
            {!isLoadingBatchs && (
              <ProtectedPage roles={["ADMIN", "ADATACAPTUREUser"]}>
                <GeneralDocument
                  experiencedUser={false}
                  datacaptureConfiguration={datacaptureConfiguration}
                />
              </ProtectedPage>
            )}
          </>
        </Route>
        {!simpleFlow && (
          <Route path={path} exact>
            <ApplicationMenu applicationName="adatacapture" />
            {isMongoOn && (
              <ProtectedPage roles={["ADMIN", "ADataCaptureUser"]}>
                <ContentContainer>
                  <WidgetContainer />
                </ContentContainer>
              </ProtectedPage>
            )}
          </Route>
        )}
      </Switch>
    </>
  );
};

export default ADataCapture;
