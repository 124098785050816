import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_backOfficeRepLegTable.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import Table from "~/pages/Easypay/components/Table";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import Dropdown from "./InputDropdown";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import ButtonUpload from "./ButtonUpload";
import {
  postInquiry,
  getInquiry,
  getEntityPdf,
  setDoneScreenings,
  setDetailFile,
  setFileScreen,
  saveDetailFile,
  getDetailFile,
  setDoneErrorCheckpep
} from "~/store/ducks/onboarding/actionTypes";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";

const Screenings = ({ t, data, pid }) => {
  const [usersList, setUsersList] = useState([]);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isModalRequiredShowing, setIsModalRequiredShowing] = useState(false);
  const [isModalWaitingShowing, setIsModalWaitingShowing] = useState(false);
  const [currentName, setCurrentName] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const dispatch = useDispatch();
  const addButtonField = {
    type: "link",
    text: "",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitMain';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function generateScreenings() {
    if(currentType === null || currentName === null)
    {
      setIsModalRequiredShowing(true)
      return
    }
    const payload = {
      subjectName: currentName,
      subjectTypeText:
        currentType === "Individual" ? "IndividualName" : "OrganizationName",
      countryISO: "PT",
      streetAddressLine: null,
      townName: null,
      postalCode: null,
      birthDate: null,
      screeningMonitoringMode: "NoMonitoring",
    };
    dispatch(postInquiry(payload, "61798db3a0ac1d6257017e87"));
    setIsModalWaitingShowing(true);
  }

  const {
    postInquiryResponse,
    getInquiryResponse,
    entityPdf,
    doneScreenings,
    detailFile,
    screen,
    doneErrorCheckpep
  } = useSelector((state) => state.onboardingReducer);

  const { user } = useSelector((state) => state.globalReducer);
  
  useEffect(() => {
    if (Object.keys(postInquiryResponse).length > 0) {
      if (postInquiryResponse?.inquiries) {
        const payload = {
          information: postInquiryResponse?.inquiries[0]?.inquiryID,
          apiSettings: "61798db3a0ac1d6257017e87",
          userId: user?.id,
        };

        dispatch(getInquiry(payload));
      }
    }
  }, [postInquiryResponse]);

  useEffect(() => {
    if (entityPdf != null && entityPdf != undefined) {
      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${
        entityPdf?.entities[0]?.contents?.contentObject
      }`;
      a.download = "Details.pdf";
      a.click();
    }
  }, [entityPdf]);

  useEffect(() => {
    if (doneScreenings === true) {
      setIsModalWaitingShowing(false);
      setIsModalShowing(true);
      dispatch(setDoneScreenings(false));
    }
  }, [doneScreenings]);

  useEffect(() => {
    if (doneErrorCheckpep === true) {
      setIsModalWaitingShowing(false);
      setIsModalErrorCheckpep(true);
      dispatch(setDoneScreenings(false));
    }
  }, [doneErrorCheckpep]);

  function downloadFileScreening(cell) {
    currentId = cell;
    const payload = {
      idProcesso: data?.client?.pid,
      idUserLegal: cell,
      type: "BENEF",
      docType: "dnb",
    };
    dispatch(getDetailFile(payload));
  }

  const downloadFileFormatter = (cell, row) => {
    if (cell != "null") {
      return (
        <SimpleButton
          text={"Baixar documento"}
          variant={"easypay-button-primary"}
          onClick={() => downloadFileScreening(cell)}
          align={"left"}
        />
      );
    } else return cell;
  };

  useEffect(() => {
    var currentArray = [];

    currentArray.push({
      pid: data?.account?.pid,
      idUser: data?.client?.idCliente,
      type: "CLIENTE",
      name: data?.client?.nome,
    });
    for (var i = 0; i < data?.legalRepresentation?.length; i++) {
      currentArray.push({
        pid: data?.account?.pid,
        idUser: data?.legalRepresentation[i]?.id,
        type: "REP LEGAL",
        name: data?.legalRepresentation[i]?.nome,
      });
    }

    for (var i = 0; i < data?.effectiveBeneficiary?.length; i++) {
      currentArray.push({
        pid: data?.account?.pid,
        idUser: data?.effectiveBeneficiary[i]?.id,
        type: "BEN EFETIVO",
        name: data?.effectiveBeneficiary[i]?.nome,
      });
    }

    setUsersList(currentArray);
  }, [data]);

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const idFormatterOnboarding = (cell) => {
    return cell;
  };

  const statusFormatter = () => {
    //return <img src={iconWarning} />;
  };

  const resultFormatter = (cell) => {
    return cell; // <Tag text={cell} variant="easypay-tag-label" type="easypay-approved" />
  };

  const calcDays = (cell) => {
    if (!cell) {
      return "";
    }
    const modifiedDate = new Date(cell);
    const currentDate = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = currentDate.getTime() - modifiedDate.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const stageFormatter = (cell) => {
    return cell;
  };

  const capitalizeText = (cell) =>
    cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : "";

  const pepFormatter = (cell) => {
    return cell === 1 ? "PEP" : "-";
  };

  function downloadFile(id) {
    const payload = {
      information: id,
      apiSettings: "61798db3a0ac1d6257017e87",
      userId: user.id,
    };
    dispatch(getEntityPdf(payload));
  }

  const detailsFormatter = (cell) => {
    return (
      <div
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <SimpleButton
          text={"Baixar documento"}
          variant={"easypay-button-primary"}
          onClick={() => downloadFile(cell)}
          align="left"
        />
      </div>
    );
  };

  const nameFormatter = (cell, row) => {
    var mainName = "";
    for (var i = 0; i < row?.personNames?.length; i++) {
      if (row?.personNames[i]?.nameType === "Primary Name") {
        mainName = row?.personNames[i]?.fullName;
        break;
      }
    }
    return <div>{mainName}</div>;
  };

  const inputFile = useRef(null);
  var currentId = -1;
  function onButtonClick(index) {
    // `current` points to the mounted file input element
    currentId = index;
    inputFile.current.click();
  }

  function getBase64(file) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      const payload = {
        idProcesso: pid,
        idUserLegal: currentId,
        file: base64String,
        type: "dnb",
      };
      dispatch(saveDetailFile(payload));
      setIsModalDocShowing(true);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];

    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";

    if (
      !(
        type.includes("pdf") ||
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      )
    ) {
      //setIsModalShowing(true)
      return;
    }

    getBase64(currentFile);
  };

  const uploadFileFormatter = (cell, row) => {
    if (cell != "null") {
      return (
        <ButtonUpload
          text={t(`bpm.onboarding.backoffice.user.buttonUploadDocument`)}
          variant={"easypay-button-secondary-icon"}
          onclick={() => onButtonClick(cell)}
          onChange={handleChange}
          key={uuid()}
          inputFile={inputFile}
          id={"id1"}
          className="card-button"
          align={"left"}
          disabledColored={false}
          disabled={false}
        />
      );
    } else return cell;
  };

  const columns = [
    {
      dataField: "riskScore",
      text: "Risco",
      sort: true,
    },
    {
      dataField: "entityID",
      text: "Informação da entidade",
      sort: true,
      formatter: nameFormatter,
    },
    {
      dataField: "entityID",
      text: "Detalhes",
      sort: false,
      formatter: detailsFormatter,
    },
  ];

  const columnsScreenings = [
    {
      dataField: "name",
      text: "Nome",
      sort: true,
    },
    {
      dataField: "type",
      text: "Tipo",
      sort: true,
    },
    {
      dataField: "idUser",
      text: "Upload detalhes",
      sort: false,
      formatter: uploadFileFormatter,
      style: {
        "min-width": "fit-content",
      },
    },
    {
      dataField: "idUser",
      text: "Download detalhes",
      sort: false,
      formatter: downloadFileFormatter,
    },
  ];

  function handleSelectChange(e) {
    setCurrentName(e);
  }

  function handleTypeChange(e) {
    setCurrentType(e);
  }

  const typeList = [
    {
      label: "Empresa",
      value: "Empresa",
      selected: false,
    },
    {
      label: "Individual",
      value: "Individual",
      selected: false,
    },
  ];

  function buildNameList() {
    var newArray = [];
    newArray.push({
      label: data?.client?.nome,
      value: data?.client?.nome,
      selected: false,
    });
    for (var i = 0; i < data?.legalRepresentation?.length; i++) {
      if (data?.legalRepresentation[i]?.nome != data?.client?.nome) {
        newArray.push({
          label: data?.legalRepresentation[i]?.nome,
          value: data?.legalRepresentation[i]?.nome,
          selected: false,
        });
      }
    }
    for (var i = 0; i < data?.effectiveBeneficiary?.length; i++) {
      if (data?.effectiveBeneficiary[i]?.nome != data?.client?.nome) {
        newArray.push({
          label: data?.effectiveBeneficiary[i]?.nome,
          value: data?.effectiveBeneficiary[i]?.nome,
          selected: false,
        });
      }
    }
    return newArray;
  }

  function createBodyWaiting() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Por favor aguarde." status="waiting" />
      </div>
    );
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Screenings Gerados!" status="success" />
      </div>
    );
  }

  function createBodyRequired() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="É necessa´rio escolher um interviniente  e um tipo!" status="error" />
      </div>
    );
  }


  function createBodyDocSuccess() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Documento guardado" status="success" />
      </div>
    );
  }

  function createBodyErro() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Ocorreu o erro DUN, por favor contactar Easypay" status="error" />
      </div>
    );
  }

  const [isModalDocShowing, setIsModalDocShowing] = useState(false);
  const [isModalErrorCheckpep, setIsModalErrorCheckpep] = useState(false);
  return (
    <div className="easypay-backoffice-sections">
      <Modal
        className="easypay-modal"
        showModal={isModalRequiredShowing}
        setShowModal={setIsModalRequiredShowing}
        headerContent={"Gerar Screenings"}
        bodyContent={createBodyRequired()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalShowing}
        setShowModal={setIsModalShowing}
        headerContent={"Gerar Screenings"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalWaitingShowing}
        setShowModal={setIsModalWaitingShowing}
        headerContent={"Gerar Screenings"}
        bodyContent={createBodyWaiting()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalDocShowing}
        setShowModal={setIsModalDocShowing}
        headerContent={"Upload documento"}
        bodyContent={createBodyDocSuccess()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorCheckpep}
        setShowModal={setIsModalErrorCheckpep}
        headerContent={"Erro"}
        bodyContent={createBodyErro()}
        key={uuid()}
      ></Modal>
      <div className="easypay-backoffice-registry-section-header">
        {"Screenings"}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "40%" }}>
          <Dropdown
            text={"Escolher interviniente :"}
            required={true}
            placeholder="N/a"
            list={buildNameList()}
            name="lead-drop-partner"
            isColor={false}
            handleSubmit={(e) => handleSelectChange(e)}
          />
        </div>
        <div style={{ width: "40%" }}>
          <Dropdown
            text={"Tipo :"}
            required={true}
            placeholder="N/a"
            list={typeList}
            name="lead-drop-partner"
            isColor={false}
            handleSubmit={(e) => handleTypeChange(e)}
          />
        </div>
      </div>
      {
        <Table
          docs={
            getInquiryResponse?.inquiry?.entities
              ? getInquiryResponse?.inquiry?.entities
              : []
          }
          columns={columns}
          key="onboarding-table"
          hidePagination={false}
        />
      }
      <Button
        text={"Gerar Screenings"}
        variant={"easypay-button-primary"}
        onclick={generateScreenings}
        key={uuid()}
        id={addButtonField.id}
        className="card-button"
        align={"left"}
        disabledColored={false}
        disabled={false}
        field={addButtonField}
      />
      <Table
        docs={usersList ? usersList : []}
        columns={columnsScreenings}
        key="onboarding-table-screening"
        hidePagination={false}
      />
    </div>
  );
};

export default withNamespaces()(Screenings);
