import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/scss/_headerTitle.scss";
import "../assets/scss/_liftworldPage.scss";
import WorkPlanPage from "./WorkPlanPage";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import {
  updateWorkplan,
  makeNewVersion,
  makeWorkplanActive,
  setTriggerLoading,
  setAddWorkplanTrigger,
  deleteWorkplan,
  saveChangesWorkplan,
  setUpdateDone,
  setUpdateSubmit,
  setAllClients,
  setContact,
} from "~/store/ducks/budgetManagement/actionTypes";
import Table from "~/pages/Easypay/components/Table";
import LiftworldPageInput from "../components/LiftworldPageInput";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import AddButton from "~/components/Buttons/AddButton";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import uuid from "uuid/v1";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import oauth from "~/utils/oauth";

import {
  getAllWorkplans,
  getAllWorkplansByOrgGroup,
  getAllDepartments,
  getAllProviders,
  getAllProviderTypes,
  getAllCollaborators,
  getAllExpenditureTypes,
  getAllExpenses,
  getAllFunctions,
  getAllCategories,
  getAllBusinesses,
  getAllProbabilities,
  getAllProjectTypes,
  getAllProposals,
  getAllStates,
  getAllTeams,
  getAllClientDirectors,
  getAllPartners,
  getAllPaymentTypes,
  getAllClients,
  getAllFactoring,
  getAllInvoicingMode,
  getDocumentByWorkplan,
  getAllProposalTypes,
  getAllCompanies,
  getAllByClientDirector,
  setAllWorkplans,
} from "~/store/ducks/budgetManagement/actionTypes";
import { getBudgetmgrConfiguration } from "~/store/ducks/budgetmgrConfiguration/actionTypes";

import {
  getIflowFormdata,
  setIflowDone,
} from "~/store/ducks/processes/actionTypes";

const devLogConsole = require("~/utils/devLog");

const LiftworldPage = () => {
  const {
    workplans,
    triggerLoading,
    clients,
    addWorkplanTrigger,
    clientDirectors,
    isLoadingMakeActive,
    updateDone,
    workPlanId,
    isLoadingGetAllClientByClientDirector,
  } = useSelector((state) => state.budgetManagementReducer);

  const { user } = useSelector((state) => state.globalReducer);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);

  const { budgetmgrConfiguration } = useSelector(
    (state) => state.budgetmgrConfigurationReducer
  );

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getBudgetmgrConfiguration(user.organization.id));
  }, [user]);

  const isOrgGroupAdmin = () => {
    if (
      user &&
      user.roles &&
      user.roles.some(
        (r) => r.label === "ADMIN" || r.label === "BUDGETMGROrgGroupAdmin"
      )
    ) {
      return true;
    } else return false;
  };

  const [filteredList, setFilteredList] = useState([]);
  const [defaultActive, setDefaultActive] = useState("");
  const [proposalActivated, setProposalActivated] = useState(true);
  const [activatingWorkplans, setActivatingWorkplans] = useState(false);
  const [showWorkplan, setShowWorkPlan] = useState(false);

  const [oldIndex, setOldIndex] = useState(null);
  const [newIndex, setNewIndex] = useState(null);
  const [updateSecond, setUpdateSecond] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState(null);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (user?.organization?.id != null)
      dispatch(getAllClientDirectors(user?.organization?.id));
  }, [clientDirectors.length === 0, user]);

  const { iflowTriggerLoading, formdata, iflowDone } = useSelector(
    (state) => state.processesReducer
  );

  useEffect(() => {
    setFilteredList(workplans);
  }, [workplans]);

  useEffect(() => {
    if (canDelete && iflowDone != null) {
      dispatch(
        deleteWorkplan({
          workPlanId: workplans[deleteIndex]?.id,
          id: user?.organization?.id,
          isManager: isOrgGroupAdmin(),
        })
      );
      setCanDelete(false);
      dispatch(setIflowDone(null));
    }
  }, [formdata, iflowDone]);

  useEffect(() => {
    if (updateDone === "success") {
      setCanDelete(true);
      const flowId =
        budgetmgrConfiguration && budgetmgrConfiguration.flowId
          ? budgetmgrConfiguration.flowId
          : "333";
      const payload = {
        url:
          "/inicio_flow.jsp?" +
          "flowid=" +
          flowId +
          "&attr_workplanId=" +
          workplans[deleteIndex]?.id +
          "&attr_Authorization=" +
          oauth.getAccessToken() +
          "&Authorization=" +
          oauth.getAccessToken(),
      };
      dispatch(getIflowFormdata(payload));
      dispatch(setUpdateDone(null));
    }
  }, [updateDone]);

  useEffect(() => {
    if (updateSecond === true) {
      setUpdateSecond(false);
      const flowId =
        budgetmgrConfiguration && budgetmgrConfiguration.flowId
          ? budgetmgrConfiguration.flowId
          : "333";
      const payload = {
        url:
          "/inicio_flow.jsp?" +
          "flowid=" +
          flowId +
          "&attr_workplanId=" +
          workplans[newIndex]?.id +
          "&attr_Authorization=" +
          oauth.getAccessToken() +
          "&Authorization=" +
          oauth.getAccessToken(),
      };
      dispatch(getIflowFormdata(payload));
      dispatch(setUpdateDone(null));
    }
  }, [formdata]);

  useEffect(() => {
    if (!isLoadingMakeActive && activatingWorkplans && workplans?.length > 0) {
      setActivatingWorkplans(false);
      var missingParams = [];
      if (workplans[oldIndex].clientDirectorProjeto.length === 0) {
        missingParams.push("CLIENT DIRETOR DO PROJETO");
      }
      if (workplans[oldIndex].leadComercial === "") {
        missingParams.push("LEAD COMERCIAL");
      }
      if (workplans[oldIndex].equipa.length === 0) {
        missingParams.push("EQUIPA");
      }
      if (workplans[oldIndex].numClienteProjeto === 0) {
        missingParams.push("Nº CLIENTE PROJETO , NOME CLIENTE PROJETO");
      }
      if (workplans[oldIndex].clientOwner === "") {
        missingParams.push("CLIENT OWNER");
      }
      if (workplans[oldIndex].clientPartner === "") {
        missingParams.push("CLIENT PARTNER");
      }
      if (workplans[oldIndex].nomeProposta === "") {
        missingParams.push("NOME DA PROPOSTA");
      }
      if (workplans[oldIndex].tipoProjeto === "") {
        missingParams.push("TIPO DE PROJETO");
      }
      if (workplans[oldIndex].probabilidade === 0) {
        missingParams.push("PROBABILIDADE");
      }
      if (workplans[oldIndex].business === 0) {
        missingParams.push("BUSINESS");
      }
      if (workplans[oldIndex].proposta === "") {
        missingParams.push("PROPOSTA");
      }
      if (
        workplans[oldIndex].dataInicioProjeto === "" ||
        workplans[oldIndex].dataInicioProjeto === null
      ) {
        missingParams.push("DATA INICIO DO PROJETO");
      }
      if (
        workplans[oldIndex].dataFinalProjeto === "" ||
        workplans[oldIndex].dataFinalProjeto === null
      ) {
        missingParams.push("DATA FINAL DO PROJETO");
      }

      var currentErrorString =
        "Falta preencher os seguintes campos obrigatórios: ";

      if (missingParams.length > 0) {
        for (var i = 0; i < missingParams.length; i++) {
          if (i != missingParams.length - 1) {
            currentErrorString = currentErrorString + missingParams[i] + " , ";
          } else {
            currentErrorString = currentErrorString + missingParams[i];
          }
        }
        return;
      }

      var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
      if (workplans[oldIndex].submitted === 1 && array != null) {
        array[oldIndex] = 0;
        array[newIndex] = 1;
        workplans[newIndex].submitted = 1;
        localStorage.setItem("submittedWorkplans", JSON.stringify(array));
        const flowId =
          budgetmgrConfiguration && budgetmgrConfiguration.flowId
            ? budgetmgrConfiguration.flowId
            : "333";
        const payload = {
          url:
            "/inicio_flow.jsp?" +
            "flowid=" +
            flowId +
            "&attr_workplanId=" +
            workplans[oldIndex].id +
            "&attr_Authorization=" +
            oauth.getAccessToken() +
            "&Authorization=" +
            oauth.getAccessToken(),
        };
        setUpdateSecond(true);
        // dispatch(
        //   saveChangesWorkplan({
        //     content: workplans[newIndex],
        //     show: false,
        //     setSubmit: true,
        //   })
        // );
        dispatch(getIflowFormdata(payload));
      }
    }
  }, [isLoadingMakeActive, workplans]);

  const [deleteWorkplans, setDeleteWorkplans] = useState(true);

  useEffect(() => {
    if (deleteWorkplans) {
      dispatch(setAllWorkplans([]));
      setDeleteWorkplans(false);
    }
    if (
      user?.organization?.id &&
      workplans.length === 0 &&
      clientDirectors.length != 0
    ) {
      dispatch(getAllPaymentTypes());
      dispatch(getAllFunctions());
      dispatch(getAllProviderTypes());
      dispatch(getAllExpenditureTypes());
      dispatch(getAllBusinesses());
      dispatch(getAllProbabilities());
      dispatch(getAllProjectTypes());
      dispatch(getAllProposals());
      dispatch(getAllProposalTypes());
      dispatch(getAllStates());
      dispatch(getAllFactoring());
      dispatch(getAllInvoicingMode());
      dispatch(getAllCompanies());
      dispatch(getAllClients());
      let clientDirectorId = 21474836;
      for (let i = 0; i < clientDirectors.length; i++) {
        if (clientDirectors[i].udwUser === user?.username) {
          clientDirectorId = clientDirectors[i].idClientDiretor;
          break;
        }
      }
      dispatch(
        !isOrgGroupAdmin()
          ? getAllByClientDirector({ id: clientDirectorId })
          : getAllWorkplansByOrgGroup()
      );
      setFilteredList(workplans);
    }
    setFilteredList(workplans);

    dispatch(setTriggerLoading(false));
  }, [
    triggerLoading,
    workplans.length === 0,
    user,
    clientDirectors,
    workPlanId,
  ]);

  useEffect(() => {
    if (addWorkplanTrigger === true) {
      const currentWorkplan = workplans[workplans.length - 1];
      dispatch(
        getDocumentByWorkplan({
          workPlanId: currentWorkplan.id,
        })
      );

      dispatch(getAllExpenses(user?.organization?.id));
      dispatch(getAllTeams(user?.organization?.id));
      dispatch(getAllClients(user?.organization?.id));
      dispatch(getAllClientDirectors(user?.organization?.id));
      dispatch(getAllPartners(user?.organization?.id));
      dispatch(getAllCategories(user?.organization?.id));
      dispatch(getAllCollaborators(user?.organization?.id));
      dispatch(getAllDepartments(user?.organization?.id));
      dispatch(getAllProviders(user?.organization?.id));

      setWorkplanId(currentWorkplan.id);

      if (
        JSON.parse(localStorage.getItem("submittedWorkplans")) != null &&
        JSON.parse(localStorage.getItem("submittedWorkplans")) != undefined &&
        JSON.parse(localStorage.getItem("submittedWorkplans"))
      ) {
        var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
        array.push(0);
        localStorage.setItem("submittedWorkplans", JSON.stringify(array));
      } else {
        var array = [];
        for (var i = 0; i < workplans.length; i++) {
          array.push(0);
        }
        localStorage.setItem("submittedWorkplans", JSON.stringify(array));
      }

      dispatch(setContact({}));
      dispatch(setAllClients([]));
      setShowWorkPlan(true);
    }
    dispatch(setAddWorkplanTrigger(false));
  }, [addWorkplanTrigger]);

  const [workplanId, setWorkplanId] = useState("");
  const [workplanIndex, setWorkplanIndex] = useState(-1);

  const dispatch = useDispatch();

  const [tableSize, setTableSize] = useState(3);

  const [filters, setFilters] = useState([
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
  ]);

  const newWorkPlan = {
    nomeEmpresa: "",
    dataFinalProjeto: "",
    descricaoResumoFinanceiro: "",
    clientDirectorProjeto: [],
    clientDirectorProjetoId: [],
    estado: "Lead",
    estadoId: 1,
    dataIdentDaLead: "",
    nomeClienteProjeto: "",
    leadComercial: "",
    leadComercialId: 0,
    equipa: [],
    equipaId: [],
    numClienteFaturacao: 0,
    nomeClienteFaturacao: 0,
    numClienteProjeto: 0,
    tipoCliente: "",
    clientOwner: 0,
    clientPartner: 0,
    prazoPagamento: 0,
    formaPagamento: 0,
    aceitarFormaPagamento: "",
    numProposta: 0,
    nomeProposta: "",
    tipoProjeto: "",
    tipoProjetoId: 0,
    probabilidade: 0,
    business: 0,
    proposta: "",
    propostaId: 0,
    dataEntregaPorposta: null,
    dataInicioProjeto: null,
    dataAdjouFecho: null,
    distribuicaoHoras: "",
    distribuicaoHorasId: 0,
    modoFaturacaoId: 0,
    modoFaturacao: "",
    faseFinal: "",
    projectId: 0,
    projectName: "",
    version: 1,
    active: false,
    organizationId: 0,
    faturacao: 0,
    subcontratos: 0,
    income: 0,
    percIncome: 0,
    versionNumeroGanho: "",
    numeroGanho: "",
    imprimir: true,
    submitted: 0,
    items: [
      {
        nomeObjetivo: "",
        valorCustoObj: 0,
        valorVendaObj: 0,
        objetivoEspecifico: "",
        atividades: [
          {
            nomeAtividade: "",
            valorCustoAct: 0,
            valorVendaAct: 0,
            duracaoAct: 0,
            dataInicioAct: null,
            dataFimAct: null,
            opcoes: [
              {
                selected: false,
                imprimir: false,
                nomeOpcao: "",
                valorCustoOpt: 0,
                valorVendaOpt: 0,
                observaceosOpt: "",
                nunidadeDeMedia: 0,
                quantidade: 0,
                unidadeDeMedia: "",
                imagensOpt: [],
                horas: [],
                fornecedores: [],
                despesas: [],
              },
            ],
          },
        ],
      },
    ],
    createdDate: null,
    simulador: {
      valorLimite: 0,
      horasPropInicial: 0,
      horasValSugerido: 0,
      horasOutroValor: 0,
      horasValorConsiderar: 0,
      subcontratosPropInicial: 0,
      subcontratosValSugerido: 0,
      subcontratosOutroValor: 0,
      subcontratosValorConsiderar: 0,
      despesasPropInicial: 0,
      despesasValSugerido: 0,
      despesasOutroValor: 0,
      despesasValorConsiderar: 0,
      factoringPropInicial: 0,
      factoringValSugerido: 0,
      factoringOutroValor: 0,
      factoringValorConsiderar: 0,
      totalPropInicial: 0,
      totalValSugerido: 0,
      totalOutroValor: 0,
      totalValorConsiderar: 0,
    },
    resumoFinaceiro: {
      resumeFactoration: [],
      resumeCost: [],
      resumeIncome: [],
      resumePercIncome: [],
      resumeCostHours: [],
      resumeHours: [],
      resumeMargem: [],
      resumePercMargin: [],
      toChange: 0,
      canSave: true,
      maxFactTotal: 0,
    },
    createdBy: "admin",
    lock: "",
    isNew: false,
  };

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Plano de trabalho ativo" status="error" />
          <div style={{ fontWeight: "bold" }}>
            Está prestes a apagar um plano de trabalho ativo tem a certeza ?
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
            }}
          >
            {" "}
            <SimpleButton
              text={"Apagar"}
              variant={"liftworld-button-primary"}
              onClick={() => deleteWorkplanFunc()}
            />
          </div>
        </div>
      </div>
    );
  }

  const stageFormatter = (cell) => {
    return cell;
  };

  function duplicateWorkplan(index) {
    var newWorkplan = JSON.parse(JSON.stringify(workplans[index]));
    newWorkplan["estado"] = "Lead";
    newWorkplan.estadoId = 1;
    newWorkplan.dataIdentDaLead = new Date();
    newWorkplan.dataEntregaPorposta = null;
    newWorkplan.numeroGanho = "";
    newWorkplan.dataAdjouFecho = null;
    newWorkplan.submitted = 0;
    delete newWorkplan.id;
    dispatch(updateWorkplan(newWorkplan));
  }

  function makeNewVersionFunction(index) {
    var newVersionWworkplan = workplans[index];
    newVersionWworkplan.submitted = 0;
    dispatch(makeNewVersion(newVersionWworkplan));
  }

  function makeActive(index) {
    var currentOldIndex = -1;
    for (var i = 0; i < workplans.length; i++) {
      if (workplans[i]?.projectName === workplans[index]?.projectName) {
        if (workplans[i]?.active === true) {
          currentOldIndex = i;
          break;
        }
      }
    }
    setOldIndex(currentOldIndex);
    setNewIndex(index);
    dispatch(
      makeWorkplanActive({
        params: {
          workPlanId: workplans[index].id,
          projectName: workplans[index].projectName,
          isManager: isOrgGroupAdmin(),
        },
        id: user?.organization?.id,
      })
    );
    setActivatingWorkplans(true);
  }

  function deleteWorkplanFunc() {
    setIsModalErrorShowing(false);
    workplans[workplanIndex].estado = "Eliminado";
    workplans[workplanIndex].estadoId = 10;
    dispatch(
      saveChangesWorkplan({
        content: workplans[workplanIndex],
        show: false,
        setUpdate: true,
      })
    );

    setDeleteIndex(workplanIndex);

    /*dispatch(
      deleteWorkplan({
        workPlanId: workplans[workplanIndex].id,
        id: user?.organization?.id,
        isManager: isOrgGroupAdmin(),
      })
    );*/
    var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
    array.splice(workplanIndex, 1);
    localStorage.setItem("submittedWorkplans", JSON.stringify(array));
  }

  function deleteWorkPlanHandler(index) {
    if (workplans[index].active) {
      setIsModalErrorShowing(true);
      setWorkplanIndex(index);
    } else {
      setIsModalErrorShowing(false);
      workplans[index].estado = "Eliminado";
      workplans[index].estadoId = 10;
      dispatch(
        saveChangesWorkplan({
          content: workplans[index],
          show: false,
          setUpdate: true,
        })
      );

      setDeleteIndex(index);

      /*dispatch(
        deleteWorkplan({
          workPlanId: workplans[index].id,
          id: user?.organization?.id,
          isManager: isOrgGroupAdmin(),
        })
      );*/
      var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
      array.splice(index, 1);
      localStorage.setItem("submittedWorkplans", JSON.stringify(array));
    }
  }
  function getIndexFromId(id) {
    if (workplans != null) {
      return workplans
        .map(function (x) {
          return x.id;
        })
        .indexOf(id);
    }
  }

  function setNewIdAndShow(id) {
    const indexCurrent = getIndexFromId(id);
    const currentWorkplan = workplans[indexCurrent];
    dispatch(
      getDocumentByWorkplan({
        workPlanId: currentWorkplan.id,
      })
    );
    if (isOrgGroupAdmin()) {
      dispatch(getAllExpenses(workplans[indexCurrent].organizationId));
      dispatch(getAllTeams(workplans[indexCurrent].organizationId));
      dispatch(getAllClients(workplans[indexCurrent].organizationId));
      dispatch(getAllClientDirectors(workplans[indexCurrent].organizationId));
      dispatch(getAllPartners(workplans[indexCurrent].organizationId));
      dispatch(getAllCategories(workplans[indexCurrent].organizationId));
      dispatch(getAllCollaborators(workplans[indexCurrent].organizationId));
      dispatch(getAllDepartments(workplans[indexCurrent].organizationId));
      dispatch(getAllProviders(workplans[indexCurrent].organizationId));
    } else {
      dispatch(getAllExpenses(user?.organization?.id));
      dispatch(getAllTeams(user?.organization?.id));
      dispatch(getAllClients(user?.organization?.id));
      dispatch(getAllClientDirectors(user?.organization?.id));
      dispatch(getAllPartners(user?.organization?.id));
      dispatch(getAllCategories(user?.organization?.id));
      dispatch(getAllCollaborators(user?.organization?.id));
      dispatch(getAllDepartments(user?.organization?.id));
      dispatch(getAllProviders(user?.organization?.id));
    }

    if (
      JSON.parse(localStorage.getItem("submittedWorkplans")) != null &&
      JSON.parse(localStorage.getItem("submittedWorkplans")) != undefined &&
      JSON.parse(localStorage.getItem("submittedWorkplans"))
    ) {
      var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
      if (array[indexCurrent] === 1) {
        setProposalActivated(false);
      } else {
        setProposalActivated(true);
      }
    } else {
      var array = [];
      for (var i = 0; i < workplans.length; i++) {
        array.push(0);
      }
      localStorage.setItem("submittedWorkplans", JSON.stringify(array));
    }
    setWorkplanId(id);
    dispatch(setContact({}));
    dispatch(setAllClients([]));
    setShowWorkPlan(true);
  }

  const duplicateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ProtectedComponent
        permissions={[{ label: "app-budgetmgr-workplan-create" }]}
      >
        <SimpleButton
          text={"Duplicar"}
          variant={"easypay-button-link"}
          onClick={() => duplicateWorkplan(getIndexFromId(row && row.id))}
        />
      </ProtectedComponent>
    );
  };

  const newVersionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ProtectedComponent
        permissions={[{ label: "app-budgetmgr-workplan-create" }]}
      >
        <SimpleButton
          text={"Nova Versão"}
          variant={"easypay-button-link"}
          onClick={() => makeNewVersionFunction(getIndexFromId(row && row.id))}
        />
      </ProtectedComponent>
    );
  };

  const deleteWorkplanFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ProtectedComponent
        permissions={[{ label: "app-budgetmgr-workplan-delete" }]}
      >
        <SimpleButton
          text={"Apagar"}
          variant={"easypay-button-link"}
          onClick={() => deleteWorkPlanHandler(getIndexFromId(row && row.id))}
        />
      </ProtectedComponent>
    );
  };

  const makeActiveFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (workplans[getIndexFromId(row && row.id)]?.active) {
      return (
        <ProtectedComponent
          permissions={[{ label: "app-budgetmgr-workplan-delete" }]}
        >
          <SimpleButton
            text={"Ativar"}
            variant={"easypay-button-link-disabled"}
          />
        </ProtectedComponent>
      );
    } else
      return (
        <ProtectedComponent
          permissions={[{ label: "app-budgetmgr-workplan-update" }]}
        >
          <SimpleButton
            text={"Ativar"}
            variant={"easypay-button-link"}
            onClick={() => makeActive(getIndexFromId(row && row.id))}
          />
        </ProtectedComponent>
      );
  };

  const projectNameFormatter = (cell) => {
    if (!cell) return;
    const indexCurrent = getIndexFromId(cell);
    const currentWorkplan = workplans[indexCurrent];
    const version = workplans[indexCurrent]?.version;
    var versionString = "";
    if (version >= 10) {
      versionString = "" + version;
    } else {
      versionString = "0" + version;
    }
    return (
      <div
        className={"liftworld-link-style"}
        onClick={() => setNewIdAndShow(currentWorkplan?.id)}
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {currentWorkplan?.projectName +
          "." +
          versionString +
          "/" +
          (currentWorkplan?.dataIdentDaLead
            ? currentWorkplan.dataIdentDaLead.substring(0, 4)
            : "2022")}
      </div>
    );
  };

  const textFormatter = (cell) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {cell}
      </div>
    );
  };

  const numberFormatter = (cell) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {parseFloat(cell).toFixed(2) + "€"}
      </div>
    );
  };

  const percentFormatter = (cell) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {parseFloat(cell).toFixed(2) + "%"}
      </div>
    );
  };

  const listFormatter = (cell) => {
    var newStr = "";

    for (var i = 0; i < cell.length; i++) {
      if (cell[i] != "" && cell[i] != null) {
        newStr = newStr + cell[i] + " , ";
      }
    }

    newStr = newStr.slice(0, -1);
    newStr = newStr.slice(0, -1);

    return (
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {newStr}
      </div>
    );
  };

  const nameFormatter = (cell) => {
    var name = "";
    if (clients != null) {
      const client = clients.find((x) => x.idCliente === parseInt(cell));
      if (client != null) name = client?.nome;
    }
    return (
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {name}
      </div>
    );
  };

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    if (cell === null || cell === "" || cell === undefined) return "";
    var currentStringDate = cell;
    if (currentStringDate.length > 10) {
      currentStringDate = currentStringDate.slice(0, 10);
    }
    if (
      currentStringDate != null &&
      currentStringDate != undefined &&
      currentStringDate?.length > 0
    ) {
      const dateInfo = currentStringDate.split("-");
      const str = dateInfo[2] + "/" + dateInfo[1] + "/" + dateInfo[0];

      return (
        <div
          style={{ display: "flex", alignItems: "center", paddingTop: "8px" }}
        >
          {str}
        </div>
      );
    } else {
      return (
        <div
          style={{ display: "flex", alignItems: "center", paddingTop: "8px" }}
        ></div>
      );
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Número da proposta",
      sort: true,
      headerStyle: { width: "150px" },
      formatter: projectNameFormatter,
    },
    {
      dataField: "nomeProposta",
      text: "Nome da Proposta",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: textFormatter,
    },
    {
      dataField: "estado",
      text: "Estado",
      sort: false,
      formatter: stageFormatter,
      headerStyle: { width: "fit-content" },
      formatter: textFormatter,
    },
    {
      dataField: "createdDate",
      text: "Data de criação",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: dateFormatter,
    },
    {
      dataField: "clientDirectorProjeto",
      text: "Client Director",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: listFormatter,
    },
    {
      dataField: "nomeClienteProjeto",
      text: "Cliente",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: nameFormatter,
    },
    {
      dataField: "dataInicioProjeto",
      text: "Data Inicio Projeto",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: dateFormatter,
    },
    {
      dataField: "dataFinalProjeto",
      text: "Data Final Projeto",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: dateFormatter,
    },
    {
      dataField: "faturacao",
      text: "Faturação",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: numberFormatter,
    },
    {
      dataField: "subcontratos",
      text: "Subcontratos",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: numberFormatter,
    },
    {
      dataField: "income",
      text: "Income",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: numberFormatter,
    },
    {
      dataField: "percIncome",
      text: "% Income",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: percentFormatter,
    },
    {
      dataField: "numeroGanho",
      text: "Nº Projeto",
      sort: false,
      headerStyle: { width: "150px" },
      formatter: textFormatter,
    },
    {
      dataField: "duplicateFormatterId",
      text: "",
      sort: false,
      formatter: duplicateFormatter,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "newVersionFormatterId",
      text: "",
      sort: false,
      formatter: newVersionFormatter,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "makeActiveFormatterId",
      text: "",
      sort: false,
      formatter: makeActiveFormatter,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "deleteWorkplanFormatterId",
      text: "",
      sort: false,
      formatter: deleteWorkplanFormatter,
      headerStyle: { width: "fit-content" },
    },
  ];

  const setFiltersHandler = (filter, filterNum) => {
    if (filters[filterNum]?.value !== filter.value) {
      setFilters((prev) => {
        const newFilter = [...prev];
        newFilter[filterNum] = filter;
        return newFilter;
      });
    }
  };

  const uniqueData = (data) => {
    return data.filter(
      (value, index, self) => self.findIndex((v) => v.id === value.id) === index
    );
  };

  const filterData = (datatouse, filter) => {
    const data = uniqueData(datatouse);
    const validFilters = filter.filter((e) => e.value !== "");
    if (validFilters.length > 0) {
      const filteredData = data.filter((workplan) => {
        //Looping in each workplan for all filters. returns false if at least one filter value is not found for better performance
        for (let i = 0; i < validFilters.length; i++) {
          if (
            validFilters[i].value.length === 2 &&
            typeof validFilters[i].value === "object"
          ) {
            //Date input takes multiple values
            const userDate = new Date(
              workplan[validFilters[i].type[0]]
            ).getTime();
            const startDate = validFilters[i].value[0];
            const endDate = validFilters[i].value[1];
            if (userDate) {
              if (startDate <= userDate && userDate <= endDate) {
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else if (validFilters[i].type.length === 2) {
            //Search input takes multiple types
            var userParam1 = "";
            if (clients != null) {
              const client = clients.find(
                (x) =>
                  x.idCliente === parseInt(workplan[validFilters[i].type[0]])
              );
              if (client != null) userParam1 = client?.nome;
            }
            const userParam2 = workplan[validFilters[i].type[1]].toString();
            if (
              !userParam1
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase()) &&
              !userParam2
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase())
            ) {
              return false;
            }
          } else if (validFilters[i].type.length === 1) {
            //Search input takes multiple types
            const userParam1 = workplan[validFilters[i].type[0]].toString();
            if (
              !userParam1
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase())
            ) {
              return false;
            }
          } else {
            if (
              workplan[validFilters[i].type[0]].toUpperCase() !==
              validFilters[i].value.toUpperCase()
            ) {
              return false; //case where workplan does not have at least one filter value (going to return false for data.filter function)
            }
          }
          if (i === validFilters.length - 1) {
            return true;
          }
        }
        return false;
      });
      return filteredData;
    } else {
      return data; //in case there's no filter applied returns data unfiltered
    }
  };

  function addWorkplan() {
    newWorkPlan.organizationId = user?.organization?.id;
    newWorkPlan.nomeEmpresa = user?.organization?.description;
    newWorkPlan.dataIdentDaLead = new Date();
    newWorkPlan.isNew = true;
    setWorkplanId("");
    dispatch(setContact({}));
    dispatch(setAllClients([]));
    dispatch(updateWorkplan(newWorkPlan));
  }

  function filterListByState(name) {
    if (name === "Ongoing")
      return filteredList.filter(function (el) {
        return (
          el.estado === "Lead" ||
          el.estado === "Proposta" ||
          el.estado === "Adjudicado"
        );
      });
    else {
      return filteredList.filter(function (el) {
        return (
          (el.estado != "Lead" && el.estado != "Proposta") ||
          el.estado != "Adjudicado"
        );
      });
    }
  }

  function getWorkPlanIndexFromId() {
    for (var i = 0; i < workplans.length; i++) {
      if (workplans[i].id === workplanId) return i;
    }
  }

  function getiFlowActivate(id) {
    const flowId =
      budgetmgrConfiguration && budgetmgrConfiguration.flowId
        ? budgetmgrConfiguration.flowId
        : "337";
    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=" +
        flowId +
        "&attr_workplanId=" +
        id +
        "&attr_Authorization=" +
        oauth.getAccessToken() +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    dispatch(getIflowFormdata(payload));
  }

  const [prevShowWorkplan, setPrevShowWorkplan] = useState(showWorkplan);

  useEffect(() => {
    if (showWorkplan !== prevShowWorkplan && !showWorkplan) {
      // workplans.forEach((workplan) => {
      //   if (workplan.lock === user.username) {
      //     const shouldUnlockAndDispatch = ["Ongoing", "Ganho"].includes(
      //       workplan.estado
      //     )
      //       ? isOrgGroupAdmin()
      //       : true;
      //     if (shouldUnlockAndDispatch) {
      //       workplan.lock = "";
      //       dispatch(saveChangesWorkplan({ content: workplan, show: false }));
      //     }
      //   }
      // });
      let clientDirectorId = 21474836;
      for (let i = 0; i < clientDirectors.length; i++) {
        if (clientDirectors[i].udwUser === user?.username) {
          clientDirectorId = clientDirectors[i].idClientDiretor;
          break;
        }
      }
      dispatch(
        !isOrgGroupAdmin()
          ? getAllByClientDirector({ id: clientDirectorId })
          : getAllWorkplansByOrgGroup()
      );
      setWorkplanId("");
    }

    setPrevShowWorkplan(showWorkplan);
  }, [showWorkplan]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingGetAllClientByClientDirector}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {!showWorkplan ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            paddingLeft: "60px",
            paddingRight: "60px",
            paddingTop: "60px",
            paddingBottom: "60px",
            background: "white",
          }}
        >
          <div className="liftworld-header">PROPOSTA DE TRABALHO</div>
          <Modal
            className="easypay-modal"
            showModal={isModalErrorShowing}
            setShowModal={setIsModalErrorShowing}
            headerContent={"Aviso!!"}
            bodyContent={createBodyError()}
            key={uuid()}
          ></Modal>
          <div>
            <TabSeparatorWrapper>
              <TabSeparator
                text="Em Curso"
                name="tab1"
                variant={"liftworld-tab-wrapper"}
              >
                <LiftworldPageInput
                  filterDate={"createdDate"}
                  tableData={filterData(filterListByState("Ongoing"), filters)}
                  onSetFilters={setFiltersHandler}
                  savedFilters={filters}
                  key={"leads-input"}
                  disabled={false}
                />
                <Table
                  sort={{ dataField: "client.pid", order: "desc" }}
                  docs={filterData(filterListByState("Ongoing"), filters)}
                  columns={columns}
                  tableSize={tableSize}
                  setTableSize={setTableSize}
                  key="leads-table"
                  setShowWorkPlan={setShowWorkPlan}
                  setWorkplanId={setWorkplanId}
                  workplans={workplans}
                  getiFlowActivate={getiFlowActivate}
                />
              </TabSeparator>
              <TabSeparator
                text="Todos"
                name="tab2"
                variant={"liftworld-tab-wrapper"}
              >
                <LiftworldPageInput
                  filterDate={"createdDate"}
                  tableData={filterData(filteredList, filters)}
                  onSetFilters={setFiltersHandler}
                  savedFilters={filters}
                  key={"leads-input"}
                  disabled={false}
                />
                <Table
                  sort={{ dataField: "client.pid", order: "desc" }}
                  docs={filterData(filteredList, filters)}
                  columns={columns}
                  tableSize={tableSize}
                  setTableSize={setTableSize}
                  key="leads-table"
                  setShowWorkPlan={setShowWorkPlan}
                  setWorkplanId={setWorkplanId}
                  getiFlowActivate={getiFlowActivate}
                />
              </TabSeparator>
            </TabSeparatorWrapper>
          </div>
          <div style={{ paddingTop: "30px" }} />
          <ProtectedComponent
            permissions={[{ label: "app-budgetmgr-workplan-create" }]}
          >
            <AddButton
              text={"Adicionar plano de trabalho"}
              onClick={() => addWorkplan()}
            />
          </ProtectedComponent>
        </div>
      ) : (
        <WorkPlanPage
          workplanIndex={getWorkPlanIndexFromId()}
          setShowWorkPlan={setShowWorkPlan}
          defaultActive={defaultActive}
          setDefaultActive={setDefaultActive}
          proposalActivated={proposalActivated}
          setProposalActivated={setProposalActivated}
          showWorkplan={showWorkplan}
          setFilters={setFilters}
          setWorkplanId={setWorkplanId}
        />
      )}
    </>
  );
};
export default LiftworldPage;
