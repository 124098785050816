import React from "react";
import { Modal } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Digest from "./Digest";

const DigestModal = ({
  t,
  showDigestModal,
  handleCloseDigestModal,
  reviewedDocs,
}) => {
  const isMobile = window.innerWidth < 992 ? true : false;
  return (
    <Modal
      dialogClassName="modal-50w"
      show={showDigestModal}
      onHide={() => handleCloseDigestModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title style={isMobile ? { fontSize: "18px" } : {}}>
        {t("datacapture.general.digestTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "40vh" }}>
        <Digest reviewedDocs={reviewedDocs} />
      </Modal.Body>
    </Modal>
  );
};
export default withNamespaces()(DigestModal);
