import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import {
  setNotificationUpdated,
  setDeletedNotification,
  setUserPortalRHNotifications,
  setUserBPMNotifications,
} from "./actionTypes";

import { activeError } from "~/components/Messages/actions";
const devLogConsole = require("~/utils/devLog");
export function* getUserPortalRHNotifications({ appId, user }) {
  try {
    const resp = yield http.get(
      `/api/legacy-notification/get-by-app-and-user?app=${appId}&sentTo=${user}`
    );
    yield put(setUserPortalRHNotifications(resp.data));
  } catch (e) {
    devLogConsole(e);
    //yield put(activeError());
  }
}

export function* getUserBPMNotifications({ appId, user }) {
  try {
    const resp = yield http.get(
      `/api/legacy-notification/get-by-app-and-user?app=${appId}&sentTo=${user}`
    );
    yield put(setUserBPMNotifications(resp.data));
  } catch (e) {
    //yield put(activeError());
    devLogConsole(e);
  }
}

export function* markAsSeen({ id }) {
  try {
    yield put(setNotificationUpdated(false));
    yield http.put(`/api/legacy-notification/toggle-read/${id}`);
    yield put(setNotificationUpdated(true));
  } catch (e) {
    yield put(activeError());
  }
}

export function* markAllAsSeen() {
  try {
    yield put(setNotificationUpdated(false));
    yield http.put(`/api/legacy-notification/marked-all-as-read`);
    yield put(setNotificationUpdated(true));
  } catch (e) {
    yield put(activeError());
  }
}

export function* deleteNotification({ id }) {
  try {
    yield http.delete(`/api/legacy-notification/delete/${id}`);
    yield put(setDeletedNotification());
  } catch (e) {
    yield put(activeError());
  }
}

export function* saveNotification({ notification }) {
  try {
    yield http.post(`/api/legacy-notification/saveMultiple`, notification);
  } catch (e) {
    yield put(activeError());
  }
}
