import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_checkbox.scss";

const Checkbox = ({ disabled, name, id, checked, value,disabledColored ,onclick}) => {

  const handleCheckboxChecked = () => {
    onclick();
  };

  return (
    <>
      <label className="easypay-checkbox">
        <span className={"easypay-checkbox-label"} disabled={disabledColored}>{value}</span>
        <input className="easypay-checkbox-input" type="checkbox" disabled={disabled || disabledColored} id={id} name={name} checked={checked === false ? null : checked} onChange = {handleCheckboxChecked} />
        <span className={disabled ? "easypay-checkmark disabled" : "easypay-checkmark"} disabled={disabled}></span>
      </label>
    </>
  );
};

export default withNamespaces()(Checkbox);
