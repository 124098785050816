import React, { useEffect, useState } from "react";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AllIframesComponent from "~/components/AllIframesComponent";
import MyTeamRequestPage from "./components/MyTeam/MyTeamRequestPage";
import PersonalAreaRequestPage from "./components/PersonalArea/PersonalAreaRequestPage";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";
import ProtectedPage from "~/components/security/ProtectedPage";
import Spinner from "~/components/Spinner";
import Tasks from "~/components/Tasks";
import WidgetContainer from "~/components/WidgetContainer";
import ContentContainer from "~/containers/ContentContainer";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import ChatBotContainer from "~/components/OpenAI/ChatBotContainer";
import Dashboard from "../Dashboards";
import {
  addItemsToMenu,
  getMenusByApplication,
  updateReduxMenu,
} from "~/store/ducks/applicationMenu/actionTypes";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { findPortalRhMenu } from "~/store/ducks/portalRhMenu/actionTypes";
import { BPM_MIDDLEWARE, PORTALRH_MIDDLEWARE } from "~/utils/constants";
import DocumentManagement from "./components/DocumentManagement";
import IframeHandler from "./components/PortalIframeHandler";
import "./portal.scss";
import SimulatorMainPage from "~/components/Simulator/SimulatorMain/NavBarSimulator";
import CollaboratorsRH from "./components/RhCollaborator/RHAdmin/RhCollaborator";
import Personal_RHCollaborator from "./components/RhCollaborator/Personal_RHCollaborator";
import Processamento_RHCollaborator from "./components/RhCollaborator/Processamento_RHCollaborator";
import {
  checkUpdateByUsername,
  setGenericRequestState,
  setGenericAuthorizationState,
  getRequestsByUsernameWithType,
} from "~/store/ducks/portalrh/actionTypes";

import {
  RequestState,
  AuthorizerApproves,
} from "~/components/widgets/Requests/TabsEnum.js";
import MyInformationPayment from "./components/myInformationPayment";
import { withNamespaces } from "react-i18next";

import Evaluator from "./components/Performance/Evaluator";
import ErrorLogs from "./components/ErrorLogs";
import EmailMessage from "./components/Administration/Parameterization/EmailTemplate";
import AnnexDocuments from "./components/Administration/Parameterization/AnnexDocuments";
import DocsDisclaimer from "./components/Administration/Parameterization/DocsDisclaimer";
import ExportSchedules from "./components/Administration/ExportSchedules";
import ExportAttendance from "./components/Administration/ExportAttendance";
import ExportAbsence from "./components/ExportAbsence";
import Abscense from "./components/Abscense";
import BolsaHoras from "./components/BolsaHoras";
import VacationManagement from "./components/VacationManagement";
import WorkDays from "./components/TrabalhoRemoto";
import RHGestaoTemplates from "./components/RhCollaborator/RHAdmin/RHGestaoTemplates";
import RHCatalogoFormacao from "./components/RhCollaborator/RHAdmin/RHFormacoes/Catalogo";
import RHPlanosFormacao from "./components/RhCollaborator/RHAdmin/RHFormacoes/Planos";
import Expenses from "./components/Expenses";
import Reports from "./components/Reports";
import Delegations from "./components/Delegations";
import DelegationsDetail from "./components/Delegations/DelegationsDetail";
import OrganizationDetail from "./components/Delegations/OrganizationDetail";
import {
  setInformationProcessRemoteWork,
  setInformationProcessMarcacaoFerias,
  setInformationProcessAuthorization,
} from "~/store/ducks/portalrh/actionTypes";

import Rules from "~/pages/Admin/Rules";
import PersistRule from "~/pages/Admin/Rules/PersistRule";
import { getRequestsByUsername } from "~/store/ducks/portalrh/actionTypes";
import DespesasDemo from "~/components/DespesasDemo";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Ferias from "./components/Ferias";
import { getOpenAIConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";

const devLogConsole = require("~/utils/devLog");

const PortalRH = (props) => {
  const { isMongoOn, styleSheet, translations } = props;
  const dispatch = useDispatch();
  let { path } = useRouteMatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const usernamePortalRh = user.legacyUsers?.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  )?.username;

  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );
  const { menu: portalRhMenu } = useSelector(
    (state) => state.portalRhMenuReducer
  );

  const [showFlowModal, setShowFlowModal] = useState(false);
  const [showModalRemoteWork, setShowModalRemoteWork] = useState(false);
  const [showModalMarcacaoFerias, setShowModalMarcacaoFerias] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(undefined);
  const [portalRhMenus, setPortalRhMenus] = useState([]);
  const [loadEventsStart, setLoadEventsStart] = useState(false);
  const [makeUpdate, setmakeUpdate] = useState(true);
  const [showModalAuthorizationsRequests, setShowModalAuthorizationsRequests] =
    useState(false);

  useEffect(() => {
    dispatch(findPortalRhMenu());
    /*close iframes by default*/
    if (document.getElementById("portalrh"))
      document.getElementById("portalrh").style.display = "none";
  }, []);

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOpenAIConfiguration(user?.organization?.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    const url = window.location.href;
    if (
      url.includes("processos") ||
      url.includes("processos/submenu") ||
      url.includes("processos/form")
    ) {
      /*close full screen iframes*/
      closeIframesScreens();
    }
  }, [window.location.href]);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("personalarea/frame/Minhas")) {
      dispatch(checkUpdateByUsername(usernamePortalRh)); //checks for user data updates
    } else if (url.includes("myteam/frame/Autorizações")) {
      dispatch(setGenericAuthorizationState(AuthorizerApproves.YES));
    } else if (url.includes("personalarea/frame/Pedidos")) {
      dispatch(setGenericRequestState(RequestState.PENDING));
    }
  }, [window.location.href]);

  /*Get response from Portal  iframe*/
  const [showMenu, setShowMenu] = useState(true);
  useEffect(() => {
    function handleSUEvent(e) {
      setShowMenu(true);
    }
    function handleWaitAppResponse(e) {
      setShowMenu(true);
    }
    window.addEventListener("SUEvent", handleSUEvent);
    window.addEventListener("WaitAppResponse", handleWaitAppResponse);
  }, []);

  useEffect(() => {
    if (document.getElementById("portalrh")) {
      if (document.getElementById("portalrh").contentWindow) {
        try {
          document
            .getElementById("portalrh")
            .contentWindow.dispatchEvent(
              new CustomEvent("GetAppResponse", { detail: "from: portalrh" })
            );
        } catch (e) {
          //devLogConsole(e);
        }
      }
    }
  });
  /*Get response from Portal  iframe end*/

  /*Get Processes raw menus from iFlow (level 2 menus)*/
  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      dispatch(getMenusByApplication(username, PORTALRH_MIDDLEWARE.context));
    }
  }, [user, dispatch]);

  /*Add Processes menus*/
  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.portalrh.processos" });
    let items = [];
    let submenus;
    let extraMenus;
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      submenus = extraConfiguration_.submenus
        ? extraConfiguration_.submenus
        : null;
      extraMenus = extraConfiguration_.extraMenus
        ? extraConfiguration_.extraMenus
        : null;
      if (submenus) {
        Object.keys(submenus).forEach((key) => {
          //check if submenu has children
          let hasChildren = false;
          if (submenus[key].length == 0) hasChildren = false;
          else {
            submenus[key].forEach((flowid) => {
              if (typeof flowid === "string") {
                const extraMenu = extraMenus.find(
                  (menu) => menu.name === flowid
                );
                if (extraMenu.type === "nativePage") {
                  items.push({
                    id: flowid,
                    name: flowid,
                    submenu: true,
                    route: extraMenu.url,
                    url: extraMenu.flowid,
                    menuParent: key,
                    type: extraMenu.type,
                    docTypes: extraMenu.docTypes,
                  });
                  return false;
                } else if (extraMenu.type === "external") {
                  items.push({
                    name: flowid,
                    id: flowid,
                    type: extraMenu.type,
                    url: extraMenu.url,
                    menuParent: key,
                  });
                  return false;
                }
              } else {
                const res = rawMenus.every((rm) => {
                  //find flowid
                  if (rm.url) {
                    let arr = rm.url.split("flowid=");
                    const flowid_ = arr[1];
                    if (flowid_ == flowid) {
                      hasChildren = true;
                      return false;
                    }
                    return true;
                  }
                  return true;
                });
                return res;
              }
            });
          }
          if (hasChildren) {
            items.push({
              id: key,
              name: key,
              submenu: true,
              route: `${PORTALRH_MIDDLEWARE.context}/processos/submenu/${key}`,
              menuParent: processosMenu ? processosMenu.id : null,
            });
          }
        });
      }
      const getProcessosMenuId = () =>
        processosMenu ? processosMenu.id : null;
      const getSubMenuParentId = (url) => {
        if (url) {
          let arr = url.split("flowid=");
          const flowId = arr[1];
          let id = getProcessosMenuId();
          Object.keys(submenus).forEach((key) => {
            if (submenus[key].some((i) => i == flowId)) {
              id = key;
            }
          });
          return id;
        } else return getProcessosMenuId();
      };
      if (Array.isArray(rawMenus)) {
        rawMenus.forEach((item) => {
          items.push({
            name: item.label,
            url: item.url,
            route: `${PORTALRH_MIDDLEWARE.context}/processos/form/${item.label}`,
            menuParent: submenus
              ? getSubMenuParentId(item.url)
              : getProcessosMenuId(),
          });
        });

        // items.forEach(item => {
        //   if (!item.id && item.url && item.url.includes('flowid=')) {
        //     item.id = item.url.split('flowid=')[1];
        //   }
        // });

        // let flowidOrder = [].concat(...Object.values(submenus));
        // items.sort((a, b) => {
        //   let aIndex = flowidOrder.indexOf(a.id);
        //   let bIndex = flowidOrder.indexOf(b.id);
        //   if (aIndex > bIndex) {
        //     return 1;
        //   } else if (aIndex < bIndex) {
        //     return -1;
        //   } else {
        //     return 0;
        //   }
        // });
        dispatch(addItemsToMenu(items));
      }
    }
  }, [rawMenus, menu, dispatch]);

  /*Merge menus from PortalRH middleware with UDW menus*/
  useEffect(() => {
    const defaultMenus = [
      {
        name: "menu.portalrh.personalArea",
        designacao: "Área Pessoal",
        pathPart: "/personalarea/frame/",
      },
      {
        name: "menu.portalrh.myTeam",
        designacao: "Minha Equipa",
        pathPart: "/myteam/frame/",
      },
      {
        name: "menu.portalrh.siadapra",
        designacao: "Siadapra",
        pathPart: "/siadapra/frame/",
      },
      {
        name: "menu.portalrh.subMenus.DesempenhoSiadapra",
        designacao: "DesempenhoSiadapra",
        pathPart: "/siadapra/frame/",
      },
      {
        name: "menu.portalrh.communication",
        designacao: "Comunicações",
        pathPart: "/communication/frame/",
      },
      {
        name: "menu.portalrh.administration",
        designacao: "Administração",
        pathPart: "/administration/frame/",
      },
      {
        name: "menu.portalrh.rhadmin",
        designacao: "RH Admin",
        pathPart: "/rhadmin/frame/",
      },
      /*OLD Menus
      {
        name: "menu.portalrh.start",
        designacao: "Início",
        pathPart: "/start/frame/"
      },
      {
        name: "menu.portalrh.configuration",
        designacao: "Configuração",
        pathPart: "/configuration/frame/"
      },
      {
        name: "menu.portalrh.receipts",
        designacao: "Disp. Recibos",
        pathPart: "/receipts/frame/"
      }*/
    ];

    const handleMenu = (name, designacao, pathPart) => {
      const foundMenu = find(menu, { name: name });
      const foundMenuRH = find(portalRhMenu, { designacao: designacao });
      if (foundMenuRH && foundMenuRH.children) {
        if (foundMenuRH.children.length > 0) {
          let items = [];
          const getMenuId = () => (foundMenu ? foundMenu.id : null);
          const getURL = (item) => {
            if (
              item &&
              item.accao !== null &&
              item.accao !== undefined &&
              item.accao !== ""
            ) {
              return item.accao;
            } else if (item && item.children) {
              const foundItem = item.children.find(
                (c) =>
                  c.accao !== null && c.accao !== undefined && c.accao !== ""
              );
              return foundItem && foundItem.accao;
            } else return null;
          };

          foundMenuRH.children.forEach((item) => {
            items.push({
              name: item.designacao,
              url: getURL(item),
              route:
                item.externo == 1
                  ? `${PORTALRH_MIDDLEWARE.context}/${item.accao}`
                  : `${PORTALRH_MIDDLEWARE.context}${pathPart}${item.designacao}`,
              menuParent: getMenuId(),
              nivel: item.nivel,
              ordem: item.ordem,
            });
          });
          dispatch(addItemsToMenu(items));
        }
      }
    };

    if (portalRhMenu && portalRhMenu.length > 0) {
      const sortedDefaultMenus = defaultMenus.filter((m) =>
        portalRhMenu.some((i) => i.designacao === m.designacao)
      );

      sortedDefaultMenus.forEach((m) => {
        handleMenu(m.name, m.designacao, m.pathPart);
      });

      /*select first item as a default for submenu lvl 2*/
      const addDefaultAction = (menus) => {
        return menus.map((m) => {
          if (m.id) {
            const children = menus.filter((i) => i.menuParent === m.id);
            if (children && children.length > 0) {
              if (
                /* m.route !== `portalrh/processos` &&
                m.route !== `portalrh/processes`*/
                m.route &&
                !m.route.includes("portalrh/processes") &&
                !m.route.includes("portalrh/processos")
              ) {
                //TODO sort children
                m.url = children[0].url;
                m.route = children[0].route;
              }
              return m;
            }
          }
          return m;
        });
      };

      const menusToRemove = defaultMenus.filter(
        (m) => !portalRhMenu.some((i) => i.designacao === m.designacao)
      );
      let sortedMenus;
      if (menusToRemove && menusToRemove.length > 0) {
        sortedMenus = menu.filter(
          (m) => !menusToRemove.some((i) => i.name === m.name)
        );
        if (sortedMenus.length > 0) {
          addDefaultAction(sortedMenus);
          dispatch(updateReduxMenu(addDefaultAction(sortedMenus)));
        }
      } else {
        dispatch(updateReduxMenu(addDefaultAction(menu)));
      }
    }
    setPortalRhMenus(portalRhMenu);
  }, [portalRhMenu, menu, dispatch]);

  const handleOpenFlowModal = (row) => {
    setShowFlowModal(true);
    setSelectedProcess(row);
  };

  const checkValidateProc = (searchFilter) => {
    switch (searchFilter) {
      case "absenceRecord":
        return false;
      case "extraTime":
        return false;
      case "insertPresence":
        return false;
      case "pagamentosBolsa":
        return false;
      case "remoteWork":
        return false;
      case "marcacaoFerias":
        return false;
      case "vacationsManagement":
        return false;
      default:
        return true;
    }
  };

  const handleOpenModalRemoteWork = (row) => {
    setShowModalRemoteWork(true);
    setSelectedProcess(row);
  };

  const handleOpenModalMarcacaoFerias = (row) => {
    setShowModalMarcacaoFerias(true);
    setSelectedProcess(row);
  };

  const handleOpenModalAuthorizationsRequests = (row) => {
    setShowModalAuthorizationsRequests(true);
    setSelectedProcess(row);
  };

  const handleCloseFlowModal = (searchFilter, state) => {
    setShowFlowModal(false);
    callGetRequestsByUsername(searchFilter, state);
  };

  const handleCloseModalRemoteWork = (searchFilter, state) => {
    setShowModalRemoteWork(false);
    setSelectedProcess(undefined);
    dispatch(setInformationProcessRemoteWork({}));
    callGetRequestsByUsername(searchFilter, state);
  };

  const handleCloseModalMarcacaoFerias = (searchFilter, state, update) => {
    setShowModalMarcacaoFerias(false);
    setSelectedProcess(undefined);
    dispatch(setInformationProcessMarcacaoFerias({}));
    callGetRequestsByUsername(searchFilter, state, update);
  };

  const handleCloseModalAuthorizationsRequests = (
    searchFilter,
    state,
    update
  ) => {
    setShowModalAuthorizationsRequests(false);
    setSelectedProcess(undefined);
    dispatch(setInformationProcessAuthorization({}));
    callGetRequestsByUsername(searchFilter, state, update);
  };

  const callGetRequestsByUsername = (searchFilter, from, update = null) => {
    if (
      searchFilter !== undefined &&
      from !== null &&
      from !== undefined &&
      (update || checkValidateProc(searchFilter))
    ) {
      dispatch(
        getRequestsByUsernameWithType(
          usernamePortalRh,
          from,
          searchFilter === "marcacaoFerias"
            ? "vacationsManagement"
            : searchFilter
        )
      );
      setmakeUpdate(true);
    }
  };

  const dashboardPages =
  Number.isInteger(configuration?.dashboard) && configuration?.dashboard != 0
    ? configuration.dashboard
    : 10;

  return (
    <>
      {showMenu && (
        <ApplicationMenu
          applicationName={PORTALRH_MIDDLEWARE.context}
          showMenu={showMenu}
          translations={translations}
        />
      )}
      <AllIframesComponent name="portalrh" />
      <Switch>
        {/* ROTAS VINDAS DO LAYOUT */}
        <Route path={`${path}/openai`} exact>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <ChatBotContainer className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        {Array.from({ length: dashboardPages }).map((_, index) => (
        <Route key={index} exact path={`${path}/page/${index + 1}`}>
          <ContentContainer>
            <WidgetContainer />
          </ContentContainer>
        </Route>
      ))}
        {/*  */}
        <Route path={`${path}/demo`}>
          <DespesasDemo className="main-card-v2" />
        </Route>
        <Route path={`${path}/rhadmin/simuladores`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <SimulatorMainPage
                portalRhMenus={portalRhMenus}
                designacao={"RH Admin"}
                path={path}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/rhadmin/colaboradores`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <CollaboratorsRH
                portalRhMenus={portalRhMenus}
                designacao={"RH Admin"}
                path={path}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>

        <Route path={`${path}/rhadmin/gestaoTemplates`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <RHGestaoTemplates />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/administration/rulesRemoteWork`} exact>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Rules />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route
          path={`${path}/administration/rulesRemoteWork/create`}
          exact
          component={PersistRule}
        />
        <Route
          path={`${path}/administration/rulesRemoteWork/update/:id`}
          exact
          component={PersistRule}
        />

        <Route exact path={`${path}/rhadmin/exportabsence`}>
          <ContentContainer>
            <ExportAbsence />
          </ContentContainer>
        </Route>
        <Route path={`${path}/rhadmin/formacao/catalogo`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <RHCatalogoFormacao />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/rhadmin/formacao/planos`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <RHPlanosFormacao />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/myInformations`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Personal_RHCollaborator
                portalRhMenus={portalRhMenus}
                designacao={"Área Pessoal"}
                path={path}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/Processamento`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Processamento_RHCollaborator
                portalRhMenus={portalRhMenus}
                designacao={"Processamento"}
                path={path}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/scheduler`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Abscense
                portalRhMenus={portalRhMenus}
                designacao={"Scheduler"}
                path={path}
                isEditable={true}
                showCalendarAndTable={true}
                loadEventsStart={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/expenses`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <Expenses
              portalRhMenus={portalRhMenus}
              designacao={"Expenses"}
              sectionName={"portalrh.requestTable.myGenericRequests"}
              searchFilter={"expenses"}
              activeSelected={"expenses"}
            />
          </ProtectedPage>
        </Route>
        {/* <Route path={`${path}/personalArea/vacationsManagement`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Ferias
                portalRhMenus={portalRhMenus}
                designacao={"vacationsManagement"}
                path={path}
                isEditable={true}
                showCalendarAndTable={true}
                loadEventsStart={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route> */}
        <Route path={`${path}/reports`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
              <Reports
                portalRhMenus={portalRhMenus}
                designacao={"Reports"}
                sectionName={"reports"}
                searchFilter={"reports"}
                activeSelected={"reports"}
              />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/vacationsManagement`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <VacationManagement
                portalRhMenus={portalRhMenus}
                designacao={"vacationsManagement"}
                path={path}
                isEditable={true}
                showCalendarAndTable={true}
                loadEventsStart={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/vacationsManagementGroup`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <VacationManagement
                portalRhMenus={portalRhMenus}
                designacao={"vacationsManagement"}
                path={path}
                isEditable={false}
                showCalendarAndTable={true}
                loadEventsStart={true}
                hideChangeView={true}
                pAVMG={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/siadapra/dirigentes`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Delegations
                portalRhMenus={portalRhMenus}
                designacao={"dirigentes"}
                path={path}
                sectionName={"portalrh.requestTable.myDirigentesRequests"}
                activeSelected={"siadapra/dirigentes"}
                type="dirigentes"
                styleSheet={styleSheet}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route
          path={`${path}/siadapra/dirigentes-detail/:id`}
          component={DelegationsDetail}
        />
        <Route
          path={`${path}/siadapra/organizacao-detail/:id/:dropVal`}
          component={OrganizationDetail}
        />
        <Route path={`${path}/siadapra/organizacao`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Delegations
                portalRhMenus={portalRhMenus}
                designacao={"organizacao"}
                path={path}
                sectionName={"Serviços da Administração Pública"}
                activeSelected={"siadapra/organizacao"}
                type="organizacao"
                styleSheet={styleSheet}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/workDays`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <WorkDays
                portalRhMenus={portalRhMenus}
                designacao={"workDays"}
                path={path}
                isEditable={true}
                showCalendarAndTable={true}
                loadEventsStart={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalArea/bolsaHoras`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <BolsaHoras
                portalRhMenus={portalRhMenus}
                designacao={"Bolsa Horas"}
                path={path}
                showDrop={false}
                handleOpenFlowModal={handleOpenFlowModal}
                handleCloseFlowModal={handleCloseFlowModal}
                selectedProcess={selectedProcess}
                showFlowModal={showFlowModal}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        {/* Simulador Fecho de Contas */}
        <Route path={`${path}/performance/evaluator`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Evaluator />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/start/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/start`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/my-information/payment`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <MyInformationPayment />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/document-management`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <DocumentManagement
                portalRhMenus={portalRhMenus}
                activeSelected={"personalarea/document-management"}
                designacao={"Minhas Informações"}
                personalArea={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/vacations`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              handleOpenModalMarcacaoFerias={handleOpenModalMarcacaoFerias}
              showModalMarcacaoFerias={showModalMarcacaoFerias}
              handleCloseModalMarcacaoFerias={handleCloseModalMarcacaoFerias}
              setShowModalMarcacaoFerias={setShowModalMarcacaoFerias}
              selectedProcess={selectedProcess}
              designacao={"Pedidos"}
              searchFilter={"vacationsManagement"}
              activeSelected={"personalarea/vacations"}
              sectionName={"portalrh.requestTable.myVacationsRequests"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
              portalRhMenus={portalRhMenus}
            />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/personalarea/marcacaoFerias`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myVacationsRequests"}
              searchFilter={"marcacaoFerias"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/marcacaoFerias"}
              designacao={"Pedidos"}
              handleOpenModalMarcacaoFerias={handleOpenModalMarcacaoFerias}
              showModalMarcacaoFerias={showModalMarcacaoFerias}
              handleCloseModalMarcacaoFerias={handleCloseModalMarcacaoFerias}
              setShowModalMarcacaoFerias={setShowModalMarcacaoFerias}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/requests/payment`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"Dados Pagamento"}
              sectionName={"portalrh.requestTable.myPaymentsRequests"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/generic`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myGenericRequests"}
              searchFilter={"generic"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/generic"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/comprovativoPasseSocial`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myPasseRequests"}
              searchFilter={"comprovativoPasseSocial"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/comprovativoPasseSocial"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/processing`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myProcessingRequests"}
              searchFilter={"processing"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/processing"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/remoteWork`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myRemoteWorkRequests"}
              searchFilter={"remoteWork"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/remoteWork"}
              designacao={"Pedidos"}
              handleOpenModalRemoteWork={handleOpenModalRemoteWork}
              showModalRemoteWork={showModalRemoteWork}
              handleCloseModalRemoteWork={handleCloseModalRemoteWork}
              setShowModalRemoteWork={setShowModalRemoteWork}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/expense`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"expense"}
              sectionName={"portalrh.requestTable.myExpenseRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/expense"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/absenceRecord`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"absenceRecord"}
              sectionName={"portalrh.requestTable.myAbsenceRecordRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/absenceRecord"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/extraTime`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"extraTime"}
              sectionName={"portalrh.requestTable.myExtraTimeRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/extraTime"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/insertPresence`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"insertPresence"}
              sectionName={"portalrh.requestTable.myInsertPresenceRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/insertPresence"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea/pagamentosBolsa`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <PersonalAreaRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"pagamentosBolsa"}
              sectionName={"portalrh.requestTable.myPagamentosBolsaRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"personalarea/pagamentosBolsa"}
              designacao={"Pedidos"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/personalarea/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/personalarea`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/documents`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <DocumentManagement manager={true} />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/generic`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"generic"}
              sectionName={"portalrh.requestTable.myTeamsGenericRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/generic"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/comprovativoPasseSocial`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"comprovativoPasseSocial"}
              sectionName={"portalrh.requestTable.myTeamsPasseRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/comprovativoPasseSocial"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/processing`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"processing"}
              sectionName={"portalrh.requestTable.myTeamsProcessingRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/processing"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/expense`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"expense"}
              sectionName={"portalrh.requestTable.myTeamsExpenseRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/expense"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/absenceRecord`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"absenceRecord"}
              sectionName={"portalrh.requestTable.myAbsenceRecordRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/absenceRecord"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/extraTime`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"extraTime"}
              sectionName={"portalrh.requestTable.myExtraTimeRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/extraTime"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/insertPresence`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"insertPresence"}
              sectionName={"portalrh.requestTable.myInsertPresenceRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/insertPresence"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/pagamentosBolsa`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              searchFilter={"pagamentosBolsa"}
              sectionName={"portalrh.requestTable.myPagamentosBolsaRequests"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/pagamentosBolsa"}
              designacao={"authorizations"}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/myteam/scheduler`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <Abscense
                portalRhMenus={portalRhMenus}
                designacao={"Scheduler"}
                path={path}
                isEditable={false}
                showCalendarAndTable={true}
                loadEventsStart={loadEventsStart}
                setLoadEventsStart={setLoadEventsStart}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/authorizations/personalData`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={
                "portalrh.requestTable.myTeamAuthorizationPersonalData"
              }
              searchFilter={"authorizations"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/authorizations/personalData"}
              designacao={"authorizations"}
              handleOpenModalAuthorizationsRequests={
                handleOpenModalAuthorizationsRequests
              }
              showModalAuthorizationsRequests={showModalAuthorizationsRequests}
              handleCloseModalAuthorizationsRequests={
                handleCloseModalAuthorizationsRequests
              }
              setShowModalAuthorizationsRequests={
                setShowModalAuthorizationsRequests
              }
              path={path}
              loadEventsStart={true}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/marcacaoFerias`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myTeamVacationsRequests"}
              searchFilter={"marcacaoFerias"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/marcacaoFerias"}
              designacao={"authorizations"}
              handleOpenModalMarcacaoFerias={handleOpenModalMarcacaoFerias}
              showModalMarcacaoFerias={showModalMarcacaoFerias}
              handleCloseModalMarcacaoFerias={handleCloseModalMarcacaoFerias}
              setShowModalMarcacaoFerias={setShowModalMarcacaoFerias}
              path={path}
              isEditable={true}
              showCalendarAndTable={true}
              loadEventsStart={true}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam/remoteWork`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <MyTeamRequestPage
              showFlowModal={showFlowModal}
              handleOpenFlowModal={handleOpenFlowModal}
              handleCloseFlowModal={handleCloseFlowModal}
              selectedProcess={selectedProcess}
              sectionName={"portalrh.requestTable.myTeamWorkRequests"}
              searchFilter={"remoteWork"}
              portalRhMenus={portalRhMenus}
              activeSelected={"myteam/remoteWork"}
              designacao={"authorizations"}
              handleOpenModalRemoteWork={handleOpenModalRemoteWork}
              showModalRemoteWork={showModalRemoteWork}
              handleCloseModalRemoteWork={handleCloseModalRemoteWork}
              setShowModalRemoteWork={setShowModalRemoteWork}
              path={path}
              isEditable={true}
              showCalendarAndTable={true}
              loadEventsStart={true}
              makeUpdate={makeUpdate}
              setmakeUpdate={setmakeUpdate}
              callGetRequestsByUsername={callGetRequestsByUsername}
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/myteam/workDays`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <WorkDays
                portalRhMenus={portalRhMenus}
                designacao={"workDays"}
                path={path}
                isEditable={false}
                showCalendarAndTable={true}
                loadEventsStart={true}
                hideChangeView={true}
                exportBtn={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/myteam/vacationsManagement`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <VacationManagement
                portalRhMenus={portalRhMenus}
                designacao={"vacationsManagement"}
                path={path}
                isEditable={false}
                showCalendarAndTable={true}
                loadEventsStart={true}
                hideChangeView={true}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/myteam/bolsaHoras`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <BolsaHoras
                portalRhMenus={portalRhMenus}
                designacao={"Bolsa Horas"}
                path={path}
                showDrop={true}
                handleOpenFlowModal={handleOpenFlowModal}
                handleCloseFlowModal={handleCloseFlowModal}
                selectedProcess={selectedProcess}
                showFlowModal={showFlowModal}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/myteam/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/myteam`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/siadapra/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/siadapra/DesempenhoSiadapra`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/siadapra`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/communication/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/communication`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/administration/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/administration`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/administration/exportShedules`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ExportSchedules />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/administration/exportAttendance`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ExportAttendance />
          </ProtectedPage>
        </Route>
        <Route
          exact
          path={`${path}/administration/parameterization/emailTemplate`}
        >
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <EmailMessage
              portalRhMenus={portalRhMenus}
              activeSelected={"administration/parameterization/emailTemplate"}
              designacao={"Parametrização"}
            />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/administration/parameterization/annexDocs`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <AnnexDocuments
              portalRhMenus={portalRhMenus}
              activeSelected={"administration/parameterization/annexdocs"}
              designacao={"Parametrização"}
            />
          </ProtectedPage>
        </Route>
        <Route
          exact
          path={`${path}/administration/parameterization/docsDisclaimer`}
        >
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <DocsDisclaimer
              portalRhMenus={portalRhMenus}
              activeSelected={"administration/parameterization/docsdisclaimer"}
              designacao={"Parametrização"}
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/rhadmin/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/rhadmin/document-management`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ContentContainer>
              <DocumentManagement
                portalRhMenus={portalRhMenus}
                activeSelected={"rhadmin/document-management"}
                designacao={"Colaboradores"}
              />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/errorlogs`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ErrorLogs
              portalRhMenus={portalRhMenus}
              designacao={"RH Admin"}
              path={path}
            />
          </ProtectedPage>
        </Route>

        {/* <Route exact path={`${path}/rhadmin`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route> */}
        <Route path={`${path}/configuration/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/configuration`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/receipts/frame`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <IframeHandler name="portalrh" />
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/receipts`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route exact path={`${path}/processos`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/submenu`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ProcessosFormHandler
              app={PORTALRH_MIDDLEWARE.context}
              className="main-card-v2"
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/generalComponent`}>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
            <ProcessosFormHandler
              app={PORTALRH_MIDDLEWARE.context}
              className="main-card-v2"
              general={true}
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/tasks`} exact>
          <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
              <Tasks
                app={PORTALRH_MIDDLEWARE.context}
                className="main-card-v2"
                styleSheet={styleSheet}
                translations={translations}
              />
          </ProtectedPage>
        </Route>
        <Route exact path={path}>
          {isMongoOn || showMenu ? (
            <ProtectedPage roles={["ADMIN", "PORTALRHUser"]}>
              <ContentContainer>
                <WidgetContainer />
              </ContentContainer>
            </ProtectedPage>
          ) : (
            <Spinner spinning={!showMenu} />
          )}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={!showMenu}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Route>
      </Switch>
    </>
  );
};
export default withNamespaces()(PortalRH);
