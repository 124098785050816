import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { useSelector } from "react-redux";
import { generateTreeMenu } from "~/containers/Menus_v2/utils";
import { NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";

const Sidebar = (props) => {
  const { menuSideBar, path, t } = props;
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [menuRoute, setMenuRoute] = useState([]);
  const [pathRoute, setPathRoute] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const lastField = url.split("/uniksystem/").pop();
    const foundItem = menu.find((item) => item.route === lastField);
    if (foundItem) {
      const foundIndex = menuRoute.findIndex(
        (item) => item.route === foundItem.route
      );
      setActiveMenuItem(foundIndex !== -1 ? foundIndex : null);
    }
  }, [window.location.href, menuRoute, menu]);

  useEffect(() => {
    const new_menus = [];
    if (menuSideBar != null) {
      for (let item of menu) {
        for (let child of menuSideBar.children) {
          if (item.name === child.designacao) {
            new_menus.push(item);
            break; // Se encontrou correspondência, interrompe o loop interno
          }
        }
      }
    }
    //ISTO È PARA REMOVER QUANDO O ROUTE DO OBJECTO FOR MUDADO
    const removedPath = path.replace("/portalrh", "");
    setPathRoute(removedPath);
    setMenuRoute(new_menus);
  }, [menuSideBar]);

  return (
    <>
      {menuRoute && (
        <div style={{ display: "flex", overflow: "scroll initial" }}>
          <CDBSidebar
            textColor="#1e0363"
            backgroundColor="#e7e7e7"
            style={{ width: "230px", minWidth: "200px" }}
          >
            <CDBSidebarHeader>
              <a
                className="text-decoration-none"
                style={{ color: "inherit", all: "unset" }}
              >
                {menuSideBar?.designacao}
              </a>
            </CDBSidebarHeader>

            <CDBSidebarContent className="sidebar-content">
              <CDBSidebarMenu>
                {menuRoute?.map((child, index) => (
                  <NavLink exact to={`${pathRoute}/${child.route}`} key={index}>
                    <CDBSidebarMenuItem
                      className={`sidebarClass ${
                        index === activeMenuItem ? "active" : ""
                      }`}
                      onClick={() => setActiveMenuItem(index)}
                    >
                      {t("menu.portalrh.subMenus." + child.name)}
                    </CDBSidebarMenuItem>
                  </NavLink>
                ))}
              </CDBSidebarMenu>
            </CDBSidebarContent>
          </CDBSidebar>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(Sidebar);
