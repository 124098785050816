import { takeLatest } from "redux-saga/effects";
import {
  GET_ADDRESS,
  GET_PERSONAL_DATA,
  GET_PERSONALDATADROPDOWN,
  GET_ACADEMICQUALIFICATIONS,
  GET_ACADEMICDROPDOWN,
  GET_TAXDATA,
  GET_TAXDATA_DROPDOWN,
  GET_HOUSEHOLD,
  GET_HOUSEHOLD_DROPDOWN,
  GET_PAYMENTSDATA,
  GET_TRAINING,
  GET_TRAINING_DROPDOWN,
  GET_PAYMENTS_DROPDOWN,
  GET_PER_EV_FORM,
  GET_PER_EV_FORM_DROPDOWN,
  GET_MEDICALRECORD,
  GET_HOUSEHOLD_AGFCODE_LAST,
  GET_COLLABORATOR_DATA,
  UPDATE_ADDRESS,
  UPDATE_PERSONAL_DATA,
  UPDATE_ACADEMICQUALIFICATIONS,
  UPDATE_TAXDATA,
  UPDATE_HOUSEHOLD,
  UPDATE_PAYMENTSDATA,
  UPDATE_TRAINING,
  UPDATE_PER_EV_FORM,
  UPDATE_MEDICALRECORD,
  CREATE_PERSONAL_DATA,
  CREATE_ADDRESS,
  CREATE_ACADEMICQUALIFICATIONS,
  CREATE_TAXDATA,
  CREATE_HOUSEHOLD,
  CREATE_PAYMENTSDATA,
  CREATE_TRAINING,
  CREATE_PER_EV_FORM,
  CREATE_MEDICALRECORD,
  UPDATE_NOT_MAIN_ADDRESS,
  UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS,
} from "../ducks/collaboratorPersonalData/actionTypes";
import {
  getAddress,
  getPersonalData,
  getPersonalDataDropdown,
  getAcademicQualifications,
  getAcademicDropdown,
  getTaxData,
  getTaxDataDropdown,
  getHouseHold,
  getHouseHoldDropdown,
  getPaymentsData,
  getTraining,
  getTrainingDropdown,
  getPaymentsDropdown,
  getPerEvForm,
  getPerEvFormDropdown,
  getMedicalRecord,
  getHouseHoldAgfCodeLast,
  getCollaboratorData,
  updateAddress,
  updatePersonalData,
  updateAcademicQualifications,
  updateTaxData,
  updateHousehold,
  updatePaymentsData,
  updateTraining,
  updatePerEvForm,
  updateMedicalRecord,
  createPersonalData,
  createAddress,
  createAcademicQualifications,
  createTaxData,
  createHousehold,
  createPaymentsData,
  createTraining,
  createPerEvForm,
  createMedicalRecord,
  updateNotMainAddress,
  updateNotMainAcademicQualifications,
} from "../ducks/collaboratorPersonalData/sagas";

export const personalDataSagas = [
  //ColalboratoData
  takeLatest(GET_COLLABORATOR_DATA, getCollaboratorData),

  //PersonalData
  takeLatest(GET_PERSONAL_DATA, getPersonalData),
  takeLatest(GET_PERSONALDATADROPDOWN, getPersonalDataDropdown),
  takeLatest(UPDATE_PERSONAL_DATA, updatePersonalData),
  takeLatest(CREATE_PERSONAL_DATA, createPersonalData),
  //AdressData
  takeLatest(GET_ADDRESS, getAddress),
  takeLatest(UPDATE_ADDRESS, updateAddress),
  takeLatest(CREATE_ADDRESS, createAddress),
  takeLatest(UPDATE_NOT_MAIN_ADDRESS, updateNotMainAddress),

  //AcademicQualifications
  takeLatest(GET_ACADEMICQUALIFICATIONS, getAcademicQualifications),
  takeLatest(GET_ACADEMICDROPDOWN, getAcademicDropdown),
  takeLatest(UPDATE_ACADEMICQUALIFICATIONS, updateAcademicQualifications),
  takeLatest(CREATE_ACADEMICQUALIFICATIONS, createAcademicQualifications),
  takeLatest(
    UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS,
    updateNotMainAcademicQualifications
  ),

  //Tax Data
  takeLatest(GET_TAXDATA, getTaxData),
  takeLatest(GET_TAXDATA_DROPDOWN, getTaxDataDropdown),
  takeLatest(UPDATE_TAXDATA, updateTaxData),
  takeLatest(CREATE_TAXDATA, createTaxData),

  //HOUSE HOLD
  takeLatest(GET_HOUSEHOLD, getHouseHold),
  takeLatest(GET_HOUSEHOLD_DROPDOWN, getHouseHoldDropdown),
  takeLatest(UPDATE_HOUSEHOLD, updateHousehold),
  takeLatest(CREATE_HOUSEHOLD, createHousehold),

  //HOUSEHOLD AGFCODE LAST
  takeLatest(GET_HOUSEHOLD_AGFCODE_LAST, getHouseHoldAgfCodeLast),

  //PAYMENTS DATA
  takeLatest(GET_PAYMENTSDATA, getPaymentsData),
  takeLatest(GET_PAYMENTS_DROPDOWN, getPaymentsDropdown),
  takeLatest(UPDATE_PAYMENTSDATA, updatePaymentsData),
  takeLatest(CREATE_PAYMENTSDATA, createPaymentsData),

  //TRAINING DATA
  takeLatest(GET_TRAINING, getTraining),
  takeLatest(GET_TRAINING_DROPDOWN, getTrainingDropdown),
  takeLatest(UPDATE_TRAINING, updateTraining),
  takeLatest(CREATE_TRAINING, createTraining),

  //Performance Evaluation Sheet
  takeLatest(GET_PER_EV_FORM, getPerEvForm),
  takeLatest(GET_PER_EV_FORM_DROPDOWN, getPerEvFormDropdown),
  takeLatest(UPDATE_PER_EV_FORM, updatePerEvForm),
  takeLatest(CREATE_PER_EV_FORM, createPerEvForm),

  //MEDICAL RECORD
  takeLatest(GET_MEDICALRECORD, getMedicalRecord),
  takeLatest(UPDATE_MEDICALRECORD, updateMedicalRecord),
  takeLatest(CREATE_MEDICALRECORD, createMedicalRecord),
];
