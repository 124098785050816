import { takeLatest } from "redux-saga/effects";

import { FIND_DOCUMENT, FIND_ALL_DOCUMENT_IFLOW_BINARY } from "../ducks/iflow/actionTypes";

import { findDocument,findAllDocumentIflowBinary } from "../ducks/iflow/sagas";

export const iflowSagas = [
  takeLatest(FIND_DOCUMENT, findDocument),
  takeLatest(FIND_ALL_DOCUMENT_IFLOW_BINARY, findAllDocumentIflowBinary),
];
