/*Reports used in widgets*/ 
export const SET_REPORT_PERFORMANCE = 'iflowReports@SET_REPORT_PERFORMANCE';
export const FIND_REPORT_PERFORMANCE= 'iflowReports@FIND_REPORT_PERFORMANCE';

export const setReportPerformance = (report) => { return { type: SET_REPORT_PERFORMANCE, data: report } }
export const findReportPerformance = (payload) => ({ type: FIND_REPORT_PERFORMANCE, data: payload })

export const SET_REPORT_SLA = 'iflowReports@SET_REPORT_SLA';
export const FIND_REPORT_SLA= 'iflowReports@FIND_REPORT_SLA';

export const setReportSla = (report) => { return { type: SET_REPORT_SLA, data: report } }
export const findReportSla = (payload) => ({ type: FIND_REPORT_SLA , data: payload})

export const SET_REPORT_PROCESS_STATISTICS = 'iflowReports@SET_REPORT_PROCESS_STATISTICS';
export const FIND_REPORT_PROCESS_STATISTICS= 'iflowReports@FIND_REPORT_PROCESS_STATISTICS';

export const setReportProcessStatistics = (report) => { return { type: SET_REPORT_PROCESS_STATISTICS, data: report } }
export const findReportProcessStatistics = (payload) => ({ type: FIND_REPORT_PROCESS_STATISTICS, data: payload })

export const SET_REPORT_PROCESSES_STATUS = 'iflowReports@SET_REPORT_PROCESSES_STATUSA';
export const FIND_REPORT_PROCESSES_STATUS= 'iflowReports@FIND_REPORT_PROCESSES_STATUS';

export const setReportProcessesStatus = (report) => { return { type: SET_REPORT_PROCESSES_STATUS, data: report } }
export const findReportProcessesStatus = (payload) => ({ type: FIND_REPORT_PROCESSES_STATUS , data: payload})
