import { find, findIndex, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import update from "react-addons-update";
import {
  Button,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import uuid from "uuid/v1";
import { generateTreeMenu } from "~/containers/Menus/utils";
import {
  deleteApplicationMenu,
  editApplicationMenu,
} from "~/store/ducks/applicationMenu/actionTypes";
import MenuForm from "./MenuForm";

const Menu = ({ t, application }) => {
  const dispatch = useDispatch();
  const { settingsMenu: menu } = useSelector(
    (state) => state.applicationMenuReducer
  );

  const [validated, setValidated] = useState(false);
  const [treeMenu, setTreeMenu] = useState();
  const [showModalDelete, setShowModalDelete] = useState();
  const [showModalEdit, setShowModalEdit] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const [availablePerms, setAvailablePerms] = useState([]);
  const [selectedPerms, setSelectedPerms] = useState([]);

  const { permissions } = useSelector((state) => state.permissionReducer);

  useEffect(() => {
    setTreeMenu(generateTreeMenu(menu));
  }, [menu]);

  const toggleShow = (item) => {
    const foundedItem = find(treeMenu, { id: item.id });
    const index = findIndex(treeMenu, { id: item.id });
    foundedItem.show = !foundedItem.show;
    const changedTreeMenu = update(treeMenu, {
      [index]: { $set: foundedItem },
    });
    setTreeMenu(changedTreeMenu);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  const deleteItem = () => {
    dispatch(deleteApplicationMenu(selectedItem.id));
    setShowModalDelete(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowModalEdit(true);
  };

  const handleCloseEdit = () => {
    setShowModalEdit(false);
    setValidated(false);
  };

  const getStyleMenu = (item) => {
    return {
      cursor: !isEmpty(item.childrens) ? "pointer" : "",
      background: item.mainMenu ? "#4484f4" : "",
      color: item.mainMenu ? "#fff" : "",
    };
  };

  const getFormattedNameMenu = (item) =>
    item.mainMenu ? t(`menu.mainItems.${item.name}`) : t(item.name);

  const generateTooltip = (item) => [
    { key: "Name", value: item.name },
    { key: "Icon", value: item.icon },
    { key: "Route", value: avoidNullValue(item.route) },
    { key: "Order", value: avoidNullValue(item?.order) },
    { key: "Permissions", value: item.permissions.map((perm) => perm.label) },
  ];

  const avoidNullValue = (prop) => prop || "Não informado";

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    try {
      if (selectedItem?.extraConfiguration) {
        JSON.parse(selectedItem.extraConfiguration);
      }
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
      } else {
        updateApplicationMenu();
      }
    } catch (e) {
      toast.error("❌ " + t("admin.applicationMenu.validateExtraConfig"), {
        toastId: "1",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateApplicationMenu = () => {
    dispatch(editApplicationMenu(selectedItem));
    setShowModalEdit(false);
  };

  return (
    <>
      {!isEmpty(treeMenu) && <h1 className="display-4">Menu</h1>}
      <ListGroup>
        {!isEmpty(treeMenu) &&
          treeMenu.map((item) => (
            <React.Fragment key={uuid()}>
              <OverlayTrigger
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Content style={{ whiteSpace: "pre-line" }}>
                      {generateTooltip(item).map((item, index) => (
                        <div key={index}>
                          <b>{`${item.key}: `}</b> {`${item.value}`}
                        </div>
                      ))}
                    </Popover.Content>
                  </Popover>
                }
              >
                <ListGroup.Item
                  key={uuid()}
                  style={getStyleMenu(item)}
                  onClick={() => toggleShow(item)}
                >
                  {item.show && !isEmpty(item.childrens) && (
                    <i style={{ marginRight: "5px" }}>
                      <IoIosArrowDown />
                    </i>
                  )}
                  {!item.show && !isEmpty(item.childrens) && (
                    <i style={{ marginRight: "5px" }}>
                      <IoIosArrowForward />
                    </i>
                  )}
                  <i className={item.icon}></i>
                  {getFormattedNameMenu(item)}
                  <i
                    className="icon-trash icon-menu-settings"
                    onClick={() => handleDelete(item)}
                  />
                  <i
                    className="icon-edit icon-menu-settings"
                    onClick={() => handleEdit(item)}
                  />
                </ListGroup.Item>
              </OverlayTrigger>
              {!isEmpty(item.childrens) &&
                item.childrens.map(
                  (children) =>
                    item.show && (
                      <ListGroup.Item key={uuid()}>
                        <div style={{ paddingLeft: "1.3rem" }}>
                          <i className={children.icon}></i>
                          {t(children.name)}
                          <i
                            className="icon-trash icon-menu-settings"
                            onClick={() => handleDelete(children)}
                          />
                          <i
                            className="icon-edit icon-menu-settings"
                            onClick={() => handleEdit(children)}
                          />
                        </div>
                      </ListGroup.Item>
                    )
                )}
            </React.Fragment>
          ))}
      </ListGroup>

      <Modal
        show={showModalDelete}
        onHide={handleCloseDelete}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("admin.applicationMenu.deleteMenu")}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseDelete}>
            {t("general.cancel")}
          </Button>
          <Button className="modal-submit-button" onClick={deleteItem}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalEdit}
        onHide={handleCloseEdit}
        size="lg"
        dialogClassName="custom-modal"
      >
        <Form
          id="menu-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          method="post"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("general.edit")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MenuForm
              application={application}
              applicationMenu={selectedItem}
              setApplicationMenu={setSelectedItem}
              availablePerms={availablePerms}
              setAvailablePerms={setAvailablePerms}
              selectedPerms={selectedPerms}
              setSelectedPerms={setSelectedPerms}
              permissions={permissions}
              isEditing={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseEdit}>
              {t("general.cancel")}
            </Button>
            <Button
              className="modal-submit-button"
              type="submit"
              style={{ marginLeft: "10px", marginRight: "55px" }}
            >
              {t("general.save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default withNamespaces()(Menu);
