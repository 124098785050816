import React from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import "../../assets/css/icons.css";
import { withNamespaces } from "react-i18next";

const CustomDropdown = ({
  apagarWidget,
  items,
  handleMouseDown,
  handleSelect,
  ...props
}) => {
  const { t } = props;
  const handleClick = (name) => {
    handleSelect && handleSelect(name);
  };

  return (
    <Dropdown as={ButtonGroup} alignRight style={{ cursor: "pointer" }}>
      <Dropdown.Toggle as={Dropdown.Header} variant="link">
        <i className="icon-gear" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu">
        {items &&
          items.map((item, index) => {
            return (
              <Dropdown.Item
                key={index}
                href="#"
                onClick={() => handleClick(item.name)}
              >
                {item.name}
              </Dropdown.Item>
            );
          })}
        <Dropdown.Item onClick={apagarWidget}>
          {t("widgets.remove")}{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default withNamespaces()(CustomDropdown);
