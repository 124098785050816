import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, Row, Col } from 'react-bootstrap';
import '~/assets/css/styles.css';
import '~/assets/css/icons.css';
/*TODO remove component*/
const PreviewModal = props => {

  const { t, showModal, closeModal, process } = props;
  const buildSLAColumn = (SLA) => {
    if (SLA) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2)
        const month = dateStr.slice(2, 4)
        const year = dateStr.slice(4, 8)
        const hour = dateStr.slice(8, 10)
        const min = dateStr.slice(10, 12)
        const sec = dateStr.slice(12, 14)
        return new Date(year, month - 1, day, hour, min, sec)
      }
      const date = toDate(SLA);
      return date ? date.toLocaleString() : ""
    }
    return ""
  };

  const renderLines = () => {
    if (process && process.metadata.length === 0)
      return (
        <div>Dados não encontrados</div>
      )
    else return process && process.metadata.map((data, index) => {
      if (data.value !== undefined && data.value !== null && data.value !== "") {
        if (data.name === "SLA")
          data.value = buildSLAColumn(data.value);
        return (
          < Row key={index} >
            <Col sm="6">
              <strong> {t(`taskPage.general.${data.name}`)}:</strong>
            </Col>
            <Col sm="6">
              <span>{data.value}</span>
            </Col>
          </Row >
        )
      }
    })
  }

  return (
    <Modal
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop='static'
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('taskPage.general.process')} &nbsp;{process && process.pid}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br />
        {renderLines()}
      </Modal.Body >
    </Modal >
  )
}

export default withNamespaces()(PreviewModal);
