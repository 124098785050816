import React, { useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_activity.scss";
import iconPlus from "~/pages/Liftworld/assets/img/icon-plus-filled.png";
import uuid from "uuid/v1";
//import InputDate from "./InputDate";
import InputDate from "~/pages/Easypay/components/InputDate";
import Textbox from "./Textbox";
import Option from "./Option";
import Input from "./Input";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import AddButton from "~/components/Buttons/AddButton";

const Activity = ({ info, index, itemIndex, workplanIndex }) => {
  const { register, formState, watch } = useForm();

  const [showContent, setShowContent] = useState(true);

  const dispatch = useDispatch();

  const newOption = {
    selected: false,
    imprimir : false,
    nomeOpcao: "",
    valorCustoOpt: 0,
    valorVendaOpt: 0,
    observaceosOpt: "",
    imagensOpt: [],
    horas: [],
    fornecedores: [],
    despesas: [],
  };

  function numberWithCommas(x) {

    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".")
    const finalString = newArray[0].replace(",",".") + "," + newArray[1]
    return finalString;
  }

  function handleShowContent() {
    setShowContent(!showContent);
  }

  const { workplans } = useSelector((state) => state.budgetManagementReducer);

  function handleDateSubmit(dates) {
    if (itemIndex != null && index != null) {
      const date1 = new Date(dates[1]);
      const date2 = new Date(dates[0]);

      // To calculate the time difference of two dates
      const Difference_In_Time = date1.getTime() - date2.getTime();

      // To calculate the no. of days between two dates
      const Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      if (Array.isArray(workplans)) {
        workplans[workplanIndex].items[itemIndex].atividades[
          index
        ].dataInicioAct = dates[0];
        workplans[workplanIndex].items[itemIndex].atividades[index].dataFimAct =
          dates[1];
        workplans[workplanIndex].items[itemIndex].atividades[index].duracaoAct =
          Difference_In_Days;
        dispatch(setAllWorkplans(workplans));
      } else {
        workplans.items[itemIndex].atividades[index].dataInicioAct = dates[0];
        workplans.items[itemIndex].atividades[index].dataFimAct = dates[1];
        workplans.items[itemIndex].atividades[index].duracaoAct =
          Difference_In_Days;
        dispatch(setAllWorkplans(workplans));
      }
    }
  }

  function addOption() {
    workplans[workplanIndex].items[itemIndex].atividades[index].opcoes.push(
      newOption
    );
    if(workplans[workplanIndex].items[itemIndex].atividades[index].opcoes.length > 1)
    workplans[workplanIndex].imprimir = true
    
    dispatch(setAllWorkplans(workplans));
  }

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  function deleteActivity() {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    workplans[workplanIndex].items[itemIndex].atividades.splice(index, 1);
    dispatch(setAllWorkplans(workplans));
  }
  return (
    <div style={{ paddingTop: "20px" }}>
      <div className="liftworld-item-option-row-wrapper">
        <div style={{ display: "flex", width: "50%", alignItems: "center" }}>
          <div
            className="easypay-text-icon"
            onClick={() => handleShowContent()}
          >
            <img src={iconPlus} alt="Icon Check" />
          </div>
          <div style={{ width: "100%" }}>
            <Input
              field={buildInputField(
                "Atividade Secundária " + (index + 1),
                info.nomeAtividade
              )}
              key={uuid()}
              color={"activity"}
              size={"large-size"}
              itemIndex={itemIndex}
              activityIndex={index}
              name={"nomeAtividade"}
              workplanIndex={workplanIndex}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Textbox
            title={"Valor de Custo"}
            value={
              isNaN(info.valorCustoAct)
                ? "0 €"
                : numberWithCommas(parseFloat(info.valorCustoAct).toFixed(2)) +
                  " €"
            }
            size={"normal"}
            color={"activity"}
          />
          <Textbox
            title={"Valor de Venda"}
            value={
              isNaN(info.valorVendaAct)
                ? "0 €"
                : numberWithCommas(parseFloat(info.valorVendaAct).toFixed(2)) +
                  " €"
            }
            size={"normal"}
            color={"activity"}
          />
          <div className="liftworld-delete-button-wrapper">
            <div className="easypay-text-icon" onClick={() => deleteActivity()}>
              <i className="icon-trash table-action-icon" />
            </div>
          </div>
        </div>
      </div>
      {showContent && (
        <>
          <div className="liftworld-activity-date-inputs-wrapper">
          <div className="liftworld-input-date-name-wrapper">
              <div className="liftworld-label-wrapper">
                Data Início/Data Fim
              </div>
              <InputDate
                text={""}
                register={register}
                formState={formState}
                errorMessage="This field is required"
                watch={watch}
                type="created_at"
                placeholder=""
                defaultValue={[new Date(info.dataInicioAct), new Date(info.dataFimAct)]}
                handleSubmit={(dates) => handleDateSubmit(dates)}
              />
            </div>
            <div className="liftworld-duration-input-wrapper">
              <Textbox
                title={"Duração"}
                value={info.duracaoAct}
                size={"normal"}
                color={"normal"}
              />
            </div>
          </div>
          <div className="liftworld-activity-content-wrapper">
            {info.opcoes &&
              Object.keys(info.opcoes).length !== 0 &&
              info.opcoes.map((item, optionIndex) => {
                return (
                  <Option
                    info={item}
                    index={optionIndex}
                    itemIndex={itemIndex}
                    activityIndex={index}
                    workplanIndex={workplanIndex}
                  />
                );
              })}
          </div>
          <div className="liftworld-button-option-wrapper">
            <AddButton text="Adicionar Nova Opção" onClick={() => addOption()} />
          </div>
        </>
      )}
    </div>
  );
};
export default Activity;
