import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { abbreviateString } from "~/utils";
import pdfPreview from "~/assets/img/datacapture/pdf_preview.svg";
import zipPreview from "~/assets/img/datacapture/zip_preview.svg";
import txtPreview from "~/assets/img/datacapture/txt_preview.png";

const FilePreviewCard = ({
  document,
  t,
  handleDeleteDocument,
  mindee
}) => {
  const [showDeleteDocument, setShowDeleteDocument] = useState(false);

  const { file } = document;
  const getPreviewImage = () => {
    if (file) {
      return file.type === "application/pdf"
        ? pdfPreview
        : file.type === "application/x-zip-compressed"
        ? zipPreview
        : file.type === "text/plain"
        ? txtPreview
        : URL.createObjectURL(file);
    } else {
      return document.filetype === "application/pdf"
        ? pdfPreview
        : document.filetype === "application/x-zip-compressed"
        ? zipPreview
        : document.filetype === "text/plain"
        ? txtPreview
        : "data:image/jpg;base64," + document.data;
    }
  };
  const filename = file ? file.name : (document.filename ? document.filename : "");

  return (
    <div style={{display: mindee ? "inline-block": "flex", flexDirection: "column"}}>
      <img
        src={getPreviewImage()}
        className="dc-image-preview"
        alt="preview"
      />
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={<Tooltip>{filename}</Tooltip>}
      >
        <small>{abbreviateString(filename, 18)}</small>
      </OverlayTrigger>
      <span
        className="dc-remove-file"
        onClick={() => setShowDeleteDocument(true)}
      >
        &times;
      </span>

      <Modal
        show={showDeleteDocument}
        onHide={() => setShowDeleteDocument(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {document !== undefined ? (
          <Modal.Body>
            {`${t("datacapture.general.deleteDocument")} ${
              document?.file?.filename || ""
            } ?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="danger" onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleDeleteDocument(document.id);
            setShowDeleteDocument(false);
          }}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withNamespaces()(FilePreviewCard);
