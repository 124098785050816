import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { getInformationProcessAuthorization } from "~/store/ducks/portalrh/actionTypes";
import { format, parseISO } from "date-fns";
import { Button as ButtonMaterial, Stack, Box } from "@mui/material";
import TranferList from "./TranferList";
import Dropdown from "~/components/Dropdown";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import Spinner from "~/components/Spinner";
import {
  FaClock,
  FaCheckCircle,
  FaTimesCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";

const ModalAuthorizationRequests = ({
  process,
  closeButton,
  showModalAuthorizationsRequests,
  handleCloseModalAuthorizationsRequests,
  setmakeUpdate,
  path,
  portalRhMenus,
  designacao,
  styleSheet,
  searchFilter,
  t,
}) => {
  const dispatch = useDispatch();
  const {
    collaborators,
    infoProcessAuthorization,
    isLoadingInfoProcessAuthorization,
  } = useSelector((state) => state.portalrhReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { genericAuthorizationState } = useSelector(
    (state) => state.portalrhReducer
  );
  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  const [infoData, setinfoData] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [closeFormsText, setCloseFormsText] = useState(false);
  const [modalConfirmation, setmodalConfirmation] = useState(false);
  const [notification, setNotification] = useState(false);
  const [conditionApproveRequest, setConditionApproveRequest] = useState(false);
  const [assunto, setAssunto] = useState("");
  const [comentario, setComentario] = useState("");
  const [arquivo, setArquivo] = useState(null);
  const [tipoDocumento, setTipoDocumento] = useState(null);
  const [cleanDropdown, setCleanDropdown] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (process != undefined) {
      const payload = {
        id: process.id,
      };
      dispatch(getInformationProcessAuthorization(payload));
    }
  }, [process]);

  useEffect(() => {
    setinfoData(infoProcessAuthorization);
  }, [infoProcessAuthorization]);

  const createStateChecked = (item) => {
    const hasStateId3 = infoData.autorizacao.some(
      (autorizacao) => autorizacao.state_id === "3"
    );
    const hasStateId4 = infoData.autorizacao.some(
      (autorizacao) => autorizacao.state_id === "4"
    );
    let icon;
    if (hasStateId3) {
      icon = (
        <OverlayTrigger
          key={item.id} // Supondo que cada autorizacao tem um id único
          overlay={<Tooltip>{t(`portalrh.workDays.Aprovado`)}</Tooltip>}
          placement="right"
        >
          {item.state_id === "3" ? (
            <FaCheckCircle style={{ color: "#008000" }} />
          ) : (
            <FaRegCircleCheck style={{ color: "#008000" }} />
          )}
        </OverlayTrigger>
      );
    } else if (hasStateId4) {
      icon = (
        <OverlayTrigger
          key={item.id} // Supondo que cada autorizacao tem um id único
          overlay={<Tooltip>{t(`portalrh.workDays.Recusado`)}</Tooltip>}
          placement="right"
        >
          {item.state_id === "4" ? (
            <FaTimesCircle style={{ color: "#FF0000" }} />
          ) : (
            <FaRegTimesCircle style={{ color: "#FF0000" }} />
          )}
        </OverlayTrigger>
      );
    } else {
      icon = (
        <OverlayTrigger
          key={item.id} // Supondo que cada autorizacao tem um id único
          overlay={<Tooltip>{t(`portalrh.workDays.${item.state}`)}</Tooltip>}
          placement="right"
        >
          <FaClock style={{ color: "#FFA500" }} />
        </OverlayTrigger>
      );
    }
    return icon;
  };

  const handleApproveRequest = (aceptReject) => {
    if (typeof aceptReject === "boolean") {
      setConditionApproveRequest(aceptReject);
      setmodalConfirmation(true);
    } else {
      setNotification(true);
    }
    setCloseFormsText(true);
  };

  const handleSend = async () => {
    setmodalConfirmation(false);
    setNotification(false);
    setCloseFormsText(false);
    let payload;
    if (conditionApproveRequest) {
      payload = {
        ped_id: process.id,
        state: 1,
        username: userNamePortalRH.username,
      };
    } else {
      payload = {
        ped_id: process.id,
        state: 0,
        username: userNamePortalRH.username,
      };
    }

    //await dispatch(approveDeleteRemoveMarcacaoFerias(payload));

    setTimeout(() => {
      setmakeUpdate(true);
      handleCloseModalAuthorizationsRequests(searchFilter, 0, true);
    }, 200);
  };

  const handleSendNotification = () => {};

  const stateArray = {
    [t("portalrh.workDays.feriasTranslated.optFirstPart")]: "1",
    [t("portalrh.workDays.feriasTranslated.optSecoundPart")]: "2",
  };

  const renderOptions = () => {
    return Object.entries(stateArray).map(([key, value]) => {
      return {
        label: key,
        value: value,
        selected: false,
      };
    });
  };

  return (
    <>
      {Object.keys(infoData).length > 0 ? (
        <>
          <Modal
            show={showModalAuthorizationsRequests}
            dialogClassName={"modal-tarefa-97w custom-modal"}
            centered
            backdrop="static"
            onHide={() => {
              handleCloseModalAuthorizationsRequests(searchFilter, 0, true);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("portalrh.authorizationsRequests.informacaoProcesso")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={
                  styleSheet?.processScroll
                    ? "scrollBar-visibleModalMyTeamFerias"
                    : ""
                }
              >
                <Row>
                  <Col lg="4">
                    <div
                      style={{
                        margin: isMobile ? "0px" : "20px 30px",
                      }}
                    >
                      <div className="custom-table custom-table-small tableDateInformation scrollBar-tableDateInformation">
                        <div
                          style={{
                            paddingBottom: "20px",
                            display: "flex",
                          }}
                        >
                          {infoData?.request[0] && (
                            <p>
                              {t("portalrh.workDays.pedidoFeitoPor.1")}{" "}
                              <b>{infoData.request[0].username} </b>
                              {t("portalrh.workDays.pedidoFeitoPor.2")}{" "}
                              <b>
                                {format(
                                  parseISO(infoData.request[0].creation_date),
                                  "dd/MM/yyyy"
                                )}
                              </b>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: isMobile ? "0px" : "20px 30px",
                      }}
                      className="scrollBar-autorizersDateInformation"
                    >
                      <h6>{t("portalrh.workDays.listaAprovadores")}</h6>
                      <ListGroup>
                        {!isLoadingInfoProcessAuthorization &&
                          infoData?.autorizacao &&
                          infoData?.autorizacao.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "self-end",
                                }}
                              >
                                <>
                                  {item.username}{" "}
                                  {item.date !== null ? "-" + item.date : ""}
                                  {createStateChecked(item)}
                                </>
                              </span>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </div>
                    {+genericAuthorizationState === 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0px 10px",
                          marginTop: "20px",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="warning"
                            onClick={() => handleApproveRequest()}
                            style={{ marginLeft: "15px" }}
                            disabled={closeFormsText}
                          >
                            Notificar
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleApproveRequest(false)}
                            disabled={closeFormsText}
                          >
                            {t("portalrh.workDays.reject")}
                          </Button>
                          <Button
                            className="card-button"
                            onClick={() => handleApproveRequest(true)}
                            style={{ marginLeft: "15px" }}
                            disabled={closeFormsText}
                          >
                            {t("portalrh.workDays.approve")}
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  </Col>
                  <Col lg="8">
                    {!closeFormsText ? (
                      <Row>
                        {Object.keys(infoData).map((key) => {
                          if (key === "request" || key === "autorizacao")
                            return null; // Ignore request and autorizacao

                          let colSize = "4"; // Default column size
                          if (key === "biCC1" || key === "biCC2") colSize = "2";
                          if (key === "nome") colSize = "12";

                          return (
                            <Col lg={colSize} md="6" sm="12" key={key}>
                              <Form.Group controlId={`form-${key}`}>
                                <Form.Label>{key}</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={infoData[key]}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <>
                        {notification ? (
                          <div
                            style={{
                              height: "100%",
                              padding: isMobile ? "" : "3rem",
                              alignContent: "center",
                            }}
                          >
                            <Row style={{ margin: "2rem -15px" }}>
                              <Col>
                                <h6>
                                 Enviar notificação para:
                                </h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <Form>
                                  <TranferList />
                                  <Form.Group controlId="freeTextInput">
                                    <Form.Label>Assunto:</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Digite aqui..."
                                      value={assunto}
                                      onChange={(e) =>
                                        setAssunto(e.target.value)
                                      }
                                    />
                                    <Form.Label>Ficheiro:</Form.Label>
                                    <Dropdown
                                      list={renderOptions()}
                                      handleSubmit={(e) => setTipoDocumento(e)}
                                      emptyDefault={true}
                                      resetDropdown={cleanDropdown}
                                      setResetDropdown={setCleanDropdown}
                                    />
                                    <Form.Label>Arquivo:</Form.Label>
                                    <Form.Control
                                      type="file"
                                      style={{ height: "35px" }}
                                      onChange={(e) =>
                                        setArquivo(e.target.files[0])
                                      }
                                    />

                                    <Form.Label>Comentário:</Form.Label>
                                    <Form.Control
                                      style={{
                                        width: "100%",
                                        ...(isMobile ? { padding: "0" } : {}),
                                      }}
                                      as="textarea"
                                      rows={isMobile ? 5 : 3}
                                      value={comentario}
                                      onChange={(e) =>
                                        setComentario(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "0px 10px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      className="card-button-red"
                                      onClick={() => {
                                        setmodalConfirmation(false);
                                        setNotification(false);
                                        setCloseFormsText(false);
                                      }}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      {t("portalrh.workDays.cancel")}
                                    </Button>
                                    <Button
                                      className="card-button"
                                      onClick={handleSendNotification}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      {t("portalrh.workDays.confirmDays")}
                                    </Button>
                                  </Stack>
                                </Box>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              padding: isMobile ? "" : "3rem",
                              alignContent: "center",
                            }}
                          >
                            <Row style={{ margin: "2rem -15px" }}>
                              <Col>
                                <h6>
                                  Pedido de alteração de morada
                                </h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <Form>
                                  <Form.Label>
                                    Deseja aprovar/recusar?
                                  </Form.Label>
                                </Form>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "0px 10px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      className="card-button-red"
                                      onClick={() => {
                                        setmodalConfirmation(false);
                                        setNotification(false);
                                        setCloseFormsText(false);
                                      }}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      {t("portalrh.workDays.cancel")}
                                    </Button>
                                    <Button
                                      className="card-button"
                                      onClick={handleSend}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      {t("portalrh.workDays.confirmDays")}
                                    </Button>
                                  </Stack>
                                </Box>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <Spinner spinning={isLoadingInfoProcessAuthorization} wrapper={true} />
      )}
    </>
  );
};

export default withNamespaces()(ModalAuthorizationRequests);
