import React from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import uuid from "uuid/v1";
import addIconDisabled from "../assets/img/icons/icon-add-disabled.png";
import addDocumentIconDisabled from "../assets/img/icons/icon-add-document-disabled.png";
import addDocumentIcon from "../assets/img/icons/icon-add-document.png";
import addIcon from "../assets/img/icons/icon-add-secondary.png";
import errorDisabled from "../assets/img/icons/icon-error-disabled.png";
import error from "../assets/img/icons/icon-error-light-small.png";
import {
  default as success,
  default as successDisabled,
} from "../assets/img/icons/icon-success-light-small.png";
import uploadIconDisabled from "../assets/img/icons/icon-upload-2.png";
import uploadIcon from "../assets/img/icons/icon-upload.png";
import "../assets/scss/_buttons.scss";

const SimpleButton = ({
  text,
  variant,
  disabled = false,
  disabledColored = false,
  onClick,
  align
}) => {

  return (
    text !== "hidden-button" && (
      <div className={`easypay-button-wrapper ${align}`} key={uuid()}>
        <button
          className={`${variant} "card-button" ${
            disabledColored ? "easypay-button-disabledColored" : ""
          } `}
          disabled={disabled || disabledColored}
          onClick={onClick}
          type="button"
        >
          {variant.includes("success") && (
            <img
              className="easypay-button-approve-img"
              src={disabled ? successDisabled : success}
              alt="Icon success"
            />
          )}
          {variant.includes("error") && (
            <img
              className="easypay-button-approve-img"
              src={disabled ? errorDisabled : error}
              alt="Icon error"
            />
          )}
          {variant === "easypay-button-add" && (
            <img
              className="easypay-button-add-img"
              src={disabled ? addIconDisabled : addIcon}
              alt="Add Icon"
              disabled={disabled}
            />
          )}
          {variant.includes("easypay-document") && (
            <img
              className="easypay-button-add-img easypay-document-img"
              src={disabled ? addDocumentIconDisabled : addDocumentIcon}
              alt="Add Icon"
              disabled={disabled}
            />
          )}
          <span
            className={`easypay-button-icon-text ${
              variant.includes("easypay-document") ? "easypay-22px-text" : ""
            } ${
              variant.includes("easypay-button-add") ? "easypay-20px-text" : ""
            }`}
          >
            {text}
            {(variant === "easypay-button-secondary-icon" ||
              variant === "easypay-button-secondary-icon-large") && (
              <img
                className={`easypay-button-upload-img ${
                  disabledColored ? "easypay-button-img-disabledColored" : ""
                }`}
                src={disabled ? uploadIconDisabled : uploadIcon}
                alt="Upload Icon"
              />
            )}
          </span>
        </button>
      </div>
    )
  );
};

export default withNamespaces()(SimpleButton);
