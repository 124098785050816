import { reject } from "q";
import { put } from 'redux-saga/effects';
import http from '../../../../utils/config/http';

import {
  setPermissions,
  setPermission,
  persistPermissionDone,
  deletePermissionDone
} from '../actions';

import { activeSuccess, activeError } from '../../../../components/Messages/actions';

export function* getPermissions() {
  try {
    const resp = yield http.get('/api/permission/all');
    yield put(setPermissions(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getPermissionById({ id }) {
  try {
    const resp = yield http.get(`/api/permission/find-by-id/${id}`);
    yield put(setPermission(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistPermission({ permission }) {
  try {
    yield http.post('/api/permission/persist', permission);
    yield put(activeSuccess("admin.permission.persist.successMessage"))
    yield put(persistPermissionDone());
  } catch (error) {
    yield put(activeError("admin.permission.persist.errorMessage"))
    yield put(persistPermissionDone());
  }
}

export function* deletePermission({ id }) {
  try {
    yield http.delete(`/api/permission/delete/${id}`);
    yield put(activeSuccess("admin.permission.delete.successMessage"))
    yield put(deletePermissionDone());
  } catch (error) {
    yield put(activeError("admin.permission.delete.errorMessage"))
    yield put(deletePermissionDone());
  }
}
