import React from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "~/pages/Easypay/assets/css/styles.css";
import "~pages/Easypay/assets/scss/_backofficeEasypayLeadsPage.scss";
import BackOfficeAccountSection from "~pages/Easypay/components/BackOfficeAccountSection.js";
import BackOfficeBusinessSection from "~pages/Easypay/components/BackOfficeBusinessSection.js";
import BackofficeProvadeVidaSection from "~pages/Easypay/components/BackofficeProvadeVidaSection.js";
import BackofficeRegistrySection from "~pages/Easypay/components/BackofficeRegistrySection.js";
import Button from "~pages/Easypay/components/Button.js";
import LineBreak from "~pages/Easypay/components/LineBreak.js";
import ProtectedComponent from "~/components/security/ProtectedComponent";

const BackOfficeEasypayLeadsSections = ({ handleClick }) => {
  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function handleSubmit() {}

  return (
    <div className="easypay-backoffice-wrapper-leads">
      <div className="easypay-backoffice-leads-dummy-wrapper"></div>
      <div className="easypay-backoffice-leads-sub-wrapper">
        <div className="easypay-backoffice-leads-form-wrapper">
          <div div="easypay-backoffice-section-1" id="backoffice-0">
            <BackofficeRegistrySection />
          </div>
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <div div="easypay-backoffice-section-2" id="backoffice-1">
            <BackofficeProvadeVidaSection />
          </div>
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <div div="easypay-backoffice-section-3" id="backoffice-2">
            <BackOfficeAccountSection />
          </div>
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <div div="easypay-backoffice-section-4" id="backoffice-3">
            <ProtectedComponent
              permissions={[{ label: "app-onboarding-business-read" }]}
            >
              <BackOfficeBusinessSection />
            </ProtectedComponent>
          </div>
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <div className="easypay-backoffice-submit-button-wrapper">
            <Button
              text={"Guardar alterações"}
              variant={"easypay-button-primary"}
              onclick={handleSubmit}
              key={uuid()}
              id={"buttonId"}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficeEasypayLeadsSections);
