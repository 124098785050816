import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Modal } from "react-bootstrap";
import Preview from "./Preview";
import Spinner from "~/components/Spinner";
import AppVersion from "./AppVersion";
import PreviewMultiple from "./PreviewMultiple";

const SignPdfModal = ({
  t,
  showSignModal,
  handleCloseSignModal,
  testSignPdf,
  documents,
  resultVersionSignPdf,
  isLoadingSignPdfVersion,
}) => {
  const [coordinates, setCoordinates] = useState({ x: 0.1, y: 0.1 });
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (showSignModal) setCoordinates({ x: 0.1, y: 0.1 });
  }, [showSignModal]);

  useEffect(() => {
    if (resultVersionSignPdf) {
      if (
        resultVersionSignPdf.data === "No dlls found" ||
        resultVersionSignPdf.data === "Network Error"
      )
        setActive(false);
      else setActive(true);
    } else setActive(false);
  }, [resultVersionSignPdf]);

  return (
    <Modal
      dialogClassName="modal-50w"
      show={showSignModal}
      onHide={() => handleCloseSignModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("signature.signPdf")}</Modal.Title>
      </Modal.Header>

      <Spinner spinning={isLoadingSignPdfVersion} wrapper />
      <AppVersion resultVersionSignPdf={resultVersionSignPdf} />
      {active && (
        <>
          {documents && documents.length === 1 ? (
            <Preview
              documents={documents}
              handleCloseSignModal={handleCloseSignModal}
              testSignPdf={testSignPdf}
            />
          ) : (
            <PreviewMultiple
              documents={documents}
              handleCloseSignModal={handleCloseSignModal}
              testSignPdf={testSignPdf}
            />
          )}
        </>
      )}
    </Modal>
  );
};
export default withNamespaces()(SignPdfModal);
