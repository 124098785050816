import React from "react";
import { withNamespaces } from "react-i18next";

const CreateEndProcessBadge = (props) => {
  const { t, badgeText, badgeTextDefault, className, translations, language } =
    props;

  const headerTextName = () => {
    let text = undefined;
    if (badgeText) {
      text = badgeTextDefault
    } else {
      text = badgeTextDefault;
    }

    if(text.includes("taskforward.")){
      return badgeText
    }
    return text;
  };

  return (
    <div
      className={className}
      style={{
        minHeight: "42px",
        borderRadius: "23px",
        fontSize: "100%",
        fontWeight: "400",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        width: "fit-content",
        padding: "2px 15px",
      }}
    >
      {headerTextName()}
    </div>
  );
};

export default withNamespaces()(CreateEndProcessBadge);
