import React, { useState, useEffect } from "react";
import { Col, Form, Row, Alert } from "react-bootstrap";
import CreateSearchableDropdown from "../CreateSearchableDropdown";
import CreateTextArea from "../CreateTextArea";
import CreateTableCheckbox from "../CreateCalculosRendimento/CreateTableCheckbox";
import CreateTextInput from "../CreateTextInput";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import uuid from "uuid/v1";

const CreateCalculosRendimentoConsulta = (props) => {
  const { blockdivision, styleSheet } = props;

  const [mrcr_observacoes, setFormData1] = useState("");

  const [fracaoRai, setFormData3] = useState("");
  const [seccao, setFormData4] = useState("");
  const [tableRendimentos, setTable1] = useState("");
  const [areaBruta, setFormData5] = useState("");
  const [total, setFormData6] = useState("");
  const [vComercial, setFormData7] = useState("");
  const [ivadoptado, setFormData8] = useState("");
  const [ltv, setFormData9] = useState("");
  const [textSize, setTextSize] = useState("")
  const [tableSize, setTableSize] = useState("")
  const [zoomCheckbox, setZoomCheckbox] = useState('1')
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
    if (
      styleSheet?.zoomIn &&
      styleSheet?.tablefontsize
    ) {
      setTableSize(`${styleSheet?.tablefontsize}`)
    }
    if(
      styleSheet?.zoomIn &&
      styleSheet?.zoomInCheckboxScaleSize
    ){
      setZoomCheckbox(`${styleSheet?.zoomInCheckboxScaleSize}`)
    }
    
  }, [styleSheet]);
  useEffect(() => {
    if (blockdivision) {
      //find form data
      let mrcr_observacoes;
      let aplicarTodas;
      let fracaoRai;
      let seccao;
      let tableRendimentos;
      let areaRen;
      let total;
      let vComercial;
      let ivadoptado;
      let ltv;

      const setValue = (f) => {
        if (f?.variable == "mrcr_observacoes") mrcr_observacoes = f;
        if (f?.variable == "fracaoRai") fracaoRai = f;
        if (f?.variable == "seccao") seccao = f;
        if (f?.variable == "areaRen") areaRen = f;

        if (f?.text == "Total") total = f.value;
        if (f?.text == "V - Valor Comercial") vComercial = f.value;
        if (f?.text == "IV adoptado") ivadoptado = f.value;
        if (f?.text == "LTV") ltv = f.value;

        if (f?.type == "arraytable") {
          if (f?.row[1]?.col[0]?.value === "Designação") {
            tableRendimentos = f;
          }
        }
      };
      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });

      setFormData1(mrcr_observacoes);
      setFormData3(fracaoRai);
      setFormData4(seccao);
      setFormData5(areaRen);
      setFormData6(total);
      setFormData7(vComercial);
      setFormData8(ivadoptado);
      setFormData9(ltv);

      setTable1(tableRendimentos);
    }
  }, [blockdivision]);

  const renderTableRendimentos = () => {
    if (tableRendimentos && Array.isArray(tableRendimentos.row)) {
      return tableRendimentos.row.map((r, index) => {
        if (index != 0 && index != 1) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  if (i == 7) {
                    return (
                      <td key={i} style={{ fontSize: `${textSize}px` }}>
                        {c.value ? `${c.value}€` : ""}
                      </td>
                    );
                  } else if (i == 8) {
                    return (
                      <CreateTableCheckbox
                        key={i}
                        field={c?.value?.input}
                        linha={index + 1}
                        onBlur={() => {}}
                        zoomcheckbox={zoomCheckbox}
                        fontSize={textSize}
                      />
                    );
                  } else
                    return (
                      <td key={i} style={{ fontSize: `${textSize}px` }}>
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };

  const renderColumnContent = () => {
    return (
      <>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                Método
              </Form.Label>
              <Form.Label column style={{ ...style7, fontSize: `${textSize}px` }}>
                Comparativo,Rendimento,Reposição
              </Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>

        {seccao && <CreateButtonsFromSelection field={seccao} />}
        {fracaoRai && (
          <CreateSearchableDropdown field={fracaoRai} key={"fracaoRai"} />
        )}

        <Form.Group key={uuid()} style={style1}>
          <Form.Label column style={{ flexGrow: "1" }}>
            <h4>Método do rendimento ou capitalização das rendas</h4> <hr />
          </Form.Label>
        </Form.Group>
        <Alert variant="info">
          O método do rendimento é utilizado para a determinação do valor do
          imóvel a partir do quociente entre a renda anual efectiva ou
          potencial, líquida de encargos de conservação e manutenção, com uma
          taxa de remuneração adequada às suas características e ao nível de
          risco do investimento, face às condições gerais do mercado imobiliário
          no momento da avaliação.
        </Alert>

        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              <td
                id="66_1_1"
                colSpan="5"
                style={{
                  paddingBottom: "10px",
                  fontSize: `${textSize}px`,
                }}
              >
                Parâmetros Valorização
              </td>
            </tr>
            <tr style={{ paddingBottom: "10px" }}>
              <th id="66_2_1" style={{ ...style4, fontSize: `${textSize}px` }}>
                Designação
              </th>
              <th
                id="66_2_2"
                style={{ ...style4, width: "140px", fontSize: `${textSize}px` }}
              >
                Tipo de renda
              </th>
              <th id="66_2_3" style={{ ...style4, fontSize: `${textSize}px` }}>
                Área (m2)
              </th>
              <th id="66_2_4" style={{ ...style4, fontSize: `${textSize}px` }}>
                R. Mensal (&euro;/m&ecirc;s)
              </th>
              <th
                id="66_2_5"
                style={{ ...style4, width: "140px", fontSize: `${textSize}px` }}
              >
                &Iacute;ndice Renda Mensal (&euro;/m&ecirc;s/m2)
              </th>
              <th id="66_2_6" style={{ ...style4, fontSize: `${textSize}px` }}>
                tx.desp (%)
              </th>
              <th id="66_2_7" style={{ ...style4, fontSize: `${textSize}px` }}>
                Yeld (%)
              </th>
              <th
                id="66_2_8"
                style={{ ...style4, width: "100px", fontSize: `${textSize}px` }}
              >
                Totais Parciais (&euro;)
              </th>
              <th id="66_2_9" style={{ ...style4, fontSize: `${textSize}px` }}>
                Selecionar
              </th>
            </tr>
          </thead>

          <tbody>{renderTableRendimentos()}</tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.2", padding: "0" }}
          >
            <Form.Group as={Row} style={style2}>
              <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
                Total
              </Form.Label>
              <Form.Label
                id="totalRendimento"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {total ? `${total}€` : ""}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>

        <br />

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {areaBruta && (
              <CreateTextInput
                id="areaBruta"
                field={areaBruta}
                key={areaBruta.variable}
                fontSize={textSize}
              />
            )}

            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                IV adoptado
              </Form.Label>
              <Form.Label
                id="ivAdoptado"
                column
                style={{ ...style7, fontSize: `${textSize}px` }}
              >
                {ivadoptado}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                LTV
              </Form.Label>
              <Form.Label column style={{ ...style7, fontSize: `${textSize}px` }}>
                {ltv ? `${ltv}%` : ""}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} style={{ ...style3, paddingLeft: "15px" }}>
              <Alert variant="info">Montante de Crédito</Alert>
            </Form.Group>
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                V &ndash; Valor Comercial
              </Form.Label>
              <Form.Label
                id="valorComercial"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {vComercial ? `${vComercial}€` : ""}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {mrcr_observacoes && (
              <CreateTextArea
                field={mrcr_observacoes}
                key={"mrcr_observacoes"}
              />
            )}
          </Col>
        </Form.Group>
      </>
    );
  };
  const fontSize = "1rem";
  const style1 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    flexGrow: "1",
  };

  const style2 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
    justifyContent: "flex-end",
  };

  const style3 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style4 = {
    overflowWrap: "break-word",
    verticalAlign: "baseline",
    padding: "0.75rem 0.25rem 0.5rem 0.25rem",
  };

  const style7 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
    paddingLeft: "0px",
  };

  const style8 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style9 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
  };

  return (
    <Row
      key={`blockdivision_comparativo`}
      style={{ width: "100%", flexWrap: "nowrap", margin: "0px" }}
    >
      <Col key={`columndivision_rendimento`} style={{ flexGrow: "1" }}>
        {renderColumnContent()}
      </Col>
    </Row>
  );
};
export default CreateCalculosRendimentoConsulta;
