import React from "react";
import uuid from "uuid/v1";

const CaptionElement = (props) => {
  const { captionHeaders, translations, language } = props;

  const transformHeaderName = (name) => {
    if (name.includes("::t::")) {
      const translatedText = translateText(name);
      return translatedText;
    } else if (name && name.includes("::")) {
      return name.split("::")[0];
    } else {
      return name || "";
    }
  };

  const translateText = (textField) => {
    if (!textField.includes("::t::")) {
      return textField;
    }
    const lingua = language.trim();
    const cleanTextField = textField.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal;
  };

  const renderRowElements = (header) => {
    const arr = Array.isArray(header) ? header : [header];
    return arr.map((e) => {
      return (
        <div
          className="caption-element"
          key={uuid()}
          style={{ flexGrow: e.colspan ? e.colspan : 1 }}
        >
          {transformHeaderName(e.value)}
        </div>
      );
    });
  };

  const renderCaption = () => {
    return captionHeaders.map((h, i) => {
      return (
        <div style={{ display: "flex", flexWrap: "nowrap" }} key={i}>
          {renderRowElements(h)}
        </div>
      );
    });
  };
  return <div style={{ marginTop: "1rem" }}>{renderCaption()}</div>;
};

export default CaptionElement;
