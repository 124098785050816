import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import WidgetContainer from "~/components/WidgetContainer";
import ContentContainer from "~/containers/ContentContainer";
import ProtectedPage from "~/components/security/ProtectedPage";
import Error404 from "~/components/Exception/Error404";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";

const Uniksystem = (props) => {
  const { isMongoOn } = props;
  let { path } = useRouteMatch();
  return (
    <>
      <ApplicationMenu applicationName="uniksystem" />
      <Switch>
        <Route path={path} exact={true}>
          <ProtectedPage roles={["ADMIN", "UniksystemUser", "UNIKSYSTEMUser"]}>
            <>
              {isMongoOn && (
                <ContentContainer>
                  <WidgetContainer />
                </ContentContainer>
              )}
            </>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/admin/*`}>
          {" "}
          <Error404 />
        </Route>
        <Route path={`${path}/*`}>
          {" "}
          <Error404 />
        </Route>
      </Switch>
    </>
  );
};
export default Uniksystem;
