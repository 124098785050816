import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

/**
 * Iban Verfication
 *
 * This service tests the verification of a user's Iban with Tink Api
 *
 */
const Test = ({ t }) => {
  const { isLoadingGetTinkId, tinkReport } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    Object.keys(tinkReport).length > 0 &&
      setOutput(JSON.stringify(tinkReport, null, 2));
  }, [tinkReport]);

  const [validated, setValidated] = useState(false);
  const [output, setOutput] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testGetTinkId();
    }
  };

  const testGetTinkId = () => {
    const redirect = encodeURIComponent(window.location.href.trim());
    window.location.href = `https://link.tink.com/1.0/account-check/?client_id=671f1f538d414766902ac0c7dc78c726&redirect_uri=${redirect}&market=PT&locale=pt_PT`;
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          className="card-button onboarding-button"
          onClick={() => {
            testGetTinkId();
          }}
        >
          {isLoadingGetTinkId ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
