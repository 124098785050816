import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card, Jumbotron, Col, Row } from "react-bootstrap";

import { toLocaleString } from "~/utils";
import {
  buildPendingColumn,
  buildSLAColumn,
  getTTLVariant,
} from "./columnFormatter";

import "~/assets/css/icons.css";

const MobileTaskCard = (props) => {
  const { t, row, rowIndex, formatExtraData } = props;

  const [showPreview, setShowPreview] = useState(false);

  const variant = getTTLVariant(row);

  const renderLines = () => {
    if (row && row.metadata.length === 0)
      return <div>{t("general.dataNotFound")}</div>;
    else
      return (
        row &&
        row.metadata.map((data, index) => {
          if (
            data.value !== undefined &&
            data.value !== null &&
            data.value !== ""
          ) {
            if (data.name === "SLA") data.value = buildSLAColumn(data.value);
            return (
              <Row key={index}>
                <Col sm="6">
                  <strong> {t(`taskPage.general.${data.name}`)}:</strong>
                </Col>
                <Col sm="6">
                  <span>{data.value}</span>
                </Col>
              </Row>
            );
          }
        })
      );
  };

  const flexCenterStyles = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyles = {
    fontSize: "20px",
    padding: "10px",
  };

  return (
    <Card
      bsPrefix="card-mini"
      key={rowIndex}
      style={{
        margin: "0 0 15px 0",
        textAlign: "center",
        borderRadius: "23px",
      }}
    >
      <Card.Header style={flexCenterStyles}>
        {" "}
        Nº Processo &nbsp;{row && row.pnumber}
      </Card.Header>
      <Card.Body>
        <Card.Title>{row && row.flowname}</Card.Title>
        {row && row.description && (
          <Card.Text>
            {row.description.length > 35
              ? row.description.slice(0, 35) + "..."
              : row.description}
          </Card.Text>
        )}

        {showPreview && (
          <Jumbotron>
            <div style={{ marginBottom: "1em" }}>
              <i
                className="icon-remove"
                onClick={() => setShowPreview(false)}
              />
            </div>
            {renderLines()}
          </Jumbotron>
        )}

        <div style={flexCenterStyles}>
          <i
            className="icon-detail table-action-icon"
            style={iconStyles}
            onClick={() => setShowPreview((preview) => !preview)}
          />
          <i
            className="icon-edit table-action-icon"
            style={iconStyles}
            onClick={() => formatExtraData.handleOpenFlowModal(row)}
          />
        </div>
      </Card.Body>
      {row.created !== null && row.created !== undefined && (
        <Card.Footer className="text-muted" style={flexCenterStyles}>
          <div className="task-description">
            {toLocaleString(row.created)} &nbsp;
            {buildPendingColumn(row.created)}
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};
export default withNamespaces()(MobileTaskCard);
