import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { DateTime } from "luxon";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import {
  getPerEvForm,
  getPerEvFormDropdown,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { Col, Form } from "react-bootstrap";
import ButtonsUpdate from "./ButtonsUpdate";
import DateTimePicker from "react-datetime-picker";

const RhPerformanceEvForm = ({ rowCollaborator, t }) => {
  const [date, setDate] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [comments, setcomments] = useState("");
  const [qualitityList, setQualitityList] = useState([]);
  const [qualititySelected, setQualititySelected] = useState("");
  const [quantityAssessement, setQuantityAssessement] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getPerEvFormDropdown());
      dispatch(
        getPerEvForm(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
    }
  }, [rowCollaborator]);

  const { perEvFormData, perEvFormDropdown } = useSelector(
    (state) => state.personalReducer
  );

  const handleQualitity = (e) => {
    setQualititySelected(e);
  };

  const handleQuantityEvolution = (e) => {
    setQuantityAssessement(e.target.value);
  };

  const handleDate = (e) => {
    setDate(e);
  };

  const handleComments = (e) => {
    setcomments(e.target.value);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (
        data.length > 0 &&
        perEvFormDropdown &&
        Object.keys(perEvFormDropdown).length > 0
      ) {
        setcomments(data[0].comments);
        setQuantityAssessement(data[0].quantEvalution);

        setQualitityList(perEvFormDropdown.qualitAssessement);

        setQualititySelected(data[0].trainingSheet);

        if (data[0].date !== null &&  data[0].date !== undefined) {
          setDate(new Date(data[0].date));
        } else {
          setDate("");
        }
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(perEvFormData);
    }
  }, [perEvFormData, perEvFormDropdown, rowClicked]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };
  const formatQualitative = (cell) => {
    if (perEvFormDropdown && Object.keys(perEvFormDropdown).length > 0) {
      for (let i = 0; i < perEvFormDropdown?.qualitAssessement?.length; i++) {
        if (cell == perEvFormDropdown.qualitAssessement[i].value) {
          return <p>{perEvFormDropdown.qualitAssessement[i].label}</p>;
        }
      }
    }
  };

  const formatEndData = (cell) => {
    if (cell !== undefined && cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const columns = [
    {
      dataField: "trainingSheet",
      text: t(`portalrh.PerformanceEvForm.QualitAssessment`),
      sort: true,
      formatter: formatQualitative,
      style: { fontSize: "12px" },
    },
    {
      dataField: "quantEvalution",
      text: t(`portalrh.PerformanceEvForm.QuantEvaluation`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "date",
      text: t(`portalrh.PerformanceEvForm.date`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
  ];
  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }
  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="3" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.PerformanceEvForm.QualitAssessment`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={qualitityList}
              handleSubmit={handleQualitity}
              defaultValue={qualititySelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="3" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.PerformanceEvForm.QuantEvaluation`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={quantityAssessement == null ? "" : quantityAssessement}
              onChange={handleQuantityEvolution}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.PerformanceEvForm.date`)}
            </Form.Label>
            <DateTimePicker
              value={date}
              onChange={handleDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="12">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.PerformanceEvForm.Comments`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={comments == null ? "" : comments}
                onChange={handleComments}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
        </div>
        <RhTableList
          tableId={"36"}
          columns={columns}
          data={perEvFormData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhPerformanceEvForm);
