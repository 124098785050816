import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import MfilesTable from "./MfilesTable";
import MfilesFilters from "./MfilesFilters";

import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";

const MFiles = () => {
  const { isLoadingMFiles } = useSelector((state) => state.mfilesReducer);

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-tarefas2 mr-2" />
              M-files
            </h6>
          </Card.Header>
          <Card.Body style={{ display: "flex", flexDirection: "column" }}>
            <MfilesFilters />
            <MfilesTable />
          </Card.Body>
        </Card>
      </div>
      {<Spinner spinning={isLoadingMFiles} wrapper />}
      <Messages />
    </>
  );
};

export default MFiles;
