import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import "~/pages/Easypay/assets/css/styles.css";
import Header from "~/pages/Easypay/components/Header";
import "~/pages/Easypay/assets/scss/_backofficeEasypayLeadsPage.scss";
import BackOfficeAccountSection from "~/pages/Easypay/components/BackOfficeAccountSection.js";
import BackOfficeBusinessSection from "~/pages/Easypay/components/BackOfficeBusinessSection.js";
import BackOfficeEasypaySubHeader from "~/pages/Easypay/components/BackOfficeEasypaySubHeader.js";
import BackofficeProvadeVidaSection from "~/pages/Easypay/components/BackofficeProvadeVidaSection.js";
import BackofficeRegistrySection from "~/pages/Easypay/components/BackofficeRegistrySection.js";
import BackOfficeVerticalButtons from "~/pages/Easypay/components/BackOfficeVerticalButtons.js";
import Button from "~/pages/Easypay/components/Button.js";
import LineBreak from "~/pages/Easypay/components/LineBreak.js";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import ProtectedComponent from "~/components/security/ProtectedComponent";

import { getUser, updateUser,setPrecario } from "~/store/ducks/onboarding/actionTypes";

const IndividualLead = (props) => {
  const [doneSave, setDoneSave] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [isModalSaveShowing, setIsModalSaveShowing] = useState(false);
  const { pid } = props?.match?.params;
  const { application, t } = props;

  const { user } = useSelector((state) => state.onboardingReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(pid));
  }, []);

  useEffect(() => {
    if (doneSave) {
      setDoneSave(false);
      setIsModalSaveShowing(true);
    }
  }, [user]);

  const handleClick = (id) => {
    //Dynamic offSet that calculates header and subheader height
    const offSet =
      document?.getElementById("easypay-header")?.offsetHeight +
      document?.getElementById("easypay-backoffice-subheader")?.offsetHeight;
    const element = document.getElementById(id);
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - offSet;

    document.documentElement.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    //For Safari
    document.body.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Conta aprovada" status="success" />
      </div>
    );
  }

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Conta Rejeitada" status="error" />
      </div>
    );
  }

  function createBodySave() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Alterações guardadas" status="success" />
      </div>
    );
  }
  const buttonsList = [
    {
      name: "Registo",
      text: t("bpm.onboarding.backoffice.administration.registryButton"),
    },
    {
      name: "Prova de vida",
      text: t("bpm.onboarding.backoffice.administration.proofOfLifeButton"),
    },
    {
      name: "Conta",
      text: t("bpm.onboarding.backoffice.administration.accountButton"),
    },
    {
      name: "Negócio",
      text: t("bpm.onboarding.backoffice.administration.businessButton"),
    },
  ];

  const breadcrumbItems = [
    {
      text: "Leads",
      link: "uniksystem/onboarding/backoffic-organization/leads",
    },
    {
      text: user?.account?.pid,
      link: "/#/uniksystem/onboarding/backoffic-organization/leads",
    },
  ];

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const handleSubmit = () => {
    user.account.modifiedDate = new Date();
    dispatch(updateUser({ user: user, pid: pid }));
    setDoneSave(true);
  };

  return (
    user && (
      <>
        <Header
          redirect={true}
          logo={
            !application
              ? ""
              : application?.applicationName === "easypay"
              ? easypayLogo
              : unikLogo
          }
          height={application?.applicationName === "easypay" ? "30px" : "40px"}
          exit={"#/uniksystem/onboarding/integrations"}
          hideExtraLinks={true}
          addDocument={true}
        />
        <Modal
          className="easypay-modal"
          showModal={isModalShowing}
          setShowModal={setIsModalShowing}
          headerContent={"Aprovação de Lead"}
          bodyContent={createBody()}
          key={uuid()}
        ></Modal>
        <Modal
          className="easypay-modal"
          showModal={isModalErrorShowing}
          setShowModal={setIsModalErrorShowing}
          headerContent={"Aprovação de Lead"}
          bodyContent={createBodyError()}
          key={uuid()}
        ></Modal>
        <Modal
          className="easypay-modal"
          showModal={isModalSaveShowing}
          setShowModal={setIsModalSaveShowing}
          headerContent={"Guardar alterações"}
          bodyContent={createBodySave()}
          key={uuid()}
        ></Modal>
        <BackOfficeEasypaySubHeader
          data={user}
          breadcrumbItems={breadcrumbItems}
          isLead={true}
          pid={pid}
          setIsModalShowing={setIsModalShowing}
          setIsModalErrorShowing={setIsModalErrorShowing}
        />
        <BackOfficeVerticalButtons
          navItems={buttonsList}
          handleClick={handleClick}
        />
        <div className="easypay-backoffice-wrapper-leads">
          <div className="easypay-backoffice-leads-dummy-wrapper"></div>
          <div className="easypay-backoffice-leads-sub-wrapper">
            <div className="easypay-backoffice-leads-form-wrapper">
              <div div="easypay-backoffice-section-1" id="backoffice-0">
                <BackofficeRegistrySection data={user} />
              </div>
              <div className="easypay-backoffice-line-break-wrapper">
                <LineBreak text="none" />
              </div>
              <div div="easypay-backoffice-section-2" id="backoffice-1">
                <BackofficeProvadeVidaSection data={user} />
              </div>
              <div className="easypay-backoffice-line-break-wrapper">
                <LineBreak text="none" />
              </div>
              <div div="easypay-backoffice-section-3" id="backoffice-2">
                <BackOfficeAccountSection data={user} />
              </div>
              <div className="easypay-backoffice-line-break-wrapper">
                <LineBreak text="none" />
              </div>
              <div div="easypay-backoffice-section-4" id="backoffice-3">
                <ProtectedComponent
                  permissions={[{ label: "app-onboarding-business-read" }]}
                >
                  <BackOfficeBusinessSection data={user} />
                </ProtectedComponent>
              </div>
              <div className="easypay-backoffice-line-break-wrapper">
                <LineBreak text="none" />
              </div>
              <div className="easypay-backoffice-submit-button-wrapper">
                <Button
                  text={t(
                    "bpm.onboarding.backoffice.administration.saveChangesButton"
                  )}
                  variant={"easypay-button-primary"}
                  onclick={handleSubmit}
                  key={uuid()}
                  id={"buttonId"}
                  className="card-button"
                  align={"right"}
                  disabledColored={false}
                  disabled={false}
                  field={addButtonField}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
export default withNamespaces()(IndividualLead);
