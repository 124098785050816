import React from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import "~/pages/Easypay/assets/scss/_checkbox.scss";
import "../assets/scss/_option.scss";

const Checkbox = ({
  disabled,
  name,
  id,
  checked,
  value,
  disabledColored,
  workplanIndex,
  activityIndex,
  itemIndex,
  optionIndex,
  isPrint
}) => {
  const dispatch = useDispatch();

  
  const { workplans } = useSelector((state) => state.budgetManagementReducer);
  const handleCheckboxChecked = () => {
    var currentActivityCost = 0;
    var currentActivitySale = 0;

    var currentItemCost = 0;
    var currentItemSale = 0;

    if(isPrint)
    {
      workplans[workplanIndex].imprimir =
        !workplans[workplanIndex].imprimir;
    }
    else
    {
      if(!workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].selected)
        {
          for(var i = 0; i <workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes.length; i++)
            {
              workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
                i
              ].selected = false
            }
        }
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
        optionIndex
      ].selected =
        !workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].selected;
      const currentOptions =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes;
  
      for (var i = 0; i < currentOptions.length; i++) {
        if (currentOptions[i].selected) {
          currentActivityCost += parseFloat(currentOptions[i].valorCustoOpt);
          currentActivitySale += parseFloat(currentOptions[i].valorVendaOpt);
        }
      }
  
      if (currentActivityCost === 0 && currentActivitySale === 0) {
        currentActivityCost = currentOptions[0].valorCustoOpt;
        currentActivitySale = currentOptions[0].valorVendaOpt;
      }
  
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].valorCustoAct = parseFloat(currentActivityCost).toFixed(2);
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].valorVendaAct = parseFloat(currentActivitySale).toFixed(2);
  
      const currentActivities =
        workplans[workplanIndex].items[itemIndex].atividades;
  
      for (var i = 0; i < currentActivities.length; i++) {
        currentItemCost += parseFloat(currentActivities[i].valorCustoAct);
        currentItemSale += parseFloat(currentActivities[i].valorVendaAct);
      }
  
      workplans[workplanIndex].items[itemIndex].valorCustoObj = parseFloat(currentItemCost).toFixed(2);
      workplans[workplanIndex].items[itemIndex].valorVendaObj = parseFloat(currentItemSale).toFixed(2);
    }
    
    dispatch(setAllWorkplans(workplans));
  };

  function checkIfIsPrint()
  {
    if(isPrint)
    {
      if(checked)
      {
        return false
      }
      else
      {
        return true
      }
    }
    return checked
  }

  return (
    <div className = "liftworld-checkbox-wrapper" style = {{margin : "0px"}}>
      <label className="easypay-checkbox">
        <span className={"easypay-checkbox-label"} disabled={disabledColored}>
          {value}
        </span>
        <input
          className="easypay-checkbox-input"
          type="checkbox"
          disabled={disabled || disabledColored}
          id={id}
          name={name}
          defaultChecked={checkIfIsPrint() === false ? null : checkIfIsPrint()}
          checked = {checkIfIsPrint()}
          onChange={handleCheckboxChecked}
          style = {{margin : "0px"}}
        />
        <span
          className={
            disabled ? "easypay-checkmark disabled" : "easypay-checkmark"
          }
          disabled={disabled}
        ></span>
      </label>
    </div>
  );
};

export default withNamespaces()(Checkbox);
