import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { TabsKey } from "../../utils/TabsEnum";
import ApplicationCard from "./ApplicationCard";


const PortalRHComponent = ({ tabKey }) => {
  const dispatch = useDispatch();

  const { isPortalRHON, loadingPortalRH } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(actions.pingMiddlewareApplication(PORTALRH_MIDDLEWARE));
      const payloadIFlow = {
        url: "/index.php",
        application: {
          context: "portal",
        },
      };
      dispatch(actions.pingIFlowBasedApplication(payloadIFlow));
    }
  }, [tabKey]);

  return (
    <ApplicationCard
      name="Portal RH"
      status={isPortalRHON}
      loading={loadingPortalRH}
      showIFlow={true}
      showMW={true}
    />
  );
};

export default PortalRHComponent;
