import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";

const Dirigentes = ({ t, styleSheet }) => {
  const history = useHistory();
  const [unidadesOrganicasList, setUnidadesOrganicasList] = useState([]);
  const { unidadesOrganicas } = useSelector((state) => state.siadapraReducer);

  useEffect(() => {
    if (unidadesOrganicas.length > 0) {
      setUnidadesOrganicasList(unidadesOrganicas);
    }
  }, [unidadesOrganicas]);

  const actionsFormatter = (cell, row) => {
    const handleRedirect = () => {
      history.push(
        `/uniksystem/portalrh/siadapra/dirigentes-detail/${row.unidadeorganicaid}`
      );
    };

    return (
      <div>
        <i className="fa fa-arrow-right" onClick={handleRedirect}></i>
      </div>
    );
  };

  const columns = [
    {
      dataField: "unidadeorganicaid",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "unidadedescricao",
      text: "Descrição",
      sort: true,
    },
    {
      dataField: "nome",
      text: "Dirigente",
      sort: true,
    },
    {
      dataField: "quota",
      text: "Quota",
      sort: true,
      formatter: (cell, row) => {
        return "2";
      },
    },
    { dataField: "actions", text: "Ações", formatter: actionsFormatter },
  ];

  const tableRowEvents = {
    onClick: (_, row, __) => {
      history.push(
        `/uniksystem/portalrh/siadapra/dirigentes-detail/${row.unidadeorganicaid}`
      );
    },
  };

  return (
    <div className={styleSheet?.processScroll ? "scrollBar-visibleModal" : ""}>
      <ToolkitProvider
        keyField="id"
        data={unidadesOrganicasList}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              noDataIndication={t("portalrh.personalData.noRequests")}
              striped
              rowEvents={tableRowEvents}
              hover
              classes="table-desempenho"
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default Dirigentes;
