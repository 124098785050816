import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { abbreviateString } from "~/utils";
import txtPreview from "~/assets/img/datacapture/txt_preview.png";
const ShedulePreviewCard = ({ shedule, t, resetStates, showDeleteModal }) => {
  const [showDeleteShedule, setShowDeleteShedule] = useState(false);

  useEffect(() => {
    showDeleteModal && setShowDeleteShedule(true);
  }, [showDeleteModal]);

  return (
    <>
      <img
        src={txtPreview}
        className={"portal-image-preview-one"}
        alt="preview"
      />
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={<Tooltip>{shedule.name}</Tooltip>}
      >
        <small>{abbreviateString(shedule.name, 28)}</small>
      </OverlayTrigger>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setShowDeleteShedule(true)}
      >
        {" "}
        &times;
      </span>

      <Modal
        show={showDeleteShedule}
        onHide={() => setShowDeleteShedule(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`${t("datacapture.general.deleteDocument")} ${shedule.name || ""} ?`}
        </Modal.Body>
        <Modal.Footer>
          {shedule.name && (
            <Button
              variant="danger"
              onClick={() => {
                resetStates();
                setShowDeleteShedule(false);
              }}
            >
              {t("general.remove")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(ShedulePreviewCard);
