import React, { useEffect } from "react";
import {
  CDBDropDown,
  CDBDropDownItem,
  CDBDropDownMenu,
  CDBDropDownToggle,
  CDBContainer,
} from "cdbreact";
import { useDispatch } from "react-redux";
import { getAnoProcessamentoAssiduidade, getMesProcessamentoAssiduidade } from "~/store/ducks/collaboratorProcessamento/actionTypes";
const RhAssiduidade = ({rowCollaborator}) => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAnoProcessamentoAssiduidade(rowCollaborator.funcionarioNum,rowCollaborator.codEntidade));
    dispatch(getAnoProcessamentoAssiduidade(rowCollaborator.funcionarioNum,rowCollaborator.codEntidade));
  }, []);

  return (
    <>
      <label>Ano Processamento:</label>
      <CDBContainer className="buttonstyle">
        <CDBDropDown>
          <CDBDropDownToggle color="secondary" size="sm" caret dropdown>
            Ano
          </CDBDropDownToggle>
          <CDBDropDownMenu dropdown>
            <CDBDropDownItem>Item</CDBDropDownItem>
          </CDBDropDownMenu>
        </CDBDropDown>
      </CDBContainer>

      <label>Mês Processamento:</label>
      <CDBContainer className="buttonstyle">
        <CDBDropDown>
          <CDBDropDownToggle color="secondary" size="sm" caret dropdown>
            Mês
          </CDBDropDownToggle>
          <CDBDropDownMenu dropdown>
            <CDBDropDownItem>Item</CDBDropDownItem>
          </CDBDropDownMenu>
        </CDBDropDown>
      </CDBContainer>
    </>
  );
};

export default RhAssiduidade;
