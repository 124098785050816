import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import { findUnikUserSettings } from "~/pages/User/actions";
import LanguageContainer from "./LanguageContainer";

const PersistUserLanguage = ({ username }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (username) dispatch(findUnikUserSettings(username));
  }, [username]);

  return <LanguageContainer />;
};
export default withNamespaces()(PersistUserLanguage);
