import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import NativeProcessesWrapper from "./NativeProcessesWrapper";
import oauth from "~/utils/oauth";

const DespesasDemo = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const currentFormUrl = "inicio_flow.jsp?flowid=349";

  const renderProcesses = () => {
    return <NativeProcessesWrapper className={className} />;
  };

  useEffect(() => {
    if (currentFormUrl !== "") {
      const payload = {
        url: currentFormUrl + "&Authorization=" + oauth.getAccessToken(),
      };
      dispatch(getIflowFormdata(payload));
    }
  }, [currentFormUrl]);

  return <>{renderProcesses()}</>;
};
export default DespesasDemo;
