import React, { useState } from "react";

import { Col, Form, Badge } from 'react-bootstrap';
import { remove } from "lodash";

import "../scss/legacy-role-table.scss";

const LegacyRoleRow = props => {

  const {
    legacyApp,
    legacyRoles,
    legacyApplications,
    openModal,
    description,
    isEditing, 
  } = props;

  const [hasAccess, setAccess] = useState(isEditing);

  const toggleLegacy = () => {
    const toggleAccess = !hasAccess;
    setAccess(toggleAccess);
    if (!toggleAccess) {
      remove(legacyRoles, { appId: legacyApp.id })
      remove(legacyApplications, legacyApp);
    }
  }

  function getSelectedLegacyRole() {
    return legacyRoles && legacyRoles.find(item => item.appId === legacyApp.id)
  }

  function getSelectedLegacyApplication() {
    return legacyApplications && legacyApplications.find(item => item.id === legacyApp.id)
  }

  const createRolesColumnData = () => {
    const selectedLegacyRole = getSelectedLegacyRole();
    const selectedLegacyApplication = getSelectedLegacyApplication();

    if (selectedLegacyRole) {
      return (
        <Badge
          pill
          variant="info"
          className="pointer lg-badge"
          onClick={() => openModal(legacyApp)}
        >
          {selectedLegacyRole.description}
        </Badge>
      )
    } if (selectedLegacyApplication) {
      return (
        <Badge
          pill
          variant="success"
          className="pointer lg-badge"
          onClick={() => openModal(legacyApp)}
        >
          {description}
        </Badge>
      )
    } else {
      return (
        <i
          className="icon-add lg-icon add-table-icon pointer"
          onClick={() => openModal(legacyApp)}
        />
      )
    }
  }

  return (
    <tr>
      <th>{legacyApp.description}</th>
      <th>
        <Form.Check
          type="switch"
          id={legacyApp.id}
          label=""
          checked={hasAccess}
          onChange={() => toggleLegacy()}
        />
      </th>
      <th>
        {hasAccess &&
          <Col xs={4}>
            {createRolesColumnData()}
          </Col>
        }
      </th>
    </tr>

  )
}

export default LegacyRoleRow;