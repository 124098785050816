import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Col, Button } from 'react-bootstrap';

import { withNamespaces } from 'react-i18next';
import { findProcessesByFilters } from './actions';
import { BPM_MIDDLEWARE } from '~/utils/constants';

import uuid from 'uuid/v1';
import '~/assets/css/icons.css';

const ProcessFilters = ({ t }) => {

  const dispatch = useDispatch();
  const defaultEndAt = new Date().toISOString().slice(0, 10);
  const { user } = useSelector(state => state.globalReducer);

  const getUsername = (legacyUsers) => {
    let userBPM = legacyUsers.find(item => item.legacyApplication.label === BPM_MIDDLEWARE.context);
    return userBPM ? userBPM.username : null;
  }

  const defaultStartAt = () => {
    let currentDate = new Date();
    let pastDate = new Date(currentDate);
    pastDate.setDate(pastDate.getDate() - 8);
    return pastDate.toISOString().slice(0, 10)
  }

  const [nProcess, setNProcess] = useState("");
  const [status, setStatus] = useState("");
  const [endAt, setEndAt] = useState(defaultEndAt);
  const [startAt, setStartAt] = useState(defaultStartAt);

  const handleEndDate = e => {
    setEndAt(e.target.value || defaultEndAt);
  }
  const handleStartDate = e => {
    setStartAt(e.target.value || defaultStartAt);
  }

  const handleNProcess = e => {
    setNProcess(e.target.value || "");
  }

  const handleStatus = e => {
    setStatus(e.target.value || undefined);
  }

  const handleSearch = () => {
    if (user.legacyUsers) {
      const userBPM = getUsername(user.legacyUsers);
      if (userBPM) {
        dispatch(findProcessesByFilters(userBPM, "datacapture", nProcess, startAt, endAt, status));
      }
    }
  }

  return (
    <Form>
      <Form.Row>
        <Col lg="12">
          <h5>{t('general.filters')}</h5>
          <hr />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg="3">
          <Form.Label>{t('ProcessesWidget.dataRegisto')}</Form.Label>
          <Form.Control
            type="date"
            value={startAt}
            onChange={e => handleStartDate(e)}
          />
        </Col>
        <Col lg="3">
          <Form.Label> &nbsp;</Form.Label>
          <Form.Control
            type="date"
            value={endAt}
            onChange={e => handleEndDate(e)}
          />
        </Col>

        <Col lg="3">
          <Form.Label>{t('ProcessesWidget.processNumber')}</Form.Label>
          <Form.Control
            type="text"
            value={nProcess}
            onChange={e => handleNProcess(e)}
          />
        </Col>


        <Col lg="3">
          <Form.Label>{t('ProcessesWidget.status')}</Form.Label>
          <Form.Control
            as="select"
            onChange={e => handleStatus(e)}
            value={status}
          >
            <option value=''></option>
            <option key={uuid()} value='Novo'>{t('TasksWidget.taskStatus.new')}</option>
            <option key={uuid()} value='OCR'>{t('TasksWidget.taskStatus.ocr')}</option>
            <option key={uuid()} value='Validação Documental'>{t('TasksWidget.taskStatus.documentValidation')}</option>
            <option key={uuid()} value='Documentação Incompleta '>{t('TasksWidget.taskStatus.incompleteDocumentation')} </option>
            <option key={uuid()} value='Controlo de Qualidade'>{t('TasksWidget.taskStatus.qualityControl')}</option>
            <option key={uuid()} value='Validação Contratual'>{t('TasksWidget.taskStatus.contractValidation')}</option>
            <option key={uuid()} value='Aprovado'>{t('TasksWidget.taskStatus.approved')}</option>
            <option key={uuid()} value='Rejeitado'>{t('TasksWidget.taskStatus.rejected')} </option>
          </Form.Control>
        </Col>

      </Form.Row>
      <br />
      <Button
        className="card-button"
        onClick={() => handleSearch()}
      >
        {t('general.search')}
      </Button>
    </Form >
  )
}

export default withNamespaces()(ProcessFilters);
