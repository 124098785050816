import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import {
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import { capitalize } from "lodash";
import { abbreviateString } from "~/utils";
import { DocumentStatus } from "../utils/DataCaptureEnum";

import {
  getColorByStatusDocument,
  translateStatusDocument,
} from "~/pages/DataCapture/utils";
import { deleteDocument } from "../actions";

import pdfPreview from "~/assets/img/datacapture/pdf_preview.svg";
import zipPreview from "~/assets/img/datacapture/zip_preview.svg";

const FilePreviewCard = ({ document, statusFile, t }) => {
  const dispatch = useDispatch();

  const [showDeleteDocument, setShowDeleteDocument] = useState(false);

  const { file } = document;
  const status = getColorByStatusDocument(document.status);

  const getPreviewImage = () => {
    if (file) {
      return file.type === "application/pdf"
        ? pdfPreview
        : file.type === "application/x-zip-compressed"
        ? zipPreview
        : URL.createObjectURL(file);
    } else {
      return document.filetype === "application/pdf"
        ? pdfPreview
        : document.filetype === "application/x-zip-compressed"
        ? zipPreview
        : "data:image/jpg;base64," + document.data;
    }
  };

  const filename = file ? file.name : document.filename;

  const handleDeleteDocument = () => {
    dispatch(deleteDocument(document.id));
  };

  return (
    <>
      <img src={getPreviewImage()} className="dc-image-preview" alt="preview" />
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={<Tooltip>{filename}</Tooltip>}
      >
        <small>{abbreviateString(filename, 18)}</small>
      </OverlayTrigger>
      <span
        className="dc-remove-file"
        onClick={() => setShowDeleteDocument(true)}
      >
        &times;
      </span>
      <div>
        {document.status === DocumentStatus.UPLOADING &&
          statusFile === undefined && (
            <>
              <small className="text-primary">Loading...</small>
              <ProgressBar className="progress-upload" animated now={100} />
            </>
          )}
        {document.status !== DocumentStatus.UPLOADING &&
          statusFile === undefined && (
            <>
              <small className={`text-${status}`}>
                {capitalize(t(translateStatusDocument(document.status)))}
              </small>
              <ProgressBar
                className="progress-upload document-loaded"
                variant={status}
                now={100}
              />
            </>
          )}
        {statusFile !== undefined && (
          <>
            <small className={`text-${status}`}>
              {capitalize(t(translateStatusDocument(statusFile)))}
            </small>
            <ProgressBar
              className="progress-upload document-loaded"
              variant={status}
              now={100}
            />
          </>
        )}
      </div>

      <Modal
        show={showDeleteDocument}
        onHide={() => setShowDeleteDocument(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {document !== undefined ? (
          <Modal.Body>
            {`${t("datacapture.general.deleteDocument")} ${
              document.filename
            } ?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDeleteDocument()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(FilePreviewCard);
