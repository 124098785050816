import { takeLatest } from "redux-saga/effects";

import {
  GET_DATACAPTURE_CONFIGURATION,
  PERSIST_DATACAPTURE_CONFIGURATION,
  DELETE_DATACAPTURE_CONFIGURATION,
} from "../ducks/datacaptureConfiguration/actionTypes";

import {
  getDatacaptureConfiguration,
  persistDatacaptureConfiguration,
  deleteDatacaptureConfiguration
} from "../ducks/datacaptureConfiguration/sagas";

export const datacaptureConfigurationSagas = [
  takeLatest(GET_DATACAPTURE_CONFIGURATION, getDatacaptureConfiguration),
  takeLatest(PERSIST_DATACAPTURE_CONFIGURATION, persistDatacaptureConfiguration),
  takeLatest(DELETE_DATACAPTURE_CONFIGURATION, deleteDatacaptureConfiguration)
];