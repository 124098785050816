import React from "react";
import { useForm } from "react-hook-form";
import { withNamespaces } from "react-i18next";
import InputDate from "~/pages/Easypay/components/InputDate";
import InputSimpleSearch from "~/pages/Easypay/components/InputSimpleSearch";

const LiftworldPageInput = ({
  filterDate,
  onSetFilters,
  savedFilters,
  disabled,
  t,
}) => {
  const { register, formState, watch } = useForm();

  var timer;

  const dateDefaultValue = () => {
    if (!(savedFilters[2].value === "")) {
      return savedFilters[2].value;
    } else return ["", ""];
  };

  const handleSubmitSearchWithDelay = (submitValue, delay) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      onSetFilters(
        {
          type: ["nomeClienteProjeto", "estado"],
          value: submitValue,
        }, // all types must be in an array
        0
      );
    }, delay);
  };

  const handleSubmitSearchVersionWithDelay = (submitValue, delay) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      onSetFilters(
        {
          type: ["projectName"],
          value: submitValue,
        }, // all types must be in an array
        0
      );
    }, delay);
  };
  return (
    <div className="input-grid">
      <div className="grid-search">
        <InputSimpleSearch
          field={{ text: "inputLabel" }}
          required={true}
          text={"Procurar"}
          placeholder={"Proposta"}
          defaultInput={savedFilters[2].value}
          handleSubmit={(submitValue) =>
            handleSubmitSearchVersionWithDelay(submitValue, 500)
          }
          disabled={disabled}
          noBorder={true}
        />
      </div>
      <div className="grid-search">
        <InputSimpleSearch
          field={{ text: "inputLabel" }}
          required={true}
          text={"Procurar"}
          placeholder={"Cliente, estado"}
          defaultInput={savedFilters[0].value}
          handleSubmit={(submitValue) =>
            handleSubmitSearchWithDelay(submitValue, 500)
          }
          disabled={disabled}
          noBorder={true}
        />
      </div>
      <div>
        <InputDate
          text={"Data de criação"}
          register={register}
          formState={formState}
          errorMessage="This field is required"
          watch={watch}
          type="created_at"
          placeholder=""
          defaultValue={dateDefaultValue()}
          handleSubmit={(dates) =>
            onSetFilters({ type: [filterDate], value: dates }, 2)
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default withNamespaces()(LiftworldPageInput);
