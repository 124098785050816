import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { Card } from "react-bootstrap";
import "../RhCollaborator.scss";
import Sidebar from "../Sidebar";
import TableList from "./TableList";
import RhNavBar from "./RHCollaboratorItems/RhNavBar";
import { withNamespaces } from "react-i18next";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { getAllowDisRecibos } from "~/store/ducks/rhadminConfiguration/actionTypes";

const RhCollaborator = ({ portalRhMenus, designacao, path, t }) => {
  const dispatch = useDispatch();
  const [isCliked, setIsCliked] = useState(false);
  const [rowCollaborator, setRowCollaborator] = useState([]);
  const [menuSideBar, setMenuSideBar] = useState(null);
  const { collaborators } = useSelector((state) => state.portalrhReducer);
  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(getCollaborators());
  }, [dispatch]);

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  const userPortal = userNamePortalRH;

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, []);

  useEffect(() => {
    if (userPortal !== undefined) {
      dispatch(getAllowDisRecibos(userPortal.codEntidade));
    }
  }, [userPortal]);

  //lifting up from TableList.js ( taking a row  and a bollean )
  const takingData = (bollean, row) => {
    setIsCliked(bollean);
    setRowCollaborator(row);
  };

  const takeBoleanBackButton = (bollean) => {
    setIsCliked(bollean);
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Header className="justify-content-between">
            <h6>{t("menu.portalrh.subMenus.Colaboradores")}</h6>
          </Card.Header>
          <Card.Body>
            {!isCliked ? (
              <>
                <TableList onTakeNavOnBolean={takingData} userPortal={userPortal} />
              </>
            ) : (
              <>
                <RhNavBar
                  rowCollaborator={rowCollaborator || ""}
                  onBoleanBackButton={takeBoleanBackButton}
                />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default withNamespaces()(RhCollaborator);
