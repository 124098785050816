import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getInformationProcessRemoteWork,
  approveDeleteRemoveDayRemoteWork,
} from "~/store/ducks/portalrh/actionTypes";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Spinner from "~/components/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import "./modalCss.css";
import { FaCheckCircle, FaClock, FaTimesCircle } from "react-icons/fa";
import { format, parseISO } from "date-fns";
import { Stack, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert as AlertMaterial } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";

const ModalRemoteWork = ({
  t,
  process,
  closeButton,
  showModalRemoteWork,
  handleCloseModalRemoteWork,
  setmakeUpdate,
  styleSheet,
  searchFilter,
}) => {
  const { infoProcessRemoteWork, isLoadingInfoProcRemoteWork, collaborators } =
    useSelector((state) => state.portalrhReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const { genericRequestState } = useSelector((state) => state.portalrhReducer);

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  const [infoData, setinfoData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (process != undefined) {
      const payload = {
        id: process.id,
      };
      dispatch(getInformationProcessRemoteWork(payload));
    }
  }, [process]);

  useEffect(() => {
    setinfoData(infoProcessRemoteWork);
  }, [infoProcessRemoteWork]);

  const buildNrDiasColumn = (_, row, index) => {
    if (row.qtDias === 1 || row.qtDias === "1") {
      return t("portalrh.workDays.diaInteiro");
    }
    return t("portalrh.workDays.diasMarcadosMeiodia");
  };

  function dateFormater(cell, row) {
    if (row.data) {
      const parsedDate = parseISO(cell);
      return format(parsedDate, "dd/MM/yyyy");
    }
  }

  const buildApprovedColumn = (_, row, index) => {
    if (row.aprovado === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (row.aprovado === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (row.aprovado === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (row.aprovado === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (row.aprovado === 5) {
      return t("portalrh.workDays.Visto");
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "data",
      text: t("portalrh.requestTable.date"),
      sort: true,
      formatter: dateFormater,
    },
    {
      dataField: "qtDias",
      text: t("portalrh.workDays.quantidadeDias"),
      sort: true,
      formatter: buildNrDiasColumn,
    },
    {
      dataField: "aprovado",
      text: t("portalrh.requestTable.state"),
      sort: true,
      formatter: buildApprovedColumn,
    },
  ];

  const handleCancelRequest = () => {
    const payload = {
      requestAprovalDTO: {
        ped_id: process.id,
        state: 2,
        username: userNamePortalRH.username,
      },
      remoteWorkAprovalDTO: [],
    };

    dispatch(approveDeleteRemoveDayRemoteWork(payload));
    setmakeUpdate(true);
    handleCloseModalRemoteWork(searchFilter, 1);
  };

  const { SearchBar } = Search;

  const createState = (item) => {
    return (
      <>
        {item === "Pendente" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaClock style={{ color: "#FFA500" }} />
          </OverlayTrigger>
        )}
        {item === "Aprovado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaCheckCircle
              style={{
                color: "#008000",
              }}
            />
          </OverlayTrigger>
        )}
        {item === "Rejeitado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaTimesCircle
              style={{
                color: "#FF0000",
              }}
            />
          </OverlayTrigger>
        )}
        {item === "Recusado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaTimesCircle
              style={{
                color: "#FF0000",
              }}
            />
          </OverlayTrigger>
        )}
      </>
    );
  };

  const comparesDatesRemoteWorkWithNow = (data) => {
    const dataNow = new Date();
    const dataParaComparar = new Date(data);
    dataNow.setHours(0, 0, 0, 0);
    dataParaComparar.setHours(0, 0, 0, 0);
    if (dataParaComparar < dataNow) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {Object.keys(infoData).length > 0 ? (
        <>
          <Modal
            show={showModalRemoteWork}
            dialogClassName={"modal-tarefa-50w custom-modal"}
            centered
            backdrop="static"
            onHide={() => handleCloseModalRemoteWork()}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("portalrh.workDays.remoteWork")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={
                  styleSheet?.processScroll ? "scrollBar-visibleModal" : ""
                }
              >
                <div
                  style={{
                    margin: window.innerWidth <= 768 ? "0px" : "20px 70px",
                  }}
                >
                  <div className="custom-table custom-table-small tableDateInformation scrollBar-tableDateInformation">
                    <ToolkitProvider
                      keyField="id"
                      data={
                        !isLoadingInfoProcRemoteWork && infoData?.trabalhoremoto
                      }
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div
                            style={{ paddingBottom: "20px", display: "flex" }}
                          >
                            {infoData && infoData?.trabalhoremoto && infoData?.trabalhoremoto.length > 1 && (
                              <SearchBar
                                {...props.searchProps}
                                placeholder={t("Search")}
                              />
                            )}
                          </div>
                          <Spinner
                            spinning={isLoadingInfoProcRemoteWork}
                            wrapper
                          />
                          {!isLoadingInfoProcRemoteWork && (
                            <>
                              <h6>{t("portalrh.workDays.diasSolicitados")}</h6>
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                //pagination={paginationFactory(pagination)}
                                noDataIndication={t(
                                  "portalrh.personalData.noRequests"
                                )}
                                striped
                                hover
                              />
                              {genericRequestState === "Pendente" && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "0px 10px",
                                  }}
                                >
                                  {infoData && infoData?.trabalhoremoto && infoData?.trabalhoremoto.length > 0 &&
                                  comparesDatesRemoteWorkWithNow(
                                    infoData?.trabalhoremoto[0].data
                                  ) ? (
                                    <Stack direction="row" spacing={2}>
                                      <Button
                                        variant="danger"
                                        onClick={() => handleCancelRequest()}
                                      >
                                        {t("portalrh.workDays.cancel")}
                                      </Button>
                                    </Stack>
                                  ) : null}
                                </Box>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
                <div
                  style={{
                    margin: window.innerWidth <= 768 ? "0px" : "20px 70px",
                  }}
                >
                  <AlertMaterial severity="info">
                    <AlertTitle>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {t("portalrh.workDays.lastComment")}{" "}
                          <span style={{ fontSize: "x-small" }}>
                            {!isLoadingInfoProcRemoteWork &&
                              infoData?.comentarios.length > 0 &&
                              infoData?.comentarios && (
                                <>
                                  {format(
                                    parseISO(infoData?.comentarios[0].data),
                                    "yyyy/MM/dd"
                                  )}{" "}
                                  - {infoData?.comentarios[0].username}
                                </>
                              )}
                          </span>
                        </span>
                        <span>
                          {!isLoadingInfoProcRemoteWork &&
                            infoData?.comentarios.length > 0 &&
                            infoData?.comentarios &&
                            createState(infoData?.comentarios[0].estado)}
                        </span>
                      </Stack>
                    </AlertTitle>
                    {!isLoadingInfoProcRemoteWork &&
                      infoData?.comentarios.length > 0 &&
                      infoData?.comentarios && (
                        <>
                          {infoData?.comentarios[0].comentario === ""
                            ? t("portalrh.workDays.emptyComment")
                            : infoData?.comentarios[0].comentario}
                        </>
                      )}
                  </AlertMaterial>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panelfirst"
                      id="panelfirst"
                    >
                      {t("portalrh.workDays.todosComentarios")}
                    </AccordionSummary>
                    <AccordionDetails className="scrollBar-autorizersDateInformation">
                      {!isLoadingInfoProcRemoteWork &&
                        infoData?.comentarios.length > 0 &&
                        infoData?.comentarios.map((item, index) => (
                          <>
                            <Alert style={{ margin: "0px" }}>
                              <AlertTitle>
                                <span>
                                  {item.username}
                                  <span style={{ fontSize: "x-small" }}>
                                    {" "}
                                    -{" "}
                                    {format(
                                      parseISO(item.data),
                                      "yyyy/MM/dd HH:mm"
                                    )}
                                  </span>
                                </span>
                              </AlertTitle>{" "}
                              <span style={{ fontSize: "small" }}>
                                {item.comentario === ""
                                  ? t("portalrh.workDays.emptyComment")
                                  : item.comentario}
                              </span>
                            </Alert>
                          </>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div
                  style={{
                    margin: window.innerWidth <= 768 ? "0px" : "20px 70px",
                  }}
                  className="scrollBar-autorizersDateInformation"
                >
                  <h6>{t("portalrh.workDays.listaAprovadores")}</h6>
                  <ListGroup>
                    {!isLoadingInfoProcRemoteWork &&
                      infoData?.autorizacao.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "self-end",
                            }}
                          >
                            {item.username} -{" "}
                            {t(`portalrh.workDays.${item.state}`)}{" "}
                            {createState(item.state)}
                          </span>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </div>
                {infoData && infoData?.trabalhoremoto && infoData?.trabalhoremoto.length > 0 &&
                  infoData?.trabalhoremoto[0].comentario && (
                    <Alert key="danger" variant="danger">
                      <b>{t("portalrh.workDays.refuseMessage")}:</b>{" "}
                      {infoData?.trabalhoremoto[0].comentario}
                    </Alert>
                  )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <Spinner spinning={isLoadingInfoProcRemoteWork} wrapper={true} />
      )}
    </>
  );
};
export default withNamespaces()(ModalRemoteWork);
