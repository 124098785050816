import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
  getAddress,
  updateAddress,
  createAddress,
  updateNotMainAddress,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";

import { useSelector, useDispatch } from "react-redux";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";
import Messages from "~/components/Messages";
import UploadDocument from "../UploadDocuments";
import ButtonsCreateUpdate from "./ButtonsCreateUpdate";

const RhAddress = ({ rowCollaborator, t }) => {
  const [addressValue, setAddressValue] = useState("");
  const [location, setLocacion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCode2, setPostalCode2] = useState("");
  const [phone, setPhone] = useState("");
  const [cellphone, setcellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [checkActive, setCheckActive] = useState(false);
  const [checkMain, setCheckMain] = useState(false);
  const [rowClicked, setRowClicked] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [addressPage, setAddressPage] = useState(false);
  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [empCode, setEmpCode] = useState("");
  const [oldAddress, setOldAddress] = useState("");
  const [isNew, setIsNew] = useState(false);

  const { addressData, isLoadingUpdateAddress } = useSelector(
    (state) => state.personalReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (rowCollaborator && Object.keys(rowCollaborator).length > 0) {
      dispatch(
        getAddress(rowCollaborator.funcionarioNum, rowCollaborator.codEntidade)
      );
      setIsNew(false);
      setRowClicked([]);
      setAddressPage(true);
    }
  }, [rowCollaborator, isLoadingUpdateAddress]);

  const handleAdressValue = (e) => {
    setAddressValue(e.target.value);
  };

  const handleLocation = (e) => {
    setLocacion(e.target.value);
  };

  const handlePostalCode = (e) => {
    setPostalCode(e.target.value);
  };

  const handlePostalCode2 = (e) => {
    setPostalCode2(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleCellPhone = (e) => {
    setcellPhone(e.target.value);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (data?.length > 0) {
        setAddressValue(data[0].address);
        setLocacion(data[0].location);
        setEmail(data[0].email);
        setcellPhone(data[0].cellphone);
        setPhone(data[0].phone);
        setPostalCode(data[0].postCard);
        setPostalCode2(data[0].postCard2);
        // setPssLct(data[0].pssLct);
        // setType(data[0].type);
        setEmpCode(data[0].empCode);
        setOldAddress(data[0].address);

        if (data[0].main == "1") {
          setCheckMain(true);
        } else {
          setCheckMain(false);
        }

        if (data[0].situation == "1") {
          setCheckActive(true);
        } else {
          setCheckActive(false);
        }
      } else {
        setPostalCode("");
        setPostalCode2("");
        setLocacion("");
        setcellPhone("");
        setPhone("");
        setEmail("");
        setAddressValue("");
      }
    };
    if (rowClicked.length > 0) {
      dataHandling(rowClicked);
    } else {
      dataHandling([]);
    }
  }, [addressData, rowClicked]);

  //ButtonsUpdate state
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  const formatWithCheckIcon = (cell, row) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const columns = [
    {
      dataField: "address",
      text: t(`portalrh.Address.Address`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "location",
      text: t(`portalrh.Address.Location`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "main",
      text: t(`portalrh.Address.Main`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
    {
      dataField: "situation",
      text: t(`portalrh.Address.Active`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  const cleanInputs = () => {
    setPostalCode("");
    setPostalCode2("");
    setLocacion("");
    setcellPhone("");
    setPhone("");
    setEmail("");
    setAddressValue("");
    setCheckActive(false);
    setCheckMain(false);
  };

  const addressObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    postCard: postalCode,
    postCard2: postalCode2,
    location: location,
    cellphone: cellphone,
    phone: phone,
    email: email,
    address: addressValue,
    situation: checkActive ? "1" : "0",
    main: checkMain ? "1" : "0",
    pssLct: rowCollaborator.pssNum,
    type: "PSS",
    oldAddress: oldAddress,
  };

  const notMainAddressObject = {
    pssLct: rowCollaborator.pssNum,
    address: addressValue,
    type: "PSS",
    main: checkMain ? "1" : "0",
  };

  const updateData = (bollean) => {
    setAddressPage(bollean);
    setIsClicked(false);
    if (isNew) {
      dispatch(createAddress(addressObject));
    } else {
      dispatch(updateAddress(addressObject));
    }
    if (addressObject.main === "1") {
      dispatch(updateNotMainAddress(notMainAddressObject));
    }
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
          <ButtonsCreateUpdate
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            setRowClicked={setRowClicked}
            setIsNew={setIsNew}
            cleanInputs={cleanInputs}
          />
          {/* {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              addressPage={addressPage}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
              setIsNew={setIsNew}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              addressPage={addressPage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
              setIsNew={setIsNew}
            />
          ) : null} */}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.Address.Address`)} <b>*</b>
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={addressValue || ""}
                onChange={handleAdressValue}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.Address.Address`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="4" md="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.Address.Location`)} <b>*</b>
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={location == null ? "" : location}
                onChange={handleLocation}
                disabled={isClicked ? false : true}
                maxLength={60}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.Address.Location`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col
            xs="12"
            xl="4"
            md="12"
            style={{ display: "inline-flex", gap: "5px" }}
          >
            <Col xs="6" xl="7" md="7" style={{ padding: "0px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.Address.Zip-Code`)} <b>*</b>
              </Form.Label>
              <Form.Group controlId="validationCustom01">
                <Form.Control
                  className={
                    isClicked
                      ? "collaboratorRH__FormControl2"
                      : "collaboratorRH__FormControl"
                  }
                  value={postalCode == null ? "" : postalCode}
                  onChange={handlePostalCode}
                  disabled={isClicked ? false : true}
                  required
                  type="number"
                />
                <Form.Control.Feedback type="invalid">
                  {`O campo ${t(
                    `portalrh.Address.Zip-Code`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>{" "}
            <Col xs="6" xl="5" md="5" style={{ padding: "0px" }}>
              <Form.Label className="rhcollaborator__labels">&nbsp;</Form.Label>
              <Form.Group controlId="validationCustom01">
                <Form.Control
                  className={
                    isClicked
                      ? "collaboratorRH__FormControl2"
                      : "collaboratorRH__FormControl"
                  }
                  value={postalCode2 == null ? "" : postalCode2}
                  onChange={handlePostalCode2}
                  disabled={isClicked ? false : true}
                  required
                  type="number"
                />
              </Form.Group>
            </Col>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="5">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Email`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={email == null ? "" : email}
              onChange={handleEmail}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Phone`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={phone == null ? "" : phone}
              onChange={handlePhone}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.Address.Mobile`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={cellphone == null ? "" : cellphone}
              onChange={handleCellPhone}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div>
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.Address.Main`)}
            checked={checkMain == null ? "" : checkMain}
            style={{ paddingBottom: 10 }}
            onChange={() => setCheckMain((checkMain) => !checkMain)}
            disabled={isClicked ? false : true}
          />
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.Address.Active`)}
            checked={checkActive == null ? "" : checkActive}
            style={{ paddingBottom: 10, paddingLeft: 50 }}
            onChange={() => setCheckActive((checkActive) => !checkActive)}
            disabled={isClicked ? false : true}
          />
        </div>

        <UploadDocument
          isClicked={isClicked}
          uploadFile={addressPage}
          rowCollaborator={rowCollaborator}
          id={2}
        />

        <RhTableList
          tableId={"32"}
          columns={columns}
          data={addressData}
          onClickedRow={takeClickedRow}
        />
      </Form>
      <Messages />
    </div>
  );
};
export default withNamespaces()(RhAddress);
