import React, { useState } from "react";

export const RequestState = {
  APPROVED: 'Aprovado',
  PENDING: 'Pendente',
  REJECTED: 'Recusado',
  TRATADOS: "Tratados"
}

export const AuthorizationState = {
  PENDING: 'Pendente',
  SEEN: 'Resolvido',
}

export const AuthorizerApproves= {
  YES: 1,
  NO: 0,
}