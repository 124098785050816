import {
  //INTEGRATION COLLABORATORS
  GET_CRUD_COLLABORATORS,
  SET_CRUD_COLLABORATORS,
  DONE_GET_CRUD_COLLABORATORS,
  POST_ADD_CRUD_COLLABORATORS,
  SET_MESSAGE_ADD_CRUD_COLLABORATORS,
  //CATEGORIES
  GET_CRUD_CATEGORIES,
  SET_CRUD_CATEGORIES,
  DONE_GET_CRUD_CATEGORIES,
  POST_ADD_CRUD_CATEGORIES,
  SET_MESSAGE_ADD_CRUD_CATEGORIES,
  POST_DELETE_CRUD_CATEGORIES,
  //COMPANY
  GET_CRUD_COMPANIES,
  DONE_GET_CRUD_COMPANIES,
  SET_CRUD_COMPANIES,
  //DEPARTMENT
  GET_CRUD_DEPARTMENT,
  POST_DELETE_CRUD_DEPARTMENT,
  SET_CRUD_DEPARTMENT,
  DONE_GET_CRUD_DEPARTMENT,
  POST_ADD_CRUD_DEPARTMENT,
  SET_MESSAGE_ADD_CRUD_DEPARTMENT,
  //TEAM
  GET_CRUD_TEAM,
  POST_DELETE_CRUD_TEAM,
  SET_CRUD_TEAM,
  DONE_GET_CRUD_TEAM,
  POST_ADD_CRUD_TEAM,
  SET_MESSAGE_ADD_CRUD_TEAM,
  //PARTNERS
  GET_CRUD_PARTNER,
  POST_DELETE_CRUD_PARTNER,
  SET_CRUD_PARTNER,
  DONE_GET_CRUD_PARTNER,
  POST_ADD_CRUD_PARTNER,
  SET_MESSAGE_ADD_CRUD_PARTNER,
  //EXPENSES
  GET_CRUD_EXPENSE,
  POST_DELETE_CRUD_EXPENSE,
  SET_CRUD_EXPENSE,
  DONE_GET_CRUD_EXPENSE,
  POST_ADD_CRUD_EXPENSE,
  SET_MESSAGE_ADD_CRUD_EXPENSE,
  //CLIENT DIRECTORS
  GET_CRUD_CLIENTDIRECTOR,
  POST_DELETE_CRUD_CLIENTDIRECTOR,
  SET_CRUD_CLIENTDIRECTOR,
  DONE_GET_CRUD_CLIENTDIRECTOR,
  POST_ADD_CRUD_CLIENTDIRECTOR,
  SET_MESSAGE_ADD_CRUD_CLIENTDIRECTOR,
  //COLLABORATORS
  GET_CRUD_COLLAB,
  POST_DELETE_CRUD_COLLAB,
  SET_CRUD_COLLAB,
  DONE_GET_CRUD_COLLAB,
  POST_ADD_CRUD_COLLAB,
  SET_MESSAGE_ADD_CRUD_COLLAB,
} from "./actionTypes";

const initialState = {
  //INTEGRATION COLLABORATORS
  collaborators_CRUD: [],
  isLoadingCRUDCollaboratos: false,
  collaborators_CRUD_ADD: [],
  //CATEGORIES
  categories_CRUD: [],
  isLoadingCRUDCategories: false,
  categories_CRUD_ADD: [],
  //COMPANY
  companies_CRUD: [],
  isLoadingCRUDCompanies: false,
  //DEPARTMENT
  departments_CRUD: [],
  isLoadingCRUDDepartment: false,
  departments_CRUD_ADD: [],
  //TEAM
  teams_CRUD: [],
  isLoadingCRUDTeam: false,
  teams_CRUD_ADD: [],
  //PARTNERS
  partners_CRUD: [],
  isLoadingCRUDPartner: false,
  partners_CRUD_ADD: [],
  //EXPENSES
  expenses_CRUD: [],
  isLoadingCRUDExpense: false,
  expenses_CRUD_ADD: [],
  //CLIENT DIRECTORS
  clientDirectors_CRUD: [],
  isLoadingCRUDClientDirector: false,
  clientDirectors_CRUD_ADD: [],
  //COLLABORATORS
  collabs_CRUD: [],
  isLoadingCRUDCollab: false,
  collabs_CRUD_ADD: [],
};

export function budgetManagementCRUDReducer(state = initialState, action) {
  switch (action.type) {
    //INTEGRATION COLLABORATORS
    case GET_CRUD_COLLABORATORS:
      return { ...state, isLoadingCRUDCollaboratos: true };
    case SET_CRUD_COLLABORATORS:
      return { ...state, collaborators_CRUD: action.collaborators_CRUD };
    case DONE_GET_CRUD_COLLABORATORS:
      return { ...state, isLoadingCRUDCollaboratos: false };
    case POST_ADD_CRUD_COLLABORATORS:
      return { ...state, isLoadingCRUDCollaboratos: false };
    case SET_MESSAGE_ADD_CRUD_COLLABORATORS:
      return {
        ...state,
        collaborators_CRUD_ADD: action.collaborators_CRUD_ADD,
      };
    //CATEGORIES
    case GET_CRUD_CATEGORIES:
      return { ...state, isLoadingCRUDCategories: true };
    case POST_DELETE_CRUD_CATEGORIES:
      return { ...state, isLoadingCRUDCategories: true };
    case SET_CRUD_CATEGORIES:
      return { ...state, categories_CRUD: action.categories_CRUD };
    case DONE_GET_CRUD_CATEGORIES:
      return { ...state, isLoadingCRUDCategories: false };
    case POST_ADD_CRUD_CATEGORIES:
      return { ...state, isLoadingCRUDCategories: false };
    case SET_MESSAGE_ADD_CRUD_CATEGORIES:
      return {
        ...state,
        categories_CRUD_ADD: action.categories_CRUD_ADD,
      };
    //COMPANY
    case GET_CRUD_COMPANIES:
      return { ...state, isLoadingCRUDCompanies: true };
    case SET_CRUD_COMPANIES:
      return { ...state, companies_CRUD: action.companies_CRUD };
    case DONE_GET_CRUD_COMPANIES:
      return { ...state, isLoadingCRUDCompanies: false };
    //DEPARTMENT
    case GET_CRUD_DEPARTMENT:
      return { ...state, isLoadingCRUDDepartment: true };
    case POST_DELETE_CRUD_DEPARTMENT:
      return { ...state, isLoadingCRUDDepartment: true };
    case SET_CRUD_DEPARTMENT:
      return { ...state, departments_CRUD: action.departments_CRUD };
    case DONE_GET_CRUD_DEPARTMENT:
      return { ...state, isLoadingCRUDDepartment: false };
    case POST_ADD_CRUD_DEPARTMENT:
      return { ...state, isLoadingCRUDDepartment: false };
    case SET_MESSAGE_ADD_CRUD_DEPARTMENT:
      return {
        ...state,
        departments_CRUD_ADD: action.departments_CRUD_ADD,
      };
    //TEAM
    case GET_CRUD_TEAM:
      return { ...state, isLoadingCRUDTeam: true };
    case POST_DELETE_CRUD_TEAM:
      return { ...state, isLoadingCRUDTeam: true };
    case SET_CRUD_TEAM:
      return { ...state, teams_CRUD: action.teams_CRUD };
    case DONE_GET_CRUD_TEAM:
      return { ...state, isLoadingCRUDTeam: false };
    case POST_ADD_CRUD_TEAM:
      return { ...state, isLoadingCRUDTeam: false };
    case SET_MESSAGE_ADD_CRUD_TEAM:
      return {
        ...state,
        teams_CRUD_ADD: action.teams_CRUD_ADD,
      };
    //PARTNERS
    case GET_CRUD_PARTNER:
      return { ...state, isLoadingCRUDPartner: true };
    case POST_DELETE_CRUD_PARTNER:
      return { ...state, isLoadingCRUDPartner: true };
    case SET_CRUD_PARTNER:
      return { ...state, partners_CRUD: action.partners_CRUD };
    case DONE_GET_CRUD_PARTNER:
      return { ...state, isLoadingCRUDPartner: false };
    case POST_ADD_CRUD_PARTNER:
      return { ...state, isLoadingCRUDPartner: false };
    case SET_MESSAGE_ADD_CRUD_PARTNER:
      return {
        ...state,
        partners_CRUD_ADD: action.partners_CRUD_ADD,
      };
    //EXPENSES
    case GET_CRUD_EXPENSE:
      return { ...state, isLoadingCRUDExpense: true };
    case POST_DELETE_CRUD_EXPENSE:
      return { ...state, isLoadingCRUDExpense: true };
    case SET_CRUD_EXPENSE:
      return { ...state, expenses_CRUD: action.expenses_CRUD };
    case DONE_GET_CRUD_EXPENSE:
      return { ...state, isLoadingCRUDExpense: false };
    case POST_ADD_CRUD_EXPENSE:
      return { ...state, isLoadingCRUDExpense: false };
    case SET_MESSAGE_ADD_CRUD_EXPENSE:
      return {
        ...state,
        expenses_CRUD_ADD: action.expenses_CRUD_ADD,
      };
    //CLIENT DIRECTORS
    case GET_CRUD_CLIENTDIRECTOR:
      return { ...state, isLoadingCRUDClientDirector: true };
    case POST_DELETE_CRUD_CLIENTDIRECTOR:
      return { ...state, isLoadingCRUDClientDirector: true };
    case SET_CRUD_CLIENTDIRECTOR:
      return { ...state, clientDirectors_CRUD: action.clientDirectors_CRUD };
    case DONE_GET_CRUD_CLIENTDIRECTOR:
      return { ...state, isLoadingCRUDClientDirector: false };
    case POST_ADD_CRUD_CLIENTDIRECTOR:
      return { ...state, isLoadingCRUDClientDirector: false };
    case SET_MESSAGE_ADD_CRUD_CLIENTDIRECTOR:
      return {
        ...state,
        clientDirectors_CRUD_ADD: action.clientDirectors_CRUD_ADD,
      };
    //COLLABORATORS
    case GET_CRUD_COLLAB:
      return { ...state, isLoadingCRUDCollab: true };
    case POST_DELETE_CRUD_COLLAB:
      return { ...state, isLoadingCRUDCollab: true };
    case SET_CRUD_COLLAB:
      return { ...state, collabs_CRUD: action.collabs_CRUD };
    case DONE_GET_CRUD_COLLAB:
      return { ...state, isLoadingCRUDCollab: false };
    case POST_ADD_CRUD_COLLAB:
      return { ...state, isLoadingCRUDCollab: false };
    case SET_MESSAGE_ADD_CRUD_COLLAB:
      return {
        ...state,
        collabs_CRUD_ADD: action.collabs_CRUD_ADD,
      };
    default:
      return state;
  }
}
