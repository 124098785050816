export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export const PERSIST_PERMISSION = 'PERSIST_PERMISSION';
export const PERSIST_PERMISSION_DONE = 'PERSIST_PERMISSION_DONE';

export const GET_PERMISSION_BY_ID = 'GET_PERMISSION_BY_ID';
export const SET_PERMISSION = 'SET_PERMISSION';

export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS';
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';

export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const DELETE_PERMISSION_DONE = 'DELETE_PERMISSION_DONE';

export const getPermissions = () => {
    return { type: GET_PERMISSIONS }
}

export const setPermissions = (permissions) => {
    return { type: SET_PERMISSIONS, permissions }
}

export const persistPermission = (permission) => {
    return { type: PERSIST_PERMISSION, permission }
}

export const persistPermissionDone = (success) => {
    return { type: PERSIST_PERMISSION_DONE, success }
}

export const getPermissionById = (id) => {
    return { type: GET_PERMISSION_BY_ID, id }
}

export const setPermission = (permission) => {
    return { type: SET_PERMISSION, permission }
}

export const clearPermissions = () => {
    return { type: CLEAR_PERMISSIONS }
}

export const clearPermission = () => {
    return { type: CLEAR_PERMISSION }
}

export const deletePermission = (id) => {
    return { type: DELETE_PERMISSION, id }
}

export const deletePermissionDone = () => {
    return { type: DELETE_PERMISSION_DONE }
}