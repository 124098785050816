import React, { useRef } from "react";
import "../assets/scss/_modalContainer.scss";
import Modal from "./Modal";

const ModalContainer = ({ contents, isShowing, setIsShowing }) => {
  const ref = useRef();

  return (
    isShowing && (
      <div className="easypay-back-shed" ref={ref}>
        <Modal
          className="easypay-modal"
          isShowing={isShowing}
          setShowModal={setIsShowing}
          headerContent={contents.modalTitle}
          bodyContent={contents.modalBody}
          footerContent={contents.modalFooter}
        ></Modal>
      </div>
    )
  );
};

export default ModalContainer;
