import React, { useEffect, useState } from "react";
import { Accordion, Col, Form } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import Dropdown from "~/components/Dropdown";
import SearchableDropDown from "~/components/SearchableDropDown";
import FilterToggle from "./FilterToggle";
import {
  adaptListForDocType,
  adaptListForOperations,
} from "./handleDropdownList";

const DocumentFilters = ({ ...props }) => {
  const {
    t,
    handleExpirationDateEnd,
    handleExpirationDateStart,
    expirationDateEnd,
    expirationDateStart,
    uploadedDateStart,
    uploadedDateEnd,
    setUploadedDateStart,
    setUploadedDateEnd,
    activeKey,
    setActiveKey,
    selectedOperation,
    setSelectedOperation,
    setDoctype,
    numFuncionario,
    nome,
    setNumFuncionario,
    setNome,
    fileName,
    uploadedBy,
    setFileName,
    setUploadedBy,
    operations,
    collaborators,
    manager,
    setSeenStatus,
    seenStatus,
  } = props;

  const [toggleIcon, setToggleIcon] = useState("icon-down");
  const [list, setList] = useState([]);
  const [listNome, setListNome] = useState([]);

  useEffect(() => {
    if (collaborators && collaborators.length > 0) {
      const optionList = collaborators.map((option) => ({
        label: option.nome,
        value: option.id,
        selected: uploadedBy && uploadedBy == option.id ? true : false,
      }));

      if (optionList) {
        setList(optionList);
      }

      const optionListNome = collaborators.map((option) => ({
        label: option.nome,
        value: option.nome,
        selected: nome && nome == option.nome ? true : false,
      }));

      if (optionListNome) {
        setListNome(optionListNome);
      }
    }
  }, [collaborators, uploadedBy, nome]);

  return (
    <Accordion activeKey={activeKey} style={{ marginBottom: "10px" }}>
      <FilterToggle
        eventKey="0"
        setToggleIcon={setToggleIcon}
        setActiveKey={setActiveKey}
        toggleIcon={toggleIcon}
      />
      <Accordion.Collapse eventKey="0">
        <Form>
          <Form.Row lg="4" className="ml-1">
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label={t("portalrh.documentManagement.checkboxFilter.all")}
                name="seenStatus"
                type="radio"
                id={`inline-radio-1`}
                value="all"
                checked={seenStatus == "all"}
                onChange={(e) => setSeenStatus(e.target.value)}
              />
              <Form.Check
                inline
                label={t("portalrh.documentManagement.checkboxFilter.seen")}
                name="seenStatus"
                type="radio"
                id={`inline-radio-2`}
                value="seen"
                checked={seenStatus === "seen"}
                onChange={(e) => setSeenStatus(e.target.value)}
              />
              <Form.Check
                inline
                label={t("portalrh.documentManagement.checkboxFilter.unseen")}
                name="seenStatus"
                type="radio"
                id={`inline-radio-3`}
                value="unseen"
                checked={seenStatus === "unseen"}
                onChange={(e) => setSeenStatus(e.target.value)}
              />
            </div>
          </Form.Row>
          <Form.Row>
            <Col lg="4">
              <Form.Label>
                {t("portalrh.documentManagement.operation")}
              </Form.Label>
              <Dropdown
                list={adaptListForOperations(
                  operations,
                  t("portalrh.documentManagement.selectOperationPlaceholder")
                )}
                handleSubmit={setSelectedOperation}
                resetDropdown={!selectedOperation}
              />
            </Col>
            {selectedOperation && (
              <Col lg="4">
                <Form.Label>
                  {t("portalrh.documentManagement.documentType")}
                </Form.Label>
                <Dropdown
                  list={adaptListForDocType(
                    operations,
                    selectedOperation,
                    t("portalrh.documentManagement.selectOperationPlaceholder")
                  )}
                  emptyDefault={selectedOperation}
                  handleSubmit={setDoctype}
                  sort={false}
                />
              </Col>
            )}
          </Form.Row>
          <br />
          <Form.Row>
            <Col lg="4">
              <Form.Label>
                {t("portalrh.documentManagement.expirationDate")}&nbsp;
                {t("portalrh.documentManagement.between")}
              </Form.Label>
              <DateTimePicker
                value={expirationDateStart}
                onChange={(e) => handleExpirationDateStart(e)}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
              />
            </Col>
            <Col lg="4">
              <Form.Label>&nbsp;</Form.Label>
              <DateTimePicker
                value={expirationDateEnd}
                onChange={(e) => handleExpirationDateEnd(e)}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
              />
            </Col>
          </Form.Row>
          {manager && (
            <>
              <br />
              <Form.Row>
                <Col lg="4">
                  <Form.Label>
                    {t("portalrh.documentManagement.uploadDate")}&nbsp;
                    {t("portalrh.documentManagement.between")}
                  </Form.Label>
                  <DateTimePicker
                    value={uploadedDateStart}
                    onChange={(e) => setUploadedDateStart(e)}
                    locale="pt-PT"
                    format="dd/MM/yyyy"
                    className="form-control"
                  />
                </Col>
                <Col lg="4">
                  <Form.Label>&nbsp;</Form.Label>
                  <DateTimePicker
                    value={uploadedDateEnd}
                    onChange={(e) => setUploadedDateEnd(e)}
                    locale="pt-PT"
                    format="dd/MM/yyyy"
                    className="form-control"
                  />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Col lg="4">
                  <Form.Label>
                    {t("portalrh.documentManagement.numFuncionario")}
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    value={numFuncionario}
                    onChange={(e) => {
                      setNumFuncionario(e.target.value);
                    }}
                  />
                </Col>
                <Col lg="4">
                  <Form.Label>
                    {t("portalrh.documentManagement.receiver")}
                  </Form.Label>
                  <SearchableDropDown
                    name="collaborator"
                    list={listNome}
                    required={false}
                    handleSubmit={(value) => setNome(value)}
                    resetSearchableDropdown={!nome}
                    enableSearchEmptyValue={true}
                  />
                </Col>
              </Form.Row>
              <Form.Row style={{ marginTop: "-10px" }}>
                <Col lg="4">
                  <Form.Label>
                    {t("portalrh.documentManagement.sender")}
                  </Form.Label>
                  <SearchableDropDown
                    name="collaborator"
                    list={list}
                    required={false}
                    handleSubmit={(value) => setUploadedBy(value)}
                    resetSearchableDropdown={!uploadedBy}
                    enableSearchEmptyValue={true}
                  />
                </Col>
                <Col lg="4">
                  <Form.Label>
                    {t("portalrh.documentManagement.filename")}
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    value={fileName}
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Row>
            </>
          )}
          <br />
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default withNamespaces()(DocumentFilters);
