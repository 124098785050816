import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import { getMedicalRecord } from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { Col, Form, Row } from "react-bootstrap";
import ButtonsUpdate from "./ButtonsUpdate";
import DateTimePicker from "react-datetime-picker";

const RhMedicalRecord = ({ rowCollaborator, t }) => {
  const [examDate, setExamDate] = useState("");
  const [nextExamDate, setNextExamDate] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [checkAdmission, setCheckAdmission] = useState(false);
  const [checkPeriodical, setCheckPeriodical] = useState(false);
  const [checkOccasional, setCheckOccasional] = useState(false);
  const [checkOther, setCheckOther] = useState(false);
  const [checkAble, setCheckAble] = useState(false);
  const [checkConditionalAble, setCheckConditionalAble] = useState(false);
  const [checkTemporarilyUnable, setCheckTemporarilyUnable] = useState(false);
  const [checkDefinitelyUnable, setCheckDefinitelyUnable] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(
        getMedicalRecord(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
    }
  }, [rowCollaborator]);

  const { medicalData } = useSelector((state) => state.personalReducer);

  const handleExamDate = (e) => {
    setExamDate(e);
  };

  const handleNextExamDate = (e) => {
    setNextExamDate(e);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (data.length > 0) {
        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          }
        };
        checkDate(data[0].examDate, setExamDate);
        checkDate(data[0].nextExameDate, setNextExamDate);

        if (data[0].admission == "1") {
          setCheckAdmission(true);
        } else {
          setCheckAdmission(false);
        }

        if (data[0].periodic == "1") {
          setCheckPeriodical(true);
        } else {
          setCheckPeriodical(false);
        }

        if (data[0].ocasional == "1") {
          setCheckOccasional(true);
        } else {
          setCheckOccasional(false);
        }

        if (data[0].other == "1") {
          setCheckOther(true);
        } else {
          setCheckOther(false);
        }

        if (data[0].able == "1") {
          setCheckAble(true);
        } else {
          setCheckAble(false);
        }

        if (data[0].conditionalAble == "1") {
          setCheckConditionalAble(true);
        } else {
          setCheckConditionalAble(false);
        }

        if (data[0].temporaryUnable == "1") {
          setCheckTemporarilyUnable(true);
        } else {
          setCheckTemporarilyUnable(false);
        }

        if (data[0].definitiveUnable == "1") {
          setCheckDefinitelyUnable(true);
        } else {
          setCheckDefinitelyUnable(false);
        }
      } else {
        setExamDate();
        setNextExamDate();
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(medicalData);
    }
  }, [medicalData, rowClicked]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const formatExamType = (cell, row) => {
    for (let i = 0; i < medicalData?.length; i++) {
      if (medicalData[i].admission == "1") {
        return <p>Admission</p>;
      } else if (medicalData[i].periodic == "1") {
        return <p>Periodic</p>;
      } else if (medicalData[i].ocasional == "1") {
        return <p>Ocasional</p>;
      } else if (medicalData[i].other == "1") {
        return <p>Other</p>;
      }
    }
  };

  const formatExamResult = (cell, row) => {
    for (let i = 0; i < medicalData?.length; i++) {
      if (medicalData[i].able == "1") {
        return <p>Able</p>;
      } else if (medicalData[i].conditionalAble == "1") {
        return <p>Conditional Able</p>;
      } else if (medicalData[i].temporaryUnable == "1") {
        return <p>Temporary Unable</p>;
      } else if (medicalData[i].definitiveUnable == "1") {
        return <p>Definitive Unable</p>;
      }
    }
  };
  const formatEndData = (cell) => {
    if (cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const columns = [
    {
      dataField: "degreeLabel",
      text: t(`portalrh.MedicalRecord.examType`),
      sort: true,
      formatter: formatExamType,
      style: { fontSize: "12px" },
    },
    {
      dataField: "examDate",
      text: t(`portalrh.MedicalRecord.ExamDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
    {
      dataField: "date",
      text: t(`portalrh.MedicalRecord.ExamResult`),
      sort: true,
      formatter: formatExamResult,
      style: { fontSize: "12px" },
    },
    {
      dataField: "nextExameDate",
      text: "Next Exam Dt.",
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
  ];
  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }
  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.MedicalRecord.examType`)}
            </Form.Label>
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.Admission`)}
              checked={checkAdmission}
              style={{ paddingBottom: 10 }}
              onChange={() =>
                setCheckAdmission((checkAdmission) => !checkAdmission)
              }
              disabled={isClicked ? false : true}
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.Periodical`)}
              checked={checkPeriodical}
              style={{ paddingBottom: 10 }}
              onChange={() =>
                setCheckPeriodical((checkPeriodical) => !checkPeriodical)
              }
              disabled={isClicked ? false : true}
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.Occasional`)}
              checked={checkOccasional}
              style={{ paddingBottom: 10 }}
              onChange={() =>
                setCheckOccasional((checkOccasional) => !checkOccasional)
              }
              disabled={isClicked ? false : true}
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.Other`)}
              checked={checkOther}
              style={{ paddingBottom: 10 }}
              onChange={() => setCheckOther((checkOther) => !checkOther)}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.MedicalRecord.ExamResult`)}
            </Form.Label>
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.Able`)}
              checked={checkAble}
              style={{ paddingBottom: 10 }}
              onChange={() => setCheckAble((checkAble) => !checkAble)}
              disabled={isClicked ? false : true}
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.CoditionalAble`)}
              checked={checkConditionalAble}
              style={{ paddingBottom: 10 }}
              onChange={() =>
                setCheckConditionalAble(
                  (checkConditionalAble) => !checkConditionalAble
                )
              }
              disabled={isClicked ? false : true}
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.TemporarilyUnable`)}
              checked={checkTemporarilyUnable}
              style={{ paddingBottom: 10 }}
              onChange={() =>
                setCheckTemporarilyUnable(
                  (checkTemporarilyUnable) => !checkTemporarilyUnable
                )
              }
              disabled={isClicked ? false : true}
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="checkbox"
              label={t(`portalrh.MedicalRecord.DefinitelyUnable`)}
              checked={checkDefinitelyUnable}
              style={{ paddingBottom: 10 }}
              onChange={() =>
                setCheckDefinitelyUnable(
                  (checkDefinitelyUnable) => !checkDefinitelyUnable
                )
              }
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.MedicalRecord.ExamDate`)}
            </Form.Label>
            <DateTimePicker
              value={examDate}
              onChange={handleExamDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels__before">
              {t(`portalrh.MedicalRecord.NextExamDate`)}
            </Form.Label>
            <Form.Label className="rhcollaborator__labels__after">
              {t(`portalrh.MedicalRecord.NextExamDate`)}
            </Form.Label>
            <DateTimePicker
              value={nextExamDate}
              onChange={handleNextExamDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <RhTableList
          tableId={"34"}
          columns={columns}
          data={medicalData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhMedicalRecord);
