import _uniqueId from "lodash/uniqueId";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "~/pages/Easypay/assets/scss/_dropdown2.scss";
import iconSearchDefault from "../assets/img/icons/icon-search-default.png";
import iconSearchDisabled from "../assets/img/icons/icon-search-disabled.png";
import iconSearchFocus from "../assets/img/icons/icon-search-focus.png";
import iconSearch from "../assets/img/icons/icon-search.png";
import "../assets/scss/_inputSearch.scss";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder (text displayed when no input exists)
        - defaultInput (pre selected input)
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
*/

const InputSimpleSearch = (props) => {
  const {
    t,
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar opção",
    defaultInput = "",
    disabled,
    text,
    name,
    noBorder,
  } = props;

  const [inputSelected, setInputSelected] = useState(false);
  const [label, setLabel] = useState(defaultInput);
  const [icon, setIcon] = useState(disabled ? iconSearchDisabled : iconSearch);

  //Randomly creates an id for input
  const id = useRef(_uniqueId(""));

  /*
    Functions that runs anytime the input changes
  */
  const handleInput = (input) => {
    //Changes input to the selected option
    setLabel(input);

    if (!handleSubmit) {
      return;
    }
    handleSubmit(input);
  };

  return (
    <>
      <Form.Group controlId={`formText-field`}>
        <div className={"easypay-input-search-wrapper"}>
          <div className="easypay-input-label">{t(text)}</div>
          <div
            className={`easypay-input-search-field-wrapper ${
              noBorder ? "" : label ? "easypay-filled" : ""
            } ${inputSelected && !disabled ? "easypay-input-selected" : ""}`}
            disabled={disabled}
            tabIndex="1"
          >
            <input
              disabled={disabled}
              autoComplete="off"
              onFocus={() => {
                setIcon(iconSearchFocus);
                setInputSelected(true);
              }}
              className={`easypay-input-search-field`}
              placeholder={`${placeholder}`}
              id={`formInput-${id.current}`}
              //onKeyUp={(e) => typeFunction(e)}
              value={label}
              required={required}
              onBlur={(e) => {
                setIcon(iconSearchDefault);
                setInputSelected(false);
              }}
              onChange={(e) => {
                handleInput(e.target.value);
              }}
              name={name + "Label"}
            />
            <div className="easypay-input-search-icon-wrapper">
              <img
                className="easypay-input-search-icon"
                src={icon}
                alt="search-icon"
              />
            </div>
          </div>
        </div>
        <input type="hidden" name={name} value={label} />
        <Form.Control.Feedback type="invalid">
          {`O campo field é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
export default withNamespaces()(InputSimpleSearch);
