import React, { useState, useEffect } from "react";
import CreateEndProcessBadge from "./CreateEndProcessBadge";
import CreateCloseProcessButton from "./CreateCloseProcessButton";
import { parseHtmlText } from "../utils";
import CreateAlert from "./CreateAlert";
import { withNamespaces } from "react-i18next";

const CreateErrorPage = (props) => {
  const { isModal, jsonData, styleSheet, t } = props;

  const [badgeText, setBadgeText] = useState("");

  useEffect(() => {
    if (jsonData && jsonData.responseMessage) {
      let text = "";
      if (jsonData.responseMessage.includes('<div class="error_msg">')) {
        text = parseHtmlText(
          jsonData.responseMessage,
          '<div class="error_msg">',
          "</div>"
        );
      }

      if (
        jsonData.responseMessage.includes('<div class="alert alert-danger">')
      ) {
        text = parseHtmlText(
          jsonData.responseMessage,
          '<div class="alert alert-danger">',
          "</div>"
        );
      }

      if (
        jsonData.responseMessage.includes(
          '<div class="info_msg" style="font-family: Verdana,Arial,sans-serif;">'
        )
      ) {
        text = parseHtmlText(
          jsonData.responseMessage,
          '<div class="info_msg" style="font-family: Verdana,Arial,sans-serif;">',
          "</div>"
        );
      }

      if (text) {
        setBadgeText(text);
      }
    }
  }, [jsonData]);

  let field = {
    text: badgeText ? badgeText : "Process error",
  };

  return (
    <div
      style={{
        marginTop: "0",
      }}
    >
      {!isModal && styleSheet?.processCloseButton && (
        <CreateCloseProcessButton className="close-button_custom" />
      )}
      {styleSheet?.badgesMessage ? (
        <div
          style={{
            marginTop: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CreateEndProcessBadge
            badgeText={badgeText}
            badgeTextDefault={t("taskforward.error")}
            className="badge-danger"
            styleSheet={styleSheet}
          />
        </div>
      ) : (
        <CreateAlert field={field} variant="danger" />
      )}
      <br />
    </div>
  );
};

export default withNamespaces()(CreateErrorPage);
