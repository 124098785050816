import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProtectedPage from "~/components/security/ProtectedPage";
import EasypayRegisterForm from "./pages/OnboardingUser/EasypayRegisterForm";
import "~/pages/Easypay/assets/scss/_responsive.scss";

import { getAPISettings } from "~/store/ducks/onboardingSettings/actionTypes";

const Easypay = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  useEffect(() => {
    user?.organization?.id && dispatch(getAPISettings(apiSettings));
  }, [user]);

  return (
    <>
      <ProtectedPage roles={["ADMIN"]}>
        <EasypayRegisterForm />
      </ProtectedPage>
    </>
  );
};

export default Easypay;
