import React, { useEffect, useState } from "react";

import ProtectedRoute from "~/components/security/ProtectedRoute";
import IntranetLogin from "~/pages/Intranet";
//import Root from '../components/Root';
import Login from "../pages/Login";
import ForgotPassword from "../pages/Login/ForgotPassword";
import SuccessPageResetPassword from "~/pages/Login/SuccessPageResetPassword";
import ChangePassword from "../pages/Login/ChangePassword";
import LoadingPage from "~/pages/Easypay/pages/LoadingPage";

import Layout from "./Layout";
import DigitalSignature from "~/components/DigitalSignature";
import RegisterOrgAdmin from "~/pages/Register/RegisterOrgAdmin";
import ConfirmEmail from "~/pages/Register/ConfirmEmail";
import ResetAdminPassword from "~/pages/ResetAdminPassword/ResetAdminPassword";
import ValidationLoading from "~/pages/Register/ValidationLoading";
import ValidationAdminLoading from "~/pages/ResetAdminPassword/ValidationAdminLoading";
import ConfirmResetAdminPassword from "~/pages/ResetAdminPassword/ConfirmResetAdminPassword";
import InsertCode from "~/pages/ResetAdminPassword/InsertCode";
import AliceProcess from "~/pages/Easypay/pages/AliceProcess";
import Simulator from "~/components/Simulator/FreeSimulator/index";
import RepLegPDA from "~/pages/Easypay/pages/RepLegPDA";
import SignPdf from "~/components/FreeSignPdf/index";
import ProcessDetailExternal from "~/pages/ProcessDetailExternal";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import SAMLLogin from "../pages/Login/SAMLLogin";

import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Error404 from "~/components/Exception/Error404";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const App = (props) => {
  const { keycloak, isCma } = props;

  const [authenticatedKeycloak, setAuthenticatedKeycloak] = useState(false);

  setTimeout(function () {
    setAuthenticatedKeycloak(keycloak.authenticated !== undefined ? keycloak.authenticated : false);
  }, 1000);

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            component={() => (
              <Login isCma={isCma} isLoading={false} isLoggedKeycloak={false} />
            )}
          />
          <Route
            exact
            path="/login"
            component={() => (
              <Login isCma={isCma} isLoading={false} isLoggedKeycloak={false} />
            )}
          />
          <Route exact path="/login-saml" component={SAMLLogin} />
          <Route exact path="/register" component={RegisterOrgAdmin} />
          <Route exact path="/confirm" component={ConfirmEmail} />
          <Route
            exact
            path="/confirm-admin-password"
            component={ConfirmResetAdminPassword}
          />
          <Route exact path="/insert-code" component={InsertCode} />
          <Route
            path="/reset-admin-password/:code"
            component={ResetAdminPassword}
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/success-page-reset-pass"
            component={SuccessPageResetPassword}
          />
          <Route path="/validate" component={ValidationLoading} />
          <Route path="/validate-admin" component={ValidationAdminLoading} />
          <Route path="/change-password/:token" component={ChangePassword} />
          <Route path="/alice-biometrics/:token" component={LoadingPage} />
          <Route path="/digital-signature" component={DigitalSignature} />
          <Route path="/sign-pdf" component={SignPdf} />

          <Route path="/employee-simulator" component={Simulator} />
          <Route path="/alice-process" component={AliceProcess} />
          <Route path="/repleg-pda/:id" component={RepLegPDA} />

          <Route path={"/procdetail"}>
            <ProcessDetailExternal />
          </Route>

          <ProtectedRoute
            path="/uniksystem"
            component={() => <Layout keycloak={keycloak} />}
          />
          <Route
            exact
            path="/intranet-integration"
            component={() => (
              <IntranetLogin />
            )}
          />
          <Route
            path="*"
            component={
              authenticatedKeycloak
                ? () => <Login isCma={isCma} isLoading={true} isLoggedKeycloak={true} />
                : Error404
            }
          />
        </Switch>
      </Router>
    </ReactKeycloakProvider>
  );
};
export default App;
