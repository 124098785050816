const buttonResult = "document.dados.buttonResult.value='";
const botao = "document.dados.botao.value='";
const botao_accao = "document.dados.botao_accao.value='";
const op = "document.dados.op.value='";
const op_ = "document.dados.op.value=";
const button_clicked_id =
  "document.getElementById('_button_clicked_id').value='";
const devLogConsole = require("~/utils/devLog");
export const handleButtonClick = (operation, buttonName) => {
  if (operation) {
    if (operation.includes(buttonResult)) {
      const array = operation.split(buttonResult);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.buttonResult.value = array2[0];
        }
      }
    }
    if (operation.includes(botao)) {
      let splitArray = operation.split(botao);

      if (splitArray[1]) {
        let value = splitArray[1].split("';")[0];
        if (document.dados.botao !== undefined) {
          document.dados.botao.value = value;
        } else {
          for (let i = 0; i < document.dados.length; i++) {
            if (document.dados[i].name === buttonName) {
              document.dados[i].value = value;
              break;
            }
          }
        }
      }
    }

    if (operation.includes(botao_accao)) {
      const array = operation.split(botao_accao);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.botao_accao.value = array2[0];
        }
      }
    }

    if (operation.includes(op)) {
      const array = operation.split(op);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.op.value = array2[0];
        }
      }
    }

    if (operation.includes(op_) && !operation.includes(op)) {
      const array = operation.split(op_);
      if (array) {
        const array2 = array[1].split(";");
        if (array2) {
          document.dados.op.value = array2[0];
        }
      }
    }

    if (operation.includes(button_clicked_id)) {
      const array = operation.split(button_clicked_id);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados._button_clicked_id.value = array2[0];
        }
      }
    }

    if (operation.includes("document.dados.")) {
      const array = operation.split("document.dados.");
      if (array) {
        if (
          (array[1].includes("value=") || array[1].includes('value="')) &&
          !array[1].includes("value='") &&
          !array[1].includes('value="')
        ) {
          array[1] = array[1].replace("value=", "value='");
          array[1] = array[1].replace('value="', "value='");
        }

        const array2 = array[1].split(".value='");
        if (array2) {
          const variable = array2[0];
          let array3;
          if (array2[1].includes("'")) {
            array3 = array2[1].split("'");
          } else if (array2[1].includes('"')) {
            array3 = array2[1].split('"');
          } else array3 = array2[1].split(";");
          document.dados[variable].value = array3[0];

          //check for 1 more
          const part2 = operation.split(`document.dados.${variable}`);
          if (part2[1].includes("document.dados.")) {
            const array4 = part2[1].split("document.dados.");
            if (array4 && array4[1].includes(".value='")) {
              const array5 = array4[1].split(".value='");
              if (array5) {
                const variable = array5[0];
                const array6 = array5[1].split("'");
                if (document.dados[variable] != undefined) {
                  document.dados[variable].value = array6[0];
                } else {
                  for (let i = 0; i < document.dados.length; i++) {
                    if (document.dados[i].name === buttonName) {
                      document.dados[i].value = array6[0];
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export const handleButtonFormdata = (operation) => {
  const pairs = [];
  if (operation) {
    if (operation.includes(buttonResult)) {
      const array = operation.split(buttonResult);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          pairs.push({
            formkey: "buttonResult",
            value: array2[0],
          });
        }
      }
    }
    if (operation.includes(botao)) {
      const array = operation.split(botao);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          pairs.push({
            formkey: "botao",
            value: array2[0],
          });
        }
      }
    }

    if (operation.includes(botao_accao)) {
      const array = operation.split(botao_accao);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          pairs.push({
            formkey: "botao_accao",
            value: array2[0],
          });
        }
      }
    }

    if (operation.includes(op)) {
      const array = operation.split(op);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          pairs.push({
            formkey: "op",
            value: array2[0],
          });
        }
      }
    }

    if (operation.includes(op_) && !operation.includes(op)) {
      const array = operation.split(op_);
      if (array) {
        const array2 = array[1].split(";");
        if (array2) {
          pairs.push({
            formkey: "op",
            value: array2[0],
          });
        }
      }
    }

    if (operation.includes(button_clicked_id)) {
      const array = operation.split(button_clicked_id);
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          pairs.push({
            formkey: "_button_clicked_id",
            value: array2[0],
          });
        }
      }
    }

    /*custom buttons*/
    if (operation.includes("document.dados.")) {
      const array = operation.split("document.dados.");
      if (array) {
        if (
          (array[1].includes("value=") || array[1].includes('value="')) &&
          !array[1].includes("value='") &&
          !array[1].includes('value="')
        ) {
          array[1] = array[1].replace("value=", "value='");
          array[1] = array[1].replace('value="', "value='");
        }

        const array2 = array[1].split(".value='");
        if (array2) {
          const variable = array2[0];
          const array3 = array2[1].split("'");

          if (!pairs.some((p) => p.formkey === variable)) {
            devLogConsole(variable);
            pairs.push({
              formkey: variable,
              value: array3[0],
            });
          }

          //check for 1 more
          const part2 = operation.split(`document.dados.${variable}`);
          if (part2[1].includes("document.dados.")) {
            const array4 = part2[1].split("document.dados.");
            if (array4) {
              const array5 = array4[1].split(".value='");
              if (array5) {
                const variable = array5[0];
                const array6 = array5[1].split("'");

                if (!pairs.some((p) => p.formkey === variable)) {
                  devLogConsole(variable);
                  pairs.push({
                    formkey: variable,
                    value: array6[0],
                  });
                }
              }
            }
          }
        }
      }
    }

    return pairs;
  }
};
