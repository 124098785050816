import React, { useState, useEffect } from "react";

import Checkbox from "~/pages/Easypay/components/Checkbox";

import "../assets/scss/_radioButton.scss";

const CheckboxMultiple = ({
  field,
  defaultChecked,
  variableValue,
  setVariableValue,
  index,
}) => {
  const [checked, setChecked] = useState(
    defaultChecked ? defaultChecked : false
  );

  useEffect(() => {
    let array = variableValue;
    array[index] = checked ? 1 : 0;
    setVariableValue(array);
  }, [checked]);

  return (
    <div className="easypay-checkbox-multiple ">
      <div className="easypay-checkbox-multiple-check">
        <Checkbox
          name={field?.col[0]?.value + "checkbox"}
          id={field?.col[0]?.value + "checkbox"}
          checked={checked}
          setCheckboxChecked={setChecked}
          checkboxChecked={checked}
        />
      </div>
      <div className="easypay-checkbox-multiple-label">{field?.col[0]?.value}</div>
      
    </div>
  );
};

export default CheckboxMultiple;
