import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "~/store/ducks/simulator/actionTypes";
import { DateTime } from "luxon";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "~/components/pagination/currentSizePerPage/10";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  getCollaboratorBoolean,
  deleteUserData,
} from "~/store/ducks/simulator/actionTypes";
import { withNamespaces } from "react-i18next";

const TableList = ({ onGetCollaboratorData, t }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [userId, setUserID] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const { userData } = useSelector((state) => state.simulatorReducer);

  const getRowSavedCollaboratorData = (data) => {
    onGetCollaboratorData(data);
  };

  // formatin date and total amount
  function dateFormater(cell, row) {
    if (row) {
      return DateTime.fromISO(cell).toLocaleString();
    }
  }

  function amountFormater(cell, row) {
    if (row) {
      return formatter.format(cell);
    }
  }

  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      dispatch(getCollaboratorBoolean());
      getRowSavedCollaboratorData(row);
    },
  };

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "dateNow",
      text: t(`simulatorMainPage.table.date`),
      sort: true,
      formatter: dateFormater,
    },
    {
      dataField: "name",
      text: t(`simulatorMainPage.table.name`),
      sort: true,
    },
    {
      dataField: "collaboratorNum",
      text: t(`simulatorMainPage.table.collaboratorNum`),
      sort: true,
    },
    {
      dataField: "collaboratorEntity",
      text: t(`simulatorMainPage.table.entity`),
      sort: true,
    },
    {
      dataField: "totalAmountResult",
      text: t(`simulatorMainPage.table.totalAmount`),
      sort: true,
      formatter: amountFormater,
    },
  ];

  const dateNotFound = t(`simulatorMainPage.table.dateNotFound`);

  const rowStyle = { cursor: "pointer" };

  const handleSelect = (row, isSelect, index) => {
    if (isSelect) {
      setUserID(row.id);
      setIsSelected(true);
    }
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    onSelect: handleSelect,
    hideSelectAll: true,
  };

  const notSelectRow = {
    mode: "radio",
    clickToSelect: false,
    hideSelectColumn: true,
  };

  const handleEditeRow = () => {
    setIsEdit(!isEdit);
    setIsSelected(false);
  };

  const handleDeleteRow = () => {
    dispatch(deleteUserData(userId));
  };

  return (
    <>
      {userData ? (
        <ToolkitProvider
          keyField="id"
          data={userData ? userData : null}
          columns={columns}
          search
        >
          {(props) => (
            <div className="custom-table custom-table-small cursor-pointer">
              <div className="simulator_searchBar">
                <SearchBar {...props.searchProps} />
              </div>

              {userData.length > 0 ? (
                <i
                  className="icon-edit table-action-icon"
                  onClick={handleEditeRow}
                />
              ) : null}

              {isSelected ? (
                <i
                  className="icon-trash table-action-icon"
                  onClick={handleDeleteRow}
                />
              ) : null}
              <div className="simulator_tableList">
                <BootstrapTable
                  {...props.baseProps}
                  pagination={
                    userData.length > 10 ? paginationFactory(pagination) : null
                  }
                  striped
                  hover
                  bootstrap4
                  noDataIndication={dateNotFound}
                  rowStyle={rowStyle}
                  rowEvents={rowEventEditMod}
                  selectRow={isEdit ? selectRow : notSelectRow}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      ) : null}
    </>
  );
};

export default withNamespaces()(TableList);
