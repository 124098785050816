import React from 'react';
import { Col } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const FilterToggle = (props) => {

  const {
    t,
    children,
    eventKey,
    setToggleIcon,
    setActiveKey,
    toggleIcon
  } = props;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setToggleIcon(icon => icon === "icon-down" ? "icon-up" : "icon-down")
    setActiveKey(activeKey => activeKey === null ? "0" : null)
  });
  return (
    <Col lg="12" onClick={decoratedOnClick} style={{ padding: "0px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ fontSize: "1.25rem", fontWeight: "500", marginBottom: "6px" }}>
          {t('general.filters')}&nbsp;
        </span>
        <i className={toggleIcon} style={{ fontSize: "20px" }} />
      </div>
      <hr />
      {children}
    </Col>
  )
};

export default withNamespaces()(FilterToggle);