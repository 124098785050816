import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Simulator from "./Simulators";
import NavTabs from "~/pages/PortalRH/components/NavTabs";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import SideBar from "~/pages/PortalRH/components/RhCollaborator/Sidebar";

const NavBarSimulator = ({ portalRhMenus, designacao, path, t }) => {
  const [menuSideBar, setMenuSideBar] = useState(null);
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, []);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Header className="justify-content-between">
            <h6>
              {t("simulatorMainPage.navBar.simulatorCompensationTermination")}
            </h6>
          </Card.Header>
          <Card.Body>
            <Simulator />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default withNamespaces()(NavBarSimulator);
