import {
  TOAST_ACTIVE_SUCCESS,
  TOAST_ACTIVE_WARNING,
  TOAST_ACTIVE_ERROR,
  TOAST_HIDE_SUCCESS,
  TOAST_HIDE_WARNING,
  TOAST_HIDE_ERROR,
  TOAST_INITIAL_STATE
} from "../actions";

const initialState = {
  activeSuccess: false,
  successMessage: '',
  activeWarning: false,
  warningMessage: '',
  activeError: false,
  errorMessage: ''
}

export const toastsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_ACTIVE_SUCCESS:
      return { ...state, activeSuccess: true, successMessage: action.message };
    case TOAST_ACTIVE_WARNING:
      return { ...state, activeWarning: true, warningMessage: action.message };
    case TOAST_ACTIVE_ERROR:
      return { ...state, activeError: true, errorMessage: action.message };
    case TOAST_HIDE_SUCCESS:
      return { ...state, activeSuccess: false };
    case TOAST_HIDE_WARNING:
      return { ...state, activeWarning: false };
    case TOAST_HIDE_ERROR:
      return { ...state, activeError: false };
    case TOAST_INITIAL_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
