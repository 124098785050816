import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import "../../assets/css/icons.css";
import CustomDropdown from "./CustomDropdown";
import { withNamespaces } from "react-i18next";
import { updateUserWidget } from "~/pages/User/actions";

const ErrorWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("general.underConstruction")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ overflowY: "scroll" }}>
            <p>Não conseguimos obter os dados. Base Widget não existe.</p>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(ErrorWidget);
