import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useSelector, useDispatch } from "react-redux";
import { getColumnFormatter } from "./columnFormatter";
import { Button } from "react-bootstrap";
import AddButton from "~/components/Buttons/AddButton";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import Modal from "~/pages/Easypay/components/Modal";

const IntegrationCollaboratorsTable = ({
  t,
  app,
  collaboratorsData,
  setUsersToConfigure,
  setTextColaboradores,
  handleRefresh,
  filterOption,
}) => {
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  const [flowColumns, setFlowColumns] = useState(undefined);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredCollaborators = collaboratorsData.colaborators
    .map((item) => item.colaborator)
    .filter((collaborator) => {
      if (
        filterOption ===
        t(`budgetManagement.integrationcolaborators.configuredOpt.configurados`)
      ) {
        return collaborator.configuredInBudgetManager === true;
      } else if (
        filterOption ===
        t(
          `budgetManagement.integrationcolaborators.configuredOpt.noConfigurados`
        )
      ) {
        return collaborator.configuredInBudgetManager === false;
      } else {
        return true; // Todos os colaboradores
      }
    });

  useEffect(() => {
    setTextColaboradores(
      t(`budgetManagement.integrationcolaborators.existentes`)
    );
  }, []);

  const handleStatusSort = (field) => {
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setField(field);
  };

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };

  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/pesquisar` || item.route === `${app}/search`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        let flowColumns_;
        let hiddenDefaults_;
        // if (flowId && extraConfiguration_[flowId]) {
        //   flowColumns_ = extraConfiguration_[flowId].columns; //columns for a specific flow
        //   hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default columns for a specific flow
        // } else {
        flowColumns_ = extraConfiguration_.defaultExtraColumns; //extra columns for all flows
        hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default columns for all flows
        //}
        setFlowColumns(flowColumns_ ? flowColumns_ : undefined);
        setHiddenDeafultColumns(hiddenDefaults_ ? hiddenDefaults_ : undefined);
      }
    }
  }, [menu]);
  //  }, [menu, flowId]);

  const handleAddUsers = (row, e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers((prevState) => [...prevState, row]);
    } else {
      setSelectedUsers((prevState) => prevState.filter((user) => user !== row));
    }
  };

  const columns = [
    {
      dataField: "idColaborador",
      text: "ID Colaborador",
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "idColaborador",
      },
      hidden: true,
      csvExport: !isHidden("idColaborador"),
    },
    {
      dataField: "usrNumFuncionario",
      text: t(`budgetManagement.integrationcolaborators.usrNumFunc`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "usrNumFuncionario",
      },
      hidden: isHidden("usrNumFuncionario"),
      csvExport: !isHidden("usrNumFuncionario"),
    },
    {
      dataField: "nome",
      text: t(`budgetManagement.integrationcolaborators.nome`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "nome",
      },
      hidden: isHidden("nome"),
      csvExport: !isHidden("nome"),
    },
    {
      dataField: "codEmpresa",
      text: t(`budgetManagement.integrationcolaborators.codEmpresa`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "codEmpresa",
      },
      hidden: isHidden("codEmpresa"),
      csvExport: !isHidden("codEmpresa"),
    },
    {
      dataField: "isConfiguredInBudgetManager",
      text: t(`budgetManagement.integrationcolaborators.configurado`),
      formatter: (cell, row) => (
        <input
          type="checkbox"
          disabled={
            row.configuredInBudgetManager || row.usrNumFuncionario == ""
          }
          defaultChecked={row.configuredInBudgetManager}
          onChange={(e) => handleAddUsers(row, e)}
        />
      ),
      formatExtraData: {
        value: "isConfiguredInBudgetManager",
      },
      hidden: isHidden("isConfiguredInBudgetManager"),
      csvExport: !isHidden("isConfiguredInBudgetManager"),
    },
  ];

  if (flowColumns) {
    getColumns(flowColumns).forEach((column, index) =>
      columns.splice(1 + index, 0, column)
    );
  }
  const getColumns = (columns) => {
    const getCSVFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (formatExtraData.value === "SLA")
        return getColumnFormatter(cell, row, rowIndex, formatExtraData).replace(
          /,/g,
          ""
        );
      else
        return row[formatExtraData.value] !== null &&
          row[formatExtraData.value] !== undefined
          ? row[formatExtraData.value]
          : "";
    };
    return columns.map((column) => {
      if (column === "PROCESS_STATUS")
        return {
          dataField: "status",
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          headerStyle: () => {
            return { width: "190px" };
          },
          onSort: () => handleStatusSort("PROCESS_STATUS"),
          csvFormatter: (cell, row, rowIndex) => {
            return row.PROCESS_STATUS !== null &&
              row.PROCESS_STATUS !== undefined
              ? row.PROCESS_STATUS
              : "";
          },
        };
      else if (column === "TTL") {
        return {
          dataField: column,
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          style: {
            overflowWrap: "break-word",
          },
          onSort: () => handleStatusSort("created"),
        };
      } else
        return {
          dataField: column,
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          style: {
            overflowWrap: "break-word",
          },
          csvFormatter: getCSVFormatter,
        };
    });
  };

  const configureNewUsers = () => {
    if (selectedUsers.length > 0) {
      setUsersToConfigure({});
      const selectedRows = selectedUsers.map((user) => ({
        usrNumFuncionario: user?.usrNumFuncionario,
        nome: user?.nome,
        codEmpresa: user?.codEmpresa,
        isConfiguredInBudgetManager: user?.isConfiguredInBudgetManager,
      }));
      setUsersToConfigure(selectedRows); // Aqui você pode fazer o que quiser com os usuários selecionados
    } else {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 2000);
    }
  };

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.integrationcolaborators.selectUser`)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { SearchBar } = Search;
  return (
    <>
      <Modal showModal={isModalOpen} bodyContent={createBody()} />
      <div className="custom-table">
        <ToolkitProvider
          keyField="nome"
          //keyField="usrNumFuncionario"
          data={filteredCollaborators}
          columns={columns}
          search
          exportCSV={{
            fileName: "process.csv",
            separator: ";",
            noAutoBOM: true,
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SearchBar
                  {...props.searchProps}
                  style={{ paddingLeft: "20px" }}
                  placeholder={t("general.search")}
                />
                <Button
                  style={{ marginBottom: "8px" }}
                  className="card-button"
                  onClick={() => handleRefresh()}
                >
                  {t(`budgetManagement.integrationcolaborators.btnUpdate`)}
                </Button>
              </div>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("taskPage.general.processesNotFound")}
                sort={{
                  dataField: "usrNumFuncionario",
                  order: "asc",
                }}
                striped
                hover
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ProtectedComponent
          permissions={[{ label: "app-budgetmgr-collaborators-create" }]}
        >
          <AddButton
            text={t(`budgetManagement.integrationcolaborators.addNew`)}
            onClick={() => configureNewUsers()}
          />
        </ProtectedComponent>
      </div>
    </>
  );
};

export default withNamespaces()(IntegrationCollaboratorsTable);
