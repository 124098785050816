import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { withNamespaces } from "react-i18next";
import {
  getUOSiadapraDropdown,
  getUOSiadapraDetailOrg,
} from "~/store/ducks/siadapra/actionTypes";
import { useHistory } from "react-router-dom";

const OrganizationDetail = (props) => {
  const { id, dropVal } = props.match.params;
  const history = useHistory();
  const dispatch = useDispatch();
  const { unidadesOrganicasDropdown, unidadesOrganicas } = useSelector(
    (state) => state.siadapraReducer
  );
  const [dropdownData, setDropdownData] = useState([]);
  //   const [dropdownValue, setDropdownValue] = useState(null);
  const [tableData, setTableData] = useState([]);
  const { t } = props;
  useEffect(() => {
    const payload = { sisId: dropVal, perId: id };
    dispatch(getUOSiadapraDetailOrg(payload));
  }, []);
  useEffect(() => {
    if (unidadesOrganicas.length > 0) {
      setTableData(unidadesOrganicas);
    } else {
      setTableData([]);
    }
  }, [unidadesOrganicas]);

  const options =
    dropdownData.length > 0 &&
    dropdownData.map((option) => {
      return {
        value: option.descricao,
        label: option.descricao,
        selected: false,
      };
    });

  //   const handleSubmit = (value) => {
  //     const matchingItem = dropdownData.find((item) => item.descricao === value);
  //     if (matchingItem) {
  //       const id = matchingItem.id;
  //       setDropdownValue(id);
  //     }
  //   };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "objetivo",
      text: "Objetivo",
      sort: true,
    },
    {
      dataField: "superado",
      text: "Superado",
      sort: true,
    },
    {
      dataField: "atingido",
      text: "Atingido",
      sort: true,
    },
    {
      dataField: "naoAtingido",
      text: "Não Atingido",
      sort: true,
    },
  ];

  const backToList = () => {
    history.push("/uniksystem/portalrh/siadapra/organizacao");
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between">
            <h6>Serviços da Administração Pública</h6>
          </Card.Header>
          <Card.Body>
            {/* <Row>
              <Col>
                {options.length > 0 && (
                  <Dropdown
                    list={options}
                    handleSubmit={handleSubmit}
                    emptyDefault={true}
                  />
                )}
              </Col>
            </Row> */}
            {/* {dropdownValue !== null && ( */}
            <Row>
              <div className={"scrollBar-visibleModal"}>
                <ToolkitProvider
                  keyField="id"
                  data={tableData}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        noDataIndication={t("portalrh.personalData.noRequests")}
                        striped
                        hover
                        classes="table-desempenho"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Row>
            {/* )} */}
          </Card.Body>
          <Card.Footer>
            <Button variant="danger" onClick={() => backToList()}>
              {t("general.back")}
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default withNamespaces()(OrganizationDetail);
