import { setEasypayFormdata, setEasypayOnboardingPid } from "./actionTypes";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import he from "he";
import { activeError } from "~/components/Messages/actions";
import { IFLOW_APP } from "~/utils/constants";
const devLogConsole = require("~/utils/devLog");
//iFlow/inicio_flow.jsp?
export function* getEasypayFormdata({ data }) {
  try {
    const resp = yield http.get(`${data.url}`, IFLOW_APP);

    // const resp = yield axios.get(`http://localhost/iFlow/Form/form.jsp?flowid=311&pid=7749&subpid=1`,IFLOW_APP);

    let arr = resp.data.split(`{"form" :`);
    try {
      yield put(
        setEasypayOnboardingPid(
          resp.data.split("&pid=")[1].split("&subpid=")[0]
        )
      );
      let obj = JSON.parse(`{"form" :` + arr[1]);
      yield put(setEasypayFormdata(obj));
    } catch (error) {
      yield put(activeError("messages.error"));
      yield put(setEasypayFormdata(null));
    }
  } catch (e) {
    yield put(setEasypayFormdata(null));
  }
}

//iFlow/Form/form.jsp?
export function* postEasypayFormData({ formdata, url }) {
  for (var pair of formdata.entries()) {
    //devLogConsole(pair[0] + ": " + pair[1]);
  }

  try {
    const resp = yield http.post(`${url}`, formdata, IFLOW_APP);
    if (
      resp.data.includes(`<div style="font-family: Verdana,Arial,sans-serif;">`)
    ) {
      let arr1 = resp.data.split(
        `<div style="font-family: Verdana,Arial,sans-serif;">`
      );
      let arr2 = arr1[1].split("</table></div>");
      yield put(setEasypayFormdata({ responseMessage: he.decode(arr2[0]) }));
    } else if (resp.data.includes(`<div class="alert alert-info">`)) {
      let arr1 = resp.data.split(`<div class="alert alert-info">`);
      let arr2 = arr1[1].split("</div>");
      yield put(setEasypayFormdata({ responseMessage: he.decode(arr2[0]) }));
    } else if (resp.data.includes(`{"form" :`)) {
      let arr = resp.data.split(`{"form" :`);
      try {
        let obj = JSON.parse(`{"form" :` + arr[1]);
        yield put(setEasypayFormdata(obj));
      } catch (error) {
        yield put(activeError(error));
        yield put(setEasypayFormdata(null));
      }
    } else if (resp.data.includes(`<div class="error_msg">`)) {
      let arr1 = resp.data.split(`<div class="error_msg">`);
      let arr2 = arr1[1].split("</div>");
      yield put(activeError(he.decode(arr2[0])));
      yield put(setEasypayFormdata(null));
    } else {
      yield put(setEasypayFormdata(null));
    }
  } catch (e) {
    yield put(setEasypayFormdata(null));
  }
}
