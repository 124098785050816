import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { transformFormData } from "../utils";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";

import SearchableDropDown from "~/components/SearchableDropDown";
import oauth from "~/utils/oauth";

import { useLabelWidth } from "../utils/useLabelWidth";
import { useLabelFormated } from "../utils/useLabelFormated";
import { setFocusElement } from "~/store/ducks/iflow/actionTypes";

const CreateSearchableDropdown = (props) => {
  const {
    field,
    children,
    justifyContent,
    styleSheet,
    isChild,
    fontSize,
    translations,
    language,
    maxWidth,
  } = props;
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [required, setRequired] = useState(false);

  const { labelWidthClass, labelWidth } = useLabelWidth(field);
  const { labelFormated } = useLabelFormated(field, translations);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (field) {
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
      if (field.option) {
        if (!Array.isArray(field.option)) {
          field.option = [field.option];
        }
        const optionList = field.option.map((option) => ({
          label: labelOptinText(option.text),
          value: option.value,
          selected: option.selected === "yes" ? true : false,
        }));

        if (optionList) {
          if (
            !optionList.some((op) => op.selected === true) &&
            optionList.length > 0
          ) {
            //set first element as default
            optionList[0].selected = true;
          }
          setList(optionList);
        }
      }
    }
  }, [field]);

  const labelOptinText = (text) => {
    if (!text?.includes("::t::")) {
      return text?.trim();
    }

    const lingua = language.trim();
    const splittext = text.replace(/'/g, "").trim().split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal?.trim();
  };

  const handleSelectChange = (value) => {
    if (field.onchange_submit !== "") {
      if (field.onchange_submit.includes("document.dados.op.value='")) {
        const array = field.onchange_submit.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (value && field.variable) {
        document.dados[field.variable].value = value;
      }
      if (field.onchange_submit.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
        dispatch(setFocusElement(field.variable));
      }
    }
  };

  return (
    <>
      <Form.Group
        controlId={`formSelect-${field.variable}`}
        as={Row}
        style={{
          flexWrap: isMobile ? "wrap" : "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          marginBottom: styleSheet?.compact ? "5px" : isMobile ? "30px" : "10px",
          flexGrow: "1",
          width: "100%",
        }}
      >
        <div
          style={{
            paddingRight: "15px",
            paddingLeft: "0px",
            display: "flex",
            alignItems: "baseline",
            paddingLeft: "0px",
            maxWidth: isMobile ? "" : maxWidth ? maxWidth : "fit-content",
          }}
        >
          <Form.Label
            column
            className={`${labelWidthClass ? labelWidthClass : "iflow-form-label-fix"} ${field.text !== "-" ? "borderOnNotDisabledFields" : ""}`}
            style={
              labelWidth
                ? {
                    width: labelWidth,
                    minWidth: labelWidth,
                    flexBasis: labelWidth,
                  }
                : {}
            }
          >
            {labelFormated
              ? labelFormated
              : field.text && field.text.b
              ? field.text.b.split("::")[0]
              : field.text.split("::")[0]}
          </Form.Label>
        </div>
        <Col
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            flexGrow: "2",
            flexBasis: "content",
          }}
        >
          <div
            style={{
              display: isMobile ? "block" : "flex",
              flexDirection: "row",
              width: children ? "auto" : "100%",
              justifyContent: justifyContent ? justifyContent : "",
            }}
          >
            <div>
              <SearchableDropDown
                name={field.variable}
                list={list}
                required={required}
                handleSubmit={(value) => handleSelectChange(value)}
                exitOnClose={true}
                styleSheet={styleSheet}
                sort={
                  field.text && field.text.b
                    ? field?.text?.b?.includes("::noOrder")
                      ? "noOrder"
                      : ""
                    : field?.text?.includes("::noOrder")
                    ? "noOrder"
                    : ""
                }
                fontSize={fontSize}
                nomargin={true}
              />
            </div>

            {children && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  flexGrow: "1",
                  flexBasis: "0",
                }}
              >
                {children}
              </div>
            )}
          </div>
        </Col>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && !styleSheet?.ultraCompact && (
        <hr />
      )}
    </>
  );
};
export default CreateSearchableDropdown;
