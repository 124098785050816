import React from "react";
import logo from "~/assets/img/logos/novobanco_logo.png";

const LoginLogoNB = () => {
  return (
    <div className="row">
      <div className="col-12 mt-3 mb-2 text-center">
        <img className="img-fluid unik-logo" src={logo} alt="Novobanco" />
      </div>
    </div>
  );
};

export default LoginLogoNB;
