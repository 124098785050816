import { takeEvery, takeLatest } from "redux-saga/effects";

import {
    GET_LIST_SIADAPRA_UO,
    GET_LIST_SIADAPRA_UO_DETAIL,
    GET_LIST_SIADAPRA_UO_DROPDOWN,
    GET_LIST_SIADAPRA_UO_ORG_DETAIL
} from "../ducks/siadapra/actionTypes";

import {
    getUOSiadapra,
    getUOSiadapraDropdown,
    getUOSiadapraDetail,
    getUOSiadapraDetailOrg
} from "../ducks/siadapra/sagas";

export const siadapraSagas = [
  takeLatest(GET_LIST_SIADAPRA_UO, getUOSiadapra),
  takeLatest(GET_LIST_SIADAPRA_UO_DROPDOWN, getUOSiadapraDropdown),
  takeLatest(GET_LIST_SIADAPRA_UO_DETAIL, getUOSiadapraDetail),
  takeLatest(GET_LIST_SIADAPRA_UO_ORG_DETAIL, getUOSiadapraDetailOrg),
];
