import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import { some } from "lodash";
import iconError from "~/pages/Easypay/assets/img/icons/icon-error-filled.png";
import iconWaiting from "~/pages/Easypay/assets/img/icons/icon-waiting.png";
import RadioButtonItem from "~/pages/Easypay/components/RadioButtonItem";
import BenefModalRadioButtonWrapper from "~/pages/Easypay/components/BenefModalRadioButtonWrapper";
import waitingIcon from "../assets/img/icons/icon-waiting.png";
import ButtonUpload from "~/pages/Easypay/components/ButtonUpload";
import Text from "./Text";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import "../assets/css/styles.css";
import LineBreak from "~/pages/Easypay/components/LineBreak";
import uuid from "uuid/v1";
import "../assets/scss/_backofficeRegistrySection.scss";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Modal from "~/pages/Easypay/components/Modal";
import Button from "./Button";
import oauth from "~/utils/oauth";
import { transformFormData } from "~/components/CreateForm/utils";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import { findDocument, setDocument } from "store/ducks/iflow/actionTypes";
import { getDocumentByPid } from "~/store/ducks/onboarding/actionTypes";
import {
  getUser,
  updateUser,
  getDocumentCoresystem,
  setDocumentCoresystem,
  createDocumentCoresystem,
  addDocumentCoresystem,
  setCreateDocumentCoresystem,
  sendEmailEndPoint,
  setDocumentPdaInfo,
} from "~/store/ducks/onboarding/actionTypes";
import ProvaDeVida from "./ProvaDeVida";

import {
  getIflowFormdata,
  setIflowTriggerLoading,
} from "~/store/ducks/processes/actionTypes";

const BackOfficeSignaturesSection = ({ data, pid, t }) => {
  const inputFile = useRef(null);
  var badge = "@";
  var badgeRepleg = "@";

  const testIndex = useRef(null);
  const dispatch = useDispatch();

  const [currentSignatureType, setCurrentSignatureType] = useState(
    t(`bpm.onboarding.backoffice.user.labelCMD`)
  );

  const { user, documentPdaInfo } = useSelector(
    (state) => state.onboardingReducer
  );

  const { currentDocument } = useSelector((state) => state.iflowReducer);

  const { documentCoresystem, createDocumentCoresystemId } = useSelector(
    (state) => state.onboardingReducer
  );
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  function repeat() {
    var arr = [];
    for (var i = 0; i < signaturesList.length; i++) {
      arr.push("");
    }
    return arr;
  }

  const [docIndex, setDocIndex] = useState();
  const [modalIsPdaIsShowing, setModalPdaIsShowing] = useState(false);
  const [modalVerifyIsShowing, setModalVerifyIsShowing] = useState(false);

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Tipo de documento não suportado" status="error" />
      </div>
    );
  }

  function sendPda() {

    if (user?.client) {
      user.client.pdaOk = "1";
      dispatch(updateUser({ user: user, pid: user?.account?.pid }));
    }
    setModalPdaIsShowing(false);
  }

  function createBodyPda() {
    return (
      <div
        className="lift-modal-body-wrapper"
        style={{
          minWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Text text={"Prentende enviar pda para o cliente ?::card-text-title"} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            minWidth: "100%",
          }}
        >
          <SimpleButton
            text={"Sim"}
            variant={"easypay-button-primary easypay-success"}
            onClick={() => sendPda()}
          />
          <SimpleButton
            text={"Não"}
            variant={"easypay-button-primary easypay-error"}
            onClick={() => setModalPdaIsShowing(false)}
          />
        </div>
      </div>
    );
  }

  var signaturesList = [
    {
      name: t("bpm.onboarding.backoffice.user.labelPropostaAdesao"),
      repLegArray: ["Carlos Manuel", "João Silva"],
      document: {},
      mode: "",
    },
    {
      name: t("bpm.onboarding.backoffice.user.labelPropostaAlteracao"),
      repLegArray: ["Francisco Monteiro", "Maria Silva"],
      document: {},
      mode: "",
    },
  ];

  useEffect(() => {
    if (createDocumentCoresystemId != null && file != null) {
      const type = file.type ? file.type : "NOT SUPPORTED";

      data.documents[testIndex.current].createdDate = new Date();
      data.documents[testIndex.current].modifiedDate = new Date();
      data.documents[testIndex.current].nomeDocumento = file.name;
      data.documents[testIndex.current].idCoresystem =
        createDocumentCoresystemId.id;
      data.documents[testIndex.current].content = fileContent;
      data.documents[testIndex.current].submited = "1";
      data.documents[testIndex.current].mimeType = file.name;
      data.documents[testIndex.current].emitted = file.name;
      data.documents[testIndex.current].expired = file.name;

      if (
        currentSignatureType === t(`bpm.onboarding.backoffice.user.labelCMD`)
      ) {
        data.documents[testIndex.current].descDocumento = "CMD::2";
      } else {
        data.documents[testIndex.current].descDocumento = "AM::1";
      }
      dispatch(
        addDocumentCoresystem(
          {
            id: createDocumentCoresystemId.id,
            fileName: file.name,
            applicationType: type,
            content: fileContent,
          },
          "61798db3a0ac1d6257017e87"
        )
      );
      dispatch(setCreateDocumentCoresystem(null));
      dispatch(updateUser({ user: data, pid: pid }));
    }
  }, [createDocumentCoresystemId, file]);

  useEffect(() => {
    if (data === undefined || data === null) return;
    var newDocList = data?.documents?.filter((doc) => doc.isPDA === 1);

    if (newDocList === undefined) return;

    if (newDocList.length === 0) {
      setMainSignaturesArray(signaturesList);
      return;
    }

    if (newDocList.length > 2) {
      var newArray = [];
      newArray.push(newDocList[0]);
      newArray.push(newDocList[newDocList.length - 1]);
      newDocList = newArray;
      signaturesList[0].document = newDocList[0];
      signaturesList[0].mode = newDocList[0].descDocumento.split("::")[0];
      signaturesList[1].document = newDocList[1];
      signaturesList[1].mode = newDocList[1].descDocumento.split("::")[0];
    } else if (newDocList.length === 1) {
      signaturesList[0].document = newDocList[0];
      signaturesList[0].mode = newDocList[0].descDocumento.split("::")[0];
    } else {
      signaturesList[0].document = newDocList[0];
      signaturesList[0].mode = newDocList[0].descDocumento.split("::")[0];
      signaturesList[1].document = newDocList[1];
      signaturesList[1].mode = newDocList[1].descDocumento.split("::")[0];
    }
    setMainSignaturesArray(signaturesList);
  }, [user]);

  const { iflowTriggerLoading, formdata } = useSelector(
    (state) => state.processesReducer
  );

  useEffect(() => {
    dispatch(
      getDocumentByPid({
        pid: data?.account?.pid,
      })
    );
    dispatch(setIflowTriggerLoading(false));
  }, [formdata]);

  useEffect(() => {
    if (currentDocument && currentDocument.filename) {
      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${currentDocument.datadoc}`;
      a.download = currentDocument.filename;
      a.click();
      dispatch(setDocumentPdaInfo({}));
      dispatch(setDocument({}));
    }
  }, [currentDocument]);

  useEffect(() => {
    if (documentPdaInfo?.pdaLink)
      dispatch(findDocument(documentPdaInfo.pdaLink));
  }, [documentPdaInfo]);

  const emptyArray = repeat();
  const [documentsCMDNameList, setDocumentsCMDNameList] = useState(emptyArray);
  const [fileContent, setFileContent] = useState(null);
  const [documentsAMNameList, setDocumentsAMNameList] = useState(emptyArray);
  const [file, setFile] = useState(null);
  const [mainSignaturesArray, setMainSignaturesArray] = useState(emptyArray);

  function onButtonClick(id) {
    // `current` points to the mounted file input element
    var currentInd = -1;
    for (var i = 0; i < data?.documents?.length; i++) {
      if (data?.documents[i]?.id === id) {
        currentInd = i;
        break;
      }
    }

    testIndex.current = currentInd;
    inputFile.current.click();
  }

  function getBase64(file, index) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setFileContent(base64String);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var currentFile;
    var newArray;
    currentFile = event.target.files[0];

    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";

    if (
      !(
        type.includes("pdf") ||
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      )
    ) {
      setIsModalErrorShowing(true);
      return;
    }

    setFile(event.target.files[0]);
    if (
      currentSignatureType ===
      t(`bpm.onboarding.backoffice.user.labelManualSignature`)
    ) {
      newArray = [...documentsAMNameList];
      newArray[tabSelected] = currentFile.name;
      setDocumentsAMNameList(newArray);
    } else {
      newArray = [...documentsCMDNameList];
      newArray[tabSelected] = currentFile.name;
      setDocumentsCMDNameList(newArray);
    }

    const emitted_at = currentFile.lastModifiedDate;
    const expires_at = new Date(
      new Date(currentFile.lastModifiedDate).setFullYear(
        new Date().getFullYear() + 1
      )
    );

    const extension = "." + currentFile.name.split(".")[1];
    var newExpires = expires_at.toISOString().split(".")[0] + "Z";
    var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";
    dispatch(
      createDocumentCoresystem(
        {
          topology: "identification_card",
          is_private: true,
          metadata: `{\n  \"topology\": \"identification_card\",\n  \"is_private\": true,\n  \"metadata\": {},\n  \"emitted_at\": \"${newEmitted}\",\n  \"expires_at\": \"${newExpires}\",\n  \"mime_type\": \"${type}\",\n  \"extension\": \"${extension}\"\n}`,
          emitted_at: newEmitted,
          expires_at: newExpires,
          mime_type: type,
          extension: extension,
        },
        "61798db3a0ac1d6257017e87"
      )
    );

    getBase64(currentFile);
  };

  const [tabSelected, setTabSelected] = useState(-1);

  function nextChar(c) {
    return (badge = String.fromCharCode(c.charCodeAt(0) + 1));
  }

  function verifySteps() {

    var bankData = false;
    var uploadDoc = true;
    var repSigned = false;
    var benEf = true;
    var finalize = false;
    var accountApproved = false;
    var docAproved = true;
    var counter = 0;
    var numReps = 1;

    if (data?.client?.iban != "" && data?.client?.iban != null) {
      bankData = true;
    }
    for (var i = 0; i < data?.documents?.length; i++) {
      if (data.documents[i].isPDA != 1) {
        if (
          data.documents[i].nomeDocumento === "" &&
          data.documents[i].nomeDocumento === null
        ) {
          uploadDoc = false;
          break;
        }
      }
    }

    if (
      data?.onboardingEntitiy?.formaObrigar.includes("1") ||
      data?.onboardingEntitiy?.formaObrigar.includes("um") ||
      data?.onboardingEntitiy?.formaObrigar.includes("one")
    ) {
      numReps = 1;
    } else if (
      data?.onboardingEntitiy?.formaObrigar.includes("2") ||
      data?.onboardingEntitiy?.formaObrigar.includes("dois") ||
      data?.onboardingEntitiy?.formaObrigar.includes("two")
    ) {
      numReps = 2;
    } else if (
      data?.onboardingEntitiy?.formaObrigar.includes("3") ||
      data?.onboardingEntitiy?.formaObrigar.includes("três") ||
      data?.onboardingEntitiy?.formaObrigar.includes("three")
    ) {
      numReps = 3;
    }

    for (var i = 0; i < data?.legalRepresentation?.length; i++) {
      if (data?.legalRepresentation[i].pvEstado === 1) {
        counter++;
      }
    }

    if (counter === numReps) {
      repSigned = true;
    }

    for (var i = 0; i < data?.effectiveBeneficiary?.length; i++) {
      if (data?.effectiveBeneficiary[i].nif != "") {
        if (
          data?.effectiveBeneficiary[i].nif.charAt(0) === "1" ||
          data?.effectiveBeneficiary[i].nif.charAt(0) === "2" ||
          data?.effectiveBeneficiary[i].nif.charAt(0) === "3"
        )
          if (
            data?.effectiveBeneficiary[i].numDocumento === "" ||
            data?.effectiveBeneficiary[i].numDocumento === null
          ) {
            benEf = false;
            break;
          }
      }
    }

    for (var i = 0; i < data?.documents?.length; i++) {
      if (data.documents[i].isPDA != 1) {
        if (data.documents[i].estadoDocumento != 1) {
          docAproved = false;
          break;
        }
      }
    }

    if (data?.account?.aprovado === 1) {
      accountApproved = true;
    }
  }

  function createBodyVerify() {
    var bankData = false;
    var uploadDoc = true;
    var repSigned = false;
    var benEf = true;
    var finalize = false;
    var accountApproved = false;
    var docAproved = true;
    var counter = 0;
    var numReps = 1;

    if (data?.client?.iban != "" && data?.client?.iban != null) {
      bankData = true;
    }
    for (var i = 0; i < data?.documents?.length; i++) {
      if (data.documents[i].isPDA != 1) {

        if (
          data.documents[i].nomeDocumento === "" ||
          data.documents[i].nomeDocumento === null
        ) {
          uploadDoc = false;
          break;
        }
      }
    }

    if (
      data?.onboardingEntitiy?.formaObrigar.includes("1") ||
      data?.onboardingEntitiy?.formaObrigar.includes("um") ||
      data?.onboardingEntitiy?.formaObrigar.includes("one")
    ) {
      numReps = 1;
    } else if (
      data?.onboardingEntitiy?.formaObrigar.includes("2") ||
      data?.onboardingEntitiy?.formaObrigar.includes("dois") ||
      data?.onboardingEntitiy?.formaObrigar.includes("two")
    ) {
      numReps = 2;
    } else if (
      data?.onboardingEntitiy?.formaObrigar.includes("3") ||
      data?.onboardingEntitiy?.formaObrigar.includes("três") ||
      data?.onboardingEntitiy?.formaObrigar.includes("three")
    ) {
      numReps = 3;
    }

    for (var i = 0; i < data?.legalRepresentation?.length; i++) {
      if (data?.legalRepresentation[i].pvEstado === 1) {
        counter++;
      }
    }

    if (counter === numReps) {
      repSigned = true;
    }

    for (var i = 0; i < data?.effectiveBeneficiary?.length; i++) {
      if (data?.effectiveBeneficiary[i].nif != "") {
        if (
          data?.effectiveBeneficiary[i].nif.charAt(0) === "1" ||
          data?.effectiveBeneficiary[i].nif.charAt(0) === "2" ||
          data?.effectiveBeneficiary[i].nif.charAt(0) === "3"
        )
          if (
            data?.effectiveBeneficiary[i].numDocumento === "" ||
            data?.effectiveBeneficiary[i].numDocumento === null
          ) {
            benEf = false;
            break;
          }
      }
    }

    for (var i = 0; i < data?.documents?.length; i++) {
      if (data.documents[i].isPDA != 1) {
        if (data.documents[i].estadoDocumento != 1) {
          docAproved = false;
          break;
        }
      }
    }

    if (data?.account?.aprovado === 1) {
      accountApproved = true;
    }

    return (
      <div
        className="lift-modal-body-wrapper"
        style={{ minWidth: "400px", display: "flex", flexDirection: "column" }}
      >
        <Text text={"Backoffice Cliente::card-text"} />
        {bankData ? (
          <Text
            text={"Preenchimento de dados bancários::card-text-green"}
            align="left"
          />
        ) : (
          <Text
            text={"Preenchimento de dados bancários::card-text-error"}
            align="left"
          />
        )}
        {uploadDoc ? (
          <Text
            text={"Upload de todos os documentos solicitados::card-text-green"}
            align="left"
          />
        ) : (
          <Text
            text={"Upload de todos os documentos solicitados::card-text-error"}
            align="left"
          />
        )}
        {repSigned ? (
          <Text
            text={
              "Confirmação de assinatura e provas de vida dos representantes legais::card-text-green"
            }
            align="left"
          />
        ) : (
          <Text
            text={
              "Confirmação de assinatura e provas de vida dos representantes legais::card-text-error"
            }
            align="left"
          />
        )}
        {benEf ? (
          <Text
            text={
              "Preenchimento de todos os dados dos Beneficiários Efetivos::card-text-green"
            }
            align="left"
          />
        ) : (
          <Text
            text={
              "Preenchimento de todos os dados dos Beneficiários Efetivos::card-text-error"
            }
            align="left"
          />
        )}
        {finalize ? (
          <Text text={"Finalizar::card-text-green"} align="left" />
        ) : (
          <Text text={"Finalizar::card-text-error"} align="left" />
        )}
        <Text text={"Backoffice Easypay::card-text"} />
        {accountApproved ? (
          <Text
            text={"Aprovar a conta::card-text-green"}
            align="left"
          />
        ) : (
          <Text
            text={"Aprovar a conta::card-text-error"}
            align="left"
          />
        )}
        {docAproved ? (
          <Text
            text={"Aprovar documentação::card-text-green"}
            align="left"
          />
        ) : (
          <Text
            text={"Aprovar documentação::card-text-error"}
            align="left"
          />
        )}
      </div>
    );
  }

  function handleHeaderOnClick(index) {
    if (tabSelected === index) {
      setTabSelected(-1);
    } else if (tabSelected != index) {
      setTabSelected(index);
      if (mainSignaturesArray[index].mode != "") {
        if (mainSignaturesArray[index].mode === "CMD") {
          setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelCMD`));
        } else if (mainSignaturesArray[index].mode === "AM") {
          setCurrentSignatureType(
            t(`bpm.onboarding.backoffice.user.labelManualSignature`)
          );
        }
      } else {
        setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelCMD`));
      }
    }
  }
  function nextCharRepleg(c) {
    return (badgeRepleg = String.fromCharCode(c.charCodeAt(0) + 1));
  }

  function isVisible(index) {
    if (index === 1) {
      if (
        mainSignaturesArray[0]?.document?.nomeDocumento != "" &&
        mainSignaturesArray[0]?.document?.nomeDocumento != null &&
        mainSignaturesArray[0]?.document?.nomeDocumento != undefined
      ) {
        return true;
      } else return false;
    }
    return true;
  }

  function approveReproveDocument(operation) {
    if (operation) {
      data.documents[docIndex].estadoDocumento = 1;
    } else {
      data.documents[docIndex].nomeDocumento = "";
      data.documents[docIndex].descDocumento = "";
    }

    for (var i = 0; i < data.documents.length; i++) {
      if (data.documents[i].estadoDocumento === -1) {
        data.client.hasDocRejected = 1;
        break;
      }
    }
    setIsModalShowing(false);
    dispatch(updateUser({ user: data, pid: pid }));
  }

  function downloadFile(index) {

    dispatch(
      getDocumentCoresystem(
        data?.documents[index].idCoresystem,
        "61798db3a0ac1d6257017e87"
      )
    );
  }

  useEffect(() => {
    if (documentCoresystem != null && documentCoresystem != undefined) {

      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${documentCoresystem}`;
      a.download = "test.pdf";
      a.click();

      dispatch(setDocumentCoresystem(null));
    }
  }, [documentCoresystem]);

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant={"easypay-button-primary easypay-success"}
            text={t(`bpm.onboarding.backoffice.administration.buttonApprove`)}
            onclick={() => {
              approveReproveDocument(true);
            }}
          />
          <Button
            variant={"easypay-button-primary easypay-error"}
            text={t(`bpm.onboarding.backoffice.administration.buttonFail`)}
            onclick={() => {
              approveReproveDocument(false);
            }}
          />
        </div>
      </div>
    );
  }

  function remindEmail() {
    const payload = {
      template: "ep-subscription-sign-reminder",
      data: `{\"type\":\"email\",\"emails\":[\"${data.client.email}\"],\"subject\":\"Teste email\",\"extra\":{\"name\":[\"${data.client.nome}\"],\"link\":\"https://www.easypay.pt/en/homepage-en/\"},\"metadata\":{\"user_id\":4657,\"name\":\"2021\"}}`,
    };
    dispatch(sendEmailEndPoint(payload, "61798db3a0ac1d6257017e87"));
  }

  function setDocState(index) {

    if (
      data.documents[index].nomeDocumento === "" ||
      data.documents[index].nomeDocumento === null ||
      data.documents[index].nomeDocumento === undefined
    )
      return;
    setDocIndex(index);
    setIsModalShowing(true);
  }

  function downloadPda(index) {
    dispatch(setDocumentPdaInfo({}));

    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=" +
        "9" +
        "&attr_pidConta=" +
        data?.account?.pid +
        "&attr_idioma=" +
        "pt" +
        "&attr_isPDA=" +
        1 +
        "&attr_Authorization=" +
        oauth.getAccessToken() +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    dispatch(getIflowFormdata(payload));
  }

  function disableButtons() {
    if (user) {
      for (var i = 0; i < user?.documents?.length; i++) {
        if (
          user?.documents[i]?.estadoDocumento != 1 &&
          user?.documents[i]?.isPDA != 1
        ) {
          return true;
        }
      }
      if (user?.client?.pdaOk === "1") {
        return true;
      } else {
        return false;
      }
    }
  }

  function disableGeraPda() {
    if (user) {
      for (var i = 0; i < user?.documents?.length; i++) {
        if (
          user?.documents[i]?.estadoDocumento != 1 &&
          user?.documents[i]?.isPDA != 1
        ) {
          return true;
        }
      }
      if (user?.onboardingEntitiy?.tipoEntidade === "eni") {
        for (var i = 0; i < user?.legalRepresentation?.length; i++) {
          if (user?.legalRepresentation[i]?.pvEstado === 1) {
            return false;
          }
        }
        return true;
      } else {
        var numSig = 1;
        var counterSig = 0;
        if (
          user?.onboardingEntity?.formaObrigar?.includes("1") ||
          user?.onboardingEntity?.formaObrigar?.includes("um") ||
          user?.onboardingEntity?.formaObrigar?.includes("Um")
        ) {
          numSig = 1;
        } else if (
          user?.onboardingEntity?.formaObrigar?.includes("2") ||
          user?.onboardingEntity?.formaObrigar?.includes("dois") ||
          user?.onboardingEntity?.formaObrigar?.includes("Dois")
        ) {
          numSig = 2;
        } else if (
          user?.onboardingEntity?.formaObrigar?.includes("3") ||
          user?.onboardingEntity?.formaObrigar?.includes("três") ||
          user?.onboardingEntity?.formaObrigar?.includes("Três")
        ) {
          numSig = 3;
        }

        for (var i = 0; i < user?.legalRepresentation?.length; i++) {
          if (user?.legalRepresentation[i]?.pvEstado === 1) {
            counterSig++;
          }
        }

        if (counterSig === numSig) return false;

        return true;
      }
    }
  }

  return (
    <div className="easypay-backoffice-sections-main-wrapper">
      <Modal
        className="easypay-modal"
        showModal={modalVerifyIsShowing}
        setShowModal={setModalVerifyIsShowing}
        headerContent={"Estado do processo"}
        bodyContent={createBodyVerify()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={modalIsPdaIsShowing}
        setShowModal={setModalPdaIsShowing}
        headerContent={"Pretende aprovar ou reprovar o documento ?"}
        bodyContent={createBodyPda()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalShowing}
        setShowModal={setIsModalShowing}
        headerContent={"Pretende aprovar ou reprovar o documento ?"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorShowing}
        setShowModal={setIsModalErrorShowing}
        headerContent={"Submissão de Documento"}
        bodyContent={createBodyError()}
        key={uuid()}
      ></Modal>
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.labelSignatures`)}
      </div>
      <>
        {mainSignaturesArray &&
          Object.keys(mainSignaturesArray).length !== 0 &&
          mainSignaturesArray.map((item, index) => {
            return (
              <>
                {isVisible(index) && (
                  <div className="easypay-accordion-backoffice" key={index}>
                    <div
                      className="easypay-accordion-header-backoffice"
                      onClick={() => {
                        handleHeaderOnClick(index);
                      }}
                    >
                      <div className="easypay-accordion-top-content-container">
                        <div className="easypay-id">{nextChar(badge)}</div>
                        <div className="easypay-title">{item.name}</div>
                      </div>
                      <div className="easypay-image-container">
                        {false === "true" && (
                          <img
                            className="easypay-accordion-checkmark-img"
                            src={greenCheckmark}
                          />
                        )}
                        <img
                          className={
                            tabSelected === index
                              ? `${"easypay-accordion-btn-img"} active`
                              : "easypay-accordion-btn-img"
                          }
                          src={
                            tabSelected === index ? arrowUpBlue : arrowDownGrey
                          }
                        />
                      </div>
                    </div>
                    {tabSelected === index && (
                      <div>
                        <div className="easypay-accordion-content-card-backoffice">
                          <Text
                            text={
                              t(
                                `bpm.onboarding.backoffice.user.labelSignaturesMethod`
                              ) + "::card-text"
                            }
                          />
                          <div className="easypay-accordion-radio-button-wrapper">
                            <BenefModalRadioButtonWrapper
                              key={uuid()}
                              activeTab={currentSignatureType}
                              setActivetab={setCurrentSignatureType}
                              variant={"easypay-radio-button-row"}
                            >
                              {/*<RadioButtonItem
                                key={uuid()}
                                text={t(
                                  `bpm.onboarding.backoffice.user.labelSMS`
                                )}
                                uniqueName={"signatureType" + index}
                                index={0}
                                name={t(
                                  `bpm.onboarding.backoffice.user.labelSMS`
                                )}
                                subHeader={""}
                                radioButtonLength={3}
                                disabled={true}
                                ></RadioButtonItem>*/}
                              <RadioButtonItem
                                key={uuid()}
                                text={t(
                                  `bpm.onboarding.backoffice.user.labelCMD`
                                )}
                                uniqueName={"signatureType" + index}
                                index={1}
                                name={t(
                                  `bpm.onboarding.backoffice.user.labelCMD`
                                )}
                                radioButtonLength={3}
                                subHeader={""}
                                disabled={item.mode != "0" ? true : false}
                              ></RadioButtonItem>
                              <RadioButtonItem
                                key={uuid()}
                                text={t(
                                  `bpm.onboarding.backoffice.user.labelManualSignature`
                                )}
                                index={2}
                                uniqueName={"signatureType" + index}
                                name={t(
                                  `bpm.onboarding.backoffice.user.labelManualSignature`
                                )}
                                subHeader={""}
                                disabled={item.mode != "0" ? true : false}
                                radioButtonLength={3}
                              ></RadioButtonItem>
                            </BenefModalRadioButtonWrapper>
                          </div>
                          <div>
                            {currentSignatureType ===
                            t(`bpm.onboarding.backoffice.user.labelSMS`) ? (
                              <div>
                                <div className="easypay-backoffice-text-button-wrapper">
                                  <div className="easypay-backoffice-signatures-text-wrapper">
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                        ) + "::card-text-blue"
                                      }
                                    />
                                    <Text
                                      text={
                                        "Informação sobre o documento::card-text-light"
                                      }
                                    />
                                  </div>
                                  <div className="easypay-document-submitted-wrapper">
                                    <div className="easypay-text-icon">
                                      <img
                                        src={iconDocument}
                                        alt="Icon Check"
                                      />
                                    </div>
                                    <Text
                                      text={"PDA12345678.pdf::card-text-blue"}
                                    />
                                  </div>
                                </div>
                                {item.repLegArray &&
                                  Object.keys(item.repLegArray).length !== 0 &&
                                  item.repLegArray.map((repleg, index) => {
                                    return (
                                      <div>
                                        <div
                                          className={
                                            index ===
                                            item.repLegArray.length - 1
                                              ? "easypay-signatures-wrapper"
                                              : "easypay-signatures-wrapper-no-padding"
                                          }
                                        >
                                          <div className="easypay-signatures-name-wrapper">
                                            <div className="easypay-bullet-number-with-padding">
                                              {nextCharRepleg(badgeRepleg)}
                                            </div>
                                            <Text
                                              text={
                                                repleg +
                                                "::card-text-no-padding"
                                              }
                                            />
                                          </div>
                                          <div className="easypay-signatures-name-wrapper">
                                            <div className="easypay-text-icon-warning">
                                              <img
                                                className="easypay-accordion-warning-img"
                                                src={waitingIcon}
                                              />
                                            </div>
                                            <Text
                                              text={
                                                t(
                                                  `bpm.onboarding.backoffice.user.labelPendingSignature`
                                                ) +
                                                "::card-text-warning-no-padding"
                                              }
                                            />
                                          </div>
                                        </div>
                                        {index !=
                                          item.repLegArray.length - 1 && (
                                          <LineBreak text={"none"} />
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : currentSignatureType ===
                              t(`bpm.onboarding.backoffice.user.labelCMD`) ? (
                              <div>
                                <div className="easypay-backoffice-text-button-wrapper">
                                  <div style={{ display: "flex" }}>
                                    <div className="easypay-backoffice-signatures-text-wrapper">
                                      <Text
                                        text={
                                          t(
                                            `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                          ) + "::card-text-blue"
                                        }
                                      />
                                      <Text
                                        text={
                                          "Informação sobre o documento::card-text-light"
                                        }
                                      />
                                    </div>
                                    {(item?.document === {} ||
                                      item.document?.nomeDocumento ===
                                        undefined ||
                                      item.document?.nomeDocumento === null ||
                                      item.document?.nomeDocumento === "") && (
                                      <div
                                        style={{
                                          paddingLeft: "15px",
                                          paddingTop: "5px",
                                        }}
                                      >
                                        <Button
                                          variant={"easypay-button-link"}
                                          text={"Relembrar"}
                                          onclick={() => {
                                            remindEmail();
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {item?.document === {} ||
                                  item.document?.nomeDocumento === undefined ||
                                  item.document?.nomeDocumento === null ||
                                  item.document?.nomeDocumento === "" ? (
                                    <ButtonUpload
                                      text={t(
                                        `bpm.onboarding.backoffice.user.buttonUploadDocument`
                                      )}
                                      variant={"easypay-button-secondary-icon"}
                                      onclick={() =>
                                        onButtonClick(item.document?.id)
                                      }
                                      onChange={handleChange}
                                      key={uuid()}
                                      inputFile={inputFile}
                                      id={"id1"}
                                      className="card-button"
                                      align={"right"}
                                      disabledColored={false}
                                      disabled={false}
                                    />
                                  ) : (
                                    <div>
                                      <div
                                        className="easypay-document-submitted-wrapper"
                                        onClick={() => {
                                          downloadFile(index);
                                          setDocIndex(index);
                                        }}
                                      >
                                        <div className="easypay-text-icon">
                                          <img
                                            src={iconDocument}
                                            alt="Icon Check"
                                          />
                                        </div>
                                        <Text
                                          text={
                                            item.document?.nomeDocumento +
                                            "::card-text-blue"
                                          }
                                        />
                                      </div>
                                      {
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            className="easypay-text-icon-check-documents"
                                            onClick={() => {
                                              if (
                                                !some(user.roles, {
                                                  label: "ONBOARDINGComercial",
                                                }) &&
                                                item.document
                                                  ?.estadoDocumento === 0
                                              ) {
                                                setDocState(index);
                                              }
                                            }}
                                          >
                                            <img
                                              src={
                                                item.document
                                                  ?.estadoDocumento === 0
                                                  ? iconWaiting
                                                  : item.document
                                                      ?.estadoDocumento === 1
                                                  ? greenCheckmark
                                                  : iconError
                                              }
                                              alt="Icon Check"
                                            />
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="easypay-backoffice-text-button-wrapper">
                                  <div style={{ display: "flex" }}>
                                    <div className="easypay-backoffice-signatures-text-wrapper">
                                      <Text
                                        text={
                                          t(
                                            `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                          ) + "::card-text-blue"
                                        }
                                      />
                                      <Text
                                        text={
                                          "Informação sobre o documento::card-text-light"
                                        }
                                      />
                                    </div>
                                    {(item?.document === {} ||
                                      item.document?.nomeDocumento ===
                                        undefined ||
                                      item.document?.nomeDocumento === null ||
                                      item.document?.nomeDocumento === "") && (
                                      <div
                                        style={{
                                          paddingLeft: "15px",
                                          paddingTop: "5px",
                                        }}
                                      >
                                        <Button
                                          variant={"easypay-button-link"}
                                          text={"Relembrar"}
                                          onclick={() => {
                                            remindEmail();
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {item?.document === {} ||
                                  item.document?.nomeDocumento === undefined ||
                                  item.document?.nomeDocumento === null ||
                                  item.document?.nomeDocumento === "" ? (
                                    <ButtonUpload
                                      text={t(
                                        `bpm.onboarding.backoffice.user.buttonUploadDocument`
                                      )}
                                      variant={"easypay-button-secondary-icon"}
                                      onclick={() =>
                                        onButtonClick(item.document?.id)
                                      }
                                      onChange={handleChange}
                                      key={uuid()}
                                      inputFile={inputFile}
                                      id={"id1"}
                                      className="card-button"
                                      align={"right"}
                                      disabledColored={false}
                                      disabled={false}
                                    />
                                  ) : (
                                    <div>
                                      <div
                                        className="easypay-document-submitted-wrapper"
                                        onClick={() => {
                                          downloadFile(index);
                                          setDocIndex(index);
                                        }}
                                      >
                                        <div className="easypay-text-icon">
                                          <img
                                            src={iconDocument}
                                            alt="Icon Check"
                                          />
                                        </div>
                                        <Text
                                          text={
                                            item.document?.nomeDocumento +
                                            "::card-text-blue"
                                          }
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div
                                          className="easypay-text-icon-check-documents"
                                          onClick={() => {
                                            if (
                                              !some(user.roles, {
                                                label: "ONBOARDINGComercial",
                                              })
                                            ) {
                                              setDocState(index);
                                            }
                                          }}
                                        >
                                          <img
                                            src={iconWaiting}
                                            alt="Icon Check"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
      </>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", paddingTop: "26px" }}>
          <SimpleButton
            text={"Gerar PDA"}
            variant={"easypay-button-primary"}
            onClick={() => downloadPda()}
            disabled={disableGeraPda()}
          />
        </div>
        <div
          style={{ display: "flex", paddingTop: "26px", paddingLeft: "15px" }}
        >
          <SimpleButton
            text={"Verificar processo"}
            variant={"easypay-button-primary"}
            onClick={() => setModalVerifyIsShowing(true)}
          />
        </div>
        <div
          style={{ display: "flex", paddingTop: "26px", paddingLeft: "15px" }}
        >
          <SimpleButton
            text={"Enviar para cliente"}
            variant={"easypay-button-primary"}
            onClick={() => setModalPdaIsShowing(true)}
            disabled={disableButtons()}
          />
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficeSignaturesSection);
