import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import { findUnikUserSettings } from "~/pages/User/actions";
import EnabledContainer from "./EnabledContainer";

const PersistUserEnabled = ({ username, enabled, setEnabled, enabledUser, t }) => {
  const dispatch = useDispatch(); 
  useEffect(() => {
    if (username) dispatch(findUnikUserSettings(username));
  }, [username]);

  return <EnabledContainer enabled={enabled} setEnabled={setEnabled} enabledUser={enabledUser} t={t} />;
};
export default withNamespaces()(PersistUserEnabled);
