import React from "react";
import Dropdown from "~/components/Dropdown";
import { Form, Row, Badge } from "react-bootstrap";

const StatisticsGroupFilters = ({ executeGraph, renderGroups }) => {

  return (
    <Form.Row style={{ marginTop: "0" }}>
      <Form.Label style={{ marginRight: "10px" }}>Grupos</Form.Label>
      <Dropdown
        list={renderGroups()}
        handleSubmit={executeGraph}
        emptyDefault={true}
      />
    </Form.Row>
  );
};

export default StatisticsGroupFilters;
