import React, { useRef, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "chart.js";
import { barChart } from "./chartDetail.js";
import CustomDropdown from "../CustomDropdown";
import ReportFilters from "../../ReportFilters";
import { withNamespaces } from "react-i18next";
import { findReportProcessesStatus } from "~/store/ducks/iflowReports/actionTypes";
import { cloneDeep } from "lodash";
import "~/assets/css/icons.css";
import { updateUserWidget } from "~/pages/User/actions";

const ProcessesStatusWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const widgetId = widget.id;

  const getReportProcessesStatus = (state) => {
    const reports = state.iflowReportsReducer.reportProcessesStatus;
    let report = [];
    const savedReport = reports.find((r) => r.id === widgetId);
    if (savedReport) report = savedReport.report;
    return report;
  };
  const reportProcessesStatus = useSelector(getReportProcessesStatus);

  const [barChart_, setBarChart] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [totalPid, setTotalPid] = useState(null);

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "12",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    if (showSpinner) {
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showSpinner]);

  useEffect(() => {
    if (isUpdating) {
      setShowSpinner(true);
    }
  }, [isUpdating]);

  useEffect(() => {
    const reportProcessesStatus_ = reportProcessesStatus.filter(
      (item) =>
        item.process_status !== null &&
        item.process_status !== "" &&
        item.process_status !== "null"
    );
    if (reportProcessesStatus_ && reportProcessesStatus_.length > 0) {
      let chart = cloneDeep(barChart); //clone object, each widget instance needs its own chart object
      const reportProcessesStatus_ = reportProcessesStatus.filter(
        (item) =>
          item.process_status !== null &&
          item.process_status !== "" &&
          item.process_status !== "null"
      );
      const statusNames = [];
      const dataset = [];
      let total = 0;
      reportProcessesStatus_.forEach((item) => {
        dataset.push(item.total_pid);
        try {
          const number = Number.parseInt(item.total_pid);
          total += number;
        } catch {
          //TODO
        }
      });
      if (total > 0) {
        setTotalPid(total);
        //calculate %
        reportProcessesStatus_.forEach((item) => {
          const persent = ((item.total_pid / total) * 100).toFixed(0);
          statusNames.push(`${item.process_status}(${persent}%)`);
        });
      } else {
        reportProcessesStatus_.forEach((item) => {
          statusNames.push(item.process_status);
        });
      }
      chart.data.labels = statusNames ? statusNames : [];
      chart.data.datasets[0].data = dataset ? dataset : [];
      setBarChart(chart);
      setIsUpdating(false);
    } else {
      setIsUpdating(false);
      setBarChart(null);
    }
  }, [reportProcessesStatus]);

  useEffect(() => {
    if (barChart_ && !isUpdating) {
      try {
        const myChartRef = myRef.current.getContext("2d");
        new Chart(myChartRef, barChart_);
      } catch {
        //TODO
      }
    }
  }, [barChart_, isUpdating]);

  const executeGraph = (startAt, endAt, flowId) => {
    const data = {
      payload: {
        flowId: flowId === "" ? null : flowId,
        startDate: startAt,
        endDate: endAt,
      },
      widgetId: widget.id,
    };
    dispatch(findReportProcessesStatus(data));
    setIsUpdating(true);
    setTotalPid(null);
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat" style={{ height: "25rem" }}>
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("ProcessesStatusWidget.title")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ padding: "0px 10px" }}>
            <Row style={{ flexWrap: "nowrap", height: "95%" }}>
              <Col
                xs={2}
                md={2}
                lg={2}
                style={{ margin: "0 20px 10px 20px", padding: "10px" }}
              >
                <ReportFilters executeGraph={executeGraph} widget={widget} />
              </Col>
              <Col
                style={{
                  padding: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {isUpdating && showSpinner && (
                  <Spinner animation="grow" variant="secondary" />
                )}
                {!isUpdating &&
                  reportProcessesStatus.length <= 0 &&
                  t("general.dataNotFound")}
                {reportProcessesStatus &&
                  reportProcessesStatus.length > 0 &&
                  !isUpdating && (
                    <>
                      <div style={{ height: "100%", width: "auto" }}>
                        <canvas
                          id="mybarChart"
                          ref={myRef}
                          width={800}
                          height={280}
                        ></canvas>
                      </div>
                      {totalPid && (
                        <div
                          style={{ padding: "20px 10px", fontWeight: "700" }}
                        >
                          Total {totalPid}
                        </div>
                      )}
                    </>
                  )}
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(ProcessesStatusWidget);
