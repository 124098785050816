import { GET_GRAPH_INFO_BY_SELECTED_ITEM, SET_GRAPH_INFO } from "./actionTypes";

const initialState = {
  isLoadingGraphInfo: false,
  graphInfo: {},
};

export function graphsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GRAPH_INFO_BY_SELECTED_ITEM:
      return {
        ...state,
        isLoadingGraphInfo: true,
      };
    case SET_GRAPH_INFO:
      return {
        ...state,
        isLoadingGraphInfo: false,
        graphInfo: action.graphInfo,
      };
    default:
      return state;
  }
}
