import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import logo from "~/assets/img/logos/unik-logo.png";
import { PERMISSION_ABOUT } from "~/utils/constants/permissions";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ReactMarkdown from 'react-markdown';

const AboutComponent = (props) => {
  const { t } = props;

  const [markdownContent, setMarkdownContent] = useState('');

  const dispatch = useDispatch();
  const AboutRow = ({ children }) => (
    <p style={{ margin: "0px", padding: "0px" }}>{children}</p>
  );

  const { loadingAboutInfo, aboutinfo } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );
  useEffect(() => {
    dispatch(actions.getAboutInfo());
  }, []);

  useEffect(() => {
    if (document.getElementById("globalsolver"))
      document.getElementById("globalsolver").style.display = "none";
  });

  useEffect(() => {
    if(Object.keys(aboutinfo).length === 0) return;
    function base64DecodeUtf8(str) {
      return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }
    
    const decodedContent = base64DecodeUtf8(aboutinfo.releaseNotes);
    setMarkdownContent(decodedContent);
  }, [aboutinfo]);

  return (
    <ProtectedComponent permissions={[{ label: PERMISSION_ABOUT }]}>
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingAboutInfo}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-card-v2">
          <Card
            bsPrefix="card-flat"
            style={{ padding: "50px", overflow: "hidden" }}
          >
            <Card.Body>
              <Row>
                <div className="col-6" style={{ alignContent: "center" }}>
                  <img className="img-fluid" src={logo} alt="UDW" />
                  <Col style={{ marginTop: "1rem" }}>
                    <b style={{ fontSize: "smaller" }}>RELEASE NOTES:</b>{" "}
                    {Object.keys(aboutinfo).length > 0 ? (
                      <div
                        style={{
                          padding: "10px",
                          background: "whitesmoke",
                          borderRadius: "11px",
                        }}
                        className="scrollableAbout"
                      >
                        <ReactMarkdown>{markdownContent}</ReactMarkdown>
                        {/* <TextField
                          multiline
                          rows={10}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          defaultValue={aboutinfo.releaseNotes
                            .replace(/\\t/g, "\t")
                            .replace(/\\n/g, "\n")}
                        /> */}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </Col>
                </div>
                <div className="col-5">
                  <Col style={{ marginBottom: "2rem" }}>
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "large" }}
                    >
                      Unik Platform
                    </span>
                  </Col>
                  <Col>
                    {" "}
                    {Object.keys(aboutinfo).length > 0 && (
                      <>
                        <AboutRow>
                          <b style={{ fontSize: "smaller" }}>FRONTEND:</b>{" "}
                          {process.env.REACT_APP_VERSION}
                        </AboutRow>
                        {aboutinfo.components.map((component, index) => (
                          <AboutRow key={index}>
                            <b
                              style={{
                                fontSize: "smaller",
                                color: !component.version ? "red" : "inherit",
                              }}
                            >
                              {component.appName.toUpperCase()}:
                            </b>{" "}
                            {component.version}
                          </AboutRow>
                        ))}
                      </>
                    )}
                  </Col>
                  <Col style={{ marginTop: "2rem" }}>
                    <AboutRow>
                      <a href="https://www.uniksystem.com" target="_blank">
                        Copyright ©{new Date().getFullYear()} Uniksystem.{" "}
                        {t("about.rights")}{" "}
                      </a>
                    </AboutRow>
                  </Col>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </>
    </ProtectedComponent>
  );
};

export default withNamespaces()(AboutComponent);
