import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import Chatbot from "./Chatbot";
import { Card } from "react-bootstrap";
import OpenAI from "openai";
import { v4 as uuidv4 } from "uuid";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  postOpenAiChat,
  getOpenAiChat,
  setOpenAiChat,
  getOpenAiChatsList,
  setOpenAiChatList,
} from "~/store/ducks/openai/actionTypes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ChatBotContainer = ({
  className,
  styleSheet,
  translations,
  t,
  ...props
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  const { openAiChat, openAiChatList } = useSelector(
    (state) => state.openaiReducer
  );
  const { openAIConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  const openai = new OpenAI({
    apiKey:
      Object.keys(openAIConfiguration).length > 0 &&
      openAIConfiguration.chatApiKey
        ? openAIConfiguration.chatApiKey
        : "",
    dangerouslyAllowBrowser: true,
  });
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [getNewChat, setGetNewChat] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [apiKeyNotFound, setApiKeyNotFound] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  useEffect(() => {
    dispatch(getOpenAiChatsList(user.id));
  }, []);

  useEffect(() => {
    if (Object.keys(openAIConfiguration).length > 0) {
      setOpen(false);
      setApiKeyNotFound(false);
    } else {
      setOpen(true);
      setApiKeyNotFound(true);
    }
  }, [openAIConfiguration]);

  useEffect(() => {
    if (apiKeyNotFound) {
      setTimeout(() => {
        setOpen(false);
        setAlertModal(true);
      }, 5000);
    }
  }, [apiKeyNotFound]);

  useEffect(() => {
    if (Object.keys(openAiChatList).length > 0) {
      setNewChat(false);
      setChats(openAiChatList);
      dispatch(setOpenAiChatList({}));
    }
  }, [openAiChatList]);

  useEffect(() => {
    const currentChat = chats.find((chat) => chat.id === currentChatId);
    if (newChat && currentChat) {
      dispatch(postOpenAiChat(currentChat));
    } else if (!newChat && currentChat && getNewChat) {
      dispatch(getOpenAiChat(currentChat.id));
    }
  }, [chats, currentChatId, newChat]);

  useEffect(() => {
    if (Object.keys(openAiChat).length > 0) {
      setGetNewChat(false);
      setNewChat(false);
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.id === openAiChat.id ? { ...openAiChat } : chat
        )
      );
      setMessages(openAiChat.messages || []);
      dispatch(setOpenAiChat({}));
    }
  }, [openAiChat]);

  const fetchChatCompletion = async (
    messages,
    userMessage,
    model,
    temperature
  ) => {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${openAIConfiguration.chatApiKey}`,
      },
      body: JSON.stringify({
        model,
        messages: [...messages, { role: "user", content: userMessage }],
        temperature,
      }),
    });
  
    if (!response.ok) {
      throw new Error(
        "Oops! Something went wrong while processing your request."
      );
    }
  
    return response.json();
  };
  
  const updateChatMessages = (responseData, userMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "assistant",
        content: responseData.choices[0].message.content,
      },
    ]);
  };
  
  const createNewChat = async (responseData, userMessage, usage) => {
    const summaryData = await fetchChatCompletion(
      [
        { role: "user", content: userMessage },
        { role: "assistant", content: responseData.choices[0].message.content },
        {
          role: "system",
          content:
            "Quero um titulo para esta conversa, que esteja resumido no maximo em 2 palavras",
        },
      ],
      userMessage,
      "gpt-4o",
      0.5
    );
  
    const chatId = uuidv4();
    setChats((prevChats) => [
      ...prevChats,
      {
        id: chatId,
        userId: user.id,
        role: "system",
        content: summaryData.choices[0].message.content,
        date: new Date().toISOString(),
        messages: [
          { role: "user", content: userMessage },
          {
            role: "assistant",
            content: responseData.choices[0].message.content,
          },
        ],
        usage,
        apiKey:
          Object.keys(openAIConfiguration).length > 0 &&
          openAIConfiguration.chatApiKey
            ? openAIConfiguration.chatApiKey
            : "",
      },
    ]);
    setCurrentChatId(chatId);
  };
  
  const updateExistingChat = (responseData, userMessage, usage) => {
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === currentChatId
          ? {
              ...chat,
              userId: user.id,
              date: new Date().toISOString(),
              messages: [
                ...chat.messages,
                { role: "user", content: userMessage },
                {
                  role: "assistant",
                  content: responseData.choices[0].message.content,
                },
              ],
              usage,
            }
          : chat
      )
    );
    setCurrentChatId(currentChatId);
  };
  
  const chatData = async (userMessage) => {
    try {
      setIsTyping(true);
      const responseData = await fetchChatCompletion(
        messages,
        userMessage,
        "gpt-4o",
        0.5
      );
      const usage = JSON.stringify(responseData.usage);
  
      updateChatMessages(responseData, userMessage);
  
      if (
        !messages ||
        (messages.length === 0 && responseData.choices[0].message !== null)
      ) {
        await createNewChat(responseData, userMessage, usage);
      } else {
        updateExistingChat(responseData, userMessage, usage);
      }
  
      setIsTyping(false);
    } catch (error) {
      console.error("Error while fetching chat data:", error);
      setIsTyping(false);
    }
  };

  const newBot = async () => {
    setNewChat(true);

    try {
      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: "You are a helpful assistant." }],
        model: "gpt-4o",
      });

      const newChatId = completion.id; // Usa o ID fornecido pela resposta do GPT-4o
      const newChat = {
        id: newChatId,
        messages: [
          {
            role: "system",
            content: completion.choices[0].message.content,
          },
        ],
      };

      setCurrentChatId(newChatId);
      setMessages([]);
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertModal(false);
  };

  return (
    <div
      className={className ? `${className} scrollBar-visible` : "main-card-v2"}
      style={{ overflowY: "auto", maxHeight: "100vh" }}
    >
      <Card bsPrefix="card-flat" style={{ overflowY: "visible" }}>
        <div className="custom-title">Uniksytem AI Assistant</div>
        <Card.Body>
          {apiKeyNotFound ? (
            <>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Snackbar
                open={alertModal}
                autoHideDuration={5000}
                onClose={handleCloseAlert}
              >
                <Alert
                  onClose={handleCloseAlert}
                  severity={"error"}
                  sx={{ width: "100%" }}
                >
                  {t("openai.errorReadingAPIKEY")}
                </Alert>
              </Snackbar>
            </>
          ) : (
            <Chatbot
              isTyping={isTyping}
              setIsTyping={setIsTyping}
              messages={messages}
              setMessages={setMessages}
              chatData={chatData}
              newBot={newBot}
              chats={chats}
              setChats={setChats}
              currentChatId={currentChatId}
              setCurrentChatId={setCurrentChatId}
              setNewChat={setNewChat}
              setGetNewChat={setGetNewChat}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(ChatBotContainer);
