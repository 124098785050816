import React from "react";
import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import ButtonAdd from "~/components/Buttons/AdicionarBtn";

const EmailTemplateTable = (props) => {
  const {
    openCreateModal,
    openUpdateModal,
    openDeleteModal,
    emailTemplates,
    isLoadingEmailTemplates,
    t,
  } = props;

  //table and toolkit creation
  const buildActionsColumn = (_, row, {}) => (
    <>
      <i
        className="icon-trash table-action-icon"
        onClick={() => openDeleteModal(row)}
      />
      <i
        className="icon-edit table-action-icon"
        onClick={() => openUpdateModal(row)}
      />
    </>
  );
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: t("portalrh.emailTemplate.id"),
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "operation",
      text: t("portalrh.emailTemplate.operation"),
      sort: true,
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "docType",
      text: t("portalrh.emailTemplate.docType"),
      sort: true,
      headerStyle: () => {
        return { width: "35%" };
      },
    },
    {
      dataField: "subject",
      text: t("portalrh.emailTemplate.subject"),
      sort: true,
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "accao",
      text: t("admin.userManagement.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  //result
  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={emailTemplates}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <div style={{ paddingBottom: "20px", display: "flex" }}>
              <SearchBar {...props.searchProps} placeholder={t("Search")} />
              <div style={{ paddingLeft: "30px" }}>
                <ButtonAdd text={t("general.add")} onClick={openCreateModal} />
              </div>
            </div>
            {isLoadingEmailTemplates ? (
              <Spinner spinning={isLoadingEmailTemplates} />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("No team requests available")}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};
export default withNamespaces()(EmailTemplateTable);
