import React, { useState, useEffect } from "react";
import Dropdown from "~/components/Dropdown";
import { Form } from "react-bootstrap";
const devLogConsole = require("~/utils/devLog");
const CreateTableSelectInput = (props) => {
  const { field } = props;
  const [list, setList] = useState([]);
  const [dropdownValue, setDropdownValue] = useState("");


  useEffect(() => {
    if (field) {
      if (field.option) {
        if (!Array.isArray(field.option)) {
          field.option = [field.option];
        }
        const optionList = field.option.map((option) => ({
          label: option.text,
          value: option.value,
          selected: field.value === option.value ? true : false,
        }));

        if (optionList) {
          setList(optionList);
          const selectedOption = optionList.find((option) => option.selected);
          if (selectedOption) {
            setDropdownValue(selectedOption.value);
          }
        }
      }
    }
  }, [field]);

  const handleSelectChange = (selected) => {
    setDropdownValue(selected)
  };

  devLogConsole(dropdownValue)

  var varaibleName = field.variable.split("::");

  return (
    <Form.Group
      controlId={`formText-${varaibleName[0]}`}
      key={field.variable}
      style={{ marginBottom: "0px" }}
    >
      {field.readonly === "true" ? (
        <div></div>
      ) : (
        <Dropdown
          name={varaibleName[0]}
          hideIcon
          showTooltip
          className="create-bootstraptable-select-input-fix"
          list={list}
          required={false}
          order={varaibleName.length == 1 ? true : varaibleName[1] == "noorder"}
          handleSubmit={(value) => handleSelectChange(value)}
          noBorderRadius
          flowId={dropdownValue}
        />
      )}
    </Form.Group>
  );
};

export default CreateTableSelectInput;
