export const PERSIST_BUDGETMGR_CONFIGURATION =
  "budgetmgrConfiguration@PERSIST_BUDGETMGR_CONFIGURATION";
export const GET_BUDGETMGR_CONFIGURATION =
  "budgetmgrConfiguration@GET_BUDGETMGR_CONFIGURATION";
export const SET_BUDGETMGR_CONFIGURATION =
  "budgetmgrConfiguration@SET_BUDGETMGR_CONFIGURATION";
export const DELETE_BUDGETMGR_CONFIGURATION =
  "budgetmgrConfiguration@DELETE_BUDGETMGR_CONFIGURATION";
export const CLEAR_BUDGETMGR_CONFIGURATION =
  "budgetmgrConfiguration@CLEAR_BUDGETMGR_CONFIGURATION";
export const SUCCESS_PERSIST_BUDGETMGR_CONFIGURATION =
  "budgetmgrConfiguration@SUCCESS_PERSIST_BUDGETMGR_CONFIGURATION";

export const persistBudgetmgrConfiguration = (configuration) => {
  return { type: PERSIST_BUDGETMGR_CONFIGURATION, configuration };
};

export const getBudgetmgrConfiguration = (organizationId) => {
  return { type: GET_BUDGETMGR_CONFIGURATION, organizationId };
};

export const setBudgetmgrConfiguration = (configuration) => {
  return { type: SET_BUDGETMGR_CONFIGURATION, configuration };
};

export const deleteBudgetmgrConfiguration = (configurationId) => {
  return { type: DELETE_BUDGETMGR_CONFIGURATION, configurationId };
};

export const clearBudgetmgrConfiguration = () => {
  return { type: CLEAR_BUDGETMGR_CONFIGURATION };
};

export const sucessesPersistBudgetmgrConfiguration = () => {
  return { type: SUCCESS_PERSIST_BUDGETMGR_CONFIGURATION };
};
