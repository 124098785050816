import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import "../assets/scss/_initialDataSection.scss";
import "../assets/scss/_contentContainer.scss";
import TableInput from "./TableInput";
import CheckboxDropdown from "./TableInputDropdownCheckbox";
import uuid from "uuid/v1";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import Dropdown from "./TableInputDropdown";

const CompanyDataAccordion = ({ items, workplanIndex }) => {
  const dispatch = useDispatch();

  const { workplans, clientDirectors, teams } = useSelector(
    (state) => state.budgetManagementReducer
  );
  const [filteredNewclientDirectors, setfilteredNewclientDirectors] = useState(
    []
  );
  const [filteredNewTeams, setfilteredNewTeams] = useState(teams);

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  useEffect(() => {
    if (workplans[workplanIndex]?.isNew) {
      setfilteredNewclientDirectors(
        clientDirectors.filter((item) => item.estado === 1)
      );
      //setfilteredNewTeams(teams.filter((item) => item.estado === 1));
    } else {
      setfilteredNewclientDirectors(clientDirectors);
      //setfilteredNewTeams(teams);
    }
  }, [workplans]);

  const [showContent, setShowContent] = useState(true);

  function handleTableChange(e, name) {
    if (name === "clientDirectorProjeto") {
      for (var o in e.list) {
        if (e.list[o] === e.selected.value && e.remove == true) {
          e.list.splice(o, 1);
        }
      }
      const clientDirector = filteredNewclientDirectors.find(
        (x) => x.nome === e.selected.value
      );
      var sortedTeams = filteredNewTeams;
      sortedTeams.sort((a, b) =>
        a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0
      );
      for (var i = 0; i < sortedTeams.length; i++) {
        if (sortedTeams[i].idEquipa === clientDirector.idEquipa) {
          const currentEquipa = sortedTeams.find(
            (x) => x.idEquipa === clientDirector.idEquipa
          );
          if (
            !workplans[workplanIndex].equipa.includes(currentEquipa.nome) &&
            !e.remove
          ) {
            workplans[workplanIndex].equipa[i] = currentEquipa.nome;
            workplans[workplanIndex].equipaId[i] = currentEquipa.idEquipa;
          }
        } else {
          if (
            workplans[workplanIndex].equipa[i] === undefined ||
            workplans[workplanIndex].equipaId[i] === undefined
          ) {
            workplans[workplanIndex].equipa[i] = "";
            workplans[workplanIndex].equipaId[i] = 0;
          }
        }
      }
      var newArray = [];
      for (var i = 0; i < e.list.length; i++) {
        if (e.list[i] === "") {
          newArray.push(0);
        } else {
          newArray.push(getIdFromName(e.list[i], "clientDirector"));
        }
      }
      workplans[workplanIndex].clientDirectorProjetoId = newArray;
      workplans[workplanIndex][name] = e.list;

      setChangeTeam(!changeTeam);
      setCurrentEquipa(workplans[workplanIndex].equipa);
    } else {
      var newArray = [];
      for (var i = 0; i < e.list.length; i++) {
        if (e.list[i] === "") {
          newArray.push(0);
        } else {
          newArray.push(getIdFromName(e.list[i], "equipa"));
        }
      }
      workplans[workplanIndex].equipaId = newArray;
      workplans[workplanIndex][name] = e.list;
    }
    dispatch(setAllWorkplans(workplans));
  }
  const clientTypeList = [
    { label: "Cliente Atual", value: "Cliente Atual", selected: false },
    { label: "Novo Cliente", value: "Novo Cliente", selected: false },
  ];


  function buildNameArray(info) {
    if (info != null) {
      var newArray = [];
      for (var i = 0; i < info.length; i++) {
        newArray.push({
          label: info[i].nome,
          value: info[i].nome,
          selected: false,
        });
      }
      return newArray;
    }
  }

  function getIdFromName(e, name) {
    if (
      (name === "clientDirector" || name === "leadComercial") &&
      filteredNewclientDirectors != null
    ) {
      const clientDirector = filteredNewclientDirectors.find(
        (x) => x.nome === e
      );
      if (clientDirector != null) return clientDirector?.idClientDiretor;
    } else if (name === "equipa" && filteredNewTeams != null) {
      const equipa = filteredNewTeams.find((x) => x.nome === e);
      if (equipa != null) return equipa?.idEquipa;
    }
  }

  function convertIdToName(id, name) {
    if (
      (name === "clientDirectors" || name === "leadComercial") &&
      filteredNewclientDirectors != null
    ) {
      const client = filteredNewclientDirectors.find(
        (x) => x.idClientDiretor === parseInt(id)
      );
      if (client != null) return client?.nome;
    }
  }

  function handleNormalTableChange(e, name) {
    workplans[workplanIndex][name] = getIdFromName(e, name).toString();
    dispatch(setAllWorkplans(workplans));
  }

  const [currentEquipa, setCurrentEquipa] = useState(items.equipa);
  const [changeTeam, setChangeTeam] = useState(false);

  return (
    <div className={"liftworld-company-accordion-wrapper"}>
      <Title text={"DADOS DA EMPRESA"} type={"smaller"} rotate={!showContent} />
      <div style={{ paddingTop: 15 }} />
      {showContent && (
        <>
          <div className={"liftworld-single-table"}>
            <div className="liftworld-table-100-wrapper">
              <TableInput
                field={buildInputField("Nome Empresa", items.nomeEmpresa)}
                size={"large"}
                position={"single"}
                key={uuid()}
                workplanIndex={workplanIndex}
                name={"nomeEmpresa"}
                disabled={true}
              />
            </div>
          </div>
          <div className={"liftworld-single-table"}>
            <div className="liftworld-table-100-wrapper">
              <div>
                <div
                  className={`${"liftworld-table-header-element"} ` + "single"}
                >
                  Lead Comercial
                </div>
                <Dropdown
                  text={"PARTNER DO CLIENT"}
                  required={true}
                  list={buildNameArray(filteredNewclientDirectors)}
                  handleSubmit={(e) =>
                    handleNormalTableChange(e, "leadComercial")
                  }
                  initialSelected={convertIdToName(
                    items.leadComercial,
                    "clientDirectors"
                  )}
                  position={"single"}
                  size={"normal"}
                />
              </div>
            </div>
          </div>
          <div className={"liftworld-single-table"}>
            <div className="liftworld-table-100-wrapper">
              <div>
                <div
                  className={`${"liftworld-table-header-element"} ` + "single"}
                >
                  Client Diretor do Projeto
                </div>
                <CheckboxDropdown
                  text={"PARTNER DO CLIENT"}
                  required={true}
                  list={buildNameArray(filteredNewclientDirectors)}
                  handleSubmit={(e) =>
                    handleTableChange(e, "clientDirectorProjeto")
                  }
                  initialSelected={items.clientDirectorProjeto}
                  position={"single"}
                  size={"normal"}
                  defaultSelectedList={items.clientDirectorProjeto}
                />
              </div>
            </div>
          </div>
          <div className={"liftworld-single-table"}>
            <div className="liftworld-table-100-wrapper">
              <div>
                <div
                  className={`${"liftworld-table-header-element"} ` + "single"}
                >
                  Equipa
                </div>
                <CheckboxDropdown
                  text={"PARTNER DO CLIENT"}
                  required={true}
                  list={buildNameArray(filteredNewTeams)}
                  handleSubmit={(e) => handleTableChange(e, "equipa")}
                  initialSelected={items.equipa}
                  position={"single"}
                  size={"normal"}
                  defaultSelectedList={items.equipa}
                  key={changeTeam}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default CompanyDataAccordion;
