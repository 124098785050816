import React from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";

import PermissionBadge from "./PermissionBadge";
import Spinner from "~/components/Spinner";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const PermissionTable = (props) => {
  const { isLoadingPermission } = useSelector(
    (state) => state.permissionReducer
  );

  const { t, permissions = [], action, actionIcon = "icon-add" } = props;

  const buildActionsColumn = (_, row, {}) => (
    <i
      className={`${actionIcon} table-action-icon`}
      onClick={() => action(row)}
    />
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "description",
      text: t("admin.permission.list.description"),
      sort: true,
    },
    {
      dataField: "actions",
      text: t("admin.permission.list.permissions"),
      sort: true,
      formatter: (cell, row,) => (
        <PermissionBadge
          create={row.create}
          read={row.read}
          update={row.update}
          delete_={row.delete}
        />
      ),
    },
    {
      dataField: "accao",
      text: t("admin.role.list.action"),
      formatter: buildActionsColumn,
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  return (
    <div className="custom-table">
      <ToolkitProvider
        keyField="id"
        data={permissions}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <SearchBar
              {...props.searchProps}
              placeholder={t("admin.role.list.searchInput")}
            />
            <hr />
            <Spinner spinning={isLoadingPermission} />
            {!isLoadingPermission && (
              <BootstrapTable
                key={uuid()}
                {...props.baseProps}
                bootstrap4
                pagination={permissions.length>0 ? paginationFactory(pagination) : false}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(PermissionTable);
