import React from "react";
const CreateGoogleMap = ({ field }) => {
  const style = {
    height: field?.maxlength + "px",
    width: field?.size + "px",
    frameborder: "0px",
    border: "none",
  };

  /*
  z is the zoom level (1-20)
  t is the map type ("m" map, "k" satellite, "h" hybrid, "p" terrain, "e" GoogleEarth)
  q is the search query */
  const url = `https://maps.google.com/maps?q=${field?.value}&t=&z=12&ie=UTF8&iwloc=&output=embed`;
  return <iframe id="gmap_canvas" src={url} style={style} title="googlemap" />;
};
export default CreateGoogleMap;
