function timeConversion(millisec) {
  const seconds = (millisec / 1000).toFixed(0);
  const minutes = (millisec / (1000 * 60)).toFixed(0);
  const hours = (millisec / (1000 * 60 * 60)).toFixed(0);
  const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(0);
  if (seconds < 0)
    return "-"
  else if (seconds < 60) {
    return "menos de um minuto";
  } else if (minutes < 60) {
    return minutes + (minutes > 1 ? " minutos" : " minuto");
  } else if (hours < 24) {
    return hours + (hours > 1 ? " horas" : " hora");
  } else {
    return days + (days > 1 ? " dias" : " dia");
  }
};

export const buildPendingPeriod = (createdDate) => {
  if (createdDate) {
    const millis = Date.now() - new Date(createdDate);
    return timeConversion(millis);
  }
  return ""
};