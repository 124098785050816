import { put } from "redux-saga/effects";
import { activeError, activeSuccess } from "~/components/Messages/actions";
import http from "~/utils/config/http";
import {
  //INTEGRATION COLLABORATORS
  doneGetCRUDCollaboratos,
  setCRUDCollaboratos,
  setMessageADDCRUDCollaboratos,
  //CATEGORIES
  doneGetCRUDCategories,
  setCRUDCategories,
  setMessageADDCRUDCategories,
  //COMPANY
  setCRUDCompanies,
  doneGetCRUDCompanies,
  //DEPARTMENT
  doneGetCRUDDepartments,
  setCRUDDepartments,
  setMessageADDCRUDDepartments,
  //TEAMS
  doneGetCRUDTeams,
  setCRUDTeams,
  setMessageADDCRUDTeams,
  //PARTNERS
  doneGetCRUDPartners,
  setCRUDPartners,
  setMessageADDCRUDPartners,
  //EXPENSES
  doneGetCRUDExpenses,
  setCRUDExpenses,
  setMessageADDCRUDExpenses,
  //CLIENT DIRECTORS
  doneGetCRUDClientDirectors,
  setCRUDClientDirectors,
  setMessageADDCRUDClientDirectors,
  //COLLABORATORS
  doneGetCRUDCollabs,
  setCRUDCollabs,
  setMessageADDCRUDCollabs,
} from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
//INTEGRATION COLLABORATORS
export function* getCRUDCollaborators() {
  try {
    const resp = yield http.get(
      `api/budget-collaborators/collaborators/configured`
    );
    yield put(setCRUDCollaboratos(resp?.data));
    yield put(doneGetCRUDCollaboratos());
  } catch (e) {
    yield put(doneGetCRUDCollaboratos());
    yield put(setCRUDCollaboratos([]));
  }
}

export function* postADDCRUDCollaborators({ payload }) {
  try {
    const resp = yield http.post(
      `api/budget-collaborators/collaborators/configured`,
      payload
    );
    yield put(setMessageADDCRUDCollaboratos(resp?.data));
    yield put(doneGetCRUDCollaboratos());
  } catch (e) {
    yield put(doneGetCRUDCollaboratos());
    yield put(setMessageADDCRUDCollaboratos([]));
  }
}

//CATEGORIES
export function* getCRUDCategories() {
  try {
    const resp = yield http.get(`api/budget-categories`);
    yield put(setCRUDCategories(resp?.data));
    yield put(doneGetCRUDCategories());
  } catch (e) {
    yield put(doneGetCRUDCategories());
    yield put(setCRUDCategories([]));
  }
}

export function* deleteCRUDCategorie(params) {
  try {
    yield http.delete(`api/budget-categories/${params?.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDCategorie(params) {
  const { id, payload } = params;
  try {
    const resp = yield http.put(`api/budget-categories/${id}`, payload);
    yield put(setCRUDCategories(resp?.data));
    yield put(doneGetCRUDCategories());
  } catch (e) {
    yield put(doneGetCRUDCategories());
    yield put(setCRUDCategories([]));
  }
}

export function* postADDCRUDCategories({ payload }) {
  try {
    const resp = yield http.post(`api/budget-categories`, payload);
    yield put(setMessageADDCRUDCategories(resp?.data));
    yield put(doneGetCRUDCategories());
  } catch (e) {
    yield put(doneGetCRUDCategories());
    yield put(setMessageADDCRUDCategories([]));
  }
}

// COMPANY
export function* getCRUDCompanies() {
  try {
    const resp = yield http.get(`api/budgetmgr/empresa/all`);
    yield put(setCRUDCompanies(resp?.data));
    yield put(doneGetCRUDCompanies());
  } catch (e) {
    yield put(doneGetCRUDCompanies());
    yield put(setCRUDCompanies([]));
  }
}

//DEPARTMENT
export function* getCRUDDepartments() {
  try {
    const resp = yield http.get(`api/budget-departments`);
    yield put(setCRUDDepartments(resp?.data));
    yield put(doneGetCRUDDepartments());
  } catch (e) {
    yield put(doneGetCRUDDepartments());
    yield put(setCRUDDepartments([]));
  }
}

export function* deleteCRUDDepartments(params) {
  try {
    yield http.delete(`api/budget-departments/${params?.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDDepartments(params) {
  const { id, payload } = params;
  try {
    yield http.put(`api/budget-departments/${id}`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* postADDCRUDDepartments({ payload }) {
  try {
    yield http.post(`api/budget-departments`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

//TEAMS
export function* getCRUDTeams() {
  try {
    const resp = yield http.get(`api/budget-teams`);
    yield put(setCRUDTeams(resp?.data));
    yield put(doneGetCRUDTeams());
  } catch (e) {
    yield put(doneGetCRUDTeams());
    yield put(setCRUDTeams([]));
  }
}

export function* deleteCRUDTeams(params) {
  try {
    yield http.delete(`api/budget-teams/${params?.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDTeams(params) {
  const { id, payload } = params;
  try {
    yield http.put(`api/budget-teams/${id}`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* postADDCRUDTeams({ payload }) {
  try {
    yield http.post(`api/budget-teams`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

//PARTNERS
export function* getCRUDPartners() {
  try {
    const resp = yield http.get(`api/budget-partners`);
    yield put(setCRUDPartners(resp?.data));
    yield put(doneGetCRUDPartners());
  } catch (e) {
    yield put(doneGetCRUDPartners());
    yield put(setCRUDPartners([]));
  }
}

export function* deleteCRUDPartners(params) {
  try {
    yield http.delete(`api/budget-partners/${params.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDPartners(params) {
  const { id, payload } = params;
  try {
    yield http.put(`api/budget-partners/${id}`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* postADDCRUDPartners({ payload }) {
  try {
    yield http.post(`api/budget-partners`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

//EXPENSES
export function* getCRUDExpenses() {
  try {
    const resp = yield http.get(`api/budget-expenses`);
    yield put(setCRUDExpenses(resp?.data));
    yield put(doneGetCRUDExpenses());
  } catch (e) {
    yield put(doneGetCRUDExpenses());
    yield put(setCRUDExpenses([]));
  }
}

export function* deleteCRUDExpenses(params) {
  try {
    yield http.delete(`api/budget-expenses/${params.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDExpenses(params) {
  const { id, payload } = params;
  try {
    yield http.put(`api/budget-expenses/${id}`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* postADDCRUDExpenses({ payload }) {
  try {
    const resp = yield http.post(`api/budget-expenses`, payload);
    yield put(setMessageADDCRUDExpenses(resp?.data));
    yield put(doneGetCRUDExpenses());
  } catch (e) {
    devLogConsole(e);
  }
}

//CLIENT DIRECTORS
export function* getCRUDClientDirectors() {
  try {
    const resp = yield http.get(`api/budget-client-directors`);
    yield put(setCRUDClientDirectors(resp?.data));
    yield put(doneGetCRUDClientDirectors());
  } catch (e) {
    yield put(doneGetCRUDClientDirectors());
    yield put(setCRUDClientDirectors([]));
  }
}

export function* deleteCRUDClientDirectors(params) {
  try {
    yield http.delete(`api/budget-client-directors/${params.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDClientDirectors(params) {
  const { id, payload } = params;
  try {
    yield http.put(`api/budget-client-directors/${id}`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* postADDCRUDClientDirectors({ payload }) {
  try {
    yield http.post(`api/budget-client-directors`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

//COLLABORATORS
export function* getCRUDCollabs() {
  try {
    const resp = yield http.get(`api/budget-collaborators`);
    yield put(setCRUDCollabs(resp?.data));
    yield put(doneGetCRUDCollabs());
  } catch (e) {
    yield put(doneGetCRUDCollabs());
    yield put(setCRUDCollabs([]));
  }
}

export function* deleteCRUDCollabs(params) {
  try {
    yield http.delete(`api/budget-collaborators/${params.id}`);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* updateCRUDCollabs(params) {
  const { id, payload } = params;
  try {
    yield http.put(`api/budget-collaborators/${id}`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}

export function* postADDCRUDCollabs({ payload }) {
  try {
    yield http.post(`api/budget-collaborators`, payload);
  } catch (e) {
    devLogConsole(e);
  }
}