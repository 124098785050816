import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal, Row, Col } from "react-bootstrap";
import UpdateDocument from './UpdateDocument';
import Spinner from "~/components/Spinner";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

import {setPreviewBinary} from "~/store/ducks/portalrh/actionTypes";

const PreviewModal = (props) => {
  const {
    showModal,
    closeModal,
    selectedDocument,
    canUpdate
  } = props;

  const dispatch = useDispatch();
  const {preview, isLoadingPreview} = useSelector((state) => state.portalrhReducer);

  useEffect(()=>{
    return ()=> dispatch(setPreviewBinary(null));
  },[])

  const generateImageView = (doc) => doc ? "data:image/jpg;base64, " + doc.data : "";
  const  generatePdfView = (doc) =>{
    if(doc && doc.data !==""){
      function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1]
        try {
          var bstr = atob(arr[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
      } catch(e) {
        return new Blob(['No data found'], {type: 'text/html'});
      }
      };
      //convert to blob because of Data protocol URL size limitations
      let blob=dataURLtoBlob("data:application/pdf;base64, " + doc.data);
      const temp_url = window.URL.createObjectURL(blob);
      return temp_url;
    }
  };

  return (
      <>
        <Modal
          dialogClassName="modal-80w"
          show={showModal}
          onHide={() => {closeModal();dispatch(setPreviewBinary(null));}}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "100%", width: "100%", minHeight: "80vh"}}>                                    
            <Row>
              <UpdateDocument
                selectedDocument={selectedDocument}
                canUpdate={canUpdate}
              />
              <Col >
                {preview && preview.documentType === "application/pdf" && (
                  <>
                    <iframe
                      id="pdf-document"
                      title="pdf-document"
                      src={generatePdfView(preview)}
                      style={{ height: "100%", width: "100%", minHeight: "80vh"}}
                    />
                  </>
                )}
                {preview &&
                  preview.documentType !== "application/pdf" && (
                    <img
                      src={generateImageView(preview)}
                      className="portal-document-preview"
                      alt="no-preview-available"
                    />
                  )}
                  <Spinner spinning={isLoadingPreview}/>
              </Col> 
            </Row>                                   
          </Modal.Body>
        </Modal>
      </>
  )
};

export default withNamespaces()(PreviewModal);
