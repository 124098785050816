export const colors = [
    { eventType: "horario", color: "#286DA4" },
    { eventType: "registoPresenca", color: "#5AB45E" },
    { eventType: "justificacao", color: "#AD3E3E" },
    { eventType: "bolsaDeHoras", color: "#62BEB5" },
    { eventType: "feriados", color: "#E6BB51" },
    { eventType: "ferias", color: "#E6BB51" },
  ];

  export const iflowConstants={
    JUSTIFICACAO: "Justificação",
    REGISTO_PRESENCA: "Registo Presença",
    HORA_EXTRA: "Bolsa Horas",
  }

  export const variablesEvents ={
    HORARIO: "horario",
    REGISTO_PRESENCA: "registoPresenca",
    JUSTIFICACAO: "justificacao",
    FERIADOS: "feriados",
    BOLSA_HORAS: "bolsaDeHoras",
    FERIAS: "ferias"
  }

  export const navigate = {
    PREVIOUS: "PREV",
    NEXT: "NEXT",
    TODAY: "TODAY",
    DATE: "DATE",
  };