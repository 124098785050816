import {
  REQUEST_USER,
  SUCCESS_REQUEST_USER,
  CLEAR_USER,
  FAIL_REQUEST_USER,
  TOGGLE_ISAVAILABLE,
  SET_CURRENT_MENU_PERMISSION,
  ADD_LEGACY_PERMISSIONS,
  SET_PROCESS_FORM_URL,
  SET_PROCESS_FORM_MENU_ITEM_CLICKED,
  SET_USER_ORGANIZATION,
} from "../actions";

const initialState = {
  user: {},
  isLoadingUser: false,
  isAvailable: true,

  currentFormUrl: "",
  currentFormMenuItemClicked: false,
  currentMenuPermission: null,
};

export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER:
      return {
        ...state,
        isLoadingUser: true,
      };
    case SUCCESS_REQUEST_USER:
      return {
        ...state,
        user: action.data,
        isLoadingUser: false,
      };
    case SET_USER_ORGANIZATION:
      return {
        ...state,
        user: {
          ...state.user,
          organization: action.data,
        },
      };
    case FAIL_REQUEST_USER:
      return {
        ...state,
        isLoadingUser: false,
      };
    case CLEAR_USER:
      return { ...state, user: initialState.user };
    case TOGGLE_ISAVAILABLE:
      return {
        ...state,
        isAvailable: !state.isAvailable,
      };

    case SET_CURRENT_MENU_PERMISSION:
      return { ...state, currentMenuPermission: action.currentMenuPermission };

    case ADD_LEGACY_PERMISSIONS: {
      /* DELETE after tests
      if (state.user.roles) {
        const updatedUser = { ...state.user };
        const role = updatedUser.roles.find(role => role.label === action.roleLabel)
        const updatedRole = { ...role };
        if (!updatedRole.permissions)
          updatedRole.permissions = []
        action.legacyPermissions.forEach(permission => {
          if (!updatedRole.permissions.find(item => item.label === permission.label))
            updatedRole.permissions.push(permission)
        })
        updatedUser.roles.map(role => {
          if (role.label === action.roleLabel)
            role = updatedRole
        })
        {
          return { ...state, user: updatedUser }
        }
      } else return state;
      */
      if (state.user.roles) {
        const { roles } = state.user;
        const { roleLabel, legacyPermissions } = action;
        let role = roles.find((r) => r.label === roleLabel);
        if (role) {
          if (!Array.isArray(role.permissions)) role.permissions = [];

          legacyPermissions.forEach((permission) => {
            if (!role?.permissions.find((r) => r.label === permission.label)) {
              role.permissions.push(permission);
            }
          });
        }
        return { ...state };
      } else return state;
    }

    case SET_PROCESS_FORM_URL:
      return { ...state, currentFormUrl: action.url };

    case SET_PROCESS_FORM_MENU_ITEM_CLICKED:
      return { ...state, currentFormMenuItemClicked: action.clicked };

    default:
      return state;
  }
};
