export const barChart =
{
  type: 'bar',
  data: {
    datasets: [{
      barThickness: "flex",
      borderWidth: 1,
      backgroundColor: "rgba(51, 5, 141, 0.5)",
      borderColor: "rgba(51, 5, 141, 1)",
      data: []
    }]
  },
  options: {
    animation: false,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'date'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          //maxTicksLimit: 10
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 7,
          padding: 10,
          callback: function (value, index, values) {
            return value;
          }
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      titleMarginBottom: 10,
      titleFontColor: '#6e707e',
      titleFontSize: 14,
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      intersect: false,
      mode: 'index',
      caretPadding: 10,
      callbacks: {
        label: (tooltipItem, chart) => {
          var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
          return datasetLabel + " " + tooltipItem.yLabel;

        }
      }
    }
  }
}

/*{
  type: 'line',
    data: {
    labels: [],
      datasets: [{
        label: "",
        lineTension: 0.3,
        backgroundColor: "rgba(51, 5, 141, 0.05)",
        borderColor: "rgba(51, 5, 141, 1)",
        pointRadius: 3,
        pointBackgroundColor: "rgba(51, 5, 141, 1)",
        pointBorderColor: "rgba(51, 5, 141, 1)",
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "rgba(51, 5, 141, 1)",
        pointHoverBorderColor: "rgba(51, 5, 141, 1)",
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: [],
      }],
  },
  options: {
    animation: false,
      maintainAspectRatio: false,
        layout: {
      padding: {
        left: 10,
          right: 25,
            top: 25,
              bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'date'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 7
        }
      }],
        yAxes: [{
          ticks: {
            maxTicksLimit: 7,
            padding: 10,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return '$' + number_format(value);
            }
          },
          gridLines: {
            color: "rgb(234, 236, 244)",
            zeroLineColor: "rgb(234, 236, 244)",
            drawBorder: false,
            borderDash: [2],
            zeroLineBorderDash: [2]
          }
        }],
    },
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
        bodyFontColor: "#858796",
          titleMarginBottom: 10,
            titleFontColor: '#6e707e',
              titleFontSize: 14,
                borderColor: '#dddfeb',
                  borderWidth: 1,
                    xPadding: 15,
                      yPadding: 15,
                        displayColors: false,
                          intersect: false,
                            mode: 'index',
                              caretPadding: 10,
                                callbacks: {
        label: (tooltipItem, chart) => {
          var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
          return datasetLabel + " " + tooltipItem.yLabel;

        }
      }
    }
  }
}*/