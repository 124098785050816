import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  getColorByStatus,
  timeConversion
} from '~/components/Tasks/utils';
import warning_alert from '~/assets/img/icons/alert_amber.png';
import danger_alert from '~/assets/img/icons/alert_red.png';
import { toLocaleString } from '~/utils';

export const getColumnFormatter = (cell, row, rowIndex, formatExtraData) => {

  const buildStatusColumn = () => {
    if (row.metadata && row.metadata.some(item => item.name === "PROCESS_STATUS")) {
      const status = row.metadata.find(item => item.name === "PROCESS_STATUS").value;
      if (status) {
        const variant = getColorByStatus(status);
        //const translatedStatus = translateStatus(status);
        return <Badge pill variant={variant}>{status.toUpperCase()}</Badge>;
      }
    }
    return <Badge pill variant="warning">INDETERMINADO</Badge>;
  };

  const buildSLAColumn = () => {
    if (row.SLA) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2)
        const month = dateStr.slice(2, 4)
        const year = dateStr.slice(4, 8)
        const hour = dateStr.slice(8, 10)
        const min = dateStr.slice(10, 12)
        const sec = dateStr.slice(12, 14)
        return new Date(year, month - 1, day, hour, min, sec)
      }
      const date = toDate(row.SLA);
      return date ? date.toLocaleString() : ""
    }
    return ""
  };

  const buildTTLColumn = () => {
    if (row.TTL) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2)
        const month = dateStr.slice(2, 4)
        const year = dateStr.slice(4, 8)
        const hour = dateStr.slice(8, 10)
        const min = dateStr.slice(10, 12)
        const sec = dateStr.slice(12, 14)
        return new Date(year, month - 1, day, hour, min, sec)
      }
      const date = toDate(row.TTL);
      if (date) {

        const diff = Date.now() - date
        let variant = "info";
        if (diff > 0)/*86400000 1 day in milliseconds*/
          variant = "danger"
        if (diff > -86400000 && diff < 0)
          variant = "warning"
        return (
          <Badge pill variant={variant}>
            <div style={{ width: "70px", wordWrap: "break-word", whiteSpace: "normal", padding: "0.3em" }}>
              {date.toLocaleString()}
            </div>
          </Badge>
        )
      } else
        return ""
    }
    return ""
  };

  const buildTTLColumnDemo = () => {
    if (row.created) {
      const toDate = () => {
        let myDate = new Date(row.created);
        return myDate.setDate(myDate.getDate() + 1);
      }
      const TTLdate = toDate();
      if (TTLdate) {
        const diff = Date.now() - TTLdate
        let variant = "info";
        if (diff > 0)
          variant = "danger"
        if (diff > - 3600000 * 4 && diff < 0)/*86400000 1 day in milliseconds, 3600000 1 hour in milliseconds*/
          variant = "warning"
        const renderTTLAlert = () => {
          if (variant === "danger") {
            return (
              <div style={{ width: "85px", wordWrap: "break-word", whiteSpace: "normal" }}>
                {toLocaleString(TTLdate)}
                < img src={danger_alert} height="20" alt="" style={{ marginLeft: "0.3em" }} />
              </div>
            )
          } else if (variant === "warning") {
            return (
              <div style={{ width: "85px", wordWrap: "break-word", whiteSpace: "normal" }}>
                {toLocaleString(TTLdate)}
                <img src={warning_alert} height="20" alt="" style={{ marginLeft: "0.3em" }} />
              </div>
            )
          } else
            return (
              <div style={{ width: "85px", wordWrap: "break-word", whiteSpace: "normal" }}>
                {toLocaleString(TTLdate)}
              </div>
            )
        }
        return (
          renderTTLAlert()
        )
      } else
        return ""
    }
    return ""
  };

  const abbreviateTextColumn = (text, rowIndex, numLetters) => {
    return <OverlayTrigger
      key={rowIndex}
      placement="right"
      overlay={
        <Tooltip id={`tooltip-${rowIndex}`}>
          {text}
        </Tooltip>
      }
    >
      <span>{text && text.length > numLetters ? text.slice(0, numLetters-1) + "..." : text}</span>
    </OverlayTrigger>
  };

  const buildPendingColumn = () => {
    if (row.created) {
      const millis = Date.now() - new Date(row.created);
      return timeConversion(millis);
    }
    return ""
  };
  const buildTextfromIDTeam=()=>{
    for (var i in formatExtraData.obj){
      if(formatExtraData.obj[i].idEquipa == row.idEquipa){
        return (formatExtraData.obj[i].empresa + ' - ' + formatExtraData.obj[i].nome)
      }
    }
  }
  const buildTextfromIDPartner=()=>{
    for (var i in formatExtraData.obj){
      if(formatExtraData.obj[i].idPartner == row.idPartner){
        return (formatExtraData.obj[i].empresa + ' - ' + formatExtraData.obj[i].nome)
      }
    }
  }

  if (formatExtraData.value === "PROCESS_STATUS") {
    return buildStatusColumn();
  }
  if (formatExtraData.value === "SLA") {
    return buildSLAColumn();
  }
  if (formatExtraData.value === "TTL") {
    //return buildTTLColumn();
    return buildTTLColumnDemo();
  }
  if (formatExtraData.value === "description") {
    return abbreviateTextColumn(row.description, rowIndex,50);
  }
  if (formatExtraData.value === "pid") {
    return abbreviateTextColumn(row.pid, rowIndex, 6);
  }
  if (formatExtraData.value === "pendingPeriod") {
    return buildPendingColumn();
  }
  if (formatExtraData.value === "trnasformeIdintoTextTeam") {
    return buildTextfromIDTeam();
  }
  if (formatExtraData.value === "trnasformeIdintoTextPartner") {
    return buildTextfromIDPartner();
  }
  else return row[formatExtraData.value]

}

