import * as types from "../actions";

const initialState = {
  isLoadingLogin: false,
  isAuthenticated: undefined,
  failCredentials: false,
  failLDPA: false,
  failRemoteServer: false,
  isLoadingCheckToken: true,

  globalSolverAuthenticated: false,
  simpleUserCreated: false,

  isRequestingSAMLUrl: false,
  samlRequestUrl: undefined,
  isSignInSAML: false,
  isSAMLAuthenticated: undefined,
  authWindows: undefined,
  authKeycloak: undefined,
  isLoadingIntranet: false,
  isFromIntranet: false,
  isLoadingLADPSign:false,
  isSetLDAPSignIn:[],
  authKeycloakConfig:{}
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_LOGIN:
      return { ...state, isLoadingLogin: true };
    case types.SUCCESS_LOGIN:
      return {
        ...state,
        isLoadingLogin: false,
        isAuthenticated: true,
        failCredentials: false,
        failRemoteServer: false,
      };
    case types.FAIL_CREDENTIALS:
      return {
        ...state,
        isLoadingLogin: false,
        isAuthenticated: false,
        failCredentials: true,
      };
      case types.FAIL_CREDENTIALS_LDPA:
        return {
          ...state,
          failLDPA: true,
        };
    case types.FAIL_REMOTE_SERVER:
      return {
        ...state,
        isLoadingLogin: false,
        isAuthenticated: false,
        failRemoteServer: true,
      };
    case types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        globalSolverAuthenticated: false,
        simpleUserCreated: false,
      };

    case types.SET_GLOBAL_SOLVER_AUTHENTICATED:
      return {
        ...state,
        globalSolverAuthenticated: action.data,
      };

    case types.SET_SIMPLE_USER_CREATED:
      return {
        ...state,
        simpleUserCreated: action.data,
      };
    case types.GOOGLE_SIGN_IN:
      return { ...state, isLoadingLogin: true };
    case types.MICROSOFT_SIGN_IN:
      return { ...state, isLoadingLogin: true };
      case types.ADFS_SIGN_IN:
        return { ...state, isLoadingLogin: true };
    case types.LDAP_SIGN_IN:
      return { ...state, isLoadingLogin: true };
      case types.SET_LDAP_SIGN_IN:
        return { ...state, isSetLDAPSignIn: action.data };
    case types.GET_SAML_URL_REQUEST:
      return { ...state, isRequestingSAMLUrl: true };
    case types.SET_SAML_URL_REQUEST:
      return {
        ...state,
        isRequestingSAMLUrl: false,
        samlRequestUrl: action.data,
      };
    case types.CLEAR_SAML_URL_REQUEST:
      return { ...state, samlRequestUrl: undefined };
    case types.SIGN_IN_SAML:
      return { ...state, isSignInSAML: true };
    case types.SIGNED_IN_SAML:
      return {
        ...state,
        isSignInSAML: false,
        isSAMLAuthenticated: action.isSAMLAuthenticated,
      };
      case types.GET_REQUEST_LEGACY_USERNAME_LOGGED_IN:
        return {...state};
      case types.SET_REQUEST_LEGACY_USERNAME_LOGGED_IN:
        return {
          ...state,
          legacyUsernameLoggedIn: action.data
        }
      case types.GET_KEYCLOAK_AUTH_CONFIG:
          return {
             ...state,
             data: action.data
          };
      case types.SET_KEYCLOAK_AUTH_CONFIG:
        return {
          ...state,
          authKeycloak: action.data
        };
      case types.GET_WINDOWS_AUTH_CONFIG:
        return {
           ...state,
           data: action.data
        };
        case types.SET_KEYCLOCK_AUTH_CONFIG:
          return {
             ...state,
             authKeycloakConfig: action.data
          };
      case types.GET_WINDOWS_AUTH_CONFIG_IFLOW:
        return {
            ...state,
            data: action.data
        };
      case types.SET_WINDOWS_AUTH_CONFIG:
        return {
          ...state,
          authWindows: action.data
        };
      case types.GET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH:
        return {
          ...state
        }
      case types.SET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH:
        return {
          ...state,
          unikUserFromlegacyUsername: action.data,
        };
      case types.GET_REQUEST_INTRANET_LOGIN:
        return {
          ...state,
          data: action.data,
          isLoadingIntranet: true

        };
      case types.SET_REQUEST_INTRANET_LOGIN:
        return {
          ...state,
          isLoadingIntranet: false
        };
      case types.GET_IS_FROM_INTRANET_LOGIN:
        return {
          ...state,
          isFromIntranet: true

      };
    default:
      return state;
  }
};
