import React, { useState, useEffect } from "react";
import oauth from "~/utils/oauth";

const CreateTablePicture = (props) => {
  const { field } = props;

  const [mimeType, setMimeType] = useState("");

  useEffect(() => {
    if (field) {
      if (field.text) {
        const mimeType = field.text.split(".").pop();
        setMimeType(mimeType);
      }
    }
  }, [field]);

  const renderImg = () => {
    if (
      mimeType.toLocaleLowerCase() === "png" ||
      mimeType.toLocaleLowerCase() === "jpg" ||
      mimeType.toLocaleLowerCase() === "jpeg"
    ) {
      return (
        <img
          alt="doc"
          style={{
            width: "90%",
            height: "auto",
          }}
          src={field.href + "&Authorization=" + oauth.getAccessToken()}
        />
      );
    }
  };

  return <div>{renderImg()}</div>;
};

export default CreateTablePicture;
