export const CREATE_USER_SETTINGS = "CREATE_USER_SETTINGS";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const CLEAR_USER_SETTINGS = "CLEAR_USER_SETTINGS";

export const FIND_WIDGET_BY_ID = "FIND_WIDGET_BY_ID";
export const FIND_ALL_WIDGETS = "FIND_ALL_WIDGETS";
export const SAVE_USER_WIDGET = "SAVE_USER_WIDGET";
export const UPDATE_USER_WIDGET = "UPDATE_USER_WIDGET";
export const DELETE_USER_WIDGET = "DELETE_USER_WIDGET";
export const SET_USER_WIDGETS = "SET_USER_WIDGETS";
export const SET_LAST_ADDED_WIDGET = "SET_LAST_ADDED_WIDGET";
export const FIND_WIDGETS_BY_APPLICATION = "FIND_WIDGETS_BY_APPLICATION";
export const SET_IS_UPDATED_WIDGET = "SET_IS_UPDATED_WIDGET";

export const SET_UNIKUSER_SETTINGS = "SET_UNIKUSER_SETTINGS";
export const FIND_UNIKUSER_SETTINGS = "FIND_UNIKUSER_SETTINGS";
export const UPDATE_UNIKUSER_SETTINGS = "UPDATE_UNIKUSER_SETTINGS";

export const GET_ISFIRSTLOGIN = "GET_ISFIRSTLOGIN";
export const SET_ISFIRSTLOGIN = "SET_ISFIRSTLOGIN";
export const POST_ISFIRSTLOGIN = "POST_ISFIRSTLOGIN";
export const SET_POST_ISFIRSTLOGIN = "SET_POST_ISFIRSTLOGIN";

export const createUserSettings = (user) => {
  return { type: CREATE_USER_SETTINGS, user };
};

export const updateUserSettings = (user) => {
  return { type: UPDATE_USER_SETTINGS, user };
};

export const setUserSettings = (userSetting) => {
  return { type: SET_USER_SETTINGS, userSetting };
};

export const clearUserSettings = () => {
  return { type: CLEAR_USER_SETTINGS };
};

export const findWidgetById = (id) => {
  return {
    type: FIND_WIDGET_BY_ID,
    id,
  };
};

export const findAllWidgets = () => {
  return { type: FIND_ALL_WIDGETS };
};

export const findWidgetsByApplication = (app) => {
  return { type: FIND_WIDGETS_BY_APPLICATION, app };
};

export const saveUserWidget = (widget) => {
  return {
    type: SAVE_USER_WIDGET,
    widget,
  };
};

export const updateUserWidget = (widget) => {
  return {
    type: UPDATE_USER_WIDGET,
    widget,
  };
};

export const deleteUserWidget = (id) => {
  return {
    type: DELETE_USER_WIDGET,
    id,
  };
};

export const setUserWidgets = (userWidgets) => {
  return {
    type: SET_USER_WIDGETS,
    userWidgets,
  };
};

export const setLastAddedWidget = (widget) => {
  return {
    type: SET_LAST_ADDED_WIDGET,
    widget,
  };
};

export const setIsUpdatedWidget = (isUpdated) => {
  return {
    type: SET_IS_UPDATED_WIDGET,
    isUpdated,
  };
};

export const setUnikUserSettings = (unikUserSettings) => {
  return { type: SET_UNIKUSER_SETTINGS, unikUserSettings };
};

export const findUnikUserSettings = (username) => {
  return { type: FIND_UNIKUSER_SETTINGS, username };
};

export const updateUnikUserSettings = (unikUserSettings) => {
  return { type: UPDATE_UNIKUSER_SETTINGS, unikUserSettings };
};

export const UPDATE_SIGNATURE_REDUCED = "UPDATE_SIGNATURE_REDUCED";
export const updateSignatureReduced = (unikUserSettings) => {
  return { type: UPDATE_SIGNATURE_REDUCED, unikUserSettings };
};

export const getIsFirstLogin = ()=>({ type: GET_ISFIRSTLOGIN });
export const setisfirstlogin = (isfirstlogin) => ({ type: SET_ISFIRSTLOGIN, isfirstlogin });
export const setpostisfirstlogin = (post_isfirstlogin) => ({ type: SET_POST_ISFIRSTLOGIN, post_isfirstlogin });


export const postIsFirstLogin = (payload) => {
  return {
    type: POST_ISFIRSTLOGIN,
    data: payload
  }
};