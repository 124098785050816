import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import Header from "~/pages/Easypay/components/Header";
import "~/pages/Easypay/assets/css/styles.css";
import BackOfficeDocumentPreviewSection from "~pages/Easypay/components/BackOfficeDocumentPreviewSection";
import { useLocation } from "react-router-dom";

const BackOfficePreviewDocumentPage = (props) => {
  const { id } = props.match?.params;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="easypay-backoffice-document-preview-page-main-wrapper"
      style={{ overflow: "auto", width: "100%", height: "100%" }}
    >
      <Header />
      <BackOfficeDocumentPreviewSection location={location} id={id} />
    </div>
  );
};
export default withNamespaces()(BackOfficePreviewDocumentPage);
