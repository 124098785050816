import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { setPortalRhMenu, setAllPortalRhMenus } from "./actionTypes";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

export function* findPortalRhMenu() {
  try {
    const resp = yield http.get(`api/menu/find-by-user`, PORTALRH_MIDDLEWARE);
    yield put(setPortalRhMenu(resp.data));
    yield put(setAllPortalRhMenus(resp.data));
  } catch (e) {
    yield put(setPortalRhMenu([]));
    yield put(setAllPortalRhMenus([]));
  }
}
