import {
  GET_LIST_SIADAPRA_UO,
  SET_LIST_SIADAPRA_UO,
  GET_LIST_SIADAPRA_UO_DROPDOWN,
  SET_LIST_SIADAPRA_UO_DROPDOWN,
  GET_LIST_SIADAPRA_UO_DETAIL,
  GET_LIST_SIADAPRA_UO_ORG_DETAIL
} from "./actionTypes";

const initialState = {
  unidadesOrganicas: [],
  unidadesOrganicasDropdown: [],
  isLoadingunidadesOrganicas: false,
};

export const siadapraReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_SIADAPRA_UO:
      return {
        ...state,
        isLoadingunidadesOrganicas: true,
      };
    case GET_LIST_SIADAPRA_UO_DROPDOWN:
      return {
        ...state,
      };
    case SET_LIST_SIADAPRA_UO:
      return {
        ...state,
        unidadesOrganicas: action.unidadesOrganicas,
        isLoadingunidadesOrganicas: false,
      };
    case SET_LIST_SIADAPRA_UO_DROPDOWN:
      return {
        ...state,
        unidadesOrganicasDropdown: action.dropdown,
        isLoadingunidadesOrganicas: false,
      };
    case GET_LIST_SIADAPRA_UO_DETAIL:
      return {
        ...state,
      };
      case GET_LIST_SIADAPRA_UO_ORG_DETAIL:
        return {
          ...state,
        };
    default:
      return state;
  }
};
