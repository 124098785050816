import React from "react";
import uuid from "uuid/v1";
import "../assets/scss/_textbox.scss";

function Textbox({ title, value, size ,color}) {
  return (
    <div className={"liftworld-textbox-main-wrapper"} key={uuid()}>
      <div className={"liftworld-textbox-title-wrapper"}>{title}</div>
      <div className={`${"liftworld-textbox-value-wrapper"} ` + size + " " + color}>
        {value}
      </div>
    </div>
  );
}

export default Textbox;
