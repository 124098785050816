import React, { useState, useEffect } from "react";
import SchedulerComponent from "./Scheduler";

import { Card, Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { requestConfiguration } from "~/pages/Admin/SystemSettings/actions";
import {
  getRHBolsaHorasConfiguration,
  getRHBolsaHorasUsersGroup,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import { withNamespaces } from "react-i18next";
const Abscense = ({
  portalRhMenus,
  designacao,
  path,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  setLoadEventsStart,
  t
}) => {
  const dispatch = useDispatch();

  const [selectedUser, setSelectedUser] = useState(undefined);
  const [grupos, setgrupos] = useState("");
  const [activeButton, setActiveButton] = useState("");

  const { user } = useSelector((state) => state.globalReducer);
  const { organization } = useSelector((state) => state.organizationsReducer);

  useEffect(() => {
    if (Object.keys(organization).length < 1)
      dispatch(getOrganizationById(user?.organization?.id));
  }, [user]);

  useEffect(() => {
    if (Object.keys(organization).length > 0)
      dispatch(getRHBolsaHorasConfiguration(organization.id));
  }, [organization]);

  useEffect(() => {
    dispatch(requestConfiguration());
    if (!isEditable) dispatch(getRHBolsaHorasUsersGroup());
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
  }, []);

  return (
    <div className="main-card-v2" style={{ overflow: "auto", scrollY: "auto" }}>
      <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
      <Card.Header className="justify-content-between">
          <h6>{t("menu.portalrh.subMenus.attendanceRegister")}</h6>
        </Card.Header>
        <Card.Body>
          <SchedulerComponent
            portalRhMenus={portalRhMenus}
            designacao={designacao}
            path={path}
            isEditable={isEditable}
            showCalendarAndTable={showCalendarAndTable}
            loadEventsStart={loadEventsStart}
            setLoadEventsStart={setLoadEventsStart}
            selectedUser={isEditable ? undefined : selectedUser}
            setSelectedUser={setSelectedUser}
            grupos={grupos}
            setgrupos={setgrupos}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(Abscense);
