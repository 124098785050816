import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import {
  setDatacaptureConfiguration,
  clearDatacaptureConfiguration,
  sucessesPersistDatacaptureConfiguration
} from "./actionTypes";

export function* getDatacaptureConfiguration({ organizationId }) {
  try {
    yield put(clearDatacaptureConfiguration());
    const resp = yield http.get(`/api/datacapture-configuration/find-by-organization/${organizationId}`);
    yield put(setDatacaptureConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistDatacaptureConfiguration({ configuration }) {
  try {
    yield put(clearDatacaptureConfiguration());
    yield http.post("/api/datacapture-configuration/create", configuration);
    yield put(sucessesPersistDatacaptureConfiguration());
  } catch (error) {
    reject(error);
  }
}

export function* deleteDatacaptureConfiguration({ datacaptureConfigurationId }) {
  try {
    yield http.delete(`/api/datacapture-configuration/delete/${datacaptureConfigurationId}`);
    yield put(sucessesPersistDatacaptureConfiguration());
  } catch (error) {
    reject(error);
  }
}