import React, { useState, useEffect, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import { withNamespaces } from "react-i18next";
import { Card } from 'react-bootstrap';
import { reject } from "q";
import http from '~/utils/config/http';
import oauth from '../../../utils/oauth';

import { FIN_MIDDLEWARE } from '~/utils/constants';

const BackofficeMenuItem = ({ t, item, children }) => {

  const [link, setLink] = useState("");
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    const generateUrl = async () => {
      const application = item?.application?.label;
      const accessToken = oauth.getAccessToken();

      if (application === FIN_MIDDLEWARE.context) {
        try {
          const myurl = "/fin/linkfin";
          const response = await http.get(myurl, FIN_MIDDLEWARE);
          if (isMountedRef.current) {
            setLink("epublicafin:" + response.data);
          }
        } catch (error) {
          if (isMountedRef.current) {
            setLink("epublicafin:error");
          }
          reject(error);
        }

      } else if (application === "rh") {
        let myurl;
        if (item.name.includes("innuxtime")) {
          myurl = "innuxtime:" + accessToken;
        } else if (item.name.includes("webtime")) {
          myurl = "/webtime";
        } else if (item.name.includes("mfiles")) {
          myurl = "mfiles:" + accessToken;
        } else {
          myurl = "epublicarh:" + accessToken;//default UnikPeople
        }
        setLink(myurl);

      } else if (application === "datacapture") {
        let myurl;
        if (item.name.includes("mfiles")) {
          myurl = "mfiles:" + accessToken;
        }
        setLink(myurl);
      }
    };

    generateUrl();
    return () => isMountedRef.current = false;
  }, [item])

  return (
    item ? <Card className="menu-item">
      <a href={link} target="_blank" className="menu-link">
        <Accordion.Toggle as={Card.Header} eventKey="0" className="menu-toggle-lvl1" style={{ border: "0" }}>
          <span>
            <i className={item.icon}></i>
            {t(item.name)} &nbsp;
            </span>
          <i className="icon-external-link" style={{ opacity: 0.8 }}></i>
        </Accordion.Toggle>
      </a>
      {children}
    </Card> : null
  )
}
export default withNamespaces()(BackofficeMenuItem)
