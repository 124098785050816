import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { differenceWith, isEqual, pull } from "lodash";

import * as actions from "./actions";

import { Card, Col, Table, Button, Form } from "react-bootstrap";

import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";
const devLogConsole = require("~/utils/devLog");
const PersistRule = (props) => {
  const [validated, setValidated] = useState(false);
  const [nome, setNome] = useState("");
  const [qtDias, setQtDias] = useState("");

  const [selectedLegacyApplication, setSelectedLegacyApplication] = useState(
    {}
  );

  const [legacyRoles, setLegacyRoles] = useState([]);
  const [legacyApplications, setLegacyApplications] = useState([]);

  const [showModalLegacyRole, setShowModalLegacyRole] = useState(false);

  const { legacyApps, rules, isLoadingRules, isPersisting } = useSelector(
    (state) => state.ruleReducer
  );

  const { permissions } = useSelector((state) => state.permissionReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = props.match.params;
  const { t } = props;
  const isEditing = !!id;

  useEffect(() => {
    return () => {
      dispatch(actions.clearRules());
      //dispatch(clearPermissions());
    };
  }, []);

  useEffect(() => {
    //   dispatch(actions.loadLegacyApps());
    if (id) {
      dispatch(actions.getRules());
    }

    //   dispatch(getPermissions());
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.keys(rules).length > 0) {
      if (id) {
        devLogConsole(id, rules);
        const ruleToUpdate = rules.find((rule) => rule.id === Number(id));
        devLogConsole(ruleToUpdate);
        if (ruleToUpdate) {
          setNome(String(ruleToUpdate.nome));
          setQtDias(String(ruleToUpdate.qtDias));
        } else {
          setNome("");
          setQtDias("");
        }
      } else {
        setNome("");
        setQtDias("");
      }
    } else {
      setNome("");
      setQtDias("");
    }
  }, [rules, id]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      persist();
    }
  };

  function persist() {
    const payload = {
      nome: nome,
      qtDias: qtDias,
    };
    payload.id = isEditing ? parseInt(id) : null;
    dispatch(actions.persistRule(payload));
  }

  const backToSearch = () => {
    history.push("/uniksystem/portalrh/administration/rulesRemoteWork");
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-add mr-2" />
              {`${isEditing ? t("general.edit") : t("general.add")}  ${t(
                "admin.general.rule"
              )}`}
            </h6>
          </Card.Header>

          <Card.Body style={isLoadingRules ? { display: "none" } : {}}>
            <Form.Row>
              <Col sm="12" md="8" lg="6">
                <Form.Group controlId="description">
                  <Form.Label>{t("admin.rule.persist.nome")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={nome}
                    disabled={isEditing}
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("admin.rule.persist.requiredNome")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="label">
                  <Form.Label>{t("admin.rule.persist.qtDias")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={qtDias}
                    onChange={(e) => setQtDias(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("admin.rule.persist.requiredQtDias")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Card.Body>

          <Card.Footer>
            <Button variant="danger" onClick={backToSearch}>
              {t("general.back")}
            </Button>
            <Button
              className="card-button"
              type="submit"
              style={{ marginLeft: "10px", marginRight: "55px" }}
            >
              {t("general.save")}
            </Button>
          </Card.Footer>

          <Spinner spinning={isLoadingRules} />
        </Form>
      </Card>

      <Spinner spinning={isPersisting} wrapper />
      <Messages afterHideSuccess={backToSearch} />
    </div>
  );
};

export default withNamespaces()(PersistRule);
