import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import PaperYear from "./PaperYear";
import PaperYearToRemove from "./PaperYearToRemove";
import { TbBeach } from "react-icons/tb";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

const YearCalendarDate = (props) => {
  const {
    dateToRender,
    dateOfMonth,
    events,
    onClick,
    setAllEvents,
    firstClickDate,
    setMousePosition,
    isEditable,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  let today =
    dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "today"
      : "";

  const handleMouseEnter = (event) => {
    if (firstClickDate && !isHovered) {
      const startDate = moment.min(firstClickDate, dateToRender);
      const endDate = moment.max(firstClickDate, dateToRender);

      const intervalDates = [];
      let currentDay = startDate.clone();
      while (currentDay.isSameOrBefore(endDate, "day")) {
        intervalDates.push(currentDay.format("YYYY-MM-DD"));
        currentDay.add(1, "day");
      }

      setIsHovered(true);

      // Criar um novo evento do tipo "hover" para todo o intervalo de datas
      const hoverEvents = intervalDates.map((date) => ({
        id: uuidv4(),
        start: moment(date).toDate(),
        end: moment(date).endOf("day").toDate(),
        type: "hover",
        title: "Hovered Day",
        color: "#44A49B",
      }));

      setAllEvents((prevEvents) => [...prevEvents, ...hoverEvents]);

      setAllEvents((prevEvents) =>
        prevEvents.map((event) =>
          intervalDates.includes(moment(event.start).format("YYYY-MM-DD"))
            ? { ...event, hover: true }
            : event
        )
      );
    }
    setMousePosition({ x: event.pageX, y: event.pageY });
  };
  const handleMouseLeave = () => {
    if (firstClickDate && isHovered) {
      setIsHovered(false);

      setAllEvents((prevEvents) => {
        // Filtra os eventos de "hover" para remover apenas aqueles do intervalo
        const updatedEvents = prevEvents.filter((event) =>
          event.type === "hover"
            ? moment(event.start).isBefore(firstClickDate) ||
              moment(event.start).isSameOrAfter(dateToRender)
            : true
        );

        return updatedEvents.map((event) =>
          event.hover ? { ...event, hover: false } : event
        );
      });
    } else {
      today =
        dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ? "today"
          : "";
    }
  };
  const dayOfWeek = moment(dateToRender).day();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

  if (isWeekend) {
    return (
      <button
        className="date in-month"
        style={{
          background: "rgba(226, 226, 226, 0.4)",
          color: "rgb(171, 171, 171)",
          // border: "2px solid rgb(171, 171, 171)",
        }}
      >
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  const matchedEvent = events.filter((eventDetail) =>
    dateToRender.isSame(moment(eventDetail.start), "day")
  );

  if (matchedEvent.length === 0) {
    matchedEvent.push(
      events.find((eventDetail) =>
        dateToRender.isSame(moment(eventDetail.start), "day")
      )
    );
  }

  function getButtonStyle(matchedEvent) {
    return matchedEvent[0]?.type === "feriasmarked" ||
      matchedEvent[0]?.type === "intervals" ||
      matchedEvent[0]?.type === "ferias"
      ? "1px"
      : "";
  }

  function getButtonTitle(matchedEvent) {
    if (matchedEvent.length > 1) {
      return matchedEvent.some((event) => event.name)
        ? matchedEvent.map((event) => event.name).join(",\n")
        : matchedEvent[0]?.name;
    }
    return "";
  }

  function renderIcon(matchedEvent) {
    if (
      matchedEvent.length === 1 &&
      [
        "feriasmarked",
        "ferias",
        "intervals",
        "datasMarcacoes",
        "feriasPendentes",
      ].includes(matchedEvent[0]?.type)
    ) {
      return (
        <TbBeach
          style={{
            fontSize: "small",
            alignSelf: "center",
            color: matchedEvent[0]?.color || "grey",
          }}
        />
      );
    } else if (matchedEvent.length > 1) {
      return (
        <TbBeach
          style={{
            fontSize: "small",
            alignSelf: "center",
            color: "grey",
          }}
        />
      );
    }
  }
  return (
    <Badge
      color="warning"
      badgeContent={matchedEvent.length}
      invisible={matchedEvent.length <= 1 || isEditable}
      variant="dot"
      classes="badgeYearCalendar"
    >
      <button
        className={`date in-month ${matchedEvent[0]?.type || today} ${
          matchedEvent[0]?.hover && matchedEvent[0]?.type == "hover"
            ? "highlighted"
            : ""
        }`}
        title={getButtonTitle(matchedEvent)}
        onClick={() => isEditable && onClick(dateToRender)}
        onMouseEnter={(e) => isEditable && handleMouseEnter(e)}
        onMouseLeave={() => isEditable && handleMouseLeave()}
        style={{ padding: getButtonStyle(matchedEvent) }}
      >
        {renderIcon(matchedEvent)}
        {dateToRender.date()}
      </button>
    </Badge>
  );
};

export default YearCalendarDate;
