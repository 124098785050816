export const GET_CRUD_COLLABORATORS = "collaborators@GET_CRUD_COLLABORATORS";
export const SET_CRUD_COLLABORATORS = "collaborators@SET_CRUD_COLLABORATORS";
export const POST_ADD_CRUD_COLLABORATORS =
  "collaborators@POST_ADD_CRUD_COLLABORATORS";
export const SET_MESSAGE_ADD_CRUD_COLLABORATORS =
  "collaborators@SET_MESSAGE_ADD_CRUD_COLLABORATORS";
export const DONE_GET_CRUD_COLLABORATORS =
  "collaborators@DONE_GET_CRUD_COLLABORATORS";

export const getCRUDCollaborators = () => ({
  type: GET_CRUD_COLLABORATORS,
});

export const doneGetCRUDCollaboratos = () => ({
  type: DONE_GET_CRUD_COLLABORATORS,
});

export const setCRUDCollaboratos = (collaborators_CRUD) => ({
  type: SET_CRUD_COLLABORATORS,
  collaborators_CRUD,
});

export const postADDCRUDCollaborators = (payload) => ({
  type: POST_ADD_CRUD_COLLABORATORS,
  payload,
});

export const setMessageADDCRUDCollaboratos = (collaborators_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_COLLABORATORS,
  collaborators_CRUD_ADD,
});

//CATEGORIES
export const GET_CRUD_CATEGORIES = "categories@GET_CRUD_CATEGORIES";
export const SET_CRUD_CATEGORIES = "categories@SET_CRUD_CATEGORIES";
export const DONE_GET_CRUD_CATEGORIES = "categories@DONE_GET_CRUD_CATEGORIES";
export const POST_ADD_CRUD_CATEGORIES = "categories@POST_ADD_CRUD_CATEGORIES";
export const POST_DELETE_CRUD_CATEGORIES =
  "categories@POST_DELETE_CRUD_CATEGORIES";
export const PUT_UPDATE_CRUD_CATEGORIES =
  "categories@PUT_UPDATE_CRUD_CATEGORIES";

export const SET_MESSAGE_ADD_CRUD_CATEGORIES =
  "categories@SET_MESSAGE_ADD_CRUD_CATEGORIES";

export const getCRUDCategories = () => ({
  type: GET_CRUD_CATEGORIES,
});

export const deleteCRUDCategorie = (id) => ({
  type: POST_DELETE_CRUD_CATEGORIES,
  id,
});

export const updateCRUDCategorie = (id, payload) => ({
  type: PUT_UPDATE_CRUD_CATEGORIES,
  id,
  payload,
});

export const doneGetCRUDCategories = () => ({
  type: DONE_GET_CRUD_CATEGORIES,
});

export const setCRUDCategories = (categories_CRUD) => ({
  type: SET_CRUD_CATEGORIES,
  categories_CRUD,
});

export const postADDCRUDCategories = (payload) => ({
  type: POST_ADD_CRUD_CATEGORIES,
  payload,
});

export const setMessageADDCRUDCategories = (categories_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_CATEGORIES,
  categories_CRUD_ADD,
});

//COMPANY
export const GET_CRUD_COMPANIES = "companies@GET_CRUD_COMPANIES";
export const DONE_GET_CRUD_COMPANIES = "companies@DONE_GET_CRUD_COMPANIES";
export const SET_CRUD_COMPANIES = "companies@SET_CRUD_COMPANIES";

export const getCRUDCompanies = () => ({
  type: GET_CRUD_COMPANIES,
});

export const doneGetCRUDCompanies = () => ({
  type: DONE_GET_CRUD_COMPANIES,
});

export const setCRUDCompanies = (companies_CRUD) => ({
  type: SET_CRUD_COMPANIES,
  companies_CRUD,
});

//DEPARTMENT
export const GET_CRUD_DEPARTMENT = "department@GET_CRUD_DEPARTMENT";
export const SET_CRUD_DEPARTMENT = "department@SET_CRUD_DEPARTMENT";
export const DONE_GET_CRUD_DEPARTMENT = "department@DONE_GET_CRUD_DEPARTMENT";
export const POST_ADD_CRUD_DEPARTMENT = "department@POST_ADD_CRUD_DEPARTMENT";
export const POST_DELETE_CRUD_DEPARTMENT =
  "department@POST_DELETE_CRUD_DEPARTMENT";
export const PUT_UPDATE_CRUD_DEPARTMENT =
  "department@PUT_UPDATE_CRUD_DEPARTMENT";

export const SET_MESSAGE_ADD_CRUD_DEPARTMENT =
  "department@SET_MESSAGE_ADD_CRUD_DEPARTMENT";

export const getCRUDDepartments = () => ({
  type: GET_CRUD_DEPARTMENT,
});

export const deleteCRUDDepartments = (id) => ({
  type: POST_DELETE_CRUD_DEPARTMENT,
  id,
});

export const updateCRUDDepartments = (id, payload) => ({
  type: PUT_UPDATE_CRUD_DEPARTMENT,
  id,
  payload,
});

export const doneGetCRUDDepartments = () => ({
  type: DONE_GET_CRUD_DEPARTMENT,
});

export const setCRUDDepartments = (departments_CRUD) => ({
  type: SET_CRUD_DEPARTMENT,
  departments_CRUD,
});

export const postADDCRUDDepartments = (payload) => ({
  type: POST_ADD_CRUD_DEPARTMENT,
  payload,
});

export const setMessageADDCRUDDepartments = (departments_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_DEPARTMENT,
  departments_CRUD_ADD,
});

//TEAMS
export const GET_CRUD_TEAM = "team@GET_CRUD_TEAM";
export const SET_CRUD_TEAM = "team@SET_CRUD_TEAM";
export const DONE_GET_CRUD_TEAM = "team@DONE_GET_CRUD_TEAM";
export const POST_ADD_CRUD_TEAM = "team@POST_ADD_CRUD_TEAM";
export const POST_DELETE_CRUD_TEAM = "team@POST_DELETE_CRUD_TEAM";
export const PUT_UPDATE_CRUD_TEAM = "team@PUT_UPDATE_CRUD_TEAM";
export const SET_MESSAGE_ADD_CRUD_TEAM = "team@SET_MESSAGE_ADD_CRUD_TEAM";

export const getCRUDTeams = () => ({
  type: GET_CRUD_TEAM,
});

export const deleteCRUDTeams = (id) => ({
  type: POST_DELETE_CRUD_TEAM,
  id,
});

export const updateCRUDTeams = (id, payload) => ({
  type: PUT_UPDATE_CRUD_TEAM,
  id,
  payload,
});

export const doneGetCRUDTeams = () => ({
  type: DONE_GET_CRUD_TEAM,
});

export const setCRUDTeams = (teams_CRUD) => ({
  type: SET_CRUD_TEAM,
  teams_CRUD,
});

export const postADDCRUDTeams = (payload) => ({
  type: POST_ADD_CRUD_TEAM,
  payload,
});

export const setMessageADDCRUDTeams = (teams_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_TEAM,
  teams_CRUD_ADD,
});

//PARTNERS
export const GET_CRUD_PARTNER = "partner@GET_CRUD_PARTNER";
export const SET_CRUD_PARTNER = "partner@SET_CRUD_PARTNER";
export const DONE_GET_CRUD_PARTNER = "partner@DONE_GET_CRUD_PARTNER";
export const POST_ADD_CRUD_PARTNER = "partner@POST_ADD_CRUD_PARTNER";
export const POST_DELETE_CRUD_PARTNER = "partner@POST_DELETE_CRUD_PARTNER";
export const PUT_UPDATE_CRUD_PARTNER = "partner@PUT_UPDATE_CRUD_PARTNER";
export const SET_MESSAGE_ADD_CRUD_PARTNER = "partner@SET_MESSAGE_ADD_CRUD_PARTNER";

export const getCRUDPartners = () => ({
  type: GET_CRUD_PARTNER,
});

export const deleteCRUDPartners = (id) => ({
  type: POST_DELETE_CRUD_PARTNER,
  id,
});

export const updateCRUDPartners = (id, payload) => ({
  type: PUT_UPDATE_CRUD_PARTNER,
  id,
  payload,
});

export const doneGetCRUDPartners = () => ({
  type: DONE_GET_CRUD_PARTNER,
});

export const setCRUDPartners = (partners_CRUD) => ({
  type: SET_CRUD_PARTNER,
  partners_CRUD,
});

export const postADDCRUDPartners = (payload) => ({
  type: POST_ADD_CRUD_PARTNER,
  payload,
});

export const setMessageADDCRUDPartners = (partners_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_PARTNER,
  partners_CRUD_ADD,
});

//EXPENSES
export const GET_CRUD_EXPENSE = "expense@GET_CRUD_EXPENSE";
export const SET_CRUD_EXPENSE = "expense@SET_CRUD_EXPENSE";
export const DONE_GET_CRUD_EXPENSE = "expense@DONE_GET_CRUD_EXPENSE";
export const POST_ADD_CRUD_EXPENSE = "expense@POST_ADD_CRUD_EXPENSE";
export const POST_DELETE_CRUD_EXPENSE = "expense@POST_DELETE_CRUD_EXPENSE";
export const PUT_UPDATE_CRUD_EXPENSE = "expense@PUT_UPDATE_CRUD_EXPENSE";
export const SET_MESSAGE_ADD_CRUD_EXPENSE = "expense@SET_MESSAGE_ADD_CRUD_EXPENSE";

export const getCRUDExpenses = () => ({
  type: GET_CRUD_EXPENSE,
});

export const deleteCRUDExpenses = (id) => ({
  type: POST_DELETE_CRUD_EXPENSE,
  id,
});

export const updateCRUDExpenses = (id, payload) => ({
  type: PUT_UPDATE_CRUD_EXPENSE,
  id,
  payload,
});

export const doneGetCRUDExpenses = () => ({
  type: DONE_GET_CRUD_EXPENSE,
});

export const setCRUDExpenses = (expenses_CRUD) => ({
  type: SET_CRUD_EXPENSE,
  expenses_CRUD,
});

export const postADDCRUDExpenses = (payload) => ({
  type: POST_ADD_CRUD_EXPENSE,
  payload,
});

export const setMessageADDCRUDExpenses = (expenses_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_EXPENSE,
  expenses_CRUD_ADD,
});

//CLIENT DIRECTORS
export const GET_CRUD_CLIENTDIRECTOR = "clientDirector@GET_CRUD_CLIENTDIRECTOR";
export const SET_CRUD_CLIENTDIRECTOR = "clientDirector@SET_CRUD_CLIENTDIRECTOR";
export const DONE_GET_CRUD_CLIENTDIRECTOR = "clientDirector@DONE_GET_CRUD_CLIENTDIRECTOR";
export const POST_ADD_CRUD_CLIENTDIRECTOR = "clientDirector@POST_ADD_CRUD_CLIENTDIRECTOR";
export const POST_DELETE_CRUD_CLIENTDIRECTOR = "clientDirector@POST_DELETE_CRUD_CLIENTDIRECTOR";
export const PUT_UPDATE_CRUD_CLIENTDIRECTOR = "clientDirector@PUT_UPDATE_CRUD_CLIENTDIRECTOR";
export const SET_MESSAGE_ADD_CRUD_CLIENTDIRECTOR = "clientDirector@SET_MESSAGE_ADD_CRUD_CLIENTDIRECTOR";

export const getCRUDClientDirectors = () => ({
  type: GET_CRUD_CLIENTDIRECTOR,
});

export const deleteCRUDClientDirectors = (id) => ({
  type: POST_DELETE_CRUD_CLIENTDIRECTOR,
  id,
});

export const updateCRUDClientDirectors = (id, payload) => ({
  type: PUT_UPDATE_CRUD_CLIENTDIRECTOR,
  id,
  payload,
});

export const doneGetCRUDClientDirectors = () => ({
  type: DONE_GET_CRUD_CLIENTDIRECTOR,
});

export const setCRUDClientDirectors = (clientDirectors_CRUD) => ({
  type: SET_CRUD_CLIENTDIRECTOR,
  clientDirectors_CRUD,
});

export const postADDCRUDClientDirectors = (payload) => ({
  type: POST_ADD_CRUD_CLIENTDIRECTOR,
  payload,
});

export const setMessageADDCRUDClientDirectors = (clientDirectors_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_CLIENTDIRECTOR,
  clientDirectors_CRUD_ADD,
});
//COLLABORATORS
export const GET_CRUD_COLLAB = "collab@GET_CRUD_COLLAB";
export const SET_CRUD_COLLAB = "collab@SET_CRUD_COLLAB";
export const DONE_GET_CRUD_COLLAB = "collab@DONE_GET_CRUD_COLLAB";
export const POST_ADD_CRUD_COLLAB = "collab@POST_ADD_CRUD_COLLAB";
export const POST_DELETE_CRUD_COLLAB = "collab@POST_DELETE_CRUD_COLLAB";
export const PUT_UPDATE_CRUD_COLLAB = "collab@PUT_UPDATE_CRUD_COLLAB";
export const SET_MESSAGE_ADD_CRUD_COLLAB = "collab@SET_MESSAGE_ADD_CRUD_COLLAB";

export const getCRUDCollabs = () => ({
  type: GET_CRUD_COLLAB,
});

export const deleteCRUDCollabs = (id) => ({
  type: POST_DELETE_CRUD_COLLAB,
  id,
});

export const updateCRUDCollabs = (id, payload) => ({
  type: PUT_UPDATE_CRUD_COLLAB,
  id,
  payload,
});

export const doneGetCRUDCollabs = () => ({
  type: DONE_GET_CRUD_COLLAB,
});

export const setCRUDCollabs = (collabs_CRUD) => ({
  type: SET_CRUD_COLLAB,
  collabs_CRUD,
});

export const postADDCRUDCollabs = (payload) => ({
  type: POST_ADD_CRUD_COLLAB,
  payload,
});

export const setMessageADDCRUDCollabs = (collabs_CRUD_ADD) => ({
  type: SET_MESSAGE_ADD_CRUD_COLLAB,
  collabs_CRUD_ADD,
});
