import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import "../../assets/css/icons.css";
import Chart from "chart.js";
import { pieChart } from "./DesempenhoBPMWidget/chartDetail.js";
import { withNamespaces } from "react-i18next";
import CustomDropdown from "./CustomDropdown";
import { updateUserWidget } from '~/pages/User/actions';

const Tasks = ({ apagarWidget,widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const myRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const myChartRef = myRef.current.getContext("2d");
    new Chart(myChartRef, pieChart);
  });

  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-tarefas2 mr-2" />
            {t("Tasks.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown apagarWidget={apagarWidget} handleMouseDown={handleMouseDown} />
        </Card.Header>
        {!isResizing && (<Card.Body>
          <div className="row">
            <div className="col-4">
              <canvas id="myPieChart" ref={myRef}></canvas>
            </div>
            <div className="col-4 text-left small">
              <p>
                <i className="icon-check mx-2 text-success"></i>
                {t("Tasks.completed")}
              </p>
              <p>
                <i className="icon-rounded-square mx-2 c-ontime"></i>
                {t("Tasks.withinTheTime")}
              </p>
              <p>
                <i className="icon-rounded-square mx-2 c-urgent"></i>
                {t("Tasks.urgent")}
              </p>
              <p>
                <i className="icon-rounded-square mx-2 c-expired"></i>
                {t("Tasks.expired")}
              </p>
            </div>
            <div className="col-4 text-center">
              <a href="#" className="mt-3">
                {t("Tasks.checkAllTasks")}
              </a>
              <br />
              <a href="#" className="mt-3">
                <i className="icon-add mr-1"></i>
                {t("Tasks.addTasks")}
              </a>
            </div>
          </div>
        </Card.Body>)}
      </Card>
    </div>
  );
};
export default withNamespaces()(Tasks);
