import React from "react";
import { useSelector } from "react-redux";
import { HiMiniCalendarDays } from "react-icons/hi2";
import { CgCalendarToday } from "react-icons/cg";
import { Button as ButtonBoot, Row, Col, Alert } from "react-bootstrap";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Dropdown as DropDownBoot } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import {
  FaCalendarDay,
  FaCalendarAlt,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaFileExport,
} from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import AlertTitle from "@mui/material/AlertTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import uuid from "uuid";

const Toolbar = (props) => {
  const {
    t,
    allEvents,
    handleSendDays,
    isEditable,
    blockIntervalSelections,
    handleSingleDaySelection,
    singleDaySelection,
    defaultView,
    hideChangeView,
    navigate,
    changeView,
    label,
    exportBtn,
    handleExport,
    handleRestartMarkup,
  } = props;
  const { isLoadingMarcacaoFerias, marcacaoFeriasCalendario } = useSelector(
    (state) => state.portalrhReducer
  );

  const ColorButtonArrow = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[500],
    },
  }));

  return (
    <>
      <div className="rbc-toolbar">
        <Row style={{ width: "100%", marginBottom: "20px" }}>
          {!isEditable && exportBtn && (
            <Col sm="12" md="6" lg="6" style={{ padding: "0px" }}>
              <DropDownBoot>
                <DropDownBoot.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "#4484f4",
                    borderColor: "#4484f4",
                    color: "white",
                  }}
                >
                  <FaFileExport style={{ paddingRight: "5px" }} />
                  {t("portalrh.workDays.export.btn")}
                </DropDownBoot.Toggle>

                <DropDownBoot.Menu>
                  {[
                    {
                      key: "screen",
                      label: "portalrh.workDays.export.item1",
                    },
                    { key: "year", label: "portalrh.workDays.export.item2" },
                    {
                      key: "criteria",
                      label: "portalrh.workDays.export.item3",
                    },
                  ].map((item) => (
                    <DropDownBoot.Item onClick={() => handleExport(item.key)}>
                      {t(item.label)}
                    </DropDownBoot.Item>
                  ))}
                </DropDownBoot.Menu>
              </DropDownBoot>
            </Col>
          )}
          <Col
            sm="12"
            md={exportBtn || !hideChangeView ? "6" : "12"}
            lg={exportBtn || !hideChangeView ? "6" : "12"}
            style={{ padding: "0px", display: "flex" }}
            className={hideChangeView ? "text-end" : ""}
          >
            <Col
              sm="12"
              lg={!hideChangeView ? "5" : "4"}
              md={!hideChangeView ? "5" : "4"}
              className="text-end"
              style={{ padding: "0px", textAlignLast: "left" }}
            >
              <ToggleButtonGroup
                value={defaultView}
                exclusive
                onChange={(event, value) => {
                  if (value === null) return;
                  changeView(value);
                }}
                aria-label="calendar view"
              >
                <ToggleButton value="month" aria-label="month">
                  <FaCalendarDay /> {t("portalrh.workDays.mes")}
                </ToggleButton>
                <ToggleButton value="year" aria-label="year">
                  <FaCalendarAlt /> {t("portalrh.workDays.ano")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
            <Col
              sm="12"
              lg={!hideChangeView ? "7" : "6"}
              md={!hideChangeView ? "7" : "6"}
              className="text-end"
              style={{ padding: "0px", textAlignLast: "left" }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: !isEditable ? "flex-end" : "space-between",
                  margin: "0 auto",
                  maxWidth: "100%",
                }}
              >
                <ColorButtonArrow
                  key="PREV"
                  size="small"
                  variant="text"
                  startIcon={<FaAngleDoubleLeft />}
                  onClick={() => navigate("PREV")}
                />
                <span className="rbc-toolbar-label" style={{ maxWidth: "15%" }}>
                  {defaultView !== "month" ? label.substring(0, 4) : label}
                </span>
                <ColorButtonArrow
                  key="NEXT"
                  size="small"
                  variant="text"
                  startIcon={<FaAngleDoubleRight />}
                  onClick={() => navigate("NEXT")}
                />
              </div>
            </Col>
          </Col>

          <Col
            lg="6"
            md="12"
            sm="12"
            className="text-end"
            style={{
              padding: "0px",
              display: "inline-flex",
              justifyContent: "right",
              gap: "1rem",
              alignItems: "baseline",
            }}
          >
            {!hideChangeView && defaultView !== "month" && (
              <>
                <InputLabel id="typeMarkup">Tipo de Marcação</InputLabel>
                <ToggleButtonGroup
                  value={singleDaySelection}
                  exclusive
                  onChange={handleSingleDaySelection}
                  aria-label="text alignment"
                >
                  <ToggleButton value="single" aria-label="Dia">
                    <CgCalendarToday /> Dia
                  </ToggleButton>
                  <ToggleButton
                    value="interval"
                    aria-label="Intervalo"
                    disabled={blockIntervalSelections}
                  >
                    <HiMiniCalendarDays /> Intervalo
                  </ToggleButton>
                </ToggleButtonGroup>
              </>
            )}
            {isEditable && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  margin: "5px",
                }}
              >
                <ButtonBoot
                  className="card-button"
                  onClick={handleSendDays}
                  style={{ color: "white" }}
                  disabled={
                    allEvents.filter((event) => event.type === "feriasmarked")
                      .length <= 0
                  }
                >
                  {t("portalrh.workDays.newMark")}
                </ButtonBoot>

                {allEvents.filter(
                  (event) =>
                    event.type === "feriasmarked" ||
                    event.type === "hover" ||
                    event.type === "intervals"
                ).length > 0 && (
                  <ButtonBoot
                    className="card-button-red active"
                    onClick={handleRestartMarkup}
                  >
                    {t("portalrh.workDays.cancel")}
                  </ButtonBoot>
                )}
              </div>
            )}
          </Col>
        </Row>
        {isEditable && (
          <Row style={{ width: "100%", marginBottom: "20px" }}>
            <Col sm="12" md="12" lg="12" style={{ padding: "0px" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panelfirst"
                  id="panelfirst"
                >
                  Situação
                </AccordionSummary>
                <AccordionDetails className="scrollBar-autorizersDateInformation">
                  {!isLoadingMarcacaoFerias &&
                  Object.keys(marcacaoFeriasCalendario).length > 0 &&
                  marcacaoFeriasCalendario?.situacao.length > 0 ? (
                    marcacaoFeriasCalendario?.situacao.map((item, index) => (
                      <Alert
                        key={uuid}
                        style={{ margin: "0px", display: "grid" }}
                      >
                        <AlertTitle>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              {t("portalrh.workDays.saldoAtual")} -{" "}
                              {marcacaoFeriasCalendario.saldoPendente}
                            </span>
                            {/* <span>
                                {t("portalrh.workDays.entityCode")} -{" "}
                                {item.codigoEntidade}
                              </span>
                              <span>
                                {t("portalrh.workDays.name")} - {item.nome}
                              </span> */}
                          </div>
                        </AlertTitle>{" "}
                        <span style={{ fontSize: "small" }}>
                          {t("portalrh.workDays.feriasDays")} -{" "}
                          {item.diasFerias}
                        </span>
                        <span style={{ fontSize: "small" }}>
                          {" "}
                          {t("portalrh.workDays.lastYearDays")} -{" "}
                          {item.diasPorGozarAnoAnt}{" "}
                        </span>
                        <span style={{ fontSize: "small" }}>
                          {t("portalrh.workDays.entity")} - {item.entidade}
                        </span>
                      </Alert>
                    ))
                  ) : (
                    <Alert style={{ margin: "0px", display: "grid" }}>
                      <span style={{ fontSize: "small" }}>
                        {t("portalrh.workDays.feriasDays")} - 0
                      </span>
                      <span style={{ fontSize: "small" }}>
                        {" "}
                        {t("portalrh.workDays.lastYearDays")} - 0
                      </span>
                    </Alert>
                  )}
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Toolbar;
