import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import DocumentTable from "./DocumentTable";
import DocumentTableRHAdmin from "./DocumentTableRHAdmin";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import NavTabs from "../NavTabs";
import "~/assets/css/icons.css";
import Spinner from "~/components/Spinner";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";

import { useDispatch, useSelector } from "react-redux";
import {
  getDocuments,
  getCollaborators,
} from "~/store/ducks/portalrh/actionTypes";

import { getDocumentsbyUsername } from "~/store/ducks/portalrh/actionTypes";

const DocumentManagement = ({
  t,
  manager,
  portalRhMenus,
  activeSelected,
  designacao,
  personalArea,
}) => {
  const { collaborators } = useSelector((state) => state.portalrhReducer);

  const dispatch = useDispatch();
  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleMenu, setTitleMenu] = useState("");
  const [colaboradorID, setColaboradorID] = useState(null);

  useEffect(() => {
    setMenus({});

    if (collaborators.length === 0) {
      dispatch(getCollaborators());
      return;
    }
    let tempcolaboradorSelected = undefined;
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
    setTitleMenu(t("portalrh.documentManagement.title"));
    if (designacao === "Colaboradores") {
      var iframe = document.getElementById("portalrh");
      var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      var titleMenuElement = iframeDoc.getElementsByClassName("title-menu");
      var titleMenuElementID = iframeDoc.getElementsByClassName("title-menuid");
      if (
        titleMenuElementID[0] != undefined &&
        titleMenuElement[0] != undefined
      ) {
        setColaboradorID(titleMenuElementID[0].outerText);
        setTitleMenu(titleMenuElement[0].outerText);
        collaborators.forEach((collaboratorItem) => {
          if (collaboratorItem.id === +titleMenuElementID[0].outerText) {
            tempcolaboradorSelected = collaboratorItem.username;
          }
        });
      }
    }
    if (tempcolaboradorSelected != undefined) {
      dispatch(getDocumentsbyUsername(tempcolaboradorSelected));
    } else {
      dispatch(getDocuments());
    }
  }, [collaborators]);
  return (
    <>
      {isLoading ? (
        <Spinner spinning={isLoading} transparent={false} />
      ) : (
        <div className="main-card-v2">
          <Card
            bsPrefix="card-flat"
            style={{ minHeight: "80vh", overflow: "auto" }}
          >
            <Card.Header className="justify-content-between">
              {manager || personalArea ? (
                <h6>{titleMenu}</h6>
              ) : (
                <NavTabs
                  titleMenu={titleMenu}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
              )}
            </Card.Header>
            <Card.Body>
              {!colaboradorID ? (
                <DocumentTable manager={manager} />
              ) : (
                <DocumentTableRHAdmin
                  manager={manager}
                  colaboradorID={colaboradorID}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};
export default withNamespaces()(DocumentManagement);
