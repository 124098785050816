import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { getSaml, getAtributes } from "~/store/ducks/onboarding/actionTypes";

/**
 * Chave Movel Digital
 *
 * This service tests the data extraction in Chave Movel Digital
 * 
 * Atributes:
 *  -   Integer NomeProprio; Integer NomeApelido; Integer DataNascimento; Integer NomeCompleto; Integer NIF; Integer NISS; Integer NSNS; Integer NIFCifrado; Integer NISSCifrado;  Integer NICCifrado; Integer NSNSCifrado; Integer DataValidadeDoc; Integer Nacionalidade; Integer CorreioElectronico; Integer DocType; Integer DocNationality; Integer DocNumber; Integer DataValidade; Integer Passport; Integer Altura; Integer Assinatura; Integer ContactosXML; Integer Foto;  Integer IndicativoTelefoneMovel; Integer NoDocumento; Integer NomeApelidoMae; Integer NomeApelidoPai; Integer NomeProprioMae; Integer NomeProprioPai; Integer NumeroTelefoneMovel; Integer Sexo; Integer MoradaXML;
        Integer NumeroTelemovel;  Integer CodigoNacionalidade;  Integer TemAssinaturaDigitalCMD;  String url;  
 *       
 * NOTE: String url - redirect url to receive the id of saved data extraction ex: 'https://unikint.uniksystem.com/#/uniksystem?id='
 *    the page it self needs to be prepared to receive the query param, extract it and make a call to get the data associated
 *    with that id
 */

const Test = ({ t }) => {
  //This list creates Radio buttons dynamically as well as the payload for getSaml request
  const listOfAtributes = [
    { value: "NIC", label: "NIC" },
    { value: "NomeProprio", label: "Nome Proprio" },
    { value: "NomeApelido", label: "Nome Apelido" },
    { value: "DataNascimento", label: "Data Nascimento" },
    { value: "NomeCompleto", label: "Nome Completo" },
    { value: "NIF", label: "NIF" },
    { value: "NISS", label: "NISS" },
    { value: "NSNS", label: "NSNS" },
    { value: "NIFCifrado", label: "NIF Cifrado" },
    { value: "NISSCifrado", label: "NISS Cifrado" },
    { value: "NICCifrado", label: "NIC Cifrado" },
    { value: "NSNSCifrado", label: "NSNS Cifrado" },
    { value: "DataValidadeDoc", label: "Data Validade Doc" },
    { value: "Nacionalidade", label: "Nacionalidade" },
    { value: "CorreioElectronico", label: "Correio Electronico" },
    { value: "DocType", label: "Doc Type" },
    { value: "DocNationality", label: "Doc Nationality" },
    { value: "DocNumber", label: "Doc Number" },
    { value: "DataValidade", label: "Data Validade" },
    { value: "Passport", label: "Passport" },
    { value: "Altura", label: "Altura" },
    { value: "Assinatura", label: "Assinatura" },
    { value: "ContactosXML", label: "Contactos XML" },
    { value: "Foto", label: "Foto" },
    { value: "IndicativoTelefoneMovel", label: "Indicativo Telefone Movel" },
    { value: "NoDocumento", label: "Nº Documento" },
    { value: "NomeApelidoMae", label: "Nome Apelido Mae" },
    { value: "NomeApelidoPai", label: "Nome Apelido Pai" },
    { value: "NomeProprioMae", label: "Nome Proprio Mae" },
    { value: "NomeProprioPai", label: "Nome Proprio Pai" },
    { value: "NumeroTelefoneMovel", label: "Numero Telefone Movel" },
    { value: "Sexo", label: "Sexo" },
    { value: "MoradaXML", label: "Morada XML" },
    { value: "NumeroTelemovel", label: "Numero Telemovel" },
    { value: "CodigoNacionalidade", label: "Codigo Nacionalidade" },
    { value: "TemAssinaturaDigitalCMD", label: "Tem Assinatura Digital CMD" },
  ];

  let payload = { url: `${window.location.href}?cmd-user-id=` };

  const dispatch = useDispatch();

  const { isLoadingGetSaml, getSamlResponse, getAtributesResponse } =
    useSelector((state) => state.onboardingReducer);

  const { user } = useSelector((state) => state.globalReducer);

  const [output, setOutput] = useState("");
  const [toggleAll, setToggleAll] = useState(false);

  useEffect(() => {
    if (getSamlResponse) {
      document.getElementById("submitSamlReqBtnId").click();
    }
  }, [getSamlResponse]);

  useEffect(() => {
    if (window.location.href.includes("?cmd-user-id=")) {
      if (window.location.href.split("?cmd-user-id=")[1] !== "") {
        dispatch(
          getAtributes({
            id: window.location.href.split("?cmd-user-id=")[1],
          })
        );
      }
      window.location.href = window.location.href.split("?cmd-user-id=")[0];
    }
  }, []);

  useEffect(() => {
    if (Object.keys(getAtributesResponse).length > 0) {
      setOutput(JSON.stringify(getAtributesResponse, null, 2));
    }
  }, [getAtributesResponse]);

  useEffect(() => {
    toggleAll
      ? updateAllAtributes(1, payload)
      : updateAllAtributes(-1, payload);
  }, [toggleAll]);

  const testChaveMovelDigital = () => {
    if (Object.keys(payload).length > 1) {
      dispatch(getSaml(payload));
    } else {
      toast.error("❌ " + t("onboarding.general.needAtributes"), {
        toastId: "2",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updatePayload = (list, label, value) => {
    if (value === -1) {
      delete list[label];
    } else {
      list[label] = value;
    }
  };

  const updateAllAtributes = (value, list) => {
    if (value === 1) {
      listOfAtributes.forEach((atr) => {
        list[atr.value] = value;
        document.getElementById(`${atr.value}-0`).checked = true;
      });
    } else {
      listOfAtributes.forEach((atr) => {
        delete list[atr.value];
        document.getElementById(`${atr.value}-2`).checked = true;
      });
    }
  };

  const renderCheckList = (payload) => {
    return listOfAtributes.map((atr, i) => {
      return (
        <div
          style={{ paddingBottom: "20px" }}
          key={`chave-movel-atribute-${i}`}
        >
          <label className="form label" style={{ paddingRight: "30px" }}>
            {atr.label}
          </label>
          <div>
            <Form.Check
              inline
              label={t("onboarding.general.obligatory")}
              name={`group-cmd-${i}`}
              type="radio"
              id={`${atr.value}-0`}
              style={{ paddingRight: "15px" }}
              onChange={(e) => {
                e.target.value === "on" && updatePayload(payload, atr.value, 1);
              }}
            />
            <Form.Check
              inline
              label={t("onboarding.general.optional")}
              name={`group-cmd-${i}`}
              type="radio"
              id={`${atr.value}-1`}
              style={{ paddingRight: "15px" }}
              onChange={(e) => {
                e.target.value === "on" && updatePayload(payload, atr.value, 0);
              }}
            />
            <Form.Check
              inline
              label={t("onboarding.general.notNeeded")}
              name={`group-cmd-${i}`}
              type="radio"
              id={`${atr.value}-2`}
              style={{ paddingRight: "15px" }}
              defaultChecked={true}
              onChange={(e) => {
                e.target.value === "on" &&
                  updatePayload(payload, atr.value, -1);
              }}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="onboarding-execute-form" id="SAMLRequestId">
      <ToastContainer />
      <div style={{ display: "flex" }}>
        <Col>
          <h3 className="onboarding-form-header">
            {t("onboarding.general.test")}
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <Form.Check
              type="switch"
              id={"switch-id-cmd"}
              label={t("onboarding.general.toggleAll")}
              value={toggleAll}
              onChange={() => setToggleAll(!toggleAll)}
            />
          </div>
          <div style={{ padding: "20px" }}>
            <Form.Group controlId="formBasicCheckbox">
              {renderCheckList(payload)}
            </Form.Group>
          </div>

          <Button
            disabled={!user?.organization?.id}
            className="card-button onboarding-button"
            onClick={() => {
              testChaveMovelDigital();
            }}
          >
            {isLoadingGetSaml ? (
              <>
                {t("onboarding.general.loading")}
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              t("onboarding.general.test")
            )}
          </Button>
        </Col>
        <Col style={{ transform: "translateY(-25px)" }}>
          <Output output={output} />
        </Col>
      </div>

      <form
        id="samlFormId"
        action="https://preprod.autenticacao.gov.pt/fa/Default.aspx"
        method="POST"
      >
        <input type="hidden" name="SAMLRequest" value={getSamlResponse} />
        <input
          type="submit"
          id="submitSamlReqBtnId"
          style={{ display: "none" }}
        />
      </form>
    </div>
  );
};
export default withNamespaces()(Test);
