export const SET_USAGE_REPORT = "reports@SET_USAGE_REPORT";
export const FIND_USAGE_REPORT = "reports@FIND_USAGE_REPORT";
export const GET_REPORT_TO_EXTRACT = "reports@GET_REPORT_TO_EXTRACT";
export const SET_REPORT_TO_EXTRACT = "reports@SET_REPORT_TO_EXTRACT";
export const SUCCESS_DOWNLOAD_REPORT_EXTRACT =
  "reports@SUCCESS_DOWNLOAD_REPORT_EXTRACT";
export const BUTTON_BACKEND_CALL = "reports@BUTTON_BACKEND_CALL";

export const setUsageReport = (report) => {
  return { type: SET_USAGE_REPORT, data: report };
};
export const findUsageReport = () => ({ type: FIND_USAGE_REPORT });
export const getReportToExtract = (payload, selectedItemKey, download) => ({
  type: GET_REPORT_TO_EXTRACT,
  payload,
  selectedItemKey,
  download,
});

export const buttonBackendCall = (method, url) => {
  return { type: BUTTON_BACKEND_CALL, method, url };
};

export const setReportToExtract = (reports) => ({
  type: SET_REPORT_TO_EXTRACT,
  reports,
});
export const setSuccessDownloadReportExtract = (value) => ({
  type: SUCCESS_DOWNLOAD_REPORT_EXTRACT,
  value,
});
