import React from "react";
import { withNamespaces } from "react-i18next";
import Link from "~/pages/Easypay/components/Link";
import "../assets/scss/_checkbox.scss";

const Termos = ({
  disabled = false,
  setCheckboxChecked,
  checkboxChecked,
  t,
}) => {
  return (
    <>
      <div style={{ paddingBottom: "20px" }}>
        <label className="easypay-checkbox">
          <input
            className="easypay-checkbox-input"
            type="checkbox"
            disabled={disabled}
            id={"novo-id-checkbox"}
            name={"name-default"}
          />
          <span
            className={
              disabled ? "easypay-checkmark disabled" : "easypay-checkmark"
            }
            disabled={disabled}
          ></span>
        </label>

        <div style={{ paddingLeft: "30px", paddingTop: "7px" }}>
          <div className="easypay-card-text-no-padding">
            {t("bpm.onboarding.user.checkBoxTerms1")}{" "}
            <Link text={t("bpm.onboarding.user.checkBoxTerms2")} link="" />
            {t("bpm.onboarding.user.checkBoxTerms3")}{" "}
            <Link text={t("bpm.onboarding.user.checkBoxTerms4")} link="" />{" "}
            {t("bpm.onboarding.user.checkBoxTerms5")}
          </div>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Termos);
