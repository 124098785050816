import axios from 'axios';
import qs from "qs";
import urljoin from "url-join";

import {
  UNIKSYSTEM_BACKEND,
  BACKEND_SERVER_URL,
  AUTH_HEADER,
  USERNAME,
  PASSWORD,
  JSON_HEADER,
  GRANT_TYPE_REFRESH_TOKEN,
} from "../constants";

import oauth from '../oauth';
const devLogConsole = require("~/utils/devLog");
const getAxiosInstance = () => (
  process.env.NODE_ENV === 'development' ?
    axios.create({
      baseURL: BACKEND_SERVER_URL(),
    }) :
    axios.create()
)

const http = getAxiosInstance();

/**
 * Interceptor for all requests
 */
http.interceptors.request.use((config) => {
  setURL(config);

  if (config.url.includes('open')) {
    return config;
  } else if (config.url.includes('oauth')) {
    config.headers = AUTH_HEADER;
    config.auth = {
      username: USERNAME,
      password: PASSWORD
    }
  } else if (config.url.includes('authenticate')) {
    config.headers = JSON_HEADER;
  } else {
    const accessToken = oauth.getAccessToken();
    config.headers = JSON_HEADER;
    if(!config.url.includes('AuthenticationServlet'))
      config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
}, (error) => {
  /**
   * Add your error handlers here
   */
  return Promise.reject(error);
});

let isRefreshing = false;
let subscribers = [];

/**
 * Interceptor for all responses
 */
http.interceptors.response.use(null, (error) => {
  const { config, response: { data, status } } = error;
  const originalRequest = config;

  if (status === 401 && data?.error === "invalid_token" && !isRefreshing) {
    devLogConsole('[INFO]: Refreshing token...')
    isRefreshing = true;
    const data = qs.stringify({
      'grant_type': GRANT_TYPE_REFRESH_TOKEN,
      'refresh_token': oauth.getRefeshToken()
    })

    http.post('/oauth/token', data)
      .then(res => {
        devLogConsole('[INFO]: Token refreshed!');
        isRefreshing = false;
        if (res.status === 200) {
          const { access_token, refresh_token, expires_in } = res.data;
          devLogConsole('refresh_token', refresh_token)
          oauth.setToken(
            {
              access_token,
              refresh_token,
              expires_in
            },
            true
          );
          onRefreshed(access_token);
          subscribers = [];
        }
      });

    const requestSubscribers = new Promise(resolve => {
      subscribeTokenRefresh(token => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(axios(originalRequest));
      });
    });
    return requestSubscribers;
  }

  return Promise.reject(error);
});

function setURL(config) {
  const url = config.context ?
    urljoin(config.context, config.url) :
    urljoin(UNIKSYSTEM_BACKEND.context, config.url)
  config.url = url;
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map(cb => cb(token));
}

export default http;
