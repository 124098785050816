import React, { useState, useEffect } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Button as ButtonMaterial } from "@mui/material";
import { withNamespaces } from "react-i18next";

const Legend = ({ t }) => {
  return (
    <ButtonMaterial
      size="small"
      variant="text"
      style={{
        pointerEvents: "none",
        padding: "0px 0px 0px 10px",
        color: "#4C4B4B",
      }}
    >
      {" "}
      <CircleIcon style={{ margin: "0px 5px", color: "#F8B4B4" }} />
      {t("portalrh.scheduler.diasFeriados")}
    </ButtonMaterial>
  );
};

export default withNamespaces()(Legend);
