export const GET_TASKS = 'GET_TASKS';
export const SET_TASKS = 'SET_TASKS';
export const FIND_TASKS_BY_FILTERS = 'FIND_TASKS_BY_FILTERS';

export const getTasks = (username, application) => {
  return { type: GET_TASKS, data: { username, application } }
}

export const setTasks = (tasks) => {
  return { type: SET_TASKS, data: tasks }
}

export const findTasksByFilters = (username, application, nProcess, startAt, endAt, PROCESS_STATUS) => {
  return { type: FIND_TASKS_BY_FILTERS, data: { username, application, nProcess, startAt, endAt, PROCESS_STATUS } };
}

