export const GET_RULES = "GET_RULES";
export const SET_RULES = "SET_RULES";
export const CLEAR_RULES = "CLEAR_RULES";
export const FIND_USERS_BY_RULE = "FIND_USERS_BY_RULE";
export const SET_FIND_USERS_BY_RULE = "SET_FIND_USERS_BY_RULE";
export const GET_RULE_INFORMATION_BY_ID = "GET_RULE_INFORMATION_BY_ID";
export const SET_RULE_INFORMATION_BY_ID = "SET_RULE_INFORMATION_BY_ID";
export const UPDATE_UNIK_USERS_RULE = "RULE@UPDATE_UNIK_USERS_RULE";
export const UPDATE_UNIK_USERS_DONE_RULE = "RULE@UPDATE_UNIK_USERS_DONE_RULE";
export const PERSIST_RULE = "RULE@PERSIST_RULE";
export const PERSIST_RULE_DONE = "RULE@PERSIST_RULE_DONE";
export const DELETE_RULE = "RULE@DELETE_RULE";
export const DELETE_RULE_DONE = "RULE@DELETE_RULE_DONE";


export const getRules = () => ({ type: GET_RULES });
export const setRules = (rules) => ({ type: SET_RULES, rules });

export const clearRules = () => ({ type: CLEAR_RULES });

export const getRuleInformationById = (ruleId) => ({
  type: GET_RULE_INFORMATION_BY_ID,
  ruleId,
});
export const setRuleInformationById = (rule) => ({
  type: SET_RULE_INFORMATION_BY_ID,
  rule,
});

export const updateUnikUsersRule = (roleUsers) => ({
  type: UPDATE_UNIK_USERS_RULE,
  roleUsers,
});
export const updateUnikUsersRuleDone = () => ({ type: UPDATE_UNIK_USERS_DONE_RULE });

export const persistRule = (rule) => ({ type: PERSIST_RULE, rule });
export const persistRuleDone = (success) => ({ type: PERSIST_RULE_DONE, success });

export const findUsersByRule = (id) => ({ type: FIND_USERS_BY_RULE, id });
export const setFindByRule = (users) => ({ type: SET_FIND_USERS_BY_RULE, users });

export const deleteRule = (id) => ({ type: DELETE_RULE, id });
export const deleteRuleDone = () => ({ type: DELETE_RULE_DONE });