export const GET_API_SETTINGS = 'onboarding@GET_API_SETTINGS';
export const SET_API_SETTINGS = 'onboarding@SET_API_SETTINGS';
export const DONE_GET_API_SETTINGS = 'onboarding@DONE_GET_API_SETTINGS';
export const PERSIST_API_SETTINGS = 'onboarding@PERSIST_API_SETTINGS';
export const DONE_PERSIST_API_SETTINGS = 'onboarding@DONE_PERSIST_API_SETTINGS';

export const getAPISettings = (currentSettings) => ({ type: GET_API_SETTINGS, currentSettings })
export const setAPISettings = (settings) => ({ type: SET_API_SETTINGS, settings })
export const doneGetAPISettings = () => ({ type: DONE_GET_API_SETTINGS });
export const persistAPISettings = (payload) => ({ type: PERSIST_API_SETTINGS, payload })
export const donePersistAPISettings = () => ({ type: DONE_PERSIST_API_SETTINGS });