import React, {useEffect} from "react";
import { withNamespaces } from "react-i18next";
import keycloak from "~/assets/img/icons/keycloak.png";

const KeycloakAuthentication = (props)  => {
 
const { click, auto } = props; 

useEffect(() => {
  if(auto) {
    click();
  }
}, [auto])

 return (
    <button className="btn-keycloak" onClick={() => click()}>
      <div style={{ display: "flex" }}>
        <div>
            <img className="logo-keycloak" alt="keycloak" src={keycloak}/>
        </div>
        <div style={{flexGrow: "1", justifyContent: "center", alignItems: "center"}}>
          Keycloak
        </div>
      </div>
    </button>
);
};


export default withNamespaces()(KeycloakAuthentication);