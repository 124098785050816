import React, { useState } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "~/assets/css/styles.css";
import RhAcademicQualifications from "./RhAcademicQualifications";
import RhAddress from "./RhAddress";
import { withNamespaces } from "react-i18next";
import RhHousehold from "./RhHousehold";
import RhMedicalRecord from "./RhMedicalRecord";
import RhPaymentsData from "./RhPaymentsData";
import RhPerformanceEvForm from "./RhPerfomanceEvForm";
import RhPersonalData from "./RhPersonalData";
import RhTaxData from "./RhTaxData";
import RhTrainingSheet from "./RhTrainingSheet";
import Hamburguer from "./MenuHamburguer";
import Media from "react-media";
import Spinner from "~/components/Spinner";
import DocumentManagement from "~/pages/PortalRH/components/DocumentManagement/DocumentTable";

const RhNavBar = ({ rowCollaborator, onBoleanBackButton, t }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  // const [cleanInputs, setCleanInouts] = useState(false);

  const { isLoadingCollaborators } = useSelector(
    (state) => state.personalReducer
  );

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const handleButtonBack = () => {
    onBoleanBackButton(false);
  };

  return (
    <div>
      <>
        <Spinner spinning={isLoadingCollaborators === true} />
        <div className="navigation">
          <div className="hamburguer1">
            <div className="hamburguer" onClick={toggleHamburger}>
              <Hamburguer isOpen={hamburgerOpen} />
            </div>
          </div>
          <Media query={{ maxWidth: 810 }}>
            {(matches) =>
              matches ? (
                <>
                  <div className="collaboratoRH__Button">
                    <h5 className="collaboratorRH__name">
                      {rowCollaborator.nome}
                    </h5>
                    <Button className="card-button" onClick={handleButtonBack}>
                      {t(`portalrh.button.back`)}
                    </Button>
                  </div>
                  <Tabs
                    defaultActiveKey="RhPersonalData"
                    // id="uncontrolled-tab-example"
                    className="custom-tabs"
                    style={{
                      display: hamburgerOpen ? "flex" : "none",
                      marginBottom: 25,
                    }}
                  >
                    <Tab
                      eventKey="RhPersonalData"
                      title={t(`portalrh.NavBar.personalData`)}
                    >
                      <RhPersonalData
                        rowCollaborator={rowCollaborator}
                        // cleanInputs={cleanInputs}
                      />
                    </Tab>
                    <Tab
                      eventKey="RhAddress"
                      title={t(`portalrh.NavBar.Address`)}
                    >
                      <RhAddress rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhAcademicQualifications"
                      title={t(`portalrh.NavBar.AcademicQualifications`)}
                    >
                      <RhAcademicQualifications
                        rowCollaborator={rowCollaborator}
                      />
                    </Tab>
                    <Tab
                      eventKey="RhTaxData"
                      title={t(`portalrh.NavBar.TaxData`)}
                    >
                      <RhTaxData rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhHousehold"
                      title={t(`portalrh.NavBar.HouseHold`)}
                    >
                      <RhHousehold rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhPaymentsData"
                      title={t(`portalrh.NavBar.paymentsData`)}
                    >
                      <RhPaymentsData rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhTraining Sheet"
                      title={t(`portalrh.NavBar.trainingSheet`)}
                    >
                      <RhTrainingSheet rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhPerformanceEvForm"
                      title={t(`portalrh.NavBar.PerformanceEvForm`)}
                    >
                      <RhPerformanceEvForm rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhMedicalRecord"
                      title={t(`portalrh.NavBar.MedicalRecord`)}
                    >
                      <RhMedicalRecord rowCollaborator={rowCollaborator} />
                    </Tab>

                    <Tab
                      eventKey="DocumentsManagement"
                      title={t(`portalrh.NavBar.Documents`)}
                    >
                      <DocumentManagement manager={true} />
                    </Tab>
                  </Tabs>
                </>
              ) : (
                <>
                  <div className="collaboratoRH__Button">
                    <h5 className="collaboratorRH__name">
                      {rowCollaborator.nome}
                    </h5>
                    <Button className="card-button" onClick={handleButtonBack}>
                      {t(`portalrh.button.back`)}
                    </Button>
                  </div>
                  <Tabs
                    // s
                    defaultActiveKey="RhPersonalData"
                    // id="uncontrolled-tab-example"
                    className="custom-tabs"
                    style={{ marginBottom: 25 }}
                  >
                    <Tab
                      eventKey="RhPersonalData"
                      title={t(`portalrh.NavBar.personalData`)}
                    >
                      <RhPersonalData
                        rowCollaborator={rowCollaborator}
                        // cleanInputs={cleanInputs}
                      />
                    </Tab>
                    <Tab
                      eventKey="RhAddress"
                      title={t(`portalrh.NavBar.Address`)}
                    >
                      <RhAddress rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhAcademicQualifications"
                      title={t(`portalrh.NavBar.AcademicQualifications`)}
                    >
                      <RhAcademicQualifications
                        rowCollaborator={rowCollaborator}
                      />
                    </Tab>
                    <Tab
                      eventKey="RhTaxData"
                      title={t(`portalrh.NavBar.TaxData`)}
                    >
                      <RhTaxData rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhHousehold"
                      title={t(`portalrh.NavBar.HouseHold`)}
                    >
                      <RhHousehold rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhPaymentsData"
                      title={t(`portalrh.NavBar.paymentsData`)}
                    >
                      <RhPaymentsData rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhTraining Sheet"
                      title={t(`portalrh.NavBar.trainingSheet`)}
                    >
                      <RhTrainingSheet rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhPerformanceEvForm"
                      title={t(`portalrh.NavBar.PerformanceEvForm`)}
                    >
                      <RhPerformanceEvForm rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhMedicalRecord"
                      title={t(`portalrh.NavBar.MedicalRecord`)}
                    >
                      <RhMedicalRecord rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="DocumentsManagement"
                      title={t(`portalrh.NavBar.Documents`)}
                    >
                      <DocumentManagement manager={true} />
                    </Tab>
                  </Tabs>
                </>
              )
            }
          </Media>
        </div>
      </>
    </div>
  );
};
export default withNamespaces()(RhNavBar);
