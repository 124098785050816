import React, { useEffect, useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_option.scss";
import { useDispatch, useSelector } from "react-redux";
import TableTextbox from "./TableTextbox";

const ProposalSuppliersTable = ({ info, workplanIndex , percentage,initialSuppliers,newTotalSuppliers}) => {
  const dispatch = useDispatch();

  const { workplans, providers, providerTypes, departments, triggerLoading, } = useSelector(
    (state) => state.budgetManagementReducer
  );
  var [currentSuppliersList, setCurrentSuppliersList] = useState([]);

  var [totalPC, setTotalPC] = useState(0);
  var [totalMargemSem, setTotalMargemSem] = useState(0);
  var [totalPV, setTotalPV] = useState(0);
  var [totalVV, setTotalVV] = useState(0);
  var [totalMargem, setTotalMargem] = useState(0);

  var currentPrecoCompra = 0;
  var currentMargemSem = 0;
  var currentPrecoVenda = 0;
  var currentValorVenda = 0;
  var currentMargem = 0;

  function convertIdToName(id, name) {
    if (name === "providers") {
      if (
        providers?.length === 0 ||
        providers === null ||
        providers === undefined
      )
        return "";
      const prov = providers.find((x) => x.idFornecedor === parseInt(id));
      if (prov != null) return prov?.nome;
    } else if (name === "providerTypes") {
      const provType = providerTypes.find(
        (x) => x.idTipoFornecedor === parseInt(id)
      );
      if (provType != null) return provType?.nome;
    } else if (name === "departments") {
      if (
        departments?.length === 0 ||
        departments === null ||
        departments === undefined
      )
        return "";
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
  }

  useEffect(() => {
    var newSuppliersArray = [];
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                .fornecedores.length;
              m++
            ) {
              var currentSuppliers =
              global.structuredClone( workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores[m]);
              currentSuppliers.item = i + 1;
              currentSuppliers.activity = j + 1;
              currentSuppliers.option = k + 1;
              if(newTotalSuppliers != null && newTotalSuppliers != 0 && newTotalSuppliers != "" && newTotalSuppliers != "0")
              {
                const newPercentage = newTotalSuppliers/initialSuppliers
                currentSuppliers.valorVendaForn = currentSuppliers.valorVendaForn * newPercentage
                currentSuppliers.precoVendaForn = currentSuppliers.valorVendaForn
                currentSuppliers.precoCompraForn = currentSuppliers.valorVendaForn * (1 - (currentSuppliers.margemForn/100))
              }
              else if(percentage != null && percentage != 0 && percentage != "" && percentage != "0")
              {
                currentSuppliers.valorVendaForn = currentSuppliers.valorVendaForn * percentage
                currentSuppliers.precoVendaForn = currentSuppliers.valorVendaForn
                currentSuppliers.precoCompraForn = currentSuppliers.valorVendaForn * (1 - (currentSuppliers.margemForn/100))
              }
              newSuppliersArray.push(currentSuppliers);
              currentPrecoCompra += parseFloat(
                currentSuppliers.precoCompraForn
              );
              currentMargemSem += parseFloat(
                currentSuppliers.margemSemPrecoVendaForn
              );
              currentPrecoVenda += parseFloat(
                currentSuppliers.precoVendaForn
              );
              currentValorVenda += parseFloat(
                currentSuppliers.valorVendaForn
              );
              currentMargem += parseFloat(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores[m].margemForn
              );
            }
          }
        }
      }
    }
    if (newSuppliersArray.length === 0) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores.length;
                m++
              ) {
                var currentSuppliers =
                global.structuredClone(workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .fornecedores[m]);
                currentSuppliers.item = i + 1;
                currentSuppliers.activity = j + 1;
                currentSuppliers.option = k + 1;
                if(newTotalSuppliers != null && newTotalSuppliers != 0 && newTotalSuppliers != "" && newTotalSuppliers != "0")
                {
                  const newPercentage = newTotalSuppliers/initialSuppliers
                  currentSuppliers.valorVendaForn = currentSuppliers.valorVendaForn * newPercentage
                  currentSuppliers.precoVendaForn = currentSuppliers.valorVendaForn
                  currentSuppliers.precoCompraForn = currentSuppliers.valorVendaForn * (1 - (currentSuppliers.margemForn/100))
                }
                else if(percentage != null && percentage != 0 && percentage != "" && percentage != "0")
                {
                  currentSuppliers.valorVendaForn = currentSuppliers.valorVendaForn * percentage
                  currentSuppliers.precoVendaForn = currentSuppliers.valorVendaForn
                  currentSuppliers.precoCompraForn = currentSuppliers.valorVendaForn * (1 - (currentSuppliers.margemForn/100))
                }
                newSuppliersArray.push(currentSuppliers);
                currentPrecoCompra += parseFloat(
                  currentSuppliers.precoCompraForn
                );
                currentMargemSem += parseFloat(
                  currentSuppliers.margemSemPrecoVendaForn
                );
                currentPrecoVenda += parseFloat(
                  currentSuppliers.precoVendaForn
                );
                currentValorVenda += parseFloat(
                  currentSuppliers.valorVendaForn
                );
                currentMargem += parseFloat(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .fornecedores[m].margemForn
                );
              }
            }
          }
        }
      }
    }

    setTotalPC(currentPrecoCompra);
    setTotalMargemSem("");
    setTotalPV(currentPrecoVenda);
    setTotalVV(currentValorVenda);
    setTotalMargem((((currentValorVenda - currentPrecoCompra)/currentValorVenda)*100).toFixed(2));

    setCurrentSuppliersList(newSuppliersArray);
  }, [currentSuppliersList.length === 0,workplans,triggerLoading]);

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".")
    const finalString = newArray[0].replace(",",".") + "," + newArray[1]
    return finalString;
  }

  function getPosition(index) {
    if (index === 0) return "start";
    if (index === supplierList.length - 1) return "end";
  }

  const supplierList = [
    "Atividade Principal",
    "Atividade Secundária",
    "Opção",
    "Tipo Fornecedor",
    "Fornecedor",
    "Departamento",
    "Descrição",
    "Preço de Compra",
    "Margem S/ Valor",
    "Preço de Venda Pretendido",
    "Valor de Venda",
    "Margem",
    "Prazo de Pagamento",
  ];

  return (
    <div className="liftworld-proposal-tables-wrapper">
      <div className="lifworld-table-wrapper">
        <div className="liftworld-table-header-wrapper">
          {supplierList &&
            Object.keys(supplierList).length !== 0 &&
            supplierList.map((item, index) => {
              return (
                <>
                  {index < 3 ? (
                    <div className="liftworld-table-3-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  ) : index === 4 || index === 6 ? (
                    <div className="liftworld-table-11-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  ) : (
                    <div className="liftworld-table-8-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
        <div>
          {currentSuppliersList &&
            Object.keys(currentSuppliersList).length !== 0 &&
            currentSuppliersList.map((item, index) => {
              return (
                <div className="liftworld-table-header-wrapper">
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.item}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.activity}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.option}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={convertIdToName(
                        item?.tipoFornecedor,
                        "providerTypes"
                      )}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-11-wrapper">
                    <TableTextbox
                      value={convertIdToName(item?.fornecedorForn, "providers")}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={convertIdToName(
                        item?.departamentoForn,
                        "departments"
                      )}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-11-wrapper">
                    <TableTextbox
                      value={item?.descricaoProdServ}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.precoCompraForn)) ||
                        item?.precoCompraForn === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.precoCompraForn).toFixed(2)
                            ) + "€"
                      }
                      number={"number"}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.margemSemPrecoVendaForn)) ||
                        item?.margemSemPrecoVendaForn === undefined
                          ? "0,00 %"
                          : numberWithCommas(parseFloat(item?.margemSemPrecoVendaForn).toFixed(
                              2
                            )) + "%"
                      }
                      number={"number"}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.precoVendaForn)) ||
                        item?.precoVendaForn === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.precoVendaForn).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.valorVendaForn)) ||
                        item?.valorVendaForn === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.valorVendaForn).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.margemForn)) ||
                        item?.margemForn === undefined
                          ? "0,00 %"
                          : numberWithCommas(parseFloat(item?.margemForn).toFixed(2)) + "%"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-8-wrapper">
                    <TableTextbox
                      value={item?.prazoPagamentoDias}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"start-end"} color={"colored"} />
          </div>
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-11-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-11-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalPC)) || totalPC === undefined
                  ? "0,00 € "
                  : numberWithCommas(parseFloat(totalPC)?.toFixed(2)) + "€"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox
              value={""}
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalPV)) || totalPV === undefined
                  ? "0,00 € "
                  : numberWithCommas(parseFloat(totalPV)?.toFixed(2)) + "€"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalVV)) || totalVV === undefined
                  ? "0,00 € "
                  : numberWithCommas(parseFloat(totalVV)?.toFixed(2)) + "€"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalMargem)) || totalMargem === undefined
                  ? "0,00 %"
                  : numberWithCommas(parseFloat(totalMargem)?.toFixed(2)) + "%"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-8-wrapper">
            <TableTextbox value={""} position={"end"} color={"colored"} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProposalSuppliersTable;
