import React from "react";
import { withNamespaces } from 'react-i18next';
import iconGreenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import iconAlert from "~/pages/Easypay/assets/img/icons/icon-alert.png";
import iconCheck from "~/pages/Easypay/assets/img/icons/icon-checkmark.png";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import iconInfo from "~/pages/Easypay/assets/img/icons/icon-info.png";
import "../assets/scss/_typography.scss";


const Text = ({ text, icon, align, t }) => {
  if (icon) {
    return (
      <div className="easypay-text-wrapper-center">
        <div className="easypay-text-wrapper">
          {icon && icon === "icon-alert" && (
            <div className="easypay-text-icon">
              <img src={iconAlert} alt="Icon Alert" />
            </div>
          )}
          {icon && icon === "icon-check" && (
            <div className="easypay-text-icon-check">
              <img src={iconCheck} alt="Icon Check" />
            </div>
          )}
          {icon && icon === "icon-info" && (
            <div className="easypay-text-icon">
              <img src={iconInfo} alt="Icon Check" />
            </div>
          )}
          {icon && icon === "icon-green-checkmark" && (
            <div className="easypay-text-icon-check">
              <img src={iconGreenCheckmark} alt="Icon Check" />
            </div>
          )}
          {icon && icon === "icon-document-submitted" && (
            <div className="easypay-text-icon-check">
              <img src={iconDocument} alt="Icon Check" />
            </div>
          )}
          <div className={`easypay-${text.split("::")[1]}`}>
            {text.includes("::") ? t(text.split("::")[0]) : t(text)}
          </div>
          {icon && icon === "icon-document-submitted" && (
            <div className="easypay-text-icon-check">
              <img src={iconGreenCheckmark} alt="Icon Check" />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={`easypay-${text.includes("::") ? text.split("::")[1] :""} ${align ? align : ""}`}>
        {text.includes("::") ? t(text.split("::")[0]) : t(text)}
      </div>
    );
  }
};

export default withNamespaces()(Text);
