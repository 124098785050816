import React from "react";
import { useDispatch } from "react-redux";
import { Badge } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
    setFlowIdMyTasksRequest
  } from "~/store/ducks/portalrh/actionTypes";

const RequestItemMyTasksWidget = (props) => {
    const dispatch = useDispatch();
    let { path } = useRouteMatch();
    const history = useHistory();

  const onCLickHandler = (flowid) => {
    dispatch(setFlowIdMyTasksRequest(flowid));
    history.push(path + "/tasks");
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <a
        onClick={() => onCLickHandler(props.flowId)}
        className="text-secondary"
      >
        {props.description}
        <Badge pill className={props.textColor}>
          {props.num == "0" ? "" : props.num}
        </Badge>
      </a>
      <br />
    </div>
  );
};

export default RequestItemMyTasksWidget;
