import {
  RESET_IFLOW_DOCUMENTS_LINK_COMPONENT,
  SET_IFLOW_DOCUMENTS_LINK_COMPONENT,
  GET_IFLOW_DOCUMENT_LINK_COMPONENT,
  SET_SELECTED_TOSIGN_LINK_COMPONENT,
  UPDATE_SELECTED_TOSIGN_LINK_COMPONENT,
  UPDATE_ISUSEEFFECT_RUN,
  SET_USERSETTINGS_STAMP,
  FIND_USERSETTINGS_STAMP,
  SET_SIGNATURE_COORDINATES,
  GET_SIGNATURE_COORDINATES,
  UPDATE_SELECTED_TOSIGN_TABLEFILE_COMPONENT,
  SET_SELECTED_TOSIGN_TABLEFILE_COMPONENT,
  SET_TABLEFILE_SIGNCLICKED,
  GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT,
  SET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT,
  RESET_IFLOW_DOCUMENTS_TABLE_FILE_COMPONENT,
} from "./actionTypes";

const initialState = {
  iflowDocumentLinkComponent: [],
  isLoadingIflowDocumentLinkComponent: false,
  selectedToSignLinkComponent: [],
  isuseEffectRun: false,
  userSettingsStamp: undefined,

  nextSignatureCoordinates: {},
  isLoadingSignatureCoordinates: false,
  isLoadingUserStamp: false,

  selectedToSignTableFileComponent: [],
  tableFileSignClicked: false,

  isLoadingIflowDocumentTableFileComponent: false,
  iflowDocumentTableFileComponent: [],
};

export function processesSignatureReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IFLOW_DOCUMENT_LINK_COMPONENT:
      return { ...state, isLoadingIflowDocumentLinkComponent: true };

    case RESET_IFLOW_DOCUMENTS_LINK_COMPONENT:
      return {
        ...state,
        iflowDocumentLinkComponent: [],
        isLoadingIflowDocumentLinkComponent: false,
      };

    case SET_IFLOW_DOCUMENTS_LINK_COMPONENT:
      let newIflowDocumentLC = Array.isArray(state.iflowDocumentLinkComponent)
        ? state.iflowDocumentLinkComponent
        : [];
      if (
        Array.isArray(state.iflowDocumentLinkComponent) &&
        state.iflowDocumentLinkComponent.some((d) => d.href === action.href)
      ) {
        newIflowDocumentLC = state.iflowDocumentLinkComponent.filter(
          (d) => d.href != action.href
        );
        newIflowDocumentLC.push({
          href: action.href,
          file: action.document,
          filename: action.filename,
        });
      } else {
        if (action.href) {
          newIflowDocumentLC.push({
            href: action.href,
            file: action.document,
            filename: action.filename,
          });
        }
      }

      return {
        ...state,
        isLoadingIflowDocumentLinkComponent: false,
        iflowDocumentLinkComponent: newIflowDocumentLC,
        isuseEffectRun: true,
      };

    case SET_SELECTED_TOSIGN_LINK_COMPONENT:
      return { ...state, selectedToSignLinkComponent: action.data };

    case UPDATE_SELECTED_TOSIGN_LINK_COMPONENT:
      if (
        !state.selectedToSignLinkComponent.some(
          (i) => i.href === action?.data?.href
        )
      ) {
        if (action.checked) {
          return {
            ...state,
            selectedToSignLinkComponent: [
              ...state.selectedToSignLinkComponent,
              action.data,
            ],
          };
        }
      } else {
        if (!action.checked) {
          return {
            ...state,
            selectedToSignLinkComponent:
              state.selectedToSignLinkComponent.filter(
                (i) => i.href != action?.data?.href
              ),
          };
        }
      }
      return state;

    case UPDATE_ISUSEEFFECT_RUN:
      return { ...state, isuseEffectRun: false };

    case FIND_USERSETTINGS_STAMP:
      return {
        ...state,
        isLoadingUserStamp: true,
      };

    case SET_USERSETTINGS_STAMP:
      return {
        ...state,
        userSettingsStamp: action.data,
        isLoadingUserStamp: false,
      };

    case SET_SIGNATURE_COORDINATES:
      const newNextSignatureCoordinates = JSON.parse(
        JSON.stringify(state.nextSignatureCoordinates)
      );
      newNextSignatureCoordinates[action.docid] = action.data.paginas;
      return {
        ...state,
        nextSignatureCoordinates: newNextSignatureCoordinates,
        isLoadingSignatureCoordinates: false,
      };

    case GET_SIGNATURE_COORDINATES:
      return {
        ...state,
        isLoadingSignatureCoordinates: true,
      };

    case UPDATE_SELECTED_TOSIGN_TABLEFILE_COMPONENT:
      if (
        !state.selectedToSignTableFileComponent.some(
          (i) => i.file.id === action?.data?.file?.id
        )
      ) {
        if (action.checked) {
          return {
            ...state,
            selectedToSignTableFileComponent: [
              ...state.selectedToSignTableFileComponent,
              action.data,
            ],
          };
        }
      } else {
        if (!action.checked) {
          return {
            ...state,
            selectedToSignTableFileComponent:
              state.selectedToSignTableFileComponent.filter(
                (i) => i.file.id != action?.data?.file?.id
              ),
          };
        }
      }
      return state;

    case SET_SELECTED_TOSIGN_TABLEFILE_COMPONENT:
      return { ...state, selectedToSignTableFileComponent: action.data };

    case SET_TABLEFILE_SIGNCLICKED:
      return { ...state, tableFileSignClicked: action.data };

    case GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT:
      return {
        ...state,
        isLoadingIflowDocumentTableFileComponent: true,
      };

    case SET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT:
      let newIflowDocumentTF = Array.isArray(
        state.iflowDocumentTableFileComponent
      )
        ? state.iflowDocumentTableFileComponent
        : [];
      if (
        Array.isArray(state.iflowDocumentTableFileComponent) &&
        state.iflowDocumentTableFileComponent.some(
          (d) => d.docid === action.docid
        )
      ) {
        newIflowDocumentTF = state.iflowDocumentTableFileComponent.filter(
          (d) => d.docid != action.docid
        );
        newIflowDocumentTF.push({
          docid: action.docid,
          file: action.document,
          filename: action.filename,
        });
      } else {
        if (action.docid) {
          newIflowDocumentTF.push({
            docid: action.docid,
            file: action.document,
            filename: action.filename,
          });
        }
      }

      return {
        ...state,
        isLoadingIflowDocumentTableFileComponent: false,
        iflowDocumentTableFileComponent: newIflowDocumentTF,
      };

    case RESET_IFLOW_DOCUMENTS_TABLE_FILE_COMPONENT:
      return {
        ...state,
        iflowDocumentTableFileComponent: [],
        isLoadingIflowDocumentTableFileComponent: false,
      };

    default:
      return state;
  }
}
