import { put } from "redux-saga/effects";
import { activeError, activeSuccess } from "~/components/Messages/actions";
import http from "~/utils/config/http";
import {
  doneGetAllCategories,
  doneGetComplaints,
  setAllCategories,
  setComplaints,
  setManualClaim,
  doneGetAllResultCategories,
  setAllResultsCategories,
  doneDataResults,
  setDataResults,
  doneSubDataResults,
  setSubDataResults,
  doneDataTops,
  setDataTops,
  setSubClaims
} from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
export function* getManualClaim({ claims }) {
  try {
    const resp = yield http.post(`/api/open/complaints`, claims);
    yield put(setManualClaim(resp.data));
  } catch (e) {
    yield put(setManualClaim(undefined));
  }
}



export function* getAllCategories() {
  try {
    const resp = yield http.get(`/api/categories`);
    yield put(setAllCategories(resp?.data));
    yield put(doneGetAllCategories());
  } catch (e) {
    yield put(doneGetAllCategories());
    yield put(setAllCategories([]));
  }
}

export function* exportClaims({ exportTypeId, categoryId, claim }) {
  try {
    let url =
      claim !== null
        ? window.location.origin +
          "/uniksystem/api/open/export/claimsmgt/" +
          exportTypeId +
          "/0/21000/" +
          categoryId +
          "/" +
          claim
        : window.location.origin +
          "/uniksystem/api/open/export/claimsmgt/" +
          exportTypeId +
          "/0/21000/" +
          categoryId +
          "/99";

    window.open(url, "_blank");
  } catch (e) {
    devLogConsole(e);
  }
}

export function* getComplaints({ id, beginIndex, endIndex }) {
  try {
    const resp = yield http.get(`/api/claims/${id}/${beginIndex}/${endIndex}`);
    yield put(setComplaints(resp?.data));
    yield put(doneGetComplaints());
  } catch (e) {
    yield put(doneGetComplaints());
    yield put(setComplaints([]));
  }
}

export function* getAllResultsCategories() {
  try {
    const resp = yield http.get(`/api/categories/total`);
    yield put(setAllResultsCategories(resp?.data));
    yield put(doneGetAllResultCategories());
  } catch (e) {
    yield put(doneGetAllResultCategories());
    yield put(setAllResultsCategories([]));
  }
}

export function* getDataResults({ id }) {
  try {
    const resp = yield http.get(`/api/results/headers/${id}`);
    yield put(setDataResults(resp?.data));
    yield put(doneDataResults());
  } catch (e) {
    yield put(doneDataResults());
    yield put(setDataResults([]));
  }
}

export function* getSubDataResults({ claimId, categoryId }) {
  try {
    const resp = yield http.get(
      `/api/results/subheaders/${claimId}/${categoryId}`
    );
    yield put(setSubDataResults(resp?.data));
    yield put(doneSubDataResults());
  } catch (e) {
    yield put(doneSubDataResults());
    yield put(setSubDataResults([]));
  }
}

export function* getSubClaims({ id, beginIndex, endIndex,claimId  }) {
  try {
    const resp = yield http.get(`/api/subclaims/${id}/${beginIndex}/${endIndex}/${claimId}`);
    yield put(setSubClaims(resp.data));
  } catch (e) {
    yield put(setSubClaims(undefined));
  }
}


export function* getDataTops({ obj }) {
  try {
    const resp = yield http.get(
      `/api/results/dynamic/${obj?.claimId}/${obj?.categoryId}/${obj?.boolean}/${obj?.index}`
    );
    yield put(setDataTops(resp?.data));
    yield put(doneDataTops());
  } catch (e) {
    yield put(doneDataTops());
    yield put(setDataTops([]));
  }
}
