import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Dropdown from "~/components/Dropdown";
import { loadMenuOnlyApps } from "~/store/ducks/application/actionTypes";
import {
    clearSettingsMenuApplication, findSettingsMenuByApplication, saveApplicationMenu
} from "~/store/ducks/applicationMenu/actionTypes";
import { TabsKey } from "../../utils/TabsEnum";
import Menu from "./Menu";
import MenuForm from "./MenuForm";

const MenuFormBase = ({ t, tabKey }) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const initialState = {
    name: "",
    route: "",
    icon: "",
    order: "",
    extraConfiguration: "",
    mainMenu: false,
    menuParent: "",
    permissions: [],
  };

  const [validated, setValidated] = useState(false);
  const [application, setApplication] = useState(undefined);
  const [applicationMenu, setApplicationMenu] = useState(initialState);
  const [availablePerms, setAvailablePerms] = useState([]);
  const [selectedPerms, setSelectedPerms] = useState([]);

  const { menuOnlyApps } = useSelector((state) => state.applicationReducer);
  const { successEdit, successDelete } = useSelector(
    (state) => state.applicationMenuReducer
  );
  const { permissions } = useSelector((state) => state.permissionReducer);

  useEffect(() => {
    return () => {
      setApplication(undefined);
      dispatch(clearSettingsMenuApplication());
    };
  }, []);

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATION_MENU) {
      dispatch(loadMenuOnlyApps());
    }
  }, [tabKey]);

  useEffect(() => {
    if ((successEdit || successDelete) && application) {
      dispatch(findSettingsMenuByApplication(application));
    }
  }, [successEdit, successDelete]);

  const handleApplication = (e) => {
    const application_ = e ? e : null;
    setApplication(application_);
    if (application_) {
      dispatch(findSettingsMenuByApplication(application_));
    } else {
      dispatch(clearSettingsMenuApplication());
    }
  };

  const handleClean = () => {
    formRef.current.reset();
    setValidated(false);
    setApplicationMenu(initialState);
    setAvailablePerms(permissions);
    setSelectedPerms([]);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    try {
      if (applicationMenu?.extraConfiguration) {
        JSON.parse(applicationMenu.extraConfiguration);
      }
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
      } else {
        persistApplicationMenu();
        handleClean();
      }
    } catch (e) {
      toast.error("❌ " + t("admin.applicationMenu.validateExtraConfig"), {
        toastId: "2",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const persistApplicationMenu = () => {
    const payload = {
      application: {
        id: application,
      },
      ...applicationMenu,
    };
    dispatch(saveApplicationMenu(payload));
    setApplicationMenu(initialState);
  };

  const adaptListForDropdown = (list) => {
    const newList = list.map((option) => {
      return {
        label: option.description,
        value: option.id.toString(),
        selected: false,
      };
    });

    return newList;
  };

  return (
    <>
      <ToastContainer />

      <Form.Row>
        <Col lg="6">
          <Form.Group>
            <Form.Label>
              <b>{t("admin.applicationMenu.application")}</b>
            </Form.Label>
            <Dropdown
              list={adaptListForDropdown(menuOnlyApps)}
              handleSubmit={handleApplication}
              emptyDefault={true}
            />
          </Form.Group>

          {!!application && (
            <Form
              id="menu-form-base"
              ref={formRef}
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              method="post"
            >
              <MenuForm
                application={application}
                applicationMenu={applicationMenu}
                setApplicationMenu={setApplicationMenu}
                availablePerms={availablePerms}
                setAvailablePerms={setAvailablePerms}
                selectedPerms={selectedPerms}
                setSelectedPerms={setSelectedPerms}
              />
              <hr />
              <Button variant="danger" onClick={handleClean}>
                {t("general.clean")}
              </Button>
              <Button
                className="card-button"
                type="submit"
                style={{ marginLeft: "10px", marginRight: "55px" }}
              >
                {t("general.save")}
              </Button>
            </Form>
          )}
        </Col>

        <Col lg={{ span: 4, offset: 1 }}>
          <Menu application={application} />
        </Col>
      </Form.Row>
    </>
  );
};

export default withNamespaces()(MenuFormBase);
