import React from "react";

const RhHorasExtra = ()=>{
return (
    <>
    horas extra
    </>
)
}

export default RhHorasExtra;