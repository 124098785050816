import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal } from "react-bootstrap";
import Spinner from "~/components/Spinner";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import NativeProcessesWrapper from "~/components/ProcessosFormHandler/NativeProcessesWrapper";
import ProcessHistoryTable from "~/components/Processes/ProcessHistoryTable";
import uuid from "uuid/v1";
import { findIflowProcessesByHistory } from "~/store/ducks/processes/actionTypes";

import oauth from "~/utils/oauth";
import "./scss/flowModal.scss";
import { findIflowProcessesHistory } from "~/store/ducks/processes/actionTypes";
import { cleariflowFormadata } from "../../store/ducks/processes/actionTypes";
import { RequestState } from "~/components/widgets/Requests/TabsEnum.js";
import AlertComponentWrapper from "../ProcessosFormHandler/AlertComponentWrapper";
import { hasError } from "~/utils";
const devLogConsole = require("~/utils/devLog");
const FlowModal = (props) => {
  const {
    t,
    src,
    app,
    process,
    showModal,
    closeModal,
    processNumber = "",
    isPortal = false,
    styleSheetLink,
    historyBTN,
    setShowFlowModal,
    setHistoryBTN,
    flowId,
    searchFilter,
    heightModal,
    callDetail = false,
    setCallDetail,
  } = props;

  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { isLoadingFormdata, history, formdata } = useSelector(
    (state) => state.processesReducer
  );
  const { genericAuthorizationState } = useSelector(
    (state) => state.portalrhReducer
  );

  const [modalClassName, setModalClassName] = useState("modal-tarefa-97w");
  const [iframeClassName, setIframeClassName] = useState("iframe-tarefa-80w");
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");
  const [isMaximized, setIsMaximised] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [styleSheet, setFormStyleSheet] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [textSize, setTextSize] = useState("");

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("opentask=true")) {
      const match = url.match(/[?&](flowid|pid|subpid)=([^&]+)/g);
      if (match) {
        const params = {};
        match.forEach((param) => {
          const [key, value] = param.substring(1).split("=");
          params[key] = value;
        });
        const flowid = params.flowid;
        const pid = params.pid;
        const supid = params.subpid;
        const payload = {
          url:
            "Forward/forward.jsp?flowid=" +
            flowid +
            "&pid=" +
            pid +
            "&subpid=" +
            supid +
            "&Authorization=" +
            oauth.getAccessToken(),
        };
        const isNewForm = true;
        dispatch(getIflowFormdata(payload, isNewForm));
      }
      const index = url.indexOf("?");
      url = url.substring(0, index);
      window.location.replace(url);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (!menu) return;
    const menuItem = menu.find(
      (item) => item.route === `${app}/tarefas` || item.route === `${app}/tasks`
    );
    if (menuItem) {
      const extraConfiguration_ = JSON.parse(
        menuItem.extraConfiguration || false
      );
      const maximize = extraConfiguration_.maximize;
      if (!maximize || maximize == "false") {
        return;
      }
      setIsMaximised(true);
      setModalClassName("modal-tarefa-100w");
      setIconClassName("icon-restore mr-4");
    }
  }, [menu]);

  const handleClick = () => {
    modalClassName === "modal-tarefa-97w"
      ? setModalClassName("modal-tarefa-100w")
      : setModalClassName("modal-tarefa-97w");
    iframeClassName === "iframe-tarefa-80w"
      ? setIframeClassName("iframe-tarefa-100w")
      : setIframeClassName("iframe-tarefa-80w");
    iconClassName === "icon-maximize mr-4"
      ? setIconClassName("icon-restore mr-4")
      : setIconClassName("icon-maximize mr-4");
  };

  const handleClose = () => {
    if (isMaximized) {
      setModalClassName("modal-tarefa-100w");
      setIconClassName("icon-restore mr-4");
    } else {
      setModalClassName("modal-tarefa-97w");
      setIconClassName("icon-maximize mr-4");
    }
    setIframeClassName("iframe-tarefa-80w");
    closeModal(searchFilter);
  };

  useEffect(() => {
    if (process != undefined && historyBTN) {
      let payload = {};
      const payloadArray = [
        {
          name: "pid",
          value: process.pid,
        },
      ];
      const payload_ = payloadArray.filter(
        (item) =>
          item.value !== "" && item.value !== undefined && item.value !== null
      );
      payload_.forEach((item) => {
        payload[item.name] = item.value;
      });
      dispatch(findIflowProcessesHistory(payload));
    }
  }, [process]);

  useEffect(() => {
    if (!isPortal) {
      if (menu && app && process && process.url !== "") {
        const menuItem = menu.find((item) => item.route === `${app}/processos`);
        if (menuItem) {
          const extraConfiguration_ = JSON.parse(
            menuItem.extraConfiguration || false
          );
          let parts;
          let updatedUrl;
          if (process.url) {
            if (process.url.includes("/iFlow")) {
              parts = process.url.split("/iFlow"); // Divide a URL em partes usando "/iFlow" como separador
              updatedUrl = parts.slice(1).join("");
            } else {
              updatedUrl = process.url;
            }
            const payload = {
              url: updatedUrl + "&Authorization=" + oauth.getAccessToken(),
            };
            const isNewForm = true;
            dispatch(getIflowFormdata(payload, isNewForm));
          }

          const styleSheet_ =
            extraConfiguration_ && extraConfiguration_.styleSheet;

          setFormStyleSheet(styleSheet_);
        }
      }
    } else {
      if (menu && process) {
        if (process?.flow_id && process?.p_id && process?.subp_id) {
          const flowid = process.flow_id;
          const pid = process.p_id;
          const subpid = process.subp_id;
          //default url
          let url =
            `/Form/form.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&Authorization=` +
            oauth.getAccessToken();
          if (
            window.location.href.includes("portalrh/personalarea/generic") ||
            window.location.href.includes("portalrh/personalarea/expense") ||
            window.location.href.includes("personalarea/requests/payment") ||
            window.location.href.includes(
              "portalrh/personalarea/absenceRecord"
            ) ||
            window.location.href.includes("portalrh/personalarea/extraTime") ||
            window.location.href.includes(
              "portalrh/personalarea/insertPresence"
            ) ||
            window.location.href.includes(
              "portalrh/personalarea/pagamentosBolsa"
            )
          ) {
            url =
              `/Form/detail.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&fwSearch=true` +
              "&Authorization=" +
              oauth.getAccessToken();
          } else if (
            window.location.href.includes("portalrh/myteam/generic") ||
            window.location.href.includes("portalrh/myteam/expense") ||
            window.location.href.includes("portalrh/myteam/absenceRecord") ||
            window.location.href.includes("portalrh/myteam/extraTime") ||
            window.location.href.includes("portalrh/myteam/insertPresence") ||
            window.location.href.includes("portalrh/myteam/pagamentosBolsa")
          ) {
            if (
              genericAuthorizationState === RequestState.APPROVED ||
              genericAuthorizationState === RequestState.REJECTED ||
              genericAuthorizationState === RequestState.PENDING
            ) {
              url =
                `/Form/detail.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&fwSearch=true` +
                "&Authorization=" +
                oauth.getAccessToken();
            } else {
              url =
                `/Forward/forward.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&procStatus=0&fwSearch=true` +
                "&Authorization=" +
                oauth.getAccessToken();
            }
          } else {
            url =
              `/Forward/forward.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&procStatus=0&fwSearch=true` +
              "&Authorization=" +
              oauth.getAccessToken();
          }
          if (callDetail) {
            url =
              `/Form/detail.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&fwSearch=true` +
              "&Authorization=" +
              oauth.getAccessToken();
            setCallDetail(false);
          }
          dispatch(getIflowFormdata({ url }));
        }
      }
    }
  }, [menu, app, process]);

  useEffect(() => {
    if (
      styleSheet &&
      styleSheet?.noTransformColumnsIntoRow &&
      // !renderLinks &&
      !styleSheet?.transformLinksIntoWrapper &&
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`);
    }
  }, [styleSheet]);

  function parseJsonFromString(formdata) {
    let arr = formdata.split(`{"form" :`);
    let re = /\"\" style=\"/g;
    let transformedString = (`{"form" :` + arr[1]).replace(re, '" style=');
    transformedString = transformedString
      .replace(/\\/g, "\\\\")
      .replace(/(\r\n|\n|\r|\t)/gm, "");
    return JSON.parse(transformedString);
  }

  function shouldUseNativeWrapper(formdata) {
    if (
      typeof formdata === "object" &&
      formdata !== null &&
      !formdata.responseMessage?.includes('<meta formType="HTML">')
    ) {
      return true;
    } else if (typeof formdata === "string" && formdata.includes(`{"form" :`)) {
      return true;
    }
    return false;
  }

  const renderContent = () => {
    if (styleSheetLink) {
      return (
        <NativeProcessesWrapper
          isModal={true}
          handleClose={handleClose}
          handleHeaderName={setHeaderName}
          heightModal={heightModal}
        />
      );
    }

    const useNativeWrapper = shouldUseNativeWrapper(formdata);

    if (hasError(formdata)) {
      return <AlertComponentWrapper />;
    }
    let formFromProcess = null;

    if (useNativeWrapper && typeof formdata === "string") {
      formFromProcess = parseJsonFromString(formdata);
    } else if (typeof formdata === "object") {
      formFromProcess = formdata;
    }

    if (useNativeWrapper) {
      return (
        <NativeProcessesWrapper
          isModal={true}
          handleHeaderName={setHeaderName}
          handleClose={handleClose}
          formFromProcess={formFromProcess}
          heightModal={heightModal}
        />
      );
    } else if (src) {
      return <TaskIframe src={src} iframeClassName={iframeClassName} />;
    }
  };

  const openSecondModal = () => {
    setShowFlowModal(false); // Fechar a primeira modal
    setShowSecondModal(true); // Abrir a segunda modal
    let payload = {};
    const payloadArray = [
      {
        name: "pid",
        value: process.pid,
      },
    ];
    const payload_ = payloadArray.filter(
      (item) =>
        item.value !== "" && item.value !== undefined && item.value !== null
    );
    payload_.forEach((item) => {
      payload[item.name] = item.value;
    });
    let teste = dispatch(findIflowProcessesByHistory(payload));

    devLogConsole(teste);
  };

  const closeSecondModal = () => {
    setShowSecondModal(false); // Fechar a segunda modal
    setShowFlowModal(true);
  };
  const handleOpenHistoryModal = () => {
    setShowFlowModal(false); // Fechar o primeiro modal
    setShowHistoryModal(true); // Abrir o segundo modal
    setHistoryBTN(false);
  };

  const handleCloseHistoryModal = () => {
    setShowFlowModal(true); // Abrir novamente o primeiro modal
    setShowHistoryModal(false); // Fechar o segundo modal
    setHistoryBTN(true);
  };
  return (
    <>
      {!isLoadingFormdata ? (
        <>
          <Modal
            dialogClassName={modalClassName + " custom-modal"}
            show={showModal && !showSecondModal} // Verificar se showSecondModal é falso
            onHide={() => handleClose()}
            backdrop="static"
            scrollable
          >
            <Modal.Header className="justify-content-between">
              <div className="custom-title ml-4">
                {styleSheetLink
                  ? t("taskPage.general.processDetail")
                  : styleSheet?.hideFormHeader
                  ? headerName
                  : t("taskPage.general.process") + " " + processNumber}
              </div>
              <div>
                <i className={iconClassName} onClick={() => handleClick()} />
                <i className="icon-remove mr-4" onClick={() => handleClose()} />
              </div>
            </Modal.Header>

            <Modal.Body style={{ padding: "0px", scrollY: "auto" }}>
              <div
                className={
                  styleSheet?.processScroll ? "scrollBar-visibleFlowModal" : ""
                }
              >
                {renderContent()}
                {historyBTN && setShowFlowModal !== undefined ? (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "70px",
                      paddingBottom: "70px",
                    }}
                  >
                    <button
                      id={uuid()}
                      type="button"
                      className="card-button btn btn-primary"
                      style={{ margin: "10px" }}
                      onClick={() => handleOpenHistoryModal()}
                    >
                      {t("validprocs.general.history")}
                    </button>
                  </div>
                ) : null}
                {history && setShowFlowModal !== undefined ? (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "70px",
                      paddingBottom: "70px",
                    }}
                  >
                    <button
                      id="5"
                      type="button"
                      className="card-button btn btn-primary"
                      style={{ margin: "10px" }}
                      onClick={openSecondModal}
                    >
                      {t("validprocs.general.history")}
                    </button>
                  </div>
                ) : null}
              </div>
            </Modal.Body>

            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            dialogClassName={modalClassName + " custom-modal"}
            show={showSecondModal} // Controla a visibilidade da segunda modal
            onHide={closeSecondModal} // Função para fechar a segunda modal
            backdrop="static"
            scrollable
          >
            <Modal.Header>
              <div className="custom-title ml-4">Título da Segunda Modal</div>
              <div>
                <i className={iconClassName} onClick={() => handleClick()} />
                <i className="icon-remove mr-4" onClick={closeSecondModal} />
              </div>
            </Modal.Header>

            <Modal.Body>
              Conteúdo da Segunda Modal
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "70px",
                  paddingBottom: "70px",
                }}
              >
                <button
                  id="5"
                  type="button"
                  className="card-button btn btn-primary"
                  style={{ margin: "10px" }}
                  onClick={closeSecondModal}
                >
                  Voltar
                </button>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {/* Botões ou elementos adicionais na parte inferior da segunda modal */}
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Spinner spinning={isLoadingFormdata} wrapper />
      )}
      <Modal
        dialogClassName={modalClassName + " custom-modal"}
        show={showHistoryModal}
        onHide={() => handleCloseHistoryModal()}
        backdrop="static"
        scrollable
      >
        <Modal.Header className="justify-content-between">
          <div className="custom-title ml-4">
            {styleSheetLink
              ? t("taskPage.general.processDetail")
              : styleSheet?.hideFormHeader
              ? headerName
              : t("taskPage.general.process") + " " + processNumber}
          </div>
          <div>
            <i className={iconClassName} onClick={() => handleClick()} />
            <i
              className="icon-remove mr-4"
              onClick={() => handleCloseHistoryModal()}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {history.length > 0 ? (
            <ProcessHistoryTable
              processHistory={history}
              styleSheet={styleSheet}
              flowId={flowId}
              app={app}
            />
          ) : (
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
                paddingBottom: "70px",
              }}
            >
              <h3 style={{ margin: "10px" }}>
                {t("validprocs.general.nodata")}
              </h3>
            </div>
          )}
          {!historyBTN ? (
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
                paddingBottom: "70px",
              }}
            >
              <button
                id={uuid()}
                type="button"
                className="card-button btn btn-primary"
                style={{ margin: "10px" }}
                onClick={() => handleCloseHistoryModal()}
              >
                {t("validprocs.general.back")}
              </button>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const TaskIframe = ({ src, iframeClassName }) => {
  devLogConsole(src);
  return (
    <iframe
      id="process"
      title="process-flow"
      src={src}
      className={iframeClassName}
    />
  );
};

export default withNamespaces()(FlowModal);
