import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import EyeBlueInvisible from "../assets/img/icons/icon-eye-invisible-blue.png";
import EyeGreyInvisible from "../assets/img/icons/icon-eye-invisible-grey.png";
import EyeBlueVisible from "../assets/img/icons/icon-eye-visible-blue.png";
import EyeGreyVisible from "../assets/img/icons/icon-eye-visible-grey.png";
import "../assets/scss/_inputPassword.scss";
import PasswordProgessBar from "./PasswordProgressBar";


function InputPassword({ pwd, setPwd, setPasswordValid , hasBar, label}) {
  const [showInput, setShowInput] = useState(false);
  const [highlighted, setHighlighted] = useState(false);
  const wrapperRef = useRef(null);
  //Randomly creates an id for input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        event.target.id != `easypay-password-input-wrapper-${id.current}` &&
        !document
          .getElementById(`easypay-password-input-wrapper-${id.current}`)
          .contains(event.target)
      ) {
        setHighlighted(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function getEyeImage() {
    if (showInput) {
      if (highlighted) return EyeBlueInvisible;
      else return EyeGreyInvisible;
    } else if (highlighted) {
      return EyeBlueVisible;
    } else return EyeGreyVisible;
  }

  return (
    <div className="easypay-main-password-input-wrapper">
      <div className = "easypay-input-password-label">{label}</div>
      <div
        className={
          highlighted
            ? `${"easypay-password-input-wrapper"} active`
            : pwd.length === 0
            ? `${"easypay-password-input-wrapper"} not-selected`
            : "easypay-password-input-wrapper"
        }
        id={`easypay-password-input-wrapper-${id.current}`}
      >
        <input
          className={
            showInput
              ? "easypay-password-input"
              : `${"easypay-password-input"} password`
          }
          name="password"
          ref={wrapperRef}
          onChange={(e) => {
            setPwd(e.target.value);
          }}
          onMouseDown={() => {
            setHighlighted(true);
          }}
        ></input>
        <img
          className="easypay-password-img"
          src={getEyeImage()}
          onClick={() => {
            setShowInput(!showInput);
          }}
        ></img>
      </div>
      {hasBar && <PasswordProgessBar
        className="easypay-input-password-progressbar"
        password={pwd}
        setPasswordValid={setPasswordValid}
      ></PasswordProgessBar>}
    </div>
  );
}

export default InputPassword;
