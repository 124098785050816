import { result } from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Dropdown from "~/components/Dropdown";
import SearchableDropDown from "~/components/SearchableDropDown";
import {
  adaptListForDocType,
  adaptListForOperations,
} from "./handleDropdownList";

const SelectFilters = (props) => {
  const {
    t,
    operations,
    showExtraFields,
    selectedOperation,
    setSelectedOperation,
    selectedCollaborator,
    setSelectedCollaborator,
    docType,
    setDoctype,
    manager,
    collaborators,
    uploadEmMassa,
    emailTemplates,
    sendEmailNotification,
    setSendEmailNotification,
    notificationOption,
    setNotificationOption,
    setCheckForAll,
    checkForAll,
  } = props;

  const [list, setList] = useState([]);

  useEffect(() => {
    if (collaborators && collaborators.length > 0) {
      const optionList = collaborators.map((option) => ({
        label: option.funcionarioNum + " " + option.nome,
        value: option.id,
        selected:
          selectedCollaborator && selectedCollaborator == option.id
            ? true
            : false,
      }));
      if (optionList) {
        setList(optionList);
      }
    }
  }, [collaborators, selectedCollaborator]);

  const getCollaboratorName = () => {
    if (selectedCollaborator) {
      const collaborator = collaborators.find(
        (c) => c.id === selectedCollaborator
      );
      if (collaborator) {
        return collaborator.funcionarioNum + " " + collaborator.nome;
      } else return "";
    } else {
      return "";
    }
  };

  let collaboratorName = "";
  if (selectedCollaborator) {
    collaboratorName = getCollaboratorName();
  }

  const handleEmailTemplateOptions = () => {
    const emailTemplate = emailTemplates.find(
      (el) => el.operationId == selectedOperation && el.docTypeId == docType
    );

    const notificationOptionsList = [
      {
        label: t("portalrh.documentManagement.annexDocuments"),
        value: "0",
        selected: false,
      },
      {
        label: t("portalrh.documentManagement.directWithUrl"),
        value: "1",
        selected: false,
      },
    ];

    let result = emailTemplate ? (
      <>
        <Form.Check
          type="checkbox"
          id="default-checkbox"
          label={t("portalrh.documentManagement.sendEmailNotification")}
          value={sendEmailNotification}
          onChange={(e) => setSendEmailNotification(e.target.checked)}
        />
        <Dropdown
          list={notificationOptionsList}
          hide={!sendEmailNotification}
          handleSubmit={setNotificationOption}
        />
        <br></br>
      </>
    ) : (
      <p>
        {t(
          "portalrh.documentManagement.noEmailTemplatesFoundForThisDocumentType"
        )}
      </p>
    );

    return result;
  };

  return (
    <div className="portal-upload-select-wrapper">
      {manager &&
        collaborators &&
        collaborators.length > 0 &&
        !uploadEmMassa && (
          <>
            <Form.Label>
              {showExtraFields
                ? t("portalrh.documentManagement.selectedCollaborator")
                : ""}
            </Form.Label>
            {showExtraFields && selectedCollaborator ? (
              <Form.Control
                disabled={true}
                style={{ marginBottom: "15px" }}
                value={collaboratorName}
              />
            ) : (
              ""
            )}
            {!showExtraFields && (
              <SearchableDropDown
                name="collaborator"
                list={list}
                required={false}
                handleSubmit={(value) => setSelectedCollaborator(value)}
                enableSearchEmptyValue={true}
              />
            )}
          </>
        )}
      <Form.Label>
        {showExtraFields
          ? t("portalrh.documentManagement.selectedDocType")
          : ""}
      </Form.Label>
      <Dropdown
        disabled={showExtraFields ? true : false}
        list={adaptListForOperations(
          operations,
          t("portalrh.documentManagement.selectOperationPlaceholder")
        )}
        handleSubmit={setSelectedOperation}
      />
      {selectedOperation && (
        <Dropdown
          disabled={showExtraFields ? true : false}
          list={adaptListForDocType(
            operations,
            selectedOperation,
            t("portalrh.documentManagement.selectOperationPlaceholder")
          )}
          handleSubmit={setDoctype}
          sort={false}
        />
      )}
      <div>
        {" "}
        <Form.Check
          className="rhcollaborator__labels__"
          inline
          type="checkbox"
          label={t("portalrh.documentManagement.checkForAll")}
          checked={checkForAll}
          style={{
            paddingBottom: 10,
            color: "#212529",
          }}
          onChange={() => setCheckForAll((checkForAll) => !checkForAll)}
        />
      </div>
      {selectedOperation && docType ? handleEmailTemplateOptions() : null}
    </div>
  );
};
export default withNamespaces()(SelectFilters);
