import React from "react";

import logo from "~/assets/img/logos/unik-logo.png";

const Error500 = () => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img className="img-fluid unik-logo-500" src={logo} alt="Uniksystem" />
      </div>
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "150px", marginBottom: -60 }}>500</div>
        <div style={{ fontSize: "50px", fontWeight: 700, color: "gray" }}>
          error
        </div>
        <small style={{ color: "gray" }}>Server unavailable</small>
      </div>
    </>
  );
};
export default Error500;
