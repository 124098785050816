import { put } from 'redux-saga/effects';
import http from '~/utils/config/http';
import { setMFiles } from '../actions';
import { activeError } from '~/components/Messages/actions';
const devLogConsole = require("~/utils/devLog");
export function* findMFilesByFilters({ filters }) {
  try {
    const resp = yield http.post('api/mfiles/find', filters);
    yield put(setMFiles(resp.data.items));
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}

export function* downloadMfile({ file }) {
  devLogConsole(file)
  const type = file.objectVersion && file.objectVersion.type;
  const objectId = file.objectVersion && file.objectVersion.objectId;
  const version = file.objectVersion && file.objectVersion.version;
  const file_ = file.files[0] && file.files[0].file;
  try {
    yield http.get(`api/mfiles/download/${type}/${objectId}/${version}/${file_}`);
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}
