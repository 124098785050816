import React from "react";

import { Badge } from 'react-bootstrap';

const PermissionBadge = ({ create, read, update, delete_ }) => {

  const badgeStyle = { marginRight: 5 };

  return (
    <>
      {create ? <Badge variant="success" style={badgeStyle}>CREATE</Badge> : <></>}
      {read ? <Badge variant="primary" style={badgeStyle}>READ</Badge> : <></>}
      {update ? <Badge variant="warning" style={badgeStyle}>UPDATE</Badge> : <></>}
      {delete_ ? <Badge variant="danger" style={badgeStyle}>DELETE</Badge> : <></>}
    </>
  )

}

export default PermissionBadge;