import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { Form, Button } from "react-bootstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import { withNamespaces } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { setGraphInfo } from "~/store/ducks/graphs/actionTypes";

const StatisticsPeriodGroupFilters = ({ executeGraph, renderGroups, t }) => {
  const dispatch = useDispatch();

  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  const [group, setGroup] = useState("");
  const [locale, setLocale] = React.useState("en");

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      setLocale(userSettings.language.trim());
    }
  }, [userSettings]);

  const handleSearch = () => {
    const { startDate, endDate } = state[0];
    if (group === "" || startDate === "" || endDate === "") return;
    executeGraph(startDate, endDate, group);
  };

  const cleanStates = () => {
    setState([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    setGroup("");
    setCleanDropdown(true);
    dispatch(setGraphInfo({}));
  };
  console.log(state, group);
  return (
    <Form.Row style={{ marginTop: "0", justifyContent: "center" }}>
      <Form.Label style={{ marginRight: "10px" }}>Grupos</Form.Label>
      <Dropdown
        list={renderGroups()}
        handleSubmit={(e) => {
          setGroup(e);
          handleSearch();
        }}
        emptyDefault={true}
        flowId={group}
        resetDropdown={cleanDropdown}
        setResetDropdown={setCleanDropdown}
      />
      <DateRange
        locale={locales[locale]}
        editableDateInputs={false}
        onChange={(item) => {
          setState([item.selection]);
          handleSearch();
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
      <div
        style={{
          width: "100%",
          justifyContent: "right",
          display: "flex",
          gap: "1rem",
        }}
      >
        {(state[0].startDate !== "" ||
          state[0].endDate !== "" ||
          group !== "") && (
          <div className="divForTrashStatistics" onClick={cleanStates}>
            <FaTrash />
          </div>
        )}
        {/* <Button className="card-button" onClick={() => handleSearch()}>
          {t("general.search")}
        </Button> */}
      </div>
    </Form.Row>
  );
};

export default withNamespaces()(StatisticsPeriodGroupFilters);
