import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pingMiddlewareApplication } from "~/store/ducks/systemSettings/applications/actionTypes";
import { GLOBAL_SOLVER_MIDDLEWARE } from '~/utils/constants';
import { TabsKey } from '../../utils/TabsEnum';
import ApplicationCard from "./ApplicationCard";


const GlobalSolverComponent = ({ tabKey, t }) => {

  const dispatch = useDispatch();

  const {
    isGlobalSolverON,
    loadingGlobalSolver
  } = useSelector(state => state.systemSettingsApplicationsReducer);

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(pingMiddlewareApplication(GLOBAL_SOLVER_MIDDLEWARE));
    }
  }, [tabKey])

  return (
    <ApplicationCard
      name="GlobalSolver"
      status={isGlobalSolverON}
      loading={loadingGlobalSolver}
      showMW={true}
    />
  )
}

export default withNamespaces()(GlobalSolverComponent);
