import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import { Card, Button, Form, Col } from "react-bootstrap";
import logo from "~/assets/img/logos/unik-logo.png";
import Spinner from "~/components/Spinner";

import {
  resetPassword,
  clearResetPassword,
} from "~/store/ducks/resetPassword/actionTypes";

import { BACKEND_SERVER_URL } from "~/utils/constants";

const ForgotPassword = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [payload, setPayload] = useState("");

  const { isLoadingResetPassword, successResetPassword } = useSelector(
    (state) => state.resetPasswordReducer
  );

  useEffect(() => {
    return () => {
      dispatch(clearResetPassword());
    };
  }, []);

  useEffect(() => {
    if (successResetPassword !== undefined) {
      if (successResetPassword) {
        history.push("/success-page-reset-pass");
      } else {
        history.push("/");
      }
    }
  }, [successResetPassword]);

  const handleResetPassword = () => {
    if (!!payload) {
      const data = { urlOrigin: BACKEND_SERVER_URL(), payload };
      dispatch(resetPassword(data));
    } else {
      toast.warning("⚠️ " + t("login.error.resetPasswordFieldEmpty"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="overlay">
        <div className="row">
          <div className="col-12 mt-3 mb-2 text-center">
            <img className="img-fluid unik-logo" src={logo} alt="Uniksystem" />
          </div>
        </div>

        <Form.Row style={{ paddingTop: 20, color: "gray" }}>
          <Col lg="12">
            <h1 className="display-4">{t("login.resetPasswordMainLabel")}</h1>
          </Col>
        </Form.Row>
        <hr />

        <Card className="card-login">
          <Card.Body>
            <Form>
              <Form.Row>
                <Col lg="12">
                  <Form.Group controlId="formBasicEmail">
                    <h1 className="label-credentials">
                      {t("login.emailUsername")}
                    </h1>
                    <Form.Control
                      type="text"
                      required
                      value={payload}
                      onChange={(e) => setPayload(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("login.requiredEmailUsername")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col lg="12">
                  <Button
                    variant="outline-light"
                    className="login-button"
                    style={{ backgroundColor: "#4484f4" }}
                    onClick={() => handleResetPassword()}
                  >
                    {t("login.send")}
                  </Button>
                  <Button
                    variant="danger"
                    style={{
                      marginLeft: 10,
                      borderRadius: "23px",
                      height: "44px",
                    }}
                    onClick={() => history.push("/")}
                  >
                    {t("login.back")}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>

        <Form.Row style={{ paddingTop: 20, color: "gray" }}>
          <Col lg="12">
            <small>{t("login.resetPasswordInfoLabel")}</small>
          </Col>
        </Form.Row>
      </div>

      <ToastContainer />
      <Spinner spinning={isLoadingResetPassword} wrapper />
    </>
  );
};

export default withNamespaces()(ForgotPassword);
