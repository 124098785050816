export const TabsKey = {
  PRODUCTION_MODE: 'tabProdMode',
  AUTH_MODE: 'tabAuthMode',
  BASE_WIDGETS: 'tabBaseWidgets',
  PUZZLE: 'tabPuzzle',
  APPLICATION_MENU: 'tabApplicationMenu',
  APPLICATION_LOGO: 'tabApplicationLogo',
  APPLICATIONS: 'tabApplications',
  SYNCRONIZATION: 'tabSyncronization',
}
