import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../../../i18n";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import "../assets/scss/_accordion.scss";
import AddBenefModalContent from "./AddBenefModalContent";
import AddRepLegModalContent from "./AddRepLegModalContent";
import BenefModalContent from "./BenefModalContent";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import CustomButton from "./Button";
import Input from "./Input";
import Link from "./Link";
import PEPCheckbox from "./PEPCheckbox";
import RadioButtonItem from "./RadioButtonItem";
import RepLegModalContent from "./RepLegModalContent";
import ReplegToggleAccordion from "./ReplegToggleAccordion";
import Tag from "./Tag";
import Text from "./Text";

const CustomAccordion = ({
  variant,
  list,
  addLinkText,
  removeLinkText,
  handleSubmitWrapperLink,
  t,
}) => {
  const [currentDocTypeTab, setCurrentDocTypeTab] = useState(
    t(`bpm.onboarding.backoffice.user.labelCitizenCard`)
  );
  const [info, setInfo] = useState();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isAddModalShowing, setIsAddModalShowing] = useState(false);
  const [benefVariant, setBenefVariant] = useState("");
  const [tabSelected, setTabSelected] = useState(-1);
  var isIndexFirstTime = true;

  var badge = "@";
  var isPersonal = false;
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isPersonalBenef, setIsPersonalBenef] = useState(false);
  const [entityType, setEntityType] = useState("");

  var deleteText = "";

  if (variant === "accordion-table") {
    deleteText = "'deleteRepLeg'";
  } else {
    deleteText = "'deleteBenef'";
  }

  const options = {
    component: (url, text, key) => (
      <a key={key} href={url}>
        This is a link: {text}
      </a>
    ),
  };

  useEffect(() => {
    if (isFirstTime && document.dados["accordion_benef_numdocumento"]) {
      document.dados["accordion_benef_numdocumento"].value = "";
      setIsFirstTime(false);
    }
    if (document.dados["benef_doctipo_edit"]) {
      document.dados["benef_doctipo_edit"].value = currentDocTypeTab;
    }
    if (document.dados["tipoentidade"]) {
      setEntityType(document.dados["tipoentidade"].value);
    }
    if (document?.dados["replegindex"]) {
      if (
        parseInt(document?.dados["replegindex"].value) >= 0 &&
        isIndexFirstTime
      ) {
        setTabSelected(parseInt(document?.dados["replegindex"].value));
        if (
          document?.dados["currentRepLegIdText"]?.value != null &&
          document?.dados["currentRepLegIdText"]?.value != undefined
        ) {
          document.dados["currentRepLegIdText"].value =
            list[parseInt(document.dados.replegindex.value)]?.info.get(
              "ids"
            )?.value;
        }
        isIndexFirstTime = false;
      }
    }
  }, [currentDocTypeTab]);

  function checkCanAdd() {
    if (list) {
      if (list[0].info.get("pda")?.value === "") {
        return true;
      }
      return false;
    }
    return false;
  }

  function nextChar(c) {
    return (badge = String.fromCharCode(c.charCodeAt(0) + 1));
  }

  function handleHeaderOnClick(index, isPersonal) {
    if (tabSelected === index) {
      setTabSelected(-1);
    } else if (tabSelected != index) {
      setTabSelected(index);
      if (document.dados.replegindex != null) {
        document.dados.replegindex.value = index;

        if (document?.dados["currentRepLegIdText"]?.value) {
          document.dados["currentRepLegIdText"].value =
            list[index].info.get("ids")?.value;
        }
      }
    }
    setIsPersonalBenef(isPersonal);
  }

  function handleSetAddModal() {
    setIsAddModalShowing(true);
  }

  const removeButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "center",
    href:
      "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value=" +
      deleteText +
      ";document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const addButtonField = {
    type: "link",
    text: t("bpm.onboarding.backoffice.user.buttonAdd"),
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitMain';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const submitButtonField = {
    type: "link",
    text: t("bpm.onboarding.backoffice.user.buttonAdd"),
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: `javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='${
      variant === "accordion-table" ? "submitRepleg" : "submitBenef"
    }';document.dados.op.value='3';document.dados.submit();`,
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const finalizeButtonField = {
    type: "link",
    text: t("bpm.onboarding.backoffice.user.buttonAdd"),
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='finalizeAccount';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const replegSubHeaderText =
    t(`bpm.onboarding.backoffice.user.subHeaderRepLeg`) +
    "::card-text-light-small-padding";

  const benefSubHeaderText =
    t(`bpm.onboarding.backoffice.user.benefTabSubHeader`) +
    "::card-text-light-small-padding";

  function canShow(title, item) {
    if (title != "bpm.onboarding.backoffice.user.labelCompany") {
      return true;
    } else if (
      item.info.get("bpm.onboarding.backoffice.user.labelIndirectCapital")
        .value === ""
    ) {
      return true;
    }
    return false;
  }

  function finalizeDisabled() {
    if (list[0].info.get("final")?.value === "0") return true;

    return false;
  }

  return (
    <>
      {variant === "accordion-table" ? (
        <AddRepLegModalContent
          title={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
          isModalShowing={isAddModalShowing}
          setIsModalShowing={setIsAddModalShowing}
        />
      ) : (
        <AddBenefModalContent
          title={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
          isModalShowing={isAddModalShowing}
          setIsModalShowing={setIsAddModalShowing}
          variant={variant}
        />
      )}
      {variant === "accordion-table" && info ? (
        <>
          <RepLegModalContent
            title={t(`bpm.onboarding.backoffice.user.buttonEdit`)}
            isModalShowing={isModalShowing}
            setIsModalShowing={setIsModalShowing}
            info={info}
            handleSubmitWrapperLink={handleSubmitWrapperLink}
          />
        </>
      ) : (
        info && (
          <BenefModalContent
            title={t(`bpm.onboarding.backoffice.user.buttonEdit`)}
            variant={benefVariant}
            isModalShowing={isModalShowing}
            setIsModalShowing={setIsModalShowing}
            info={info}
            isEni={list[0].info.get("eni")?.value === "eni"}
          ></BenefModalContent>
        )
      )}
      {variant === "accordion-table" && (
        <Text text={replegSubHeaderText}></Text>
      )}
      {variant === "accordion-benef" && <Text text={benefSubHeaderText}></Text>}
      {list &&
        Object.keys(list).length !== 0 &&
        list.map((item, index) => {

          const procDocNumberInputField = {
            value: "",
            obligatory: false,
            variable: "benef_numdocumento_edit",
            maxlenght: 15,
            text: t(`bpm.onboarding.backoffice.user.labelDocumentNumber`),
          };
          var currentNif;
          if (variant === "accordion-benef") {
            currentNif = item.info.get(
              "bpm.onboarding.backoffice.user.labelNIF"
            ).value;
            if (parseInt(currentNif.charAt(0)) < 5) isPersonal = true;
            else isPersonal = false;
          }

          return (
            <>
              {item.info.get("Nome").value != "" && (
                <div className="easypay-accordion" key={index}>
                  <div
                    className="easypay-accordion-header"
                    onClick={() => {
                      handleHeaderOnClick(index, isPersonal);
                    }}
                  >
                    <div className="easypay-accordion-top-content-container">
                      <div className="easypay-id">{nextChar(badge)}</div>
                      <div className="easypay-title">
                        {variant === "accordion-benef"
                          ? item.info.get("Nome").value.split("::")[0]
                          : item.info.get("Nome").value}
                      </div>
                      {variant === "accordion-table" &&
                        item.info.get("Cargo").value &&
                        item.info.get("Cargo").value != "null" && (
                          <Tag
                            className="easypay-accordion-tag"
                            variant="easypay-tag"
                            text={item.info.get("Cargo").value}
                            type="easypay-normal"
                          ></Tag>
                        )}
                    </div>
                    <div className="easypay-image-container">
                      {false === "true" && (
                        <img
                          className="easypay-accordion-checkmark-img"
                          src={greenCheckmark}
                        />
                      )}
                      <img
                        className={
                          tabSelected === index
                            ? `${"easypay-accordion-btn-img"} active`
                            : "easypay-accordion-btn-img"
                        }
                        src={
                          tabSelected === index ? arrowUpBlue : arrowDownGrey
                        }
                      />
                    </div>
                  </div>
                  {tabSelected === index && (
                    <div>
                      <div className="easypay-accordion-content-card">
                        {item.content &&
                          Object.keys(item.content).length !== 0 &&
                          item.content.map((currentItem, index) => {
                            if (
                              currentItem.value.length > 0 &&
                              currentItem.value != "" &&
                              currentItem.value != null &&
                              currentItem.title != "pda" &&
                              currentItem.title != "ids" &&
                              currentItem.title != "eni" &&
                              currentItem.title != "pv" &&
                              currentItem.title != "isProc"&&
                              currentItem.title != "final"
                            )
                              return (
                                <>
                                  {currentItem.title ===
                                  "bpm.onboarding.backoffice.user.labelIndirectCapital" ? (
                                    <div className="easypay-capital-empresa-wrapper">
                                      <div
                                        className="easypay-capital-wrapper"
                                        style={{ width: "50%" }}
                                      >
                                        <Text
                                          text={
                                            currentItem.title +
                                            "::card-text-no-padding"
                                          }
                                        />
                                        <Text
                                          text={
                                            currentItem.value +
                                            "::card-text-light"
                                          }
                                        />
                                      </div>
                                      <div
                                        className="easypay-empresa-wrapper"
                                        style={{ width: "50%" }}
                                      >
                                        <Text
                                          text={
                                            t(
                                              `bpm.onboarding.backoffice.user.labelCompany`
                                            ) + "::card-text-no-padding"
                                          }
                                        />
                                        <Text
                                          text={
                                            item.info.get(
                                              "bpm.onboarding.backoffice.user.labelCompany"
                                            ).value + "::card-text-light"
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    canShow(currentItem.title, item) && (
                                      <>
                                        {currentItem.title ===
                                          "bpm.onboarding.backoffice.user.labelNIF" &&
                                        !isPersonal &&
                                        variant != "accordion-table" ? (
                                          <Text
                                            text={
                                              t(
                                                `bpm.onboarding.backoffice.user.labelNIPC`
                                              ) + "::card-text-no-padding"
                                            }
                                          />
                                        ) : (
                                          <Text
                                            text={
                                              t(currentItem.title) +
                                              "::card-text-no-padding"
                                            }
                                          />
                                        )}
                                        <Text
                                          text={
                                            currentItem.value +
                                            "::card-text-light"
                                          }
                                        />
                                      </>
                                    )
                                  )}
                                </>
                              );
                          })}
                        {variant === "accordion-table" ? (
                          <div>
                            {item.info.get("isProc").value != "1" && (
                              <>
                                <PEPCheckbox
                                  checked={item.info.get("PEPCheckbox")}
                                  input={item.info.get("PEPInput")}
                                  variable={"repleg_pep_edit"}
                                ></PEPCheckbox>
                                <ReplegToggleAccordion
                                  info={item.info}
                                  entityType={entityType}
                                ></ReplegToggleAccordion>
                              </>
                            )}
                          </div>
                        ) : (
                          <div>
                            {isPersonal &&
                              item.info.get(
                                "bpm.onboarding.backoffice.user.labelDocumentNumber"
                              ).value === "" &&
                              item.info.get(
                                "bpm.onboarding.backoffice.user.labelIDDocument"
                              ).value === "" && (
                                <div>
                                  <Text
                                    text={
                                      t(
                                        `bpm.onboarding.backoffice.administration.headerDocType`
                                      ) + "::card-text-no-padding"
                                    }
                                  />
                                  <div
                                    className={`easypay-accordion-radio-button-wrapper ${"column"}`}
                                  >
                                    <BenefModalRadioButtonWrapper
                                      key={uuid()}
                                      variant={"easypay-radio-button-column"}
                                      activeTab={currentDocTypeTab}
                                      setActivetab={setCurrentDocTypeTab}
                                      displayType={"column"}
                                    >
                                      <RadioButtonItem
                                        key={uuid()}
                                        text={t(
                                          `bpm.onboarding.backoffice.user.labelCitizenCard`
                                        )}
                                        uniqueName={"docTypeAccordion" + index}
                                        index={0}
                                        name={t(
                                          `bpm.onboarding.backoffice.user.labelCitizenCard`
                                        )}
                                        subHeader={""}
                                        checked
                                        displayType={"column"}
                                      ></RadioButtonItem>
                                      <RadioButtonItem
                                        key={uuid()}
                                        text={t(
                                          `bpm.onboarding.backoffice.user.labelPassport`
                                        )}
                                        uniqueName={"docTypeAccordion" + index}
                                        index={1}
                                        name={t(
                                          `bpm.onboarding.backoffice.user.labelPassport`
                                        )}
                                        subHeader={""}
                                        displayType={"column"}
                                      ></RadioButtonItem>
                                      <RadioButtonItem
                                        key={uuid()}
                                        text={t(
                                          `bpm.onboarding.backoffice.user.labelIdentityTicket`
                                        )}
                                        index={2}
                                        uniqueName={"docTypeAccordion" + index}
                                        name={t(
                                          `bpm.onboarding.backoffice.user.labelIdentityTicket`
                                        )}
                                        subHeader={""}
                                        displayType={"column"}
                                      ></RadioButtonItem>
                                      <RadioButtonItem
                                        key={uuid()}
                                        text={t(
                                          `bpm.onboarding.backoffice.user.labelResidenceTitle`
                                        )}
                                        index={3}
                                        uniqueName={"docTypeAccordion" + index}
                                        name={t(
                                          `bpm.onboarding.backoffice.user.labelResidenceTitle`
                                        )}
                                        subHeader={""}
                                        displayType={"column"}
                                      ></RadioButtonItem>
                                      <RadioButtonItem
                                        key={uuid()}
                                        text={t(
                                          `bpm.onboarding.backoffice.user.labelResidenceCard`
                                        )}
                                        index={4}
                                        uniqueName={"docTypeAccordion" + index}
                                        name={t(
                                          `bpm.onboarding.backoffice.user.labelResidenceCard`
                                        )}
                                        subHeader={""}
                                        displayType={"column"}
                                      ></RadioButtonItem>
                                    </BenefModalRadioButtonWrapper>
                                  </div>
                                  <Input
                                    field={procDocNumberInputField}
                                    key={procDocNumberInputField.variable}
                                  ></Input>
                                </div>
                              )}
                            <PEPCheckbox
                              checked={item.info.get("PEPCheckbox")}
                              input={item.info.get("PEPInput")}
                              variable={"benef_pep_edit"}
                            ></PEPCheckbox>
                          </div>
                        )}
                      </div>
                      <div className="easypay-accordion-bottom-button-wrapper">
                        <div
                          className="easypay-accordion-add-link-wrapper"
                          style={{ paddingLeft: "20px" }}
                          onClick={(event) => {
                            event.preventDefault();
                            setIsModalShowing(true);
                            setInfo(item.info);
                            setBenefVariant(
                              currentNif?.charAt(0) < 5
                                ? "Individual"
                                : "Empresa"
                            );
                          }}
                        >
                          {addLinkText != "none" && (
                            <Link
                              text={t(
                                `bpm.onboarding.backoffice.user.buttonEdit`
                              )}
                              disabledColored={false}
                              variant={"easypay-edit"}
                              disabled={false}
                              link=""
                              key={uuid()}
                            />
                          )}
                        </div>
                        <div
                          className="easypay-accordion-remove-link-wrapper"
                          style={{ paddingLeft: "20px" }}
                        >
                          {removeLinkText &&
                            checkCanAdd() &&
                            list[0].info.get("eni")?.value != "eni" && (
                              <CustomButton
                                text={t(
                                  `bpm.onboarding.backoffice.user.buttonRemove`
                                )}
                                variant={"easypay-button-remove"}
                                onclick={eval(removeButtonField.onclick)}
                                key={uuid()}
                                id={removeButtonField.id}
                                className="card-button"
                                align={removeButtonField.align}
                                disabledColored={false}
                                disabled={false}
                                field={removeButtonField}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      {checkCanAdd() && list[0].info.get("eni")?.value != "eni" && (
        <CustomButton
          text={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
          variant={"easypay-button-add"}
          onclick={handleSetAddModal}
          key={uuid()}
          id={addButtonField.id}
          className="card-button"
          align={addButtonField.align}
          disabledColored={false}
          disabled={false}
          field={addButtonField}
        />
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CustomButton
          text={"Finalizar"}
          variant={"easypay-button-primary"}
          key={uuid()}
          onclick={finalizeButtonField.onclick}
          id={finalizeButtonField.id}
          className="card-button"
          align={finalizeButtonField.align}
          disabledColored={false}
          disabled={finalizeDisabled()}
          field={finalizeButtonField}
        />
        <CustomButton
          text={t(`bpm.onboarding.backoffice.user.buttonSubmit`)}
          variant={"easypay-button-primary"}
          key={uuid()}
          onclick={submitButtonField.onclick}
          id={submitButtonField.id}
          className="card-button"
          align={submitButtonField.align}
          disabledColored={false}
          disabled={false}
          field={submitButtonField}
        />
      </div>
    </>
  );
};

export default withNamespaces()(CustomAccordion);
