import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import "../assets/scss/_liftworldTableInput.scss";

const LiftworldTableInput = ({
  field,
  type,
  tableType,
  name,
  onChange,
  size,
  position,
  pattern,
  currentIndex,
  itemIndex,
  activityIndex,
  optionIndex,
  workplanIndex,
  fontColor,
  number,
  updateHoursCalculations,
  setUpdateHoursCalculations,
}) => {
  const [textInputValue, setTextInputValue] = useState("");

  const dispatch = useDispatch();
  const {
    workplans,
    expenses,
    collaborators,
    providerTypes,
    expenditureTypes,
    projectTypes,
  } = useSelector((state) => state.budgetManagementReducer);
  var gpi = 1.0;
  var fse = 1.0;
  var pc = 1.0;
  var pv = 1.0;

  useEffect(() => {
    if (field) {
      if (field.value) setTextInputValue(field.value);
    }
  }, [field]);

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  useEffect(() => {
    if(updateHoursCalculations){
      workplans[workplanIndex].isChanged = true;
      workplans[workplanIndex].resumoFinaceiro.toChange = 1;
      handleChangedValue()
      setUpdateHoursCalculations(false)
    }
  }, [updateHoursCalculations]);

  function handleChangedValue() {
    if (itemIndex != null && activityIndex != null && optionIndex != null) {
      workplans[workplanIndex].resumoFinaceiro.toChange = 1;
      if (tableType === "horas") {
        const currentColabId =
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].horas[currentIndex].colaborador;

        if (
          collaborators?.length != 0 &&
          collaborators != null &&
          collaborators != undefined
        ) {
          const colab = collaborators.find(
            (x) => x.idColaborador === parseInt(currentColabId)
          );

          if (colab != null) {
            pc = colab.precoCompra;
            pv = colab.precoVenda;
          }
        } else {
          pc = 0;
          pv = 0;
        }
        if (
          expenses?.length != 0 &&
          expenses != null &&
          expenses != undefined
        ) {
          const expGPI = expenses.find(
            (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
          );
          if (expGPI != null) gpi = expGPI.percentagem / 100;

          const expFSE = expenses.find((x) => x.tipo === "FSE");
          if (expFSE != null) fse = expFSE.percentagem / 100;
        } else {
          fse = 0;
          gpi = 0;
        }
        if (name === "horasPropostas") {
          const hoursInfo = textInputValue.split(":");
          if (hoursInfo.length != 2) {
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].horas[currentIndex][name] = "00:00";
          } else {
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].horas[currentIndex][name] = textInputValue;
          }
        } else {
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].horas[currentIndex][name] = textInputValue;
        }
        if (name === "horasPropostas" || name === "valorAdicional") {
          workplans[workplanIndex].resumoFinaceiro.toChange = 1;

          const currentHoras =
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].horas[currentIndex];

          const currentHorasPropostas =
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].horas[currentIndex].horasPropostas;

          if (currentHorasPropostas === undefined) {
            return;
          }
          if ((currentHorasPropostas?.split(":")).length === 0) {
            return;
          }
          const hoursArray = currentHoras.horasPropostas.split(":");
          var newHoras =
            parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

          var newVVP = 0;
          var newCHP = 0;
          var newMargem = 0;
          if (
            currentHoras.valorAdicional != undefined &&
            currentHoras.valorAdicional != 0
          ) {
            newVVP =
              parseFloat(newHoras) * pv +
              parseFloat(currentHoras.valorAdicional);
            const sub = parseFloat(
              newVVP - parseFloat(currentHoras.valorAdicional)
            );
            const sub2 = parseFloat(
              newVVP - parseFloat(currentHoras.valorAdicional)
            );
            newCHP = parseFloat(newHoras) * pc + sub * gpi;
            newCHP = newCHP + sub2 * fse;

            if (newVVP === 0) {
              newMargem = 0;
            } else {
              newMargem = 100 - (newCHP * 100) / newVVP;
            }
          } else {
            newVVP = parseFloat(newHoras) * pv;

            newCHP = parseFloat(newHoras) * pc + gpi * newVVP + fse * newVVP;
            if (newVVP === 0) {
              newMargem = 0;
            } else {
              newMargem = 100 - (newCHP * 100) / newVVP;
            }
          }

          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].horas[currentIndex].valorVendaHoras =
            newVVP.toFixed(2);

          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].horas[currentIndex].custosHoras =
            newCHP.toFixed(2);

          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].horas[currentIndex].margemHoras =
            newMargem.toFixed(2);
        }
      }
      if (tableType === "fornecedores") {
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].fornecedores[currentIndex][name] = textInputValue;
        if (
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].fornecedores[currentIndex]
            .margemSemPrecoVendaForn === ""
        ) {
          const provType = providerTypes.find(
            (x) =>
              x.idTipoFornecedor ===
              parseInt(
                workplans[workplanIndex].items[itemIndex].atividades[
                  activityIndex
                ].opcoes[optionIndex].fornecedores[currentIndex].tipoFornecedor
              )
          );
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].fornecedores[currentIndex].margemForn =
            provType?.percentagem;
        }
        if (
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn ===
            "" ||
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn ===
            0 ||
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn ===
            undefined
        ) {
          if (
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].fornecedores[currentIndex]
              .margemSemPrecoVendaForn != ""
          )
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].fornecedores[currentIndex].margemForn =
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].fornecedores[
                currentIndex
              ].margemSemPrecoVendaForn;

          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].fornecedores[currentIndex].valorVendaForn = (
            parseFloat(
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].fornecedores[currentIndex].precoCompraForn
            ) /
            (1 -
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].fornecedores[currentIndex].margemForn /
                100)
          ).toFixed(2);
        } else if (
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn !=
            "" &&
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn !=
            undefined
        ) {
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].fornecedores[currentIndex].valorVendaForn =
            parseFloat(
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn
            ).toFixed(2);
          const currrentPC = parseFloat(
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].fornecedores[currentIndex].precoCompraForn
          );
          const currrentVV =
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].fornecedores[currentIndex].valorVendaForn;

          if (currrentVV != 0) {
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].fornecedores[currentIndex].margemForn = (
              ((currrentVV - currrentPC) / currrentVV) *
              100
            ).toFixed(2);
          }
        } else {
          const provType = providerTypes.find(
            (x) =>
              x.idTipoFornecedor ===
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].fornecedores[currentIndex].tipoFornecedor
          );
          if (provType != null) {
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].fornecedores[currentIndex].valorVendaForn =
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].fornecedores[currentIndex].precoCompraForn /
              (1 - provType.percentagem / 100);
          }
        }
      }
      if (tableType === "despesas") {
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].despesas[currentIndex][name] = textInputValue;
        if (
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].despesas[currentIndex]
            .margemSemPrecoVendaDesp === ""
        ) {
          const expType = expenditureTypes.find(
            (x) =>
              x.idTipoDespesa ===
              parseInt(
                workplans[workplanIndex].items[itemIndex].atividades[
                  activityIndex
                ].opcoes[optionIndex].despesas[currentIndex].tipoDespesa
              )
          );
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].despesas[currentIndex].margemDesp =
            expType?.percentagem;
        }
        if (
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].despesas[currentIndex].precoVendaDesp === "" ||
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].despesas[currentIndex].precoVendaDesp === 0 ||
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].despesas[currentIndex].precoVendaDesp ===
            undefined
        ) {
          if (
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].despesas[currentIndex]
              .margemSemPrecoVendaDesp != "" &&
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].despesas[currentIndex]
              .margemSemPrecoVendaDesp != undefined
          )
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].despesas[currentIndex].margemDesp =
              parseFloat(
                workplans[workplanIndex].items[itemIndex].atividades[
                  activityIndex
                ].opcoes[optionIndex].despesas[currentIndex]
                  .margemSemPrecoVendaDesp
              );
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].despesas[currentIndex].valorVendaDesp =
            parseFloat(
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].despesas[currentIndex].precoCompraForn
            ) /
            (1 -
              parseFloat(
                workplans[workplanIndex].items[itemIndex].atividades[
                  activityIndex
                ].opcoes[optionIndex].despesas[currentIndex].margemDesp
              ) /
                100);
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].despesas[currentIndex].valorVendaDesp =
            parseFloat(
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].despesas[currentIndex].valorVendaDesp
            ).toFixed(2);
        } else if (
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].despesas[currentIndex].precoVendaDesp != "" &&
          workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
            .opcoes[optionIndex].despesas[currentIndex].precoVendaDesp !=
            undefined
        ) {
          workplans[workplanIndex].items[itemIndex].atividades[
            activityIndex
          ].opcoes[optionIndex].despesas[currentIndex].valorVendaDesp =
            parseFloat(
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].despesas[currentIndex].precoVendaDesp
            ).toFixed(2);
          const currrentPC = parseFloat(
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].despesas[currentIndex].precoCompraForn
          );
          const currrentVV =
            workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
              .opcoes[optionIndex].despesas[currentIndex].valorVendaDesp;

          if (currrentVV != 0) {
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].despesas[currentIndex].margemDesp = (
              ((currrentVV - currrentPC) / currrentVV) *
              100
            ).toFixed(2);
          }
        } else {
          const despType = expenditureTypes.find(
            (x) =>
              x.idTipoDespesa ===
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].despesas[currentIndex].tipoDespesa
          );
          if (despType != null) {
            workplans[workplanIndex].items[itemIndex].atividades[
              activityIndex
            ].opcoes[optionIndex].despesas[currentIndex].valorVendaDesp =
              workplans[workplanIndex].items[itemIndex].atividades[
                activityIndex
              ].opcoes[optionIndex].despesas[currentIndex].precoCompraForn /
              (1 - despType.percentagem / 100);
          }
        }
      }

      var newOptionCostValue = 0;
      var newOptionSaleValue = 0;
      const currentHoras =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas;
      const currentForn =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].fornecedores;
      const currentDespesas =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].despesas;
      for (var i = 0; i < currentHoras.length; i++) {
        newOptionCostValue += parseFloat(currentHoras[i].custosHoras);
        newOptionSaleValue += parseFloat(currentHoras[i].valorVendaHoras);
      }
      for (var i = 0; i < currentForn.length; i++) {
        newOptionCostValue += parseFloat(currentForn[i].precoCompraForn);
        newOptionSaleValue += parseFloat(currentForn[i].valorVendaForn);
      }
      for (var i = 0; i < currentDespesas.length; i++) {
        newOptionCostValue += parseFloat(currentDespesas[i].precoCompraForn);
        newOptionSaleValue += parseFloat(currentDespesas[i].valorVendaDesp);
      }

      if (!(newOptionCostValue instanceof String))
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].valorCustoOpt = newOptionCostValue?.toFixed(2);

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].valorVendaOpt = newOptionSaleValue?.toFixed(2);

      var currentActivityCost = 0;
      var currentActivitySale = 0;

      var currentItemCost = 0;
      var currentItemSale = 0;

      const currentOptions =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes;

      for (var i = 0; i < currentOptions.length; i++) {
        if (currentOptions[i].selected) {
          currentActivityCost += parseFloat(currentOptions[i].valorCustoOpt);
          currentActivitySale += parseFloat(currentOptions[i].valorVendaOpt);
        }
      }

      if (currentActivityCost === 0 && currentActivitySale === 0) {
        currentActivityCost = parseFloat(currentOptions[0].valorCustoOpt);
        currentActivitySale = parseFloat(currentOptions[0].valorVendaOpt);
      }

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].valorCustoAct = currentActivityCost;
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].valorVendaAct = currentActivitySale;

      const currentActivities =
        workplans[workplanIndex].items[itemIndex].atividades;

      for (var i = 0; i < currentActivities.length; i++) {
        currentItemCost += parseFloat(currentActivities[i].valorCustoAct);
        currentItemSale += parseFloat(currentActivities[i].valorVendaAct);
      }

      workplans[workplanIndex].items[itemIndex].valorCustoObj =
        parseFloat(currentItemCost);
      workplans[workplanIndex].items[itemIndex].valorVendaObj =
        parseFloat(currentItemSale);

      dispatch(setAllWorkplans(workplans));
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChangedValue();
    }
  };

  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      style={{ margin: "0px" }}
    >
      <div className={`${"liftworldtable-input-wrapper-wrapper"} ` + size}>
        <div
          className={`liftworldtable-input-wrapper ${
            field.maxlength && "liftworld-table-input-size-" + field.maxlength
          }  `}
          tabIndex="1"
        >
          <div
            className={`${"liftworldtable-input-field-wrapper"} ` + position}
          >
            <input
              className={`liftworldtable-input-field ${
                fontColor ? fontColor : ""
              } ${number}`}
              type={type && type}
              pattern={pattern ? pattern : ""}
              name={name !== undefined ? name : field.variable}
              value={type === "password" ? "palavra-pass" : textInputValue}
              maxLength={field.maxlength}
              onChange={(e) => {
                setTextInputValue(e.target.value);
                onChange && onChange(e.target.value, name);
              }}
              onBlur={() => handleChangedValue()}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {`O campo é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </div>
      </div>
    </Form.Group>
  );
};
export default withNamespaces()(LiftworldTableInput);
