import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import { FaTrash, FaRegEdit } from "react-icons/fa";
const CreateActionColumn = (props) => {
  const {
    t,
    row,
    app,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleAtivateInactivate,
  } = props;

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [showPreview, setShowPreview] = useState(false);

  /*Get flow Columns defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/tarefas` || item.route === `${app}/tasks`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );

        let showPreview_;
        showPreview_ = extraConfiguration_.preview === "false" ? false : true;

        setShowPreview(showPreview_);
      }
    }
  }, [menu]);

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const getIconsEdit = () => {
    return (
      <div className="table-icon-wrapper">
        <FaRegEdit
          onClick={(e) => {
            handleOpenEditModal(row);
          }}
        />
      </div>
    );
  };
  const getIconsDelete = () => {
    return (
      <div className="table-icon-wrapper">
        <FaTrash
          onClick={(e) => {
            handleOpenDeleteModal(row);
          }}
        />
      </div>
    );
  };


  return (
    <div className="action-wrapper" style={{ justifyContent: "center" }}>
      <div style={{ display: "flex" }}>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("taskPage.general.proceed"))}
        >
          {getIconsEdit()}
        </OverlayTrigger>
      </div>
    </div>
  );
};
export default withNamespaces()(CreateActionColumn);
