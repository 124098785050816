import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "react-addons-update";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { pagination } from "~/components/pagination/currentSizePerPage/8";
import uuid from "uuid/v1";

const TemplateTableList = ({ template, action, actionIcon, t }) => {
  const { isLoadingTemplates } = useSelector(
    (state) => state.processesReducer
  );
  const { SearchBar } = Search;
  const buildActionsColumn = (_, row, {}) => (
    <i
      className={`${actionIcon} table-action-icon`}
      onClick={() => action(row)}
    />
  );
  const columns = [
    { dataField: "id", text: "ID", hidden: true },
    { dataField: "name", text: t("portalrh.rhadmin.gestaoTemplates.perfis") },
    {
      dataField: "accao",
      text: t("portalrh.rhadmin.gestaoTemplates.action"),
      formatter: buildActionsColumn,
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  return (
    <div className="custom-table">
      <ToolkitProvider
        keyField="id"
        data={template}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <SearchBar
              {...props.searchProps}
              placeholder={t("portalrh.rhadmin.gestaoTemplates.searchInput")}
            />
            <hr />
            {!isLoadingTemplates && (
              <BootstrapTable
                key={uuid()}
                {...props.baseProps}
                bootstrap4
                noDataIndication={t("portalrh.rhadmin.gestaoTemplates.emptyTable")}
                pagination={paginationFactory(pagination)}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(TemplateTableList);
