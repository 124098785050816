import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/scss/_option.scss";
import "../assets/scss/_projectDescriptionSection.scss";
import TableTextbox from "./TableTextbox";

const ProposalHoursTable = ({
  info,
  workplanIndex,
  percentage,
  initialHours,
  newTotalHours,
}) => {
  const dispatch = useDispatch();
  const {
    workplans,
    collaborators,
    departments,
    categories,
    functions,
    expenses,
    triggerLoading,
  } = useSelector((state) => state.budgetManagementReducer);
  var [currentHoursList, setCurrentHoursList] = useState([]);

  var [totalHoursValue, setTotalHoursValue] = useState("");
  var [totalVAP, setTotalVAP] = useState(0);
  var [totalCHP, setTotalCHP] = useState(0);
  var [totalVVP, setTotalVVP] = useState(0);
  var [totalMargem, setTotalMargem] = useState(0);

  function convertIdToName(id, name) {
    if (name === "departments") {
      if (
        departments?.length === 0 ||
        departments === null ||
        departments === undefined
      )
        return "";
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    } else if (name === "collaborators") {
      if (
        collaborators?.length === 0 ||
        collaborators === null ||
        collaborators === undefined
      )
        return "";
      const colab = collaborators.find((x) => x.idColaborador === parseInt(id));
      if (colab != null) return colab?.nome;
    } else if (name === "categories") {
      if (
        categories?.length === 0 ||
        categories === null ||
        categories === undefined
      )
        return "";
      const cat = categories.find((x) => x.idCategoria === parseInt(id));
      if (cat != null) return cat?.nome;
    } else if (name === "functions") {
      const func = functions.find((x) => x.idFuncao === parseInt(id));
      if (func != null) return func?.nome;
    }
  }

  useEffect(() => {
    var newHoursArray = [];
    var currentHorasValue = 0;
    var currentVAP = 0;
    var currentCHP = 0;
    var currentVVP = 0;
    var currentMargem = 0;
    var gpi = 1.0;
    var fse = 1.0;

    if (expenses?.length != 0 && expenses != null && expenses != undefined) {
      const expGPI = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = expenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
    }

    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ]
              );
              currentHours.item = i + 1;
              currentHours.activity = j + 1;
              currentHours.option = k + 1;
              if (
                newTotalHours != null &&
                newTotalHours != 0 &&
                newTotalHours != "" &&
                newTotalHours != "0"
              ) {
                const newPercentage = newTotalHours / initialHours;
                currentHours.valorVendaHoras =
                  currentHours.valorVendaHoras * newPercentage;
                currentHours.valorAdicional =
                  currentHours.valorAdicional * newPercentage;
                const colab = collaborators.find(
                  (x) => x.idColaborador === parseInt(currentHours.colaborador)
                );
                if (colab != null && colab != undefined) {
                  const currentHorasArray =
                    currentHours.horasPropostas?.split(":");
                  var newCalculatedHoras =
                    (parseInt(currentHorasArray[0]) +
                      parseInt(currentHorasArray[1]) / 60) *
                    newPercentage;
                  var n = new Date(0, 0);
                  n.setSeconds(+newCalculatedHoras * 60 * 60);
                  const hoursStringArray = newCalculatedHoras
                    .toString()
                    .split(".");
                  var minutes = "0." + hoursStringArray[1];
                  minutes = Math.ceil(parseFloat(minutes) * 60);
                  minutes = minutes.toString();
                  if (minutes.length === 1) {
                    minutes = "0" + minutes;
                  }
                  const newHours = hoursStringArray[0] + ":" + minutes;
                  currentHours.horasPropostas = newHours;

                  if (
                    currentHours.valorAdicional != undefined &&
                    currentHours.valorAdicional != 0
                  ) {
                    const sub = parseFloat(
                      currentHours.valorVendaHoras -
                        parseFloat(currentHours.valorAdicional)
                    );
                    const sub2 = parseFloat(
                      currentHours.valorVendaHoras -
                        parseFloat(currentHours.valorAdicional)
                    );
                    currentHours.custosHoras =
                      parseFloat(newCalculatedHoras) * colab.precoCompra +
                      sub * gpi;
                    currentHours.custosHoras =
                      currentHours.custosHoras + sub2 * fse;

                    currentHours.margemHoras =
                      100 -
                      (currentHours.custosHoras * 100) /
                        currentHours.valorVendaHoras;
                  } else {
                    currentHours.custosHoras =
                      parseFloat(newCalculatedHoras) * colab.precoCompra +
                      gpi * currentHours.valorVendaHoras +
                      fse * currentHours.valorVendaHoras;
                    currentHours.margemHoras =
                      100 -
                      (currentHours.custosHoras * 100) /
                        currentHours.valorVendaHoras;
                  }
                }
              } else if (
                percentage != null &&
                percentage != 0 &&
                percentage != "" &&
                percentage != "0"
              ) {
                currentHours.valorVendaHoras =
                  currentHours.valorVendaHoras * percentage;
                currentHours.valorAdicional =
                  currentHours.valorAdicional * percentage;
                const colab = collaborators.find(
                  (x) => x.idColaborador === parseInt(currentHours.colaborador)
                );
                const currentHorasArray =
                  currentHours.horasPropostas?.split(":");
                var newCalculatedHoras =
                  (parseInt(currentHorasArray[0]) +
                    parseInt(currentHorasArray[1]) / 60) *
                  percentage;
                var n = new Date(0, 0);
                n.setSeconds(+newCalculatedHoras * 60 * 60);
                const hoursStringArray = newCalculatedHoras
                  .toString()
                  .split(".");
                var minutes = "0." + hoursStringArray[1];
                minutes = Math.ceil(parseFloat(minutes) * 60);
                minutes = minutes.toString();
                if (minutes.length === 1) {
                  minutes = "0" + minutes;
                }
                const newHours = hoursStringArray[0] + ":" + minutes;
                currentHours.horasPropostas = newHours;

                if (
                  currentHours.valorAdicional != undefined &&
                  currentHours.valorAdicional != 0
                ) {
                  const sub = parseFloat(
                    currentHours.valorVendaHoras -
                      parseFloat(currentHours.valorAdicional)
                  );
                  const sub2 = parseFloat(
                    currentHours.valorVendaHoras -
                      parseFloat(currentHours.valorAdicional)
                  );
                  currentHours.custosHoras =
                    parseFloat(newCalculatedHoras) * colab.precoCompra +
                    sub * gpi;
                  currentHours.custosHoras =
                    currentHours.custosHoras + sub2 * fse;

                  currentHours.margemHoras =
                    100 -
                    (currentHours.custosHoras * 100) /
                      currentHours.valorVendaHoras;
                } else {
                  if (colab === null || colab === undefined) return;
                  currentHours.custosHoras =
                    parseFloat(newCalculatedHoras) * colab.precoCompra +
                    gpi * currentHours.valorVendaHoras +
                    fse * currentHours.valorVendaHoras;
                  currentHours.margemHoras =
                    100 -
                    (currentHours.custosHoras * 100) /
                      currentHours.valorVendaHoras;
                }
              }

              newHoursArray.push(currentHours);
              if (
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].horasPropostas === "" ||
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].horasPropostas === undefined ||
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].horasPropostas === 0
              )
                continue;
              const horasArray = currentHours.horasPropostas?.split(":");
              currentHorasValue +=
                parseInt(horasArray[0]) + parseInt(horasArray[1]) / 60;
              currentVAP += parseFloat(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].valorAdicional
              );
              currentCHP += parseFloat(currentHours.custosHoras);
              currentVVP += parseFloat(currentHours.valorVendaHoras);
              currentMargem += parseFloat(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].margemHoras
              );
            }
          }
        }
      }
    }
    if (newHoursArray.length === 0) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m]
                );
                currentHours.item = i + 1;
                currentHours.activity = j + 1;
                currentHours.option = k + 1;
                if (
                  newTotalHours != null &&
                  newTotalHours != 0 &&
                  newTotalHours != "" &&
                  newTotalHours != "0"
                ) {
                  const newPercentage = newTotalHours / initialHours;
                  currentHours.valorVendaHoras =
                    currentHours.valorVendaHoras * newPercentage;
                  currentHours.valorAdicional =
                    currentHours.valorAdicional * newPercentage;
                  const colab = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );
                  if (colab != null && colab != undefined) {
                    const currentHorasArray =
                      currentHours.horasPropostas?.split(":");
                    var newCalculatedHoras =
                      (parseInt(currentHorasArray[0]) +
                        parseInt(currentHorasArray[1]) / 60) *
                      newPercentage;
                    var n = new Date(0, 0);
                    n.setSeconds(+newCalculatedHoras * 60 * 60);
                    const hoursStringArray = newCalculatedHoras
                      .toString()
                      .split(".");
                    var minutes = "0." + hoursStringArray[1];
                    minutes = Math.ceil(parseFloat(minutes) * 60);
                    minutes = minutes.toString();
                    if (minutes.length === 1) {
                      minutes = "0" + minutes;
                    }
                    const newHours = hoursStringArray[0] + ":" + minutes;
                    currentHours.horasPropostas = newHours;

                    if (
                      currentHours.valorAdicional != undefined &&
                      currentHours.valorAdicional != 0
                    ) {
                      const sub = parseFloat(
                        currentHours.valorVendaHoras -
                          parseFloat(currentHours.valorAdicional)
                      );
                      const sub2 = parseFloat(
                        currentHours.valorVendaHoras -
                          parseFloat(currentHours.valorAdicional)
                      );
                      currentHours.custosHoras =
                        parseFloat(newCalculatedHoras) * colab.precoCompra +
                        sub * gpi;
                      currentHours.custosHoras =
                        currentHours.custosHoras + sub2 * fse;

                      currentHours.margemHoras =
                        100 -
                        (currentHours.custosHoras * 100) /
                          currentHours.valorVendaHoras;
                    } else {
                      currentHours.custosHoras =
                        parseFloat(newCalculatedHoras) * colab.precoCompra +
                        gpi * currentHours.valorVendaHoras +
                        fse * currentHours.valorVendaHoras;
                      currentHours.margemHoras =
                        100 -
                        (currentHours.custosHoras * 100) /
                          currentHours.valorVendaHoras;
                    }
                  }
                } else if (
                  percentage != null &&
                  percentage != 0 &&
                  percentage != "" &&
                  percentage != "0"
                ) {
                  currentHours.valorVendaHoras =
                    currentHours.valorVendaHoras * percentage;
                  currentHours.valorAdicional =
                    currentHours.valorAdicional * percentage;
                  const colab = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );
                  if (colab != null && colab != undefined) {
                    const currentHorasArray =
                      currentHours.horasPropostas?.split(":");
                    var newCalculatedHoras =
                      (parseInt(currentHorasArray[0]) +
                        parseInt(currentHorasArray[1]) / 60) *
                      percentage;
                    var n = new Date(0, 0);
                    n.setSeconds(+newCalculatedHoras * 60 * 60);
                    const hoursStringArray = newCalculatedHoras
                      .toString()
                      .split(".");
                    var minutes = "0." + hoursStringArray[1];
                    minutes = Math.ceil(parseFloat(minutes) * 60);
                    minutes = minutes.toString();
                    if (minutes.length === 1) {
                      minutes = "0" + minutes;
                    }
                    const newHours = hoursStringArray[0] + ":" + minutes;
                    currentHours.horasPropostas = newHours;

                    if (
                      currentHours.valorAdicional != undefined &&
                      currentHours.valorAdicional != 0
                    ) {
                      const sub = parseFloat(
                        currentHours.valorVendaHoras -
                          parseFloat(currentHours.valorAdicional)
                      );
                      const sub2 = parseFloat(
                        currentHours.valorVendaHoras -
                          parseFloat(currentHours.valorAdicional)
                      );

                      currentHours.custosHoras =
                        parseFloat(newCalculatedHoras) * colab.precoCompra +
                        sub * gpi;
                      currentHours.custosHoras =
                        currentHours.custosHoras + sub2 * fse;

                      currentHours.margemHoras =
                        100 -
                        (currentHours.custosHoras * 100) /
                          currentHours.valorVendaHoras;
                    } else {
                      currentHours.custosHoras =
                        parseFloat(newCalculatedHoras) * colab.precoCompra +
                        gpi * currentHours.valorVendaHoras +
                        fse * currentHours.valorVendaHoras;
                      currentHours.margemHoras =
                        100 -
                        (currentHours.custosHoras * 100) /
                          currentHours.valorVendaHoras;
                    }
                  }
                }
                newHoursArray.push(currentHours);

                const horasArray = currentHours.horasPropostas?.split(":");
                currentHorasValue +=
                  parseInt(horasArray[0]) + parseInt(horasArray[1]) / 60;
                currentVAP += parseFloat(currentHours.valorAdicional);
                currentCHP += parseFloat(currentHours.custosHoras);
                currentVVP += parseFloat(currentHours.valorVendaHoras);
                currentMargem += parseFloat(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m].margemHoras
                );
              }
            }
          }
        }
      }
    }

    var n = new Date(0, 0);
    n.setSeconds(+currentHorasValue * 60 * 60);
    const hoursStringArray = currentHorasValue.toString().split(".");
    var minutes = "0." + hoursStringArray[1];
    minutes = Math.ceil(parseFloat(minutes) * 60);
    minutes = minutes.toString();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    const newHours = hoursStringArray[0] + ":" + minutes;
    setTotalHoursValue(newHours);
    setTotalCHP(currentCHP);
    if (currentVVP === 0) {
      setTotalMargem(0);
    } else {
      setTotalMargem(
        (((currentVVP - currentCHP) / currentVVP) * 100).toFixed(2)
      );
    }
    setTotalVAP(currentVAP);
    setTotalVVP(currentVVP);

    setCurrentHoursList(newHoursArray);
  }, [currentHoursList.length === 0, workplans, triggerLoading]);

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function getPosition(index) {
    if (index === 0) return "start";
    if (index === hourList.length - 1) return "end";
  }

  const hourList = [
    "Atividade Principal",
    "Atividade Secundária",
    "Opção",
    "Colaborador",
    "Categoria",
    "Departamento",
    "Função",
    "Tarefa",
    "Horas Propostas",
    "Valor Adicional Proposto",
    "Custo Horas Propostas",
    "Valor de Venda Proposta",
    "Margem",
  ];

  return (
    <div className="liftworld-proposal-tables-wrapper">
      <div className="lifworld-table-wrapper">
        <div className="liftworld-table-header-wrapper">
          {hourList &&
            Object.keys(hourList).length !== 0 &&
            hourList.map((item, index) => {
              return (
                <>
                  {index < 3 ? (
                    <div className="liftworld-table-3-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  ) : (
                    <div className="liftworld-table-9-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
        <div>
          {currentHoursList &&
            Object.keys(currentHoursList).length !== 0 &&
            currentHoursList.map((item, index) => {
              return (
                <div className="liftworld-table-header-wrapper">
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.item}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.activity}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.option}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={convertIdToName(
                        item?.colaborador,
                        "collaborators"
                      )}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={convertIdToName(item?.categoria, "categories")}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={convertIdToName(
                        item?.departamentoHoras,
                        "departments"
                      )}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={convertIdToName(item?.idFuncao, "functions")}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox value={item?.tarefa} position={"middle"} />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={item?.horasPropostas.replace("NaN", "00")}
                      position={"middle"}
                      number={"hours"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.valorAdicional)) ||
                        item?.valorAdicional === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.valorAdicional).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.custosHoras)) ||
                        item?.custosHoras === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.custosHoras).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.valorVendaHoras)) ||
                        item?.valorVendaHoras === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.valorVendaHoras).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.margemHoras)) ||
                        item?.margemHoras === undefined
                          ? "0,00 %"
                          : numberWithCommas(
                              parseFloat(item?.margemHoras).toFixed(2)
                            ) + "%"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"start-end"} color={"colored"} />
          </div>
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={totalHoursValue.replace("NaN", "00")}
              position={"middle"}
              color={"colored"}
              number={"hours"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalVAP)) || totalVAP === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(totalVAP)?.toFixed(2)) + "€"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalCHP)) || totalCHP === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(totalCHP)?.toFixed(2)) + "€"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalVVP)) || totalVVP === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(totalVVP)?.toFixed(2)) + "€"
              }
              number={"number"}
              position={"middle"}
              color={"colored"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalMargem)) || totalMargem === undefined
                  ? "0,00 %"
                  : numberWithCommas(parseFloat(totalMargem)?.toFixed(2)) + "%"
              }
              number={"number"}
              position={"end"}
              color={"colored"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProposalHoursTable;
