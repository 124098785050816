import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getTaxData,
  getTaxDataDropdown,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { DateTime } from "luxon";
import ButtonsUpdate from "./ButtonsUpdate";
import DateTimePicker from "react-datetime-picker";

const RhTaxData = ({ rowCollaborator, t }) => {
  const [numbHolders, setNumbHolders] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [numbDependents, setNumbDependents] = useState("");
  const [numbDisabledDep, setNumbDisabledDep] = useState("");
  const [taxZoneList, setTaxZoneList] = useState([]);
  const [taxZoneSelected, setTaxZonaSelected] = useState("");
  const [maritalSelected, setMaritalSelected] = useState("");
  const [taxSelected, setTaxSelected] = useState("");
  const [checkRetiree, setCheckRetiree] = useState(false);
  const [checkDisabledSpouse, setCheckdisabledSpouse] = useState(false);
  const [checkDisabledHolder, setCheckDisabledHolder] = useState(false);
  const [checkAwaitsRetire, setCheckAwaitsRetire] = useState(false);
  const [checkActive, setCheckActive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  let listMarital = [
    { label: "Casado / União de Facto", value: "1" },
    { label: "Não Casado", value: "0" },
  ];

  let listTax = [
    { label: "Oficial", value: "1" },
    { label: "Fixa", value: "0" },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getTaxDataDropdown());
      dispatch(
        getTaxData(rowCollaborator.funcionarioNum, rowCollaborator.codEntidade)
      );
    }
  }, [rowCollaborator]);

  const { taxData, taxDataDropdown } = useSelector(
    (state) => state.personalReducer
  );

  const handleMaritalStatus = (e) => {
    setMaritalSelected(e);
  };

  const handleTax = (e) => {
    setTaxSelected(e);
  };
  const handleTaxZone = (e) => {
    setTaxZonaSelected(e);
  };

  const handleEffectiveDate = (e) => {
    setEffectiveDate(e);
  };

  const handleNumbHolders = (e) => {
    setNumbHolders(e.target.value);
  };

  const handleNumbDepedents = (e) => {
    setNumbDependents(e.target.value);
  };

  const handleNumbDisabledDepedents = (e) => {
    setNumbDisabledDep(e.target.value);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (
        data.length > 0 &&
        taxDataDropdown &&
        Object.keys(taxDataDropdown).length > 0
      ) {
        setNumbHolders(data[0].numberOfHolders);
        setNumbDependents(data[0].numberOfDependentes);
        setNumbDisabledDep(data[0].numberOfDependentesDef);

        setTaxZonaSelected(data[0].zona);
        setTaxSelected(data[0].taxOficial);
        setMaritalSelected(data[0].maritalStatus);

        setTaxZoneList(taxDataDropdown.taxZone);

        if (data[0].retiree == "1") {
          setCheckRetiree(true);
        } else {
          setCheckRetiree(false);
        }

        if (data[0].active == "1") {
          setCheckActive(true);
        } else {
          setCheckActive(false);
        }

        if (data[0].awaitingRetirement == "1") {
          setCheckAwaitsRetire(true);
        } else {
          setCheckAwaitsRetire(false);
        }

        if (data[0].disabledHolder == "1") {
          setCheckDisabledHolder(true);
        } else {
          setCheckDisabledHolder(false);
        }

        if (data[0].disabledSpouse == "1") {
          setCheckdisabledSpouse(true);
        } else {
          setCheckdisabledSpouse(false);
        }

        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          }
        };
        checkDate(data[0].effectiveDate, setEffectiveDate);
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(taxData);
    }
  }, [checkDisabledHolder, taxData, rowClicked, taxDataDropdown]);

  const formatWithCheckIcon = (cell, row) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const formatEndData = (cell) => {
    if (cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const formatTax = (cell, row) => {
    if (cell !== null) {
      for (let i = 0; i < listTax.length; i++) {
        if (cell == listTax[i].value) {
          return <p>{listTax[i].label}</p>;
        }
      }
    }
  };

  const columns = [
    {
      dataField: "taxOficial",
      text: t(`portalrh.TaxData.Tax`),
      sort: true,
      formatter: formatTax,
      style: { fontSize: "12px" },
    },
    {
      dataField: "effectiveDate",
      text: t(`portalrh.TaxData.EffectiveDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
    {
      dataField: "active",
      text: t(`portalrh.TaxData.Active`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.TaxZone`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={taxZoneList}
              handleSubmit={handleTaxZone}
              defaultValue={taxZoneSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col md="3" xl="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.Tax`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={listTax}
              handleSubmit={handleTax}
              defaultValue={taxSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col md="3" xl="2">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.MaritalStatus`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={listMarital}
              handleSubmit={handleMaritalStatus}
              defaultValue={maritalSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="4" xl="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.EffectiveDate`)}
            </Form.Label>
            <DateTimePicker
              value={effectiveDate}
              onChange={handleEffectiveDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="3" xl="2">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.NumberofHolders`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={numbHolders == null ? "" : numbHolders}
              onChange={handleNumbHolders}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col md="3" xl="2">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.NumberofDependents`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={numbDependents == null ? "" : numbDependents}
              onChange={handleNumbDepedents}
              disabled={isClicked ? false : true}
            />
          </Col>

          <Col md="4" xl="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.TaxData.NumberofDisabledDependents`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={numbDisabledDep == null ? "" : numbDisabledDep}
              onChange={handleNumbDisabledDepedents}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <Col>
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.AwaitingRetirement`)}
            checked={checkAwaitsRetire}
            style={{ paddingBottom: 10 }}
            onChange={() =>
              setCheckAwaitsRetire((checkAwaitsRetire) => !checkAwaitsRetire)
            }
            disabled={isClicked ? false : true}
          />
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.Retired`)}
            checked={checkRetiree}
            style={{ paddingBottom: 10 }}
            onChange={() => setCheckRetiree((checkRetiree) => !checkRetiree)}
            disabled={isClicked ? false : true}
          />
        </Col>
        <Col>
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.DisabledHolder`)}
            checked={checkDisabledHolder}
            style={{ paddingBottom: 10 }}
            onChange={() =>
              setCheckDisabledHolder(
                (checkDisabledHolder) => !checkDisabledHolder
              )
            }
            disabled={isClicked ? false : true}
          />
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.DisabledSpouse`)}
            checked={checkDisabledSpouse}
            style={{ paddingBottom: 10 }}
            onChange={() =>
              setCheckdisabledSpouse(
                (checkDisabledSpouse) => !checkDisabledSpouse
              )
            }
            disabled={isClicked ? false : true}
          />
        </Col>
        <Col>
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.Active`)}
            checked={checkActive}
            style={{ paddingBottom: 10 }}
            onChange={() => setCheckActive((checkActive) => !checkActive)}
            disabled={isClicked ? false : true}
          />
        </Col>
        <RhTableList
          tableId={"37"}
          columns={columns}
          data={taxData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhTaxData);
