import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_tableInput.scss";
import uuid from "uuid/v1";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllWorkplans,
  setTriggerLoading,
} from "~/store/ducks/budgetManagement/actionTypes";

const TableInput = ({
  field,
  type,
  t,
  name,
  onChange,
  size,
  position,
  workplanIndex,
  disabled,
  changeDate,
  date,
  setIsModalErrorShowing,
  number,
  euro,
  resumoFactIndex,
  error
}) => {
  const [textInputValue, setTextInputValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (field) {
      if (field.value) setTextInputValue(field.value);
    }
  }, [field]);

  const { workplans } = useSelector((state) => state.budgetManagementReducer);


  function checkIsDate() {
    return (
      new Date(textInputValue) !== "Invalid Date" &&
      !isNaN(new Date(textInputValue))
    );
  }

  function compareTime(time1, time2) {
    return new Date(time1) > new Date(time2); // true if time1 is later
  }

  function updateState() {
    if (name === "" || name === null) return;

    if (workplanIndex != null) {
      if (name === "dataInicioProjeto" || name === "dataFinalProjeto") {
        const isDate = checkIsDate();
        if (textInputValue.length === 10 && isDate) {
          var dateStr = textInputValue;
          dateStr = dateStr.slice(0, -1);
          dateStr = dateStr.slice(0, -1);
          if (name === "dataInicioProjeto") dateStr = dateStr + "01";
          else dateStr = dateStr + "31";
          if (
            name === "dataInicioProjeto" &&
            workplans[workplanIndex].dataFinalProjeto?.length > 0
          ) {
            const isAfter = compareTime(
              workplans[workplanIndex].dataFinalProjeto,
              dateStr
            );
            if (isAfter) {
              workplans[workplanIndex][name] = dateStr;
            }
          } else if (
            name === "dataFinalProjeto" &&
            workplans[workplanIndex].dataInicioProjeto?.length > 0
          ) {
            const isAfter = compareTime(
              dateStr,
              workplans[workplanIndex].dataInicioProjeto
            );
            if (isAfter) {
              workplans[workplanIndex][name] = dateStr;
            }
          } else {
            workplans[workplanIndex][name] = dateStr;
          }
        }
        changeDate();
      }
      if (name === "numeroGanho") {
        var hasNumber = false;
        for (var i = 0; i < workplans.length; i++) {
          if (workplans[i].numeroGanho === textInputValue) {
            hasNumber = true;
          }
        }

        if(textInputValue.split("/").length != 2)
        {
          setIsModalErrorShowing(true)
        }
        else
        {
          if(textInputValue.split("/")[0].length != "4" || textInputValue.split("/")[1].length != "5")
          {
            setIsModalErrorShowing(true)
          }
          else
          {
            workplans[workplanIndex].numeroGanho = textInputValue;
          }
        }
        
      } else {
        if (name.includes("simulador")) {
          const name1 = name.split(".")[0];
          const name2 = name.split(".")[1];
          if (textInputValue === "") {
            workplans[workplanIndex][name1][name2] = 0;
          } else {
            workplans[workplanIndex][name1][name2] = textInputValue;
          }
          dispatch(setAllWorkplans(workplans));
          dispatch(setTriggerLoading(true));
        } else if (name.includes("resumoFinaceiro")) {
          workplans[workplanIndex].resumoFinaceiro.toChange = 0;
          workplans[workplanIndex].resumoFinaceiro.resumeFactoration[
            resumoFactIndex
          ].value = textInputValue;

          dispatch(setAllWorkplans(workplans));
          dispatch(setTriggerLoading(true));
        } else workplans[workplanIndex][name] = textInputValue;
      }
      //dispatch(setAllWorkplans(workplans));
    }
  }

  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      style={{ margin: "0px", width: "100%" }}
    >
      <div className={`${"liftworld-table-input-wrapper-wrapper"} ` + size}>
        <div
          className={`liftworld-table-input-wrapper ${
            field.maxlength && "liftworld-table-input-size-" + field.maxlength
          }  `}
          tabIndex="1"
        >
          {field.text != "" && (
            <div className={`${"liftworld-table-input-label"} ` + position}>
              {field.text}
            </div>
          )}
          <div
            className={`${"liftworld-table-input-field-wrapper"} ` + position}
          >
            <input
              className={`liftworld-table-input-field ${date} ${number} ${error}`}
              type={type && type}
              name={name !== undefined ? name : field.variable}
              value={type === "password" ? "palavra-pass" : textInputValue}
              maxLength={field.maxlength}
              onChange={(e) => {
                setTextInputValue(e.target.value);
                onChange && onChange(e.target.value, name);
              }}
              onBlur={updateState}
              disabled={disabled}
            />
            {euro && <div style = {{paddingRight : "8px"}}> € </div>}
          </div>
          <Form.Control.Feedback type="invalid">
            {`O campo é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </div>
      </div>
    </Form.Group>
  );
};
export default withNamespaces()(TableInput);
