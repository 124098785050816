import React, { useEffect, useState } from "react";
import { Form, Col, Button, Accordion } from "react-bootstrap";
import ColorList from "./ColorList";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { invertColor } from "./utils";
import {
  saveFolder,
  updateFolders,
  deleteFolder,
} from "../../store/ducks/taskFolders/actionTypes";

const Categories = (props) => {
  const {
    t,
    userBPM,
    folders,
    selectedFolder,
    setSelectedFolder,
    app,
    activeKeys,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (folders) {
      setCategories(folders);
      //clone object
      setTempCategories(JSON.parse(JSON.stringify(folders)));
    }
  }, [folders]);

  const [categories, setCategories] = useState([]);
  const [tempCategories, setTempCategories] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState("#5225a7");

  const [validated, setValidated] = useState(false);

  const addCategory = () => {
    if (name && color) {
      const payload = {
        folder: {
          name: name,
          color: color,
        },
        username: userBPM,
        application: app,
      };
      dispatch(saveFolder(payload));
      setColor("#fa9e00");
      setName("");
      setShowAdd(false);
    }
  };

  const handleEdit = (value, c, name) => {
    if (c && name) {
      setTempCategories((tempCategories) =>
        tempCategories.map((cat) => {
          if (cat.id === c.id) {
            cat[name] = value;
          }
          return cat;
        })
      );
      setShowSaveChanges(true);
    }
  };

  const persistEdit = () => {
    const payload = {
      folders: tempCategories,
      username: userBPM,
      application: app,
    };
    dispatch(updateFolders(payload));
    setShowSaveChanges(false);
    setShowEdit(false);
  };

  const handleDelete = (c) => {
    if (c && c.id) {
      setCategories((categories) =>
        categories.filter((cat) => cat.id !== c.id)
      );
      dispatch(deleteFolder(c.id));
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (showAdd) {
        addCategory();
        setValidated(false);
      } else {
        persistEdit();
        setValidated(false);
      }
    }
  };

  return (
    <Accordion activeKey={activeKeys?.categories}>
      <Accordion.Collapse eventKey="0">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="tasks-badge-header">
            {t("taskPage.categories.editCategory")}&nbsp;
          </div>
          <br />
          {!showAdd && !showEdit && (
            <>
              <i
                className="icon-add text-success mr-2"
                style={{ cursor: "pointer" }}
                onClick={() => setShowAdd(true)}
              />
              <i
                className="icon-edit table-action-icon"
                onClick={() => setShowEdit(true)}
              />
            </>
          )}
          {showAdd && !showEdit && (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowAdd(false);
                  setColor("#5225a7");
                  setValidated(false);
                }}
              >
                <i className="icon-left" /> {t("general.close")}
              </div>

              <Col lg="3" style={{ paddingTop: "15px" }}>
                <Form.Group controlId="name" style={{ marginBottom: "0px" }}>
                  <Form.Control
                    type="text"
                    placeholder={t("taskPage.categories.typeName")}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("taskPage.categories.requireName")}
                  </Form.Control.Feedback>
                </Form.Group>

                <ColorList color={color} setColor={setColor} />

                <Button
                  className="card-button"
                  style={{ marginTop: "15px", height: "35px" }}
                  type="submit"
                >
                  {t("taskPage.categories.addCategory")}
                </Button>
              </Col>
            </>
          )}
          {showEdit && !showAdd && (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowEdit(false);
                  setShowSaveChanges(false);
                  setValidated(false);
                  setTempCategories(JSON.parse(JSON.stringify(folders)));
                }}
              >
                <i className="icon-left" />
                {t("general.close")}
              </div>
              <div className="tasks-badges">
                {/*<span className="tasks-badge-header">
                  {t("taskPage.categories.editCategory")}
              </span>*/}
                <div className="tasks-badges-edit-wrapper">
                  {tempCategories.map((c, i) => {
                    return (
                      <div className="tasks-colorpicker-edit" key={i}>
                        <Form.Control
                          type="text"
                          placeholder={t("taskPage.categories.typeName")}
                          required
                          value={c.name}
                          onChange={(e) =>
                            handleEdit(e.target.value, c, "name")
                          }
                        />

                        <Form.Control
                          style={{ width: "55px", marginLeft: "10px" }}
                          bsPrefix="color-control"
                          type="color"
                          value={c.color}
                          onChange={(e) =>
                            handleEdit(e.target.value, c, "color")
                          }
                          title={t("taskPage.categories.chooseColor")}
                        />
                        <i
                          className="icon-trash table-action-icon ml-2"
                          onClick={() => handleDelete(c)}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ marginLeft: "10px" }}
                        >
                          {t("taskPage.categories.requireName")}
                        </Form.Control.Feedback>
                      </div>
                    );
                  })}
                </div>
              </div>
              <br />
              {showSaveChanges && (
                <Button
                  className="card-outlined-button"
                  style={{ width: "fit-content", height: "100%" }}
                  type="submit"
                >
                  {t("taskPage.categories.saveChanges")}
                </Button>
              )}
            </>
          )}

          {categories && categories.length > 0 && !showEdit && (
            <div className="tasks-badges">
              <div style={{ display: "flex" }}>
                <span className="tasks-badge-header">
                  {t("taskPage.categories.filterByCategory")}
                </span>
                {selectedFolder && (
                  <div
                    className="tasks-clear-selection"
                    onClick={() => {
                      setSelectedFolder(null);
                    }}
                  >
                    {t("taskPage.categories.clearSelection")}
                  </div>
                )}
              </div>

              <div className="tasks-badges-wrapper">
                {categories.map((c, i) => {
                  return (
                    <div
                      key={i}
                      className="tasks-badge"
                      onClick={() => {
                        setSelectedFolder((b) =>
                          b && b.id === c.id ? null : c
                        );
                      }}
                      style={{
                        backgroundColor: c.color,
                        color: invertColor(c.color),
                      }}
                    >
                      <div className="tasks-badge-content">
                        {selectedFolder && selectedFolder.id === c.id && (
                          <i className="icon-check mr-2 mt-1" />
                        )}
                        {c.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <hr />
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default withNamespaces()(Categories);
