import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";
import { Card, Button } from "react-bootstrap";

import {
  getEmailTemplates,
  countEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
} from "~/store/ducks/udw/actionTypes";

import { getOperations } from "~/store/ducks/portalrh/actionTypes";

import EmailTemplateTable from "./EmailTemplateTable";
import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import NavTabs from "../../../NavTabs";

const EmailTemplate = ({ t, portalRhMenus, activeSelected, designacao}) => {

  //dispatch and reducers
  const dispatch = useDispatch();
  const { emailTemplates, isLoadingEmailTemplates, nEmailTemplates, editingEmailTemplates } = useSelector(
    (state) => state.udwReducer
  );
  const { operations } = useSelector(
    (state) => state.portalrhReducer
  );

  //Message Variables
  const msgVars = ["sender", "receiver", "timeSensitiveGreetings", "documentTitle",
    "documentType", "operation", "date", "employeeNum"];
  //cursor position in textareas
  const subjectRef = useRef();
  const messageRef = useRef();

  //state variables
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedOperationId, setSelectedOperationId] = useState("");
  const [selectedDocTypeId, setSelectedDocTypeId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [focus, setFocus] = useState("");

  const [validated, setValidated] = useState(false);
  const [operationError, setOperationError] = useState();
  const [docTypeError, setDocTypeError] = useState();

  const [resetDocTypeDropdown, setResetDocTypeDropdown] = useState(false);
  
  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //useEfects
  useEffect(() => {
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
  },[]);
  useEffect(() => {
    dispatch(getEmailTemplates(PORTALRH_MIDDLEWARE.context, t));
    dispatch(getOperations(t));
    dispatch(countEmailTemplates());
  }, []);
  useEffect(() => {
    if ((!showCreateModal && !showUpdateModal && !showDeleteModal) || editingEmailTemplates) {
      dispatch(getEmailTemplates(PORTALRH_MIDDLEWARE.context, t));
      dispatch(countEmailTemplates());
      resetEmailTemplateValues();
    }
    if (showUpdateModal) {
      fillEmailTemplateValues();
    }
  }, [showCreateModal, showUpdateModal, showDeleteModal, editingEmailTemplates]);
  useEffect(() => {
    setOperationError((selectedOperationId === "") ? true : false);
    setDocTypeError((selectedDocTypeId === "") ? true : false);
  }, [selectedDocTypeId, selectedOperationId])

  //Setup modal's values --------------------------------------------
  const resetEmailTemplateValues = () => {
    setSelectedEmailTemplate(undefined);
    setSelectedOperationId("");
    setSelectedDocTypeId("");
    setSubject("");
    setMessage("");
    setFocus("");
    setValidated(false);
    setOperationError();
    setDocTypeError();
  }
  const fillEmailTemplateValues = () => {
    setSelectedOperationId(selectedEmailTemplate?.operationId);
    setSelectedDocTypeId(selectedEmailTemplate?.docTypeId);
    setSubject(selectedEmailTemplate?.subject);
    setMessage(selectedEmailTemplate?.message);
  }

  //create modal --------------------------------------------------------------------
  const closeCreateModal = () => {
    setShowCreateModal(false);
  };
  const openCreateModal = () => {
    setShowCreateModal(true);
  };
  const HandleCreateEmailTemplate = (operationId, docTypeId, subject, message) => {
    const portalrhDTO = {
      templateId: nEmailTemplates + 1,
      operationId: operationId,
      docTypeId: docTypeId,
    }
    const udwDTO = {
      emailTemplateId: nEmailTemplates + 1,
      subject: subject,
      message: message,
      appLabel: PORTALRH_MIDDLEWARE.context
    }
    dispatch(createEmailTemplate(udwDTO, portalrhDTO, PORTALRH_MIDDLEWARE.context));
    closeCreateModal();
  };

  //update modal ---------------------------------------------------------------------
  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const openUpdateModal = (emailTemplate) => {
    setSelectedEmailTemplate(emailTemplate);
    setShowUpdateModal(true);
  };
  const HandleUpdateEmailTemplate = (templateId, operationId, docTypeId, subject, message) => {
    const portalrhDTO = {
      templateId: templateId,
      operationId: operationId,
      docTypeId: docTypeId,
    }
    const udwDTO = {
      emailTemplateId: templateId,
      subject: subject,
      message: message,
      appLabel: PORTALRH_MIDDLEWARE.context
    }
    dispatch(updateEmailTemplate(udwDTO, portalrhDTO, PORTALRH_MIDDLEWARE.context));
    closeUpdateModal();
  };

  //Deletion modal -------------------------------------------------------------------
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const openDeleteModal = (emailTemplate) => {
    setSelectedEmailTemplate(emailTemplate);
    setShowDeleteModal(true);
  };
  const HandleDeleteEmailTemplate = (id) => {
    dispatch(deleteEmailTemplate(id, PORTALRH_MIDDLEWARE.context));
    closeDeleteModal();
  };

  //Message Variables ----------------------------------------------------------------
  const addMessageVariable = (string) => {
    if (focus === "subject") {
      setSubject(subject.substring(0, subjectRef.current.selectionStart) + string
        + subject.substring(subjectRef.current.selectionStart, subject.length));
    } else if (focus === "message") {
      setMessage(message.substring(0, messageRef.current.selectionStart) + string
        + message.substring(messageRef.current.selectionStart, message.length));
    }
  }
  const createMessageVariableButtons = () => {
    const msgVarButtons = msgVars.map((el) =>
      <Button key={el + "MsgVarButton"} className="message-variable-btn" onMouseDown={(e) => e.preventDefault()} onClick={() => addMessageVariable("§" + el + "§", subjectRef, messageRef)}>
        {t("portalrh.emailTemplate.messageVariables." + el)}
      </Button>
    )
    return msgVarButtons;
  }

  //Form submissions and validations ----------------------------------------------------
  const handleDropdownClassName = (varError) => {
    return ("dropdown-margin " +
      ((varError === true && validated) ? "dropdown-border-invalid" :
        ((varError === false && validated) ? "dropdown-border-valid" : ""))
    );
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() && operationError === false && docTypeError === false) {
      if (selectedEmailTemplate !== undefined) {
        HandleUpdateEmailTemplate(selectedEmailTemplate.id, selectedOperationId, selectedDocTypeId, subject, message);
      } else {
        HandleCreateEmailTemplate(selectedOperationId, selectedDocTypeId, subject, message);
      }
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };
  const handleSetSelectedOperationId = (id) => {
    if (id !== selectedOperationId) {
      setSelectedOperationId(id);
      setSelectedDocTypeId("");
      setResetDocTypeDropdown(true);
    }
  }

  //result
  return (
    <>
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between">
            <NavTabs
                  titleMenu={t('portalrh.emailTemplate.title')}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
          </Card.Header>
          <Card.Body>
            <div className="custom-table custom-table-small cursor-pointer">

              <EmailTemplateTable
                openCreateModal={openCreateModal}
                openUpdateModal={openUpdateModal}
                openDeleteModal={openDeleteModal}
                emailTemplates={emailTemplates}
                isLoadingEmailTemplates={isLoadingEmailTemplates}
              />

              <CreateModal
                //General modal funcionalities
                createMessageVariableButtons={createMessageVariableButtons}
                setFocus={setFocus}
                //Specific modal functionalities
                showCreateModal={showCreateModal}
                closeCreateModal={closeCreateModal}
                //Email template data
                operations={operations}
                selectedOperationId={selectedOperationId}
                setSelectedDocTypeId={setSelectedDocTypeId}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
                //Validation Funcionalities
                handleDropdownClassName={handleDropdownClassName}
                handleSubmit={handleSubmit}
                handleSetSelectedOperationId={handleSetSelectedOperationId}
                //validation data
                validated={validated}
                setValidated={setValidated}
                operationError={operationError}
                docTypeError={docTypeError}
                //Reset docType dropdown when needed
                resetDocTypeDropdown={resetDocTypeDropdown}
                setResetDocTypeDropdown={setResetDocTypeDropdown}
                //track cursor position in textareas
                subjectRef={subjectRef}
                messageRef={messageRef}
              />

              <UpdateModal
                //General modal funcionalities
                createMessageVariableButtons={createMessageVariableButtons}
                setFocus={setFocus}
                //Specific modal functionalities
                selectedEmailTemplate={selectedEmailTemplate}
                showUpdateModal={showUpdateModal}
                closeUpdateModal={closeUpdateModal}
                //Email template data
                operations={operations}
                selectedOperationId={selectedOperationId}
                setSelectedDocTypeId={setSelectedDocTypeId}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
                //Validation Funcionalities
                handleDropdownClassName={handleDropdownClassName}
                handleSubmit={handleSubmit}
                handleSetSelectedOperationId={handleSetSelectedOperationId}
                //validation data
                validated={validated}
                setValidated={setValidated}
                operationError={operationError}
                docTypeError={docTypeError}
                //Reset docType dropdown when needed
                resetDocTypeDropdown={resetDocTypeDropdown}
                setResetDocTypeDropdown={setResetDocTypeDropdown}
                //track cursor position in textareas
                subjectRef={subjectRef}
                messageRef={messageRef}
              />

              <DeleteModal
                //Specific modal functionalities
                selectedEmailTemplate={selectedEmailTemplate}
                showDeleteModal={showDeleteModal}
                closeDeleteModal={closeDeleteModal}
                HandleDeleteEmailTemplate={HandleDeleteEmailTemplate}
                //Email template data
                operations={operations}
                selectedOperationId={selectedOperationId}
                setSelectedDocTypeId={setSelectedDocTypeId}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
              />

              <Spinner spinning={editingEmailTemplates} wrapper />
              <Messages />
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(EmailTemplate);
