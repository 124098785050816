import {
  SET_IS_EDIT_ANNOTATION,
  SET_SELECTED_ROW,
  SET_UPDATE_ANNOTATION,
  SET_COMMENT_HISTORY,
} from "./actionTypes";

const initialState = {
  isEditAnnotation: false,
  selectedRow: null,
  successUpdateAnnotation: false,
  commentHistory: [],
};

export function processAnnotationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_EDIT_ANNOTATION:
      return { ...state, isEditAnnotation: action.data };
    case SET_SELECTED_ROW:
      return { ...state, selectedRow: action.data };
    case SET_UPDATE_ANNOTATION:
      return { ...state, successUpdateAnnotation: action.data };
    case SET_COMMENT_HISTORY:
      return { ...state, commentHistory: action.data };

    default:
      return state;
  }
}
