import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditingAnnotation } from "~/store/ducks/processAnnotations/actionTypes";
import { setIsEditingActivityFolder } from "~/store/ducks/taskFolders/actionTypes";
import { findUDWUsers } from "~/store/ducks/udw/actionTypes";
import { toLocaleDateString } from "~/utils";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import AnnotationColumn from "./AnnotationColumn";
import { getColumnFormatter, getMobileCardFormatter } from "./columnFormatter";
import CreateActionColumn from "./CreateActionColumn";
import { BiRightTopArrowCircle } from "react-icons/bi";
import { BiLeftTopArrowCircle } from "react-icons/bi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TaskTablePag200 from "./TaskTablePag200";
import TaskTablePagDefault from "./TaskTablePagDefault";
import "~/assets/css/icons.css";

const TaskTable = (props) => {
  const {
    t,
    handleOpenPreviewModal,
    handleOpenFlowModal,
    flowId,
    app,
    selectedFolder,
    userBPM,
    showCategories,
    styleSheet,
    handleSearch,
    translations,
    language,
  } = props;

  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state.tasksReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { udwUsers } = useSelector((state) => state.udwReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { isEditActivityFolder } = useSelector(
    (state) => state.taskFoldersReducer
  );
  const { isEditAnnotation, selectedRow } = useSelector(
    (state) => state.processAnnotationsReducer
  );

  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [flowColumns, setFlowColumns] = useState(undefined);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  const [searchBarValue, setSearchBarValue] = useState("");
  // const [isFromBy, setIsFromBy] = useState(true);

  const handleStatusSort = (field) => {
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setField(field);
  };

  /*Get flow Columns defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/tarefas` || item.route === `${app}/tasks`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );

        let flowColumns_;
        let hiddenDefaults_;

        if (flowId && extraConfiguration_[flowId]) {
          flowColumns_ = extraConfiguration_[flowId].columns; //columns for a specific flow
          hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default columns for a specific flow
        } else {
          flowColumns_ = extraConfiguration_.defaultExtraColumns; //extra columns for all flows
          hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default columns for all flows
        }
        setFlowColumns(flowColumns_ ? flowColumns_ : undefined);
        setHiddenDeafultColumns(hiddenDefaults_ ? hiddenDefaults_ : undefined);
      }
    }
  }, [menu, flowId]);

  const getColumns = (columns) => {
    let columns_;
    columns_ = columns.map((column) => {
      if (column === "PROCESS_STATUS")
        return {
          dataField: "status",
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          headerStyle: () => {
            return { width: "190px" };
          },
          onSort: () => handleStatusSort("PROCESS_STATUS"),
        };
      else if (column === "TTL") {
        return {
          dataField: column,
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          style: {
            overflowWrap: "break-word",
          },
          onSort: () => handleStatusSort("created"),
        };
      } else
        return {
          dataField:
            typeof column === "object" ? Object.keys(column)[0] : column,
          text: t(
            `taskPage.general.${
              typeof column === "object" ? Object.keys(column)[0] : column
            }`
          ),
          sort: true,
          formatter: getColumnFormatter,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              setSearchBarValue(row?.from);
              e.stopPropagation();
              e.preventDefault();
            },
          },
          formatExtraData: {
            value: column,
          },
          style: {
            overflowWrap: "break-word",
          },
          headerStyle: () => {
            return {
              width: "120px",
            };
          },
          onSort: () => handleStatusSort(column),
          sortCaret: (order, column) => {
            if (column.toString() !== "") {
              if (!order) return <span className="order-4"></span>;
              else if (order === "asc")
                return <span className="caret-4-asc"></span>;
              else if (order === "desc")
                return <span className="caret-4-desc"></span>;
              return null;
            } else return null;
          },
        };
    });
    return columns_;
  };

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };

  const buildActionsColumn = (_, row, _rowIndex, formatExtraData) => (
    <CreateActionColumn
      app={app}
      row={row}
      handleOpenPreviewModal={handleOpenPreviewModal}
      handleOpenFlowModal={handleOpenFlowModal}
      userBPM={userBPM}
      styleSheet={styleSheet}
    />
  );

  const translateText = (textField) => {
    if (!textField.includes("::t::")) {
      return textField;
    }
    const lingua = language.trim();
    const cleanTextField = textField.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          if (tt.startsWith("nDP::")) {
            return tt.substring(5);
          } else {
            if (tt.includes(": Required")) {
              return t("portalrh.error.required");
            }
            return tt;
          }
        }
      })
      .join("");

    return textoFinal;
  };

  const buildIconDelegated = (_, row, _rowIndex, formatExtraData) => {
    if (row?.delegated === 1) {
      if (row?.userDelegated?.length > 0 || row?.ownerId?.length > 0) {
        if (row?.ownerId?.length > 0) {
          return (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(row?.ownerId)}
            >
              <BiLeftTopArrowCircle color="green" size={20} />
            </OverlayTrigger>
          );
        }
        if (row?.userDelegated?.length > 0) {
          return (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(row?.userDelegated)}
            >
              <BiRightTopArrowCircle color="red" size={20} />
            </OverlayTrigger>
          );
        }
      } else {
        return null;
      }
    }
  };

  function renderTooltip(text, ...props) {
    return text && text?.length > 0 ? (
      <Tooltip id="button-tooltip" {...props}>
        {text.join(", ")}
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...props}>
        ""
      </Tooltip>
    );
  }

  const buildAnnotationColumn = (_, row, _rowIndex, formatExtraData) => {
    return <AnnotationColumn row={row} formatExtraData={formatExtraData} />;
  };

  const sortDelegated = () => {
    if (tasks.length > 0) {
      const delegated = tasks?.find((e) => e?.delegated === 1);
      if (checkUserDelegated(tasks, delegated)) {
        return true;
      }
    } else {
      return false;
    }
  };

  const isDelegated = () => {
    if (tasks.length > 0) {
      const delegated = tasks?.find((e) => e?.delegated === 1);
      if (checkUserDelegated(tasks, delegated)) {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkUserDelegated = (tasks, delegated) => {
    for (let i = 0; i < tasks.length; i++) {
      if (
        Array.isArray(tasks[i]?.userDelegated) &&
        tasks[i]?.userDelegated?.length > 0
      ) {
        for (let k = 0; k < tasks[i]?.userDelegated?.length; k++) {
          if (tasks[i]?.userDelegated[k] === userBPM && delegated) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return false;
  };
  const isMobile = window.innerWidth < 992 ? true : false;
  const columns = [
    {
      dataField: "keyField",
      formatter: (_, row, rowIndex) =>
        `${row.pnumber}_${row.created}_${rowIndex}`,
      text: "keyField",
      hidden: true,
    },
    {
      dataField: "mobileCard",
      text: "",
      formatter: getMobileCardFormatter,
      formatExtraData: {
        handleOpenFlowModal: handleOpenFlowModal,
      },
      hidden: isMobile ? false : true,
      headerStyle: { display: "none" },
      style: { padding: "0px" },
    },
    {
      dataField: "initials",
      text: t("taskPage.general.flow"),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "initials",
        app: app,
        translateText: translateText,
      },
      onSort: () => handleStatusSort("initials"),
      hidden:
        isMobile ||
        !styleSheet?.firstColumnInitial ||
        styleSheet?.excludeBaseColumns?.includes("initials")
          ? true
          : isHidden("initials"),
      headerStyle: () => {
        return {
          width: "240px",
        };
      },
      // style: {
      //   overflowWrap: "break-word",
      // },
    },
    {
      dataField: "pnumber",
      type: "number",
      text: t("taskPage.general.processNumber"),
      sort: true,
      hidden:
        isMobile || styleSheet?.excludeBaseColumns?.includes("pnumber")
          ? true
          : isHidden("pnumber"),
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "pnumber",
        userBPM: userBPM,
        showCategories: showCategories,
        readFlag: styleSheet?.readFlag,
        toolTipMoreOptions: styleSheet?.toolTipMoreOptions,
      },
      onSort: () => handleStatusSort("pnumber"),
      sortFunc: (a, b, order) => {
        // reverse sorting
        if (order === "asc") return b - a;
        return a - b; // desc
      },
      headerStyle: () => {
        return {
          width: "250px",
          minWidth: "fit-content",
          textAlign: "center",
        };
      },
      classes: () => {
        return "pnumber";
      },
    },
    {
      dataField: "flowname",
      text: t("taskPage.general.flow"),
      sort: true,
      onSort: () => handleStatusSort("flowname"),
      hidden:
        isMobile || styleSheet?.firstColumnInitial
          ? true
          : isHidden("flowname"),
      headerStyle: () => {
        return {
          width: flowColumns && flowColumns.length > 0 ? "120px" : "auto",
        };
      },
      formatter: (_, row, rowIndex) => translateText(row.flowname),
      style: {
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "description",
      text: t("taskPage.general.description"),
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "description",
        translations: translations,
        language: language,
        t: t,
      },
      sort: true,
      onSort: () => handleStatusSort("description"),
      hidden:
        isMobile || styleSheet?.excludeBaseColumns?.includes("description")
          ? true
          : isHidden("description"),
      headerStyle: () => {
        return {
          width: "auto",
        };
      },
      style: {
        maxWidth: "1px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "created",
      text: t("taskPage.general.sendDateExcludeBaseColumns"),
      sort: true,
      onSort: () => handleStatusSort("created"),
      hidden:
        isMobile || styleSheet?.excludeBaseColumns?.includes("created")
          ? true
          : isHidden("created"),
      formatter: (_, row, {}) => {
        if (row.created !== null && row.created !== undefined)
          //return toLocaleString(row.created);
          return toLocaleDateString(row.created);
      },
      headerStyle: () => {
        return {
          width: flowColumns && flowColumns.length > 0 ? "100px" : "140px",
        };
      },
    },
    {
      dataField: "pendingPeriod",
      text: t("taskPage.general.pendingFor"),
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "pendingPeriod",
      },
      sort: true,
      // onSort: () => handleStatusSort("created"),
      hidden:
        isMobile || styleSheet?.excludeBaseColumns?.includes("pendingSince")
          ? true
          : isHidden("pendingPeriod"),
      headerStyle: () => {
        return {
          width: flowColumns && flowColumns.length > 0 ? "90px" : "130px",
        };
      },
    },
    {
      dataField: "PROCESS_STATUS",
      text: "process_status",
      sort: true,
      hidden: true,
    },
    {
      dataField: "delegated",
      text: "",
      sort: sortDelegated(),
      hidden: isDelegated(),
      formatter: buildIconDelegated,
      headerStyle: () => {
        return { width: "40px" };
      },
    },
    {
      dataField: "annotation",
      text: styleSheet?.annotationColumnName
        ? t("taskPage.annotation.annotationColumnName")
        : " ",
      sort: true,
      onSort: () => handleStatusSort("deadline"), //will sort for deadline hidden column
      formatter: buildAnnotationColumn,
      formatExtraData: {
        selectedRow: selectedRow,
      },
      hidden:
        isMobile || styleSheet?.excludeBaseColumns?.includes("annotation")
          ? true
          : false,
      align: "center",
      headerStyle: () => {
        return styleSheet?.annotationColumnName
          ? { width: "120px", textAlign: "center" }
          : { width: "40px", textAlign: "center" };
      },
      classes: () => {
        return "column-icon";
      },
      sortCaret: (order, column) => {
        if (column !== "") {
          if (!order) return <span className="order-4"></span>;
          else if (order === "asc")
            return <span className="caret-4-asc"></span>;
          else if (order === "desc")
            return <span className="caret-4-desc"></span>;
          return null;
        } else return null;
      },
    },
    {
      dataField: "deadline", //hidden column used for sorting annotation icon column
      text: "",
      sort: true,
      hidden: true,
      sortValue: (_, row) => (row.deadline ? row.deadline : "2122/01/01"), //used for empty values to be last
    },
    {
      dataField: "actions",
      text: t("taskPage.general.action"),
      formatter: buildActionsColumn,
      hidden: isMobile ? true : false,
      align: "center",
      headerStyle: () => {
        return { width: "90px", textAlign: "center" };
      },
    },
  ];
  if (flowColumns && !isMobile) {
    getColumns(flowColumns).forEach((column, index) =>
      columns.splice(styleSheet?.firstColumnInitial ? 4 : 3 + index, 0, column)
    );
  }

  /*Find UDW Users for column from*/
  useEffect(() => {
    if (
      flowColumns &&
      flowColumns.some((column) => {
        return (
          column === "from" || (typeof column === "object" && "from" in column)
        );
      })
    ) {
      if (tasks) {
        let payload = [];
        tasks.forEach((task) => {
          if (task.from !== "" && task.from !== undefined)
            if (!payload.some((item) => item.username === task.from))
              if (task.from !== null)
                payload.push({
                  username: task.from,
                  legacyApplication: {
                    label: BPM_MIDDLEWARE.context,
                  },
                });
        });
        if (payload.length !== 0) dispatch(findUDWUsers(payload));
      }
    }
  }, [tasks, dispatch, flowColumns]);

  const [tasksToRender, setTasksToRender] = useState([]);

  useEffect(() => {
    let tasks_ = [];
    if (tasks) {
      tasks_ = tasks.map((task) => {
        task.metadata &&
          task.metadata.forEach((item) => {
            task[item.name] = item.value;
          });
        return task;
      });
    }
    if (udwUsers) {
      tasks_.forEach((task) => {
        const udwUser = udwUsers.find(
          (user) => user.legacyUser.username === task.from
        );
        if (udwUser) {
          task.from = udwUser.username;
        }
      });
    }
    if (selectedFolder && selectedFolder.id) {
      tasks_ = tasks_.filter(
        (task) => task && task.folderid === selectedFolder.id
      );
    }
    if (styleSheet && styleSheet.flowsToNotRender) {
      tasks_ = tasks_.filter(
        (task) =>
          task.creator === user.username ||
          !styleSheet.flowsToNotRender.includes(task.flowid)
      );
    }
    setTasksToRender(tasks_);
  }, [tasks, udwUsers, selectedFolder, styleSheet]);

  const rowEvents = {
    onClick: (e, row) => {
      //className exists
      if (
        e?.target?.className &&
        typeof e.target.className !== "undefined" &&
        typeof e.target.className === "string"
      ) {
        if (
          !e.target.className?.includes("pnumber") &&
          !e.target.className?.includes("column-icon") &&
          !e.target.className?.includes("annotation") &&
          !e.target.className?.includes("react-datetime-picker") &&
          !e.target.className?.includes("form-control") &&
          !e.target.className?.includes("react-calendar") &&
          !e.target.className?.includes("icon-detail")
        ) {
          //special controls are clicked
          if (isEditActivityFolder) {
            e.stopPropagation();
            e.preventDefault();
            dispatch(setIsEditingActivityFolder(false));
          } else if (isEditAnnotation) {
            if (e.target.type !== "checkbox") {
              e.stopPropagation();
              e.preventDefault();
              dispatch(setIsEditingAnnotation(false));
            } else {
              //do not stop event, required for checkbox
            }
          } else {
            //open flow modal
            e.stopPropagation();
            e.preventDefault();
            handleOpenFlowModal(row);
          }
        } else {
          //do not open flow modal
          e.stopPropagation();
          e.preventDefault();
        }
      } else {
        //className does not exist
        if (isEditActivityFolder) {
          e.stopPropagation();
          e.preventDefault();
        } else if (isEditAnnotation) {
          if (e.target.type !== "checkbox") {
            e.stopPropagation();
            e.preventDefault();
          } else {
            //do not stop event, required for checkbox
          }
        } else if (e.target.tagName === "IMG") {
          //do not open flow modal
          e.stopPropagation();
          e.preventDefault();
        } else {
          //show flow modal
          e.stopPropagation();
          e.preventDefault();
          handleOpenFlowModal(row);
        }
      }
    },
  };

  /*test data
  tasksToRender.forEach((t) => (t.dateStart = "06042022144137378"));
  tasksToRender.forEach((t) => (t.numImoveis = "100000000000000100000"));
  */

  return (
    <>
      {styleSheet?.pagination200_1000 ? (
        <TaskTablePag200
          tasksToRender={tasksToRender}
          columns={columns}
          field={field}
          order={order}
          isMobile={isMobile}
          rowEvents={rowEvents}
          handleSearch={handleSearch}
          searchBarValue={searchBarValue}
          setSearchBarValue={setSearchBarValue}
        />
      ) : (
        <TaskTablePagDefault
          tasksToRender={tasksToRender}
          columns={columns}
          field={field}
          order={order}
          isMobile={isMobile}
          rowEvents={rowEvents}
          handleSearch={handleSearch}
        />
      )}
    </>
  );
};
export default withNamespaces()(TaskTable);
