import React from 'react';
import CustomTotal from '~/components/pagination/customTotal';

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div className="btn-group" role="group">
    {
      options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={ option.text }
            type="button"
            onClick={ () => onSizePerPageChange(option.page) }
            className={`btn ${isSelect ? 'btn-primary' : 'btn-link'}`}
          >
            { option.text }
          </button>
        );
      })
    }
  </div>
);

export const pagination = {
  paginationSize: 4,
  pageStartIndex: 0,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

  //firstPageText: 'First',
  //prePageText: 'Back',
  //nextPageText: 'Next',
  //lastPageText: 'Last',
  //nextPageTitle: 'First page',
  //prePageTitle: 'Pre page',
  //firstPageTitle: 'Next page',
  //lastPageTitle: 'Last page',
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [{
    text: '8', value: 8
  }, {
    text: '15', value: 15
  }, {
    text: '30', value: 30
  },{
    text: '50', value: 50
  }] // A numeric array is also available. the purpose of above example is custom the text
};