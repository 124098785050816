import {
  GET_EASYPAY_FORMDATA,
  SET_EASYPAY_FORMDATA,
  POST_EASYPAY_FORMDATA,
  SET_EASYPAY_ONBOARDING_PID
} from './actionTypes';

const initialState = {
  easypayFormdata: null,
  isLoadingEasypayFormdata: false,
  easypayOnboardingPid: ""
}

export function easypayReducer (state = initialState, action) {

  switch (action.type) {
    case GET_EASYPAY_FORMDATA:
      return { ...state, isLoadingEasypayFormdata: true };
    case SET_EASYPAY_FORMDATA:
      return { ...state, easypayFormdata: action.formdata, isLoadingEasypayFormdata: false };
    case SET_EASYPAY_ONBOARDING_PID:
      return { ...state, easypayOnboardingPid: action.pid };
    case POST_EASYPAY_FORMDATA:
      return { ...state, isLoadingEasypayFormdata: true };
    default:
      return state
  }
}
