import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import Spinner from "~/components/Spinner";

import { persistAPISettings } from "~/store/ducks/onboardingSettings/actionTypes";

const Config = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingPersistApiSettings, isLoadingGetApiSettings, apiSettings } =
    useSelector((state) => state.onboardingSettingsReducer);

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
     if (Object.keys(apiSettings).length !== 0 && apiSettings.coreSystem) {
      apiSettings.coreSystem?.username && setUsername(apiSettings.coreSystem.username);
      apiSettings.coreSystem?.password && setPassword(apiSettings.coreSystem.password);
    } 
  }, [apiSettings]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      saveCoreSystemClientConsultConfig();
    }
  };

  const saveCoreSystemClientConsultConfig = () => {
    const organizationId = user.organization?.id;
    let payload = apiSettings;

    if (organizationId) {
      if (!apiSettings.organizationId) {
        payload.organizationId = organizationId;
      }
      payload.coreSystem= { username, password } };
      if (apiSettings.id) {
        payload.id = apiSettings.id;
      }
      dispatch(persistAPISettings(payload));
  };

  return (
    <>
      <div className="onboarding-test-form">
        <Form
          noValidate
          validated={validated}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          encType="multipart/form-data"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h3 className="onboarding-form-header">
            {t("onboarding.general.config")}
          </h3>
          <Form.Row>
            <Col sm="12" md="8" lg="8">
              <Form.Group controlId="validationUserClientConsult">
                <Form.Label>{t("onboarding.persist.name")}</Form.Label>
                <Form.Control
                autoComplete="off"
                  type="text"
                  autofill="false"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("onboarding.persist.requiredUser")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationPasswordClientConsult">
                <Form.Label>{t("onboarding.persist.password")}</Form.Label>
                <Form.Control
                  type="password"
                  autofill="false"
                  required
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("onboarding.persist.requiredPassword")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Button
            disabled={!user?.organization?.id}
            type="submit"
            className="card-button onboarding-button"
          >
            {isLoadingPersistApiSettings || isLoadingGetApiSettings ? (
              <>
                {t("onboarding.general.loading")}
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              t("onboarding.general.save")
            )}
          </Button>
        </Form>
      </div>
    </>
  );
};
export default withNamespaces()(Config);
