import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import uuid from "uuid/v1";

import { Form, Col } from 'react-bootstrap';
import '~/assets/css/icons.css';

import {
  findBatchsByFilters,
} from '~/pages/DataCapture/actions';
import { updateUserWidget } from '~/pages/User/actions';

import {
  translateStatusBatch
} from '~/pages/DataCapture/utils';
import { BatchStatus } from '~/pages/DataCapture/utils/DataCaptureEnum';

const BatchFilters = ({ t, userWidget }) => {

  const dispatch = useDispatch();

  const { isDeletedBatch, updatedBatch, isSavingMetadataDocument } = useSelector(state => state.dataCaptureReducer);
  const { userWidgets } = useSelector(state => state.userSettingsReducer);
  const { menu } = useSelector(state => state.applicationMenuReducer);

  //const [flow, setFlow] = useState(undefined);
  const [menuId, setMenuId] = useState(undefined);
  const [status, setStatus] = useState(BatchStatus.WAITING_SUBMISSION);
  const [changeFilter, setChangeFilter] = useState(undefined);

  useEffect(() => {
    return () => setChangeFilter("");
  }, []);

  useEffect(() => {
    if (userWidget && userWidget.userConfiguration) {
      const { userConfiguration } = userWidget;
      setMenuId(userConfiguration.menuId || undefined);
      setStatus(userConfiguration.status || undefined);
    }
  }, [userWidgets]);

  useEffect(() => {
    dispatch(findBatchsByFilters({ menuId, status }));
  }, [dispatch, changeFilter, updatedBatch, status, menuId]);

  useEffect(() => {
    if (isDeletedBatch) {
      dispatch(findBatchsByFilters({ menuId, status }));
    }
  }, [dispatch, isDeletedBatch]);

  useEffect(() => {
    dispatch(findBatchsByFilters({ menuId, status }));
  }, [dispatch, isSavingMetadataDocument]);

  useEffect(() => {
    changeFilter && saveUserWidgetConfiguration();
  }, [changeFilter]);

  const saveUserWidgetConfiguration = () => {
    const payload = {
      id: userWidget.id,
      widgetId: userWidget.widgetId,
      userConfiguration: {
        type: ".DataCaptureUserConfiguration",
        menuId, status
      }
    };
    dispatch(updateUserWidget(payload));
  };

  const handleMenuId = e => {
    setChangeFilter(e.target.value);
    setMenuId(e.target.value || undefined);
  }

  const handleStatus = e => {
    setChangeFilter(e.target.value);
    setStatus(e.target.value || undefined);
  }

  const getOptions = () => {
    const menuItems = menu.filter(item => (item.route === "datacapture/invoice" || item.route === "datacapture/onboarding"));
    let options = [];
    menuItems.forEach(item => {
      options.push(<option
        key={uuid()}
        value={item.id}
      >
        {t(item.name).toUpperCase()}
      </option>);
    })
    return options;
  }

  return (
    <Form>
      <Form.Row>
        <Col lg="12">
          <h5>{t('general.filters')}</h5>
          <hr />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg="3">
          <Form.Label>{t('datacapture.general.flow')}</Form.Label>
          <Form.Control
            as="select"
            onChange={handleMenuId}
            value={menuId}
          >
            <option value=''></option>
            {menu && getOptions()}
          </Form.Control>
        </Col>
        <Col lg="3">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            onChange={handleStatus}
            value={status}
          >
            <option value=''></option>
            {
              Object.keys(BatchStatus).map(item =>
                <option
                  key={uuid()}
                  value={item}
                >
                  {t(translateStatusBatch(BatchStatus[item]))}
                </option>
              )
            }
          </Form.Control>
        </Col>
      </Form.Row>
    </Form >
  )
}

export default withNamespaces()(BatchFilters)
