import {
  SEND_EMAIL,
  DONE_SEND_EMAIL,
  SET_SEND_EMAIL_RESPONSE,
  SEND_EMAIL_ENDPOINT,
  DONE_SEND_EMAIL_ENDPOINT,
  SET_SEND_EMAIL_ENDPOINT_RESPONSE,
  SEND_SMS,
  DONE_SEND_SMS,
  SET_SEND_SMS_RESPONSE,
  SEND_SMS_OTP,
  DONE_SEND_SMS_OTP,
  SET_SEND_SMS_OTP_RESPONSE,
  POST_INQUIRY,
  DONE_POST_INQUIRY,
  SET_POST_INQUIRY_RESPONSE,
  GET_INQUIRY,
  DONE_GET_INQUIRY,
  SET_GET_INQUIRY_RESPONSE,
  GET_CRC_DOCUMENT,
  DONE_GET_CRC_DOCUMENT,
  SET_GET_CRC_DOCUMENT_RESPONSE,
  GET_IES_DOCUMENT,
  DONE_GET_IES_DOCUMENT,
  SET_GET_IES_DOCUMENT_RESPONSE,
  CREATE_ALICE_USER,
  DONE_CREATE_ALICE_USER,
  SET_ALICE_USER_TOKEN,
  SET_ALICE_USER_ID,
  GET_ALICE_REPORT,
  DONE_GET_ALICE_REPORT,
  SET_ALICE_REPORT,
  CREATE_MERCHANT,
  SET_MERCHANT,
  DONE_CREATE_MERCHANT,
  GET_MERCHANT,
  DONE_GET_MERCHANT,
  ADD_DOCUMENT_TO_MERCHANT,
  DONE_ADD_DOCUMENT_TO_MERCHANT,
  GET_TINK_ID,
  SET_TINK_ID,
  DONE_GET_TINK_ID,
  GET_TINK_REPORT,
  SET_TINK_REPORT,
  GET_TINK_TOKEN,
  SET_TINK_TOKEN,
  DONE_GET_TINK_TOKEN,
  GET_SIGNED_PDF,
  DONE_GET_SIGNED_PDF,
  SET_SIGNED_PDF,
  SET_DIGITAL_SIGNATURE_OUTPUT,
  GET_CARD_INFORMATION,
  DONE_GET_CARD_INFORMATION,
  SET_CARD_INFORMATION,
  GET_SAML,
  DONE_GET_SAML,
  SET_GET_SAML_RESPONSE,
  CLEAR_GET_SAML_RESPONSE,
  GET_ATRIBUTES,
  DONE_GET_ATRIBUTES,
  SET_ATRIBUTES,
  GET_ALL_USERS,
  DONE_GET_ALL_USERS,
  SET_ALL_USERS,
  GET_ALL_DOCUMENTS,
  SET_ALL_DOCUMENTS,
  DONE_GET_ALL_DOCUMENTS,
  GET_USER,
  DONE_GET_USER,
  SET_USER,
  UPDATE_USER,
  DONE_UPDATE_USER,
  UPDATE_DOCUMENT,
  DONE_UPDATE_DOCUMENT,
  GET_DOCUMENT_PDA_INFO,
  SET_DOCUMENT_PDA_INFO,
  DONE_GET_DOCUMENT_PDA_INFO,
  SET_CURRENT_VERTICAL,
  SET_CURRENT_SIGNATURE,
  SET_ALICE_LINK,
  SET_ALICE_PROCESS,
  GET_ALICE_TOKEN,
  PROCESS_ALICE_REPORT,
  SET_CREATE_DOCUMENT_ID_CORESYSTEM,
  ADD_DOCUMENT_CORESYSTEM_DONE,
  SET_DOCUMENT_CORESYSTEM,
  SET_TRIGGER_LOADING,
  SET_VERSION_SIGNPDF,
  GET_VERSION_SIGNPDF,
  SET_DOCUMENT,
  POST_MINDEE,
  SET_MINDEE_IBAN,
  SET_MINDEE_ADDRESS,
  UPDATE_REP_CMD,
  DONE_UPDATE_REP_CMD,
  SET_REP_ID,
  GET_PRECARIO,
  SET_PRECARIO,
  DONE_GET_PRECARIO,
  UPDATE_PRECARIO,
  DONE_UPDATE_PRECARIO,
  GET_REPLEG_ID,
  DONE_GET_REPLEG_ID,
  SET_REPLEG_ID,
  GET_CLIENT_PID,
  SET_CLIENT_PID,
  DONE_GET_CLIENT_PID,
  SET_UPDATE_CMD_ERRO,
  GET_PRECARIO_NIF_ACCOUNT,
  SAVE_PRECARIO,
  DONE_SAVE_PRECARIO,
  SET_ADDED_REPLEG,
  SET_SAVE_PRECARIO_STATE,
  GET_ENTITY_PDF,
  SET_ENTITY_PDF,
  DONE_GET_ENTITY_PDF,
  DONE_SAVE_DETAIL_FILE,
  SAVE_DETAIL_FILE,
  GET_DETAIL_FILE,
  SET_DETAIL_FILE,
  DONE_GET_DETAIL_FILE,
  SET_DONE_SCREENINGS,
  SET_FILE_SCREEN,
  DONE_ERROR_CHECKPEP
} from "./actionTypes";

const initialState = {
  getCrcDocumentResponse: {},
  getIesDocumentResponse: {},
  smsSettings: {},
  aliceReport: {},
  aliceUserToken: "",
  aliceUserID: "",
  merchant: {},
  sendEmailResponse: "",
  sendEmailEndPointResponse: "",
  sendSmsResponse: "",
  sendSmsOtpResponse: "",
  postInquiryResponse: {},
  getInquiryResponse: {},
  tinkId: "",
  tinkReport: {},
  tinkToken: "",
  digitalSignatureOutput: "",
  signedPdf: "",
  cardInformation: {},
  getSamlResponse: "",
  getAtributesResponse: "",
  users: [],
  user: [],
  currentVertical: "",
  currentSignature: null,
  documentPdaInfo: {},
  documents: [],
  document: null,
  precario: null,
  triggerLoading: false,
  isLoadingUpdateUser: false,
  isLoadingUpdateDocument: false,
  isLoadingGetAllUsers: false,
  isLoadingGetUser: false,
  isLoadingSendEmail: false,
  isLoadingSendEmailEndPoint: false,
  isLoadingSendSms: false,
  isLoadingSendSmsOTP: false,
  isLoadingGetApiSettings: false,
  isLoadingPersistApiSettings: false,
  isLoadingGetInquiry: false,
  isLoadingPostInquiry: false,
  isLoadingGetCrcDocument: false,
  isLoadingGetIesDocument: false,
  isLoadingCreateAliceUser: false,
  isLoadingGetAliceReport: false,
  isLoadingCreateMerchant: false,
  isLoadingGetMerchant: false,
  isLoadingAddDocumentsToMerchant: false,
  isLoadingGetTinkId: false,
  isLoadingGetTinkToken: false,
  isLoadingGetSignedPdf: false,
  isLoadingCardInformation: false,
  isLoadingGetSaml: false,
  isLoadingGetAtributes: false,
  isLoadingGetDocumentPdaInfo: false,
  isLoadingGetAllDocuments: false,
  aliceLink: null,
  aliceProcess: null,
  isLoadingGetAliceToken: false,
  isProcessingAliceReport: false,
  createDocumentCoresystemId: null,
  addDocumentCoresystemDone: null,
  documentCoresystem: null,
  isLoadingSignPdfVersion: false,
  resultVersionSignPdf: "",
  mindeeIban: "",
  mindeeAddress:"",
  isLoadingRepCmd: false,
  replegId: 1909,
  isLoadingGetPrecario: false,
  isLoadingUpdatePrecario: false,
  isLoadingGetReplegId: false,
  isLoadingGetClientPid: false,
  clientPid: null,
  updateCmdErro: null,
  isLoadingSavePrecario: false,
  addedRepLeg: false,
  savePrecarioResult: null,
  entityPdf: null,
  isLoadingGetEntityPdf: false,
  isLoadingSaveDetailFile: false,
  isLoadingGetDetailFile: false,
  detailFile: null,
  doneScreenings: false,
  screen : null,
  doneErrorCheckpep:false,
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL:
      return { ...state, isLoadingSendEmail: true };
    case DONE_SEND_EMAIL:
      return { ...state, isLoadingSendEmail: false };
    case SET_SEND_EMAIL_RESPONSE:
      return { ...state, sendEmailResponse: action.response };

    case SAVE_PRECARIO:
      return { ...state, isLoadingSavePrecario: true };
    case DONE_SAVE_PRECARIO:
      return { ...state, isLoadingSavePrecario: false };

    case SET_CURRENT_VERTICAL:
      return { ...state, currentVertical: action.currentVertical };

    case SET_SAVE_PRECARIO_STATE:
      return { ...state, savePrecarioResult: action.status };

    case SET_CURRENT_SIGNATURE:
      return { ...state, currentSignature: action.currentSignature };

    case SET_DONE_SCREENINGS:
      return { ...state, doneScreenings: action.done };

    case DONE_ERROR_CHECKPEP:
      return { ...state, doneErrorCheckpep: action.done };

    case SET_FILE_SCREEN:
      return { ...state, screen: action.screen };

    case GET_DOCUMENT_PDA_INFO:
      return { ...state, isLoadingGetDocumentPdaInfo: true };
    case SET_DOCUMENT_PDA_INFO:
      return { ...state, documentPdaInfo: action.documentPdaInfo };
    case DONE_GET_DOCUMENT_PDA_INFO:
      return { ...state, isLoadingGetDocumentPdaInfo: false };

    case GET_DETAIL_FILE:
      return { ...state, isLoadingGetDetailFile: true };
    case SET_DETAIL_FILE:
      return { ...state, detailFile: action.file };
    case DONE_GET_DETAIL_FILE:
      return { ...state, isLoadingGetDetailFile: false };

    case SEND_EMAIL_ENDPOINT:
      return { ...state, isLoadingSendEmailEndPoint: true };
    case DONE_SEND_EMAIL_ENDPOINT:
      return { ...state, isLoadingSendEmailEndPoint: false };
    case SET_SEND_EMAIL_ENDPOINT_RESPONSE:
      return { ...state, sendEmailEndPointResponse: action.response };

    case GET_ENTITY_PDF:
      return { ...state, isLoadingGetEntityPdf: true };
    case DONE_GET_ENTITY_PDF:
      return { ...state, isLoadingGetEntityPdf: false };
    case SET_ENTITY_PDF:
      return { ...state, entityPdf: action.payload };

    case SAVE_DETAIL_FILE:
      return { ...state, isLoadingSaveDetailFile: true };
    case DONE_SAVE_DETAIL_FILE:
      return { ...state, isLoadingSaveDetailFile: false };

    case SEND_SMS:
      return { ...state, isLoadingSendSms: true };
    case DONE_SEND_SMS:
      return { ...state, isLoadingSendSms: false };
    case SET_SEND_SMS_RESPONSE:
      return { ...state, sendSmsResponse: action.response };

    case SET_TRIGGER_LOADING:
      return { ...state, triggerLoading: action.triggerLoading };

    case SET_ADDED_REPLEG:
      return { ...state, addedRepLeg: action.addedRepLeg };

    case SEND_SMS_OTP:
      return { ...state, isLoadingSendSmsOTP: true };
    case DONE_SEND_SMS_OTP:
      return { ...state, isLoadingSendSmsOTP: false };
    case SET_SEND_SMS_OTP_RESPONSE:
      return { ...state, sendSmsOtpResponse: action.response };

    case POST_INQUIRY:
      return { ...state, isLoadingPostInquiry: false };
    case DONE_POST_INQUIRY:
      return { ...state, isLoadingPostInquiry: false };
    case SET_POST_INQUIRY_RESPONSE:
      return { ...state, postInquiryResponse: action.response };
    case GET_INQUIRY:
      return { ...state, isLoadingGetInquiry: false };
    case DONE_GET_INQUIRY:
      return { ...state, isLoadingGetInquiry: false };
    case SET_GET_INQUIRY_RESPONSE:
      return { ...state, getInquiryResponse: action.response };

    case GET_CRC_DOCUMENT:
      return { ...state, isLoadingGetCrcDocument: true };
    case DONE_GET_CRC_DOCUMENT:
      return { ...state, isLoadingGetCrcDocument: false };
    case SET_GET_CRC_DOCUMENT_RESPONSE:
      return { ...state, getCrcDocumentResponse: action.response };

    case GET_IES_DOCUMENT:
      return { ...state, isLoadingGetIesDocument: true };
    case DONE_GET_IES_DOCUMENT:
      return { ...state, isLoadingGetIesDocument: false };
    case SET_GET_IES_DOCUMENT_RESPONSE:
      return { ...state, getIesDocumentResponse: action.response };

    case CREATE_ALICE_USER:
      return { ...state, isLoadingCreateAliceUser: true };
    case DONE_CREATE_ALICE_USER:
      return {
        ...state,
        isLoadingCreateAliceUser: false,
        isLoadingGetAliceToken: false,
      };
    case SET_ALICE_USER_TOKEN:
      return { ...state, aliceUserToken: action.payload };
    case SET_ALICE_USER_ID:
      return { ...state, aliceUserID: action.payload };
    case GET_ALICE_REPORT:
      return { ...state, isLoadingGetAliceReport: true };
    case SET_ALICE_REPORT:
      return { ...state, aliceReport: action.data };
    case SET_REP_ID:
      return { ...state, replegId: action.id };
    case DONE_GET_ALICE_REPORT:
      return {
        ...state,
        isLoadingGetAliceReport: false,
        isProcessingAliceReport: false,
      };
    case SET_ALICE_LINK:
      return { ...state, aliceLink: action.aliceLink };
    case SET_ALICE_PROCESS:
      return { ...state, aliceProcess: action.data };
    case GET_ALICE_TOKEN:
      return { ...state, isLoadingGetAliceToken: true };
    case PROCESS_ALICE_REPORT:
      return { ...state, isProcessingAliceReport: true };

    case CREATE_MERCHANT:
      return { ...state, isLoadingCreateMerchant: true };
    case DONE_CREATE_MERCHANT:
      return { ...state, isLoadingCreateMerchant: false };
    case SET_MERCHANT:
      return { ...state, merchant: action.data };
    case GET_MERCHANT:
      return { ...state, isLoadingGetMerchant: true };
    case DONE_GET_MERCHANT:
      return { ...state, isLoadingGetMerchant: false };
    case ADD_DOCUMENT_TO_MERCHANT:
      return { ...state, isLoadingAddDocumentsToMerchant: false };
    case DONE_ADD_DOCUMENT_TO_MERCHANT:
      return { ...state, isLoadingAddDocumentsToMerchant: false };

    case GET_TINK_ID:
      return { ...state, isLoadingGetTinkId: true };
    case SET_TINK_ID:
      return { ...state, tinkId: action.id };
    case DONE_GET_TINK_ID:
      return { ...state, isLoadingGetTinkId: false };
    case GET_TINK_REPORT:
      return { ...state };
    case SET_TINK_REPORT:
      return { ...state, tinkReport: action.report };
    case GET_TINK_TOKEN:
      return { ...state, isLoadingGetTinkToken: true };
    case SET_TINK_TOKEN:
      return { ...state, tinkToken: action.id };
    case DONE_GET_TINK_TOKEN:
      return { ...state, isLoadingGetTinkToken: false };

    case GET_SIGNED_PDF:
      return { ...state, isLoadingGetSignedPdf: true };
    case DONE_GET_SIGNED_PDF:
      return { ...state, isLoadingGetSignedPdf: false };
    case SET_SIGNED_PDF:
      return {
        ...state,
        signedPdf: {
          ...action.response,
        },
      };
    case SET_DIGITAL_SIGNATURE_OUTPUT:
      return { ...state, digitalSignatureOutput: action.response };
    case GET_VERSION_SIGNPDF:
      return { ...state, isLoadingSignPdfVersion: true };
    case SET_VERSION_SIGNPDF:
      return {
        ...state,
        resultVersionSignPdf: action.response,
        isLoadingSignPdfVersion: false,
      };

    case GET_CARD_INFORMATION:
      return { ...state, isLoadingGetCardInformation: true };
    case DONE_GET_CARD_INFORMATION:
      return { ...state, isLoadingGetCardInformation: false };
    case SET_CARD_INFORMATION:
      return { ...state, cardInformation: action.data };

    case GET_SAML:
      return { ...state, isLoadingGetSaml: true };
    case DONE_GET_SAML:
      return { ...state, isLoadingGetSaml: false };
    case SET_GET_SAML_RESPONSE:
      return { ...state, getSamlResponse: action.response };
    case CLEAR_GET_SAML_RESPONSE:
      return { ...state, getSamlResponse: "" };
    case GET_ATRIBUTES:
      return { ...state, isLoadingGetAtributes: true };
    case DONE_GET_ATRIBUTES:
      return { ...state, isLoadingGetAtributes: false };
    case SET_ATRIBUTES:
      return { ...state, getAtributesResponse: action.response };

    case GET_ALL_USERS:
      return { ...state, isLoadingGetAllUsers: true };
    case DONE_GET_ALL_USERS:
      return { ...state, isLoadingGetAllUsers: false };
    case SET_ALL_USERS:
      return { ...state, users: action.users };

    case GET_ALL_DOCUMENTS:
      return { ...state, isLoadingGetAllDocuments: true };
    case DONE_GET_ALL_DOCUMENTS:
      return { ...state, isLoadingGetAllDocuments: false };
    case SET_ALL_DOCUMENTS:
      return { ...state, documents: action.documents };

    case GET_PRECARIO_NIF_ACCOUNT:
      return { ...state, isLoadingGetPrecario: true };

    case GET_PRECARIO:
      return { ...state, isLoadingGetPrecario: true };
    case DONE_GET_PRECARIO:
      return { ...state, isLoadingGetPrecario: false };
    case SET_PRECARIO:
      return { ...state, precario: action.precario };

    case GET_REPLEG_ID:
      return { ...state, isLoadingGetReplegId: true };
    case DONE_GET_REPLEG_ID:
      return { ...state, isLoadingGetReplegId: false };
    case SET_REPLEG_ID:
      return { ...state, replegId: action.replegId };

    case SET_UPDATE_CMD_ERRO:
      return { ...state, updateCmdErro: action.updateCmdErro };

    case GET_CLIENT_PID:
      return { ...state, isLoadingGetClientPid: true };
    case DONE_GET_CLIENT_PID:
      return { ...state, isLoadingGetClientPid: false };
    case SET_CLIENT_PID:
      return { ...state, clientPid: action.clientPid };

    case GET_USER:
      return { ...state, isLoadingGetUser: true };
    case DONE_GET_USER:
      return { ...state, isLoadingGetUser: false };
    case SET_USER:
      return { ...state, user: action.user };

    case SET_DOCUMENT:
      return { ...state, document: action.document };

    case UPDATE_USER:
      return { ...state, isLoadingUpdateUser: true };
    case DONE_UPDATE_USER:
      return { ...state, isLoadingUpdateUser: false };

    case UPDATE_PRECARIO:
      return { ...state, isLoadingUpdatePrecario: true };
    case DONE_UPDATE_PRECARIO:
      return { ...state, isLoadingUpdatePrecario: false };

    case UPDATE_REP_CMD:
      return { ...state, isLoadingRepCmd: true };
    case DONE_UPDATE_REP_CMD:
      return { ...state, isLoadingRepCmd: false };

    case UPDATE_DOCUMENT:
      return { ...state, isLoadingUpdateDocument: true };
    case DONE_UPDATE_DOCUMENT:
      return { ...state, isLoadingUpdateDocument: false };

    /*Coresystem start*/
    case SET_CREATE_DOCUMENT_ID_CORESYSTEM:
      return { ...state, createDocumentCoresystemId: action.data };
    case ADD_DOCUMENT_CORESYSTEM_DONE:
      return { ...state, addDocumentCoresystemDone: action.data };
    case SET_DOCUMENT_CORESYSTEM:
      return { ...state, documentCoresystem: action.data };
    /*Coresystem end*/
    case SET_MINDEE_IBAN:
      return { ...state, mindeeIban: action.data };
    case SET_MINDEE_ADDRESS:
      return { ...state, mindeeAddress: action.data };

    default:
      return state;
  }
};
