import { reject } from "q";
import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import http from "~/utils/config/http";
import { FIN_MIDDLEWARE } from "~/utils/constants";
import oauth from "../../../utils/oauth";

const BackofficeMenuItem = ({ t, item, innuxLogin }) => {
  const [link, setLink] = useState("");
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    const generateUrl = async () => {
      const application = item?.application?.label;
      const accessToken = oauth.getAccessToken();

      if (application === FIN_MIDDLEWARE.context) {
        try {
          const myurl = "/fin/linkfin";
          const response = await http.get(myurl, FIN_MIDDLEWARE);
          if (isMountedRef.current) {
            setLink("epublicafin:" + response.data);
          }
        } catch (error) {
          if (isMountedRef.current) {
            setLink("epublicafin:error");
          }
          reject(error);
        }
      } else if (application === "rh") {
        let myurl;
        if (item.name.includes("innuxtime")) {
          myurl = "innuxtime:" + accessToken;
        } else if (item.name.includes("webtime")) {
          if (innuxLogin?.length > 0) {
            myurl = innuxLogin;
          } else {
            myurl = "/webtime";
          }
        } else if (item.name.includes("mfiles")) {
          myurl = "mfiles:" + accessToken;
        } else {
          myurl = "epublicarh:" + accessToken; //default UnikPeople
        }
        setLink(myurl);
      } else if (application === "datacapture") {
        let myurl;
        if (item.name.includes("mfiles")) {
          myurl = "mfiles:" + accessToken;
        }
        setLink(myurl);
      } else if (application === "portalrh") {
        //define default url
        let myurl = "https://portalpicagens.cespu.pt";
        //read url from config
        const extraConfiguration = JSON.parse(item.extraConfiguration || false);
        if (extraConfiguration && extraConfiguration.url)
          myurl = extraConfiguration.url;
        setLink(myurl);
      }
    };

    generateUrl();
    return () => (isMountedRef.current = false);
  }, [item]);

  return (
    <a href={link} target="_blank" className="menu-link">
      <div className={"menu-item"}>
        <div className="menu-item-wrap">
          <i
            className="icon-external-link"
            style={{ fontSize: "18px", opacity: 0.8 }}
          ></i>
          <span style={{ padding: "0 5px" }}>{t(`${item.name}`)}</span>
        </div>
      </div>
    </a>
  );
};
export default withNamespaces()(BackofficeMenuItem);
