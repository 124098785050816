import React from "react";
import uuid from "uuid/v1";
import "../assets/scss/_badge.scss";

function Badge({ char }) {
  return (
    <div className="easypay-badge-wrapper" key={uuid()}>
        {char}
    </div>
  );
}

export default Badge;
