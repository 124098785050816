import React, { useEffect, useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_contentContainer.scss";
import Textbox from "./Textbox";
import { useSelector } from "react-redux";
import TableTextbox from "./TableTextbox";
import TableInput from "./TableInput";
import uuid from "uuid/v1";
const devLogConsole = require("~/utils/devLog");
const ProjectDescriptionSection = (props) => {
  const {
    workplanIndex,
    comeFromSave,
    calculateGoBackFunction,
    callRecalculate, totalOpt1CostValue
    , totalOpt2CostValue
    , totalOpt3CostValue
    , totalOptSelectedCostValue
    , startDate
    , setStartDate
    , totalOpt1SaleValue
    , totalOpt2SaleValue
    , totalOpt3SaleValue
    , totalOptSelectedSaleValue
    , option1CostValue
    , setOption1CostValue
    , option1SaleValue
    , setOption1SaleValue
    , option1FinalMargin
    , setOption1FinalMargin
    , option2CostValue
    , setOption2CostValue
    , option2SaleValue
    , setOption2SaleValue
    , option2FinalMargin
    , setOption2FinalMargin
    , option3CostValue
    , setOption3CostValue
    , option3SaleValue
    , setOption3SaleValue
    , option3FinalMargin
    , setOption3FinalMargin
    , optionSelectedCostValue
    , setOptionSelectedCostValue
    , optionSelectedSaleValue
    , setOptionSelectedSaleValue
    , optionSelectedFinalMargin
    , setOptionSelectedFinalMargin
    , showOption2
    , setShowOption2
    , showOption3
    , setShowOption3
    , monthsArray
    , setMonthsArray
    , factureArrayState
    , setFactureArrayState
    , costArrayState
    , setCostArrayState
    , tableTitle
    , setTableTitle
    , totalFacture
    , setTotalFacture
    , totalCost
    , setTotalCost
    , income
    , setIncome
    , percIncome
    , setPercIncome
    , costHours
    , setCostHours
    , hours
    , setHours
    , margin
    , setMargin
    , percMargin
    , setPercMargin
    , marginOk
    , setMarginOk
    , factureArrayStateVerify
    , setFactureArrayStateVerify
    , costArrayStateVerify
    , setCostArrayStateVerify
    , totalFactureVerify
    , setTotalFactureVerify
    , totalCostVerify
    , setTotalCostVerify
    , incomeVerify
    , setIncomeVerify
    , percIncomeVerify
    , setPercIncomeVerify
    , marginVerify
    , setMarginVerify
    , percMarginVerify
    , setPercMarginVerify
    
  } = props;
  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  const {
    paymentTypes,
    departments,
    factoring,
    collaborators,
    expenses,
    projectTypes,
    providerTypes,
    expenditureTypes,
    workplans,
    triggerLoading,
    workplanById,
  } = useSelector((state) => state.budgetManagementReducer);



  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  var factureArray = [];
  var factureArrayVerify = [];
  var costArray = [];
  var costArrayVerify = [];

  var costHoursElement = {};
  var hoursElement = {};

  var gpi = 1.0;
  var fse = 1.0;
  var gpd = 1.0;
  var pc = 1.0;
  var pv = 1.0;

  const months = [
    "jan",
    "feb",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dec",
  ];
  function getPosition(index, list) {
    if (index === 0) return "start";
    if (index === Object.keys(tableTitle).length - 1) return "end";
  }

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  function getPositionBottom(index, list) {
    if (index === 0) return "start-end";
    if (index === Object.keys(list).length - 1) return "end";
  }

  useEffect(() => {
    callRecalculate();
  }, [workplans[workplanIndex], triggerLoading]);


  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    if (x === 0) {
      return "0,00";
    }
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function convertIdToName(id, name) {
    if (name === "departments") {
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
    if (name === "paymentTypes" && paymentTypes != null) {
      const payType = paymentTypes.find(
        (x) => x.idTipoPagamento === parseInt(id)
      );
      if (payType != null) return payType?.nome;
    }
  }


  function getPositionTop(index, table) {
    if (index === 0) return "start";
    if (index === Object.keys(table).length - 1) return "top-end";
  }

  function getPercentage(size) {
    return 100 / size + "%";
  }

  function disabledInputs() {
    if (
      (workplans[workplanIndex].faseFinal === "Inicio" ||
        workplans[workplanIndex].faseFinal === null ||
        workplans[workplanIndex].faseFinal === "" ||
        workplans[workplanIndex].faseFinal === undefined ||
        workplans[workplanIndex].faseFinal === "Final") &&
      (getNameFromId() === "Pontual" || getNameFromId() === "Press kit")
    ) {
      return true;
    }

    return false;
  }

  function calc(theform) {
    var num = theform.original.value,
      rounded = theform.rounded;
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return (rounded.value = with2Decimals);
  }

  //devLogConsole(totalFacture);
  return (
    <div className="liftworld-content-container">
      <div className="liftworld-expenses-sales-wrapper">
        <div className="liftworld-text-wrapper">
          Resumo Proposta Financeira do Projeto
        </div>
        <div className="liftworld-expenses-sales-values-wrapper">
          <div className="liftworld-option-wrapper">
            <div className="liftworld-text-wrapper-large">Total Opção 1</div>
            <Textbox
              title={"Valor de Custo"}
              value={
                isNaN(parseFloat(option1CostValue)) ||
                option1CostValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(option1CostValue).toFixed(2)) +
                    " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Valor de Venda"}
              value={
                isNaN(parseFloat(option1SaleValue)) ||
                option1SaleValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(option1SaleValue).toFixed(2)) +
                    " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Margem Final"}
              value={
                isNaN(parseFloat(option1FinalMargin)) ||
                option1FinalMargin === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(option1FinalMargin).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
          </div>
        </div>
        {showOption2 && (
          <div className="liftworld-expenses-sales-values-wrapper">
            <div className="liftworld-option-wrapper">
              <div className="liftworld-text-wrapper-large">Total Opção 2</div>
              <Textbox
                title={"Valor de Custo"}
                value={
                  isNaN(parseFloat(option2CostValue)) ||
                  option2CostValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option2CostValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Valor de Venda"}
                value={
                  isNaN(parseFloat(option2SaleValue)) ||
                  option2SaleValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option2SaleValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Margem Final"}
                value={
                  isNaN(parseFloat(option2FinalMargin)) ||
                  option2FinalMargin === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option2FinalMargin).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
            </div>
          </div>
        )}
        {showOption3 && (
          <div className="liftworld-expenses-sales-values-wrapper">
            <div className="liftworld-option-wrapper">
              <div className="liftworld-text-wrapper-large">Total Opção 3</div>
              <Textbox
                title={"Valor de Custo"}
                value={
                  isNaN(parseFloat(option3CostValue)) ||
                  option3CostValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option3CostValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Valor de Venda"}
                value={
                  isNaN(parseFloat(option3SaleValue)) ||
                  option3SaleValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option3SaleValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Margem Final"}
                value={
                  isNaN(parseFloat(option3FinalMargin)) ||
                  option3FinalMargin === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option3FinalMargin).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
            </div>
          </div>
        )}
        <div className="liftworld-expenses-sales-values-wrapper">
          <div className="liftworld-option-wrapper">
            <div className="liftworld-text-wrapper-large">
              Total de Opções Selecionadas
            </div>
            <Textbox
              title={"Valor de Custo"}
              value={
                isNaN(parseFloat(optionSelectedCostValue)) ||
                optionSelectedCostValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(optionSelectedCostValue).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Valor de Venda"}
              value={
                isNaN(parseFloat(optionSelectedSaleValue)) ||
                optionSelectedSaleValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(optionSelectedSaleValue).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Margem Final"}
              value={
                isNaN(parseFloat(optionSelectedFinalMargin)) ||
                optionSelectedFinalMargin === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(optionSelectedFinalMargin).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
          </div>
        </div>
        <div style={{ paddingTop: "30px" }} />
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(monthsArray).map((key, index) => (
              <TableTextbox
                value={monthsArray[key]}
                position={getPositionTop(index, monthsArray)}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-table-header-wrapper">
          {Object.keys(totalFacture).map((key, index) => (
            <div
              style={{
                display: "flex",
                width: getPercentage(Object.keys(totalFacture).length),
              }}
            >
              <TableInput
                field={buildInputField(
                  "",
                  totalFacture[key] === 0
                    ? "0.00"
                    : index != 0
                    ? parseFloat(totalFacture[key]).toFixed(2)
                    : totalFacture[key]
                )}
                size={"large"}
                position={"middle"}
                key={uuid()}
                workplanIndex={workplanIndex}
                name={"resumoFinaceiro.resumeFactoration"}
                number={index != 0 ? "number" : ""}
                euro={index != 0 ? true : false}
                disabled={
                  index === 0 ||
                  index === Object.keys(totalFacture).length - 1 ||
                  disabledInputs()
                    ? true
                    : false
                }
                resumoFactIndex={index - 1}
                error={
                  workplans[workplanIndex].resumoFinaceiro.canSave
                    ? ""
                    : index === Object.keys(totalFacture).length - 1
                    ? "error"
                    : ""
                }
              />
            </div>
          ))}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(totalCost).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? totalCost[key]
                    : isNaN(parseFloat(totalCost[key])) ||
                      totalCost[key] === undefined
                    ? "0,00 €"
                    : numberWithCommas(parseFloat(totalCost[key]).toFixed(2)) +
                      "€"
                }
                position={"middle"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(income).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? income[key]
                    : isNaN(parseFloat(income[key])) ||
                      income[key] === undefined
                    ? "0,00 €"
                    : numberWithCommas(parseFloat(income[key]).toFixed(2)) + "€"
                }
                position={"middle"}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(percIncome).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? percIncome[key]
                    : isNaN(parseFloat(percIncome[key])) ||
                      percIncome[key] === undefined
                    ? "0,00 %"
                    : numberWithCommas(parseFloat(percIncome[key]).toFixed(2)) +
                      "%"
                }
                position={getPositionBottom(index, percIncome)}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div style={{ paddingTop: "15px" }} />
        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(costHours).map((key, index) => {
                let value;
              
                if (index === 0) {
                  value = costHours[key];
                } else {
                  const parsedValue = parseFloat(costHours[key]);
                  if (isNaN(parsedValue) || costHours[key] === undefined) {
                    value = "0,00 €";
                  } else {
                    value = numberWithCommas(parsedValue.toFixed(2)) + "€";
                  }
                }
              
                return (
                  <TableTextbox
                    key={key}
                    value={value}
                    position={getPositionTop(index, costHours)}
                    number={index !== 0 ? "number" : ""}
                  />
                );
              })}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(hours).map((key, index) => (
                <TableTextbox
                  value={hours[key]}
                  position={getPositionBottom(index, hours)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(margin).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? margin[key]
                      : isNaN(parseFloat(margin[key])) ||
                        margin[key] === undefined
                      ? "0,00 €"
                      : numberWithCommas(parseFloat(margin[key]).toFixed(2)) +
                        "€"
                  }
                  position={getPositionTop(index, margin)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(percMargin).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? percMargin[key]
                      : isNaN(parseFloat(percMargin[key])) ||
                        percMargin[key] === undefined
                      ? "0,00 %"
                      : numberWithCommas(
                          parseFloat(percMargin[key]).toFixed(2)
                        ) + "%"
                  }
                  position={getPositionBottom(index, percMargin)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectDescriptionSection;
