import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../../../i18n";
import "../assets/scss/_replegmodal.scss";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import Input from "./Input";
import RadioButtonItem from "./RadioButtonItem";
import Text from "./Text";

function AddBenefDocComponent({ title, isModalShowing, setIsModalShowing, t }) {
  const [activeDocTypeTab, setActiveDocTypeTab] = useState(
    t("bpm.onboarding.backoffice.user.labelCitizenCard")
  );
  useEffect(() => {
    if (document.dados["accordion_benef_doctipo"]) {
      if (
        document.dados["accordion_benef_tipo"].value ===
        t("bpm.onboarding.backoffice.user.labelIndividual")
      )
        document.dados["accordion_benef_doctipo"].value = activeDocTypeTab;
      else {
        document.dados["accordion_benef_doctipo"].value = "";
        document.dados["accordion_benef_numdocumento"].value = "";
      }
    }
  }, [activeDocTypeTab]);


  function buildInputField(text, variable) {
    return {
      value: "",
      obligatory: false,
      variable: variable,
      maxlenght: 9,
      text: text,
    };
  }


  return (
    <div>
      <Text
        text={
          t(`bpm.onboarding.backoffice.user.labelIDDocument`) +
          "::card-text-no-padding"
        }
      />
      <div className="easypay-accordion-radio-button-wrapper" key={uuid()}>
        <BenefModalRadioButtonWrapper
          key={uuid()}
          variant={"easypay-radio-button-column"}
          activeTab={activeDocTypeTab}
          setActivetab={setActiveDocTypeTab}
          displayType={"column"}
        >
          <RadioButtonItem
            key={uuid()}
            uniqueName={"docType"}
            text={t(`bpm.onboarding.backoffice.user.labelCitizenCard`)}
            index={4}
            name={t(`bpm.onboarding.backoffice.user.labelCitizenCard`)}
            subHeader={""}
            displayType={"column"}
          ></RadioButtonItem>
          <RadioButtonItem
            key={uuid()}
            uniqueName={"docType"}
            text={t(`bpm.onboarding.backoffice.user.labelPassport`)}
            index={5}
            name={t(`bpm.onboarding.backoffice.user.labelPassport`)}
            subHeader={""}
            displayType={"column"}
          ></RadioButtonItem>
          <RadioButtonItem
            key={uuid()}
            uniqueName={"docType"}
            text={t(`bpm.onboarding.backoffice.user.labelIdentityTicket`)}
            index={6}
            name={t(`bpm.onboarding.backoffice.user.labelIdentityTicket`)}
            subHeader={""}
            displayType={"column"}
          ></RadioButtonItem>
          <RadioButtonItem
            key={uuid()}
            text={t(`bpm.onboarding.backoffice.user.labelResidenceTitle`)}
            index={7}
            uniqueName={"docTypeAccordion"}
            name={t(`bpm.onboarding.backoffice.user.labelResidenceTitle`)}
            subHeader={""}
            displayType={"column"}
          ></RadioButtonItem>
          <RadioButtonItem
            key={uuid()}
            text={t(`bpm.onboarding.backoffice.user.labelResidenceCard`)}
            index={8}
            uniqueName={"docTypeAccordion"}
            name={t(`bpm.onboarding.backoffice.user.labelResidenceCard`)}
            subHeader={""}
            displayType={"column"}
          ></RadioButtonItem>
        </BenefModalRadioButtonWrapper>
      </div>
      <div style={{ width: "500px" }}>
        <Input
          field={buildInputField(
            t(`bpm.onboarding.backoffice.user.labelDocumentNumber`),
            "accordion_benef_numdocumento"
          )}
          key={"accordion_benef_numdocumento"}
        ></Input>
      </div>
    </div>
  );
}
export default withNamespaces()(AddBenefDocComponent);
