import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Col, Button, Accordion } from "react-bootstrap";
import FilterToggle from "./FilterToggle";
import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import Dropdown from "~/components/Dropdown";

const CollaboratorsFilters = ({
  t,
  filterOption,
  setFilterOption,
  hiddenDefaults = false,
  collabsData,
  setcollabsToShow,
  cleanDropdown,
  setCleanDropdown,
  handleReset,
  collabFilter,
  setcollabFilter,
}) => {
  const [activeKey, setActiveKey] = useState("0");
  const [toggleIcon, setToggleIcon] = useState("icon-down");
  const [filterToggle, setFilterToggle] = useState(true);

  useEffect(() => {
    setActiveKey(filterToggle === "false" ? null : "0");
    setToggleIcon(filterToggle === "false" ? "icon-down" : "icon-up");
  }, [filterToggle]);

  useEffect(() => {
    setcollabFilter(filterOption);
  }, [filterOption]);

  const handleFilterOptionChange = (e) => {
    setcollabFilter(e);
  };

  const handleSearchFilter = () => {
    setcollabsToShow(
      collabsData.filter((item) => item.empresa === collabFilter)
    );
  };

  const isHidden = (filter) => {
    if (hiddenDefaults) {
      return hiddenDefaults.some((f) => f === filter) ? true : false;
    } else return false;
  };

  const renderFilterCompany = () => {
    const uniqueCompanies = [
      ...new Set(collabsData.map((item) => item.empresa)),
    ];

    return uniqueCompanies.map((company) => ({
      label: company,
      value: company,
      selected: collabFilter === company, // Define o item como selecionado se collabFilter for igual à empresa
    }));
  };

  return (
    <Accordion activeKey={activeKey} style={{ marginBottom: "20px" }}>
      <FilterToggle
        eventKey="0"
        setToggleIcon={setToggleIcon}
        setActiveKey={setActiveKey}
        toggleIcon={toggleIcon}
      />
      <Accordion.Collapse eventKey="0">
        <Form>
          <Form.Row>
            {!isHidden("flowMenu") && (
              <Col lg="4">
                <Form.Label>
                  {t(`budgetManagement.collaborators.empresas`)}
                </Form.Label>
                <Dropdown
                  list={renderFilterCompany()}
                  handleSubmit={handleFilterOptionChange}
                  emptyDefault={true}
                  setResetDropdown={setCleanDropdown}
                  resetDropdown={cleanDropdown}
                />
              </Col>
            )}
          </Form.Row>
          <br />
          <Button className="card-button" onClick={handleSearchFilter}>
            {t("general.search")}
          </Button>

          <Button
            className="card-button"
            onClick={handleReset}
            style={{ marginLeft: "15px" }}
          >
            {t(`budgetManagement.collaborators.reset`)}
          </Button>
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default withNamespaces()(CollaboratorsFilters);
