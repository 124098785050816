import React from "react";
import uuid from "uuid/v1";
import TableInput from "./TableInput";
import "../assets/scss/_singleTable.scss";

function SingleTable({ list, size }) {
  function buildInputField(text) {
    return {
      value: "",
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  function getTableInputSzie(index) {
    if (
      (size === "normal" && list.length === 2 && index === 1) ||
      (size === "large" && list.length === 3 && index === 1) ||
      size === "single"
    )
      return "large";

    return "normal";
  }

  function getPosition(index) {
    if (size === "single") return "single";
    if (index === 0) return "start";
    if (index === list.length - 1) return "end";

    return "middle";
  }
  return (
    <div className={"liftworld-single-table"} key={uuid()}>
      {list &&
        Object.keys(list).length !== 0 &&
        list.map((item, index) => {
          return (
            <TableInput
              field={buildInputField(item)}
              size={getTableInputSzie(index)}
              position={getPosition(index)}
              key={uuid()}
            />
          );
        })}
    </div>
  );
}

export default SingleTable;
