import React from "react";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import {
  Button as ButtonBoot,
  Card,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const EventItem = ({
  event,
  updateEventState,
  removeDayFromEvents,
  disabled,
  t
}) => {
  function EventCheckbox({ label, checked, onChange, disabled }) {
    return (
      <Form.Check
        type="checkbox"
        label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return (
    <ListGroup.Item className="event-item">
      <Row>
        <Col>
          <Row>
            <Col
              className="checkbox-group"
              style={{ display: "inline-flex", gap: "2rem" }}
            >
              <EventCheckbox
                label={t("portalrh.workDays.diaInteiro")}
                checked={event.checked}
                disabled={disabled}
                onChange={(e) =>
                  updateEventState(event.id, {
                    checked: e.target.checked,
                    checkedHalfDay: !e.target.checked && event.checkedHalfDay,
                  })
                }
              />
              <EventCheckbox
                label={t("portalrh.workDays.diasMarcadosMeiodia")}
                checked={event.checkedHalfDay}
                disabled={disabled}
                onChange={(e) =>
                  updateEventState(event.id, {
                    checkedHalfDay: e.target.checked,
                    checked: !e.target.checked && event.checked,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="date-label" style={{ display: "inline-flex" }}>
              <Form.Label>
                <b>{moment(event.start).format(`DD [de] MMMM [de] YYYY`)}</b>
              </Form.Label>
              {event.type !== "datasMarcacoes" ? (
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    color: "#AD3E3E",
                  }}
                  onClick={() => removeDayFromEvents(event)}
                >
                  <FaTrash />
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <EventCheckbox
                    checked={event.dayToRemove}
                    disabled={false}
                    onChange={(e) =>
                      updateEventState(event.id, {
                        dayToRemove: e.target.checked,
                      })
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default withNamespaces()(EventItem);
