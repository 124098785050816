import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { abbreviateString } from "~/utils";
import txtPreview from "~/assets/img/datacapture/excel_preview.png";
const PlanoPreviewCard = ({ plano, t, resetStates, showDeleteModal }) => {
  const [showDeleteplano, setShowDeleteplano] = useState(false);

  useEffect(() => {
    showDeleteModal && setShowDeleteplano(true);
  }, [showDeleteModal]);

  return (
    <>
      <img
        src={txtPreview}
        className={"portal-image-preview-one"}
        alt="preview"
      />
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={<Tooltip>{plano.name}</Tooltip>}
      >
        <small>{abbreviateString(plano.name, 28)}</small>
      </OverlayTrigger>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setShowDeleteplano(true)}
      >
        {" "}
        &times;
      </span>

      <Modal
        show={showDeleteplano}
        onHide={() => setShowDeleteplano(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`${t("datacapture.general.deleteDocument")} ${plano.name || ""} ?`}
        </Modal.Body>
        <Modal.Footer>
          {plano.name && (
            <Button
              variant="danger"
              onClick={() => {
                resetStates();
                setShowDeleteplano(false);
              }}
            >
              {t("general.remove")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(PlanoPreviewCard);
