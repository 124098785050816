import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import qs from "qs";

import {
  setAPISettings,
  doneGetAPISettings,
  donePersistAPISettings,
} from "./actionTypes";
import { activeError, activeSuccess } from "~/components/Messages/actions";

/**
 * Service to get API settings
 */
export function* getAPISettings({ currentSettings }) {
  try {
    const user = yield http.get("/api/user/me");
    const organizationId = user.data?.organization?.id;
    const resp = yield http.get(
      `/api/apisettings/find-by-id/?${qs.stringify({ organizationId })}`
    );
    yield put(setAPISettings({ ...resp.data }));
    yield put(doneGetAPISettings());
  } catch (e) {
    const { response } = e;
    if (response.status === 400 || Object.keys(currentSettings).length === 0) {
      yield put(setAPISettings({}));
      yield put(doneGetAPISettings());
      return;
    }
    yield put(doneGetAPISettings());
    yield put(activeError("onboarding.general.errorGetSettings"));
  }
}

/**
 * Service to persist API settings
 */
export function* persistAPISettings({ payload }) {
  try {
    const resp = yield http.post("/api/apisettings/persist", payload);
    yield put(setAPISettings(resp.data));
    yield put(donePersistAPISettings());
    yield put(activeSuccess("onboarding.general.successPersistSettings"));
  } catch (e) {
    yield put(activeError("onboarding.general.errorPersistSettings"));
  }
}
