export const POST_OPENAI_CHAT = "POST_OPENAI_CHAT";
export const GET_OPENAI_CHAT = "GET_OPENAI_CHAT";
export const SET_OPENAI_CHAT = "SET_OPENAI_CHAT";
export const GET_OPENAI_CHAT_LIST = "GET_OPENAI_CHAT_LIST";
export const SET_OPENAI_CHAT_LIST = "SET_OPENAI_CHAT_LIST";
export const SAVE_DOCUMENT_EXPENSES = "SAVE_DOCUMENT_EXPENSES";
export const SET_SAVE_DOCUMENTEXPENSES_LOADING =
  "SET_SAVE_DOCUMENTEXPENSES_LOADING";
export const SET_DOCUMENT_EXPENSES_RESULT = "SET_DOCUMENT_EXPENSES_RESULT";
export const POST_IFLOW_FORM_EXPENSES = "POST_IFLOW_FORM_EXPENSES";
export const SET_ALERT_DOCUMENT_EXPENSE = "SET_ALERT_DOCUMENT_EXPENSE";
export const GET_DOCUMENT_EXPENSES_LIST = "GET_DOCUMENT_EXPENSES_LIST";
export const SET_DOCUMENT_EXPENSES_LIST = "SET_DOCUMENT_EXPENSES_LIST";
export const GET_DOCUMENT_EXPENSES_SPECIFIC = "GET_DOCUMENT_EXPENSES_SPECIFIC";
export const SET_DOCUMENT_EXPENSES_SPECIFIC = "SET_DOCUMENT_EXPENSES_SPECIFIC";
export const POST_CREATE_DOCUMENT_EXPENSES = "POST_CREATE_DOCUMENT_EXPENSES";
export const SET_CREATE_DOCUMENT_EXPENSES = "SET_CREATE_DOCUMENT_EXPENSES";
export const GENERATE_PROMPT = "GENERATE_PROMPT";
export const SET_GENERATE_PROMPT = "SET_GENERATE_PROMPT";
export const SET_PID_EXPENSES = "SET_PID_EXPENSES";
export const GENERATE_PROMPT_FROM_PROMPTS = "GENERATE_PROMPT_FROM_PROMPTS";
export const SAVE_FLOWID_PID_BATCH = "SAVE_FLOWID_PID_BATCH";

export const postOpenAiChat = (chat) => ({
  type: POST_OPENAI_CHAT,
  chat,
});

export const getOpenAiChat = (chatId) => ({
  type: GET_OPENAI_CHAT,
  chatId,
});

export const setOpenAiChat = (openAiChat) => ({
  type: SET_OPENAI_CHAT,
  openAiChat,
});

export const getOpenAiChatsList = (userId) => ({
  type: GET_OPENAI_CHAT_LIST,
  userId,
});

export const setOpenAiChatList = (openAiChatList) => ({
  type: SET_OPENAI_CHAT_LIST,
  openAiChatList,
});

export const saveDocumentExpenses = (docList) => {
  return { type: SAVE_DOCUMENT_EXPENSES, docList };
};

export const generatePrompt = (items, desc) => {
  return { type: GENERATE_PROMPT, items, desc};
};

export const generatePromptFromPrompts = (items, desc) => {
  return { type: GENERATE_PROMPT_FROM_PROMPTS, items, desc };
}

export const setGeneratePrompt = (prompt) => {
  return { type: SET_GENERATE_PROMPT, prompt };
}

export const postCreateDocumentExpenses = (payload) => {
  return { type: POST_CREATE_DOCUMENT_EXPENSES, payload };
};

export const setSaveDocumentExpensesLoading = (loading) => {
  return { type: SET_SAVE_DOCUMENTEXPENSES_LOADING, loading };
};

export const setPidExpenses = (pid) => {
  return { type: SET_PID_EXPENSES, pid };
}

export const setDocumentExpensesResult = (expense) => {
  return { type: SET_DOCUMENT_EXPENSES_RESULT, expense };
};

export const postIflowFormExpenses = (payload, url) => ({
  type: POST_IFLOW_FORM_EXPENSES,
  payload,
  url,
});

export const saveFlowIDPidBatch = (payload) => (
  {
    type: SAVE_FLOWID_PID_BATCH,
    payload
  }
)

export const setAlertDocumentExpenses = (alert) => ({
  type: SET_ALERT_DOCUMENT_EXPENSE,
  alert,
});

export const getDocumentExpensesList = (usrId) => ({
  type: GET_DOCUMENT_EXPENSES_LIST,
  usrId,
});

export const setDocumentExpensesList = (list) => ({
  type: SET_DOCUMENT_EXPENSES_LIST,
  list,
});

export const getDocumentExpensesSpecific = (docId) => ({
  type: GET_DOCUMENT_EXPENSES_SPECIFIC,
  docId,
});

export const setDocumentExpensesSpecific = (doc) => ({
  type: SET_DOCUMENT_EXPENSES_SPECIFIC,
  doc,
});

export const setCreateDocumentExpenses = (message, id) => ({
  type: SET_CREATE_DOCUMENT_EXPENSES,
  message,
  id,
});
