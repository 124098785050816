import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import iconGreenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import iconWaiting from "~/pages/Easypay/assets/img/icons/icon-waiting.png";
import iconInfo from "~/pages/Easypay/assets/img/icons/icon-info.png";
import "../assets/scss/_toggleAccordion.scss";
import CreateInputDropdown from "../CreateForm/components/CreateInputDropdown";
import Button from "./Button";
import Input from "./Input";
import PEPCheckbox from "./PEPCheckbox";
import Text from "./Text";
import Toggle from "./Toggle";

const ToggleAccordion = ({ info, entityType, t }) => {
  const [data, setData] = useState(-1);
  const textRequestSMS =
    "Por favor, faculte o número de telmóvel deste representante legal para quem será enviada uma SMS para realizar a prova de vida.::card-text-light-small-padding";
  function buildInputField(text, variable) {
    return {
      value: "",
      obligatory: false,
      variable: variable,
      maxlenght: 9,
      text: text,
    };
  }

  const buttonField = {
    type: "link",
    text: t("bpm.onboarding.backoffice.user.buttonAdd"),
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='sendSMS';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  useEffect(() => {
    if (
      info.get("PDATelemovel").value != "" &&
      info.get("PROCNome").value === ""
    ) {
      setData(0);
    } else if (info.get("PROCTelemovel").value != "") {
      setData(1);
    }
  }, [data]);

  function buildDropdownField(text, variable) {
    return {
      type: "selection",
      obligatory: "false",
      text: text,
      variable: variable,
      onchange_submit:
        "camouflageForm();document.dados.op.value='16';document.dados.submit();",
      option: [
        {
          text: "+049",
          value: "DE",
          selected: "no",
        },
        {
          text: "+351",
          value: "PT",
          selected: "yes",
        },
        {
          text: "+034",
          value: "ES",
          selected: "no",
        },
      ],
      even_field: "true",
    };
  }

  function checkToggleIndex(index) {
    if (
      info.get("PDATelemovel").value != "" ||
      info.get("PROCTelemovel").value != ""
    )
      return true;
    if (data === -1 || data === index) return false;
    else return true;
  }

  const handleToggleState = (index) => {
    if (data === -1) {
      setData(index);
    } else setData(-1);
  };

  return (
    <>
      <div className="easypay-toggle-accordion-wrapper" key={0}>
        <div className="easypay-toggle-accordion-header">
          <div className="easypay-toggle-accordion-name">
            {t(`bpm.onboarding.backoffice.user.labelPDA`)}
          </div>
          <div className="easypay-top-toggle-container">
            <Toggle
              id={"toggle-accordion-id" + uuid()}
              disabled={checkToggleIndex(0)}
              onClick={() => handleToggleState(0)}
              disabledColored={
                info.get("PDATelemovel").value != "" &&
                info.get("PROCNome").value === "" &&
                info.get("PROCNIF").value === ""
              }
            ></Toggle>
          </div>
        </div>
        {data === 0 &&
        info.get("pv").value != "0" &&
        info.get("PDATelemovel").value != "" ? (
          <div className="easypay-toggle-accordion-content">
            <div className="easypay-toggle-accordion-text-wrapper">
              <div className="easypay-text-icon-check">
                <img src={iconGreenCheckmark} alt="Icon Check" />
              </div>
              <Text
                text={
                  t(`bpm.onboarding.backoffice.user.labelLifeProof`) +
                  "::card-text-green"
                }
              ></Text>
            </div>
          </div>
        ) : data === 0 && info.get("PDATelemovel").value != "" ? (
          data === 0 && (
            <div className="easypay-toggle-accordion-content">
              <div className="easypay-toggle-accordion-text-wrapper">
                <div className="easypay-text-icon-check">
                  <img src={iconWaiting} alt="Icon Check" />
                </div>
                <Text
                  text={
                    "Prova de vida por realizar,carregue no submeter quando realizada." +
                    "::card-text-warning"
                  }
                ></Text>
              </div>
            </div>
          )
        ) : (
          data === 0 && (
            <div className="easypay-toggle-accordion-content">
              <div className="easypay-toggle-accordion-text-wrapper">
                <div className="easypay-text-icon">
                  <img src={iconInfo} alt="Icon Check" />
                </div>
                <Text
                  text={
                    t(`bpm.onboarding.backoffice.user.PDALifeProofText`) +
                    "::card-text-light-small-padding"
                  }
                ></Text>
              </div>
              <div className="easypay-toggle-accordion-pda-input-wrapper">
                <div style={{ width: "30%", paddingRight: "20px" }}>
                  <CreateInputDropdown
                    key={uuid()}
                    field={buildDropdownField(
                      t(`bpm.onboarding.backoffice.user.labelCountry`) +
                        "::toggle-dropdown",
                      "repleg_pdapais"
                    )}
                  />
                </div>
                <div style={{ width: "70%" }}>
                  <Input
                    field={buildInputField(
                      t(`bpm.onboarding.backoffice.user.labelMobile`),
                      "repleg_pdamobile_edit"
                    )}
                    key={"repleg_pdamobile_edit"}
                  ></Input>
                </div>
              </div>
              <div className="easypay-repleg-toggle-button-wrapper">
                <Button
                  text={"Enviar SMS"}
                  variant={"easypay-button-primary"}
                  align={"left"}
                  onclick={buttonField.onclick}
                  key={uuid()}
                  id={buttonField.id}
                  className="card-button"
                  disabledColored={false}
                  disabled={false}
                  field={buttonField}
                ></Button>
              </div>
            </div>
          )
        )}
      </div>
      <div
        className="easypay-toggle-accordion-wrapper"
        key={1}
        style={{ border: "none" }}
      >
        <div className="easypay-toggle-accordion-header">
          <div className="easypay-toggle-accordion-name">
            {t(`bpm.onboarding.backoffice.user.labelProc`)}
          </div>
          <div className="easypay-top-toggle-container">
            <Toggle
              id={"toggle-accordion-id" + uuid()}
              disabled={checkToggleIndex(1)}
              onClick={() => handleToggleState(1)}
              disabledColored={
                info.get("PROCTelemovel").value != "" &&
                info.get("PROCNIF").value != ""
              }
            ></Toggle>
          </div>
        </div>
        {data === 1 &&
        info.get("PROCTelemovel").value != "" &&
        info.get("pv").value != "0" ? (
          <div className="easypay-toggle-accordion-content">
            <div className="easypay-toggle-accordion-text-wrapper">
              <div className="easypay-text-icon-check">
                <img src={iconGreenCheckmark} alt="Icon Check" />
              </div>
              <Text
                text={
                  t(`bpm.onboarding.backoffice.user.labelLifeProof`) +
                  "::card-text-green"
                }
              ></Text>
            </div>
          </div>
        ) : data === 1 && info.get("PROCTelemovel").value != "" ? (
          <div className="easypay-toggle-accordion-content">
            <div className="easypay-toggle-accordion-text-wrapper">
              <div className="easypay-text-icon-check">
                <img src={iconWaiting} alt="Icon Check" />
              </div>
              <Text
                text={
                  "Prova de vida por realizar,carregue no submeter quando realizada." +
                  "::card-text-warning"
                }
              ></Text>
            </div>
          </div>
        ) : (
          data === 1 && (
            <div className="easypay-toggle-accordion-content">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelName`),
                  "teste_nome"
                )}
                key={"teste_nome"}
              ></Input>
              <div style={{ width: "45%" }}>
                <Input
                  field={buildInputField(
                    t(`bpm.onboarding.backoffice.user.labelNIF`),
                    "repleg_procnif_edit"
                  )}
                  key={"repleg_procnif_edit"}
                ></Input>
              </div>
              <div className="easypay-toggle-accordion-text-wrapper">
                <div className="easypay-text-icon">
                  <img src={iconInfo} alt="Icon Check" />
                </div>
                <Text
                  text={
                    t(`bpm.onboarding.backoffice.user.PDALifeProofText`) +
                    "::card-text-light-small-padding"
                  }
                ></Text>
              </div>
              <div className="easypay-toggle-accordion-pda-input-wrapper">
                <div style={{ width: "30%", paddingRight: "20px" }}>
                  <CreateInputDropdown
                    key={uuid()}
                    field={buildDropdownField(
                      t(`bpm.onboarding.backoffice.user.labelCountry`) +
                        "::toggle-dropdown",
                      "repleg_procpais_edit"
                    )}
                  />
                </div>
                <div style={{ width: "70%" }}>
                  <Input
                    field={buildInputField(
                      t(`bpm.onboarding.backoffice.user.labelMobile`),
                      "repleg_proc_mobile_edit"
                    )}
                    key={"repleg_proc_mobile_edit"}
                  ></Input>
                </div>
              </div>
              <div className="easypay-repleg-toggle-button-wrapper">
                <Button
                  text={"Enviar SMS"}
                  variant={"easypay-button-primary"}
                  align={"left"}
                  onclick={buttonField.onclick}
                  key={uuid()}
                  id={buttonField.id}
                  className="card-button"
                  disabledColored={false}
                  disabled={false}
                  field={buttonField}
                ></Button>
              </div>
              <PEPCheckbox
                checked={info.get("PROCPEP")}
                input={info.get("PROCPEPInput")}
                variable={"repleg_proc_pep_edit"}
              ></PEPCheckbox>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default withNamespaces()(ToggleAccordion);
