import React, { useState, useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import signature from "~/assets/img/icons/signature.svg";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  updateSelectedTosignLinkComponent,
  setSelectedTosignLinkComponent,
  getIflowDocumentLinkComponent,
  updateIsuseeffectRun,
} from "~/store/ducks/processesSignature/actionTypes";

import { substituteIflowDocument } from "~/store/ducks/processes/actionTypes";
import {
  getSignedPdfs,
  setSignedPdf,
} from "~/store/ducks/onboarding/actionTypes";
import SignPdfModal from "../SignPdfModal";
import pdfsample from "../../utils/pdfsample";
import oauth from "~/utils/oauth";
import { FaFileSignature } from "react-icons/fa";
const devLogConsole = require("~/utils/devLog");
const CreateTableDocumentLink = (props) => {
  const { t, field, cell, rowIndex, styleSheet } = props;

  const [extension, setExtension] = useState("");

  const [showSignModal, setShowSignModal] = useState(false);
  const [documentBlobs, setDocumentBlobs] = useState([]);
  const [base64, setBase64] = useState("");
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const {
    iflowDocumentLinkComponent,
    selectedToSignLinkComponent,
    isuseEffectRun,
    isLoadingIflowDocumentLinkComponent,
  } = useSelector((state) => state.processesSignatureReducer);

  const { signedPdf } = useSelector((state) => state.onboardingReducer);

  useEffect(() => {
    const extension_ = field?.text?.split(".").pop().toLocaleLowerCase();
    if (extension_) setExtension(extension_);
  }, [field]);

  useEffect(() => {
    if (
      selectedToSignLinkComponent &&
      selectedToSignLinkComponent.some((i) => i.href === field.href)
    ) {
      const component = myRef.current;
      if (component) {
        component.checked = true;
      }
    }
  }, [selectedToSignLinkComponent, field]);

  /* start sign process*/
  useEffect(() => {
    if (
      Array.isArray(iflowDocumentLinkComponent) &&
      Array.isArray(selectedToSignLinkComponent) &&
      iflowDocumentLinkComponent.length > 0 &&
      selectedToSignLinkComponent.length > 0 &&
      isuseEffectRun
    ) {
      let filesReturned = true;
      selectedToSignLinkComponent.forEach((check) => {
        if (!iflowDocumentLinkComponent.some((i) => i.href === check.href)) {
          //not all files have returned from iflow yet
          filesReturned = false;
        }
      });
      if (filesReturned) {
        let blobsarr = [];
        if (selectedToSignLinkComponent.length > 1) {
          iflowDocumentLinkComponent.forEach((d) => {
            //convert file to Blob
            const file = new Blob([d.file], {
              type: "application/pdf",
            });
            blobsarr.push({
              file: file,
              filename: d.filename,
              href: d.href,
            });
          });
          setDocumentBlobs(blobsarr);
          setBase64(pdfsample);
          handleShowSignModal();
        } else {
          iflowDocumentLinkComponent.forEach((d) => {
            //convert file to Blob
            const file = new Blob([d.file], {
              type: "application/pdf",
            });
            //todo find selected
            if (d.href === selectedToSignLinkComponent[0].href) {
              fileToBase64(file)
                .then((result) => {
                  setBase64(result);
                })
                .catch((e) => Error(e));
            }
            blobsarr.push({
              file: file,
              filename: d.filename,
              href: d.href,
            });
          });
          setDocumentBlobs(blobsarr);
          handleShowSignModal();
        }
      }
      dispatch(updateIsuseeffectRun());
    }
  }, [
    iflowDocumentLinkComponent,
    field,
    selectedToSignLinkComponent,
    isuseEffectRun,
  ]);

  //Substitute the document in IFlow with the signed one
  useEffect(() => {
    if (signedPdf && selectedToSignLinkComponent?.length > 0) {
      //find min cell
      let mincell = selectedToSignLinkComponent[0]?.cell;
      let minRowIndex = selectedToSignLinkComponent[0]?.rowIndex;
      selectedToSignLinkComponent.forEach((item) => {
        if (item.cell < mincell) {
          mincell = item.cell;
          minRowIndex = item.rowIndex;
        }
      });

      if (
        cell === mincell &&
        rowIndex === minRowIndex &&
        selectedToSignLinkComponent.some(
          (i) => i.cell === mincell && i.rowIndex === minRowIndex
        )
      ) {
        //update iFlow table once
        if (signedPdf.fileList && signedPdf.fileList.length > 0) {
          /*
          devLogConsole(
            "selectedToSignLinkComponent",
            selectedToSignLinkComponent,
            cell,
            rowIndex
          );*/
          signedPdf.fileList.forEach((item) => {
            const base64String = item.file;
            if (base64String) {
              //convert base64 to file
              const url = `data:application/pdf;base64,${base64String}`;
              function urltoFile(url, filename, mimeType) {
                return fetch(url)
                  .then(function (res) {
                    return res.arrayBuffer();
                  })
                  .then(function (buf) {
                    return new File([buf], filename, { type: mimeType });
                  });
              }

              const findDocId = (name) => {
                let isUpdatingIflowDoc = false;
                if (
                  selectedToSignLinkComponent.some(
                    (item) =>
                      item.text.toLocaleLowerCase() ===
                      `${name}.pdf`.toLocaleLowerCase()
                  )
                ) {
                  isUpdatingIflowDoc = true;
                }
                if (isUpdatingIflowDoc) {
                  const foundItem = selectedToSignLinkComponent.find(
                    (i) =>
                      i.text.toLocaleLowerCase() ===
                      `${name}.pdf`.toLocaleLowerCase()
                  );
                  if (foundItem?.href.includes("docid=")) {
                    const arr = foundItem.href.split("docid=");
                    const arr2 = arr[1].split("&");
                    if (arr2[0]) return arr2[0];
                  }
                }
                return null;
              };

              const findVariable = (name) => {
                let isUpdatingIflowDoc = false;
                if (
                  selectedToSignLinkComponent.some(
                    (item) =>
                      item.text.toLocaleLowerCase() ===
                      `${name}.pdf`.toLocaleLowerCase()
                  )
                ) {
                  isUpdatingIflowDoc = true;
                }
                if (isUpdatingIflowDoc) {
                  const foundItem = selectedToSignLinkComponent.find(
                    (i) =>
                      i.text.toLocaleLowerCase() ===
                      `${name}.pdf`.toLocaleLowerCase()
                  );
                  if (foundItem.variable) {
                    return foundItem?.variable;
                  }
                }
                return null;
              };
              urltoFile(url, item.name, "application/pdf").then(function (
                file
              ) {
                const data = new FormData();
                const allFormdata = new FormData(
                  document.getElementsByName("dados")[0]
                );
                const flowId = allFormdata.get("flowid");
                const pid = allFormdata.get("pid");
                const subpid = allFormdata.get("subpid");

                //from AppletDocParameters.java
                data.append("file", file);
                data.append("variable", findVariable(item.name));

                data.append("update", "true");
                data.append("NUMASS", "1");
                data.append("docid", findDocId(item.name));

                data.append("flowid", flowId);
                data.append("pid", pid);
                data.append("subpid", 1);

                const url_ =
                  "/DocumentService?" +
                  "Authorization=" +
                  oauth.getAccessToken();

                //devLogConsole("docid", item.name, findDocId(item.name));
                findDocId(item.name) !== null &&
                  findDocId(item.name) !== undefined &&
                  dispatch(substituteIflowDocument(url_, data));
              });
            }
          });

          dispatch(setSignedPdf({}));
          dispatch(setSelectedTosignLinkComponent([]));
        }
      }
    }
  }, [signedPdf, field, selectedToSignLinkComponent]);

  const handleSignAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    //check if data already loaded
    if (
      Array.isArray(iflowDocumentLinkComponent) &&
      Array.isArray(selectedToSignLinkComponent) &&
      iflowDocumentLinkComponent.length > 0 &&
      selectedToSignLinkComponent.length > 0
    ) {
      let filesReturned = true;
      selectedToSignLinkComponent.forEach((check) => {
        if (!iflowDocumentLinkComponent.some((i) => i.href === check.href)) {
          //not all files have returned from iflow yet
          filesReturned = false;
        }
      });
      if (filesReturned) {
        let blobsarr = [];
        iflowDocumentLinkComponent.forEach((d) => {
          //convert file to Blob
          const file = new Blob([d.file], {
            type: "application/pdf",
          });
          blobsarr.push({
            file: file,
            filename: d.filename,
            href: d.href,
          });
        });

        if (selectedToSignLinkComponent.length > 1) {
          setBase64(pdfsample);
          setShowSignModal(true);
        } else {
          //find selected
          const foundItem = iflowDocumentLinkComponent.find(
            (i) => i.href === selectedToSignLinkComponent[0].href
          );
          if (foundItem) {
            const file = new Blob([foundItem.file], {
              type: "application/pdf",
            });
            fileToBase64(file)
              .then((result) => {
                setBase64(result);
              })
              .catch((e) => Error(e));
          } else {
            setBase64(pdfsample);
          }
          setShowSignModal(true);
        }
        setDocumentBlobs(blobsarr);
      } else {
        //load data
        selectedToSignLinkComponent.forEach((item) => {
          if (item.href !== "") {
            if (!iflowDocumentLinkComponent.some((i) => i.href === item.href)) {
              const payload = {
                url: item.href + "&Authorization=" + oauth.getAccessToken(),
                href: item.href,
                filename: item.text,
              };
              dispatch(getIflowDocumentLinkComponent(payload));
            } else {
              //document already loaded
            }
          }
        });
      }
    } else {
      //load data
      selectedToSignLinkComponent.forEach((item) => {
        if (item.href !== "") {
          const payload = {
            url: item.href + "&Authorization=" + oauth.getAccessToken(),
            href: item.href,
            filename: item.text,
          };
          dispatch(getIflowDocumentLinkComponent(payload));
        }
      });
    }
  };

  const handleShowSignModal = () => {
    //find min cell
    //let mincell = cell;
    //let minRowIndex = rowIndex;
    let mincell = selectedToSignLinkComponent[0]?.cell;
    let minRowIndex = selectedToSignLinkComponent[0]?.rowIndex;
    selectedToSignLinkComponent.forEach((item) => {
      if (item.cell < mincell) {
        mincell = item.cell;
        minRowIndex = item.rowIndex;
      }
    });
    //find min rowIndex
    /*
    selectedToSignLinkComponent.forEach((item) => {
      if (item.rowIndex < minRowIndex) {
        minRowIndex = item.rowIndex;
      }
    });*/
    if (
      cell === mincell &&
      rowIndex === minRowIndex &&
      selectedToSignLinkComponent.some(
        (i) => i.cell === mincell && i.rowIndex === minRowIndex
      )
    ) {
      //show only one modal
      setShowSignModal(true);
    }
  };

  const handleCloseSignModal = () => {
    setShowSignModal(false);
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //sign document
  async function testSignPdf(
    coordinates,
    pageNumber,
    lastPage,
    reduced,
    givenLocation,
    givenReason,
    rectCoord,
    rectCoordTopLeft,
    rectCoordBottomRight,
    longText,
    stampAll,
    stampImage,
    stampWithoutSign
  ) {
    let payload = [];
    /*devLogConsole(
      "selectedToSign and blobs",
      selectedToSignLinkComponent,
      documentBlobs
    );*/
    const promises = documentBlobs.map(async (d) => {
      const result = await fileToBase64(d?.file).catch((e) => Error(e));

      let stampResult = undefined;
      if (stampImage)
        stampResult = await fileToBase64(stampImage).catch((e) => Error(e));
      return {
        file: result,
        filename: d.filename,
        href: d.href,
        stampImage: stampResult,
      };
    });
    const results = await Promise.all(promises);
    if (results) {
      results.forEach((r) => {
        try {
          const part =
            r.file && r.file.split("data:application/pdf;base64,")[1];
          //const stampPart = r.stampImage && r.stampImage.split(",")[1];
          const stampPart = r.stampImage;
          if (part) {
            if (
              Array.isArray(selectedToSignLinkComponent) &&
              selectedToSignLinkComponent.some((i) => i.href === r.href)
            )
              payload.push({
                file: part,
                fileName: r.filename.replace(/\.[^/.]+$/, ""), //without extension
                stampImage: stampPart,
              });
          }
        } catch (e) {
          devLogConsole("error", e);
          devLogConsole("result", r);
        }
      });
    }

    if (payload.length > 0) {
      const queryParams = {
        posX: coordinates.x ? coordinates.x : 0.1,
        posY: coordinates.y ? coordinates.y : 0.1,
        pagina: pageNumber ? pageNumber : 1,
        ultima: lastPage !== null ? lastPage : false,
        visivel: true,
        reduzido: reduced !== null ? reduced : true,
        givenLocation,
        givenReason,
        additionalText: longText,
        boxLowerLeftX: rectCoord ? rectCoord.x : 0.1,
        boxLowerLeftY: rectCoord ? rectCoord.y : 0.1,
        boxHigerLeftX: rectCoordTopLeft ? rectCoordTopLeft.x : 0.1,
        boxHigherLeftY: rectCoordTopLeft ? rectCoordTopLeft.y : 0.1,
        boxLowerRightX: rectCoordBottomRight ? rectCoordBottomRight.x : 0.1,
        boxLowerRightY: rectCoordBottomRight ? rectCoordBottomRight.y : 0.1,
        stampAll: stampAll,
        stampWithoutSign: stampWithoutSign,
      };

      dispatch(getSignedPdfs(payload, queryParams));
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <a
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            wordBreak: "break-all",
          }}
          download="sample.pdf"
          href={field.href + "&Authorization=" + oauth.getAccessToken()}
        >
          {field.text || "file"}
        </a>
        {extension === "pdf" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            {/*<OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip`}>{t("fileInput.signed")}</Tooltip>
              }
            >
              <i
                className="icon-check"
                style={{
                  margin: "5px 15px",
                  color: "#28a745",
                }}
              ></i>
              </OverlayTrigger>*/}
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip`}>{t("fileInput.toSignSelect")}</Tooltip>
              }
            >
              <input
                ref={myRef}
                type="checkbox"
                style={{
                  margin: "5px 15px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  zIndex: 100,
                }}
                onChange={(e) => {
                  {
                    const payload = {
                      ...field,
                      rowIndex: rowIndex,
                      cell: cell,
                    };
                    dispatch(
                      updateSelectedTosignLinkComponent(
                        payload,
                        e?.target?.checked,
                        rowIndex,
                        cell
                      )
                    );
                  }
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip`}>{t("fileInput.toSign")}</Tooltip>
              }
            >
              {styleSheet.iconSignature &&
              styleSheet.iconSignature === "FaFileSignature" ? (
                <FaFileSignature
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    color: "#24579e",
                    zIndex: 100,
                  }}
                  onClick={(e) => handleSignAll(e)}
                />
              ) : (
                <img
                  src={signature}
                  style={{
                    marginTop: "-5px",
                    height: "30px",
                    width: "30px",
                    zIndex: 100,
                  }}
                  onClick={(e) => handleSignAll(e)}
                />
              )}
            </OverlayTrigger>
          </div>
        )}
      </div>
      <SignPdfModal
        showSignModal={showSignModal}
        handleCloseSignModal={handleCloseSignModal}
        testSignPdf={testSignPdf}
        file={base64}
        isLoadingIflowDocument={isLoadingIflowDocumentLinkComponent}
        selectedToSign={selectedToSignLinkComponent}
      />
    </>
  );
};
export default withNamespaces()(CreateTableDocumentLink);
