import he from "he";

export const SIGNATURE_COORDINATES_X = 0.01;
export const SIGNATURE_COORDINATES_Y = 0.75;
const devLogConsole = require("~/utils/devLog");
export const transformFormData = (formdata) => {
  let pairsToAppend = [];
  let pairsToAppendFile = [];
  let pairsToAppendFiles = [];
  let pairsToAppendTextBox = [];

  for (let pair of formdata.entries()) {
    if (pair[0].includes("DateToTransform")) {
      pairsToAppend.push(pair);
    }
    if (pair[0].includes("FileToTransform")) {
      pairsToAppendFile.push(pair);
    }
    if (pair[0].includes("FilesToTransform")) {
      pairsToAppendFiles.push(pair);
    }
    if (pair[0].includes("TextBoxToTransform")) {
      pairsToAppendTextBox.push(pair);
    }
  }

  //transform text input
  pairsToAppendTextBox.forEach((pair, index) => {
    const formKey = pair[0].split("&variable=")[1];
    const arr1 = pair[0].split("outputFormat=")[1];
    const outputFormat = arr1.split("&variable=")[0];
    if (outputFormat === "00h:00m") {
      if (pair[1]) {
        let value;
        if (pair[1].includes(":")) {
          value = pair[1].slice(0, 2) + "h" + pair[1].slice(2, 5) + "m";
        } else {
          value = pair[1].replace(/(\d{2})(\d{2})/, "$1h:$2m?").split("?")[0];
        }
        if (value) {
          if (value.includes("undefined")) value = "";
          formdata.append(formKey, value);
        }
        formdata.delete(pair[0]);
      } else {
        //empty value
        formdata.append(formKey, "");
        formdata.delete(pair[0]);
      }
    } else {
      if (pair[1]) {
        let value = pair[1];
        if (value.includes("undefined")) value = "";
        formdata.append(formKey, value);
        formdata.delete(pair[0]);
      }
    }
  });

  //Transform Date
  pairsToAppend.forEach((pair) => {
    const formKey = pair[0].split("&variable=")[1];
    const arr1 = pair[0].split("dateformattext=")[1];
    const outputFormat = arr1.split("&variable=")[0];
    //datetime picker format: 2021-10-01T00:00
    let transformedValue;
    if (pair[1].length >= 16) {
      const dateArray = pair[1].split("-");
      const year = dateArray[0];
      const month = dateArray[1];
      const time = dateArray[2].split("T")[1];
      const timeArr = time.split(":");
      const day = dateArray[2].split("T")[0] || 0;
      const hour = timeArr[0];
      const minute = timeArr[1];

      if (outputFormat === "yyyy/MM/dd") {
        transformedValue = year + "/" + month + "/" + day;
      } else if (outputFormat === "yyyy/MM/dd HH:mm") {
        transformedValue =
          year + "/" + month + "/" + day + " " + hour + ":" + minute;
      } else if (outputFormat === "dd/MM/yyyy") {
        transformedValue = day + "/" + month + "/" + year;
      } else if (outputFormat === "dd/MM/yyyy HH:mm") {
        transformedValue =
          day + "/" + month + "/" + year + " " + hour + ":" + minute;
      }
    }

    if (transformedValue) {
      if (transformedValue.includes("undefined")) transformedValue = "";
      formdata.append(formKey, transformedValue);
    }
    formdata.delete(pair[0]);
  });

  //transform File
  pairsToAppendFile.forEach((pair) => {
    const formKey = pair[0].split("&variable=")[1];
    const arr1 = pair[0].split("filename=")[1];
    const filename = arr1.split("&variable=")[0];
    if (pair[1] && pair[1].size > 0) {
      formdata.append(formKey, pair[1], filename, { type: "application/pdf" });
    }
    formdata.delete(pair[0]);
  });

  //transform Files
  const fileVariables = [];
  pairsToAppendFiles.forEach((pair, index) => {
    const variable = pair[0].split("&variable=")[1];
    if (!fileVariables.some((i) => i === variable)) {
      fileVariables.push(variable);
    }
  });

  if (fileVariables.length > 0) {
    fileVariables.forEach((variavel) => {
      let varIndex = 0;
      pairsToAppendFiles.forEach((pair) => {
        const variable = pair[0].split("&variable=")[1];
        if (variable === variavel) {
          const formKey = `${variable}_add_[${varIndex}]`;
          const filename = pair[1].name || "document";
          if (pair[1] && pair[1].size > 0) {
            formdata.append(formKey, pair[1], filename, {
              type: "application/pdf",
            });
          }
          formdata.delete(pair[0]);
          varIndex = varIndex + 1;
        }
      });
    });
  }

  for (var pair of formdata.entries()) {
    // devLogConsole(pair[0] + ": " + pair[1]);
  }
  return formdata;
};

export const handleAccrodionKey = (id, tabDiv) => {
  let arrays = JSON.parse(localStorage.getItem("iflowAccordion")) || [];
  if (arrays.some((a) => a.id === tabDiv.id)) {
    let found = arrays.find((a) => a.id === tabDiv.id);
    if (found.key !== id) {
      //add
      found.key = id;
    } else {
      //remove
      found.key = null;
    }
  } else {
    //add
    arrays.push({
      id: tabDiv.id,
      key: id,
    });
  }
  //update
  localStorage.setItem("iflowAccordion", JSON.stringify(arrays));
};

export const getHeaderName = (jsonData) => {
  if (jsonData && jsonData.form) {
    if (Array.isArray(jsonData.form.blockdivision)) {
      if (
        Array.isArray(jsonData?.form?.blockdivision[0]?.columndivision?.field)
      ) {
        return (
          jsonData?.form?.blockdivision[0]?.columndivision?.field?.find(
            (f) => f.type === "header"
          )?.text || ""
        );
      } else {
        if (
          jsonData?.form?.blockdivision[0]?.columndivision?.field?.type ===
          "header"
        ) {
          return jsonData?.form?.blockdivision[0]?.columndivision?.field?.text;
        } else return "";
      }
    } else {
      if (Array.isArray(jsonData.form.blockdivision.columndivision.field)) {
        return jsonData?.form?.blockdivision?.columndivision?.field?.find(
          (f) => f.type === "header"
        )?.text;
      } else {
        if (
          jsonData?.form?.blockdivision?.columndivision?.field?.type ===
          "header"
        ) {
          return jsonData?.form?.blockdivision?.columndivision?.field?.text;
        } else return "";
      }
    }
  } else return "";
};

export const hasLinks = (columndivision) => {
  let hasLink = false;

  columndivision.forEach((colD) => {
    Object.keys(colD).forEach((columnKey) => {
      if (columnKey === "field") {
        let fieldArray = colD.field ? colD.field : [];
        if (!Array.isArray(fieldArray)) {
          fieldArray = [fieldArray];
        }

        fieldArray.forEach((field) => {
          if (field.type === "link") hasLink = true;
        });
      }
    });
  });

  return hasLink;
};

export const getUrlVars = (url) => {
  let vars = {};
  let parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export const parseHtmlText = (str, delimiterStart, delimiterEnd) => {
  if (str && str.includes(delimiterStart) && str.includes(delimiterEnd)) {
    const text = [];
    const arr = str.split(delimiterStart);
    arr.forEach((i) => {
      if (i.includes(delimiterEnd)) {
        const paragraph = i.split(delimiterEnd)[0];
        if (paragraph) {
          text.push(he.decode(paragraph));
        }
      }
    });
    return text.join(" ");
  }
  return "";
};

//find app from url
export const getApplication = (url) => {
  let app = "bpm";
  if (url) {
    const arr1 = url.includes("/uniksystem/") && url.split("/uniksystem/");
    if (arr1.length > 1) {
      const arr2 = arr1[1];
      const arr3 = arr2.split("/");
      app = arr3[0];
    }
  }

  return app;
};
