import React from "react";
import { withNamespaces } from "react-i18next";
import iconInfo from "~/pages/Easypay/assets/img/icons/icon-checkmark.png";
import "../assets/css/styles.css";
import "../assets/scss/_backofficeProvadeVidaSection.scss";
import Input from "./Input";
import Link from "./Link";

const BackofficeProvadeVidaSection = ({ t, data }) => {
  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const dateFormatter = (date) => {
    if (!date) {
      return date;
    }
    const dateAux = new Date(date);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const capitalizeText = (cell) =>
    cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : "";

  const renderProofOFLifeState = () => {
    return data?.client?.pvEstado === 1 ? (
      <>
        <div className="easypay-text-icon-check">
          <img src={iconInfo} alt="Icon Check" />
        </div>
        <div className="easypay-backoffice-provadevida-state-result-text">
          {t(
            "bpm.onboarding.backoffice.administration.labelProofOfLifeCompleted"
          )}
        </div>
      </>
    ) : (
      ""
    );
  };

  return (
    <div className="easypay-backoffice-sections-wrapper">
      <div className="easypay-backoffice-provadevida-section-header">
        {t("bpm.onboarding.backoffice.administration.proofOfLifeButton")}
      </div>
      <div className="easypay-backoffice-provadevida-titles-wrapper">
        <div className="easypay-backoffice-provadevida-method-title">
          {t("bpm.onboarding.backoffice.administration.headerMethod")}
        </div>
        <div className="easypay-backoffice-provadevida-date-title">
          {t("bpm.onboarding.backoffice.administration.headerDate")}
        </div>
        <div className="easypay-backoffice-provadevida-state-title">
          {t("bpm.onboarding.backoffice.administration.headerState")}
        </div>
      </div>
      <div className="easypay-backoffice-provadevida-results-wrapper">
        <div className="easypay-backoffice-provadevida-method-result">
          {capitalizeText(data?.client?.pvMetodo)}
        </div>
        <div className="easypay-backoffice-provadevida-date-result">
          {dateFormatter(data?.client?.pvCreatedDate)}
        </div>
        <div className="easypay-backoffice-provadevida-state-result">
          <div className="easypay-backoffice-provadevida-state-result-content">
            {renderProofOFLifeState()}
          </div>
        </div>
      </div>
      <Link
        text={t(
          "bpm.onboarding.backoffice.administration.linkRequestProofOfLife"
        )}
        link="https://unikint.uniksystem.pt/#/"
      />
      <div className="easypay-backoffice-provadevida-data-received-header">
        {t("bpm.onboarding.backoffice.administration.headerAquiredData")}
      </div>
      <div className="easypay-backoffice-provadevida-content-wrapper">
        <div className="easypay-backoffice-registry-section-column-content">
          <Input
            field={buildInputField(
              t("bpm.onboarding.backoffice.administration.tableName") +
                "::disabled",
              data?.client?.pvNome
            )}
            name="dados"
          />
          <Input
            field={buildInputField(
              t("bpm.onboarding.backoffice.administration.headerDateOfBirth") +
                "::disabled",
              data?.client?.pvDataNascimento?.slice(0,10)
            )}
            name="dados"
          />
          <Input
            field={buildInputField(
              t("bpm.onboarding.backoffice.administration.headerDocType") +
                "::disabled",
              data?.client?.pvTipoDocumento
            )}
            name="dados"
          />
        </div>
        <div className="easypay-backoffice-registry-section-column-content">
          <Input
            field={buildInputField(
              t("bpm.onboarding.backoffice.administration.headerNationality") +
                "::disabled",
              data?.client?.pvNacionalidade
            )}
            name="dados"
          />
          <Input
            field={buildInputField(
              t("bpm.onboarding.backoffice.administration.headerNIF") +
                "::disabled",
              data?.client?.pvNif
            )}
            name="dados"
          />
          <div className="easypay-backoffice-provadevida-bottom-right-input-wrapper">
            <div className="easypay-backoffice-provadevida-small-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerDocNum") +
                    "::disabled",
                  data?.client?.pvNumDocumento
                )}
                name="dados"
              />
            </div>
            <div className="easypay-backoffice-provadevida-small-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.validityHeader") +
                    "::disabled",
                  dateFormatter(data?.client?.pvDataExpiracao)
                )}
                name="dados"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackofficeProvadeVidaSection);
