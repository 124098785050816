import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import { BPM_MIDDLEWARE } from "~/utils/constants";

import {
  setAprovalDelegacoes,
  setDelegacoes,
  setOwners,
  setSuccess,
  setUserFlow,
  setUsers,
  setNotification,
  setDelegacoesByUserid,
  setRequestedDelegation,
} from "./actionTypes";

export function* getFlowFromUser({ data }) {
  try {
    const resp = yield http.get(`/api/flows/delegations-from-user`, {
      ...BPM_MIDDLEWARE,
      params: data,
    });
    yield put(setUserFlow(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* postInsertDelegation({ data }) {
  try {
    const resp = yield http.post(
      `/api/activity-hierarchy/insert-delegation`,
      data,
      {
        ...BPM_MIDDLEWARE,
      }
    );
    yield put(setSuccess({ value: true, caller: "postInsertDelegation" }));
    yield put(setNotification(resp.data));
  } catch (error) {
    yield put(setSuccess({ value: false, caller: "postInsertDelegation" }));
  }
}

export function* getAprovalDelegation({ data }) {
  try {
    const resp = yield http.get(
      `/api/activity-hierarchy/find-delegation-by-user-and-pending`,
      { ...BPM_MIDDLEWARE, params: data }
    );
    yield put(setAprovalDelegacoes(resp.data));
  } catch (error) {
    yield put(setAprovalDelegacoes([]));
    reject(error);
  }
}

export function* aproveDelegation({ data }) {
  try {
    const resp = yield http.post(
      `/api/activity-hierarchy/aproval-delegation`,
      data,
      { ...BPM_MIDDLEWARE }
    );
    yield put(
      setSuccess({ value: resp.data == "Sucesso", caller: "aproveDelegation" })
    );
    yield put(setNotification(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getDelegationsFromUserToUser({ data }) {
  try {
    const resp = yield http.get(`/api/flows/delegations-from-user-to-user`, {
      ...BPM_MIDDLEWARE,
      params: data,
    });
    yield put(setUserFlow(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getDelegacoes({ data }) {
  try {
    const resp = yield http.get(
      `/api/activity-hierarchy/find-by-ownerid/${data.id}`,
      BPM_MIDDLEWARE
    );
    yield put(setDelegacoes(resp.data));
  } catch (error) {
    yield put(setDelegacoes([]));
  }
}

export function* getUsers({ data }) {
  try {
    const resp = yield http.get(`/api/users/get-users-to-delegate`, {
      ...BPM_MIDDLEWARE,
      params: data,
    });
    yield put(setUsers(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getOwners({ data }) {
  try {
    const resp = yield http.get(`/api/users/get-owners`, {
      ...BPM_MIDDLEWARE,
      params: data,
    });
    yield put(setOwners(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* deleteDelegacao({ data }) {
  try {
    const resp = yield http.post(
      `/api/activity-hierarchy/delete-delegation`,
      data,
      { ...BPM_MIDDLEWARE }
    );
    yield put(setSuccess({ value: true, caller: "deleteDelegacao" }));
    yield put(setNotification(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getDelegacoesByUserid({ data }) {
  try {
    const resp = yield http.get(
      `/api/activity-hierarchy/find-by-userid/${data.id}`,
      BPM_MIDDLEWARE
    );
    yield put(setDelegacoesByUserid(resp.data));
  } catch (error) {
    yield put(setDelegacoesByUserid([]));
  }
}

export function* getRequestedDelegation({ username, flowid }) {
  try {
    const resp = yield http.get(
      `/api/activity-hierarchy/requested-delegations/${username}/${flowid}`,
      BPM_MIDDLEWARE
    );
    yield put(setRequestedDelegation(resp.data));
  } catch (error) {
    yield put(setRequestedDelegation([]));
  }
}
