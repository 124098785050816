import qs from "qs";
import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { activeError } from "~/components/Messages/actions";
import { setDocument, setAllDocumentIflowBinary } from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
export function* findDocument({ docid }) {
  try {
    devLogConsole(docid);
    const resp = yield http.get(
      `/document/findById?docid=${docid}`,
      BPM_MIDDLEWARE
    );
    //devLogConsole(resp.data)
    yield put(setDocument(resp.data));
  } catch (e) {
    devLogConsole(e);
    yield put(setDocument(null));
    yield put(activeError("general.error"));
  }
}

export function* findAllDocumentIflowBinary({ docids, selected }) {
  try {
    const responses = {};
    
    for (const docid of docids) {
      const resp = yield http.get(
        `/document/findById?${qs.stringify({ docid })}`,
        BPM_MIDDLEWARE
      );
    
      // Encontrar o item correspondente no array selected
      const selectedItem = selected.find(item => item.file.id === docid);
    
      // Adicionar a informação do selected ao resp.data
      responses[docid] = {
        ...resp.data,
        selectedInfo: selectedItem
      };
    }
    
    yield put(setAllDocumentIflowBinary(responses));
  } catch (e) {
    yield put(setAllDocumentIflowBinary([]));
    yield put(activeError("general.error"));
  }
}
 