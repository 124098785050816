import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_tabWrapper.scss";
import Button from "./Button";
import ClientModalContent from "./ClientModalContent";

const TabWrapper = ({ children, variant, t }) => {

  const [activeTab, setActivetab] = useState(
    children.length <= 0
      ? ""
      : children[0][0]?.props?.children[0][0][0]?.props?.children?.props?.defaultValue
      ? (children[0][0]?.props?.children[0][0][0]?.props?.children?.props?.defaultValue)
      : children.length > 1
      ? children[0].props.name
      : children[0][0]?.props?.text
  );

  const [isModalShowing, setIsModalShowing] = useState(false);

  const buttonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "() => setIsModalShowing(true)",
    onmouseover: "center",
    align: "center",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='addRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeTab,
      setTheActiveTab: (name) => {
        setActivetab(name);
      },
      index,
    });
  });

  const getChildrenContent = () =>
    children[0].filter((child) => child.props.name === activeTab)[0]?.props
      ?.children;

  const getChildrenContentDocuments = () =>
    children.filter((child) => child.props.name === activeTab)[0]?.props
      ?.children;

  return (
    <>
      <div className="easypay-tab-wrapper">{childrenWithProps}</div>
      <ClientModalContent
        isModalShowing={isModalShowing}
        setIsModalShowing={setIsModalShowing}
        activeTab={t(activeTab)}
      />
      <div className="easypay-tab-button-content">
        <div>
          {children.length === 1
            ? getChildrenContent()
            : getChildrenContentDocuments()}
        </div>
        {((t(activeTab) ===
          t(`bpm.onboarding.backoffice.user.tabOrganization`) &&
          (variant === "all" || variant === "editNegocio")) ||
          (t(activeTab) === t(`bpm.onboarding.backoffice.user.tabBusiness`) &&
            (variant === "all" || variant === "editOrganizacao"))) && (
          <div>
            <Button
              text={t(`bpm.onboarding.backoffice.user.buttonChangeData`)}
              variant={"easypay-button-link"}
              onclick={eval(buttonField.onclick)}
              key={uuid()}
              id={buttonField.id}
              className="card-button"
              align={buttonField.align}
              disabledColored={false}
              disabled={false}
              field={buttonField}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(TabWrapper);
