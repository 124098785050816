import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Processes from "~/components/Processes";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";
import ProtectedPage from "~/components/security/ProtectedPage";
import Tasks from "~/components/Tasks";
import WidgetContainer from "~/components/WidgetContainer";
import ContentContainer from "~/containers/ContentContainer";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import {
  addItemsToMenu,
  getMenusByApplication,
} from "~/store/ducks/applicationMenu/actionTypes";
import { BPM_MIDDLEWARE, BUDGETMGR_APP } from "~/utils/constants";
import LiftworldPage from "../Liftworld/pages/LiftworldPage";
import AuxTablesPage from "../Liftworld/pages/AuxTablesPage";
import TablesPage from "../Anacom/pages/TablesPage";
import IntegrationColaborators from "../Liftworld/pages/IntegrationColaborators";
import Categories from "../Liftworld/pages/Categories";
import Departments from "../Liftworld/pages/Departments";
import Teams from "../Liftworld/pages/Teams";
import Partners from "../Liftworld/pages/Partners";
import Expenses from "../Liftworld/pages/Expenses";
import Collaborators from "../Liftworld/pages/Collaborators";
import ClientDirector from "../Liftworld/pages/ClientDirector";

const Budgetmgr = (props) => {
  const { isMongoOn } = props;
  const [budgetPermissions, setbudgetPermissions] = useState(false);
  const [workeplanSelected, setworkeplanSelected] = useState(null);
  const [workeplanactive, setworkeplanactive] = useState(true);
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      username && dispatch(getMenusByApplication(username, BUDGETMGR_APP));
    }
  }, [user, dispatch]);

  useEffect(() => {
    const processosMenu = find(menu, {
      name: `menu.${BUDGETMGR_APP}.processos`,
    });
    let items = [];
    let submenus;
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      submenus = extraConfiguration_.submenus
        ? extraConfiguration_.submenus
        : null;
      if (submenus) {
        Object.keys(submenus).forEach((key) => {
          //check if submenu has children
          let hasChildren = false;
          if (submenus[key].length == 0) hasChildren = false;
          else {
            submenus[key].every((flowid) => {
              const res = rawMenus.every((rm) => {
                //find flowid
                if (rm.url) {
                  let arr = rm.url.split("flowid=");
                  const flowid_ = arr[1];
                  if (flowid_ == flowid) {
                    hasChildren = true;
                    return false;
                  }
                  return true;
                }
                return true;
              });
              return res;
            });
          }
          if (hasChildren) {
            items.push({
              id: key,
              name: key,
              submenu: true,
              route: `${BPM_MIDDLEWARE.context}/processos/submenu/${key}`,
              menuParent: processosMenu ? processosMenu.id : null,
            });
          }
        });
      }
      const getProcessosMenuId = () =>
        processosMenu ? processosMenu.id : null;
      const getSubMenuParentId = (url) => {
        if (url) {
          let arr = url.split("flowid=");
          const flowId = arr[1];
          let id = getProcessosMenuId();
          Object.keys(submenus).forEach((key) => {
            if (submenus[key].some((i) => i == flowId)) {
              id = key;
            }
          });
          return id;
        } else return getProcessosMenuId();
      };
      rawMenus.forEach((item) => {
        items.push({
          name: item.label,
          url: item.url,
          route: `${BUDGETMGR_APP}/processos/form/${item.label}`,
          menuParent: submenus
            ? getSubMenuParentId(item.url)
            : getProcessosMenuId(),
        });
      });
      dispatch(addItemsToMenu(items));
    }
  }, [rawMenus, menu, dispatch]);

  useEffect(() => {
    if (
      user &&
      user.roles &&
      user.roles.some(
        (r) => r.label === "ADMIN" || r.label === "BUDGETMGROrgGroupAdminTables"
      )
    ) {
      return setbudgetPermissions(true);
    } else return setbudgetPermissions(false);
  }, [user]);

  return (
    <>
      <ApplicationMenu applicationName={BUDGETMGR_APP} showMenu={true} />
      <Switch>
        <Route path={`${path}/tarefas`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <ContentContainer>
              <Tasks app={"bpm"} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/test`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <TablesPage />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/workplan`}>
          <LiftworldPage />
        </Route>
        <Route path={`${path}/integrationcolaborators`}>
          <IntegrationColaborators isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/categories`}>
          <Categories isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/departments`}>
          <Departments isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/teams`}>
          <Teams isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/partners`}>
          <Partners isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/collaborators`}>
          <Collaborators isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/expenses`}>
          <Expenses isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/clientDirector`}>
          <ClientDirector isOrgGroupAdmin={budgetPermissions} />
        </Route>
        <Route path={`${path}/auxtables`}>
          <AuxTablesPage />
        </Route>
        <Route path={`${path}/processos/submenu`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <ProcessosFormHandler
              app={BPM_MIDDLEWARE.context}
              className="main-card-v2"
            />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/pesquisar`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <ContentContainer>
              <Processes app={BUDGETMGR_APP} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={path}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "BUDGETMGRUser",
              "BUDGETMGRJunior",
              "BUDGETMGROrgGroupAdmin",
            ]}
          >
            <>
              {isMongoOn && (
                <ContentContainer>
                  <WidgetContainer />
                </ContentContainer>
              )}
            </>
          </ProtectedPage>
        </Route>
      </Switch>
    </>
  );
};
export default Budgetmgr;
