import React from "react";
import { useDispatch } from "react-redux";
import { Badge } from "react-bootstrap";
import { redirectUserTo } from "~/store/ducks/portalrh/actionTypes";
import { useRouteMatch } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";

import {
  setGenericRequestState,
  setGenericAuthorizationState,
  setLoadingRequests,
} from "~/store/ducks/portalrh/actionTypes";
const devLogConsole = require("~/utils/devLog");
const RequestItem = (props) => {
  const dispatch = useDispatch();

  let { path } = useRouteMatch();
  const history = useHistory();

  const getAction = () => {
    for (var i = 0; i < Object.keys(props.allMenus).length; i++) {
      if (
        Object.values(props.allMenus)[i].designacao === props.description &&
        Object.values(props.allMenus)[i].nivel === 4 &&
        Object.values(props.allMenus)[i].accao.includes(props.type)
      ) {
        return Object.values(props.allMenus)[i].accao;
      }
    }
  };
  devLogConsole(props);
  const onCLickHandler = () => {
    let url = "";
    if (props.type === "aut_ac_") {
      switch (props.description) {
        case "euroticketCreche":
        case "euroticketEstudante":
        case "euroticketFlexivel":
        case "comprovativoPasseSocial":
        case "processing":
        case "offboarding":
        case "generic":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/generic");
          window.location.reload();
          break;
        // case "comprovativoPasseSocial":
        //   dispatch(setGenericAuthorizationState(props.state));
        //   dispatch(setLoadingRequests());
        //   localStorage.setItem(
        //     "authorizationState",
        //     JSON.stringify(props.state)
        //   );
        //   history.push(path + "/myteam/comprovativoPasseSocial");
        //   window.location.reload();
        //   break;
        case "expense":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/expense");
          window.location.reload();
          break;
        case "absenceRecord":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/absenceRecord");
          window.location.reload();
          break;
        case "extraTime":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/extraTime");
          window.location.reload();
          break;
        case "insertPresence":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/insertPresence");
          window.location.reload();
          break;
        case "pagamentosBolsa":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/pagamentosBolsa");
          window.location.reload();
          break;
        case "remoteWorkLideres":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/remoteWork");
          window.location.reload();
          break;
        case "remoteWorkDiretores":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/remoteWork");
          window.location.reload();
          break;
        case "vacationsManagement":
        case "ferias":
          dispatch(setGenericAuthorizationState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem(
            "authorizationState",
            JSON.stringify(props.state)
          );
          history.push(path + "/myteam/marcacaoFerias");
          window.location.reload();
          break;

        // case "processing":
        //   dispatch(setGenericAuthorizationState(props.state));
        //   dispatch(setLoadingRequests());
        //   localStorage.setItem(
        //     "authorizationState",
        //     JSON.stringify(props.state)
        //   );
        //   history.push(path + "/myteam/processing");
        //   window.location.reload();
        //   break;
        default:
          if (props?.flowId === 1) {
            history.push(path + "/myteam/generic");
            window.location.reload();
          } else {
            url = "personalarea/frame/Pedidos";
          }
      }
    } else if (props.type === "ped_ac_") {
      switch (props.description) {
        case "vacationsManagement":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/vacations");
          window.location.reload();
          break;
        case "paymentData":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          url = "personalarea/frame/Pedidos";
          //history.push(path + "/personalarea/requests/payment");

          break;
        case "euroticketCreche":
        case "euroticketEstudante":
        case "euroticketFlexivel":
        case "comprovativoPasseSocial":
        case "processing":
        case "offboarding":
        case "generic":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/generic");
          window.location.reload();
          break;
        // case "comprovativoPasseSocial":
        //   dispatch(setGenericRequestState(props.state));
        //   dispatch(setLoadingRequests());
        //   localStorage.setItem("requestState", JSON.stringify(props.state));
        //   history.push(path + "/personalarea/comprovativoPasseSocial");
        //   window.location.reload();
        //   break;
        case "expense":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/expense");
          window.location.reload();
          break;
        case "absenceRecord":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/absenceRecord");
          window.location.reload();
          break;
        case "extraTime":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/extraTime");
          window.location.reload();
          break;
        case "insertPresence":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/insertPresence");
          window.location.reload();
          break;
        case "pagamentosBolsa":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/pagamentosBolsa");
          window.location.reload();
          break;
        case "remoteWorkLideres":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/remoteWork");
          window.location.reload();
          break;
        case "remoteWorkDiretores":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/remoteWork");
          window.location.reload();
          break;
        case "ferias":
          dispatch(setGenericRequestState(props.state));
          dispatch(setLoadingRequests());
          localStorage.setItem("requestState", JSON.stringify(props.state));
          history.push(path + "/personalarea/marcacaoFerias");
          window.location.reload();
          break;
        // case "processing":
        //   dispatch(setGenericRequestState(props.state));
        //   dispatch(setLoadingRequests());
        //   localStorage.setItem("requestState", JSON.stringify(props.state));
        //   history.push(path + "/personalarea/processing");
        //   window.location.reload();
        //   break;
        default:
          if (props?.flowId === 1) {
            history.push(path + "/personalarea/generic");
            window.location.reload();
          } else {
            url = "personalarea/frame/Pedidos";
          }
      }
    }

    const object = {
      action: getAction(),
      url: url,
    };
    dispatch(redirectUserTo(JSON.stringify(object)));
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <a onClick={() => onCLickHandler()} className="text-secondary">
        {props.t("portalrh.requests.descriptions." + props.description) + " "}
        <Badge pill className={props.textColor}>
          {props.num == "0" ? "" : props.num}
        </Badge>
      </a>
      <br />
    </div>
  );
};

export default RequestItem;
