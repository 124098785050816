import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import infoIcon from "~/assets/img/icons/info-icon.png";

export const useLabelFormated = (field, translations, setCurrentInfo) => {
  const [labelFormated, setLabelFormated] = useState("");
  const [imageFormated, setImageFormated] = useState("");
  const [textFormated, setTextFormated] = useState("");
  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  let language = userSettings?.language ? userSettings.language : "pt";

  useEffect(() => {
    const processFieldText = (text) => {
      if (text.includes("::t::")) {
        const translatedText = translateText(text);
        setLabelFormated(translatedText);
      } else if (text.includes(":::")) {
        setLabelFormated(text.split("::")[0] + ":");
      } else if (text.includes("::i::")) {
        setLabelFormated(text.split("::i::")[0]);
        setImageFormated(
          <img
            style={{ cursor: "pointer" }}
            className="info-icon"
            src={infoIcon}
          ></img>
        );
        setTextFormated(text.split("::i::")[1]);
      } else if (text.includes("::")) {
        setLabelFormated(text.split("::")[0]);
      }
    };

    if (typeof field?.text === "string") {
      processFieldText(field.text);
      } else if (
      typeof field?.text === "object" &&
      Object.keys(field?.text).length > 0
    ) {
      Object.values(field?.text).forEach((key) => {
        processFieldText(field?.text[key]);
      });
    }
  }, [field]);

  const translateText = (textField) => {
    if (!textField.includes("::t::")) {
      return textField;
    }
    const lingua = language.trim();
    const cleanTextField = textField.replace(/'/g, "").trim(); 
    let splittext = undefined;

    if(textField.includes("::nDP::")){
      const removeNDP = cleanTextField.split("::nDP::");
      splittext = removeNDP[0].split("::t")
    } else {
      splittext = cleanTextField.split("::t::");
    }

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal;
  };

  return { labelFormated, imageFormated, textFormated, language };
};
