import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import {
  PORTALRH_MIDDLEWARE,
  RH_MIDDLEWARE,
  IFLOW_APP,
} from "~/utils/constants";

import {
  setUsageReport,
  setReportToExtract,
  setSuccessDownloadReportExtract,
} from "./actionTypes";

import { activeError } from "~/components/Messages/actions";

export function* findUsageReport() {
  try {
    const resp = yield http.get("/api/datacapture/reports/all");
    yield put(setUsageReport(resp.data));
  } catch (e) {
    yield put(setUsageReport([]));
    yield put(activeError(e));
  }
}

export function* buttonBackendCall({ method, url }) {
  try {
    const lowerCaseMethod = method.toLowerCase();
    const resp = yield http[lowerCaseMethod](url, {}, PORTALRH_MIDDLEWARE);
    yield put(setReportToExtract(resp.data));
  } catch (e) {
    yield put(setReportToExtract([]));
    yield put(activeError(e));
  }
}

export function* getReportToExtract({ payload, selectedItemKey, download }) {
  try {
    let resp;
    if (download) {
      resp = yield http.post(
        `/api/reports/${selectedItemKey}`,
        payload,
        PORTALRH_MIDDLEWARE
      );
      const byteCharacters = atob(resp.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const data = new Blob([byteArray]);

      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedItemKey}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      yield put(setSuccessDownloadReportExtract(true));
    } else {
      resp = yield http.put(
        `/api/reports/${selectedItemKey}`,
        payload,
        PORTALRH_MIDDLEWARE
      );
      yield put(setReportToExtract(resp.data));
    }
  } catch (e) {
    if (download) {
      yield put(setSuccessDownloadReportExtract(false));
    }
    yield put(setReportToExtract([]));
    yield put(activeError(e));
  }
}
