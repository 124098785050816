import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// Função auxiliar para converter data para o fuso horário de Lisboa e retornar em formato ISO
const toLocalISO = (dateString) => {
  try {
    // Cria um objeto Date diretamente a partir da entrada
    const date = new Date(dateString);
    // Calcula o deslocamento para o fuso horário de Lisboa
    const offset = date.getTimezoneOffset() * 60000; // Deslocamento em milissegundos
    const lisbonTime = new Date(date.getTime() - offset + 3600000 * 0); // Ajusta para o fuso horário de Lisboa
    return lisbonTime.toISOString().split("T")[0];
  } catch (error) {
    console.error("Erro ao converter data:", error);
    return null;
  }
};

// Função principal para converter datas, com validação de entrada
const convertDateToLocalISO = (date, type) => {
  // Verifica se a entrada é um objeto com a propriedade 'data' para o tipo 'feriados'
  if (type === "feriados" && date && date.data) {
    return toLocalISO(date.data);
  } else if (date) {
    // Para outros tipos, apenas verifica se a data foi fornecida
    return toLocalISO(date);
  } else {
    console.error("Data inválida fornecida.");
    return null; // Retorna null ou trata a entrada inválida conforme necessário
  }
};

const findMarcacao = (itemDate, marcacoes) => {
  const itemDateMoment = moment(itemDate);
  let selectedMarcacao = null;

  marcacoes.forEach((marc) => {
    const inicio = moment(marc.dataInicio || marc.FEM_DATA_INICIO);
    const fim = moment(marc.dataFim || marc.FEM_DATA_FIM);

    if (itemDateMoment.isBetween(inicio, fim, "day", "[]")) {
      selectedMarcacao = marc;
    }
  });

  return selectedMarcacao;
};

export const convertToEvents = (pedidoEvent) => {
  return pedidoEvent.data.map((item) => {
    const itemDate = convertDateToLocalISO(item, pedidoEvent.type);
    const startLocal = new Date(itemDate);
    startLocal.setHours(8, 0, 0, 0);
    const endLocal = new Date(itemDate);
    endLocal.setHours(20, 0, 0, 0);

    let marcacaoPedido =
      findMarcacao(itemDate, pedidoEvent.customProps?.marcacoes || []) ||
      findMarcacao(
        itemDate,
        Object.values(pedidoEvent.customProps?.pendentesMap || {})
      );

    return {
      id: uuidv4(),
      start: startLocal,
      end: endLocal,
      type: pedidoEvent.type,
      color: pedidoEvent.color,
      title: pedidoEvent.customProps?.title || item.obs,
      idToSelect: marcacaoPedido?.idpedido || marcacaoPedido?.pedId,
      marcacaoPedidoEvent: marcacaoPedido,
      dayToRemove: pedidoEvent.customProps?.dayToRemove || false,
      checked:
        pedidoEvent.type === "feriasmarked"
          ? pedidoEvent.customProps?.checked
          : null,
      checkedHalfDay:
        pedidoEvent.type === "feriasmarked"
          ? pedidoEvent.customProps?.checkedHalfDay
          : null,
      removeDay:
        pedidoEvent.type === "feriasmarked"
          ? pedidoEvent.customProps?.removeDay
          : null,
      multi: pedidoEvent.customProps?.multi || false,
    };
  });
};

export const pedidosEvents = (marcacaoFerias, t) => {
  return [
    {
      data: marcacaoFerias?.datasMarcacoes,
      color: "#4447A4",
      type: "datasMarcacoes",
      customProps: {
        title: t("portalrh.workDays.folgas"),
        marcacoes: marcacaoFerias?.marcacoes,
        dayToRemove: true,
      },
    },
    {
      data: marcacaoFerias?.feriados,
      color: "#D6D6D6",
      type: "feriados",
      customProps: {},
    },
    {
      data: marcacaoFerias?.feriasPendentes,
      color: "dimgrey",
      type: "feriasPendentes",
      customProps: {
        title: t("portalrh.workDays.feriasPendentes"),
        pendentesMap: marcacaoFerias?.feriasPendentesMap,
      },
    },
  ];
};

export const folgasEvents = (marcacaoFolgas, t) => {
  return [
    {
      data: marcacaoFolgas.folgas,
      color: "#4447A4",
      type: "folgas",
      customProps: {
        title: t("portalrh.workDays.folgas"),
      },
    },
  ];
};

export const marcacaoEvents = (data, t) => {
  return [
    {
      data: [data],
      color: "#44A49B",
      type: "feriasmarked",
      customProps: {
        title: t("portalrh.workDays.folgas"),
        checked: true,
        checkedHalfDay: false,
        removeDay: false,
        multi: true,
      },
    },
  ];
};
