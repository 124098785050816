import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import WidgetContainer from "~/components/WidgetContainer";
import ContentContainer from "~/containers/ContentContainer";
import { withNamespaces } from "react-i18next";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import { find } from "lodash";
import { BPM_MIDDLEWARE, PORTALRH_MIDDLEWARE, SYS_ADMIN } from "~/utils/constants";
import {
  addItemsToMenu,
  getMenusByApplication,
  updateReduxMenu,
} from "~/store/ducks/applicationMenu/actionTypes";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );
  const { menu: portalRhMenu } = useSelector(
    (state) => state.portalRhMenuReducer
  );
  const [portalRhMenus, setPortalRhMenus] = useState([]);

  const dashboardPages =
    Number.isInteger(configuration?.dashboard) && configuration?.dashboard != 0
      ? configuration.dashboard
      : 10;

  useEffect(() => {
    const defaultMenus = [
      {
        name: "menu.portalrh.personalArea",
        designacao: "Área Pessoal",
        pathPart: "/personalarea/frame/",
      },
      {
        name: "menu.portalrh.myTeam",
        designacao: "Minha Equipa",
        pathPart: "/myteam/frame/",
      },
      {
        name: "menu.portalrh.siadapra",
        designacao: "Siadapra",
        pathPart: "/siadapra/frame/",
      },
      {
        name: "menu.portalrh.subMenus.DesempenhoSiadapra",
        designacao: "DesempenhoSiadapra",
        pathPart: "/siadapra/frame/",
      },
      {
        name: "menu.portalrh.communication",
        designacao: "Comunicações",
        pathPart: "/communication/frame/",
      },
      {
        name: "menu.portalrh.administration",
        designacao: "Administração",
        pathPart: "/administration/frame/",
      },
      {
        name: "menu.portalrh.rhadmin",
        designacao: "RH Admin",
        pathPart: "/rhadmin/frame/",
      },
      /*OLD Menus
        {
          name: "menu.portalrh.start",
          designacao: "Início",
          pathPart: "/start/frame/"
        },
        {
          name: "menu.portalrh.configuration",
          designacao: "Configuração",
          pathPart: "/configuration/frame/"
        },
        {
          name: "menu.portalrh.receipts",
          designacao: "Disp. Recibos",
          pathPart: "/receipts/frame/"
        }*/
    ];

    const handleMenu = (name, designacao, pathPart) => {
      const foundMenu = find(menu, { name: name });
      const foundMenuRH = find(portalRhMenu, { designacao: designacao });
      if (foundMenuRH && foundMenuRH.children) {
        if (foundMenuRH.children.length > 0) {
          let items = [];
          const getMenuId = () => (foundMenu ? foundMenu.id : null);
          const getURL = (item) => {
            if (
              item &&
              item.accao !== null &&
              item.accao !== undefined &&
              item.accao !== ""
            ) {
              return item.accao;
            } else if (item && item.children) {
              const foundItem = item.children.find(
                (c) =>
                  c.accao !== null && c.accao !== undefined && c.accao !== ""
              );
              return foundItem && foundItem.accao;
            } else return null;
          };

          foundMenuRH.children.forEach((item) => {
            items.push({
              name: item.designacao,
              url: getURL(item),
              route:
                item.externo == 1
                  ? `${PORTALRH_MIDDLEWARE.context}/${item.accao}`
                  : `${PORTALRH_MIDDLEWARE.context}${pathPart}${item.designacao}`,
              menuParent: getMenuId(),
              nivel: item.nivel,
              ordem: item.ordem,
            });
          });
          dispatch(addItemsToMenu(items));
        }
      }
    };

    if (portalRhMenu && portalRhMenu.length > 0) {
      const sortedDefaultMenus = defaultMenus.filter((m) =>
        portalRhMenu.some((i) => i.designacao === m.designacao)
      );

      sortedDefaultMenus.forEach((m) => {
        handleMenu(m.name, m.designacao, m.pathPart);
      });

      /*select first item as a default for submenu lvl 2*/
      const addDefaultAction = (menus) => {
        return menus.map((m) => {
          if (m.id) {
            const children = menus.filter((i) => i.menuParent === m.id);
            if (children && children.length > 0) {
              if (
                /* m.route !== `portalrh/processos` &&
                  m.route !== `portalrh/processes`*/
                m.route &&
                !m.route.includes("portalrh/processes") &&
                !m.route.includes("portalrh/processos")
              ) {
                //TODO sort children
                m.url = children[0].url;
                m.route = children[0].route;
              }
              return m;
            }
          }
          return m;
        });
      };

      const menusToRemove = defaultMenus.filter(
        (m) => !portalRhMenu.some((i) => i.designacao === m.designacao)
      );
      let sortedMenus;
      if (menusToRemove && menusToRemove.length > 0) {
        sortedMenus = menu.filter(
          (m) => !menusToRemove.some((i) => i.name === m.name)
        );
        if (sortedMenus.length > 0) {
          addDefaultAction(sortedMenus);
          dispatch(updateReduxMenu(addDefaultAction(sortedMenus)));
        }
      } else {
        dispatch(updateReduxMenu(addDefaultAction(menu)));
      }
    }
    setPortalRhMenus(portalRhMenu);
  }, [portalRhMenu, menu, dispatch]);

  return (
    <>
      <ApplicationMenu showMenu={true} />
      <Switch>
        <Route exact path={`${path}`}>
          <div></div>
        </Route>
        {Array.from({ length: dashboardPages }).map((_, index) => (
          <Route key={index} exact path={`${path}/page/${index + 1}`}>
            <ContentContainer>
              <WidgetContainer />
            </ContentContainer>
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default withNamespaces()(Dashboard);
