import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "react-bootstrap";
import "./webTime.css";
import { getLoginsByUsername } from "~/store/ducks/webTimeWidget/actionTypes";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { withNamespaces } from "react-i18next";
const devLogConsole = require("~/utils/devLog");
const RequestWebTimeItem = (props, t) => {
  const dispatch = useDispatch();
  const [isCLicked, setIsClicked] = useState(false);

  const { user } = useSelector((state) => state.globalReducer);
  const { logins, isLoadingLogin } = useSelector(
    (state) => state.webTimeDataReducer
  );

  const usernamePortalRh = user?.legacyUsers?.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;

  useEffect(() => {
    if (!isLoadingLogin && logins && isCLicked) {
      setIsClicked(false);

      window.open(logins, "_blank", "noopener,noreferrer");
    }
  }, [isLoadingLogin]);

  const openNewTab = () => {
    if (props.userType === "func") {
      switch (props.description) {
        case "Gestão de Férias":
        case "Vacation Management":
          dispatch(
            getLoginsByUsername(usernamePortalRh, true, "holidays", "0")
          );
          setIsClicked(true);

          break;

        case "Gestão de Ausências":
        case "Absence Management":
          dispatch(
            getLoginsByUsername(usernamePortalRh, true, "absences", "0")
          );
          setIsClicked(true);
          break;
        default:
          devLogConsole("DEFAULT");
      }
    }
    if (props.userType === "admin") {
      switch (props.description) {
        case "Gestão de Férias":
        case "Vacation Management":
          dispatch(
            getLoginsByUsername(usernamePortalRh, true, "holidays", "1")
          );
          setIsClicked(true);

          break;

        case "Gestão de Ausências":
        case "Absence Management":
          dispatch(
            getLoginsByUsername(usernamePortalRh, true, "absences", "1")
          );
          setIsClicked(true);
          break;
        default:
          devLogConsole("DEFAULT");
      }
    }
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="webTime__Link"
      onClick={() => openNewTab()}
    >
      {props.description + " "}
      <Badge pill className={props.textColor}>
        {props.num == "0" ? "" : props.num}
      </Badge>
      {/* </a> */}
      <br />
    </div>
  );
};

export default withNamespaces()(RequestWebTimeItem);
