import React from "react";
import { Button } from "react-bootstrap";
import { IoCloudUploadOutline } from "react-icons/io5";

const UploadButton = ({ disabled, text, onClick, type, onChange }) => {
  return (
    <Button
      type={type ? type : "button"}
      disabled={disabled}
      variant="outline-primary"
      onClick={type !== "file" ? onClick : null}
      className="custom-button"
      onChange={onChange}
    >
      {text}
      <IoCloudUploadOutline
        style={{ fontSize: "x-large", marginLeft: "10px" }}
      />
    </Button>
  );
};

export default UploadButton;
