import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import AFileUpload from "./AFileUpload";
import { clearSavedBatch } from "~/pages/DataCapture/actions";

const Document = ({ docType, experiencedUser, show, datacaptureConfiguration, handleCloseUploadModal, docTypeExtraConfiguration }) => {

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearSavedBatch());//do not remove!
    }
  }, [dispatch]);

  return (
    <AFileUpload
      docType={docType}
      experiencedUser={experiencedUser}
      show={show}
      datacaptureConfiguration={datacaptureConfiguration}
      handleCloseUploadModal={handleCloseUploadModal}
      docTypeExtraConfiguration={docTypeExtraConfiguration}
    />
  );
};

export default Document;
