import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Image } from "react-bootstrap";
import logo from "../../assets/img/logos/uniksystem.svg";
import "../../assets/css/icons.css";
import CustomDropdown from "./CustomDropdown";
import { withNamespaces } from "react-i18next";
import { updateUserWidget } from "~/pages/User/actions";

const image = {
  width: "25rem",
  padding: "15px",
};

const DefaultWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("general.underConstruction")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ overflowY: "scroll" }}>
            <div className="text-center">
              <Image src={logo} rounded style={image} />
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              augue nibh, congue ac semper eget, dictum nec velit. Nam ac
              sagittis diam, ut aliquet ligula.
            </p>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(DefaultWidget);
