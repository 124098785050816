import React, { useEffect, useRef, useState } from "react";

export function Accordion({
  title = "Accordion Title",
  show = false,
  children,
  icon,
  isMenuVisible = false,
}) {
  const accordionBodyRef = useRef(null);
  const [collapse, setCollapse] = useState(show);
  const [showIcon, setShowIcon] = useState(icon);

  const randomId = useRef(
    window.crypto.getRandomValues(new Uint32Array(1))[0].toString(36)
  );

  useEffect(() => {
    setCollapse(show);
  }, [show]);

  const toggleCollapse = () => {
    setCollapse(!collapse);
    setShowIcon(!showIcon);
  };
  return (
    <div className="accordion-item">
      <div id={`heading-${randomId.current}`}>
        <button
          className={`accordion-button${
            collapse ? "" : " collapsed"
          } menu-item-wrap-lvl2`}
          type="button"
          aria-expanded={collapse}
          aria-controls={`collapse-${randomId.current}`}
          onClick={toggleCollapse}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          {title}
          {showIcon ? <i className="icon-up" /> : <i className="icon-down" />}
        </button>
      </div>

      <div
        id={`collapse-${randomId.current}`}
        aria-labelledby={`heading-${randomId.current}`}
        className={`accordion-collapse`}
        style={
          collapse
            ? {
                height: isMenuVisible
                  ? "auto"
                  : accordionBodyRef.current?.clientHeight,
                transition: "height 0.2s ease",
                overflow: "hidden",
              }
            : {
                height: 0,
                transition: "height 0.2s ease",
                overflow: "hidden",
              }
        }
      >
        <div className="accordion-body" ref={accordionBodyRef}>
          {children ||
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem illum odit similique quibusdam ea eaque pariatur laboriosam repellendus voluptas, aspernatur in id tenetur eligendi nobis quam saepe cumque enim esse."}
        </div>
      </div>
    </div>
  );
}
