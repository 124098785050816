import { takeLatest } from "redux-saga/effects";

import {
  GET_RHADMIN_CONFIGURATION,
  PERSIST_RHADMIN_CONFIGURATION,
  GET_RH_BOLSA_HORAS_CONFIGURATION,
  PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
  GET_RH_BOLSA_HORAS_USERS_GROUP,
  GET_RH_SALDO_BOLSA_HORAS,
  GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
  GET_OPENAI_CONFIGURATION,
  PERSIST_OPENAI_CONFIGURATION,
  DELETE_OPENAI_CONFIGURATION,
  GET_RHCURRENCY_CONFIGURATION,
  PERSIST_RHCURRENCY_CONFIGURATION,
  GET_DISP_RECIBOS,
  GET_ALLOW_DIS_RECIBOS
} from "../ducks/rhadminConfiguration/actionTypes";

import {
  getRHAdminConfiguration,
  persistRHAdminConfiguration,
  getRHBolsaHorasConfiguration,
  persistRHBolsaHorasConfiguration,
  getRHBolsaHorasUsersGroup,
  getRHSaldoBolsaHoras,
  getRHSaldoInicialFinalBolsaHoras,
  getOpenAIConfiguration,
  persistOpenAIConfiguration,
  deleteOpenAiConfiguration,
  getRHCurrencyConfiguration,
  persistRHCurrencyConfiguration,
  getDispRecibos,
  getAllowDisRecibos
} from "../ducks/rhadminConfiguration/sagas";

export const rhadminConfigurationSagas = [
  takeLatest(GET_RHADMIN_CONFIGURATION, getRHAdminConfiguration),
  takeLatest(PERSIST_RHADMIN_CONFIGURATION, persistRHAdminConfiguration),
  //BOLSA DE HORAS
  takeLatest(GET_RH_BOLSA_HORAS_CONFIGURATION, getRHBolsaHorasConfiguration),
  takeLatest(GET_RH_BOLSA_HORAS_USERS_GROUP, getRHBolsaHorasUsersGroup),
  takeLatest(GET_RH_SALDO_BOLSA_HORAS, getRHSaldoBolsaHoras),
  takeLatest(
    GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
    getRHSaldoInicialFinalBolsaHoras
  ),
  takeLatest(
    PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
    persistRHBolsaHorasConfiguration
  ),
  takeLatest(GET_OPENAI_CONFIGURATION, getOpenAIConfiguration),
  takeLatest(PERSIST_OPENAI_CONFIGURATION, persistOpenAIConfiguration),
  takeLatest(DELETE_OPENAI_CONFIGURATION, deleteOpenAiConfiguration),
  takeLatest(GET_RHCURRENCY_CONFIGURATION, getRHCurrencyConfiguration),
  takeLatest(PERSIST_RHCURRENCY_CONFIGURATION, persistRHCurrencyConfiguration),
  takeLatest(GET_DISP_RECIBOS, getDispRecibos),
  takeLatest(GET_ALLOW_DIS_RECIBOS, getAllowDisRecibos)
];
