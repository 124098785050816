import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

const CreateTableRadioInput = (props) => {
  const { field } = props;
  const [inputValue, setInputValue] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    if (field) {
      if (field.checked && field.checked === "true") {
        const component = myRef.current;
        if (component) component.checked = true;
      }
      if (field.value) setInputValue(field.value);
    }
  }, [field]);

  return (
    <Form.Group
      controlId={`formRadio-${field.variable}`}
      key={field.variable}
      style={{ marginBottom: "0px", paddingTop: "4px" }}
    >
      {field.disabled === "true" || field.readonly === "true" ? (
        <input
          ref={myRef}
          type="radio"
          name={field.name}
          value={inputValue}
          style={{ width: "100%", paddingTop: "0px", paddingBottom: "0px" }}
          disabled
        />
      ) : (
        <input
          ref={myRef}
          type="radio"
          name={field.name}
          value={inputValue}
          style={{ width: "100%", paddingTop: "0px", paddingBottom: "0px" }}
          onChange={(e) => {}}
        />
      )}
    </Form.Group>
  );
};

export default CreateTableRadioInput;
