import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from "react-redux";
import SystemSettings from './SystemSettings';
import WidgetSettings from './WidgetSettings';
import Error404 from '~/components/Exception/Error404';

import UnikUser from '~/pages/Admin/User';
import PersistUnikUser from '~/pages/Admin/User/PersistUnikUser';

import Role from "~/pages/Admin/Role"
import PersistRole from "~/pages/Admin/Role/PersistRole"

import Report from '~/pages/Admin/Report';
import Permission from '~/pages/Admin/Permission';
import PersistPermission from '~/pages/Admin/Permission/PersistPermission';
import PersistOrganization from '~/pages/Admin/Organizations/components/PersistOrganization';
import OrganizationSettings from '~/pages/Admin/Organizations/components/OrganizationSettings'

//import ApplicationMenu from '~/containers/Menus/components/ApplicationMenu';
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import { SYS_ADMIN } from "~/utils/constants";
import ContentContainer from '~/containers/ContentContainer';

import Organization from '~/pages/Admin/Organizations';

const Admin = () => {

  let { path } = useRouteMatch();
  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (document.getElementById("globalsolver"))
      document.getElementById("globalsolver").style.display = "none";
  })

  return (
    <>
      <ApplicationMenu applicationName={SYS_ADMIN} />

        <Switch>
            
          <Route path={`${path}/settings`}> <ContentContainer><SystemSettings /></ContentContainer> </Route>
          <Route path={`${path}/widgetsettings`}> <ContentContainer><WidgetSettings /></ContentContainer></Route>

          <Route path={`${path}/unikuser`}><ContentContainer><UnikUser /></ContentContainer></Route>
          <Route path={`${path}/new-unikuser`} component={PersistUnikUser} />
          <Route path={`${path}/edit-unikuser/:id`} component={PersistUnikUser} />

          <Route path={`${path}/role`} exact><ContentContainer><Role /></ContentContainer></Route>
          <Route path={`${path}/role/create`} exact component={PersistRole} />
          <Route path={`${path}/role/update/:id`} exact component={PersistRole} />

          <Route path={`${path}/permission`} exact> <ContentContainer><Permission /></ContentContainer> </Route>
          <Route path={`${path}/permission/create`} exact component={PersistPermission} />
          <Route path={`${path}/permission/update/:id`} exact component={PersistPermission} />

          <Route path={`${path}/organization`} exact> <ContentContainer><Organization /></ContentContainer></Route>
          <Route path={`${path}/organization/create`} exact component={PersistOrganization} />
          <Route path={`${path}/organization/update/:id`} exact component={PersistOrganization} />

          <Route path={`${path}/orgadmin/orgsettings`} exact> <ContentContainer><OrganizationSettings /></ContentContainer></Route>

          <Route path={`${path}/report`} exact> <ContentContainer><Report /></ContentContainer> </Route>

          <Route path={path} exact={true}> <ContentContainer><div></div> </ContentContainer> </Route>
          <Route path={`${path}/*`}> <Error404 /></Route>

        </Switch>
    </>
  )
}
export default Admin

