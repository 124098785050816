import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_card.scss";
import "../assets/scss/_radioButtonWrapper.scss";

const RadioButtonWrapper = ({ variant, children, activeTabName }) => {
  const [activeTab, setActivetab] = useState(
    activeTabName ? activeTabName : children.length > 0 ? children[0][0]?.props?.text : ""
  );
  
  
  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeTab,
      child,
      index,
      setTheActiveTab: (name) => {
        setActivetab(name);
      },
      index,
    });
  });

  return (
    <div className="easypay-vertical-radio-button-wrapper">
      <div className={`easypay-vertical-radio-button-padding${variant}`}>
        {childrenWithProps}
      </div>
    </div>
  );
};

export default withNamespaces()(RadioButtonWrapper);
