import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import widgetplaceholder from '../../assets/img/widgets/widget-placeholder.jpg';
import '../../assets/css/styles.css';
import '../../assets/css/icons.css';
import { withNamespaces } from 'react-i18next';

const BuyModal = (props) => {

  const {
    t,
    selectedApp,
    show,
    handleClose
  } = props;

  return (
    <Modal show={show} onHide={handleClose}  centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('puzzle.buy')}  {selectedApp && selectedApp.application.description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <img className="rounded img-fluid float-left" src={widgetplaceholder} alt="Widget" />
          </div>
          <div className="col-6">
            <small className="text-justify">{selectedApp && selectedApp.buytext}
              <b> Regulamento Geral da Proteção de Dados</b>.
                         </small>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <a href="https://www.uniksystem.pt/protecao-de-dados-rgpd/" target="_blank" className="float-right m-2">Saber mais!</a>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancelar
   </Button>
        <a href="https://www.uniksystem.pt/protecao-de-dados-rgpd/#contacts" target="_blank">
          <Button variant="success" onClick={handleClose}>
            Comprar
     </Button>
        </a>
      </Modal.Footer>
    </Modal>
  )
}

export default withNamespaces()(BuyModal)

