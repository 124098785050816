import qs from 'qs'
import { put } from 'redux-saga/effects';
import http from '~/utils/config/http';

import { doneCheckToken } from './actionTypes';

import { successLogin } from '~/pages/Login/actions';

import oauth from '~/utils/oauth';

export function* checkToken() {
  const token = { token: oauth.getAccessToken() };
  try {
    yield http.get(`/oauth/check_token?${qs.stringify(token)}`);
    yield put(successLogin());
    yield put(doneCheckToken(true));
  } catch (e) {
    yield put(doneCheckToken(false));
  }
}
