import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import Text from "~/pages/Easypay/components/Text";
import { useSelector, useDispatch } from "react-redux";
import ButtonUpload from "~/pages/Easypay/components/ButtonUpload";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import uuid from "uuid/v1";
import Modal from "~/pages/Easypay/components/Modal";
import {
  createDocumentCoresystem,
  addDocumentCoresystem,
  getDocumentCoresystem,
  setDocumentCoresystem,
} from "~/store/ducks/onboarding/actionTypes";

import { setCreateDocumentCoresystem } from "~/store/ducks/onboarding/actionTypes";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

/**
 * Sms OTP
 *
 * This service tests an API from CoreSystem
 *
 * It allows to create, add document and get document.
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();
  const { isLoadingSendSmsOTP } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const {
    createDocumentCoresystemId,
    addDocumentCoresystemDone,
    documentCoresystem,
  } = useSelector((state) => state.onboardingReducer);

  const [file, setFile] = useState(null);
  const [currentBase64, setCurrentBase64] = useState();
  const [isModalShowing, setIsModalErrorShowing] = useState(false);
  const [output, setOutput] = useState("");

  useEffect(() => {
    if (
      createDocumentCoresystemId != null &&
      file != null &&
      file != undefined
    ) {
      const type = file.type ? file.type : "NOT SUPPORTED";
      setOutput(JSON.stringify(createDocumentCoresystemId, null, 2));
      dispatch(
        addDocumentCoresystem(
          {
            id: createDocumentCoresystemId.id,
            fileName: file.name,
            applicationType: type,
            content: currentBase64,
          },
          "61798db3a0ac1d6257017e87"
        )
      );
      dispatch(setCreateDocumentCoresystem(null));
    }
  }, [createDocumentCoresystemId]);

  const { user } = useSelector((state) => state.globalReducer);
  const inputFile = useRef(null);

  const [validated, setValidated] = useState(false);
  const [id, setId] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testeValidateNif();
    }
  };

  function onButtonClick(index) {
    // `current` points to the mounted file input element
    //testIndex.current = index;
    inputFile.current.click();
  }

  function getBase64(file) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setCurrentBase64(base64String);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];
    setFile(currentFile);
  };

  const testeValidateNif = () => {
    /* const payload = {
      phone: nif,
      message,
      username: user,
      password,
    };
    dispatch(sendSmsOTP(payload)); */
  };

  function handleRemoveFile() {
    setFile(null);
  }

  function addToCoresystem() {
    const type = file.type ? file.type : "NOT SUPPORTED";

    if (
      !(
        type.includes("pdf") ||
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      )
    ) {
      setIsModalErrorShowing(true);
      return;
    }

    const emitted_at = file.lastModifiedDate;
    const expires_at = new Date(
      new Date(file.lastModifiedDate).setFullYear(new Date().getFullYear() + 1)
    );

    const extension = "." + file.name.split(".")[1];
    var newExpires = expires_at.toISOString().split(".")[0] + "Z";
    var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";

    dispatch(
      createDocumentCoresystem(
        {
          topology: "identification_card",
          is_private: true,
          metadata: `{\n  \"topology\": \"identification_card\",\n  \"is_private\": true,\n  \"metadata\": {},\n  \"emitted_at\": \"${newEmitted}\",\n  \"expires_at\": \"${newExpires}\",\n  \"mime_type\": \"${type}\",\n  \"extension\": \"${extension}\"\n}`,
          emitted_at: newEmitted,
          expires_at: newExpires,
          mime_type: type,
          extension: extension,
        },
        "61798db3a0ac1d6257017e87"
      )
    );
    getBase64(file);
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        Document type not supported. Document must be type pdf, dng, jpg, jpeg.
      </div>
    );
  }

  function getFromCoresystem() {
    dispatch(getDocumentCoresystem(id, "61798db3a0ac1d6257017e87"));
  }

  function isBase64UrlImage(base64String) {
    let image = new Image();
    image.src = base64String;
    image.onload = function () {
      if (image.height === 0 || image.width === 0) {
        return;
      }
    };
    image.onerror = () => {
    };
  }

  useEffect(() => {
    if (documentCoresystem != null && documentCoresystem != undefined) {
      const a = document.createElement("a");
      //const newFile = dataURLtoFile(documentCoresystem,"teste")
      //console.log(newFile)
      isBase64UrlImage(documentCoresystem);
      a.href = `data:${"pdf"};base64,${documentCoresystem}`;
      a.download = "test.pdf";
      a.click();
      dispatch(setDocumentCoresystem(null));
    }
  }, [documentCoresystem]);

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        {file?.name ? (
          <div style={{ maxWidth: "250px", display: "flex" }}>
            <div className="easypay-text-icon">
              <img src={iconDocument} alt="Icon Check" />
            </div>
            <Text text={file?.name + "::card-text-blue"} />
            <div className="easypay-x-icon" onClick={() => handleRemoveFile()}>
              X
            </div>
          </div>
        ) : (
          <div style={{ paddingBottom: "15px" }}>
            {" "}
            <ButtonUpload
              text={t(`bpm.onboarding.backoffice.user.buttonUploadDocument`)}
              variant={"easypay-button-secondary-icon"}
              onclick={() => {
                onButtonClick();
              }}
              onChange={handleChange}
              key={uuid()}
              inputFile={inputFile}
              id={"id1"}
              className="card-button"
              align={"left"}
              disabledColored={false}
              disabled={false}
            />
          </div>
        )}
        <Modal
          className="easypay-modal"
          showModal={isModalShowing}
          setShowModal={setIsModalErrorShowing}
          headerContent={"Document Type Error"}
          bodyContent={createBody()}
          key={uuid()}
        ></Modal>
        <Button
          disabled={
            !user?.organization?.id ||
            Object.keys(apiSettings).length === 0 ||
            file === null
          }
          className="card-button onboarding-button"
          onClick={() => addToCoresystem()}
        >
          {isLoadingSendSmsOTP ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            "Test upload"
          )}
        </Button>
        <div style={{ paddingTop: "15px" }} />
        <Form.Label>{"Coresystem Doc Id"}</Form.Label>
        <Form.Control
          autoComplete="off"
          required
          value={id}
          onChange={(e) => {
            setId(e.target.value);
          }}
        />
        <div style={{ paddingTop: "15px" }} />
        <Form.Row>
          <Button
            disabled={
              !user?.organization?.id ||
              Object.keys(apiSettings).length === 0 ||
              !id
            }
            className="card-button onboarding-button"
            onClick={() => getFromCoresystem()}
          >
            {isLoadingSendSmsOTP ? (
              <>
                {t("onboarding.general.loading")}
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Test download"
            )}
          </Button>
        </Form.Row>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
