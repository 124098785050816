import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/css/styles.css";
import "../assets/scss/_backOfficeAccountSection.scss";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import Input from "./Input";
import Dropdown from "./InputDropdown";
import Link from "./Link";
import RadioButtonItem from "./RadioButtonItem";
const devLogConsole = require("~/utils/devLog");
const BackOfficeAccountSection = ({ t, data }) => {
  const organizationTypeList = [
    { label: "Fundação", value: "Fundação", selected: true },
    { label: "Companhia", value: "Companhia", selected: false },
    { label: "Empresa", value: "Empresa", selected: false },
  ];

  function handleSelectChange(e) {
    //devLogConsole(e);
  }

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  useEffect(() => {
    data?.onboardingEntity?.tipoEntidade &&
      setActiveAccountType(data?.onboardingEntity?.tipoEntidade);
  }, [data]);

  useEffect(() => {
    if(data != null && data != undefined)
    {
      if(data.onboardingEntity != null && data.onboardingEntity != undefined)
      {
        data.onboardingEntity.tipoEntidade = activeAccountType
      }
    }
  });

  const capitalizeText = (cell) =>
    cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : "";

  const [activeAccountType, setActiveAccountType] = useState("");

  return (
    <div className="easypay-backoffice-account-sections-wrapper">
      <div className="easypay-backoffice-registry-section-header">
        {t("bpm.onboarding.backoffice.administration.accountButton")}
      </div>
      <div className="easypay-backoffice-account-section-account-type-wrapper">
        <div className="easypay-backoffice-account-type-header">
          {t("bpm.onboarding.backoffice.administration.headerAccountType")}
        </div>
        <div className="easypay-backoffice-account-type-text">
          {capitalizeText(data?.onboardingEntity?.tipoEntidade)}
        </div>
      </div>
      <div className="easypay-backoffice-account-section-host-country-wrapper">
        <div className="easypay-backoffice-host-country-header">
          {t("bpm.onboarding.backoffice.administration.headerHostCountry")}
        </div>
        <div className="easypay-backoffice-host-country-text">
          {data?.entity?.pais}
        </div>
      </div>
      <Link
        text={t("bpm.onboarding.backoffice.administration.linkChange")}
        link="https://unikint.uniksystem.pt/#/"
      ></Link>
      <div className="easypay-backoffice-account-section-entity-type-header">
        {t("bpm.onboarding.backoffice.administration.headerEntityType")}
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={activeAccountType}
        setActivetab={setActiveAccountType}
      >
        <RadioButtonItem
          key={uuid()}
          text={t("bpm.onboarding.backoffice.administration.ENIHeader")}
          uniqueName={"backofficeRadioEntityType"}
          index={0}
          name="eni"
          subHeader={
            t("bpm.onboarding.backoffice.administration.subHeaderENI") +
            "::card-text-light"
          }
        />
        <RadioButtonItem
          key={uuid()}
          text={t("bpm.onboarding.backoffice.administration.companyHeader")}
          uniqueName={"backofficeRadioEntityType"}
          index={1}
          name="empresas"
          subHeader={
            t("bpm.onboarding.backoffice.administration.companySubHeader") +
            "::card-text-light"
          }
        />
        <RadioButtonItem
          key={uuid()}
          text={t("bpm.onboarding.backoffice.administration.otherHeader")}
          index={2}
          uniqueName={"backofficeRadioEntityType"}
          name="outro"
          subHeader={
            t("bpm.onboarding.backoffice.administration.otherSubHeader") +
            "::card-text-light"
          }
        />
      </BenefModalRadioButtonWrapper>
      <input
        id="hidden-radio-button-input"
        type="hidden"
        value={activeAccountType}
        name="dados"
      />
      {activeAccountType !== "eni" && (
        <>
          <div className="easypay-backoffice-account-section-entity-data-header">
            {t("bpm.onboarding.backoffice.administration.entityDataHeader")}
          </div>
          <div className="easypay-backoffice-account-section-cod-validity-wrapper">
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t(
                    "bpm.onboarding.backoffice.administration.codCertidaoHeader"
                  ) + "::disabled",
                  data?.onboardingEntity?.cprc
                )}
                name="input-cprc"
              />
            </div>
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.validityHeader") +
                    "::disabled",
                  dateFormatter(data?.onboardingEntity?.dataValidade)
                )}
                name="input-validaty"
              />
            </div>
          </div>
        </>
      )}
      {activeAccountType === "outro" && (
        <>
          <div className="easypay-backoffice-account-section-organization-nipc-cae-name-wrapper">
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Dropdown
                text={t(
                  "bpm.onboarding.backoffice.administration.organizationTypeHeader"
                )}
                required={true}
                placeholder="N/a"
                list={organizationTypeList}
                handleSubmit={(e) => handleSelectChange(e)}
              />
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerNIPC") +
                    "::disabled",
                  data?.onboardingEntity?.nipc
                )}
                name="dados"
              />
            </div>
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerCAE") +
                    "::disabled",
                  data?.onboardingEntity?.cae
                )}
                name="dados"
              />
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerName"),
                  data?.onboardingEntity?.nomeEntidade
                )}
                name="dados"
              />
            </div>
          </div>
          <div className="easypay-backoffice-account-section-entity-data-header">
            {t("bpm.onboarding.backoffice.administration.headerAddress")}
          </div>
          <div className="easypay-backoffice-account-section-zipcode-locality-wrapper">
            <div className="easypay-backoffice-account-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerZipCode"),
                  data?.onboardingEntity?.codPostal
                )}
                name="dados"
              />
            </div>
            <div className="easypay-backoffice-account-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerLocality"),
                  data?.onboardingEntity?.localidade
                )}
                name="dados"
              />
            </div>
          </div>
          <div className="easypay-backoffice-account-section-addresses-wrapper">
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerAddress-1"),
                  data?.onboardingEntity?.moradaSede
                )}
                name="dados"
              />
            </div>
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.headerAddress-2"),
                  data?.onboardingEntity?.moradaSede
                )}
                name="dados"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default withNamespaces()(BackOfficeAccountSection);
