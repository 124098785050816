import { takeLatest } from "redux-saga/effects";

import {
  //INTEGRATION COLLABORATORS
  GET_CRUD_COLLABORATORS,
  POST_ADD_CRUD_COLLABORATORS,
  //CATEGORIES
  GET_CRUD_CATEGORIES,
  POST_ADD_CRUD_CATEGORIES,
  POST_DELETE_CRUD_CATEGORIES,
  PUT_UPDATE_CRUD_CATEGORIES,
  //COMPANY
  GET_CRUD_COMPANIES,
  //DEPARTMENT
  GET_CRUD_DEPARTMENT,
  POST_ADD_CRUD_DEPARTMENT,
  POST_DELETE_CRUD_DEPARTMENT,
  PUT_UPDATE_CRUD_DEPARTMENT,
  //TEAMS
  GET_CRUD_TEAM,
  POST_ADD_CRUD_TEAM,
  POST_DELETE_CRUD_TEAM,
  PUT_UPDATE_CRUD_TEAM,
  //PARTNERS
  GET_CRUD_PARTNER,
  POST_ADD_CRUD_PARTNER,
  POST_DELETE_CRUD_PARTNER,
  PUT_UPDATE_CRUD_PARTNER,
  //EXPENSES
  GET_CRUD_EXPENSE,
  POST_ADD_CRUD_EXPENSE,
  POST_DELETE_CRUD_EXPENSE,
  PUT_UPDATE_CRUD_EXPENSE,
  //CLIENT DIRECTORS
  GET_CRUD_CLIENTDIRECTOR,
  POST_ADD_CRUD_CLIENTDIRECTOR,
  POST_DELETE_CRUD_CLIENTDIRECTOR,
  PUT_UPDATE_CRUD_CLIENTDIRECTOR,
  //COLLABORATORS
  GET_CRUD_COLLAB,
  POST_ADD_CRUD_COLLAB,
  POST_DELETE_CRUD_COLLAB,
  PUT_UPDATE_CRUD_COLLAB,
} from "../ducks/budgetManagementCRUD/actionTypes";

import {
  //INTEGRATION COLLABORATORS
  getCRUDCollaborators,
  postADDCRUDCollaborators,
  //CATEGORIES
  getCRUDCategories,
  postADDCRUDCategories,
  deleteCRUDCategorie,
  updateCRUDCategorie,
  //COMPANY
  getCRUDCompanies,
  //DEPARTMENT
  getCRUDDepartments,
  postADDCRUDDepartments,
  deleteCRUDDepartments,
  updateCRUDDepartments,
  //TEAMS
  getCRUDTeams,
  postADDCRUDTeams,
  deleteCRUDTeams,
  updateCRUDTeams,
  //PARTNERS
  getCRUDPartners,
  postADDCRUDPartners,
  deleteCRUDPartners,
  updateCRUDPartners,
  //EXPENSES
  getCRUDExpenses,
  postADDCRUDExpenses,
  deleteCRUDExpenses,
  updateCRUDExpenses,
  //CLIENT DIRECTORS
  getCRUDClientDirectors,
  postADDCRUDClientDirectors,
  deleteCRUDClientDirectors,
  updateCRUDClientDirectors,
  //COLLABORATORS
  getCRUDCollabs,
  postADDCRUDCollabs,
  deleteCRUDCollabs,
  updateCRUDCollabs,
} from "../ducks/budgetManagementCRUD/sagas";

export const budgetManagementCRUDSagas = [
  //INTEGRATION COLLABORATORS
  takeLatest(GET_CRUD_COLLABORATORS, getCRUDCollaborators),
  takeLatest(POST_ADD_CRUD_COLLABORATORS, postADDCRUDCollaborators),
  //CATEGORIES
  takeLatest(GET_CRUD_CATEGORIES, getCRUDCategories),
  takeLatest(POST_ADD_CRUD_CATEGORIES, postADDCRUDCategories),
  takeLatest(POST_DELETE_CRUD_CATEGORIES, deleteCRUDCategorie),
  takeLatest(PUT_UPDATE_CRUD_CATEGORIES, updateCRUDCategorie),
  //COMAPNY
  takeLatest(GET_CRUD_COMPANIES, getCRUDCompanies),
  //DEPARTMENT
  takeLatest(GET_CRUD_DEPARTMENT, getCRUDDepartments),
  takeLatest(POST_ADD_CRUD_DEPARTMENT, postADDCRUDDepartments),
  takeLatest(POST_DELETE_CRUD_DEPARTMENT, deleteCRUDDepartments),
  takeLatest(PUT_UPDATE_CRUD_DEPARTMENT, updateCRUDDepartments),
  //TEAMS
  takeLatest(GET_CRUD_TEAM, getCRUDTeams),
  takeLatest(POST_ADD_CRUD_TEAM, postADDCRUDTeams),
  takeLatest(POST_DELETE_CRUD_TEAM, deleteCRUDTeams),
  takeLatest(PUT_UPDATE_CRUD_TEAM, updateCRUDTeams),
  //PARTNERS
  takeLatest(GET_CRUD_PARTNER, getCRUDPartners),
  takeLatest(POST_ADD_CRUD_PARTNER, postADDCRUDPartners),
  takeLatest(POST_DELETE_CRUD_PARTNER, deleteCRUDPartners),
  takeLatest(PUT_UPDATE_CRUD_PARTNER, updateCRUDPartners),
  //EXPENSES
  takeLatest(GET_CRUD_EXPENSE, getCRUDExpenses),
  takeLatest(POST_ADD_CRUD_EXPENSE, postADDCRUDExpenses),
  takeLatest(POST_DELETE_CRUD_EXPENSE, deleteCRUDExpenses),
  takeLatest(PUT_UPDATE_CRUD_EXPENSE, updateCRUDExpenses),
  //CLIENT DIRECTORS
  takeLatest(GET_CRUD_CLIENTDIRECTOR, getCRUDClientDirectors),
  takeLatest(POST_ADD_CRUD_CLIENTDIRECTOR, postADDCRUDClientDirectors),
  takeLatest(POST_DELETE_CRUD_CLIENTDIRECTOR, deleteCRUDClientDirectors),
  takeLatest(PUT_UPDATE_CRUD_CLIENTDIRECTOR, updateCRUDClientDirectors),
  //COLLABORATORS
  takeLatest(GET_CRUD_COLLAB, getCRUDCollabs),
  takeLatest(POST_ADD_CRUD_COLLAB, postADDCRUDCollabs),
  takeLatest(POST_DELETE_CRUD_COLLAB, deleteCRUDCollabs),
  takeLatest(PUT_UPDATE_CRUD_COLLAB, updateCRUDCollabs),
];
