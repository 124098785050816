import { takeEvery } from 'redux-saga/effects';

import {
  FIND_REPORT_PERFORMANCE,
  FIND_REPORT_PROCESS_STATISTICS,
  FIND_REPORT_SLA,
  FIND_REPORT_PROCESSES_STATUS
} from "../ducks/iflowReports/actionTypes";

import {
  findReportPerformance,
  findReportProcessStatistics,
  findReportSla,
  findReportProcessesStatus
} from "../ducks/iflowReports/sagas";

export const iflowReportsSagas = [
  takeEvery(FIND_REPORT_PERFORMANCE, findReportPerformance),
  takeEvery(FIND_REPORT_SLA, findReportSla),
  takeEvery(FIND_REPORT_PROCESS_STATISTICS, findReportProcessStatistics),
  takeEvery(FIND_REPORT_PROCESSES_STATUS, findReportProcessesStatus)
]
