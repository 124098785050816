import {
  SET_TASKS,
  FIND_IFLOW_TASKS_BY_FILTERS,
  UPDATE_READFLAG,
  SET_UPDATE_READFLAG,
  SET_TASKS_COUNT,
  SET_TASKS_COUNT_TOTAL
} from "./actionTypes";

const initialState = {
  tasks: [],
  isLoadingTasks: false,
  isLoadingUpdateReadFlag: false,
  message: [],
  numbercount: 0,
  numbercountTotal: 0,
};
 
export function tasksReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_IFLOW_TASKS_BY_FILTERS:
      return { ...state, isLoadingTasks: true };
    case SET_TASKS:
      return { ...state, tasks: action.data, isLoadingTasks: false };
    case UPDATE_READFLAG:
      return { ...state, isLoadingUpdateReadFlag: true };
    case SET_UPDATE_READFLAG:
      return { ...state, message: action.data, isLoadingUpdateReadFlag: false };
      case SET_TASKS_COUNT:
        return { ...state, numbercount: action.data, isLoadingTasks: false };
      case SET_TASKS_COUNT_TOTAL:
        return {
          ...state,
          numbercountTotal: action.data
        };
    default:
      return state;
  }
}
