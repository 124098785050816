import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import { createBaseWidget } from "../actions";

const RegisterBaseWidget = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const [production, setProduction] = useState(false);

  const [adminConfigNeeded, setAdminConfigNeeded] = useState(false);
  const [component, setComponent] = useState("");

  const toggleAdminConfig = () => {
    setAdminConfigNeeded((adminConfigNeeded) => !adminConfigNeeded);
  };

  const toggleReady = () => {
    setProduction((production) => !production);
  };

  const registerBaseWidget = () => {
    const payload = {
      component: component,
      adminConfigNeeded: adminConfigNeeded,
      production: production,
    };
    dispatch(createBaseWidget(payload));
    setComponent("");
  };
  return (
    <Col sm={4}>
      <Form.Control
        type="text"
        value={component}
        placeholder={t("SystemSettings.enterFileName")}
        onChange={(e) => {
          setComponent(e.target.value);
        }}
      />
      <br />
      <Form.Check
        type="switch"
        id="prodready"
        label={t("SystemSettings.productionReady")}
        checked={production}
        onChange={() => toggleReady()}
      />
      <Form.Check
        type="switch"
        id="isadminconfig"
        label={t("SystemSettings.adminConfigNeeded")}
        checked={adminConfigNeeded}
        onChange={() => toggleAdminConfig()}
      />
      <br />
      <ButtonAdd
        text={t("SystemSettings.registerWidget")}
        onClick={registerBaseWidget}
      />
    </Col>
  );
};

export default withNamespaces()(RegisterBaseWidget);
