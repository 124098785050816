import React from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid/v1";
import { transformFormData } from "~/components/CreateForm/utils";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import { generateAliceLink } from "~store/ducks/onboarding/actionTypes";
import oauth from "~/utils/oauth";
import addIconDisabled from "../assets/img/icons/icon-add-disabled.png";
import addDocumentIconDisabled from "../assets/img/icons/icon-add-document-disabled.png";
import addDocumentIcon from "../assets/img/icons/icon-add-document.png";
import addIcon from "../assets/img/icons/icon-add-secondary.png";
import errorDisabled from "../assets/img/icons/icon-error-disabled.png";
import error from "../assets/img/icons/icon-error-light-small.png";
import iconLess from "../assets/img/icons/icon-less.png";
import success from "../assets/img/icons/icon-success-light-small.png";
import successDisabled from "../assets/img/icons/icon-success-light-small-disabled.png";
import uploadIconDisabled from "../assets/img/icons/icon-upload-2.png";
import uploadIcon from "../assets/img/icons/icon-upload.png";
import "../assets/scss/_buttons.scss";
const devLogConsole = require("~/utils/devLog");

const Button = ({
  text,
  variant,
  disabled = false,
  disabledColored = false,
  onclick,
  field,
  t,
  align,
  handleSubmitWrapper,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);

  const handleLinkClick = (e) => {
    devLogConsole(window.location.href)
    if(field === null || field === undefined)
      return
    e.preventDefault();
    e.stopPropagation();
    if (field.href.includes("javascript:")) {
      if (field.href.includes("document.dados.op.value='")) {
        const array = field.href.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.buttonResult.value='")) {
        const array = field.href.split("document.dados.buttonResult.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.buttonResult.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.acção.value='")) {
        const array = field.href.split("document.dados.acção.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.acção.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.voltar.value='")) {
        const array = field.href.split("document.dados.voltar.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.voltar.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.trocarDeContaTink.value='")) {
        const array = field.href.split(
          "document.dados.trocarDeContaTink.value='"
        );
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.trocarDeContaTink.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.botao_accao.value='")) {
        const array = field.href.split("document.dados.botao_accao.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.botao_accao.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.mostrardoc.value='")) {
        const array = field.href.split("document.dados.mostrardoc.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.mostrardoc.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.cancelardoc.value='")) {
        const array = field.href.split("document.dados.cancelardoc.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.cancelardoc.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.toggleCodigoCertidao.value='")) {
        const array = field.href.split(
          "document.dados.toggleCodigoCertidao.value='"
        );
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.toggleCodigoCertidao.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.botao.value='")) {
        const array = field.href.split("document.dados.botao.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.botao.value = array2[0];
          }
        }
      }

      if (document.dados?.cmdPath && document.dados?.cmdHost) {
        if (document.dados?.acção.value === "iniciarChaveMovelDigital") {
          document.dados.cmdHost.value = window.location.origin;
          document.dados.cmdPath.value = window.location.hash.slice(1); //To remove # that causes iflow some problems
        }
      }

      if (field.href.includes("iniciarAliceBiometrics")) {
        const pid = window.localStorage.getItem("pid");
        const orgId = user?.organization?.id;
        if (pid && orgId) {
          dispatch(generateAliceLink(pid, orgId));
        }
      }

      if (field.href.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postEasypayFormData(formdata, url));
        if(window.location.href.includes("USER_CANCELLED"))
        window.location.href = window.location.href.toString().split("?")[0]
      }
    }
  };

  return (
    text !== "hidden-button" && (
      <div
        className="easypay-button-wrapper"
        key={uuid()}
        style={{ justifyContent: align }}
      >
        <button
          className={`${variant} ${
            disabledColored ? "easypay-button-disabledColored" : ""
          } `}
          disabled={disabled || disabledColored}
          onClick={
            !onclick
              ? (e) => handleLinkClick(e)
              : onclick === "guardar"
              ? (e) =>
                  handleSubmitWrapper(e, {
                    id: "5",
                    name: "_guardar",
                    operation: "disableForm(); document.dados.op.value=2;",
                    text: "Continuar mais tarde",
                    tooltip: "Save",
                  })
              : onclick
          }
          type="button"
        >
          {variant?.includes("success") && (
            <img
              className="easypay-button-approve-img"
              src={disabled ? successDisabled : success}
              alt="Icon success"
            />
          )}
          {variant?.includes("error") && (
            <img
              className="easypay-button-approve-img"
              src={disabled ? errorDisabled : error}
              alt="Icon error"
            />
          )}
          {variant === "easypay-button-add" && (
            <img
              className="easypay-button-add-img"
              src={disabled ? addIconDisabled : addIcon}
              alt="Add Icon"
              disabled={disabled}
            />
          )}
          {variant?.includes("easypay-document") && (
            <img
              className="easypay-button-add-img easypay-document-img"
              src={disabled ? addDocumentIconDisabled : addDocumentIcon}
              alt="Add Icon"
              disabled={disabled}
            />
          )}
          {variant?.includes("easypay-button-remove") && (
            <img
              className="easypay-button-add-img easypay-document-img"
              src={iconLess}
              alt="Add Icon"
              disabled={disabled}
            />
          )}
          <span
            className={`easypay-button-icon-text ${
              variant?.includes("easypay-document") ? "easypay-22px-text" : ""
            } ${
              variant?.includes("easypay-button-add") ? "easypay-20px-text" : ""
            }`}
          >
            {t(text)}
            {(variant === "easypay-button-secondary-icon" ||
              variant === "easypay-button-secondary-icon-large") && (
              <img
                className={`easypay-button-upload-img ${
                  disabledColored ? "easypay-button-img-disabledColored" : ""
                }`}
                src={disabled ? uploadIconDisabled : uploadIcon}
                alt="Upload Icon"
              />
            )}
          </span>
        </button>
      </div>
    )
  );
};

export default withNamespaces()(Button);
