import React, { useState, useEffect } from "react";

const CreateTableTextInput = (props) => {
  const { id, name, value, linha, size, maxlength, onBlur, fontSize } = props;

  const [inputValue, setValue] = useState("");
  const [isValueSet, setIsValueSet] = useState(false);

  useEffect(() => {
    if (value) {
      setValue(value);
      setIsValueSet(true);
    }
  }, [value]);

  useEffect(() => {
    if (isValueSet) {
      onBlur(linha, 66, 83);
    }
  }, [isValueSet]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <td id={id}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          paddingLeft: "5px",
        }}
      >
        <input
          className="form-control"
          name={name}
          type="text"
          size={size}
          style={{ width: "70%", maxHeight: "42px", fontSize: `${fontSize}px`}}
          maxLength={maxlength}
          value={inputValue}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            onBlur(linha, 66, 83);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
    </td>
  );
};
export default CreateTableTextInput;
