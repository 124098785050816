import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

const AppVersion = (props) => {
  const { t, resultVersionSignPdf } = props;

  const [alert, setAlert] = useState("");
  const [versionmsg, setVersionMsg] = useState("");

  useEffect(() => {
    if (resultVersionSignPdf !== "") {
      if (resultVersionSignPdf && resultVersionSignPdf.data) {
        if (resultVersionSignPdf.data === "No dlls found") setAlert("noDLLs");
        else if (resultVersionSignPdf.data === "Network Error")
          setAlert("noSignpdf");
      } else if (Array.isArray(resultVersionSignPdf)) {
        if (resultVersionSignPdf[0].includes("autenticacao.gov"))
          setVersionMsg(resultVersionSignPdf);
      }
    }
  }, [resultVersionSignPdf]);

  return (
    <div>
      {versionmsg && (
        <Alert variant="info">
          <>
            {t("signature.version")}
            <br />
            {versionmsg[0]}
            <br />
            {versionmsg[1]}
          </>
        </Alert>
      )}
      {alert === "noDLLs" && (
        <Alert variant="warning">
          {" "}
          {t(`signature.${alert}`)}
          <a
            href="https://www.autenticacao.gov.pt/web/guest/cc-aplicacao"
            target="_blank"
          >
            Autenticação.gov.
          </a>
        </Alert>
      )}
      {alert === "noSignpdf" && (
        <Alert variant="warning">
          {" "}
          {t(`signature.${alert}`)}
          <a
            href="https://www.uniksystem.com/pt/download-digital-sign/"
            target="_blank"
          >
            Unik Digital Sign App
          </a>
        </Alert>
      )}
    </div>
  );
};
export default withNamespaces()(AppVersion);
