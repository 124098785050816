export const REQUEST_USER = 'REQUEST_USER';
export const SUCCESS_REQUEST_USER = 'SUCCESS_REQUEST_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const FAIL_REQUEST_USER = 'FAIL_REQUEST_USER';
export const TOGGLE_ISAVAILABLE = 'TOGGLE_ISAVAILABLE';
export const TOGGLE_PRODUCTION_MODE = 'TOGGLE_PRODUCTION_MODE';
export const SET_CURRENT_MENU_PERMISSION = 'SET_CURRENT_MENU_PERMISSION';
export const ADD_LEGACY_PERMISSIONS = 'ADD_LEGACY_PERMISSIONS';

export const SET_PROCESS_FORM_URL = 'SET_PROCESS_FORM_URL';
export const SET_PROCESS_FORM_MENU_ITEM_CLICKED = 'SET_PROCESS_FORM_MENU_ITEM_CLICKED';

export const SET_USER_ORGANIZATION = 'SET_USER_ORGANIZATION';

export const requestUser = () => {
    return {
        type: REQUEST_USER,
    }
}

export const successRequestUser = (payload) => {
    return {
        type: SUCCESS_REQUEST_USER,
        data: payload
    }
}


export const setUserOrganization = (payload) => {
    return {
        type: SET_USER_ORGANIZATION,
        data: payload
    }
}

export const failRequestUser = (payload) => {
    return {
        type: FAIL_REQUEST_USER,
        data: payload
    }
}

export const clearUser = () => {
    return { type: CLEAR_USER }
}

export const toggleIsAvailable = () => {
    return {
        type: TOGGLE_ISAVAILABLE,
    }
}

export const setCurrentMenuPermission = (currentMenuPermission) => {
    return { type: SET_CURRENT_MENU_PERMISSION, currentMenuPermission }
}

export const addLegacyPermissions = (legacyPermissions, roleLabel) => {
    return { type: ADD_LEGACY_PERMISSIONS, legacyPermissions, roleLabel }
}

export const setProcessFormUrl = (url) => {
    return { type: SET_PROCESS_FORM_URL, url }
}


export const setProcessFormMenuItemClicked = (clicked) => {
    return { type: SET_PROCESS_FORM_MENU_ITEM_CLICKED, clicked }
}


