import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useSelector, useDispatch } from "react-redux";
import { getColumnFormatter } from "./columnFormatter";
import { Button } from "react-bootstrap";
import AddButton from "~/components/Buttons/AddButton";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import Modal from "~/pages/Easypay/components/Modal";
import CreateActionColumn from "./CreateActionColumn";

const DepartmentsTable = ({
  t,
  app,
  departmentsData,
  handleRefresh,
  setaddEditNewDepartment,
  handleOpenEditModal,
  handleOpenDeleteModal,
  setshowEditModal,
  
}) => {
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  const [flowColumns, setFlowColumns] = useState(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/pesquisar` || item.route === `${app}/search`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        let flowColumns_;
        let hiddenDefaults_;
        // if (flowId && extraConfiguration_[flowId]) {
        //   flowColumns_ = extraConfiguration_[flowId].columns; //columns for a specific flow
        //   hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default columns for a specific flow
        // } else {
        flowColumns_ = extraConfiguration_.defaultExtraColumns; //extra columns for all flows
        hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default columns for all flows
        //}
        setFlowColumns(flowColumns_ ? flowColumns_ : undefined);
        setHiddenDeafultColumns(hiddenDefaults_ ? hiddenDefaults_ : undefined);
      }
    }
  }, [menu]);

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };
  if (flowColumns) {
    getColumns(flowColumns).forEach((column, index) =>
      columns.splice(1 + index, 0, column)
    );
  }
  const getColumns = (columns) => {
    const getCSVFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (formatExtraData.value === "SLA")
        return getColumnFormatter(cell, row, rowIndex, formatExtraData).replace(
          /,/g,
          ""
        );
      else
        return row[formatExtraData.value] !== null &&
          row[formatExtraData.value] !== undefined
          ? row[formatExtraData.value]
          : "";
    };
  };
  const isMobile = window.innerWidth < 992 ? true : false;
  const columns = [
    {
      dataField: "idDepartamento",
      text: "ID Departamento",
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "idDepartamento",
      },
      hidden: true,
      csvExport: !isHidden("idDepartamento"),
    },
    {
      dataField: "empresa",
      text: t(`budgetManagement.departments.empresa`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "empresa",
      },
      hidden: isHidden("empresa"),
      csvExport: !isHidden("empresa"),
    },
    {
      dataField: "nome",
      text: t(`budgetManagement.departments.nome`),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "nome",
      },
      hidden: isHidden("nome"),
      csvExport: !isHidden("nome"),
    },
  ];

  const { SearchBar } = Search;
  return (
    <>
      <div className="custom-table">
        <ToolkitProvider
          keyField="idDepartamento"
          data={departmentsData}
          columns={columns}
          search
          exportCSV={{
            fileName: "process.csv",
            separator: ";",
            noAutoBOM: true,
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SearchBar
                  {...props.searchProps}
                  style={{ paddingLeft: "20px" }}
                  placeholder={t("general.search")}
                />
                <Button
                  style={{ marginBottom: "8px" }}
                  className="card-button"
                  onClick={() => handleRefresh()}
                >
                  {t(`budgetManagement.departments.btnUpdate`)}
                </Button>
              </div>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("taskPage.general.processesNotFound")}
                sort={{
                  dataField: "nome",
                  order: "asc",
                }}
                striped
                hover
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ProtectedComponent
          permissions={[{ label: "app-budgetmgr-departments-create" }]}
        >
          <AddButton
            text={t(`budgetManagement.departments.addNew`)}
            onClick={() => {
              setshowEditModal(true);
              setaddEditNewDepartment(true);
            }}
          />
        </ProtectedComponent>
      </div>
    </>
  );
};

export default withNamespaces()(DepartmentsTable);
