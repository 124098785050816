import { TasksStatus } from './TasksEnum';

export const getColorByStatus = (status) => {
  switch (status) {
    case TasksStatus.NOVO:
      return 'info'
    case TasksStatus.OCR:
      return 'warning'
    case TasksStatus.VALIDAÇÃO_DOCUMENTAL:
      return 'warning'
    case TasksStatus.DOCUMENTAÇÃO_INCOMPLETA:
      return 'danger'
    case TasksStatus.CONTROLO_DE_QUALIDADE:
      return 'warning'
    case TasksStatus.VALIDAÇÃO_CONTRATUAL:
      return 'warning'
    case TasksStatus.APROVADO:
      return 'success'
    case TasksStatus.REJEITADO:
      return 'danger'
    default:
      return ''
  }
}

export const translateStatus = (status) => {
  switch (status) {
    case TasksStatus.NOVO:
      return 'TasksWidget.taskStatus.new'
    case TasksStatus.OCR:
      return 'TasksWidget.taskStatus.ocr'
    case TasksStatus.VALIDAÇÃO_DOCUMENTAL:
      return 'TasksWidget.taskStatus.documentValidation'
    case TasksStatus.DOCUMENTAÇÃO_INCOMPLETA:
      return 'TasksWidget.taskStatus.incompleteDocumentation'
    case TasksStatus.CONTROLO_DE_QUALIDADE:
      return 'TasksWidget.taskStatus.qualityControl'
    case TasksStatus.VALIDAÇÃO_CONTRATUAL:
      return 'TasksWidget.taskStatus.contractValidation'
    case TasksStatus.APROVADO:
      return 'TasksWidget.taskStatus.approved'
    case TasksStatus.REJEITADO:
      return 'TasksWidget.taskStatus.rejected'
    default:
      return ''
  }
}

