import React, { useState } from "react";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import "../assets/scss/_accordion.scss";
import Tag from "./Tag";

const Accordion = ({ list }) => {
  const [data, setData] = useState([]);

  function removeElementFromData(index) {
    const i = data.indexOf(index);
    const newData = data;
    newData.splice(i, 1);
    return newData;
  }

  return (
    <>
      {list &&
        Object.keys(list).length !== 0 &&
        list.map((item, index) => {
          return (
            <>
              <div className="easypay-accordion" key={index}>
                <div
                  className="easypay-accordion-header"
                  onClick={() => {
                    data.includes(index)
                      ? setData([...removeElementFromData(index)])
                      : setData([...data, index]);
                  }}
                >
                  <div className="easypay-accordion-top-content-container">
                    <div className="easypay-id">{item.badge}</div>
                    <div className="easypay-title">{item.title}</div>
                    {item.role &&  item.role != "" &&<Tag
                      className="easypay-accordion-tag"
                      variant="easypay-tag"
                      text={item.role}
                      type="easypay-normal"
                    ></Tag>}
                  </div>
                  <div className="easypay-image-container">
                    {item.submitted === "true" && (
                      <img
                        className="easypay-accordion-checkmark-img"
                        src={greenCheckmark}
                      />
                    )}
                    <img
                      className={data.includes(index) ? `${"easypay-accordion-btn-img"} active` : "easypay-accordion-btn-img"}
                      src={data.includes(index) ? arrowUpBlue : arrowDownGrey}
                    />
                  </div>
                </div>
                {data.includes(index) === true && (
                  <div className="easypay-accordion-content-card">
                    {item.content}
                  </div>
                )}
                <div className = "easypay-accordion-bottom-button-wrapper">

                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default Accordion;
