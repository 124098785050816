import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";

import { toLocaleString } from "~/utils";
import { withNamespaces } from "react-i18next";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { getColorByStatus, translateStatus } from "./utils";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { getTasks } from "./actions";
import { updateUserWidget } from "~/pages/User/actions";

import FlowModal from "./FlowModal";
import TaskFilters from "./TaskFilters";
import PreviewModal from "./PreviewModal";
import Messages from "~/components/Messages";
import CustomDropdown from "../CustomDropdown";

import "~/assets/css/icons.css";

const TasksWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { tasks } = useSelector((state) => state.tasksReducer);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const getUsername = (legacyUsers) => {
    let userBPM = legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    );
    return userBPM ? userBPM.username : null;
  };

  useEffect(() => {
    if (user.legacyUsers) {
      dispatch(getTasks(getUsername(user.legacyUsers), "datacapture"));
    }
  }, [user.legacyUsers]);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "12",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  const handleOpenFlowModal = (row) => {
    setShowFlowModal(true);
    setSelectedTask(row);
  };
  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };

  const handleOpenPreviewModal = (row) => {
    setShowPreviewModal(true);
    setSelectedTask(row);
  };
  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const buildDescriptionColumn = (_, row, index) => {
    return (
      <OverlayTrigger
        key={index}
        placement="right"
        overlay={<Tooltip id={`tooltip-${index}`}>{row.description}</Tooltip>}
      >
        <span>
          {row.description && row.description.length > 30
            ? row.description.slice(0, 25) + "..."
            : row.description}
        </span>
      </OverlayTrigger>
    );
  };

  function timeConversion(millisec) {
    const seconds = (millisec / 1000).toFixed(0);
    const minutes = (millisec / (1000 * 60)).toFixed(0);
    const hours = (millisec / (1000 * 60 * 60)).toFixed(0);
    const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(0);
    if (seconds < 60) {
      return "menos de um minuto";
    } else if (minutes < 60) {
      return minutes + (minutes > 1 ? " minutos" : " minuto");
    } else if (hours < 24) {
      return hours + (hours > 1 ? " horas" : " hora");
    } else {
      return days + (days > 1 ? " dias" : " dia");
    }
  }

  const buildPendingColumn = (_, row, index) => {
    if (row.created) {
      const millis = Date.now() - new Date(row.created);
      return timeConversion(millis);
    }
    return "";
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const buildActionsColumn = (_, row, {}) => (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("TasksWidget.preview"))}
      >
        <i
          className="icon-detail table-action-icon"
          onClick={() => handleOpenPreviewModal(row)}
        />
      </OverlayTrigger>

      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("TasksWidget.proceed"))}
      >
        <i
          className="icon-edit table-action-icon"
          onClick={() => handleOpenFlowModal(row)}
        />
      </OverlayTrigger>
    </>
  );

  const buildStatusColumn = (_, row, index) => {
    if (row.PROCESS_STATUS) {
      const variant = getColorByStatus(row.PROCESS_STATUS);
      const translatedStatus = translateStatus(row.PROCESS_STATUS);
      return (
        <Badge pill variant={variant}>
          {t(translatedStatus)}
        </Badge>
      );
    }
    return "";
  };

  const columns = [
    {
      dataField: "pnumber",
      text: t("TasksWidget.processNumber"),
      sort: true,
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "fluxo",
      text: t("TasksWidget.flow"),
      sort: true,
    },
    {
      dataField: "description",
      text: t("TasksWidget.description"),
      formatter: buildDescriptionColumn,
      sort: true,
      style: {
        width: "100px",
      },
    },
    {
      dataField: "created",
      text: t("TasksWidget.sendDate"),
      sort: true,
      formatter: (_, row, {}) => toLocaleString(row.created),
    },
    {
      dataField: "pendingPeriod",
      text: t("TasksWidget.pendingFor"),
      formatter: buildPendingColumn,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "status",
      text: t("TasksWidget.status"),
      sort: true,
      formatter: buildStatusColumn,
    },
    {
      dataField: "actions",
      text: t("TasksWidget.action"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
  ];

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6 className="h6old">
              <i className="icon-bell mr-2" />
              {t("TasksWidget.title")}
            </h6>
            <h6 className="h6old">
              {textMove && t("widgets.resizeMove")}
              {textClick && t("widgets.resizeClick")}
            </h6>
            <CustomDropdown
              apagarWidget={apagarWidget}
              handleMouseDown={handleMouseDown}
            />
          </Card.Header>
          {!isResizing && (
            <Card.Body>
              <TaskFilters />
              <>
                <br />
                <h5>{t("TasksWidget.tasks")}</h5>
                <hr />

                <BootstrapTable
                  keyField="pnumber"
                  data={tasks}
                  columns={columns}
                  bootstrap4
                  pagination={paginationFactory()}
                  noDataIndication={t("TasksWidget.tasksNotFound")}
                  striped
                  hover
                />
              </>
            </Card.Body>
          )}
        </Card>
      </div>

      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        task={selectedTask}
      />
      <PreviewModal
        showModal={showPreviewModal}
        closeModal={handleClosePreviewModal}
        task={selectedTask}
      />
      <Messages />
    </>
  );
};
export default withNamespaces()(TasksWidget);
