import React from "react";
import { useSelector } from "react-redux";

import { hasRoles, hasPermission, hasPermissions } from "./utils";

/*
  Usage example:

  <ProtectedComponent
    roles={[...]}
    permission="PERMISSION_NAME"
    canCreate={BOOLEAN_VALUE}
    canRead={BOOLEAN_VALUE}
    canUpdate={BOOLEAN_VALUE}
    canDelete={BOOLEAN_VALUE}
  >
    <>{...}</>
  </ProtectedComponent>
*/

const ProtectedComponent = (props) => {
  const { user } = useSelector((state) => state.globalReducer);

  const {
    children,
    roles = [],
    permissions = [],
    permission,
    canCreate,
    canRead,
    canUpdate,
    canDelete,
  } = props;

  const rendered = () => {
    const _hasRoles = hasRoles(user, roles);
    const _hasPermissions = hasPermissions(user, permissions);
    const _hasPermission = hasPermission(
      user,
      permission,
      canCreate,
      canRead,
      canUpdate,
      canDelete
    );
    return _hasRoles && _hasPermissions && _hasPermission;
  };

  return <>{rendered() && { ...children }}</>;
};

export default ProtectedComponent;
