import { takeLatest } from 'redux-saga/effects';

import {
  FIND_LEGACY_PERMISSIONS
} from "../ducks/legacyPermission/actionTypes";

import {
  findLegacyPermissions
} from "../ducks/legacyPermission/sagas";

export const legacyPermissionSagas = [
  takeLatest(FIND_LEGACY_PERMISSIONS, findLegacyPermissions)
]
