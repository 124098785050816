import React from "react";
import BackOfficeEasypaySubHeader from "~pages/Easypay/components/BackOfficeEasypaySubHeader.js";
import BackOfficeEasypayLeadsSections from "./LeadsSections.js";
import BackOfficeVerticalButtons from "~pages/Easypay/components/BackOfficeVerticalButtons.js";
import { withRouter } from "react-router-dom";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";

import Header from "~/pages/Easypay/components/Header";

import "~/pages/Easypay/assets/css/styles.css";

const Leads = ({ application }) => {
  const handleClick = (id) => {
    //Dynamic offSet that calculates header and subheader height
    const offSet =
      document?.getElementById("easypay-header")?.offsetHeight +
      document?.getElementById("easypay-backoffice-subheader")?.offsetHeight;
    const element = document.getElementById(id);
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - offSet;

    document.documentElement.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    //For Safari
    document.body.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const buttonsList = [
    { name: "Registo", text: "Registo" },
    { name: "Prova de vida", text: "Prova de vida" },
    { name: "Conta", text: "Conta" },
    { name: "Negócio", text: "Negócio" },
  ];

  const breadcrumbItems = [
    {
      text: "Leads",
      link: "/#/uniksystem/onboarding/backoffic-organization/leads",
    },
    {
      text: "ANT03213",
      link: "/#/uniksystem/onboarding/backoffic-organization/leads",
    },
  ];

  return (
    <>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
        addDocument={true}
      />
      <BackOfficeEasypaySubHeader breadcrumbItems={breadcrumbItems} />
      <BackOfficeVerticalButtons
        navItems={buttonsList}
        handleClick={handleClick}
      />
      <BackOfficeEasypayLeadsSections />
    </>
  );
};
export default withRouter(Leads);
