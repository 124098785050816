import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid/v1";
import Tab from "~/pages/Easypay/components/Tab";
import { Card, Tabs } from "react-bootstrap";
import TabWrapper from "~/pages/Easypay/components/TabWrapper";
import NFinantialDoc from "../components/NFinantialDoc";
import UploadModal from "~/pages/ADataCapture/components/AutomatedDatacapture/components/UploadModal";
import ReclamacoesOnline from "../components/ReclamacoesOnline";
import CorreioEletronico from "../components/CorreioEletronico";
import CorreioFisico from "../components/CorreioFisico";
import Resultados from "../components/Resultados";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Spinner from "~/components/Spinner";
import Modal from "~/pages/Easypay/components/Modal";
import Pagination from "~/pages/Easypay/components/Pagination";
import Dropdown from "~/components/Dropdown";
import "~pages/Anacom/assets/scss/_anacomTable.scss";

import {
  getAllCategories,
  classifyManualClaim,
  getComplaints,
  setComplaints,
  exportClaims,
  setSubClaims,
} from "~/store/ducks/claimsManagement/actionTypes";
import { set } from "lodash";
import { list } from "~/pages/Easypay/testData";
import greenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
const devLogConsole = require("~/utils/devLog");
const TempoRealTable = ({
  assuntosList,
  unikList,
  assuntosPagination,
  unikPagination,
}) => {
  const [unikCounter, setUnikCounter] = useState(5);
  const [assuntosCounter, setAssuntosCounter] = useState(5);
  const [unikPage, setUnikPage] = useState(1);
  const [assuntosPage, setAssuntosPage] = useState(1);
  const [listAssuntos, setListAssuntos] = useState([]);
  const [assuntoSelected, setAssuntoSelected] = useState("");
  const [unikListFiltered, setUnikListFiltered] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [arrUnik, setArrUnik] = useState([]);

  useEffect(() => {
    if (assuntosList.length > 0) {
      let newArr = JSON.parse(JSON.stringify(assuntosList));

      for (let i = 0; i < newArr.length; i++) {
        delete newArr[i]["percentagem"];
        delete newArr[i]["score"];
        delete newArr[i]["keywordsScore"];
        newArr[i]["label"] = newArr[i]["assunto"];
      }
      newArr.unshift({ label: "Todos" });
      for (let k = 0; k < newArr.length; k++) {
        delete newArr[k]["assunto"];
        newArr[k]["value"] = k.toString();
      }

      setListAssuntos(newArr);
    }
  }, [assuntosList]);

  const handleAssuntos = (e) => {
    setAssuntoSelected(e);
    unikListfilteredByDropdown();
  };

  const unikListfilteredByDropdown = () => {
    const assuntoByDropdown = listAssuntos.filter(
      (e) => e.value === assuntoSelected
    );

    devLogConsole(assuntoSelected, "assuntoSelected");

    const arrFiltered = unikList.filter((e) =>
      assuntoByDropdown[0]?.label === "Todos"
        ? e
        : e.descricao === assuntoByDropdown[0]?.label
        ? e
        : null
    );

    setUnikListFiltered(arrFiltered);
  };

  useEffect(() => {
    if (unikListFiltered.length > 0) {
      let counter = 0;
      let newArr = [];
      counter = unikListFiltered.length / 5;
      counter = Math.round(counter);

      for (let i = 0; i < counter; i++) {
        newArr.push(i + 1);
      }

      setPageList(newArr);
    } else {
      setPageList(unikPagination);
    }
  }, [unikListFiltered, unikPagination]);

  function checkFormatter(cell) {
    if (cell === "t") {
      return <img className="anacom-checkmark-img" src={greenCheckmark} />;
    } else return "";
  }

  useEffect(() => {
    if (unikListFiltered.length > 0) {
      setArrUnik(unikListFiltered);
    } else {
      setArrUnik(unikList);
    }
  }, [unikList, unikListFiltered]);

  function formatPercentagem(percentagem) {
    var currentPercentagem = "";
    if (percentagem?.length > 4) {
      currentPercentagem = percentagem?.slice(0, 4);
      if (currentPercentagem === "100.") {
        return "100";
      } else {
        return currentPercentagem;
      }
    }
    return percentagem;
  }
  devLogConsole(arrUnik, "arrUnik");
  return (
    <>
      <div>
        <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          Assuntos
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div className={`anacom-table-assunto ${"bold"}`}>Assunto</div>
          <div className={`anacom-table-percentagem ${"bold"}`}>%</div>
        </div>
        {assuntosList &&
          Object.keys(assuntosList).length !== 0 &&
          assuntosList.map((item, index) => {
            return (
              <>
                {index < assuntosPage * 5 && index >= assuntosPage * 5 - 5 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div className={`anacom-table-assunto`}>{item.assunto}</div>
                    <div className={`anacom-table-percentagem`}>
                      {formatPercentagem(item?.score?.toString())}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        {
          <Pagination
            list={assuntosPagination}
            pageSelected={assuntosPage}
            setPageSelected={setAssuntosPage}
            counter={assuntosCounter}
            setCounter={setAssuntosCounter}
          />
        }
        <br></br>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            Classificação
          </div>
          <Dropdown
            className="dropdownPopUp"
            list={listAssuntos}
            handleSubmit={handleAssuntos}
            defaultValue={
              assuntoSelected ? assuntoSelected : listAssuntos[0]?.value
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div className={`anacom-table-medium ${"bold"}`}>Nº</div>
          <div className={`anacom-table-big-real ${"bold"}`}>
            Motivo ou Indicio
          </div>
          <div className={`anacom-table-small ${"bold"}`}>%</div>
          <div className={`anacom-table-normal ${"bold"}`}>Assunto</div>
        </div>
        {arrUnik &&
          arrUnik.length > 0 &&
          arrUnik.map((item, index) => {
            return (
              <>
                {index < unikPage * 5 && index >= unikPage * 5 - 5 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`anacom-table-medium ${
                        item?.id?.length <= 3 ? "color" : ""
                      }`}
                    >
                      {item.id}
                    </div>
                    <div
                      className={`anacom-table-big-real ${
                        item?.id?.length <= 3 ? "color" : ""
                      }`}
                    >
                      {item.type}
                    </div>
                    <div
                      className={`anacom-table-small ${
                        item?.id?.length <= 3 ? "color" : ""
                      }`}
                    >
                      {formatPercentagem(item.score)}
                    </div>
                    <div
                      className={`anacom-table-normal ${
                        item?.id?.length <= 3 ? "color" : ""
                      }`}
                    >
                      {item.descricao}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        {
          <Pagination
            list={pageList}
            pageSelected={unikPage}
            setPageSelected={setUnikPage}
            counter={unikCounter}
            setCounter={setUnikCounter}
          />
        }
      </div>
    </>
  );
};

const TablesPage = ({ t }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((selectorState) => selectorState.globalReducer);
  const { complaints } = useSelector((state) => state.claimsManagementReducer);

  const { categories, result } = useSelector(
    (state) => state.claimsManagementReducer
  );

  const [reset, setReset] = useState(false);

  const hanldeResetDropdown = () => {
    setReset(true);
  };

  const changeReset = (boolean) => {
    setReset(boolean);
  };

  const [docTypeSelected, setDocTypeSelected] = useState("financial-document");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [updateColumnLabels, setUpdateColumnLabels] = useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [isRealTimeClaimSet, setIsRealTimeClaimSet] = useState(false);
  const [currentClaim, setCurrentClaim] = useState(undefined);
  const [isLoadingManualClaim, setLoadingManualClaim] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [currentUnik, setCurrentUnik] = useState([]);
  const [currentAssuntos, setCurrentAssuntos] = useState([]);
  const [beginIndex, setBeginIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(2000);
  const [firstIteration, setFirstIteration] = useState(true);

  const [unikPage, setUnikPage] = useState(1);
  const [assuntosPage, setAssuntosPage] = useState(1);
  const [unikList, setUnikList] = useState([]);
  const [assuntosList, setAssuntosList] = useState([]);
  const [unikCounter, setUnikCounter] = useState(5);
  const [assuntosCounter, setAssuntosCounter] = useState(5);

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleOpenMetadataModal = () => {
    if (result === null || result === undefined) return;

    var array = [];
    for (var i = 0; i < result[0]?.motivosAndIndicio?.length; i++) {
      array.push({
        id: result[0].motivosAndIndicio[i]?.motivoOrIndicioId,
        descricao: result[0].motivosAndIndicio[i]?.assunto,
        type: result[0].motivosAndIndicio[i]?.motivoOrIndicioName?.split(
          "___"
        )[1],
        score: result[0].motivosAndIndicio[i]?.score,
      });
    }

    const unikSize = Math.ceil(array.length / 5);
    const assuntosSize = Math.ceil(result[0]?.assuntos.length / 5);

    var currentUnikPagination = [];
    var currentAssuntosPagination = [];

    for (var i = 1; i <= unikSize; i++) {
      currentUnikPagination.push(i);
    }
    for (var i = 1; i <= assuntosSize; i++) {
      currentAssuntosPagination.push(i);
    }
    setUnikList(currentUnikPagination);
    setAssuntosList(currentAssuntosPagination);

    setCurrentAssuntos(result[0]?.assuntos);
    setCurrentUnik(array);
    setIsModalErrorShowing(true);
  };

  const { datacaptureConfiguration } = useSelector(
    (state) => state.datacaptureConfigurationReducer
  );

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (categories?.length > 0) {
      setUpdateColumnLabels(true);
    }
  }, [categories]);

  const resetClaim = () => {
    setCurrentClaim(undefined);
    setIsRealTimeClaimSet(false);
    document.getElementById("claimTextArea").value = "";
  };

  const addQueixa = (queixa) => {
    setCurrentClaim(queixa);
  };

  const classifyClaim = () => {
    if (currentClaim !== undefined && currentClaim !== "") {
      setLoadingManualClaim(true);
      let claimsArray = [];
      claimsArray.push(currentClaim);
      dispatch(classifyManualClaim(claimsArray));
    }
  };

  const showResultDetail = () => {
    //TODO mostrar popup com detalhes do resultado
    setIsShowDetail(true);
  };

  useEffect(() => {
    if (result?.length > 0) {
      setIsRealTimeClaimSet(true);
      setLoadingManualClaim(false);
    } else {
      if (isLoadingManualClaim) {
        //TODO fazer tbm reset ao result do reducer
        resetClaim();
      }
    }
  }, [result]);

  useEffect(() => {
    dispatch(getComplaints(1, 0, 50000));
  }, []);

  const [key, setKey] = useState("aprov");

  useEffect(() => {
    if (key === "aprov") {
      dispatch(setComplaints([]));
      dispatch(setSubClaims(null));
      setBeginIndex(0);
      setEndIndex(21000);
      dispatch(getComplaints(1, 0, 50000));
    } else if (key === "online") {
      dispatch(setComplaints([]));
      dispatch(setSubClaims(null));
      setBeginIndex(0);
      setEndIndex(21000);
      dispatch(getComplaints(2, 0, 50000));
    } else if (key === "correo") {
      dispatch(setComplaints([]));
      dispatch(setSubClaims(null));
      setBeginIndex(0);
      setEndIndex(21000);
      dispatch(getComplaints(3, 0, 50000));
    } else if (key === "fisico") {
      dispatch(setComplaints([]));
      dispatch(setSubClaims(null));
      setBeginIndex(0);
      setEndIndex(21000);
      dispatch(getComplaints(4, 0, 50000));
    } else if (key === "naoclassificadas") {
      dispatch(setComplaints([]));
      dispatch(setSubClaims(null));
      setBeginIndex(0);
      setEndIndex(21000);
      dispatch(getComplaints(8, 0, 50000));
    }
  }, [key]);

  function createBodySmall() {
    return (
      <div>
        <TempoRealTable
          assuntosList={currentAssuntos}
          unikList={currentUnik}
          assuntosPagination={assuntosList}
          unikPagination={unikList}
        />
        <div
          style={{
            paddingTop: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SimpleButton
            variant={"liftworld-button-primary"}
            text={"Fechar"}
            onClick={() => {
              setIsModalErrorShowing(false);
            }}
            align={"left"}
          />
        </div>
      </div>
    );
  }

  const downloadXls = () => {
    //1 = EXCEL
    dispatch(exportClaims(1, 6, result[0]?.complaintId));
  };

  return (
    <div style={{ width: "100%", backgroundColor: "#FFFFFF" }}>
      <UploadModal
        docTypeSelected={docTypeSelected}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        simpleFlow={true}
        datacaptureConfiguration={datacaptureConfiguration}
      />
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Tabs
            defaultActiveKey="aprov"
            id="adminSettingsTab"
            className="custom-tabs"
            onSelect={(k) => setKey(k)}
            onClick={hanldeResetDropdown}
          >
            <Tab
              eventKey="aprov"
              title={categories?.length > 0 ? categories[0]?.label : ""}
            >
              <div style={{ display: "flex", gap: 10 }}>
                <NFinantialDoc
                  setBeginIndex={setBeginIndex}
                  setEndIndex={setEndIndex}
                  category={categories ? categories[0]?.id : null}
                  setShowUploadModal={setShowUploadModal}
                />
              </div>
            </Tab>
            <Tab
              eventKey="online"
              title={categories?.length > 0 ? categories[1]?.label : ""}
            >
              <ReclamacoesOnline
                setBeginIndex={setBeginIndex}
                setEndIndex={setEndIndex}
                complaints={complaints}
                category={categories ? categories[1]?.id : null}
              />
            </Tab>
            <Tab
              eventKey="correo"
              title={categories?.length > 0 ? categories[2]?.label : ""}
            >
              <CorreioEletronico
                setBeginIndex={setBeginIndex}
                setEndIndex={setEndIndex}
                category={categories ? categories[2]?.id : null}
              />
            </Tab>
            <Tab
              eventKey="fisico"
              title={categories?.length > 0 ? categories[3]?.label : ""}
            >
              <CorreioFisico
                naoclass={false}
                isNaoClassificadas={true}
                setBeginIndex={setBeginIndex}
                setEndIndex={setEndIndex}
                category={categories ? categories[3]?.id : null}
              />
            </Tab>
            <Tab
              eventKey="naoclassificadas"
              title={categories?.length > 0 ? categories[6]?.label : ""}
            >
               <CorreioFisico
                naoclass={true}
                isNaoClassificadas={true}
                setBeginIndex={setBeginIndex}
                setEndIndex={setEndIndex}
                category={categories ? categories[6]?.id : null}
              />
            </Tab>
            <Tab
              eventKey="resultados"
              title={categories?.length > 0 ? categories[4]?.label : ""}
            >
              <Resultados
                setBeginIndex={setBeginIndex}
                setEndIndex={setEndIndex}
                category={categories ? categories[4]?.id : null}
                resetDropdown={reset}
                changeReset={changeReset}
              />
            </Tab>
            <Tab
              eventKey="classificação"
              title={categories?.length > 0 ? categories[5]?.label : ""}
            >
              <div>
                <div style={{ paddingTop: "15px", paddingBottom: "5px" }}>
                  Reclamação
                </div>
                <div className={`${"liftworld-textarea-input-field-wrapper"}`}>
                  <textarea
                    id="claimTextArea"
                    disabled={isRealTimeClaimSet}
                    className={`liftworld-textarea-input-field`}
                    type={"textarea"}
                    rows="14"
                    onChange={(e) => addQueixa(e.target.value)}
                  />
                </div>
                <div style={{ paddingTop: "15px" }}></div>
                <SimpleButton
                  variant={"liftworld-button-primary"}
                  text={
                    isRealTimeClaimSet ? "Ver classificação" : "Classificar"
                  }
                  align={"left"}
                  onClick={
                    isRealTimeClaimSet
                      ? () => handleOpenMetadataModal()
                      : () => classifyClaim()
                  }
                />
                <br></br>
                {/* {isRealTimeClaimSet ? (
                  <SimpleButton
                    variant={"liftworld-button-primary"}
                    text={"Exportar"}
                    align={"left"}
                    onClick={() => downloadXls()}
                  />
                ) : null} 
               <br></br>*/}
                {isRealTimeClaimSet ? (
                  <SimpleButton
                    variant={"liftworld-button-danger"}
                    text={"Limpar Queixa"}
                    align={"left"}
                    onClick={() => resetClaim()}
                  />
                ) : null}
                <Spinner spinning={isLoadingManualClaim} wrapper />
                <Modal
                  className="easypay-modal"
                  showModal={isModalErrorShowing}
                  setShowModal={setIsModalErrorShowing}
                  headerContent={"Classificação"}
                  bodyContent={createBodySmall()}
                  key={uuid()}
                  nomax={true}
                ></Modal>
              </div>
            </Tab>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export default withNamespaces()(TablesPage);
