export const GET_GRAPH_INFO_BY_SELECTED_ITEM =
  "graphs@GET_GRAPH_INFO_BY_SELECTED_ITEM";
export const SET_GRAPH_INFO = "graphs@SET_GRAPH_INFO";

export const getGraphInfoBySelectedItem = (selectedItem) => ({
  type: GET_GRAPH_INFO_BY_SELECTED_ITEM,
  selectedItem,
});
export const setGraphInfo = (graphInfo) => ({
  type: SET_GRAPH_INFO,
  graphInfo,
});
