import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getRHAdminConfiguration,
  persistRHAdminConfiguration
} from "~/store/ducks/rhadminConfiguration/actionTypes";

const RHAdminConfiguration = (props) => {
  const { t, organizationId } = props;
  const dispatch = useDispatch();
  const {
    rhadminConfiguration,
    successPersistRHAdminConfiguration,
  } = useSelector((state) => state.rhadminConfigurationReducer);

  const [flowId, setFlowId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (organizationId) dispatch(getRHAdminConfiguration(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (rhadminConfiguration.body != undefined && rhadminConfiguration.body.flowId) {
      setFlowId(rhadminConfiguration.body.flowId);
    }
  }, [rhadminConfiguration]);

  useEffect(() => {
    if (successPersistRHAdminConfiguration) setIsEditing(false);
  }, [successPersistRHAdminConfiguration]);

  const handleSave = () => {
    const payload = {
      id: rhadminConfiguration && rhadminConfiguration?.body?.id ? rhadminConfiguration.body.id : null,
      flowId: flowId,
      organizationId: organizationId,
      active: true
    };
    dispatch(persistRHAdminConfiguration(payload));
  };

  return (
    <Form.Row style={{ margin: "20px" }}>
      <Col sm="12" md="12" lg="12">
        <Form.Group controlId="validationCustom">
          <Form.Label style={{ marginTop: "20px" }}>
            <h5>{t("admin.organization.settings.rhadminConfiguration")}</h5>
          </Form.Label>
          <br />
          <br />
          <Form.Group
            controlId="flowId"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Form.Label>{t("admin.organization.settings.flowId")}</Form.Label>
            <Form.Control
              type="text"
              style={{ marginLeft: "15px", width: "200px" }}
              value={flowId}
              onChange={(e) => {
                setFlowId(e.target.value);
                setIsEditing(true);
              }}
              autoComplete="off"
            />
          </Form.Group>

          {isEditing && (
            <Button className="card-button" onClick={() => handleSave()}>
              {t("admin.organization.settings.saveConfig")}
            </Button>
          )}
        </Form.Group>
      </Col>
    </Form.Row>
  );
};

export default withNamespaces()(RHAdminConfiguration);
