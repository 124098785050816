import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { Modal, Button } from "react-bootstrap";
import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { deletePermission, getPermissions } from "../actions";
import PermissionBadge from "~/components/permission/PermissionBadge";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";

const PermissionsTable = ({ permissions, t, persistPermission }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(undefined);

  const { isLoadingPermissions, isDeleting } = useSelector(
    (state) => state.permissionReducer
  );

  const buildActionsColumn = (_, row, {}) => (
    <>
      <i
        className="icon-trash table-action-icon"
        onClick={() => openModal(row)}
      />
      <i
        className="icon-edit-user table-action-icon"
        onClick={() =>
          history.push(`/uniksystem/admin/permission/update/${row.id}`)
        }
      />
    </>
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "description",
      text: t("admin.permission.list.description"),
      sort: true,
    },
    {
      dataField: "label",
      text: t("admin.permission.list.label"),
      sort: true,
    },
    {
      dataField: "actions",
      text: t("admin.permission.list.permissions"),
      sort: true,
      formatter: (cell, row) => (
        <PermissionBadge
          create={row.create}
          read={row.read}
          update={row.update}
          delete_={row.delete}
        />
      ),
    },
    {
      dataField: "accao",
      text: t("admin.permission.list.action"),
      formatter: buildActionsColumn,
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const openModal = (row) => {
    setSelectedPermission(row);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const _deletePermission = () => {
    dispatch(deletePermission(selectedPermission.id));
    handleClose();
  };

  return (
    <div className="custom-table custom-table-small">
      <ToolkitProvider
        keyField="id"
        data={permissions}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <div style={{ display: "flex" }}>
              <SearchBar
                {...props.searchProps}
                placeholder={t("admin.permission.list.searchInput")}
              />

              <div style={{ paddingLeft: "30px" }}>
                <ButtonAdd
                  text={t("general.add")}
                  onClick={persistPermission}
                />
              </div>
            </div>
            <Spinner spinning={isLoadingPermissions} />
            {!isLoadingPermissions && (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("admin.permission.list.emptyTable")}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>

      <Spinner spinning={isDeleting} wrapper />
      <Messages afterHideSuccess={() => dispatch(getPermissions())} />

      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {selectedPermission !== undefined ? (
          <Modal.Body>
            {`${t("general.remove")}: ${selectedPermission.description}?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => _deletePermission()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withNamespaces()(PermissionsTable);
