import { takeEvery } from "redux-saga/effects";

import {
  APROVE_DELEGATION,
  DEL_DELEGACAO,
  GET_APROVAL_DELEGACOES,
  GET_DELEGACOES,
  GET_OWNERS,
  GET_USERS,
  GET_USER_FLOWS,
  GET_USER_TO_USER_FLOWS,
  POST_DELEGATION,
  GET_DELEGACOES_BY_USERID,
  GET_REQUESTED_DELEGATION,
} from "../ducks/delegacoes/actionTypes";

import {
  aproveDelegation,
  deleteDelegacao,
  getAprovalDelegation,
  getDelegacoes,
  getDelegationsFromUserToUser,
  getFlowFromUser,
  getOwners,
  getUsers,
  postInsertDelegation,
  getDelegacoesByUserid,
  getRequestedDelegation,
} from "../ducks/delegacoes/sagas";

export const delegacoesSagas = [
  takeEvery(GET_DELEGACOES, getDelegacoes),
  takeEvery(GET_USER_FLOWS, getFlowFromUser),
  takeEvery(GET_USER_TO_USER_FLOWS, getDelegationsFromUserToUser),
  takeEvery(GET_APROVAL_DELEGACOES, getAprovalDelegation),
  takeEvery(APROVE_DELEGATION, aproveDelegation),
  takeEvery(GET_USERS, getUsers),
  takeEvery(DEL_DELEGACAO, deleteDelegacao),
  takeEvery(POST_DELEGATION, postInsertDelegation),
  takeEvery(GET_OWNERS, getOwners),
  takeEvery(GET_DELEGACOES_BY_USERID, getDelegacoesByUserid),
  takeEvery(GET_REQUESTED_DELEGATION, getRequestedDelegation),
];
