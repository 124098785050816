import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_tab.scss";

const Tab = ({
  variant,
  name,
  text,
  setTheActiveTab,
  activeTab,
  disabled,
  numberOfTabs,
  t,
  action,
}) => {
  useEffect(() => {
    if (action) {
      const variable = action?.split("::")[0];
      const value = action?.split("::")[1];

      if (document.dados[variable]) {
        if (activeTab === name) {
          document.dados[variable].value = value;
        }
      }
    }
  }, [activeTab]);
  return (
    <>
      <div
        className={
          variant &&
          `easypay-tab-separator ${name === activeTab && "easypay-active-tab"}`
        }
      >
        <button
          id={name}
          type="button"
          className={`easypay-tab ${
            name === activeTab ? "easypay-active" : null
          }`}
          style={{ width: 580 / numberOfTabs }}
          onClick={() => {
            setTheActiveTab(name);
          }}
          disabled={disabled}
        >
          {t(text)}
        </button>
      </div>
    </>
  );
};

export default withNamespaces()(Tab);
