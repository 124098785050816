import React, {useEffect} from "react";
import { withNamespaces } from "react-i18next";
import Header from "~/pages/Easypay/components/Header";
import "~/pages/Easypay/assets/css/styles.css";
import BackOfficeRequestDocumentSection from "~pages/Easypay/components/BackOfficeRequestDocumentSection";
import { withRouter } from 'react-router-dom';

const BackOfficeRequestDocumentPage = (props) => {
  const { id } = props.match?.params;
  
  useEffect(() => {
   window.scrollTo(0, 0);

  }, []);

  return (
    <div
      className="easypay-backoffice-document-preview-page-main-wrapper"
      style={{ overflow: "auto", width: "100%", height: "100%" }}
    >
      <Header />
      <BackOfficeRequestDocumentSection id = {id}/>
    </div>
  );
};
export default withRouter(withNamespaces()(BackOfficeRequestDocumentPage));
