import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonDownload from "~/components/Buttons/DownloadButton";
import logo from "~/assets/img/logos/unik-logo.png";

const ToolsComponent = (props) => {
  const { t } = props;
  const ToolRow = ({ children }) => (
    <div style={{ margin: "0px", padding: "0px" }}>{children}</div>
  );

  useEffect(() => {
    if (document.getElementById("globalsolver"))
      document.getElementById("globalsolver").style.display = "none";
  });

  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const getUserLanguage = () => {
    return userSettings.language ? userSettings.language : "pt";
  };
  return (
    <div className="main-card-v2">
      <Row>
        <div
          className="col-5 text-center"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img className="img-fluid" src={logo} alt="UDW" />
        </div>
        <div className="col-7">
          <span className="font-weight-bold">UnikSystem SignPDF </span>
          <br />
          <br />
          <ToolRow>{t("tools.description")}</ToolRow>
          <br />
          <ToolRow>
            <b>{t("tools.guide")}</b>
            {t("tools.requisites")}
            <br />
            <div className="tools_url">
              {t("tools.req1")}
              <a
                href="https://www.autenticacao.gov.pt/web/guest/cc-aplicacao"
                target="_blank"
              >
                Autenticação.gov.
              </a>
            </div>
            {t("tools.req2")}
          </ToolRow>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: "20px" }}>
              <ButtonDownload
                text={t("tools.download")}
                onClick={() =>
                  window.open(
                    "https://www.uniksystem.com/pt/download-digital-sign/",
                    "_blank"
                  )
                }
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href={`/#/digital-signature/${getUserLanguage()}`}
                target="_blank"
              >
                {t("signature.signPdf")}
              </a>
            </div>
          </div>
          <br />
          <br />
          <ToolRow>
            <a href="https://www.uniksystem.com" target="_blank">
              Copyright ©2021 Uniksystem. {t("about.rights")}{" "}
            </a>
          </ToolRow>
        </div>
      </Row>
    </div>
  );
};

export default withNamespaces()(ToolsComponent);
