import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import "../assets/scss/_singleAccordion.scss";

const SingleAccordion = ({ text, t }) => {
  const [showAccordion, setShowAccordion] = useState(false);

  return (
    <>
      <div
        className="easypay-single-accordion"
        onClick={() => {
          setShowAccordion(!showAccordion);
        }}
        key={120}
      >
        <div
          className="easypay-single-accordion-header"
          onClick={() => {
            setShowAccordion(!showAccordion);
          }}
        >
          <div className="easypay-single-accordion-top-content-container">
            <div
              className={`easypay-single-title easypay-${
                text ? t(text[1].value.split("::")[1]) : ""
              }`}
            >
              {text ? t(text[1].value.split("::")[0]) : ""}
            </div>
          </div>
          <div className="easypay-single-image-container">
            <img
              className={
                showAccordion
                  ? `${"easypay-single-accordion-btn-img"} active`
                  : "easypay-single-accordion-btn-img"
              }
              src={showAccordion ? arrowUpBlue : arrowDownGrey}
            />
          </div>
        </div>
        {showAccordion && (
          <div className="easypay-single-accordion-content-card">
            {text.map((paragraph, i) => {
              if (i > 1) {
                return (
                  <div className={`easypay-${paragraph.value.split("::")[1]}`}>
                    {t(paragraph.value.split("::")[0])}
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(SingleAccordion);
