import React, { useState } from "react";
import InputPassword from "~/pages/Easypay/components/InputPassword";

const CreateInputPassword = ({hasBar, label}) => {
  
    const [pwd,setPwd] = useState("")
    const [passwordValid, setPasswordValid] = useState(false);

    return (
        <>
            <InputPassword label = {label} pwd = {pwd} setPwd = {setPwd} setPasswordValid = {setPasswordValid} hasBar = {hasBar}></InputPassword>
        </>
      );
}
export default CreateInputPassword 