export const GET_ROLES = "GET_ROLES";
export const SET_ROLES = "SET_ROLES";
export const PERSIST_ROLE = "PERSIST_ROLE";
export const PERSIST_ROLE_DONE = "PERSIST_ROLE_DONE";
export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const SET_ROLE = "SET_ROLE";
export const CLEAR_ROLES = "CLEAR_ROLES";
export const CLEAR_ROLE = "CLEAR_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_DONE = "DELETE_ROLE_DONE";
export const FIND_USERS_BY_ROLE = "FIND_USERS_BY_ROLE";
export const SET_FIND_USERS_BY_ROLE = "SET_FIND_USERS_BY_ROLE";
export const ADD_ROLE_ITEM = "ROLE@ADD_ROLE_ITEM";
export const REMOVE_ROLE_ITEM = "ROLE@REMOVE_ROLE_ITEM";
export const ALL_USERS_BY_ROLE = "ROLE@ALL_USERS_BY_ROLE";
export const ALL_USERS_BY_ROLE_DONE = "ROLE@ALL_USERS_BY_ROLE_DONE";

export const LOAD_LEGACY_APPS = 'LOAD_LEGACY_APPS';
export const SET_LEGACY_APPS = 'SET_LEGACY_APPS';

export const LOAD_LEGACY_ROLES = 'LOAD_LEGACY_ROLES';
export const SET_LEGACY_ROLES = 'SET_LEGACY_ROLES';

export const UPDATE_UNIK_USERS = "ROLE@UPDATE_UNIK_USERS";
export const UPDATE_UNIK_USERS_DONE = "ROLE@UPDATE_UNIK_USERS_DONE";

export const FIND_DEFAULT_ROLES = "ROLE@FIND_DEFAULT_ROLES";
export const SET_DEFAULT_ROLES = "ROLE@SET_DEFAULT_ROLES";
export const ADD_DEFAULT_ROLE = "ROLE@ADD_DEFAULT_ROLE";
export const DELETE_DEFAULT_ROLE = "ROLE@DELETE_DEFAULT_ROLE";
export const SET_UPDATED_DEFAULT_ROLES = "ROLE@SET_UPDATED_DEFAULT_ROLES";

export const PULL_LEGACY_ROLES = "PULL_LEGACY_ROLES";
export const SET_PULLED_LEGACY_ROLES = "SET_PULLED_LEGACY_ROLES";
export const CLEAR_PULLED_LEGACY_ROLES = "CLEAR_PULLED_LEGACY_ROLES";

export const getRoles = () => ({ type: GET_ROLES });
export const setRoles = (roles) => ({ type: SET_ROLES, roles });
export const persistRole = (role) => ({ type: PERSIST_ROLE, role });
export const persistRoleDone = (success) => ({ type: PERSIST_ROLE_DONE, success });
export const getRoleById = (id) => ({ type: GET_ROLE_BY_ID, id });
export const setRole = (role) => ({ type: SET_ROLE, role });
export const clearRoles = () => ({ type: CLEAR_ROLES });
export const clearRole = () => ({ type: CLEAR_ROLE });
export const deleteRole = (id) => ({ type: DELETE_ROLE, id });
export const deleteRoleDone = () => ({ type: DELETE_ROLE_DONE });
export const findUsersByRole = (id) => ({ type: FIND_USERS_BY_ROLE, id });
export const setFindByRole = (users) => ({ type: SET_FIND_USERS_BY_ROLE, users });
export const addRoleItem = (role) => ({ type: ADD_ROLE_ITEM, role });
export const removeRoleItem = (role) => ({ type: REMOVE_ROLE_ITEM, role });

export const allUsersByRole = (roleId) => ({ type: ALL_USERS_BY_ROLE, roleId });
export const allUsersByRoleDone = (roleUsers) => ({
  type: ALL_USERS_BY_ROLE_DONE,
  roleUsers,
});

export const loadLegacyApps = () => {
  return { type: LOAD_LEGACY_APPS }
}
export const setLegacyApps = (legacyApps) => {
  return { type: SET_LEGACY_APPS, data: legacyApps }
}

export const loadLegacyRoles = (payload) => {
  return { type: LOAD_LEGACY_ROLES, data: payload }
}
export const setLegacyRoles = (legacyRoles) => {
  return { type: SET_LEGACY_ROLES, data: legacyRoles }
}

export const updateUnikUsers = (roleUsers) => ({
  type: UPDATE_UNIK_USERS,
  roleUsers,
});
export const updateUnikUsersDone = () => ({ type: UPDATE_UNIK_USERS_DONE });

export const findDefaultRoles = () => ({ type: FIND_DEFAULT_ROLES });
export const setDefaultRoles = (defaultRoles) => ({
  type: SET_DEFAULT_ROLES,
  defaultRoles,
});
export const addDefaultRole = (roleId) => ({
  type: ADD_DEFAULT_ROLE,
  roleId,
});
export const deleteDefaultRole = (roleId) => ({
  type: DELETE_DEFAULT_ROLE,
  roleId,
});
export const setUpdatedDefaultRoles = (updated) => ({
  type: SET_UPDATED_DEFAULT_ROLES,
  updated,
});
