import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../assets/scss/_input.scss";
import { withNamespaces } from "react-i18next";

const BenefInput = ({
  inputValue,
  setInputValue,
  field,
  type,
  submitOnChange,
  handleSubmitWrapper,
  t,
}) => {
  const [textInputValue, setTextInputValue] = useState(inputValue);
  const [required, setRequired] = useState(false);


  useEffect(() => {
    if (field) {
      if (field.value) {
        setTextInputValue(field.value);
      }
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
    }
  }, [field]);

  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      style={{ margin: "0px" }}
    >
      <div className="easypay-input-wrapper-wrapper">
        <div
          className={`easypay-input-wrapper ${
            field.maxlength && "easypay-input-size-" + field.maxlength
          }  `}
          tabIndex="1"
        >
          <div className="easypay-input-label">{`${
            submitOnChange
              ? t(field.text.split("::")[0])
              : t(field.text.split("::")[0])
          }`}</div>
          <div
            className={`easypay-input-field-wrapper ${
              field.disabled ? "disabled" : textInputValue ? `easypay-input-filled` : ""
            }`}
          >
            <input
              className={`easypay-input-field`}
              type={type && type}
              name={field.variable}
              value={textInputValue}
              maxLength={field.maxlength}
              onChange={(e) => {
                setTextInputValue(e.target.value);
              }}
              required={required}
              onBlur={(e) => {
                if (textInputValue && submitOnChange) {
                  handleSubmitWrapper(e, {
                    id: "5",
                    name: "_guardar",
                    operation: "disableForm(); document.dados.op.value=2;",
                    text: "Continuar mais tarde",
                    tooltip: "Save",
                  });
                }
              }}
              autoComplete={type === "password" ? "new-password" : "off"}
              disabled={field.disabled}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {`O campo ${
              field.text.split("::")[0]
            } é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </div>
      </div>
    </Form.Group>
  );
};
export default withNamespaces()(BenefInput);
