import {
  SET_REPORT_PERFORMANCE,
  FIND_REPORT_PERFORMANCE,
  SET_REPORT_SLA,
  FIND_REPORT_SLA,
  FIND_REPORT_PROCESS_STATISTICS,
  SET_REPORT_PROCESS_STATISTICS,
  FIND_REPORT_PROCESSES_STATUS,
  SET_REPORT_PROCESSES_STATUS
} from './actionTypes';

const initialState = {
  reportPerformance: [],
  isLoadingReportPerformance: false,

  reportSla: [],
  isLoadingReportSla: false,

  reportProcessStatistics: [],
  isLoadingReportProcessStatistics: false,

  reportProcessesStatus: [],
  isLoadingReportProcessesStatus: false
}

export function iflowReportsReducer(state = initialState, action) {

  const mergeReport = (action, reportName) => {
    const id = action?.data?.id;
    let resultReport = [...state[reportName]]
    if (id) {
      const report = action?.data?.report || []
      if (!resultReport.some(item => item.id === id)) {
        resultReport.push({
          id: id,
          report: report
        })
      } else {
        resultReport.forEach(item => {
          if (item.id === id) {
            item.report = report
          }
        })
      }
    }
    return resultReport
  };

  switch (action.type) {
    case FIND_REPORT_PERFORMANCE:
      return { ...state, isLoadingReportPerformance: true };
    case SET_REPORT_PERFORMANCE:
      const reportPerformance_ = mergeReport(action, "reportPerformance");
      return { ...state, reportPerformance: reportPerformance_, isLoadingReportPerformance: false };
    case FIND_REPORT_SLA:
      return { ...state, isLoadingReportSla: true };
    case SET_REPORT_SLA:
      const reportSla_ = mergeReport(action, "reportSla");
      return { ...state, reportSla: reportSla_, isLoadingReportSla: false };
    case FIND_REPORT_PROCESS_STATISTICS:
      return { ...state, isLoadingReportProcessStatistics: true };
    case SET_REPORT_PROCESS_STATISTICS:
      const reportProcessStatistics_ = mergeReport(action, "reportProcessStatistics");
      return { ...state, reportProcessStatistics: reportProcessStatistics_, isLoadingReportProcessStatistics: false };
    case FIND_REPORT_PROCESSES_STATUS:
      return { ...state, isLoadingReportProcessesStatus: true };
    case SET_REPORT_PROCESSES_STATUS:
      const reportProcessesStatus_ = mergeReport(action, "reportProcessesStatus");
      return { ...state, reportProcessesStatus: reportProcessesStatus_, isLoadingReportProcessesStatus: false };
    default:
      return state
  }
}
