import React from "react";
import logo from "~/assets/img/logos/acores.png";

import "~/assets/scss/_loginNonDefault.scss";

const DrpaFooter = () => {
  return (
    <div className="drpa-footer-wrapper">
      <div className="drpa-footer">
        <div className="logo-img-wrapper">
          <img className="logo-img" src={logo} alt="governo dos açores logo" />
        </div>
        <div className="logo-text">
          Governo dos <b>Açores</b>
        </div>
      </div>
      <div className="subtext">
        Esta é uma presença online oficial do{" "}
        <a
          className="subtext-link"
          href="https://portal.azores.gov.pt/"
          target="_blank"
        >
          Governo dos Açores
        </a>
        <p>Secretaria Regional do Mar e das Pescas</p>
      </div>
    </div>
  );
};

export default DrpaFooter;
