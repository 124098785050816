import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { find } from "lodash";

import Tasks from '~/components/Tasks';
import Processes from '~/components/Processes';
import ProtectedPage from '~/components/security/ProtectedPage';
import ProcessosIframe from '~/components/ProcessosIframe';
import WidgetContainer from '~/components/WidgetContainer';

import ApplicationMenu from '~containers/Menus/components/ApplicationMenu';
import { BPM_MIDDLEWARE, GAI_APP } from '~/utils/constants';

import {
  getMenusByApplication,
  addItemsToMenu
} from '~/store/ducks/applicationMenu/actionTypes';

const GAI = (props) => {
  const { isMongoOn } = props;
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.globalReducer);
  const { rawMenus, menu } = useSelector(state => state.applicationMenuReducer);

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(item => item.legacyApplication.label === BPM_MIDDLEWARE.context)
      let username = userBPM ? userBPM.username : null;
      dispatch(getMenusByApplication(username, GAI_APP))
    }
  }, [user, dispatch]);

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.gai.processos" });
    const items = rawMenus.map(item =>
      ({
        name: item.label,
        url: item.url,
        route: `${GAI_APP}/processos/form/${item.label}`,
        menuParent: processosMenu ? processosMenu.id : null
      })
    )
    dispatch(addItemsToMenu(items));
  }, [rawMenus, menu, dispatch]);

  return (
    <>
      <ApplicationMenu applicationName={GAI_APP} />
      <Switch>
        <Route path={`${path}/tarefas`} exact>
          <ProtectedPage roles={["ADMIN", "GAIUser"]}>
            <Tasks app={"gai"} />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/pesquisar`} exact>
          <ProtectedPage roles={["ADMIN", "GAIUser"]}>
            <Processes app={"gai"} />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`} >
          <ProtectedPage roles={["ADMIN", "GAIUser"]}>
            <ProcessosIframe />
          </ProtectedPage>
        </Route>
        <Route path={path} exact>
          {isMongoOn && <ProtectedPage roles={["ADMIN", "GAIUser"]}>
            <WidgetContainer />
          </ProtectedPage>
          }</Route>
      </Switch >
    </>
  )
}
export default GAI
