import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { msalInstance } from "~/utils/msalOAuth";
import { loginRequest } from "~/utils/msalOAuth/AuthConfig";
import oauth from "~/utils/oauth";
import "./account.scss";

const AccountComponent = () => {
  const [account, setAccount] = useState(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const account_ = oauth.get("MSAL_USERNAME");
    if (account_) setAccount(account_);
  }, [isLoggedIn]);

  const handleLogin = () => {
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        if (response !== null) {
          const username = response.account.username;
          if (username) {
            oauth.set(username, "MSAL_USERNAME", true);
            setIsLoggedIn(true);
          }
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts === null) {
            return;
          } else if (currentAccounts.length > 1) {
            // Add choose account code here
            console.warn("Multiple accounts detected.");
          } else if (currentAccounts.length === 1) {
            const username = currentAccounts[0].username;
            if (username) {
              oauth.set(username, "MSAL_USERNAME", true);
              setIsLoggedIn(true);
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-gear mr-2" />
            Accounts
          </h6>
        </Card.Header>
        <Card.Body>
          {account ? (
            `Welcome ${account}`
          ) : (
            <button className="btn-microsoft" onClick={() => handleLogin()}>
              <div style={{ display: "flex" }}>
                <object
                  type="image/svg+xml"
                  data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                  className="x-icon"
                ></object>
                Microsoft
              </div>
            </button>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(AccountComponent);
