import React, { useState } from 'react';
import oauth from '../utils/oauth';

const AllIframesComponent = ({ name }) => {

        const [menu, setMenu] = useState("tasks");

        const style = {
                frameborder: "0px",
                width: "100%",
                border: "none",
                //display: "none",
                height: 'auto',
                minHeight: '92vh',
        }
        const stylenew = {
                frameborder: "0px",
                width: "100%",
                border: "none",
                //display: "none",
                height: 'auto',
                minHeight: '80vh',
                padding: '1rem 2rem',
                margin: '1rem',
                borderRadius: "20px",
                backgroundColor: '#fff',
                boxShadow: "0px 4px 30px #6d6d6d26"
        }
        const token = oauth.getAccessToken();
        const authtoken = "&Authorization=" + token;

        let url;
        if (name === "gdpr")
                url = "/GDPR/main.jsp?uniktheme=uniksystem&app=" + name + "&menu=" + menu + authtoken;
        else if (name === "bpm")
                url = "/iFlow/main.jsp?uniktheme=uniksystem&app=" + name + "&menu=" + menu + authtoken;
        else if (name === "compliance")
                url = "/Compliance/main.jsp?uniktheme=uniksystem&app=" + name + "&menu=" + menu + authtoken;
        else if (name === "gai")
                url = "/GAI/main.jsp?uniktheme=uniksystem&app=" + name + "&menu=" + menu + authtoken;
        else if (name === "rh")
                url = "/portal/index.php?authtoken=" + token;
        else if (name === "portalrh")
                url = "/portal/index.php?authtoken=" + token;
        //url = "http://faiaiflow.alra.local:80/portal/index.php?authtoken=" + token;//for Alraa Client
        //url = "http://faiaudw.alra.local:80/portal/index.php?authtoken=" + token;//for Alraa Client

        return (
                <iframe id={name} src={url} style={name == "portalrh" ? stylenew : style} title="app" />
        )
}
export default AllIframesComponent