import { takeLatest } from "redux-saga/effects";

import {
  UPDATE_ANNOTATION,
  FIND_COMMENT_HISTORY,
} from "../ducks/processAnnotations/actionTypes";

import {
  updateAnnotation,
  findCommentHistory,
} from "../ducks/processAnnotations/sagas";

export const processAnnotationsSagas = [
  takeLatest(UPDATE_ANNOTATION, updateAnnotation),
  takeLatest(FIND_COMMENT_HISTORY, findCommentHistory),
];
