import { isEmpty } from "lodash";
import React, { useEffect, useState } from 'react';
import { Col, Form, Toast } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteBaseWidget, getBaseWidgets, updateBaseWidget
} from '../actions';


const devLogConsole = require("~/utils/devLog");
const BaseWidgetsForm = (props) => {

  const { t } = props;
  const { baseWidgets, isUpdated } = useSelector(state => state.adminConfigReducer);
  let widget = undefined;
  if (Array.isArray(baseWidgets) && baseWidgets.length > 0) {
    widget = baseWidgets.find(item => item.component === 'ServicoGenericoWidget');
}
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [selectedBaseWidget, setSelectedBaseWidget] = useState(undefined);

  useEffect(() => {
    dispatch(getBaseWidgets());
  }, [dispatch, isUpdated]);

  const editBaseWidget = (item) => {
    setShow(true);
    setSelectedBaseWidget(item);
  };

  const handleDeleteBaseWidget = (item) => {
    const payload = item.id
    dispatch(deleteBaseWidget(payload))
    setShow(false);
  };

  const toggleShow = () => setShow(!show);

  const toggleSelectedItemAdminConfig = () => {
    setSelectedBaseWidget({
      ...selectedBaseWidget,
      adminConfigNeeded: !selectedBaseWidget.adminConfigNeeded
    });
  };

  const toggleSelectedItemReady = () => {
    setSelectedBaseWidget({
      ...selectedBaseWidget,
      production: !selectedBaseWidget.production
    });
  };
devLogConsole(widget)
  const saveBaseWidget = () => {
    const payload = {
      id: selectedBaseWidget.id,
      component: selectedBaseWidget.component,
      adminConfigNeeded: selectedBaseWidget.adminConfigNeeded,
      production: selectedBaseWidget.production,
      idToRemove: "",
      configurations: widget != undefined ? widget.configurations : [],
    }
    dispatch(updateBaseWidget(payload))
    setShow(false);
    setSelectedBaseWidget(undefined);
  };

  return (
    <>
      <Col sm={6}>
        <br />
        <div style={{ display: "flex" }}>
          <table>
            <tbody>
              {
                !isEmpty(baseWidgets) && baseWidgets.map((item, index) =>
                  <tr key={index}>
                    <td style={{ paddingRight: "10px" }}>{item.component}</td>
                    <td> <i className="icon-edit table-action-icon" onClick={() => editBaseWidget(item)} /></td>
                    <td> <i className="icon-trash table-action-icon" onClick={() => handleDeleteBaseWidget(item)} /></td>
                  </tr>
                )
              }
            </tbody>
          </table>
          <Toast show={show} onClose={toggleShow} style={{ marginLeft: "20px" }}>
            <Toast.Header>
            </Toast.Header>
            <Toast.Body>
              <input type="text"
                placeholder="widget file name(without .js)"
                onChange={e => {
                  setSelectedBaseWidget({
                    ...selectedBaseWidget,
                    component: e.target.value
                  })
                }}
                value={selectedBaseWidget ? selectedBaseWidget.component : ""} />
              <Form.Check
                type="switch"
                id="editProdReady"
                label={t('SystemSettings.productionReady')}
                onChange={() => toggleSelectedItemReady()}
                checked={selectedBaseWidget ? selectedBaseWidget.production : false}
              />
              <Form.Check
                type="switch"
                id="editIsAdminConfig"
                label={t('SystemSettings.adminConfigNeeded')}
                onChange={() => toggleSelectedItemAdminConfig()}
                checked={selectedBaseWidget ? selectedBaseWidget.adminConfigNeeded : false}
              />
              <button onClick={() => saveBaseWidget()}>{t('general.save')}</button>
            </Toast.Body>
          </Toast>
        </div>
      </Col>
    </>
  )

}

export default withNamespaces()(BaseWidgetsForm);
