import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { getDocsDisclaimers } from "~/store/ducks/portalrh/actionTypes";

const DisclaimerModal = (props) => {
  const {
    showModal,
    closeModal,
    handleUpdateDocument,
    t,
  } = props;

  const dispatch = useDispatch();

  const { docsDisclaimers, isLoadingDocsDisclaimers } = useSelector((state) => state.portalrhReducer);

  useEffect(() => {
    dispatch(getDocsDisclaimers());
  }, []);

  return (
    <>
      {isLoadingDocsDisclaimers ? (
        <Spinner spinning={'true'} />
      ) : (
        <Modal show={showModal} onHide={() => { closeModal() }} size="md">
          <Modal.Header closeButton>
            <Modal.Title className="default-modal-title">{t(`portalrh.docsDisclaimer.title`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Label style={{ marginTop: "7px" }} className="default-label">
                {(docsDisclaimers[0]) ? docsDisclaimers[0].text : ""}
              </Form.Label>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="btn" onClick={() => { closeModal() }}>
              {t("general.cancel")}
            </Button>
            <Button className="card-button" onClick={() => { handleUpdateDocument(); closeModal() }}>
              {t("general.accept")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default withNamespaces()(DisclaimerModal);