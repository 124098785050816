import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import CatalogoTable from "./CatalogoTable";
import { getCatalogoFormacao } from "store/ducks/processes/actionTypes";

const RHCatalogoFormacao = ({ t }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCatalogoFormacao());
  }, []);

  return (
    <div className="main-card-v2" style={{ height: "fit-content" }}>
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>{t("portalrh.rhadmin.formacoes.catalogoTitle")}</h6>
        </Card.Header>
        <Card.Body>
          <CatalogoTable />
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(RHCatalogoFormacao);
