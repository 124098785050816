import {
  SET_UDW_SETTINGS,
  SET_UDW_USERS,
  SET_SSO_INFO,
  SET_LEGACY_USERS,
  GET_EMAIL_TEMPLATES,
  SET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE_BY_ID,
  SET_EMAIL_TEMPLATE_BY_ID,
  COUNT_EMAIL_TEMPLATES,
  SET_COUNT_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  STOP_EDITING_EMAIL_TEMPLATES,
  SEND_EMAIL
} from "./actionTypes";

const initialState = {
  udwSettings: null,
  udwUsers: [],
  legacyusers: [],

  ssoInfo: {},

  emailTemplates: [],
  isLoadingEmailTemplates: false,

  emailTemplate: null,
  isLoadingEmailTemplate: false,

  nEmailTemplates: 0,
  isLoadingNEmailTemplates: false,

  editingEmailTemplates: false,
};
const devLogConsole = require("~/utils/devLog");
export const udwReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UDW_SETTINGS:
      return { ...state, udwSettings: action.udwSettings };
    case SET_UDW_USERS:
      return { ...state, udwUsers: action.udwUsers };
    case SET_SSO_INFO:
      devLogConsole(action)
      return { ...state, ssoInfo: action.ssoInfo };
    case SET_LEGACY_USERS:
      return { ...state, legacyUsers: action.legacyUsers };
    case GET_EMAIL_TEMPLATES:
      return { ...state, isLoadingEmailTemplates: true };
    case SET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.emailTemplates,
        isLoadingEmailTemplates: false,
      };
    case GET_EMAIL_TEMPLATE_BY_ID:
      return { ...state, isLoadingEmailTemplate: true };
    case SET_EMAIL_TEMPLATE_BY_ID:
      return {
        ...state,
        emailTemplate: action.emailTemplate,
        isLoadingEmailTemplate: false,
      };
    case COUNT_EMAIL_TEMPLATES:
      return { ...state, isLoadingNEmailTemplates: true };
    case SET_COUNT_EMAIL_TEMPLATES:
      return {
        ...state,
        nEmailTemplates: action.nEmailTemplates,
        isLoadingNEmailTemplates: false,
      };
    case CREATE_EMAIL_TEMPLATE:
      return { ...state, editingEmailTemplates: true };
    case UPDATE_EMAIL_TEMPLATE:
      return { ...state, editingEmailTemplates: true };
    case DELETE_EMAIL_TEMPLATE:
      return { ...state, editingEmailTemplates: true };
    case STOP_EDITING_EMAIL_TEMPLATES:
      return { ...state, editingEmailTemplates: false };
    case SEND_EMAIL:
      return { ...state };

    default:
      return state;
  }
};
