import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import PTFlag from "~/pages/Easypay/assets/img/icons/flags/PT.png";
import iconWarning from "~/pages/Easypay/assets/img/icons/icon-alert.png";
import "../assets/css/styles.css";
import "../assets/scss/_backOfficeClientSection.scss";
import { useDispatch, useSelector } from "react-redux";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import Input from "./Input";
import Dropdown from "./InputDropdown";
import Link from "./Link";
import RadioButtonItem from "./RadioButtonItem";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import Text from "./Text";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import {
  savePrecario,
  getPrecario,
  setSavePrecarioState,
  setDetailFile,
  setFileScreen,
} from "~/store/ducks/onboarding/actionTypes";
import { useHistory } from "react-router-dom";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import ButtonUpload from "./ButtonUpload";
import {
  saveDetailFile,
  getDetailFile,
} from "~/store/ducks/onboarding/actionTypes";
const devLogConsole = require("~/utils/devLog");
const BackOfficeClientSection = ({ t, data }) => {
  const organizationTypeList = [
    { label: "Empresa", value: "Empresa", selected: true },
    { label: "Outro", value: "Outro", selected: false },
    { label: "ENI", value: "ENI", selected: false },
  ];

  const mobileList = [
    { label: "PT(+351)", value: "PT(+351)", selected: true },
    { label: "DE(+050)", value: "DE(+050)", selected: false },
    { label: "ES(+092)", value: "ES(+092)", selected: false },
  ];

  function handleSelectChange(e) {
    if (e === "ENI") {
      data.onboardingEntity.tipoEntidade = "eni";
    } else if (e === "Outro") {
      data.onboardingEntity.tipoEntidade = "outro";
    } else if (e === "Empresa") {
      data.onboardingEntity.tipoEntidade = "empresas";
    }
  }

  const history = useHistory();

  const [isModalSaveShowing, setIsModalSaveShowing] = useState(false);
  const [isModalSaveErrorShowing, setIsModalSaveErrorShowing] = useState(false);

  const [isModalDocShowing, setIsModalDocShowing] = useState(false);
  const [isModalDocErrorShowing, setIsModalDocErrorShowing] = useState(false);

  const [mainQuestions, setMainQuestions] = useState([]);
  const [mainAnswers, setMainAnswers] = useState([]);

  const { precario, savePrecarioResult, detailFile, screen } = useSelector(
    (state) => state.onboardingReducer
  );

  const dispatch = useDispatch();

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  useEffect(() => {
    if (data?.client?.pid) {
      dispatch(getPrecario(data?.client?.pid));
    }
  }, [data, precario === null]);

  const updateUser = (value, variable) => {
    const variableName = variable?.split("-");

    if (variable.includes("dataValidade")) {
      if (value.length === 10) {
        var parsedDate = Date.parse(value);
        if (isNaN(value) && !isNaN(parsedDate)) {
          if (variableName.length === 2) {
            data[variableName[0]][variableName[1]] = parsedDate;
          }
        }
      }
    } else if (variableName.length === 2) {
      data[variableName[0]][variableName[1]] = value;
    }
  };

  useEffect(() => {
    data?.onboardingEntity?.tipoEntidade &&
      setActiveAccountType(data?.onboardingEntity?.tipoEntidade);
      formatRiskEval()
  }, [data]);

  useEffect(() => {
    if (detailFile != null && detailFile != undefined && screen === "CLIENT") {
      if (detailFile?.length === 0) {
        dispatch(setDetailFile(null));
        dispatch(setFileScreen(null));
        setIsModalDocErrorShowing(true);
        return;
      }
      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${detailFile[0].file}`;
      a.download = fileName + ".pdf";
      a.click();
      dispatch(setDetailFile(null));
      dispatch(setFileScreen(null));
    }
  }, [detailFile, screen]);

  useEffect(() => {
    if (data?.client?.pid) {
      dispatch(getPrecario(data?.client?.pid));
    }
  }, [data, precario === null]);

  useEffect(() => {
    if (precario != null && precario?.length > 0) {
      setDropDisabled(true);
    }
  }, [precario]);

  useEffect(() => {
    if (savePrecarioResult != null) {
      if (savePrecarioResult === "success") {
        setIsModalSaveShowing(true);
        history.push({
          pathname: `/uniksystem/onboarding/backoffic-organization/precario`,
          precarioPid: data?.client?.pid,
        });
      } else {
        setIsModalSaveErrorShowing(true);
      }
      dispatch(setSavePrecarioState(null));
    }
  }, [savePrecarioResult]);

  const [activeAccountType, setActiveAccountType] = useState("");
  const [dropDisabled, setDropDisabled] = useState(false);
  const [fileName, setFileName] = useState("Details");

  function getAccountTypeValue() {
    if (data?.onboardingEntity?.tipoEntidade === "eni") {
      return "ENI";
    } else if (data?.onboardingEntity?.tipoEntidade === "outro") {
      return "Outro";
    } else if (data?.onboardingEntity?.tipoEntidade === "empresas") {
      return "Empresa";
    }
  }

  const accountTypeList = [
    { label: "Ocasional", value: "Ocasional", selected: false },
    { label: "Demo", value: "Demo", selected: false },
    { label: "Pagamentos", value: "Pagamentos", selected: false },
  ];

  function createPrecario() {
    setDropDisabled(true);
    dispatch(
      savePrecario({
        pid: data?.client?.pid?.toString(),
        tipoConta: data?.account?.tipoConta,
      })
    );
  }

  function goToPrecario() {
    history.push({
      pathname: `/uniksystem/onboarding/backoffic-organization/precario`,
      precarioPid: data?.client?.pid,
    });
  }

  function createBodySuccess() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Preçário criado" status="success" />
      </div>
    );
  }

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Erro ao criar preçário!" status="error" />
      </div>
    );
  }

  function createBodyDocSuccess() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Documento guardado" status="success" />
      </div>
    );
  }

  function createBodyDocError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Erro ao baixar documento!" status="error" />
      </div>
    );
  }

  const inputFile = useRef(null);
  const [currentBase64, setCurrentBase64] = useState("");
  var currentId = -1;
  function onButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  function getBase64(file) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setCurrentBase64(base64String);
      const payload = {
        idProcesso: data?.client?.pid,
        idUserLegal: data?.client?.idCliente,
        file: base64String,
        type: "dnb",
      };
      dispatch(saveDetailFile(payload));
      setIsModalDocShowing(true);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];

    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";

    if (
      !(
        type.includes("pdf") ||
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      )
    ) {
      //setIsModalShowing(true)
      return;
    }

    getBase64(currentFile);
  };

  function downloadFile() {
    setFileName("Details");
    const payload = {
      idProcesso: data?.client?.pid,
      idUserLegal: data?.client?.idCliente,
      type: "CLIENT",
      docType: "dnb",
    };
    dispatch(getDetailFile(payload));
  }

  function downloadCRCIES(docTypeText) {
    if (docTypeText === "crc") {
      setFileName("CRC Doc");
    } else {
      setFileName("IES Doc");
    }
    const payload = {
      idProcesso: data?.client?.pid,
      idUserLegal: data?.client?.idCliente,
      type: "CLIENT",
      docType: docTypeText,
    };
    dispatch(getDetailFile(payload));
  }

  function formatRiskEval() {
    var questionArray = [];
    var answerArray = [];
    var currentHigh = 0;
    var currentAnswer = "";
    var currentQuestion = "";

    data.riskEvaluation &&
      Object.keys(data.riskEvaluation).length !== 0 &&
      data.riskEvaluation.map((item, index) => {
        if (currentQuestion != item.question) {
          if (currentQuestion != "") {
            questionArray.push(currentQuestion);
            answerArray.push(currentAnswer + " => " + currentHigh);
          }

          currentQuestion = item.question;
          currentHigh = item.riskLevel;
          currentAnswer = item.answer;
        } else {
          currentAnswer = currentAnswer + ", " + item.answer;
          if (currentHigh < item.riskLevel) {
            currentHigh = item.riskLevel;
          }
        }
      });

    setMainAnswers(answerArray);
    setMainQuestions(questionArray);
    /*questionArray &&
      Object.keys(questionArray).length !== 0 &&
      questionArray.map((item, index) => {
        console.log(item);
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="easypay-business-text-title">{item}</div>
            <div className="easypay-business-text-light">
              {questionArray[index].includes("zz$$")
                ? questionArray[index].replace("zz$$", "")
                : questionArray[index]}
            </div>
          </div>
        );
      });*/
  }

  return (
    <div className="easypay-backoffice-account-sections-wrapper">
      <Modal
        className="easypay-modal"
        showModal={isModalSaveShowing}
        setShowModal={setIsModalSaveShowing}
        headerContent={"Criar preçário"}
        bodyContent={createBodySuccess()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalSaveErrorShowing}
        setShowModal={setIsModalSaveErrorShowing}
        headerContent={"Criar preçário"}
        bodyContent={createBodyError()}
        key={uuid()}
      ></Modal>

      <Modal
        className="easypay-modal"
        showModal={isModalDocShowing}
        setShowModal={setIsModalDocShowing}
        headerContent={"Upload documento"}
        bodyContent={createBodyDocSuccess()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalDocErrorShowing}
        setShowModal={setIsModalDocErrorShowing}
        headerContent={"Baixar documento"}
        bodyContent={createBodyDocError()}
        key={uuid()}
      ></Modal>
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.verticalTabClient`)}
      </div>
      <div className="easypay-backoffice-account-section-account-type-wrapper">
        <div className="easypay-backoffice-client-blue-header">
          {t(`bpm.onboarding.backoffice.user.labelContactData`)}
        </div>
      </div>
      <div className="easypay-backoffice-client-section-input-wrapper">
        <Input
          field={buildInputField(
            t(`bpm.onboarding.backoffice.user.labelName`),
            data?.client?.nome
          )}
          name="client-nome"
          onChange={updateUser}
        />
      </div>
      <div className="easypay-backoffice-client-section-columns-wrapper">
        <div className="easypay-backoffice-client-section-column-wrapper">
          <div className="easypay-backoffice-client-section-small-input-wrapper">
            <Dropdown
              text={t(`bpm.onboarding.backoffice.user.labelCountry`)}
              required={true}
              placeholder="N/a"
              list={mobileList}
              handleSubmit={(e) => handleSelectChange(e)}
            />
          </div>
          <div className="easypay-backoffice-client-section-small-input-wrapper">
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelMobile`),
                data?.client?.numTelemovel
              )}
              name="client-numTelemovel"
              onChange={updateUser}
            />
          </div>
        </div>
        <div className="easypay-backoffice-client-section-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerEmail`),
              data?.client?.email
            )}
            name="client-email"
            onChange={updateUser}
          />
        </div>
      </div>
      <div className="easypay-backoffice-client-blue-header">
        {t(`bpm.onboarding.backoffice.user.tabOrganization`)}
      </div>
      <div className="easypay-backoffice-client-section-columns-wrapper">
        <div className="easypay-backoffice-client-section-small-input-wrapper">
          <Dropdown
            text={t(
              "bpm.onboarding.backoffice.administration.headerEntityType"
            )}
            required={true}
            placeholder="N/a"
            list={organizationTypeList}
            name="lead-drop-partner"
            isColor={false}
            initialValue={getAccountTypeValue()}
            handleSubmit={(e) => handleSelectChange(e)}
          />
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelNIPC`) + "::disabled",
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? data?.client?.pvNif
                : data?.onboardingEntity?.nipc
            )}
            onChange={updateUser}
          />
          {data?.onboardingEntity?.tipoEntidade != "eni" && (
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelFormaObg`) +
                  "::disabled",
                data?.onboardingEntity?.formaObrigar
              )}
              name="onboardingEntity-formaObrigar"
              onChange={updateUser}
            />
          )}
        </div>
        <div className="easypay-backoffice-client-section-small-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              data?.onboardingEntity?.nomeEntidade
            )}
            name="onboardingEntity-nomeEntidade"
            onChange={updateUser}
          />
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelCAE`),
              data?.onboardingEntity?.cae
            )}
            onChange={updateUser}
          />
          <div className="easypay-backoffice-client-section-columns-wrapper">
            <div className="easypay-backoffice-client-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  data?.onboardingEntity?.tipoEntidade === "eni"
                    ? t(
                        `bpm.onboarding.backoffice.user.labelDeclarationActivity`
                      )
                    : t(`bpm.onboarding.backoffice.user.labelCertificateCod`) +
                        "::disabled",
                  data?.onboardingEntity?.cprc
                )}
                name="onboardingEntity-cprc"
                onChange={updateUser}
              />
            </div>
            <div className="easypay-backoffice-client-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  data?.onboardingEntity?.tipoEntidade === "eni"
                    ? t(`bpm.onboarding.backoffice.user.labelEmissionDate`)
                    : t(`bpm.onboarding.backoffice.user.labelValidity`) +
                        "::disabled",
                  data?.onboardingEntity?.dataValidade?.toString()?.length > 10
                    ? data?.onboardingEntity?.dataValidade
                        .toString()
                        .slice(0, 10)
                    : data?.onboardingEntity?.dataValidade
                )}
                name="onboardingEntity-dataValidade"
                onChange={updateUser}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="easypay-backoffice-client-blue-header">
        {t(`bpm.onboarding.backoffice.user.adressTitle`)}
      </div>
      <div className="easypay-backoffice-client-small-wrapper">
        <div className="easypay-backoffice-client-section-small-header">
          {t(`bpm.onboarding.backoffice.administration.headerHostCountry`)}
        </div>
        <div className="easypay-backoffice-country-falag-text-wrapper">
          <img src={PTFlag} />
          <div className="easypay-backoffice-host-country-text">
            {data?.onboardingEntity?.pais}
          </div>
        </div>
      </div>
      {/*<div className="easypay-backoffice-link-wrapper">
        <Link
          text={t(`bpm.onboarding.backoffice.administration.linkChange`)}
          link=""
        />
      </div>*/}
      <div className="easypay-backoffice-account-section-zipcode-locality-wrapper">
        <div className="easypay-backoffice-account-section-small-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerZipCode`),
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? data?.client?.pvCodigoPostal
                : data?.onboardingEntity?.codPostal
            )}
            name={
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? "client-pvCodigoPostal"
                : "onboardingEntity-codPostal"
            }
            onChange={updateUser}
          />
        </div>
        <div className="easypay-backoffice-account-section-small-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerLocality`),
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? data?.client?.pvLocalidade
                : data?.onboardingEntity?.localidade
            )}
            name={
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? "client-pvLocalidade"
                : "onboardingEntity-localidade"
            }
            onChange={updateUser}
          />
        </div>
      </div>
      <div className="easypay-backoffice-account-section-addresses-wrapper">
        <div className="easypay-backoffice-account-section-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerAddress-1`),
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? data?.client?.morada
                : data?.onboardingEntity?.moradaSede
            )}
            name={
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? "client-morada"
                : "onboardingEntity-moradaSede"
            }
            onChange={updateUser}
          />
        </div>
        <div className="easypay-backoffice-account-section-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerAddress-2`),
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? data?.client?.morada
                : data?.onboardingEntity?.moradaSede
            )}
            name={
              data?.onboardingEntity?.tipoEntidade === "eni"
                ? "client-morada"
                : "onboardingEntity-moradaSede"
            }
            onChange={updateUser}
          />
        </div>
      </div>
      <div className="easypay-backoffice-client-blue-header">
        {t(`bpm.onboarding.backoffice.administration.businessButton`)}
      </div>
      <div className="easypay-backoffice-business-section-subheader-wrapper">
        <div className="easypay-text-icon">
          <img src={iconWarning} alt="Icon Check" />
        </div>
        <div className="easypay-backoffice-business-section-subheader-text">
          {t(
            `bpm.onboarding.backoffice.administration.subHeaderChangeBusinessData`
          )}
        </div>
      </div>
      <ProtectedComponent
        roles={["ADMIN", "ONBOARDINGUser", "ONBOARDINGCompliance"]}
      >
        <div>
          {mainQuestions &&
            Object.keys(mainQuestions).length !== 0 &&
            mainQuestions.map((item, index) => {

              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="easypay-business-text-title">{item}</div>
                  <div className="easypay-business-text-light">
                    {mainAnswers[index].includes("zz$$")
                      ? mainAnswers[index].replace("zz$$", "")
                      : mainAnswers[index]}
                  </div>
                </div>
              );
            })}
        </div>
      </ProtectedComponent>

      <Text text={"Criar Preçário::card-text-title"} />
      <Text
        text={
          "Ao criar um preçário, depois poderá ser acessado a partir da tab Preçários::card-text-light"
        }
      />
      {dropDisabled ? (
        <>
          <Text
            text={
              "Já existe um preçário associado a este processo!::card-text-green"
            }
            align={"left"}
          />
          <SimpleButton
            text={"Personalizar preçário"}
            variant={"easypay-button-primary"}
            onClick={() => goToPrecario()}
          />
        </>
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              paddingTop: "26px",
              paddingLeft: "15px",
            }}
          >
            <SimpleButton
              text={"Criar preçário"}
              variant={"easypay-button-primary"}
              onClick={() => createPrecario()}
            />
          </div>
        </div>
      )}
      <div style={{ paddingTop: "25px" }}></div>
      {/*
        <div>
          <Text text={"Detalhes do screening::card-text-title"} />

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <ButtonUpload
              text={t(`bpm.onboarding.backoffice.user.buttonUploadDocument`)}
              variant={"easypay-button-secondary-icon"}
              onclick={() => onButtonClick()}
              onChange={handleChange}
              key={uuid()}
              inputFile={inputFile}
              id={"id1"}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={false}
            />
            <div style={{ paddingLeft: "15px" }}>
              <SimpleButton
                text={"Baixar documento"}
                variant={"easypay-button-primary"}
                onClick={() => downloadFile()}
              />
            </div>
          </div>
        </div>
      */}

      {/*data?.onboardingEntity?.cprc != "" &&
        data?.onboardingEntity?.cprc != null &&
        data?.onboardingEntity?.cprc != undefined && false (
          <div style={{ paddingTop: "25px" }}>
            <Text text={"Detalhes CRC e IES::card-text-title"} />

            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <SimpleButton
                text={"Baixar CRC"}
                variant={"easypay-button-primary"}
                onClick={() => downloadCRCIES("crc")}
              />
              <div style={{ paddingLeft: "15px" }}>
                <SimpleButton
                  text={"Baixar IES"}
                  variant={"easypay-button-primary"}
                  onClick={() => downloadCRCIES("ies")}
                />
              </div>
            </div>
          </div>
        )*/}
      {/* <InputSearch
        field={{ text: "inputLabel" }}
        required={true}
        text={t(`bpm.onboarding.backoffice.administration.headerBusinessArea`)}
        placeholder="Área de Negócios"
        list={inputSearchList}
        handleSubmit={(value) => handleSelectChange(value)}
      />
      <div className="easypay-backoffice-business-section-payment-type-header">
        {t(`bpm.onboarding.backoffice.administration.headerPaymentType`)}
      </div>
      <div className="easypay-backoffice-business-section-main-checkbox-wrapper">
        <div className="easypay-backoffice-business-section-checkbox-wrapper">
          <Checkbox
            name="checkbox1"
            id="check1"
            value={"Multibanco"}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox2"
            id="check2"
            value={t(
              `bpm.onboarding.backoffice.administration.headerVISAMasterCard`
            )}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox3"
            id="check3"
            value={t(
              `bpm.onboarding.backoffice.administration.headerDirectDebit`
            )}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
        </div>
        <div className="easypay-backoffice-business-section-checkbox-wrapper">
          <Checkbox
            name="checkbox4"
            id="check4"
            value={t(
              `bpm.onboarding.backoffice.administration.headerBankTicket`
            )}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox5"
            id="check5"
            value="MBWay"
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox6"
            id="check6"
            value={t(`bpm.onboarding.backoffice.administration.headerIBAN`)}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
        </div>
      </div>
      <div className="easypay-backoffice-business-section-payment-type-header">
        {t(`bpm.onboarding.backoffice.administration.headerTransactionSize`)}
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={transactionVolume}
        setActivetab={setTransactionVolume}
      >
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.header50000`)}
          uniqueName={"backofficeRadioTransactionSize"}
          index={0}
          name={t(`bpm.onboarding.backoffice.administration.header50000`)}
          subHeader={""}
          checked
        />
        <RadioButtonItem
          key={uuid()}
          text={t(
            `bpm.onboarding.backoffice.administration.headerBetween50000`
          )}
          uniqueName={"backofficeRadioTransactionSize"}
          index={1}
          name={t(
            `bpm.onboarding.backoffice.administration.headerBetween50000`
          )}
          subHeader={""}
        />
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.headerAbove150000`)}
          index={2}
          uniqueName={"backofficeRadioTransactionSize"}
          name={t(`bpm.onboarding.backoffice.administration.headerAbove150000`)}
          subHeader={""}
        />
      </BenefModalRadioButtonWrapper>
      <div className="easypay-backoffice-business-section-payment-type-header">
        {t(`bpm.onboarding.backoffice.administration.headerProductType`)}
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={productType}
        setActivetab={setProductType}
      >
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.headerAssets`)}
          uniqueName={"backofficeRadioProductType"}
          index={0}
          name={t(`bpm.onboarding.backoffice.administration.headerAssets`)}
          subHeader={""}
          checked
        />
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.headerServices`)}
          uniqueName={"backofficeRadioProductType"}
          index={1}
          name={t(`bpm.onboarding.backoffice.administration.headerServices`)}
          subHeader={""}
        />
        <RadioButtonItem
          key={uuid()}
          text={t(
            `bpm.onboarding.backoffice.administration.headerAssetsAndServices`
          )}
          index={2}
          uniqueName={"backofficeRadioProductType"}
          name={t(
            `bpm.onboarding.backoffice.administration.headerAssetsAndServices`
          )}
          subHeader={""}
        />
      </BenefModalRadioButtonWrapper>
      <div className="easypay-backoffice-business-section-payment-type-header">
        5.Lorem ipsum dolor
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={lorem}
        setActivetab={setLorem}
      >
        <RadioButtonItem
          key={uuid()}
          text={"Opção 01"}
          uniqueName={"backofficeRadioLorem"}
          index={0}
          name={"Opção 01"}
          subHeader={""}
          checked
        />
        <RadioButtonItem
          key={uuid()}
          text={"Opção 02"}
          uniqueName={"backofficeRadioLorem"}
          index={1}
          name={"Opção 02"}
          subHeader={""}
        />
        <RadioButtonItem
          key={uuid()}
          text={"Opção 03"}
          index={2}
          uniqueName={"backofficeRadioLorem"}
          name={"Opção 03"}
          subHeader={""}
        />
      </BenefModalRadioButtonWrapper> */}
    </div>
  );
};
export default withNamespaces()(BackOfficeClientSection);
