import React from "react";
import uuid from "uuid/v1";
import "../assets/scss/_tableTextbox.scss";

function TableTextbox({value, position, color,number,textType,borderless}) {
  return (
      <div className={`liftworld-table-textbox-value-wrapper ${position} ${color} ${number} ${textType} ${borderless}`}>
        {value}
      </div>
  );
}

export default TableTextbox;
