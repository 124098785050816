export const GET_AUSENCIAS_BY_EMAIL = "webTime@GET_AUSENCIAS_BY_EMAIL";
export const SET_AUSENCIAS_BY_EMAIL = "webTime@SET_AUSENCIAS_BY_EMAIL";

export const GET_FERIAS_BY_EMAIL = "webTime@GET_FERIAS_BY_EMAIL";
export const SET_FERIAS_BY_EMAIL = "webTime@SET_FERIAS_BY_EMAIL";

export const GET_LOGINS_BY_USERNAME = "webTime@GET_LOGINS_BY_USERNAME";
export const SET_LOGINS_BY_USERNAME = "webTime@SET_LOGINS_BY_USERNAME";

export const GET_AUTORIZACOES_BY_USERNAME =
  "webTime@GET_AUTORIZACOES_BY_USERNAME";
export const SET_AUTORIZACOES_BY_USERNAME =
  "webTime@SET_AUTORIZACOES_BY_USERNAME";

export const getAusenciasByEmail = (username) => {
  return { type: GET_AUSENCIAS_BY_EMAIL, username };
};

export const setAusenciasByEmail = (data) => {
  return { type: SET_AUSENCIAS_BY_EMAIL, data };
};

export const getFeriasByEmail = (username) => {
  return { type: GET_FERIAS_BY_EMAIL, username };
};

export const setFeriasByEmail = (data) => {
  return { type: SET_FERIAS_BY_EMAIL, data };
};

export const getLoginsByUsername = (username, redirect, page, admin) => {
  return { type: GET_LOGINS_BY_USERNAME, username, redirect, page, admin };
};

export const setLoginsByUsername = (data) => {
  return { type: SET_LOGINS_BY_USERNAME, data };
};

export const getAutorizacoesByUsername = (username) => {
  return { type: GET_AUTORIZACOES_BY_USERNAME, username };
};

export const setAutorizacoesByUsername = (data) => {
  return { type: SET_AUTORIZACOES_BY_USERNAME, data };
};
