import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

const ButtonsCreateUpdate = ({
  isClicked,
  setIsClicked,
  setRowClicked,
  setIsNew,
  cleanInputs,
  t,
}) => {
  const handleButton = (event, newEntry) => {
    event.stopPropagation();
    event.preventDefault();
    setIsClicked(!isClicked);
    setIsNew(newEntry);
    if (newEntry) {
      cleanInputs();
      setRowClicked([]);
    }
  };
  return (
    <>
      {isClicked ? (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            type="submit"
            className="card-button"
            style={{ marginLeft: 15 }}
          >
            {t(`portalrh.NavBar.ButtonSave`)}
          </Button>

          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => {handleButton(e, false); cleanInputs()}}
          >
            {t(`portalrh.NavBar.ButtonCancel`)}
          </Button>
        </div>
      ) : (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => handleButton(e, true)}
          >
            {t(`portalrh.NavBar.ButtonNew`)}
          </Button>
          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => handleButton(e, false)}
          >
            {t(`portalrh.NavBar.ButtonChange`)}
          </Button>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(ButtonsCreateUpdate);
