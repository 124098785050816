import {
  SET_PROCESSES,
  SET_PROCESSES_HISTORY,
  SET_PROCESSES_COUNT,
  SET_PROCESSES_COUNT_TOTAL,
  SET_HISTORY,
  FIND_IFLOW_PROCESSES_BY_FILTERS,
  FIND_IFLOW_PROCESSES_HISTORY,
  FIND_IFLOW_PROCESSES_HISTORY_TASKS,
  SET_IFLOW_FORMDATA,
  SET_IFLOW_PID,
  GET_IFLOW_FORMDATA,
  POST_IFLOW_FORMDATA,
  POST_IFLOW_FORM,
  SET_IFLOW_DOCUMENTS,
  GET_IFLOW_DOCUMENT,
  SET_IFLOW_TRIGGER_LOADING,
  SET_SELECTED_TOSIGN,
  RESET_IFLOW_DOCUMENTS,
  SET_IFLOW_DONE,
  SET_IFLOW_METADATA_PREVIEW,
  GET_IFLOW_METADATA_PREVIEW,
  SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE,
  GET_BUTTONSAVE_TRIGGER,
  SET_BUTTONSAVE_TRIGGER,
  CLEAR_IFLOW_FORMDATA,
  SET_GREYED_OUT,
  GET_GREYED_OUT,
  POST_ADMISSAO_COLLAB,
  GET_COMPANIES_NEW_COLLAB,
  SET_COMPANIES_NEW_COLLAB,
  SET_ADMISSAO_COLLAB_MESSAGE,
  SET_ADMISSAO_COLLAB_MESSAGE_ALREADY_EXISTS,
  GET_TEMPLATES_LIST,
  SET_TEMPLATES_LIST,
  UPDATE_TEMPLATE_PROFILE,
  UPDATE_TEMPLATE_PROFILE_DONE,
  GET_TEMPLATE_DETAIL,
  SET_TEMPLATE_DETAIL,
  DELETE_TEMPLATE,
  SET_TEMPLATE_DOCUMENTS_ALL,
  GET_CATALOGO_FORMACAO,
  SET_CATALOGO_FORMACAO,
  GET_PLANO_FORMACAO,
  UPLOAD_PLANO_FORMACAO,
  SET_UPLOAD_PLANO_FORMACAO,
  SET_DOWNLOAD_PLANO_FORMACAO,
  DELETE_CATALOGO_FORMACAO,
  GET_CATALOGO_FORMACAO_DROPDOWNS,
  SET_CATALOGO_FORMACAO_DROPDOWNS,
  UPDATE_CREATE_CATALOGO_FORMACAO,
  UPDATE_CREATE_CATALOGO_FORMACAO_DONE,
  GET_IFLOW_PROPERTIES,
  SET_IFLOW_PROPERTIES,
  GET_ENTITIES_FORMACAO,
  SET_ENTITIES_FORMACAO,
  SET_DELETE_CATALOGO_FORMACAO_MESSAGE,
} from "./actionTypes";

const initialState = {
  processes: [],
  isLoadingProcesses: false,
  history: [],
  numbercount: 0,
  numbercountTotal: 0,
  formdata: null,
  isLoadingFormdata: false,

  formdata: null,
  isLoadingFormdata: false,

  iflowDocument: [],
  isLoadingIflowDocument: false,
  selectedToSign: [],

  iflowTriggerLoading: null,
  iflowDone: null,

  metadataPreview: null,
  isLoadingPreview: false,

  isSubstitutingIflowDocument: false,

  buttonSaveTrigger: false,
  iflowpid: {},

  greyedout: null,
  isLoadingGreyedOut: false,
  newCollabMessage: null,
  newCollabAlreadyExists: false,
  companiesNewCollab: [],
  templatesList: [],
  isLoadingTemplates: false,
  templateDetail: [],
  templateDocumentsAll: [],
  catalogoFormacao: [],
  isLoadingCatalogoFormacao: false,
  isLoadingPlanoFormacao: false,
  uploadPlanoFormacaoMessage: null,
  downloadPlanoFormacaoMessage: null,
  catalogoFormacaoDropdowns: [],
  entitiesFormacao: [],

  deleteCatalogoFormacaoMessage: "",
};

export function processesReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_IFLOW_PROCESSES_BY_FILTERS:
      return { ...state, isLoadingProcesses: true };
    case FIND_IFLOW_PROCESSES_HISTORY:
      return { ...state, isLoadingProcesses: true };
    case FIND_IFLOW_PROCESSES_HISTORY_TASKS:
      return { ...state, isLoadingProcesses: true };
    case SET_IFLOW_TRIGGER_LOADING:
      return { ...state, iflowTriggerLoading: action.iflowTriggerLoading };
    case SET_PROCESSES:
      return { ...state, processes: action.data };
    case SET_PROCESSES_HISTORY:
      return { ...state, history: action.data, isLoadingProcesses: false };
    case SET_PROCESSES_COUNT:
      return { ...state, numbercount: action.data, isLoadingProcesses: false };
    case SET_PROCESSES_COUNT_TOTAL:
      return {
        ...state,
        numbercountTotal: action.data,
      };
    case SET_HISTORY:
      return { ...state, history: action.data, isLoadingProcesses: false };
    case GET_IFLOW_FORMDATA:
      return { ...state, isLoadingFormdata: true };
    case SET_IFLOW_FORMDATA:
      return { ...state, formdata: action.formdata, isLoadingFormdata: false };
    case SET_IFLOW_PID:
      return { ...state, iflowpid: action.data };
    case POST_IFLOW_FORMDATA:
      return { ...state, isLoadingFormdata: true };
    case POST_IFLOW_FORM:
      return { ...state };
    case GET_IFLOW_DOCUMENT:
      return { ...state, isLoadingIflowDocument: true };
    case SET_SELECTED_TOSIGN:
      return { ...state, selectedToSign: action.data };

    case SET_IFLOW_DONE:
      return { ...state, iflowDone: action.message };

    case GET_GREYED_OUT:
      return { ...state, isLoadingGreyedOut: true };
    case POST_ADMISSAO_COLLAB:
      return { ...state };
    case GET_COMPANIES_NEW_COLLAB:
      return { ...state };
    case SET_GREYED_OUT:
      return {
        ...state,
        greyedout: action.greyedout,
        isLoadingGreyedOut: false,
      };
    case SET_COMPANIES_NEW_COLLAB:
      return {
        ...state,
        companiesNewCollab: action.companies,
      };
    case SET_ADMISSAO_COLLAB_MESSAGE:
      return {
        ...state,
        newCollabMessage: action.message,
      };
    case SET_ADMISSAO_COLLAB_MESSAGE_ALREADY_EXISTS:
      return {
        ...state,
        newCollabAlreadyExists: action.collabExists,
      };
    case RESET_IFLOW_DOCUMENTS:
      return { ...state, iflowDocument: [], isLoadingIflowDocument: false };

    case SET_IFLOW_DOCUMENTS:
      let newIflowDocument = Array.isArray(state.iflowDocument)
        ? state.iflowDocument
        : [];
      if (
        Array.isArray(state.iflowDocument) &&
        state.iflowDocument.some((d) => d.docid === action.docid)
      ) {
        newIflowDocument = state.iflowDocument.filter(
          (d) => d.docid != action.docid
        );
        newIflowDocument.push({
          docid: action.docid,
          file: action.document,
          filename: action.filename,
        });
      } else {
        if (action.docid) {
          newIflowDocument.push({
            docid: action.docid,
            file: action.document,
            filename: action.filename,
          });
        }
      }

      return {
        ...state,
        isLoadingIflowDocument: false,
        iflowDocument: newIflowDocument,
      };

    case GET_IFLOW_METADATA_PREVIEW:
      return {
        ...state,
        isLoadingPreview: true,
      };

    case SET_IFLOW_METADATA_PREVIEW:
      return {
        ...state,
        metadataPreview: action.data,
        isLoadingPreview: false,
      };

    case SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE:
      return {
        ...state,
        isSubstitutingIflowDocument: action.data,
      };

    case GET_BUTTONSAVE_TRIGGER:
      return {
        ...state,
        buttonSaveTrigger: true,
      };
    case SET_BUTTONSAVE_TRIGGER:
      return {
        ...state,
        buttonSaveTrigger: false,
      };
    case CLEAR_IFLOW_FORMDATA:
      return { ...state, formdata: initialState.formdata };
    case GET_TEMPLATES_LIST:
      return { ...state, isLoadingTemplates: true };
    case SET_TEMPLATES_LIST:
      return {
        ...state,
        templatesList: action.list,
        isLoadingTemplates: false,
      };
    case GET_CATALOGO_FORMACAO:
      return { ...state, isLoadingCatalogoFormacao: true };
    case SET_CATALOGO_FORMACAO:
      return {
        ...state,
        catalogoFormacao: action.catalogo,
        isLoadingCatalogoFormacao: false,
      };
    case DELETE_CATALOGO_FORMACAO:
      return { ...state };
    case GET_CATALOGO_FORMACAO_DROPDOWNS:
      return { ...state };
    case SET_CATALOGO_FORMACAO_DROPDOWNS:
      return {
        ...state,
        catalogoFormacaoDropdowns: action.data,
      };
    case GET_PLANO_FORMACAO:
      return { ...state, isLoadingPlanoFormacao: true };
    case GET_ENTITIES_FORMACAO:
      return { ...state, isLoadingPlanoFormacao: true };
    case SET_ENTITIES_FORMACAO:
      return {
        ...state,
        isLoadingPlanoFormacao: false,
        entitiesFormacao: action.data,
      };

    case SET_DELETE_CATALOGO_FORMACAO_MESSAGE:
      return {
        ...state,
        deleteCatalogoFormacaoMessage: action.message,
      };
    case UPLOAD_PLANO_FORMACAO:
      return { ...state, isLoadingPlanoFormacao: true };
    case SET_UPLOAD_PLANO_FORMACAO:
      return {
        ...state,
        uploadPlanoFormacaoMessage: action.data,
        isLoadingPlanoFormacao: false,
      };
    case SET_DOWNLOAD_PLANO_FORMACAO:
      return {
        ...state,
        downloadPlanoFormacaoMessage: action.data,
        isLoadingPlanoFormacao: false,
      };
    case UPDATE_TEMPLATE_PROFILE:
      return { ...state, isLoadingTemplates: true };
    case UPDATE_TEMPLATE_PROFILE_DONE:
      return { ...state, isLoadingTemplates: false };
    case UPDATE_CREATE_CATALOGO_FORMACAO:
      return { ...state, isLoadingCatalogoFormacao: true };
    case UPDATE_CREATE_CATALOGO_FORMACAO_DONE:
      return { ...state, isLoadingCatalogoFormacao: false };
    case GET_TEMPLATE_DETAIL:
      return { ...state, isLoadingTemplates: true };
    case SET_TEMPLATE_DETAIL:
      return {
        ...state,
        templateDetail: action.data,
        isLoadingTemplates: false,
      };
    case SET_TEMPLATE_DOCUMENTS_ALL:
      return {
        ...state,
        templateDocumentsAll: action.data,
        isLoadingTemplates: false,
      };
    case DELETE_TEMPLATE:
      return { ...state };
    default:
      return state;
  }
}
