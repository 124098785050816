import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import "../assets/scss/_passwordProgressBar.scss";

const PasswordProgressBar = ({ password, setPasswordValid }) => {
  const warningTextSize = "A password tem de ter mais de 6 caracteres.";
  const warningTextError =
    'Tem que escolher uma senha "boa" ou "forte" para prosseguir.';

  const [strength, setStrenght] = useState(0);
  const [strenghtColor, setStrenghtColor] = useState("");
  const [strenghtText, setStrenghtText] = useState("");
  const [warningText, setWarningText] = useState(warningTextError)

  function checkpassword(password) {
    if (password === null) return;
    else if (String(password).length < 6) {
      setStrenght(0);
      setStrenghtColor("#FFFFFF");
      setStrenghtText("");
      setWarningText(warningTextSize);
      return;
    }
    var passwordStrenght = 0;
    if (password.match(/[a-z]+/)) {
      passwordStrenght += 1;
    }
    if (password.match(/[A-Z]+/)) {
      passwordStrenght += 1;
    }
    if (password.match(/[0-9]+/)) {
      passwordStrenght += 1;
    }
    if (password.match(/[$@#&!.]+/)) {
      passwordStrenght += 1;
    }

    switch (passwordStrenght) {
      case 0:
        setStrenght(0);
        setStrenghtColor("#FFFFFF");
        setStrenghtText("");
        setWarningText(warningTextError);
        setPasswordValid(false);
        break;

      case 1:
        setStrenght(25);
        setStrenghtColor("#C70039");
        setStrenghtText("Fraca");
        setWarningText(warningTextError);
        setPasswordValid(false);
        break;

      case 2:
        setStrenght(50);
        setStrenghtColor("#F78D3F");
        setStrenghtText("Boa");
        setWarningText(warningTextError);
        setPasswordValid(true);
        break;

      case 3:
        setStrenght(75);
        setStrenghtColor("#83F70E");
        setStrenghtText("Forte");
        setWarningText(warningTextError);
        setPasswordValid(true);
        break;

      case 4:
        setStrenght(100);
        setStrenghtColor("#1F7607");
        setStrenghtText("Perfeita");
        setWarningText(warningTextError);
        setPasswordValid(true);
        break;
    }
  }

  useEffect(() => {
    checkpassword(password);
  });

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 5,
      width: "100%",
      borderRadius: 5,
      position: "inherit",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: strenghtColor,
    },
  }))(LinearProgress);

  return (
    <div>
      <div className="easypay-password-container">
        <BorderLinearProgress
          className="progress-bar"
          variant="determinate"
          value={strength}
        />
        <label className="easypay-password-strength-label">{strenghtText}</label>
        <label className="easypay-password-warning-label" color="#BFB8B8">
          {warningText}
        </label>
      </div>
    </div>
  );
};

export default PasswordProgressBar;
