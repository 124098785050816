export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const FAIL_CREDENTIALS = "FAIL_CREDENTIALS";
export const FAIL_REMOTE_SERVER = "FAIL_REMOTE_SERVER";
export const LOGOUT = "LOGOUT";
export const REQUEST_LOGIN_GLOBAL_SOLVER = "REQUEST_LOGIN_GLOBAL_SOLVER";

export const SET_GLOBAL_SOLVER_AUTHENTICATED =
  "SET_GLOBAL_SOLVER_AUTHENTICATED";
export const SET_SIMPLE_USER_CREATED = "SET_SIMPLE_USER_CREATED";

export const GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN";
export const MICROSOFT_SIGN_IN = "MICROSOFT_SIGN_IN";
export const ADFS_SIGN_IN = "ADFS_SIGN_IN";
export const LDAP_SIGN_IN = "LDAP_SIGN_IN";
export const SET_LDAP_SIGN_IN = "SET_LDAP_SIGN_IN";
export const SET_WINDOWS_AUTH_CONFIG = "SET_WINDOWS_AUTH_CONFIG";
export const GET_WINDOWS_AUTH_CONFIG = "GET_WINDOWS_AUTH_CONFIG";
export const SET_KEYCLOAK_AUTH_CONFIG = "SET_KEYCLOAK_AUTH_CONFIG";
export const GET_KEYCLOAK_AUTH_CONFIG = "GET_KEYCLOAK_AUTH_CONFIG";
export const GET_WINDOWS_AUTH_CONFIG_IFLOW = "GET_WINDOWS_AUTH_CONFIG_IFLOW";
export const GET_WINDOWS_AUTH_CONFIG_SIGN_PDF =
  "GET_WINDOWS_AUTH_CONFIG_SIGN_PDF";
export const SET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH =
  "SET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH";
export const GET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH =
  "GET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH";
export const SET_REQUEST_LEGACY_USERNAME_LOGGED_IN =
  "SET_REQUEST_LEGACY_USERNAME_LOGGED_IN";
export const GET_REQUEST_LEGACY_USERNAME_LOGGED_IN =
  "GET_REQUEST_LEGACY_USERNAME_LOGGED_IN";
export const GET_REQUEST_INTRANET_LOGIN = "GET_REQUEST_INTRANET_LOGIN";
export const SET_REQUEST_INTRANET_LOGIN = "SET_REQUEST_INTRANET_LOGIN";
export const GET_IS_FROM_INTRANET_LOGIN = "GET_IS_FROM_INTRANET_LOGIN";
export const FAIL_CREDENTIALS_LDPA = "FAIL_CREDENTIALS_LDPA";
export const SET_KEYCLOCK_AUTH_CONFIG = "SET_KEYCLOCK_AUTH_CONFIG";

export const getIntranetLogin = (data) => {
  return {
    type: GET_REQUEST_INTRANET_LOGIN,
    data: data,
  };
};

export const checkIsIntranetLogin = () => {
  return {
    type: GET_IS_FROM_INTRANET_LOGIN,
  };
};

export const setIntranetLogin = () => {
  return {
    type: SET_REQUEST_INTRANET_LOGIN,
  };
};

export const requestLogin = (payload, checkAdminConfigFile) => {
  return {
    type: REQUEST_LOGIN,
    data: payload,
    checkAdminConfigFile,
  };
};

export const successLogin = () => {
  return { type: SUCCESS_LOGIN };
};

export const failCredentials = () => {
  return { type: FAIL_CREDENTIALS };
};

export const failCredentialsLDPA = () => {
  return { type: FAIL_CREDENTIALS_LDPA };
};

export const failRemoteServer = () => {
  return { type: FAIL_REMOTE_SERVER };
};

export const logout = (username) => {
  return { type: LOGOUT, username };
};

export const requestLoginGlobalSolver = (payload) => {
  return {
    type: REQUEST_LOGIN_GLOBAL_SOLVER,
    data: payload,
  };
};

export const setGlobalSolverAuthenticated = (payload) => {
  return { type: SET_GLOBAL_SOLVER_AUTHENTICATED, data: payload };
};

export const setSimpleUserCreated = (payload) => {
  return { type: SET_SIMPLE_USER_CREATED, data: payload };
};

export const googleSignIn = (response) => ({ type: GOOGLE_SIGN_IN, response });
export const microsoftSignIn = (response) => ({
  type: MICROSOFT_SIGN_IN,
  response,
});
export const adfsSignIn = (response) => ({ type: ADFS_SIGN_IN, response });
export const LDAPSignIn = (authentication) => ({
  type: LDAP_SIGN_IN,
  authentication,
});

export const setLDAPSignIn = (resp) => ({
  type: SET_LDAP_SIGN_IN,
  data: resp,
});

export const GET_SAML_URL_REQUEST = "GET_SAML_URL_REQUEST";
export const SET_SAML_URL_REQUEST = "SET_SAML_URL_REQUEST";
export const CLEAR_SAML_URL_REQUEST = "CLEAR_SAML_URL_REQUEST";

export const getSAMLRequestURL = () => ({
  type: GET_SAML_URL_REQUEST,
});
export const setSAMLRequestURL = (data) => ({
  type: SET_SAML_URL_REQUEST,
  data,
});
export const clearSamlRequestUrl = () => ({
  type: CLEAR_SAML_URL_REQUEST,
});

export const SIGN_IN_SAML = "SIGN_IN_SAML";
export const SIGNED_IN_SAML = "SIGNED_IN_SAML";

export const signInSAML = (payload) => ({
  type: SIGN_IN_SAML,
  payload,
});
export const signedInSAML = (isSAMLAuthenticated) => ({
  type: SIGNED_IN_SAML,
  isSAMLAuthenticated,
});

export const getWindowsAuthConfigIflow = (data) => ({
  type: GET_WINDOWS_AUTH_CONFIG_IFLOW,
  data: data,
});

export const getWindowsAuthConfigSignPdf = (data) => ({
  type: GET_WINDOWS_AUTH_CONFIG_SIGN_PDF,
  data: data,
});

export const getWindowsAuthConfig = (data) => ({
  type: GET_WINDOWS_AUTH_CONFIG,
  data: data,
});

export const setKeycloackAuthConfig = (data) => ({
  type: SET_KEYCLOCK_AUTH_CONFIG,
  data: data,
});

export const setAuthWindowsAuthConfig = (data) => ({
  type: SET_WINDOWS_AUTH_CONFIG,
  data: data,
});

export const getKeycloakAuthConfig = (data) => ({
  type: GET_KEYCLOAK_AUTH_CONFIG,
  data: data,
});

export const setAuthKeycloakAuthConfig = (data) => ({
  type: SET_KEYCLOAK_AUTH_CONFIG,
  data: data,
});

export const requestLegacyUserWindowsAuth = (data) => ({
  type: GET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH,
  data: data,
});

export const setRequestLegacyUserWindowsAuth = () => ({
  type: SET_REQUEST_LEGACY_USER_ID_WINDOWS_AUTH,
});

export const setAuthWindowsUsername = (data) => ({
  type: SET_REQUEST_LEGACY_USERNAME_LOGGED_IN,
  data: data,
});
