import React, { useState, useEffect } from "react";

export const useAutoAdjust = (field) => {
  const [autoAdjust, setAutoAdjust] = useState("");

  useEffect(() => {
    if (typeof field?.text === "string") {
      if (field?.text?.includes("::autoAdjust")) {
        let myStr = field?.text?.split("::autoAdjust")[1];

        if (myStr.match(/=(tru)/)) {
          setAutoAdjust(true);
        }
      }
    }
    if (typeof field?.text === "object") {
      Object.keys(field?.text).forEach((key) => {
        if (field?.text[key].includes("::autoAdjust")) {
          let myStr = field?.text[key]?.split("::autoAdjust")[1];

          if (myStr.match(/=(tru)/)) {
            setAutoAdjust(true);
          }
        }
      });
    }
  }, [field]);

  return { autoAdjust };
};
