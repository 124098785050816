import React, { useState, useRef, useEffect } from "react";
import "../assets/scss/_documentsTab.scss";
import uuid from "uuid/v1";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { transformFormData } from "~/components/CreateForm/utils";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import Tab from "./Tab";
import TabWrapper from "./TabWrapper";
import ButtonUpload from "./ButtonUpload";
import Text from "~/pages/Easypay/components/Text";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import checkmark from "~/pages/Easypay/assets/img/icons/icon-checkmark.png";
import iconError from "~/pages/Easypay/assets/img/icons/icon-error-filled.png";
import iconWaiting from "~/pages/Easypay/assets/img/icons/icon-waiting.png";
import Button from "~/pages/Easypay/components/Button";
import LineBreak from "~/pages/Easypay/components/LineBreak";
import oauth from "~/utils/oauth";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "./ProvaDeVida";
const devLogConsole = require("~/utils/devLog");
function DocumentsTab({ list, documentsSubmittedList, t }) {
  var currentIndex = -1;

  const dispatch = useDispatch();

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitDocuments';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const field = {
    type: "link",
    text: "text",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='removeDoc';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function handleDeleteFile(id) {
    setCurrentDocIndex(id);
    return;
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Tipo de documento não suportado" status="error" />
      </div>
    );
  }

  var numberToSubmit = 0;
  function repeat() {
    var arr = [];
    for (var i = 0; i < documentsSubmittedList.length; i++) {
      arr.push("");
      if (documentsSubmittedList[i] === "0") numberToSubmit++;
    }
    return arr;
  }

  const [isModalShowing, setIsModalShowing] = useState(false);

  useEffect(() => {
    if (list[0].content[11].value === "" && currentDocIndex != null || document.dados["currentDocIndex"] && currentDocIndex != null) {
      document.dados["currentDocIndex"].value = currentDocIndex;

      if (field.href.includes("javascript:")) {
        if (field.href.includes("document.dados.op.value='")) {
          const array = field.href.split("document.dados.op.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.op.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.buttonResult.value='")) {
          const array = field.href.split("document.dados.buttonResult.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.buttonResult.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.acção.value='")) {
          const array = field.href.split("document.dados.acção.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.acção.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.voltar.value='")) {
          const array = field.href.split("document.dados.voltar.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.voltar.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.trocarDeContaTink.value='")) {
          const array = field.href.split(
            "document.dados.trocarDeContaTink.value='"
          );
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.trocarDeContaTink.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.botao_accao.value='")) {
          const array = field.href.split("document.dados.botao_accao.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.botao_accao.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.mostrardoc.value='")) {
          const array = field.href.split("document.dados.mostrardoc.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.mostrardoc.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.cancelardoc.value='")) {
          const array = field.href.split("document.dados.cancelardoc.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.cancelardoc.value = array2[0];
            }
          }
        }
        if (
          field.href.includes("document.dados.toggleCodigoCertidao.value='")
        ) {
          const array = field.href.split(
            "document.dados.toggleCodigoCertidao.value='"
          );
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.toggleCodigoCertidao.value = array2[0];
            }
          }
        }
        if (field.href.includes("document.dados.botao.value='")) {
          const array = field.href.split("document.dados.botao.value='");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              document.dados.botao.value = array2[0];
            }
          }
        }

        if (document.dados?.cmdPath && document.dados?.cmdHost) {
          if (document.dados?.acção.value === "iniciarChaveMovelDigital") {
            document.dados.cmdHost.value = window.location.origin;
            document.dados.cmdPath.value = window.location.hash.slice(1); //To remove # that causes iflow some problems
          }
        }

        if (field.href.includes("document.dados.submit()")) {
          const formdata = transformFormData(
            new FormData(document.getElementsByName("dados")[0])
          );
          const url =
            "/Form/form.jsp?" +
            "flowid=" +
            formdata.get("flowid") +
            "&pid=" +
            formdata.get("pid") +
            "&subpid=" +
            formdata.get("subpid") +
            "&Authorization=" +
            oauth.getAccessToken();
          dispatch(postEasypayFormData(formdata, url));
        }
      }
    }
    if (document.dados["documentsToSubmit"]) {
      document.dados["documentsToSubmit"].value = documentsNameList;
    }
    if (document.dados["documentsContentToSubmit"]) {
      document.dados["documentsContentToSubmit"].value = fileArray;
    }
    if (document.dados["documentsExpiresContent"]) {
      document.dados["documentsExpiresContent"].value = documentsExpiresList;
    }
    if (document.dados["documentsEmittedContent"]) {
      document.dados["documentsEmittedContent"].value = documentsEmittedList;
    }
    if (document.dados["documentsExtensionsContent"]) {
      document.dados["documentsExtensionsContent"].value =
        documentsExtensionList;
    }
    if (document.dados["documentsMimesContent"]) {
      document.dados["documentsMimesContent"].value = documentsMimeList;
    }
  });

  const emptyArray = repeat();
  const [currentDocIndex, setCurrentDocIndex] = useState(null);
  const [documentsNameList, setDocumentsNameList] = useState(emptyArray);
  const [documentsExpiresList, setDocumentsExpiresList] = useState(emptyArray);
  const [documentsEmittedList, setDocumentsEmittedList] = useState(emptyArray);
  const [documentsMimeList, setDocumentsMimeList] = useState(emptyArray);
  const [documentsExtensionList, setDocumentsExtensionList] =
    useState(emptyArray);
  const [fileArray, setFileArray] = useState(emptyArray);
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);

  function checkForEmptyDocumentsToSubmit() {
    for (var i = 0; i < documentsSubmittedList.length; i++) {
      if (documentsNameList[i] != "") {
        return false;
      }
    }
    return true;
  }

  function onButtonClick(index) {
    // `current` points to the mounted file input element
    currentIndex = index;
    inputFile.current.click();
  }

  function getBase64(file, index) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      var newFileArray = [...fileArray];
      newFileArray[index] = base64String;
      setFileArray(newFileArray);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];
    setFile(currentFile);

    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";

    if (
      !(
        type.includes("pdf") ||
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      )
    ) {
      setIsModalShowing(true)
      return;
    }

    var newArray = [...documentsNameList];
    newArray[currentIndex] = currentFile.name;
    setDocumentsNameList(newArray);

    const emitted_at = currentFile.lastModifiedDate;
    const expires_at = new Date(
      new Date(currentFile.lastModifiedDate).setFullYear(
        new Date().getFullYear() + 1
      )
    );
    const is_private = true;
    const extension = "." + currentFile.name.split(".")[1];
    var newExpires = expires_at.toISOString().split(".")[0] + "Z";
    var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";

    newArray = [...documentsEmittedList];
    newArray[currentIndex] = newEmitted;
    setDocumentsEmittedList(newArray);

    newArray = [...documentsExpiresList];
    newArray[currentIndex] = newExpires;
    setDocumentsExpiresList(newArray);

    newArray = [...documentsMimeList];
    newArray[currentIndex] = type;
    setDocumentsMimeList(newArray);

    newArray = [...documentsExtensionList];
    newArray[currentIndex] = extension;
    setDocumentsExtensionList(newArray);

    getBase64(currentFile, currentIndex);
  };

  function handleRemoveFile(index) {
    var newArray = [...documentsNameList];
    newArray[index] = "";
    setDocumentsNameList(newArray);

    var newFileArray = [...fileArray];
    newFileArray[index] = "";
    setFileArray(newFileArray);
  }

  function checkIfOneTypeList() {
    if (
      (documentsSubmittedList.indexOf("0") === -1 &&
        documentsSubmittedList.indexOf("1") >= 0) ||
      (documentsSubmittedList.indexOf("0") >= 0 &&
        documentsSubmittedList.indexOf("1") === -1)
    ) {
      return true;
    } else {
      return false;
    }
  }

  devLogConsole(list)
  return (
    <>
      {checkIfOneTypeList() ? (
        <div>
          <Text
            text={
              t(`bpm.onboarding.backoffice.user.headerUploadDocuments`) +
              "::card-text-title"
            }
          />
          {list &&
            Object.keys(list).length !== 0 &&
            list.map((item, index) => {
              devLogConsole(item)
              return (
                <>
                  <div className="easypay-document-item-wrapper" key={uuid()}>
                    <div className="easypay-document-info-wrapper">
                      <Text text={item.content[1].value + "::card-text-blue"} />
                      <Text
                        text={
                          item.content[2].value + "::card-text-light-no-padding"
                        }
                      />
                    </div>
                    {documentsSubmittedList.indexOf("0") >= 0 ? (
                      <div>
                        {documentsNameList[index] === "" ? (
                          <div style = {{display : "flex",flexDirection : "column", alignItems : "center"}}>
                          <ButtonUpload
                            text={t(
                              `bpm.onboarding.backoffice.user.buttonUploadDocument`
                            )}
                            variant={"easypay-button-secondary-icon"}
                            onclick={() => onButtonClick(index)}
                            onChange={handleChange}
                            key={uuid()}
                            inputFile={inputFile}
                            id={"id1"}
                            className="card-button"
                            align={"right"}
                            disabledColored={false}
                            disabled={false}
                          />
                          <Text
                          text={
                            "pdf, jpeg, jpg, png ::card-text-light"
                          }
                        />
                        </div>
                        ) : (
                          <div className="easypay-document-submitted-wrapper easypay-no-padding">
                            <div className="easypay-text-icon">
                              <img src={iconDocument} alt="Icon Check" />
                            </div>
                            <Text
                              text={
                                documentsNameList[index] + "::card-text-blue"
                              }
                            />
                            <div
                              className="easypay-x-icon"
                              onClick={() => handleRemoveFile(index)}
                            >
                              X
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="easypay-document-submitted-wrapper">
                        <div
                          className="easypay-x-icon-padding-right"
                          onClick={() =>
                            handleDeleteFile(item.content[10].value)
                          }
                        >
                          X
                        </div>
                        <div className="easypay-text-icon">
                          <img src={iconDocument} alt="Icon Check" />
                        </div>
                        <Text
                          text={item.content[3].value + "::card-text-blue"}
                        />
                        <div className="easypay-text-icon-check-padding-left">
                          <img src={item.content[12].value === "0" ? iconWaiting : item.content[12].value === "-1" ? iconError : checkmark} alt="Icon Check" />
                        </div>
                      </div>
                    )}
                  </div>
                  <LineBreak text={"none"} key={uuid()} />
                </>
              );
            })}
        </div>
      ) : (
        <TabWrapper variant={"normal"}>
          <Tab
            action={"action1"}
            text={
              t(`bpm.onboarding.backoffice.user.labelMissingDocs`) +
              "(" +
              numberToSubmit +
              ")"
            }
            index={0}
            name={"Em falta"}
            numberOfTabs={2}
            key={uuid()}
          >
            <Text
              text={
                t(`bpm.onboarding.backoffice.user.headerUploadDocuments`) +
                "::card-text"
              }
            />
            {list &&
              Object.keys(list).length !== 0 &&
              list.map((item, index) => {
                return (
                  <>
                    {documentsSubmittedList[index] === "0" && (
                      <>
                        <div
                          className="easypay-document-item-wrapper"
                          key={uuid()}
                        >
                          <div className="easypay-document-info-wrapper">
                            <Text
                              text={item.content[1].value + "::card-text-blue"}
                            />
                            <Text
                              text={
                                item.content[2].value +
                                "::card-text-light-no-padding"
                              }
                            />
                          </div>
                          {documentsNameList[index] === "" ? (
                            <ButtonUpload
                              text={t(
                                `bpm.onboarding.backoffice.user.buttonUploadDocument`
                              )}
                              variant={"easypay-button-secondary-icon"}
                              onclick={() => onButtonClick(index)}
                              onChange={handleChange}
                              key={uuid()}
                              inputFile={inputFile}
                              id={"id1"}
                              className="card-button"
                              align={"right"}
                              disabledColored={false}
                              disabled={false}
                            />
                          ) : (
                            <div className="easypay-document-submitted-wrapper">
                              <div className="easypay-text-icon">
                                <img src={iconDocument} alt="Icon Check" />
                              </div>
                              <Text
                                text={
                                  documentsNameList[index] + "::card-text-blue"
                                }
                              />
                              <div
                                className="easypay-x-icon"
                                onClick={() => handleRemoveFile(index)}
                              >
                                X
                              </div>
                            </div>
                          )}
                        </div>
                        <LineBreak text={"none"} key={uuid()} />
                      </>
                    )}
                  </>
                );
              })}
          </Tab>
          <Tab
            action={"action2"}
            text={t(`bpm.onboarding.backoffice.user.labelSubmitted`)}
            index={1}
            name={"Submetidos"}
            numberOfTabs={2}
            key={uuid()}
          >
            {list &&
              Object.keys(list).length !== 0 &&
              list.map((item, index) => {
                return (
                  <>
                    {documentsSubmittedList[index] === "1" && (
                      <>
                        <div
                          className="easypay-document-item-wrapper"
                          key={uuid()}
                        >
                          <div className="easypay-document-info-wrapper">
                            <Text
                              text={item.content[1].value + "::card-text-blue"}
                            />
                            <Text
                              text={
                                item.content[2].value +
                                "::card-text-light-no-padding"
                              }
                            />
                          </div>
                          <div className="easypay-document-submitted-wrapper">
                            <div
                              className="easypay-x-icon-padding-right"
                              onClick={() =>
                                handleDeleteFile(item.content[10].value)
                              }
                            >
                              X
                            </div>
                            <div className="easypay-text-icon">
                              <img src={iconDocument} alt="Icon Check" />
                            </div>
                            <Text
                              text={item.content[3].value + "::card-text-blue"}
                            />
                            <div className="easypay-text-icon-check-padding-left">
                              <img src={item.content[12].value === "0" ? iconWaiting : item.content[12].value === "-1" ? iconError : checkmark} alt="Icon Check" />
                            </div>
                          </div>
                        </div>
                        <LineBreak text={"none"} key={uuid()} />
                      </>
                    )}
                  </>
                );
              })}
          </Tab>
        </TabWrapper>
      )}
      {documentsSubmittedList.indexOf("0") != -1 && (
        <Button
          text={t(`bpm.onboarding.backoffice.user.buttonSubmit`)}
          variant={"easypay-button-primary-large"}
          key={uuid()}
          field={addButtonField}
          id={1}
          className="card-button"
          align={"center"}
          disabledColored={false}
          disabled={checkForEmptyDocumentsToSubmit()}
        />
      )}
      <Modal
        className="easypay-modal"
        showModal={isModalShowing}
        setShowModal={setIsModalShowing}
        headerContent={"Submissão de Documento"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
    </>
  );
}

export default withNamespaces()(DocumentsTab);
