// import React from 'react';
// import '../assets/css/loading.css';
// import logo from '../assets/img/logos/unik-logo.png';

// const LoadingKeycloak = () => {
//   return (
//     <div className="loading-page">
//       <img src={logo} width={"600px"} alt="Loading" />
//     </div>
//   );
// }

// export default LoadingKeycloak;

import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "../assets/css/loading.css";

export default function LoadingKeycloak() {
  return (
    <div className="loading-page">
      <Stack
        sx={{ color: "grey.500", justifyContent: "center" }}
        spacing={2}
        direction="row"
      >
        <CircularProgress color="inherit" />
      </Stack>
    </div>
  );
}
