import React from "react";
import { Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { FaFilter, FaListUl } from "react-icons/fa";

const FilterToggle = (props) => {
  const {
    t,
    children,
    eventKey,
    setToggleIcon,
    setActiveKey,
    toggleIcon,
    name,
    iconToDecorate,
    filterActive
  } = props;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setToggleIcon((icon) => (icon === "icon-down" ? "icon-up" : "icon-down"));
    setActiveKey((activeKey) => (activeKey === null ? "0" : null));
  });

  const getIconToDecorate = () => {
    switch (iconToDecorate) {
      case "FaFilter":
        return <FaFilter />;
      case "FaListUl":
        return <FaListUl />;
      default:
        return null;
    }
  };
  return (
    <Col lg="12" onClick={decoratedOnClick} style={{ padding: "0px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {filterActive && (
          <div style={{width:"7px", height:"7px", backgroundColor:'red', borderRadius:"20px", alignSelf:"start"}}></div>
        )}
        <span
          style={{
            fontSize: "18px",
            fontWeight: "500",
            marginBottom: "6px",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getIconToDecorate()} &nbsp; {t(name)}&nbsp;
        </span>
        <i className={toggleIcon} style={{ fontSize: "15x" }} />
      </div>
      {children}
    </Col>
  );
};

export default withNamespaces()(FilterToggle);
