import React, { useEffect, useState } from "react";

import { Col, Form, Row } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Dropdown from "~/components/Dropdown";

const ModalNewProvider = ({
  isClicked,
  isClosed,
  takeNewProvider,
  companiesList,
}) => {
  const [isModalNewClientShowing, setIsModalNewClientShowing] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [dropdownInvalid, setDropdownInvalid] = useState(false);

  const setField = (field, value) => {
    if(field === "prazoPagamento")
    {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex
      if (value === '' || re.test(value)) {
        setForm({
          ...form,
          [field]: value,
        });
    
        if (!!errors[field]) {
          setErrors({
            ...errors,
            [field]: null,
          });
        }
      }

    }
    else
    {
      setForm({
        ...form,
        [field]: value,
      });
  
      if (!!errors[field]) {
        setErrors({
          ...errors,
          [field]: null,
        });
      }
    }
  };

  useEffect(() => {
    if (isClicked) {
      setIsModalNewClientShowing(true);
    }
  }, [isClicked]);

  const validateForm = () => {
    const { nome, morada, orgId, prazoPagamento, year } = form;
    const newErrors = {};
    if (!nome || nome === "" || !isNaN(nome)) {
      newErrors.nome = "Por favor preencha o nome";
    }
    if (!morada || morada === "" || !isNaN(morada)) {
      newErrors.morada = "Por favor preencha a morada.";
    }

    if (!prazoPagamento || prazoPagamento === "" || isNaN(prazoPagamento)) {
      newErrors.prazoPagamento = "Por favor preencha o prazo de pagamento.";
    }

    return newErrors;
  };

  const handleClose = () => setIsModalNewClientShowing(false);

  useEffect(() => {
    isClosed(isModalNewClientShowing);
  }, [isModalNewClientShowing]);

  const handleSaveData = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setDropdownInvalid(true);
    } else {
      setDropdownInvalid(false);
      takeNewProvider(form);
      handleClose();
      setForm("");
    }
  };

  const handleDropdownClassName = (varError, boolean) => {
    if (boolean) {
      return (
        "dropdown-margin " +
        (varError === "" || varError === undefined
          ? "dropdown-border-invalid"
          : "")
      );
    }
  };

  return (
    <>
      <Modal show={isModalNewClientShowing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Fornecedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={form.nome || ""}
                  onChange={(e) => setField("nome", e.target.value)}
                  type="text"
                  isInvalid={!!errors.nome}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome}
                </Form.Control.Feedback>
              </Col>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Morada</Form.Label>
                <Form.Control
                  value={form.morada || ""}
                  onChange={(e) => setField("morada", e.target.value)}
                  type="text"
                  isInvalid={!!errors.morada}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.morada}
                </Form.Control.Feedback>
              </Col>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Prazo de Pagamento (Dias)</Form.Label>
                <Form.Control
                  value={form.prazoPagamento || ""}
                  onChange={(e) => setField("prazoPagamento", e.target.value)}
                  type="text"
                  isInvalid={!!errors.prazoPagamento}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.prazoPagamento}
                </Form.Control.Feedback>
              </Col>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "15px",
          }}
        >
          <SimpleButton
            text={"Fechar"}
            variant={"liftworld-button-primary"}
            onClick={handleClose}
          />
          <SimpleButton
            text={"Guardar"}
            variant={"liftworld-button-primary"}
            onClick={handleSaveData}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalNewProvider;
