import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import NavTabs from "../../../NavTabs";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import "~/assets/css/icons.css";

import { getOperations, updateOperationStates } from "~/store/ducks/portalrh/actionTypes";
import Messages from "~/components/Messages";

const AnnexDocuments = ({ t, portalRhMenus, activeSelected, designacao}) => { 

  const { operations, isLoadingOperations } = useSelector(
    (state) => state.portalrhReducer
  );

  const dispatch = useDispatch();

  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //useEfects
  useEffect(() => {
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
  },[]);

  
  useEffect(() => {
    dispatch(getOperations(t));
  }, []);

  const filteredOps = []
  operations.forEach((op) =>{
    if(op.active && op.processOperation && op.id != 6 && op.id != 10){
      filteredOps.push(op);
    }
  });

  function handleOperationCheckLists(){
    const checkList = []

    filteredOps.forEach((op) =>{
      checkList.push(
        <Form.Check 
          key={op.id}
          id={"opSwitch" + op.id}
          type="switch"
          label={op.name}
          defaultChecked={op.annexDoc}
        />
      )
    })
    
    return (<Form>{checkList}</Form>)
  }

  function updateAnnexDocumentConfigurations(){
    filteredOps.forEach((op) =>{
      op.annexDoc = (document.getElementById("opSwitch" + op.id).checked) ? 1 : 0
    })
    dispatch(updateOperationStates(filteredOps));
  }

  function revertAnnexDocumentConfigurations(){
    filteredOps.forEach((op) =>{
      document.getElementById("opSwitch" + op.id).checked = op.annexDoc;
    })
  }

  return (
    <>
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          
          <Spinner spinning={isLoadingOperations.toString()} />
          
          <Card.Header className="justify-content-between">
            <NavTabs
                  titleMenu={t('portalrh.annexDocs.title')}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
          </Card.Header>
          <Card.Body>
            
            <p>{t('portalrh.annexDocs.helpText')}</p>
            
            {handleOperationCheckLists()}
            
            <br/><br/>
            <Button variant="secondary" onClick={revertAnnexDocumentConfigurations}>
              {t("general.reset")}
            </Button>
            <Button  
              className="card-button" 
              onClick={updateAnnexDocumentConfigurations}
              style={{ marginLeft: "10px", marginRight: "55px" }}
            >
              {t("general.save")}
            </Button>
            <Messages />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(AnnexDocuments);