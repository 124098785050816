export const LOAD_MENU_ONLY_APPS = 'application@LOAD_MENU_ONLY_APPS';
export const SET_MENU_ONLY_APPS = 'application@SET_MENU_ONLY_APPS';
export const SET_SELECTED_APPLICATION = 'application@SET_SELECTED_APPLICATION';
export const CHANGE_LOGO_APPLICATION = 'application@CHANGE_LOGO_APPLICATION';
export const SET_LOGO_APPLICATION = 'application@SET_LOGO_APPLICATION';
export const RESET_SAVED_LOGO = 'application@RESET_SAVED_LOGO';

export const loadMenuOnlyApps = () => ({ type: LOAD_MENU_ONLY_APPS });
export const setMenuOnlyApps = menuOnlyApps => ({ type: SET_MENU_ONLY_APPS, menuOnlyApps });

export const setSelectedApplication = application => ({ type: SET_SELECTED_APPLICATION, application });

export const changeLogoApplication = payload => ({ type: CHANGE_LOGO_APPLICATION, payload });
export const setLogoApplication = application => ({ type: SET_LOGO_APPLICATION, application });
export const resetSavedLogo = () => ({ type: RESET_SAVED_LOGO });
