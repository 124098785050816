import {
  RESET_PASSWORD,
  DONE_RESET_PASSWORD,
  CLEAR_RESET_PASSWORD,
  VALIDATE_TOKEN,
  SET_PASSWORD_TOKEN_STATUS,
  CLEAR_TOKEN_STATUS,
  SAVE_PASSWORD,
  REQUEST_RESET_ADMIN_PASSWORD,
  DONE_REQUEST_RESET_ADMIN_PASSWORD,
  CLEAR_SUCCESS_REQUEST_RESET_ADMIN,
  VALIDATE_ADMIN_CODE,
  DONE_VALIDATE_ADMIN_CODE,
  CLEAR_ADMIN_CODE_STATUS,
  VALIDATE_ADMIN_RESET,
  DONE_VALIDATE_ADMIN_RESET,
} from "./actionTypes";

const initialState = {
  email: undefined,
  successResetPassword: undefined,
  tokenStatus: undefined,
  redirectUrl: "",
  adminCodeStatus: false,
  successRequestResetAdminPassword: false,
  successValidateAdminReset: false,
  isLoadingValidateAdminCode: false,
  isLoadingRequestResetAdminPassword: false,
  isLoadingValidateToken: false,
  isLoadingResetPassword: false,
  isLoadingValidateAdminReset: false,
  isLoadingResetAdminPassword: false,
};

export const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
   /* Regular User Reset Password */
    case RESET_PASSWORD:
      return { ...state, isLoadingResetPassword: true };
    case DONE_RESET_PASSWORD:
      return { ...state, email: action.payload?.email, successResetPassword: action.payload.success, isLoadingResetPassword: false };
    case CLEAR_RESET_PASSWORD:
      return { ...state, successResetPassword: undefined };

    case VALIDATE_TOKEN:
      return { ...state, isLoadingValidateToken: true };
    case SET_PASSWORD_TOKEN_STATUS:
      return {...state, tokenStatus: action.tokenStatus, isLoadingValidateToken: false};
    case CLEAR_TOKEN_STATUS:
      return { ...state, tokenStatus: undefined };

    case SAVE_PASSWORD:
      return { ...state };

    /* Admin Reset Password */
    case VALIDATE_ADMIN_CODE:
      return { ...state, isLoadingValidateAdminCode: true };
    case DONE_VALIDATE_ADMIN_CODE:
      return { ...state, isLoadingValidateAdminCode: false, adminCodeStatus: action.status };
      
    case CLEAR_ADMIN_CODE_STATUS:
      return { ...state, adminCodeStatus: undefined };

    case REQUEST_RESET_ADMIN_PASSWORD:
      return { ...state, isLoadingRequestResetAdminPassword: true };
    case DONE_REQUEST_RESET_ADMIN_PASSWORD:
      return { ...state, isLoadingRequestResetAdminPassword: false, successRequestResetAdminPassword: action.status };
    case CLEAR_SUCCESS_REQUEST_RESET_ADMIN:
      return { ...state, successRequestResetAdminPassword: false };
    
    case VALIDATE_ADMIN_RESET:
      return { ...state, isLoadingValidateAdminReset: true };
    case DONE_VALIDATE_ADMIN_RESET:
      return { ...state, isLoadingValidateAdminReset: false, redirectUrl: action.redirect, successValidateAdminReset: action.status };

    default:
      return state;
  }
};
