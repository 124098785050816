import React, { useState, useEffect } from "react";

import mb from "~/pages/Easypay/assets/img/icons/mb.png";
import visaMastercard from "~/pages/Easypay/assets/img/icons/visaMastercard.png";
import mbWay from "~/pages/Easypay/assets/img/icons/mbWay.png";
import debitoDireto from "~/pages/Easypay/assets/img/icons/debitoDireto.png";
import boletoBancario from "~/pages/Easypay/assets/img/icons/boletoBancario.png";
import ibanDigital from "~/pages/Easypay/assets/img/icons/ibanDigital.png";
import santanderConsumer from "~/pages/Easypay/assets/img/icons/santanderConsumer.png";
import uniflex from "~/pages/Easypay/assets/img/icons/uniflex.png";
import Checkbox from "~/pages/Easypay/components/Checkbox";

import "../assets/scss/_radioButton.scss";

const RadioButtonCard = ({
  field,
  defaultChecked,
  variableValue,
  setVariableValue,
  index,
}) => {
  const [checked, setChecked] = useState(
    defaultChecked ? defaultChecked : false
  );

  useEffect(() => {
    let array = variableValue;
    array[index] = checked ? 1 : 0;
    setVariableValue(array);
  }, [checked]);

  return (
    <div className="easypay-radio-card">
      <div className="easypay-radio-card-wrap">
        <div className="easypay-radio-card-image">
          {field?.col[0]?.value?.includes("MbWay") ? (
            <img src={mbWay} alt="payment method" />
          ) : field?.col[0]?.value?.includes("IBAN") ? (
            <img src={ibanDigital} alt="payment method" />
          ) : field?.col[0]?.value?.includes("Santander") ? (
            <img src={santanderConsumer} alt="payment method" />
          ) : field?.col[0]?.value?.includes("Flex") ? (
            <img src={uniflex} alt="payment method" />
          ) : field?.col[0]?.value?.includes("Multibanco") ||
            field?.col[0]?.value?.includes("Cajero") ||
            field?.col[0]?.value?.includes("ATM") ? (
            <img src={mb} alt="payment method" />
          ) : field?.col[0]?.value?.includes("Boleto") ||
            field?.col[0]?.value?.includes("Comprobante") ||
            field?.col[0]?.value?.includes("Slip") ? (
            <img src={boletoBancario} alt="payment method" />
          ) : field?.col[0]?.value?.includes("Direto") ||
            field?.col[0]?.value?.includes("Direct") ? (
            <img src={debitoDireto} alt="payment method" />
          ) : field?.col[0]?.value?.includes("Visa") ? (
            <img src={visaMastercard} alt="payment method" />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="easypay-radio-card-label">{field?.col[0]?.value}</div>
      <div className="easypay-radio-card-check">
        <Checkbox
          name={field?.col[0]?.value + "checkbox"}
          id={field?.col[0]?.value + "checkbox"}
          checked={checked}
          setCheckboxChecked={setChecked}
          checkboxChecked={checked}
        />
      </div>
    </div>
  );
};

export default RadioButtonCard;
