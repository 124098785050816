export const GET_ORGANIZATIONS = 'organizations@GET_ORGANIZATIONS';
export const SET_ORGANIZATIONS = 'organizations@SET_ORGANIZATIONS';

export const PERSIST_ORGANIZATION = 'organizations@PERSIST_ORGANIZATION';
export const PERSIST_ORGANIZATION_DONE = 'organizations@PERSIST_ORGANIZATION_DONE';

export const GET_ORGANIZATION_BY_ID = 'organizations@GET_ORGANIZATION_BY_ID';
export const SET_ORGANIZATION = 'organizations@SET_ORGANIZATION';

export const CLEAR_ORGANIZATIONS = 'organizations@CLEAR_ORGANIZATIONS';
export const CLEAR_ORGANIZATION = 'organizations@CLEAR_ORGANIZATION';

export const DELETE_ORGANIZATION = 'organizations@DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_DONE = 'organizations@DELETE_ORGANIZATION_DONE';

export const UPDATE_ORGANIZATION = 'organizations@UPDATE_ORGANIZATION';

export const REMOVE_ORGANIZATION_PICTURE = 'organizations@REMOVE_ORGANIZATION_PICTURE';

export const getOrganizations = () => {
    return { type: GET_ORGANIZATIONS }
}

export const setOrganizations = (organizations) => {
    return { type: SET_ORGANIZATIONS, organizations }
}

export const persistOrganization = (organization, datacaptureConfigPayload) => {
    return { type: PERSIST_ORGANIZATION, organization, datacaptureConfigPayload }
}

export const persistOrganizationDone = (success) => {
    return { type: PERSIST_ORGANIZATION_DONE, success }
}

export const updateOrganization = (organization, datacaptureConfigPayload) => {
    return { type: UPDATE_ORGANIZATION, organization, datacaptureConfigPayload }
}

export const getOrganizationById = (id) => {
    return { type: GET_ORGANIZATION_BY_ID, id }
}

export const setOrganization = (organization) => {
    return { type: SET_ORGANIZATION, organization }
}

export const clearOrganizations = () => {
    return { type: CLEAR_ORGANIZATIONS }
}

export const clearOrganization = () => {
    return { type: CLEAR_ORGANIZATION }
}

export const deleteOrganization = (id) => {
    return { type: DELETE_ORGANIZATION, id }
}

export const deleteOrganizationDone = () => {
    return { type: DELETE_ORGANIZATION_DONE }
}

export const removeOrganizationPicture = (id) => {
    return { type: REMOVE_ORGANIZATION_PICTURE, id }
}
