import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Card, Col, Button, Form } from "react-bootstrap";
import logo from "~/assets/img/logos/unik-logo.png";

import Toast from "~/components/Toast";
import Spinner from "~/components/Spinner";

import "~/pages/Register/styles.css";

import { validateOrgAdmin } from "~/store/ducks/registerOrgAdmin/actionTypes";

const ConfirmEmail = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    isLoadingValidateOrgAdmin,
    successValidationUser,
    isLoadingPersistOrgAdmin,
    temporaryUser,
  } = useSelector((state) => state.registerOrgAdminReducer);

  const [validated, setValidated] = useState(false);
  const [rawCode, setRawCode] = useState("");
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    if (rawCode.length === 5) {
      digestMessage(rawCode).then((digestHex) => setCode(digestHex));
    } else {
      setCode("");
    }
  }, [rawCode]);

  useEffect(() => {
    successValidationUser && backToLogin();
  }, [successValidationUser]);

  useEffect(() => {
    temporaryUser?.email && setUsername(temporaryUser.email);
  }, [temporaryUser]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(validateOrgAdmin({ username, code }));
    }
  };

  const backToLogin = () => {
    const payload = {
      pathname: `/login`,
      state: { name: "Login" },
    };
    history.replace(payload);
  };

  async function digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // code the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    return hashHex;
  }

  return (
    <div className={"register-form-wrapper register-center"}>
      <div className="overlay">
        <div className="row">
          <div className="col-12 mt-3 mb-2 text-center">
            <a href="/#">
              <img
                className="img-fluid unik-logo"
                src={logo}
                alt="Uniksystem"
              />
            </a>
          </div>
        </div>
        <div className="col-xl-5 col-lg-8 col-md-10  my-2 register-form ">
          <Card
            className="register-form"
            style={{ border: "0px", width: "100%" }}
          >
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <Card.Header className="register-header">
                <span className="label-register">
                  {t("register.confirmEmail.header")}
                </span>
              </Card.Header>

              <Card.Body className="register-body">
                <span className="reg-confirmation-txt">
                  {t("register.confirmEmail.body")}
                </span>
                <Form.Row style={{ marginTop: "15px" }}>
                  <Col sm="12" md="12" lg="12">
                    <Form.Group controlId="validationValidateUsername">
                      <Form.Label className="register-form-label">
                        {t("register.confirmEmail.username")}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        autofill="false"
                        required
                        autoComplete="off"
                        style={{ textAlign: "center" }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value.trim())}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("register.confirmEmail.requiredUsername")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="12" md="12" lg="12">
                    <Form.Group controlId="validationValidateCode">
                      <Form.Label className="register-form-label">
                        {t("register.confirmEmail.code")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        autofill="false"
                        required
                        autoComplete="off"
                        style={{ textAlign: "center" }}
                        value={rawCode}
                        onChange={(e) => setRawCode(e.target.value.trim())}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("register.confirmEmail.requiredCode")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Card.Body>
              <Card.Footer className="register-confirm-footer">
                <Button
                  className="card-button"
                  type="submit"
                  style={{
                    backgroundColor: "#4484f4",
                    borderColor: "#4484f4",
                    borderRadius: "23px",
                    height: "44px",
                  }}
                >
                  {t("register.confirmEmail.confirm")}
                </Button>
                <Button
                  style={{
                    marginLeft: "15px",
                    borderRadius: "23px",
                    height: "44px",
                  }}
                  variant="danger"
                  onClick={() => backToLogin()}
                >
                  {t("register.confirmEmail.goToLogin")}
                </Button>
              </Card.Footer>
            </Form>
          </Card>
        </div>
        <Spinner
          spinning={isLoadingValidateOrgAdmin || isLoadingPersistOrgAdmin}
          wrapper
        />
        <Toast />
      </div>
    </div>
  );
};

export default withNamespaces()(ConfirmEmail);
