import {
  PERSIST_ORG_ADMIN,
  PERSIST_ORG_ADMIN_DONE,
  SET_TEMPORARY_USER,
  VALIDATE_ORG_ADMIN,
  VALIDATE_ORG_ADMIN_DONE,
} from "./actionTypes";

const initialState = {
  temporaryUser: {},
  isLoadingPersistOrgAdmin: false,
  successRegisteringUser: false,
  isLoadingValidateOrgAdmin: false,
  successValidationUser: false
};

export const registerOrgAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSIST_ORG_ADMIN:
      return { ...state, isLoadingPersistOrgAdmin: true, successRegisteringUser: false };
    case PERSIST_ORG_ADMIN_DONE:
      return { ...state, isLoadingPersistOrgAdmin: false, successRegisteringUser: action.redirect };
    case SET_TEMPORARY_USER:
      return { ...state, temporaryUser: action.user };

    case VALIDATE_ORG_ADMIN:
      return { ...state, isLoadingValidateOrgAdmin: true, successValidationUser: false };
    case VALIDATE_ORG_ADMIN_DONE:
      return { ...state, isLoadingValidateOrgAdmin: false, successValidationUser: action.redirect };
    
    default:
      return state;
  }
};
