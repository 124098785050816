export const GET_ANOPROCESSAMENTOASSIDUIDADEANO = "processamentoAssiduidade@GET_ANOPROCESSAMENTOASSIDUIDADEANO";
export const SET_ANOPROCESSAMENTOASSIDUIDADEANO = "processamentoAssiduidade@SET_ANOPROCESSAMENTOASSIDUIDADEANO";
export const GET_ANOPROCESSAMENTOASSIDUIDADEMES = "processamentoAssiduidade@GET_ANOPROCESSAMENTOASSIDUIDADEMES";
export const SET_ANOPROCESSAMENTOASSIDUIDADEMES = "processamentoAssiduidade@SET_ANOPROCESSAMENTOASSIDUIDADEMES";

export const getAnoProcessamentoAssiduidade = (id, companyId) => {
    return { type: GET_ANOPROCESSAMENTOASSIDUIDADEANO, id, companyId };
  };

  export const setAnoProcessamentoAssiduidade = (data) => {
    return { type: SET_ANOPROCESSAMENTOASSIDUIDADEANO, data };
  };
  export const getMesProcessamentoAssiduidade = (id, companyId) => {
    return { type: GET_ANOPROCESSAMENTOASSIDUIDADEMES, id, companyId };
  };

  export const setMesProcessamentoAssiduidade = (data) => {
    return { type: SET_ANOPROCESSAMENTOASSIDUIDADEMES, data };
  };