import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import ButtonUpload from "~/pages/Easypay/components/ButtonUpload";
import "../assets/scss/_documentsTab.scss";

function ButtonUploadDocument({ variables, submitForm }) {
  const [fileContent, setFileContent] = useState("");
  const [file, setFile] = useState("");
  const inputFile = useRef(null);

  useEffect(() => {
    fileContent && handleSubmitToIflow();
  }, [fileContent]);

  function getBase64(file) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setFileContent(base64String);
    };
    setFile(file);
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];
    getBase64(currentFile);
  };

  function onButtonClick() {
    inputFile.current.click();
  }

  const handleSubmitToIflow = () => {
    if (
      document.dados[variables[2]?.value] && //variavelNome
      document.dados[variables[3]?.value] && //variavelContent
      document.dados[variables[4]?.value?.split("::")[0]] //variavélAcção::valorDaVariavelDepoisDeSubmter
    ) {
      document.dados[variables[2]?.value].value = file.name;
      document.dados[variables[3]?.value].value = fileContent;
      document.dados[variables[4]?.value?.split("::")[0]].value =
        variables[4].value?.split("::")[1];

      submitForm();
    }
  };

  return (
    <>
      <ButtonUpload
        text={variables[1].value.split("::")[0]}
        variant={variables[1].value.split("::")[1]}
        key={uuid()}
        id={uuid()}
        className="card-button"
        disabledColored={
          variables[1].value.split("::")[2] === "disabledColored"
            ? "true"
            : false
        }
        disabled={
          variables[1].value.split("::")[2] === "disabled" ? true : false
        }
        onclick={() => onButtonClick()}
        inputFile={inputFile}
        onChange={handleChange}
      />
    </>
  );
}

export default withNamespaces()(ButtonUploadDocument);
