import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getHouseHold,
  getHouseHoldDropdown,
  updateHousehold,
  getHouseHoldAgfCodeLast,
  createHousehold,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";
import UploadDocument from "../UploadDocuments";

const RhHousehold = ({ rowCollaborator, t }) => {
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState("");
  const [documentNum, setDocumentNum] = useState("");
  const [rowClicked, setRowClicked] = useState();
  const [kinshipList, setKinshipList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [kinshipSelected, setKinshipSelected] = useState("");
  const [deficientSelected, setDeficientSelected] = useState("");
  const [genderSelected, setGenderSelected] = useState("");
  const [documentSelected, setDocumentSelected] = useState("");
  const [echelonSelected, setEchelonSelected] = useState("");
  const [checkFamilyAllo, setCheckFamilyAllo] = useState(false);
  const [checkSingleParent, setCheckSingleParent] = useState(false);
  const [checkPreNatal, setCheckPreNatal] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [houseHoldPage, setHouseHoldPage] = useState(false);
  const [agfCode, setAgfCode] = useState("");
  const [agfCodeLast, setAgfCodeLast] = useState("");
  const [parentescoDesign, setParentescoDesign] = useState("");
  const [documentDesign, setDocumentDesign] = useState("");
  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);
  const [validatedDropdowns, setValidatedDropdowns] = useState(false);
  const [kinshipSelectedError, setKinshipSelectedError] = useState(false);
  const [genderSelectedError, setGenderSelectedError] = useState(false);
  const [documentSelectedError, setDocumentSelectedError] = useState(false);
  const [deficientSelectedError, setDeficientSelectedError] = useState(false);

  const dispatch = useDispatch();

  const {
    houseHold,
    houseHoldDropdown,
    isLoadingUpdateHousehold,
    houseHoldAgfCode,
  } = useSelector((state) => state.personalReducer);

  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getHouseHoldDropdown());
      dispatch(getHouseHoldAgfCodeLast());
      dispatch(
        getHouseHold(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      setHouseHoldPage(true);
    }
  }, [rowCollaborator, isLoadingUpdateHousehold]);

  let listDeficient = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "2" },
  ];

  let listEchelon = [
    { label: "Escalão I", value: "1" },
    { label: "Escalão II", value: "2" },
    { label: "Escalão III", value: "3" },
    { label: "Escalão IV", value: "4" },
    { label: "Escalão V", value: "5" },
    { label: "Escalão VI", value: "6" },
  ];

  const handleKinship = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setKinshipSelectedError(false);
    setKinshipSelected(e);
  };

  const handleGender = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setGenderSelectedError(false);
    setGenderSelected(e);
  };

  const handleDocument = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setDocumentSelectedError(false);
    setDocumentSelected(e);
  };

  const handleDeficient = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setDeficientSelectedError(false);
    setDeficientSelected(e);
  };

  const handleEchelon = (e) => {
    setEchelonSelected(e);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDocumentNum = (e) => {
    setDocumentNum(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleBirthDate = (e) => {
    setBirthDate(e);
  };
  useEffect(() => {
    if (houseHoldAgfCode?.length > 0) {
      setAgfCodeLast(houseHoldAgfCode[0].agfCodeLast);
    }
  }, [houseHoldAgfCode]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (
      kinshipSelected === "" ||
      kinshipSelected === null ||
      kinshipSelected === undefined ||
      kinshipSelected === 0 ||
      kinshipSelected === "0"
    )
      setKinshipSelectedError(true);
    if (
      genderSelected === "" ||
      genderSelected === null ||
      genderSelected === undefined ||
      genderSelected === 0 ||
      genderSelected === "0"
    )
      setGenderSelectedError(true);
    if (
      deficientSelected === "" ||
      deficientSelected === null ||
      deficientSelected === undefined ||
      deficientSelected === 0 ||
      deficientSelected === "0"
    )
      setDeficientSelectedError(true);
    if (
      documentSelected === "" ||
      documentSelected === null ||
      documentSelected === undefined ||
      documentSelected === 0 ||
      documentSelected === "0"
    )
      setDocumentSelectedError(true);
    if (form.checkValidity() === false) {
      setValidated(true);
      setValidatedDropdowns(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (houseHoldDropdown && Object.keys(houseHoldDropdown).length > 0) {
        setKinshipList(houseHoldDropdown.kinShips);
        setGenderList(houseHoldDropdown.gender);
        setDocumentList(houseHoldDropdown.document);
      }
      if (data?.length > 0) {
        setName(data[0].name);
        setDocumentNum(data[0].documentNumber);
        setComments(data[0].comments);

        //lists

        setKinshipSelected(data[0].kinship);
        setGenderSelected(data[0].gender);
        setDocumentSelected(data[0].document);
        setDeficientSelected(data[0].deficient);
        setEchelonSelected(data[0].echelon);
        setAgfCode(data[0].agfCode);
        setParentescoDesign(data[0].parentescoDesign);
        setDocumentDesign(data[0].documentoDesign);

        //dates
        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          } else {
            return set(date);
          }
        };
        checkDate(data[0].birthDate, setBirthDate);
        checkDate(data[0].endDate, setEndDate);
        checkDate(data[0].startDate, setStartDate);

        if (data[0].socialBenefitBeneficiary == "1") {
          setCheckFamilyAllo(true);
        } else {
          setCheckFamilyAllo(false);
        }

        if (data[0].singleParent == "1") {
          setCheckSingleParent(true);
        } else {
          setCheckSingleParent(false);
        }

        if (data[0].prenatalAllowance == "1") {
          setCheckPreNatal(true);
        } else {
          setCheckPreNatal(false);
        }
      } else {
        setName("");
        setDocumentNum("");
        setComments("");
        setKinshipSelected("");
        setGenderSelected("");
        setDocumentSelected("");
        setDeficientSelected("");
        setEchelonSelected("");
        setAgfCode("");
        setParentescoDesign("");
        setDocumentDesign("");
        setBirthDate("");
        setEndDate("");
        setStartDate("");
        setCheckFamilyAllo(false);
        setCheckSingleParent(false);
        setCheckPreNatal(false);
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(houseHold);
    }
  }, [houseHoldDropdown, rowClicked, houseHold]);

  const formatWithCheckIcon = (cell, row) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const columns = [
    {
      dataField: "name",
      text: t(`portalrh.HouseHold.Name`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "parentescoDesign",
      text: t(`portalrh.HouseHold.Kinship`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "prenatalAllowance",
      text: t(`portalrh.HouseHold.PrenatalAllowance`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    setKinshipSelectedError(false);
    setDeficientSelectedError(false);
    setGenderSelectedError(false);
    setDocumentSelectedError(false);
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }
  const cleanInputs = () => {
    setName("");
    setDocumentNum("");
    setComments("");
    setKinshipSelected("");
    setGenderSelected("");
    setDocumentSelected("");
    setDeficientSelected("");
    setEchelonSelected("");
    setAgfCode("");
    setParentescoDesign("");
    setDocumentDesign("");
    setBirthDate("");
    setEndDate("");
    setStartDate("");
    setCheckFamilyAllo(false);
    setCheckSingleParent(false);
    setCheckPreNatal(false);
  };

  const houseHoldObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    name: name,
    kinship: kinshipSelected,
    gender: genderSelected,
    birthDate: birthDate,
    document: documentSelected,
    documentNumber: documentNum,
    deficient: deficientSelected,
    socialBenefitBeneficiary: checkFamilyAllo ? "1" : "",
    startDate: startDate,
    endDate: endDate,
    comments: comments,
    echelon: echelonSelected,
    prenatalAllowance: checkPreNatal ? "1" : "",
    singleParent: checkSingleParent ? "1" : "",
    parentescoDesign: parentescoDesign,
    agfCode: agfCode,
    agfNum: rowCollaborator.pssNum,
    documentoDesign: documentDesign,
  };

  const houseHoldObjectNew = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    name: name,
    kinship: kinshipSelected,
    gender: genderSelected,
    birthDate: birthDate,
    document: documentSelected,
    documentNumber: documentNum,
    deficient: deficientSelected,
    socialBenefitBeneficiary: checkFamilyAllo ? "1" : "",
    startDate: startDate,
    endDate: endDate,
    comments: comments,
    echelon: echelonSelected,
    prenatalAllowance: checkPreNatal ? "1" : "",
    singleParent: checkSingleParent ? "1" : "",
    parentescoDesign: parentescoDesign,
    agfCode: agfCodeLast,
    agfNum: rowCollaborator.pssNum,
    documentoDesign: documentDesign,
  };

  const updateData = (bollean) => {
    setHouseHoldPage(bollean);
    dispatch(updateHousehold(houseHoldObject));
  };

  const createNewData = (bollean) => {
    setHouseHoldPage(bollean);
    dispatch(createHousehold(houseHoldObjectNew));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      cleanInputs();
      setNewButtonClicked(true);
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };
  const validateDropdowns = (isSelected, required) => {
    if (validatedDropdowns && required) {
      if (
        isSelected === "" ||
        isSelected === null ||
        isSelected === undefined ||
        isSelected === 0 ||
        isSelected === "0"
      ) {
        return "dropdown-margin dropdown-border-invalid";
      } else {
        return "dropdown-margin dropdown-border-valid";
      }
    }
    if (validatedDropdowns && !required) {
      return "dropdown-margin dropdown-border-valid";
    }
  };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
          {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              houseHoldPage={houseHoldPage}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              houseHoldPage={houseHoldPage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
            />
          ) : null}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="8" md="8">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.HouseHold.Name`)}
                <b>*</b>
              </Form.Label>
              <Form.Control
                value={name === null ? "" : name}
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                type="text"
                onChange={handleName}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${`portalrh.HouseHold.Name`} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.dateBirth`)}
            </Form.Label>
            <DateTimePicker
              value={birthDate}
              onChange={handleBirthDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.HouseHold.Kinship`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(kinshipSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={kinshipList}
                handleSubmit={handleKinship}
                defaultValue={kinshipSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: kinshipSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.HouseHold.Kinship`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="4" md="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.HouseHold.Gender`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(genderSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={genderList}
                handleSubmit={handleGender}
                defaultValue={genderSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: genderSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.HouseHold.Gender`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="4" md="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.HouseHold.Handicapped`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(deficientSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={listDeficient}
                handleSubmit={handleDeficient}
                defaultValue={deficientSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: deficientSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.HouseHold.Handicapped`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.HouseHold.Document`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(documentSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={documentList}
                handleSubmit={handleDocument}
                defaultValue={documentSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: documentSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.HouseHold.Document`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="4" md="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.HouseHold.DocumentNumber`)} <b>*</b>
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={documentNum == null ? "" : documentNum}
                onChange={handleDocumentNum}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.HouseHold.DocumentNumber`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Step`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={listEchelon}
              handleSubmit={handleEchelon}
              defaultValue={echelonSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.StartDate`)}
            </Form.Label>
            <DateTimePicker
              value={startDate}
              onChange={handleStartDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.EndDate`)}
            </Form.Label>
            <DateTimePicker
              value={endDate}
              onChange={handleEndDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <Form.Check
          className="rhcollaborator__labels__"
          inline
          type="checkbox"
          label={t(`portalrh.HouseHold.FamilyAllowanceBenefit`)}
          checked={checkFamilyAllo}
          style={{ padding: 10 }}
          onChange={() =>
            setCheckFamilyAllo((checkFamilyAllo) => !checkFamilyAllo)
          }
          disabled={isClicked ? false : true}
        />
        <Form.Check
          className="rhcollaborator__labels__"
          inline
          type="checkbox"
          label={t(`portalrh.HouseHold.PrenatalAllowance`)}
          checked={checkPreNatal}
          style={{ padding: 10 }}
          onChange={() => setCheckPreNatal((checkPreNatal) => !checkPreNatal)}
          disabled={isClicked ? false : true}
        />
        <Row>
          <Col>
            <Form.Check
              className="rhcollaborator__labels__"
              inline
              type="checkbox"
              label={t(`portalrh.HouseHold.SingleParentFamily`)}
              checked={checkSingleParent}
              style={{ paddingLeft: 10 }}
              onChange={() =>
                setCheckSingleParent((checkSingleParent) => !checkSingleParent)
              }
              disabled={isClicked ? false : true}
            />
          </Col>
        </Row>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col>
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Comments`)}
            </Form.Label>
            <Form.Control
              value={comments === null ? "" : comments}
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              type="text"
              onChange={handleComments}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div>
          <UploadDocument
            isClicked={isClicked}
            uploadFile={houseHold}
            rowCollaborator={rowCollaborator}
            id={5}
          />
        </div>
        <RhTableList
          tableId={"33"}
          columns={columns}
          data={houseHold}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhHousehold);
