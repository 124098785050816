import React from "react";
import { withNamespaces } from "react-i18next";
import { Alert } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const AlertComponentWrapper = ({ t }) => {
  return (
    <div className="main-card-v2" style={{ overflow: "auto", scrollY: "auto" }}>
      <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
        <Card.Body>
          <Alert key={uuidv4()} variant={"info"}>
            {t("general.errorOpeningForm")}
          </Alert>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(AlertComponentWrapper);
