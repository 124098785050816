import React, { useRef, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "chart.js";
import { pieChart } from "./chartDetail.js";
import CustomDropdown from "../CustomDropdown";
import ReportFilters from "../../ReportFilters";
import { withNamespaces } from "react-i18next";
import { findReportPerformance } from "~/store/ducks/iflowReports/actionTypes";
import { cloneDeep } from "lodash";
import "~/assets/css/icons.css";
import { updateUserWidget } from "~/pages/User/actions";

const DesempenhoBPMWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const widgetId = widget.id;

  const getReportPerformance = (state) => {
    const reports = state.iflowReportsReducer.reportPerformance;
    let report = [];
    const savedReport = reports.find((r) => r.id === widgetId);
    if (savedReport) report = savedReport.report;
    return report;
  };
  const reportPerformance = useSelector(getReportPerformance);

  const [pieChart_, setPieChart] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    if (reportPerformance && reportPerformance.length > 0) {
      let chart = cloneDeep(pieChart); //clone object, each widget instance needs its own chart object
      reportPerformance.sort((a, b) => b.avg - a.avg);
      const profilenames = [];
      const dataset = [];
      reportPerformance.forEach((item) => {
        if (profilenames.length < 6) profilenames.push(item.profilename);
        if (dataset.length < 6) dataset.push(item.avg);
      });

      const graphColors = [
        "#A7A5C6",
        "#8797B2",
        "#6D8A96",
        "#5D707F",
        "#66CED6",
        "#bcdfe3",
      ];
      let backgroundColors = [];
      let hoverBackgroundColor = [];
      for (let i = 0; i < dataset.length; i++) {
        backgroundColors.push(graphColors[i]);
        hoverBackgroundColor.push("#99ff99");
      }

      chart.data.datasets[0].backgroundColor = backgroundColors;
      chart.data.datasets[0].hoverBackgroundColor = hoverBackgroundColor;

      chart.data.labels = profilenames ? profilenames : [];
      chart.data.datasets[0].data = dataset ? dataset : [];
      setPieChart(chart);
      setIsUpdating(false);
    }
  }, [reportPerformance]);

  useEffect(() => {
    if (pieChart_ && !isUpdating) {
      const myChartRef = myRef.current.getContext("2d");
      new Chart(myChartRef, pieChart_);
    }
  }, [pieChart_, isUpdating]);

  const executeGraph = (startAt, endAt, flowId) => {
    const data = {
      payload: {
        flowId: flowId === "" ? null : flowId,
        startDate: startAt,
        endDate: endAt,
      },
      widgetId: widget.id,
    };
    dispatch(findReportPerformance(data));
    setIsUpdating(true);
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("DesempenhoBPMWidget.title")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ padding: "0px 10px" }}>
            <Row style={{ flexWrap: "nowrap", height: "90%" }}>
              <Col
                xs={4}
                md={4}
                lg={4}
                style={{ margin: "0 10px 10px 10px", padding: "10px" }}
              >
                <ReportFilters executeGraph={executeGraph} widget={widget} />
              </Col>
              <Col
                style={{
                  padding: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isUpdating && reportPerformance.length > 0 && (
                  <Spinner animation="grow" variant="secondary" />
                )}
                {reportPerformance &&
                  reportPerformance.length > 0 &&
                  !isUpdating && (
                    <div style={{ height: "100%", width: "auto" }}>
                      <canvas id="myPieChart" ref={myRef}></canvas>
                    </div>
                  )}
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(DesempenhoBPMWidget);
