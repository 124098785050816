import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import { Button, Form, Spinner } from "react-bootstrap";

import * as authDomain from "../utils/AuthDomain";
import { GRANT_TYPE_PASSWORD } from "~/utils/constants";

import * as actions from "../actions";
import { createSimpleUser } from "~/pages/Admin/User/actions";

import { logout } from "~/pages/Login/actions";
import { clearUser } from "~/containers/Layout/actions";
import { clearUserSettings } from "~/pages/User/actions";

const RegularAuthenticationNB = ({ t }) => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { isLoadingLogin, globalSolverAuthenticated, simpleUserCreated } =
    useSelector((state) => state.loginReducer);

  const { configuration, successRetrieveConfiguration } = useSelector(
    (state) => state.adminConfigReducer
  );

  //Login global solver success!
  useEffect(() => {
    if (globalSolverAuthenticated) {
      const payload = {
        username,
        password,
      };
      //console.log("creating simple user")
      dispatch(createSimpleUser(payload));
    }
  }, [dispatch, globalSolverAuthenticated]);

  //Simple user created!
  useEffect(() => {
    if (simpleUserCreated) {
      const payload = {
        username,
        password,
        grant_type: GRANT_TYPE_PASSWORD,
      };
      //console.log("requesting login SU")
      dispatch(actions.requestLogin(payload));
    }
  }, [dispatch, simpleUserCreated]);

  const handleLogin = (e) => {
    e.preventDefault();

    //clear previous user´s state
    dispatch(clearUserSettings());
    dispatch(clearUser());

    const payload = {
      username,
      password,
      grant_type: GRANT_TYPE_PASSWORD,
    };
    if (configuration) {
      if (username === "admin") {
        dispatch(actions.requestLogin(payload, true));
      } else if (authDomain.isIFlowSaml()) {
        //dispatch()
      } else if (authDomain.isLDAP()) {
        dispatch(actions.LDAPSignIn(payload));
      } else if (!authDomain.isGlobalSolver()) {
        dispatch(actions.requestLogin(payload));
      } else {
        const payload_ = {
          username,
          password,
        };
        dispatch(actions.requestLoginGlobalSolver(payload_));
      }
    }
  };

  return (
    <Form onSubmit={handleLogin}>
      <Form.Group controlId="formBasicEmail">
        <h1 className="label-credentials">{t("login.username")}</h1>
        <Form.Control
          type="text"
          autofill="false"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          {t("login.requiredUsername")}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <h1 className="label-credentials">{t("login.password")}</h1>
        <Form.Control
          type="password"
          required
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          {t("login.requiredPassword")}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formBasicCheckbox" className="checkbox">
        <div style={{ marginLeft: "15px" }}>
          <Form.Check type="checkbox" label={t("login.rememberMe")} />
        </div>
      </Form.Group>
      <div className="buttons">
        <Button
          type="submit"
          variant="outline-light"
          className="login-button-isel"
          style={{ backgroundColor: "#000000" }}
          disabled={!successRetrieveConfiguration}
        >
          {isLoadingLogin && (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {t("login.loading")}
            </>
          )}
          {!isLoadingLogin && t("login.submit")}
        </Button>
      </div>
    </Form>
  );
};

export default withNamespaces()(RegularAuthenticationNB);
