import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form } from "react-bootstrap";

import { updateProviderId } from "~/store/ducks/budgetManagement/actionTypes";

import Modal from "react-bootstrap/Modal";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Dropdown from "~/components/Dropdown";

const ModalUpdateProvider = ({
  isClicked,
  isClosed,
  companiesList,
  rowObject,
}) => {
  const [isModalUpdatProviderShowing, setIsModalUpdatProviderShowing] =
    useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [dropdownInvalid, setDropdownInvalid] = useState(false);

  const dispatch = useDispatch();

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  useEffect(() => {
    if (isClicked) {
      setIsModalUpdatProviderShowing(true);
    }
  }, [isClicked]);

  const validateForm = () => {
    const { nome, morada, year, numFornecedor, empresa } = form;

    const newErrors = {};
    if (!nome || nome === "" || !isNaN(nome)) {
      newErrors.nome = "Por favor preencha o nome";
    }
    if (!morada || morada === "" || !isNaN(morada)) {
      newErrors.morada = "Por favor preencha a morada.";
    }

    if (!empresa || empresa === undefined || empresa === "") {
      newErrors.orgId = "Por favor preencha a empresa.";
    }

    if (!year || year === "" || isNaN(year)) {
      newErrors.year = "Por favor preencha o ano.";
    }

    if (!numFornecedor || numFornecedor === "" || isNaN(numFornecedor)) {
      newErrors.numCliente = "Por favor preencha o nº do fornecedor.";
    }

    return newErrors;
  };

  const handleClose = () => setIsModalUpdatProviderShowing(false);

  useEffect(() => {
    isClosed(isModalUpdatProviderShowing);
  }, [isModalUpdatProviderShowing]);

  const handleSaveData = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setDropdownInvalid(true);
    } else {
      setDropdownInvalid(false);

      //   takeUpdatedClient(form);

      dispatch(updateProviderId(form));

      handleClose();
      setForm("");
    }
  };

  const handleDropdownClassName = (varError, boolean) => {
    if (boolean) {
      return (
        "dropdown-margin " +
        (varError === "" || varError === undefined
          ? "dropdown-border-invalid"
          : "")
      );
    }
  };

  useEffect(() => {
    if (Object.keys(rowObject).length > 0) {
      setForm({
        ...form,
        nome: rowObject.nome,
        morada: rowObject.morada,
        empresa: rowObject.empresa,
        year: rowObject.year,
        numFornecedor: rowObject.numFornecedor,
        prazoPagamento: rowObject.prazoPagamento,
        idFornecedor: rowObject.idFornecedor,
      });
    }
  }, [rowObject]);

  const handleValues = (event, list) => {
    return list.filter((e) => e.value === event)[0]?.label;
  };
  return (
    <>
      <Modal show={isModalUpdatProviderShowing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar Fornecedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={form.nome || ""}
                  onChange={(e) => setField("nome", e.target.value)}
                  type="text"
                  isInvalid={!!errors.nome}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome}
                </Form.Control.Feedback>
              </Col>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Morada</Form.Label>
                <Form.Control
                  value={form.morada || ""}
                  onChange={(e) => setField("morada", e.target.value)}
                  type="text"
                  isInvalid={!!errors.morada}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.morada}
                </Form.Control.Feedback>
              </Col>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="6" md="6">
                <Form.Label>Empresa</Form.Label>
                <Dropdown
                  defaultValue={form.empresa}
                  list={companiesList}
                  handleSubmit={(e) =>
                    setField("empresa", handleValues(e, companiesList))
                  }
                  className={handleDropdownClassName(
                    form.empresa,
                    dropdownInvalid
                  )}
                />
                <div
                  style={{
                    width: "100%",
                    fontSize: "80%",
                    color: "#dc3545",
                  }}
                >
                  {errors.empresa}
                </div>
              </Col>
              <Col xl="6" md="6">
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  value={form.year || ""}
                  onChange={(e) => setField("year", e.target.value)}
                  type="text"
                  isInvalid={!!errors.year}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.year}
                </Form.Control.Feedback>
              </Col>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Prazo do Pagamento</Form.Label>
                <Form.Control
                  value={form.prazoPagamento || ""}
                  onChange={(e) => setField("prazoPagamento", e.target.value)}
                  type="text"
                  isInvalid={!!errors.prazoPagamento}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.prazoPagamento}
                </Form.Control.Feedback>
              </Col>
              <Col xl="6" md="6">
                <Form.Label>Nº Fornecedor</Form.Label>
                <Form.Control
                  value={form.numFornecedor || ""}
                  onChange={(e) => setField("numFornecedor", e.target.value)}
                  type="text"
                  isInvalid={!!errors.numFornecedor}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numFornecedor}
                </Form.Control.Feedback>
              </Col>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "15px",
          }}
        >
          <SimpleButton
            text={"Fechar"}
            variant={"liftworld-button-primary"}
            onClick={handleClose}
          />
          <SimpleButton
            text={"Guardar"}
            variant={"liftworld-button-primary"}
            onClick={handleSaveData}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUpdateProvider;
