export const REQUEST_USERS = 'REQUEST_USERS';
export const SUCCESS_REQUEST_USERS = 'SUCCESS_REQUEST_USERS';
export const FAIL_REQUEST_USERS = 'FAIL_REQUEST_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';

export const ADD_USER = 'ADD_USER';
export const SUCCESS_ADD_USER = 'SUCCESS_ADD_USER';
export const FAIL_ADD_USER = 'FAIL_ADD_USER';

export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER_PICTURE = 'REMOVE_USER_PICTURE';
export const SUCCESS_UPDATE_USER = 'SUCCESS_UPDATE_USER';
export const FAIL_UPDATE_USER = 'FAIL_UPDATE_USER';

export const DELETE_USER = 'DELETE_USER';
export const SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER';
export const CLEAR_ADD_USER = 'CLEAR_ADD_USER';
export const FAIL_DELETE_USER = 'FAIL_DELETE_USER';


export const CREATE_SIMPLE_USER = 'CREATE_SIMPLE_USER';
/* SHolanda - Begin */
export const LOAD_LEGACY_APPS = 'LOAD_LEGACY_APPS';
export const SET_LEGACY_APPS = 'SET_LEGACY_APPS';
export const LOAD_LEGACY_USERS = 'LOAD_LEGACY_USERS';
export const SET_LEGACY_USERS = 'SET_LEGACY_USERS';

export const LOAD_UNIK_USER_BY_ID = 'LOAD_UNIK_USER_BY_ID';
export const SET_UNIK_USER = 'SET_UNIK_USER';
export const CLEAR_UNIK_USER = 'CLEAR_UNIK_USER';

//TODO Remove it when CRUD Roles and Profiles it's done!
export const REQUEST_ALL_ROLES = 'REQUEST_ALL_ROLES';
export const SET_ROLES = 'SET_ROLES';

export const CLEAR_DELETE_USER = "CLEAR_DELETE_USER";

export const PULL_LEGACY_USERS = "PULL_LEGACY_USERS";
export const SET_PULLED_LEGACY_USERS = "SET_PULLED_LEGACY_USERS";
export const CLEAR_PULLED_LEGACY_USERS = "CLEAR_PULLED_LEGACY_USERS";

export const PULL_LEGACY_USERS_ONLY_APP = "PULL_LEGACY_USERS_ONLY_APP";

/*User settings for Automated Datacapture*/
export const UPDATE_USER_SETTINGS_ADATACAPTURE = "UPDATE_USER_SETTINGS_ADATACAPTURE";

export const REQUEST_EXTENDED_ATTRIBUTES = "REQUEST_EXTENDED_ATTRIBUTES";

export const SUCESS_EXTENDED_ATTRIBUTES = "SUCESS_EXTENDED_ATTRIBUTES";

export const CLEAR_EXTENDED_ATTRIBUTES = "CLEAR_EXTENDED_ATTRIBUTES";

export const loadLegacyApps = () => {
  return { type: LOAD_LEGACY_APPS }
}

export const setLegacyApps = (legacyApps) => {
  return { type: SET_LEGACY_APPS, data: legacyApps }
}

export const loadLegacyUsers = (payload) => {
  return { type: LOAD_LEGACY_USERS, data: payload }
}

export const setLegacyUsers = (legacyUsers) => {
  return { type: SET_LEGACY_USERS, data: legacyUsers }
}

export const loadUnikUserById = (id) => {
  return { type: LOAD_UNIK_USER_BY_ID, id }
}

export const setUnikUser = (unikUser) => {
  return { type: SET_UNIK_USER, data: unikUser }
}
/* SHolanda - End */

export const requestUsers = (payload) => {
  return {
    type: REQUEST_USERS,
    data: payload
  }
}
export const successRequestUsers = (payload) => {
  return {
    type: SUCCESS_REQUEST_USERS,
    data: payload
  }
}

export const failRequestUsers = (payload) => {
  return {
    type: FAIL_REQUEST_USERS,
    data: payload
  }
}

export const addUser = (payload) => {
  return {
    type: ADD_USER,
    data: payload
  }
}
export const successAddUser = (payload) => {
  return {
    type: SUCCESS_ADD_USER,
    data: payload
  }
}
export const failAddUser = (payload) => {
  return {
    type: FAIL_ADD_USER,
    data: payload
  }
}
export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    data: payload
  }
}
export const removeUserPicture = (id) => {
  return {
    type: REMOVE_USER_PICTURE,
    id
  }
}
export const successUpdateUser = (payload) => {
  return {
    type: SUCCESS_UPDATE_USER,
    data: payload
  }
}
export const failUpdateUser = (payload) => {
  return {
    type: FAIL_UPDATE_USER,
    data: payload
  }
}
export const deleteUser = (payload) => {
  return {
    type: DELETE_USER,
    data: payload
  }
}

export const successDeleteUser = (payload) => {
  return {
    type: SUCCESS_DELETE_USER,
    data: payload
  }
}

export const failDeleteUser = (payload) => {
  return {
    type: FAIL_DELETE_USER,
    data: payload
  }
}

export const clearUsers = () => {
  return { type: CLEAR_USERS }
}

export const clearUnikUser = () => {
  return { type: CLEAR_UNIK_USER }
}

export const requestAllRoles = () => {
  return { type: REQUEST_ALL_ROLES }
}

export const setRoles = (roles) => {
  return { type: SET_ROLES, roles }
}

export const createSimpleUser = (payload) => {
  return {
    type: CREATE_SIMPLE_USER,
    data: payload
  }
}

export const updateUserSettingsADatacapture = (payload) => {
  return {
    type: UPDATE_USER_SETTINGS_ADATACAPTURE,
    data: payload
  }
}

export const requestExtendedAttributes = (payload) => {
  return {
    type: REQUEST_EXTENDED_ATTRIBUTES,
    data: payload
  }
}
export const sucessExtendedAttributes = (payload) => {
  return {
    type: SUCESS_EXTENDED_ATTRIBUTES,
    data: payload
  }
}

export const clearExtendedAttributes = () => ({ type: CLEAR_EXTENDED_ATTRIBUTES })
export const clearDeleteUser = () => ({ type: CLEAR_DELETE_USER })
export const clearAddUser = () => ({ type: CLEAR_ADD_USER })
export const pullLegacyUsersOnlyApp = (appName) => ({ type: PULL_LEGACY_USERS_ONLY_APP, appName })
export const pullLegacyUsers = () => ({ type: PULL_LEGACY_USERS })
export const setPulledLegacyUsers = pulledLegacyUser => ({ type: SET_PULLED_LEGACY_USERS, pulledLegacyUser })
export const clearTotalPulledLegacy = () => ({ type: CLEAR_PULLED_LEGACY_USERS })
