import React, { useState, useEffect } from 'react';
import '~/assets/css/icons.css';
import '~/assets/css/styles.css';
import { withNamespaces } from 'react-i18next';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateBaseWidget,
  getBaseWidgets
} from '~/pages/Admin/SystemSettings/actions';

import PersistSource from './PersistSource';
import SourceTable from './SourceTable';
const devLogConsole = require("~/utils/devLog");
const ConfigureServicoGenericoWidget = () => {

  const [selectedSource, setSelectedSource] = useState(undefined);
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(getBaseWidgets());
  }, [dispatch, isUpdated]);

  const { baseWidgets, isUpdated } = useSelector(state => state.adminConfigReducer);
  const widget = baseWidgets.find(item => item.component === 'ServicoGenericoWidget');

  const editSource = (newConfiguration) => {
    newConfiguration.type = "com.uniksystem.backend.mongo.model.widget.configuration.PowerBIConfiguration";//important for java
    let newArray;
    if (selectedSource !== undefined) {
      newArray = widget.configurations.map(item => {
        if (item.id === selectedSource.id) {
          newConfiguration.id = selectedSource.id;
          return newConfiguration;
        }
        else return item;
      })
    }
    else {
      newArray = widget.configurations;
      newArray.push(newConfiguration);
    }
    const payload = {
      ...widget,
      configurations: newArray,
      idToRemove:""
    };
    //devLogConsole(payload)
    dispatch(updateBaseWidget(payload));
    history.push(path);
  };

  return (
    <Switch>
      <Route path={`${path}/edit`}>
        <PersistSource
          selectedSource={selectedSource}
          editSource={editSource} />
      </Route>
      <Route path={path}>
        <SourceTable
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          widget={widget}
        />
      </Route>
    </Switch>
  )
}

export default withNamespaces()(ConfigureServicoGenericoWidget); 