import React, { useState, useEffect, useRef } from "react";
import Dropdown from "~/components/Dropdown";
import ReactExport from "react-export-excel";
import { Button, Col, Form } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { DateTime } from "luxon";
import Helper from "./Helper";
import { handlePrint } from "~/utils";
import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import "./Simulator.css";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUserData,
  saveUserDataExcel,
} from "~/store/ducks/simulator/actionTypes";
import { getMarcacaoFerias } from "~/store/ducks/portalrh/actionTypes";
import i18n from "~/i18n";
import logo from "~/assets/img/logos/unik-logo.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Simulator = ({
  t,
  showButtonSave,
  collaboratorSavedData,
  onBooleanShowModal,
  isExternal,
  collaboratorProperties,
}) => {
  const { codes } = useSelector((state) => state.simulatorReducer);
  const { simuladorFerias } = useSelector((state) => state.portalrhReducer);
  const [contractSelected, setContractSelected] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [hoursPerWeek, setHourPerWeek] = useState(0);
  const [baseSalary, setBaseSalary] = useState(0);
  const [averageBaseSalary, setAverageBaseSalary] = useState(0);
  const [diuturnities, setDiuturnities] = useState(0);
  const [complement, setComplement] = useState(0);
  const [initiative, setInitiative] = useState("");
  const [durationTime, setDurationTime] = useState();
  const [cause, setCause] = useState("");
  const [valuePerHour, setValuePerHour] = useState(0);
  const [vacationSubsidy, setVacationSubsidy] = useState(0);
  const [unusedVacation, setUnusedVacation] = useState(0);
  const [trainingHours, setTrainingHours] = useState(0);
  const [outputVacationSub, setOutputVacationSub] = useState(0);
  const [outputUneusedVac, setOutputUnusedVac] = useState(0);
  const [outputVacLastYear, setOutputVacLastYear] = useState(0);
  const [outputVacSubLastYear, setOutputVacSubLastYear] = useState(0);
  const [hide, setHide] = useState(false);
  const [hideOutputs, setHideOutputs] = useState(false);
  const [hideMessageErrors, setHideMessageErrors] = useState(false);
  const [hideErrorSaveSimulator, setHideErrorSaveSimulator] = useState(false);
  const [hideSuccessSimulator, setHideSuccessSimulator] = useState(false);
  const [outputCrhistSub, setOutputCrhistSub] = useState(0);
  const [outputTrainingHours, setOutputTrainingHours] = useState(0);
  const [outputCompensation, setOutputCompensation] = useState(0);
  const [outputTaxFreeCompensation, setOutputTaxFreeCompensation] = useState(0);
  const [outputTotal, setOutputTotal] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [formErrorsSaveSimulator, setFormErrorSaveSimulator] = useState({});
  const [formMessageSucess, setFormMessageSucess] = useState({});
  const [messageError, setMessageError] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [firstDate, setFirstDate] = useState();
  const [lastDate, setLastDate] = useState();
  const [dateNow, setDateNow] = useState();

  const [diffDate, setDiffDate] = useState();
  const [diffDateYears, setDiffDateYears] = useState();
  const [diffDateTermination, setDiffDateTermination] = useState();
  const [diffToHuman, setDiffToHuman] = useState();
  const [dateBeforeLaw, setDateBeforeLaw] = useState();
  const [dateAfterLaw, setDateAfterLaw] = useState();
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [messageErrorSave, setMessageErrorSave] = useState();
  const [messageSuccess, setMessageSuccess] = useState();
  const [isBigger, setIsBigger] = useState(false);
  const [collaboratorName, setCollaboratorName] = useState();
  const [collaboratorNum, setCollaboratorNum] = useState();
  const [collaboratorContent, setCollaboratorContent] = useState();
  const [printContract, setPrintContrac] = useState();
  const [printFreeSimulator, setPrintFreeSimulator] = useState();
  const [printInicitive, setPrintIniciative] = useState();
  const [printCause, setPrintCause] = useState();
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [typeOfContractList, setTypeOfContractList] = useState([
    { label: t("simulator.openEndedContract"), value: "CTInde" },
    { label: t("simulator.uncertianTermContract"), value: "CTInce" },
    { label: t("simulator.fixedTermContract"), value: "CTCert" },
  ]);

  const contractType = [
    { label: t("simulator.openEndedContract"), value: "CTInde" },
    { label: t("simulator.uncertianTermContract"), value: "CTInce" },
    { label: t("simulator.fixedTermContract"), value: "CTCert" },
  ];

  useEffect(() => {
    if (
      showButtonSave &&
      collaboratorSavedData !== undefined &&
      collaboratorSavedData.funcionarioNum &&
      collaboratorSavedData.codEntidade
    ) {
      const payload = {
        entidade: collaboratorSavedData.codEntidade,
        numFunc: collaboratorSavedData.funcionarioNum,
        ano: new Date().getFullYear(),
      };
      dispatch(getMarcacaoFerias(payload, true));
    }
  }, [collaboratorSavedData]);

  useEffect(() => {
    if (Object.keys(simuladorFerias).length > 0) {
      setUnusedVacation(simuladorFerias.saldoAprovado);
    }
  }, [simuladorFerias]);

  //Translations for URÇ Free Simulator

  useEffect(() => {
    if (isExternal) {
      if (window.location.href.includes("/")) {
        const lng = window.location.href.split("/").pop();
        if (lng) {
          window.localStorage.setItem("lng", lng);
          i18n.changeLanguage(lng);
        }
      }
    }
  }, [window.location.href, isExternal]);

  const dispatch = useDispatch();
  const isEmpty = useRef(true);

  const tooltipData = [
    {
      id: 1,
      value: t("simulator.toolTips.1"),
    },
    {
      id: 2,
      value: t("simulator.toolTips.2"),
    },
    {
      id: 3,
      value: t("simulator.toolTips.3"),
    },
    {
      id: 4,
      value: t("simulator.toolTips.4"),
    },
    {
      id: 5,
      value: t("simulator.toolTips.5"),
    },
    {
      id: 6,
      value: t("simulator.toolTips.6"),
    },
    {
      id: 7,
      value: t("simulator.toolTips.7"),
    },
    {
      id: 8,
      value: t("simulator.toolTips.6"),
    },
    {
      id: 9,
      value: t("simulator.toolTips.9"),
    },
    {
      id: 10,
      value: t("simulator.toolTips.9"),
    },
    {
      id: 11,
      value: t("simulator.toolTips.9"),
    },
    {
      id: 12,
      value: t("simulator.toolTips.10"),
    },
    {
      id: 13,
      value: t("simulator.toolTips.11"),
    },
    {
      id: 14,
      value: t("simulator.toolTips.12"),
    },
    {
      id: 15,
      value: t("simulator.toolTips.13"),
    },
    {
      id: 16,
      value: t("simulator.toolTips.14"),
    },
  ];

  const initiativeList = [
    { label: t("simulator.initiative.employer"), value: "Empr" },
    { label: t("simulator.initiative.employee"), value: "Trab" },
  ];

  const causeList = [
    { label: t("simulator.fairCause.yes"), value: "Y" },
    { label: t("simulator.fairCause.no"), value: "N" },
  ];

  const handleContract = (e) => {
    setContractSelected(e);
  };

  const handleInitiative = (e) => {
    setInitiative(e);
  };

  const handleStartTime = (e) => {
    setStartTime(e);
  };

  const handlePerWeek = (e) => {
    if (contractSelected !== "CTInde") {
      setHourPerWeek(Number(e.target.value));
    } else {
      setHourPerWeek(0);
    }
  };

  const handleBaseSalary = (e) => {
    setBaseSalary(Number(e.target.value));
  };

  const handleAverageBaseSalary = (e) => {
    setAverageBaseSalary(Number(e.target.value));
  };

  const handleDiuturnities = (e) => {
    setDiuturnities(parseInt(e.target.value));
  };

  const handleComplement = (e) => {
    setComplement(Number(e.target.value));
  };

  const handleCause = (e) => {
    setCause(e);
  };

  const handleValuePerHour = (e) => {
    setValuePerHour(Number(e.target.value));
  };

  const handleVacationSubsidy = (e) => {
    setVacationSubsidy(Number(e.target.value));
  };

  const handleUnusedVacation = (e) => {
    setUnusedVacation(Number(e.target.value));
  };

  const handleTrainingHours = (e) => {
    setTrainingHours(Number(e.target.value));
  };

  const handleHideOutputs = () => {
    setHideOutputs(true);
  };

  useEffect(() => {
    if (collaboratorSavedData) {
      setCollaboratorName(collaboratorSavedData.nome);
      setCollaboratorNum(collaboratorSavedData.funcionarioNum);
    }
  }, [collaboratorSavedData]);

  useEffect(() => {
    if (diffDate) {
      setDurationTime(diffToHuman);
    } else {
      setDurationTime();
    }
  }, [diffDate, diffToHuman]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setHideMessageErrors(false);
    setFormErrors(
      validate(
        contractSelected,
        startTime,
        endTime,
        initiative,
        cause,
        baseSalary
      )
    );
    setIsSubmit(true);

    if (
      startTime < endTime &&
      startTime &&
      endTime &&
      contractSelected &&
      initiative &&
      cause &&
      baseSalary
    ) {
      calcDiffTime(
        diffDate,
        diffDateTermination,
        dateBeforeLaw,
        dateAfterLaw,
        diffDateYears,
        dateNow
      );
      handleHideOutputs();
    }
  };

  useEffect(() => {
    if (showButtonSave) {
      if (collaboratorProperties && collaboratorProperties !== undefined) {
        if (
          collaboratorProperties?.diuturnites &&
          collaboratorProperties?.diuturnites !== undefined
        ) {
          setDiuturnities(collaboratorProperties?.diuturnites);
        }
        if (
          collaboratorProperties?.valuePerHour &&
          collaboratorProperties?.valuePerHour !== undefined
        ) {
          setValuePerHour(collaboratorProperties?.valuePerHour);
        }

        if (
          collaboratorProperties?.baseSalary &&
          collaboratorProperties?.baseSalary !== undefined
        ) {
          setBaseSalary(collaboratorProperties?.baseSalary);
        }

        if (
          collaboratorProperties?.trainingHours &&
          collaboratorProperties?.trainingHours !== undefined
        ) {
          setTrainingHours(collaboratorProperties?.trainingHours);
        }
        if (
          collaboratorProperties?.startTime &&
          collaboratorProperties?.startTime !== undefined
        ) {
          setStartTime(new Date(collaboratorProperties?.startTime));
        }

        setCollaboratorContent(collaboratorProperties);

        const selectLabels = (data, savedData, state, setState) => {
          for (let i = 0; i < data.length; i++) {
            if (!state && data[i].value == savedData) {
              data[i]["selected"] = true;

              setState(savedData);
            } else if (data[i].value == state) {
              data[i]["selected"] = false;
              setState(state);
            }
          }
        };
        if (collaboratorProperties?.contractsValues) {
          selectLabels(
            typeOfContractList,
            collaboratorProperties.contractsValues,
            contractSelected,
            setContractSelected
          );
        }
      }
    }
  }, [
    collaboratorProperties,
    contractSelected,
    showButtonSave,
    typeOfContractList,
  ]);

  const handleResetoutputs = () => {
    setHideErrorSaveSimulator(true);
    setHideSuccessSimulator(true);
    setHideMessageErrors(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setHideOutputs(false);
    setCleanDropdown(true);
    setContractSelected();
    setDiffDate();
    setInitiative();
    setStartTime();
    setEndTime();
    setHourPerWeek(0);
    setBaseSalary(0);
    setAverageBaseSalary(0);
    setDiuturnities(0);
    setComplement(0);
    setCause();
    setValuePerHour(0);
    setVacationSubsidy(0);
    setUnusedVacation(0);
    setTrainingHours(0);
    if (showButtonSave) {
      onBooleanShowModal(true);
    }
  };
  const handleSaveData = () => {
    setHideErrorSaveSimulator(false);
    setHideSuccessSimulator(false);

    //if the user doesn't calculate the simulator before click the Save Simulator's button, will appear a message error
    setFormErrorSaveSimulator(validateSaveSimulator(outputTotal, dateNow));

    //validate success messages
    setFormMessageSucess(validateSucessMessage(outputTotal, dateNow));

    const userDataForm = {
      contractSelected: contractSelected,
      hoursPerWeek: hoursPerWeek,
      initiative: initiative,
      fairCause: cause,
      baseSalary: baseSalary,
      baseSalaryAverage: averageBaseSalary,
      diuturnites: diuturnities,
      complements: complement,
      valuePerHour: valuePerHour,
      vacationAllowance: vacationSubsidy,
      unusedVacation: unusedVacation,
      trainingHoursLast3Y: trainingHours,
      vacationAllowanceResult: outputVacationSub,
      unusedVacationResult: outputUneusedVac,
      trainingHoursLast3YResult: outputTrainingHours,
      vacationLastYResult: outputVacLastYear,
      vacationSubLastYResult: outputVacSubLastYear,
      crhistmasSubResult: outputCrhistSub,
      compensationResult: outputCompensation,
      taxFreeCompensationResult: outputTaxFreeCompensation,
      totalAmountResult: outputTotal,
      startTime: startTime,
      endTime: endTime,
      diffDate: diffToHuman,
      dateNow: DateTime.now(),
      name: collaboratorSavedData.nome,
      collaboratorNum: collaboratorSavedData.funcionarioNum,
      collaboratorEntity: collaboratorSavedData.codEntidade,
    };
    if (outputTotal && dateNow) {
      dispatch(saveUserData(userDataForm));
    }
  };

  //to convert inputs values to euro with dot and comma
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  //to print the screen
  const componentRef = useRef();

  const validate = () => {
    const errors = {};
    if (!contractSelected) {
      errors.contractSelected = t("simulator.errorMessage.1");
    }

    if (startTime > endTime) {
      errors.startTime = t("simulator.errorMessage.2");
      errors.endTime = t("simulator.errorMessage.3");
    }

    if (!startTime) {
      errors.startTime = t("simulator.errorMessage.2");
    }
    if (!endTime) {
      errors.endTime = t("simulator.errorMessage.3");
    }
    if (!initiative) {
      errors.initiative = t("simulator.errorMessage.4");
    }
    if (!cause) {
      errors.cause = t("simulator.errorMessage.5");
    }
    if (!baseSalary) {
      errors.baseSalary = t("simulator.errorMessage.6");
    }

    if (!hoursPerWeek) {
      errors.hoursPerWeek = t("simulator.errorMessage.7");
    }

    if (
      (contractSelected !== "CTInde" && !hoursPerWeek) ||
      !baseSalary ||
      !cause ||
      !initiative ||
      !endTime ||
      !startTime ||
      !contractSelected ||
      startTime > endTime
    ) {
      setMessageError((errors.submitButton = t("simulator.errorMessage.8")));
      setTimeout(() => {
        setMessageError("");
      }, 5000);
    }

    return errors;
  };

  //form error for Save Simulator
  useEffect(() => {
    if (Object.keys(formErrorsSaveSimulator).length === 0 && isSubmit) {
    }
  }, [contractSelected, formErrorsSaveSimulator, isSubmit]);

  const validateSaveSimulator = () => {
    const errors = {};
    if (!dateNow && !outputTotal) {
      setMessageErrorSave((errors.saveDate = t("simulator.errorMessage.9")));
      setTimeout(() => {
        setMessageErrorSave("");
      }, 5000);
    }
    return errors;
  };

  //form  success messages
  useEffect(() => {
    if (Object.keys(formMessageSucess).length === 0 && isSubmit) {
    }
  }, [contractSelected, formMessageSucess, isSubmit]);

  const validateSucessMessage = () => {
    const success = {};
    if (dateNow && outputTotal) {
      setMessageSuccess((success.saveDate = t("simulator.successMessage.1")));
      setTimeout(() => {
        setMessageSuccess("");
      }, 5000);
    }
    return success;
  };

  //share the information in real time
  useEffect(() => {
    if (startTime && endTime) {
      const dateStart = DateTime.fromISO(startTime.toISOString());
      const dateEnd = DateTime.fromISO(endTime.toISOString());

      //getting the 01/01 of date termination
      let newDateTermination = new Date(dateEnd);

      newDateTermination.setMonth(0);

      newDateTermination.setDate(0);

      newDateTermination = DateTime.fromISO(newDateTermination.toISOString());

      let dateDiffLastYear = dateEnd.diff(newDateTermination, ["days"]);

      //days between startTime and endTime
      let durationDate = dateEnd.diff(dateStart, ["years", "months", "days"]);
      durationDate = durationDate.plus({ days: 1 });

      let durationDateArr = durationDate.toString().split("").slice(1);

      for (let i = 0; i < durationDateArr.length; i++) {
        if (durationDateArr[i] === "D") {
          durationDateArr[i] = durationDateArr[i].replace(
            "D",
            t("simulator.days")
          );
        }
        if (durationDateArr[i] === "Y") {
          durationDateArr[i] = durationDateArr[i].replace(
            "Y",
            t("simulator.years")
          );
        }
        if (durationDateArr[i] === "M") {
          durationDateArr[i] = durationDateArr[i].replace(
            "M",
            t("simulator.months")
          );
        }
      }

      let durationDateToHuman = durationDateArr.join("");

      //diff in years to calc
      let durationDateYears = dateEnd.diff(dateStart, ["years"]);
      durationDateYears = durationDateYears.toString().replace(/[a-z]/gi, "");
      durationDateYears = Number(durationDateYears);

      //date Now
      let dateNow = DateTime.now().toFormat("dd-MM-yyyy");
      let dateLaw = DateTime.fromISO("2019-09-04");
      let dateBeforeLaw;
      let dateAfterLaw;

      //dateEnd less 3 years
      let dateEndLess3 = dateEnd.plus({ years: -3 });

      //condition for training law (datend - 3 years diff 04/09/2019)
      if (dateEndLess3 < dateLaw) {
        dateBeforeLaw = dateLaw.diff(dateEndLess3, "months");
      }
      //condition for training law (datend diff 04/09/2019)
      if (dateEnd > dateLaw) {
        dateAfterLaw = dateEnd.diff(dateLaw, "months");
      }

      //condition for contract a term cert if the date diff is bigger than 2 years
      if (durationDateYears > 2 && contractSelected === "CTCert") {
        setIsBigger(true);
      } else {
        setIsBigger(false);
      }

      setDateNow(dateNow);
      setDiffToHuman(durationDateToHuman);
      setDiffDateYears(durationDateYears);
      setDiffDate(durationDate);
      setDiffDateTermination(dateDiffLastYear);
      setDateBeforeLaw(dateBeforeLaw);
      setDateAfterLaw(dateAfterLaw);
      //to the compensation
      setFirstDate(dateStart);
      setLastDate(dateEnd);
    }
  }, [startTime, endTime, t, contractSelected]);

  const calcDiffTime = () => {
    // setOutputTaxFreeCompensation
    const taxFreeCompensationCalc = () => {
      setOutputTaxFreeCompensation(averageBaseSalary * diffDateYears * 1);
    };
    taxFreeCompensationCalc();

    // calculation Compensation for termination of contract
    let remuneration = baseSalary + diuturnities + complement;

    let daylyRemuneration = (
      (baseSalary + diuturnities + complement) /
      30
    ).toFixed(2);

    let date1 = DateTime.fromISO("2012-10-31");
    let date2 = DateTime.fromISO("2012-11-01");
    let date3 = DateTime.fromISO("2013-09-30");
    let date4 = DateTime.fromISO("2013-10-01");
    let date5 = DateTime.fromISO("2011-11-01");
    let date2023 = DateTime.fromISO("2023-05-01");

    let limit = remuneration * 12;
    let result = 0;
    let isBiggerTreeYears;
    if (firstDate) {
      isBiggerTreeYears = firstDate;
      isBiggerTreeYears = isBiggerTreeYears.plus({ years: 3 });
    }
    const compensationForCession = () => {
      if (initiative === "Trab" && cause === "N") {
        setOutputCompensation(0);
        setHide(false);
      } else if (initiative === "Trab" && cause === "Y") {
        setOutputCompensation(0);
        setHide(true);
      } else if (initiative === "Empr" && cause === "N") {
        //para contratos a termo certo
        if (contractSelected === "CTCert") {
          let diff0 = lastDate.diff(firstDate, "years");
          diff0 = diff0.toString().replace(/[a-z]/gi, "");
          diff0 = Number(diff0);
          diff0 = diff0.toFixed(4);
          result += daylyRemuneration * diff0 * 18;

          //contratos incertos
        } else if (contractSelected === "CTInce") {
          let diff = lastDate.diff(firstDate, "years", true);
          diff = diff.toString().replace(/[a-z]/gi, "");
          diff = Number(diff);

          if (diff < 3 || diff === 3) {
            result += daylyRemuneration * diff * 18;
            if (result === limit || result > limit) {
              return setOutputCompensation(result);
            }
          } else if (diff > 3) {
            let dateMore3 = firstDate.plus({ years: 3 });

            let datefor3 = dateMore3.plus({ days: -1 });

            let diff = datefor3.diff(firstDate, "years");
            diff = diff.toString().replace(/[a-z]/gi, "");
            result += daylyRemuneration * diff * 18;

            if (result === limit || result > limit) {
              return setOutputCompensation(result);
            }
            let diff2 = lastDate.diff(datefor3, "years");
            diff2 = diff2.toString().replace(/[a-z]/gi, "");
            diff2 = Number(diff2);
            diff2 = diff2.toFixed(4);
            result += daylyRemuneration * diff2 * 12;

            if (result === limit || result > limit) {
              return setOutputCompensation(result);
            }
          }
        } else {
          //CONTRATOS CELEBRADOS ANTES DE 01/11/2011
          if (firstDate < date5 && contractSelected === "CTInde") {
            if (lastDate > date1 || lastDate === date1) {
              let diff = date1.diff(firstDate, "years");

              diff = diff.toString().replace(/[a-z]/gi, "");
              diff = Number(diff);
              diff = diff.toFixed(4);
              result = daylyRemuneration * diff * 30;

              if (result === limit || result > limit) {
                return setOutputCompensation(result);
              }
            }
            if (
              lastDate > date2 ||
              lastDate === date2 ||
              lastDate > date3 ||
              lastDate === date3
            ) {
              let diff2 = date3.diff(date2, "years");
              diff2 = diff2.toString().replace(/[a-z]/gi, "");
              diff2 = Number(diff2);
              diff2 = diff2.toFixed(4);
              result += daylyRemuneration * diff2 * 20;

              if (result === limit || result > limit) {
                return setOutputCompensation(result);
              }
            }
            //CONTRATOS CELEBRADOS DEPOIS DE 01/10/2013
            if (lastDate > date4 || lastDate === date4) {
              if (isBiggerTreeYears > date4) {
                let diff3 = isBiggerTreeYears.plus({ days: -1 });
                diff3 = isBiggerTreeYears.diff(date4, "years");
                diff3 = diff3.toString().replace(/[a-z]/gi, "");
                diff3 = Number(diff3);
                diff3 = diff3.toFixed(4);
                result += daylyRemuneration * diff3 * 18;

                let diff4 = isBiggerTreeYears.plus({ days: -1 });
                diff4 = lastDate.diff(isBiggerTreeYears, "years");

                diff4 = diff4.toString().replace(/[a-z]/gi, "");
                diff4 = Number(diff4);
                diff4 = diff4.toFixed(4);
                result += daylyRemuneration * diff4 * 12;

                if (result === limit || result > limit) {
                  return setOutputCompensation(result);
                }
              } else if (isBiggerTreeYears < date4) {
                //for 12 days
                let diff5 = isBiggerTreeYears.plus({ days: -1 });
                diff5 = lastDate.diff(date4, "years");

                diff5 = diff5.toString().replace(/[a-z]/gi, "");
                diff5 = Number(diff5);
                diff5 = diff5.toFixed(4);
                result += daylyRemuneration * diff5 * 12;
              }
            }
          }
          //CONTRATOS CELEBRADOS ENTRE 01/11/2011 E 30/09/2013
          if (
            (firstDate > date5 || firstDate === date5) &&
            (firstDate < date3 || firstDate === date3) &&
            contractSelected !== "CTCert"
          ) {
            let diff6 = date3.diff(firstDate, "years"); // aquii
            diff6 = diff6.toString().replace(/[a-z]/gi, "");
            diff6 = Number(diff6);
            diff6 = diff6.toFixed(4);
            result += daylyRemuneration * diff6 * 20;

            if (result === limit || result > limit) {
              return setOutputCompensation(result);
            }
            // CONTRATOS CELEBRADOS DEPOIS DE 01/10/2013
            if (lastDate > date4 || lastDate === date4) {
              if (isBiggerTreeYears > date4) {
                //for 18 days
                let diff3 = isBiggerTreeYears.plus({ days: -1 });
                diff3 = isBiggerTreeYears.diff(date4, "years");
                diff3 = diff3.toString().replace(/[a-z]/gi, "");
                diff3 = Number(diff3);
                diff3 = diff3.toFixed(4);
                result += daylyRemuneration * diff3 * 18;

                //for 12 days
                let diff4 = isBiggerTreeYears.plus({ days: -1 });
                diff4 = lastDate.diff(isBiggerTreeYears, "years");

                diff4 = diff4.toString().replace(/[a-z]/gi, "");
                diff4 = Number(diff4);
                diff4 = diff4.toFixed(4);
                result += daylyRemuneration * diff4 * 12;

                if (result === limit || result > limit) {
                  return setOutputCompensation(result);
                }
              } else if (isBiggerTreeYears < date4) {
                //for 12 days
                let diff5 = lastDate.diff(date4, "years");
                diff5 = diff5.toString().replace(/[a-z]/gi, "");
                diff5 = Number(diff5);
                diff5 = diff5.toFixed(4);
                result += daylyRemuneration * diff5 * 12;
              }
            }
          }
          // CONTRATOS CELEBRADOS DEPOIS DE 01/10/2013
          if (
            (firstDate > date4 || firstDate === date4) &&
            firstDate < date2023 &&
            contractSelected !== "CTCert"
          ) {
            let diff7 = lastDate.diff(firstDate, "years");

            diff7 = diff7.toString().replace(/[a-z]/gi, "");
            diff7 = Number(diff7);
            diff7 = diff7.toFixed(4);
            result += daylyRemuneration * diff7 * 12;
          }
          // CONTRATOS CELEBRADOS DEPOIS DE 01/05/2023
          if (
            (firstDate > date2023 || firstDate === date2023) &&
            contractSelected === "CTInde"
          ) {
            let diff1 = isBiggerTreeYears.plus({ days: -1 });
            diff1 = lastDate.diff(date2023, "years");

            diff1 = diff1.toString().replace(/[a-z]/gi, "");
            diff1 = Number(diff1);
            diff1 = diff1.toFixed(4);
            result += daylyRemuneration * diff1 * 14;
          }
          // CONTRATOS CELEBRADOS DEPOIS DE 01/05/2023
          if (
            (firstDate > date2023 || firstDate === date2023) &&
            contractSelected !== "CTCert" &&
            contractSelected !== "CTInde"
          ) {
            let diff8 = lastDate.diff(firstDate, "years");

            diff8 = diff8.toString().replace(/[a-z]/gi, "");
            diff8 = Number(diff8);
            diff8 = diff8.toFixed(4);
            result += daylyRemuneration * diff8 * 14;
          }
        }
      } else if (initiative === "Empr" && cause === "Y") {
        setOutputCompensation(0);
        setHide(false);
      }

      setOutputCompensation(result.toFixed(2));
    };
    compensationForCession();
    let durationDateArray;
    let durationDateNumbers;
    if (diffDate) {
      durationDateArray = diffDate.toHuman().split(" ").map(Number);
      //letters removed
      durationDateNumbers = durationDateArray.filter((e) => !Number.isNaN(e));
    }

    let dateDiffArray;
    let durationDays;
    if (diffDateTermination) {
      dateDiffArray = diffDateTermination.toHuman().split(" ").map(Number);

      durationDays = dateDiffArray.filter((e) => !Number.isNaN(e));
    }

    //calc rdsf
    let rdsf = (baseSalary + diuturnities + complement) / 22;
    //
    let rds = (baseSalary + diuturnities + complement) / 365;

    //calculation unused Vacation
    const unusedVacationCalc = (rdsf) => {
      setOutputUnusedVac(unusedVacation * rdsf);
    };

    unusedVacationCalc(rdsf);

    //calculation unused Vacation
    const christmasSubsidyCalc = (durationDays, rds) => {
      if (durationDays && rds) {
        setOutputCrhistSub(durationDays[0] * rds);
      }
    };

    christmasSubsidyCalc(durationDays, rds);

    //before law
    let monthsBeforeLaw;
    if (dateBeforeLaw) {
      monthsBeforeLaw = dateBeforeLaw.toString().replace(/[a-z]/gi, "");
      monthsBeforeLaw = parseInt(monthsBeforeLaw); // TALVEZ MUDE PARA PARSEFLOAT
    }

    //after law
    let monthsAfterLaw;
    if (dateAfterLaw) {
      monthsAfterLaw = dateAfterLaw.toString().replace(/[a-z]/gi, "");
      monthsAfterLaw = parseInt(monthsAfterLaw);
    }

    //calculate the training value
    const valueOfTrainingCalc = (monthsBeforeLaw, monthsAfterLaw) => {
      let valueBefore = 0;
      let valueAfter = 0;

      if (monthsBeforeLaw) {
        valueBefore = (35 * monthsBeforeLaw) / 12;
        valueBefore = Number(valueBefore);
      }

      if (monthsAfterLaw) {
        valueAfter = (40 * monthsAfterLaw) / 12;
        valueAfter = Number(valueAfter);
      }

      let totalValue = valueBefore + valueAfter;

      let diffHours = totalValue - trainingHours;

      setOutputTrainingHours(diffHours * valuePerHour);
    };
    valueOfTrainingCalc(monthsBeforeLaw, monthsAfterLaw);

    const conditionDurationDate = (durationDate, rdsf) => {
      if (durationDate[0] === 0) {
        let firstCalc = 2 * durationDate[1] * rdsf - vacationSubsidy;

        setOutputVacationSub(firstCalc);

        //holidays in date termiantion
        setOutputVacLastYear(0);
        setOutputVacSubLastYear(0);
      } else if (durationDate[0] > 0) {
        const secCalc = 2 * 11 * rdsf - vacationSubsidy;

        setOutputVacationSub(secCalc);

        //holidays in date termination
        setOutputVacLastYear(durationDays[0] * rds);

        //holadays vacation in date termination
        setOutputVacSubLastYear(durationDays[0] * rds);
      } else {
        return null;
      }
    };

    //Condtion to select the type of contract
    const selectWay = (durationDate, rdsf) => {
      if (contractSelected === "CTInde") {
        conditionDurationDate(durationDate, rdsf);
        let total =
          Number(outputVacationSub) +
          Number(outputUneusedVac) +
          Number(outputTrainingHours) +
          Number(outputVacLastYear) +
          Number(outputVacSubLastYear) +
          Number(outputCrhistSub) +
          Number(outputCompensation);
        setOutputTotal(total);
      } else if (contractSelected === "CTCert") {
        conditionDurationDate(durationDate, rdsf);
      } else if (contractSelected === "CTInce") {
        conditionDurationDate(durationDate, rdsf);
      } else {
        return null;
      }
    };

    selectWay(durationDateNumbers, rdsf);
  };

  useEffect(() => {
    if (contractSelected === "CTInde") {
      let total =
        Number(outputVacationSub) +
        Number(outputUneusedVac) +
        Number(outputTrainingHours) +
        Number(outputVacLastYear) +
        Number(outputVacSubLastYear) +
        Number(outputCrhistSub) +
        Number(outputCompensation);
      setOutputTotal(total);
    } else {
      let total =
        Number(trainingHours) +
        Number(outputVacationSub) +
        Number(outputUneusedVac) +
        Number(outputTrainingHours) +
        Number(outputVacLastYear) +
        Number(outputVacSubLastYear) +
        Number(outputCrhistSub) +
        Number(outputCompensation);
      setOutputTotal(total);
    }
  }, [
    contractSelected,
    outputCompensation,
    outputCrhistSub,
    outputTrainingHours,
    outputUneusedVac,
    outputVacLastYear,
    outputVacSubLastYear,
    outputVacationSub,
    trainingHours,
  ]);

  useEffect(() => {
    if (contractSelected) {
      for (let i = 0; i < typeOfContractList.length; i++) {
        if (typeOfContractList[i].value == contractSelected) {
          setPrintContrac(typeOfContractList[i].label);
        }
      }
    }
  }, [contractSelected, typeOfContractList]);

  useEffect(() => {
    if (contractSelected) {
      for (let i = 0; i < contractType.length; i++) {
        if (contractType[i].value == contractSelected) {
          setPrintFreeSimulator(contractType[i].label);
        }
      }
    }
  }, [contractSelected, contractType]);

  useEffect(() => {
    const printHandling = (list, valueSelected, setValue) => {
      for (let i = 0; i < list.length; i++) {
        if (list[i].value == valueSelected) {
          setValue(list[i].label);
        }
      }
    };
    if (contractSelected) {
      printHandling(typeOfContractList, contractSelected, setPrintContrac);
    }

    if (initiative) {
      printHandling(initiativeList, initiative, setPrintIniciative);
    }

    if (cause) {
      printHandling(causeList, cause, setPrintCause);
    }
  }, [
    cause,
    causeList,
    contractSelected,
    initiative,
    initiativeList,
    typeOfContractList,
  ]);

  const handleExcell = () => {
    setHideErrorSaveSimulator(false);
    setHideSuccessSimulator(false);

    //if the user doesn't calculate the simulator before click the Save Simulator's button, will appear a message error
    setFormErrorSaveSimulator(validateSaveSimulator(outputTotal, dateNow));
    const formatterExcell = new Intl.NumberFormat("pt-PT", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const parametros = [
      {
        code: "ferias",
        descricao: "Subsidio de Férias",
        valor: parseFloat(parseFloat(outputVacationSub).toFixed(2)),
      },
      {
        code: "feriasNaoGozadas",
        descricao: "Férias não gozadas",
        valor: parseFloat(parseFloat(outputUneusedVac).toFixed(2)),
      },
      {
        code: "compensacaoFormacao",
        descricao: "Valor de compensação de horas de formação",
        valor: parseFloat(parseFloat(outputTrainingHours).toFixed(2)),
      },
      {
        code: "ferias",
        descricao: "Férias",
        valor: parseFloat(parseFloat(outputVacLastYear).toFixed(2)),
      },
      {
        code: "ferias",
        descricao: "Subsídio de férias ano anterior",
        valor: parseFloat(parseFloat(outputVacSubLastYear).toFixed(2)),
      },
      {
        code: "natal",
        descricao: "Subsídio de Natal",
        valor: parseFloat(parseFloat(outputCrhistSub).toFixed(2)),
      },
      {
        code: "compensacaoCessacao",
        descricao: "Compensação por cessação do contrato",
        valor: parseFloat(parseFloat(outputCompensation).toFixed(2)),
      },
      {
        code: "compensacaoIsenta",
        descricao: "Compensação isenta de impostos (IRS)",
        valor: parseFloat(parseFloat(outputTaxFreeCompensation).toFixed(2)),
      },
    ];
    const data = {
      num_funcionario: collaboratorNum || "",
      data_fecho: endTime || "",
      codigo_empresa: collaboratorSavedData?.codEntidade || "",
      ...(showButtonSave ? { parametros } : {}),
    };

    dispatch(saveUserDataExcel(data));
  };

  const dataSet1 = [
    {
      name: collaboratorName,
      num: collaboratorNum,
    },
    {
      code: codes.ferias,
      type: t(`simulator.excel.subsidioFerias`),
      amount: formatter.format(outputVacationSub),
    },
    {
      code: codes.feriasNaoGozadas,
      type: t(`simulator.excel.feriasNaoGozadas`),
      amount: formatter.format(outputUneusedVac),
    },
    {
      code: codes.compensacaoFormacao,
      type: t(`simulator.excel.valorCompensacaoHorasFormacao`),
      amount: formatter.format(outputTrainingHours),
    },
    {
      code: codes.ferias,
      type: t(`simulator.excel.ferias`),
      amount: formatter.format(outputVacLastYear),
    },
    {
      code: codes.ferias,
      type: t(`simulator.excel.subsidioFeriasAnoAnterior`),
      amount: formatter.format(outputVacSubLastYear),
    },
    {
      code: codes.natal,
      type: t(`simulator.excel.subsidioNatal`),
      amount: formatter.format(outputCrhistSub),
    },
    {
      code: codes.compensacaoCessacao,
      type: t(`simulator.excel.compensacaoCessacaoContrato`),
      amount: formatter.format(outputCompensation),
    },
    {
      code: codes.compensacaoIsenta,
      type: t(`simulator.excel.compensacaoIsentaIRS`),
      amount: formatter.format(outputTaxFreeCompensation),
    },
    {
      type: t(`simulator.excel.totalAmount`),
      amount: formatter.format(outputTotal),
    },
  ];

  return (
    <div className="simulator">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isGeneratingImage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div ref={componentRef}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="toPrint"
        >
          <img
            className="toPrint"
            style={{ maxWidth: "30%", margin: 90 }}
            src={logo}
            alt="UDW"
          />
        </div>
        {showButtonSave ? (
          <>
            <div className="simulator__org">
              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.collaboratorName`)}
                </Form.Label>
                <Form.Control disabled type="text" value={collaboratorName} />
              </Col>
              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.collaboratorNum`)}
                </Form.Label>
                <Form.Control disabled type="text" value={collaboratorNum} />
              </Col>
            </div>
            <div className="simulator__org">
              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.TypeOfContract`)}
                </Form.Label>
                {!showButtonSave ? (
                  <Dropdown
                    list={contractType}
                    handleSubmit={handleContract}
                    resetDropdown={cleanDropdown}
                    setResetDropdown={setCleanDropdown}
                    emptyDefault={true}
                    required
                  />
                ) : (
                  <>
                    <div className="dropDownNotPrint">
                      <Dropdown
                        list={typeOfContractList}
                        handleSubmit={handleContract}
                        resetDropdown={cleanDropdown}
                        // setResetDropdown={setCleanDropdown}
                        // emptyDefault={false}
                        required
                      />
                    </div>
                    <div className="toPrint">
                      <Form.Control value={printContract} />
                    </div>
                  </>
                )}
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.contractSelected}
                </p>
                {isBigger ? (
                  <>
                    <p className="simulator__warning">
                      {t(`simulator.warning`)}:
                    </p>
                    <p className="simulator__warning2">
                      {t(`simulator.errorMessage.10`)}
                    </p>
                    <p className="simulator__warning2">
                      {t(`simulator.errorMessage.11`)}{" "}
                    </p>
                  </>
                ) : null}
              </Col>

              {showButtonSave ? (
                <Col xl="3">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.Duration`)}
                  </Form.Label>
                  <p style={{ paddingTop: 7 }}>{durationTime}</p>
                </Col>
              ) : null}
            </div>
          </>
        ) : (
          <div className="simulator__title">
            <h6>{t(`simulator.title`)}</h6>
          </div>
        )}

        <article className="simulator__article">
          <section>
            <div className="simulator__org">
              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.InitiationDate`)}
                </Form.Label>
                <DateTimePicker
                  value={startTime}
                  onChange={(e) => handleStartTime(e)}
                  locale="pt-PT"
                  format="dd/MM/yyyy"
                  className="form-control"
                />
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.startTime}
                </p>
              </Col>

              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.ExpirationDate`)}
                </Form.Label>
                <DateTimePicker
                  value={endTime}
                  onChange={(e) => setEndTime(e)}
                  locale="pt-PT"
                  format="dd/MM/yyyy"
                  className="form-control"
                />
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.endTime}
                </p>
              </Col>

              {!showButtonSave ? (
                <Col xl="3">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.Duration`)}
                  </Form.Label>
                  <p style={{ paddingTop: 7 }}>{durationTime}</p>
                </Col>
              ) : null}
            </div>

            <div className="simulator__org">
              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.Initiative`)}
                </Form.Label>
                <div className="dropDownNotPrint">
                  <Dropdown
                    list={initiativeList}
                    handleSubmit={handleInitiative}
                    emptyDefault={true}
                    resetDropdown={cleanDropdown}
                    setResetDropdown={setCleanDropdown}
                  />
                </div>
                <div className="toPrint">
                  <Form.Control value={printInicitive} />
                </div>
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.initiative}
                </p>
              </Col>

              <Col xl="4">
                <Form.Label className="simulator__labels">
                  {t(`simulator.FairCause`)}
                </Form.Label>
                <div className="dropDownNotPrint">
                  <Dropdown
                    list={causeList}
                    handleSubmit={handleCause}
                    emptyDefault={true}
                    resetDropdown={cleanDropdown}
                    setResetDropdown={setCleanDropdown}
                  />
                </div>
                <div className="toPrint">
                  <Form.Control value={printCause} />
                </div>
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.cause}
                </p>
              </Col>
            </div>
            <div className="simulator__org">
              {!showButtonSave ? (
                <Col xl="4">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.TypeOfContract`)}
                  </Form.Label>
                  <div className="dropDownNotPrint">
                    <Dropdown
                      list={contractType}
                      handleSubmit={handleContract}
                      resetDropdown={cleanDropdown}
                      setResetDropdown={setCleanDropdown}
                      emptyDefault={true}
                      required
                    />
                  </div>
                  <div className="toPrint">
                    <Form.Control value={printFreeSimulator} />
                  </div>

                  <p className="simulator__errors">
                    {hideMessageErrors ? null : formErrors.contractSelected}
                  </p>
                  {isBigger ? (
                    <>
                      <p className="simulator__warning">
                        {t(`simulator.warning`)}:
                      </p>
                      <p className="simulator__warning2">
                        {t(`simulator.errorMessage.10`)}
                      </p>
                      <p className="simulator__warning2">
                        {t(`simulator.errorMessage.11`)}{" "}
                      </p>
                    </>
                  ) : null}
                </Col>
              ) : null}

              {contractSelected === "CTInde" ? null : (
                <>
                  <Col xl="4">
                    <Form.Label className="simulator__labels">
                      {t(`simulator.HoursPerWeek`)}
                    </Form.Label>
                    <Form.Control
                      min={0}
                      type="number"
                      value={Number(hoursPerWeek).toString()}
                      onChange={handlePerWeek}
                    />
                    <p className="simulator__errors">
                      {hideMessageErrors ? null : formErrors.hoursPerWeek}
                    </p>
                  </Col>
                </>
              )}
            </div>

            <div className="simulator_subtitles">
              <h6>{t(`simulator.Retribution`)}</h6>
            </div>
            <hr></hr>
            <div className="simulator__org">
              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.BaseSalary`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[0]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(baseSalary).toString()}
                    onChange={handleBaseSalary}
                  />
                </div>
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.baseSalary}
                </p>
              </Col>

              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.Diuturnities`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[2]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(diuturnities).toString()}
                    onChange={handleDiuturnities}
                  />
                </div>
              </Col>

              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.ValuePerHour`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[4]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(valuePerHour).toString()}
                    onChange={handleValuePerHour}
                  />
                </div>
              </Col>
            </div>

            <div className="simulator__org">
              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.BaseSalaryAverage`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[1]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(averageBaseSalary).toString()}
                    onChange={handleAverageBaseSalary}
                  />
                </div>
              </Col>

              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.Complements`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[3]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(complement).toString()}
                    onChange={handleComplement}
                    // style={{ fontSize: "10 !important" }}
                  />
                </div>
              </Col>
            </div>

            <div className="simulator_subtitles">
              <h6>{t(`simulator.FinalSettlement`)}</h6>
            </div>

            <hr></hr>
            <div className="simulator__org">
              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.VacationAllowance`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[5]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(vacationSubsidy).toString()}
                    onChange={handleVacationSubsidy}
                  />
                </div>
                <p className="simulator__errors">
                  {hideMessageErrors ? null : formErrors.baseSalary}
                </p>
              </Col>

              <Col xl="3">
                <div className="simulator_tooltipLabel">
                  <Form.Label className="simulator__labels">
                    {t(`simulator.UnusedVacation`)}
                  </Form.Label>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[6]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <Form.Control
                    min={0}
                    type="number"
                    value={Number(unusedVacation).toString()}
                    onChange={handleUnusedVacation}
                  />
                </div>
              </Col>
            </div>

            <Col xl="3">
              <div className="simulator_tooltipLabel">
                <Form.Label className="simulator__labels">
                  {t(`simulator.TrainingHoursLast3Y`)}
                </Form.Label>
                <div className="simulator_tooltipHelper">
                  <Helper data={tooltipData[7]} />
                </div>
              </div>
              <div className="simulator__tooltip">
                <Form.Control
                  min={0}
                  type="number"
                  value={Number(trainingHours).toString()}
                  onChange={handleTrainingHours}
                />
              </div>
            </Col>
            {/* </Form> */}
          </section>
          <section className="simulator__outputs">
            {hideOutputs ? (
              <>
                <div className="simulator_subtitles">
                  <h6>{t(`simulator.OtherCompensations`)}</h6>
                  <hr></hr>
                </div>

                <div>
                  <div className="simulator__tooltip">
                    <div>
                      {t(`simulator.VacationAllowance`)}:{" "}
                      <strong>{formatter.format(outputVacationSub)}</strong>
                    </div>
                    <div className="simulator_tooltipHelper">
                      <Helper data={tooltipData[8]} />
                    </div>
                  </div>
                  <div className="simulator__tooltip">
                    <div>
                      {t(`simulator.UnusedVacation`)}:{" "}
                      <strong>{formatter.format(outputUneusedVac)}</strong>
                    </div>
                    <div className="simulator_tooltipHelper">
                      <Helper data={tooltipData[9]} />
                    </div>
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <div>
                    {t(`simulator.CompensattionTrainingHours`)}:{" "}
                    <strong>{formatter.format(outputTrainingHours)}</strong>
                  </div>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[10]} />
                  </div>
                </div>
                <div className="simulator_subtitleOutputs">
                  <h6 style={{ fontWeight: "bold" }}>
                    {t(`simulator.YearTermination`)}
                  </h6>
                </div>

                <div className="simulator__tooltip">
                  <div>
                    {t(`simulator.Vacation`)}:{" "}
                    <strong>{formatter.format(outputVacLastYear)}</strong>
                  </div>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[11]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <div>
                    {t(`simulator.VacationAllowance`)}:{" "}
                    <strong>{formatter.format(outputVacSubLastYear)}</strong>
                  </div>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[12]} />
                  </div>
                </div>
                <div className="simulator__tooltip">
                  <div>
                    {t(`simulator.ChristmasAllowance`)}:{" "}
                    <strong>{formatter.format(outputCrhistSub)}</strong>
                  </div>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[13]} />
                  </div>
                </div>
                <div className="simulator_subtitleOutputs">
                  <h6 style={{ fontWeight: "bold" }}>
                    {t(`simulator.SeverencePay`)}
                  </h6>
                </div>
                <div className="simulator__tooltip2">
                  <div>
                    {t(`simulator.CompensationTerminationContract`)}:{" "}
                    <strong>{formatter.format(outputCompensation)}</strong>
                    {!hide ? (
                      false
                    ) : (
                      <p>
                        {t(`simulator.FairCauseList.1`)}
                        <li>{t(`simulator.FairCauseList.2`)}</li>
                        <li>{t(`simulator.FairCauseList.3`)}</li>
                        <li>{t(`simulator.FairCauseList.4`)}</li>
                        <li>{t(`simulator.FairCauseList.5`)}</li>
                        <li>{t(`simulator.FairCauseList.6`)}</li>
                        <li>{t(`simulator.FairCauseList.7`)}</li>
                        {t(`simulator.FairCauseList.8`)}
                      </p>
                    )}
                  </div>
                  {/* <Helper data={tooltipData[14]} /> */}
                </div>
                <div className="simulator__tooltip">
                  <div>
                    {t(`simulator.TaxFreeCompensation`)}:{" "}
                    <strong>
                      {formatter.format(outputTaxFreeCompensation)}
                    </strong>
                  </div>
                  <div className="simulator_tooltipHelper">
                    <Helper data={tooltipData[15]} />
                  </div>
                </div>
                <div>
                  <div>
                    {t(`simulator.TotalAmount`)}:{" "}
                    <strong>{formatter.format(outputTotal)}</strong>
                  </div>
                  <div>
                    {t(`simulator.Date`)}: <strong>{dateNow}</strong>{" "}
                  </div>
                </div>
              </>
            ) : null}
            <div className="dropDownNotPrint">
              <div
                className={
                  showButtonSave
                    ? "simulator__buttons"
                    : "simulator__EditedButtons"
                }
              >
                <div className="simulator_submitButton">
                  <Button
                    to="outputs"
                    type="submit"
                    className="card-button"
                    onClick={handleSubmitForm}
                  >
                    {t(`simulator.buttons.calcSimulation`)}
                  </Button>
                </div>
                <div className="simulator__secButtons">
                  {showButtonSave ? (
                    <>
                      <Button className="card-button" onClick={handleSaveData}>
                        {t(`simulator.buttons.saveSimulation`)}
                      </Button>
                      {outputTotal && dateNow ? (
                        <ExcelFile
                          element={
                            <Button
                              className="card-button"
                              onClick={handleExcell}
                            >
                              {t(`simulator.buttons.process`)}
                            </Button>
                          }
                        >
                          <ExcelSheet data={dataSet1} name="Employees">
                            <ExcelColumn
                              label={t(`simulator.excel.codes`)}
                              value="code"
                            />
                            <ExcelColumn
                              label={t(`simulator.excel.type`)}
                              value="type"
                            />
                            <ExcelColumn
                              label={t(`simulator.excel.amount`)}
                              value="amount"
                            />
                            <ExcelColumn
                              label={t(`simulator.excel.name`)}
                              value="name"
                            />
                            <ExcelColumn
                              label={t(`simulator.excel.num`)}
                              value="num"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : (
                        <Button className="card-button" onClick={handleExcell}>
                          {" "}
                          {t(`simulator.buttons.process`)}
                        </Button>
                      )}
                    </>
                  ) : null}
                  <Button
                    type="submit"
                    className="card-button"
                    onClick={() =>
                      handlePrint(componentRef.current, setIsGeneratingImage)
                    }
                  >
                    {t(`simulator.buttons.print`)}
                  </Button>

                  <Button className="card-button" onClick={handleResetoutputs}>
                    {t(`simulator.buttons.newSimulation`)}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "40px",
                }}
                className="simulator__errors"
              >
                <p>{hideErrorSaveSimulator ? null : messageErrorSave}</p>
                <p>{hideMessageErrors ? null : messageError}</p>
                <p className="simulator__sucess">
                  {hideSuccessSimulator ? null : messageSuccess}
                </p>
              </div>
            </div>
          </section>
        </article>
        <footer className="simulator__footer">
          <h6>{t(`simulator.warning`)}</h6>
          <p>{t(`simulator.lastText`)}</p>
        </footer>
      </div>
    </div>
  );
};

export default withNamespaces()(Simulator);
