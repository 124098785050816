import React from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_bullet.scss";
import Link from "./Link";

function BulletWithLinks({ list, startNumber, t }) {
  let counter = startNumber ? startNumber : 1;

  return (
    <div className="easypay-bullet-wrapper" key={uuid()}>
      {list &&
        Object.keys(list).length !== 0 &&
        list.map((item) => {
          return (
            <div className="easypay-bullet" key={uuid()}>
              <div className="easypay-bullet-number">{counter++}</div>
              <div className="easypay-bullet-with-links-wrapper">{item}</div>
            </div>
          );
        })}
    </div>
  );
}

export default withNamespaces()(BulletWithLinks);
