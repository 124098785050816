import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFileSignature } from 'react-icons/fa';
import { withNamespaces } from 'react-i18next';

const MultipleFileInputTypes = ({ type, t, f, field, handleCheck, handleSignClick, styleSheet, signature }) => {
  if (type === 'checkbox') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={`tooltip`}>{t("fileInput.toSignSelect")}</Tooltip>}
      >
        <input
          type="checkbox"
          name={f.variable}
          style={{ width: "100%", paddingTop: "0px", paddingBottom: "0px" }}
          onChange={(e) => handleCheck(e, f)}
        />
      </OverlayTrigger>
    );
  } else if (type === 'icon') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={`tooltip`}>{t("fileInput.toSign")}</Tooltip>}
      >
        {styleSheet.iconSignature && styleSheet.iconSignature === "FaFileSignature" ? (
          <FaFileSignature
            style={{ height: "20px", width: "20px", cursor: "pointer", color: "#24579e" }}
            onClick={(e) => handleSignClick(e, f)}
          />
        ) : (
          <img
            src={signature}
            style={{ height: "30px", width: "30px", cursor: "pointer" }}
            onClick={(e) => handleSignClick(e, f)}
          />
        )}
      </OverlayTrigger>
    );
  }
  return null;
};

export default withNamespaces()(MultipleFileInputTypes);