import {
  SET_DOCUMENT,
  SET_CONFIRM_PROCESS_MESSAGE,
  SET_FOCUS_ELEMENT,
  FIND_ALL_DOCUMENT_IFLOW_BINARY,
  SET_ALL_DOCUMENT_IFLOW_BINARY,
} from "./actionTypes";

const initialState = {
  currentDocument: null,

  confirmMessage: null,

  focusElement: null,

  isLoadingBinarys: false,
  binaryDocs: {},
};

export function iflowReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENT:
      return { ...state, currentDocument: action.data };
    case SET_CONFIRM_PROCESS_MESSAGE:
      return { ...state, confirmMessage: action.data };
    case SET_FOCUS_ELEMENT:
      return { ...state, focusElement: action.data };
    case FIND_ALL_DOCUMENT_IFLOW_BINARY:
      return { ...state, isLoadingBinarys: true };
    case SET_ALL_DOCUMENT_IFLOW_BINARY:
      return {
        ...state,
        isLoadingBinarys: false,
        binaryDocs: action.documents,
      };

    default:
      return state;
  }
}
