import React, {  useState } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_card.scss";
import "../assets/scss/_tabButtonWrapper.scss";

const TabButtonWrapper = ({ variant, children,active }) => {

  const activeTabName = active ? active : children[0]?.filter((child) =>
    child?.props?.name?.includes("::active")
  )[0]?.props?.name;

  const [activeTab, setActivetab] = useState(
    active ? active : children.length > 0
      ? activeTabName
        ? activeTabName
        : children[0][0]?.props?.text
      : ""
  );

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeTab,
      setTheActiveTab: (name) => {
        setActivetab(name);
      },
      tabsNumber: children[0]?.length,
      index,
    });
  });

  const getChildrenContent = () =>
    children[0]?.filter((child) => child.props.name === activeTab)[0]?.props
      ?.children;

  return (
    <div>
      {children[0]?.length > 1 && (
        <div
          className={
            !variant
              ? "easypay-tab-button-wrapper"
              : "easypay-tab-button-wrapper-separator"
          }
        >
          {childrenWithProps}
        </div>
      )}

      <div className="easypay-tab-button-content">
        <div className="easypay-card">{getChildrenContent()}</div>
      </div>
    </div>
  );
};

export default withNamespaces()(TabButtonWrapper);



