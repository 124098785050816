import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getRHCurrencyConfiguration,
  persistRHCurrencyConfiguration,
} from "~/store/ducks/rhadminConfiguration/actionTypes";

const CurrencyConfiguration = (props) => {
  const { t, organizationId } = props;
  const dispatch = useDispatch();
  const { rhcurrencyConfiguration, successPersistRHCurrencyConfiguration } =
    useSelector((state) => state.rhadminConfigurationReducer);

  const [isEditing, setIsEditing] = useState(false);
  const [currencyValues, setcurrencyValues] = useState([])

  useEffect(() => {
    if (organizationId) dispatch(getRHCurrencyConfiguration(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if(rhcurrencyConfiguration.length > 0)
    setcurrencyValues(rhcurrencyConfiguration)
  }, [rhcurrencyConfiguration]);

  useEffect(() => {
    if (successPersistRHCurrencyConfiguration) setIsEditing(false);
  }, [successPersistRHCurrencyConfiguration]);

  const handleSave = () => {
    const payload = currencyValues;
    dispatch(persistRHCurrencyConfiguration(payload));
  };

  return (
    <Form.Row style={{ margin: "20px" }}>
      <Col sm="12" md="12" lg="12">
        <Form.Group controlId="validationCustom">
          <Form.Label style={{ marginTop: "20px" }}>
            <h5>
              {t("admin.organization.settings.rhCurrencyConfiguration")}
            </h5>
          </Form.Label>
          <br />
          <br />
          {currencyValues.map((entry, index) => (
            <Form.Group
              key={entry.id}
              controlId={`currencyId-${entry.id}`}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Form.Label>{t(`admin.organization.settings.currencyId`+ entry.description)}</Form.Label>
              <Form.Control
                type="text"
                style={{ marginLeft: "15px", width: "200px" }}
                value={entry.currencyId}
                onChange={(e) => {
                  const updateCurrencyValues = [...currencyValues];
                  updateCurrencyValues[index].currencyId = e.target.value;
                  setcurrencyValues(updateCurrencyValues);
                  setIsEditing(true)
                }}
                autoComplete="off"
              />
            </Form.Group>
          ))}

          {isEditing && (
            <Button className="card-button" onClick={() => handleSave()}>
              {t("admin.organization.settings.saveConfig")}
            </Button>
          )}
        </Form.Group>
      </Col>
    </Form.Row>
  );
};

export default withNamespaces()(CurrencyConfiguration);
