import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "~/components/DatePicker";
import AnnotationIcons from "./AnnotationIcons";
import CommentHistory from "./CommentHistory";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import {
  setIsEditingAnnotation,
  setSelectedRow,
  updateAnnotation,
  findCommentHistory,
} from "~/store/ducks/processAnnotations/actionTypes";
import { iconmap } from "./utils";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

const Annotation = (props) => {
  const { t, task, userBPM } = props;

  const { isEditAnnotation, selectedRow, commentHistory } = useSelector(
    (state) => state.processAnnotationsReducer
  );
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const [show, setShow] = useState(false);
  const [dateValue, setDateValue] = useState(undefined);
  const [comment, setComment] = useState("");
  const [iconId, setIconId] = useState("");
  const [showHistory, setShowHistory] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEditAnnotation) {
      setShow(false);
      dispatch(setSelectedRow(null));
      setDateValue(undefined);
      setIconId("");
      setComment("");
      setShowHistory(false);
    } else {
      if (task) {
        task.deadline
          ? setDateValue(new Date(task.deadline))
          : setDateValue(undefined);
        task.comment ? setComment(task.comment) : setComment("");
        task.labelid !== null &&
        task.labelid !== "" &&
        task.labelid !== undefined &&
        task.labelid !== 99999 &&
        task.labelid !== 100000 &&
        task.labelid !== 0
          ? setIconId(task.labelid)
          : setIconId("");
      }
    }
  }, [isEditAnnotation, task]);

  useEffect(() => {
    if (
      selectedRow &&
      selectedRow.pnumber === task.pnumber &&
      task.pnumber !== undefined
    ) {
      setShow(true);
      dispatch(setIsEditingAnnotation(true));
      //check for comment history if Preview
      if (selectedRow.isPreview) {
        if (
          userBPM &&
          selectedRow.flowid &&
          selectedRow.pnumber &&
          selectedRow.subpid
        ) {
          const payload = {
            username: userBPM,
            flowid: selectedRow.flowid,
            pid: selectedRow.pnumber,
            subpid: selectedRow.subpid,
          };
          dispatch(findCommentHistory(payload));
        }
      }
    } else {
      setShow(false);
    }
  }, [selectedRow]);

  const handleClose = () => {
    setShow(false);
    setShowHistory(false);
    dispatch(setIsEditingAnnotation(false));
  };

  const handleEditAnnotation = () => {
    let transformedDate = "";

    if (dateValue) {
      //convert to example 09/12/2021, 00:00:00
      const dateValue_ = dateValue.toLocaleDateString("pt-PT");
      const dateArray = dateValue_.split("/");
      const day = dateArray[0];
      const month = dateArray[1];
      const year = dateArray[2];

      transformedDate = year + "/" + month + "/" + day;
    }
    const payload = {
      flowid: task.flowid,
      pid: task.pid,
      subpid: task.subpid,
      deadline: transformedDate,
      iconid:
        (iconId === 0 && transformedDate === "") ||
        (iconId === 99999 && comment === "")
          ? ""
          : iconId, //dont save iconid when no deadline
      comment: comment,
    };
  if(userBPM == undefined || userBPM == null) userBPM = user.legacyUsers ? getUsername(user.legacyUsers) : null;

    dispatch(updateAnnotation(payload, userBPM));
    handleClose();
  };

  const getUsername = (legacyUsers) => {
    let userBPM = legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    );
    return userBPM ? userBPM.username : null;
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const getUserTheme = () => {
    let config = configuration?.loginPage;
    if (config === "nblp") {
      return "nblp";
    }
    return userSettings.theme ? userSettings.theme : "default";
  };

  const foundIcon = (iconId_) =>
    iconmap(getUserTheme()).find((i) => i.id == iconId_);

  let deadline = "-";
  if (selectedRow && selectedRow.deadline) {
    const arr = selectedRow.deadline.split("/");
    deadline = arr[2] + "/" + arr[1] + "/" + arr[0];
  }

  return (
    <div className="tasks-annotation-wrapper">
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("taskPage.annotation.annotate"))}
      >
        <i
          className="far fa-sticky-note annotation table-action-icon"
          style={{
            padding: "0px 10px 0px 0px",
            fontSize: "17px",
          }}
          onClick={() => {
            selectedRow && selectedRow.pnumber === task.pnumber && show
              ? handleClose()
              : dispatch(setSelectedRow(task));
          }}
        />
      </OverlayTrigger>
      {show && (
        <Modal
          dialogClassName="modal-30w custom-modal"
          show={show}
          onHide={handleClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            {task.pnumber && (
              <div className="custom-title">
                {t("taskPage.annotation.title")} {task.pnumber}
              </div>
            )}
          </Modal.Header>
          <Modal.Body style={{ scrollY: "auto" }}>
            <div className="tasks-annotation-inner">
              {/*Preview starts*/}
              {selectedRow.isPreview ? (
                <div style={{ width: "100%" }}>
                  <div className="annotation-preview-row">
                    <span className="annotation-preview-header">
                      {t(`taskPage.annotation.deadline`)}
                    </span>
                    <span className="annotation-preview-content">
                      {deadline}
                    </span>
                  </div>

                  <div className="annotation-preview-row">
                    <span className="annotation-preview-header">
                      {t(`taskPage.annotation.label`)}
                    </span>
                    <span className="annotation-preview-content">
                      {iconId ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                          }}
                        >
                          {foundIcon(iconId) && foundIcon(iconId).faIcon ? (
                            <i
                              style={{ marginRight: "10px" }}
                              className={
                                foundIcon(iconId) && foundIcon(iconId).faIcon
                              }
                            />
                          ) : (
                            <img
                              src={foundIcon(iconId)?.image}
                              style={{
                                width: "18px",
                                height: "16px",
                                marginRight: "10px",
                              }}
                              alt="--"
                            />
                          )}

                          <div style={{ paddingRight: "10px" }}>
                            {foundIcon(iconId)
                              ? t(
                                  `taskPage.annotation.${
                                    foundIcon(iconId).name
                                  }`
                                )
                              : ""}
                          </div>
                        </div>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>

                  <div className="annotation-preview-row">
                    <span className="annotation-preview-header">
                      {t(`taskPage.annotation.lastComment`)}
                      <br />
                      {comment &&
                        commentHistory &&
                        commentHistory[0] &&
                        commentHistory[0].userid &&
                        `(${commentHistory[0].userid})`}
                    </span>
                    <span className="annotation-preview-content">
                      {comment ? comment : "-"}
                    </span>
                  </div>
                  <br />

                  {commentHistory && commentHistory.length > 1 && (
                    <>
                      {!showHistory && (
                        <a
                          href={""}
                          onClick={() => {
                            setShowHistory(true);
                          }}
                        >
                          {t(`taskPage.annotation.showMore`)}
                        </a>
                      )}
                      {showHistory && (
                        <>
                          <a
                            href={""}
                            onClick={() => {
                              setShowHistory(false);
                            }}
                          >
                            {t(`taskPage.annotation.showLess`)}
                          </a>
                          <br />
                          <br />
                          <CommentHistory commentHistory={commentHistory} />
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                /*Preview ends*/
                /*Edit starts*/
                <>
                  <div className="annotation-preview-row">
                    <span className="annotation-preview-header">
                      {t(`taskPage.annotation.selectLabel`)}
                    </span>
                    <div className="tasks-annotation-icons-wrapper">
                      <AnnotationIcons
                        iconId={iconId}
                        setIconId={setIconId}
                        setShow={setShow}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="annotation-preview-row">
                    <span className="annotation-preview-header">
                      {t(`taskPage.annotation.setDeadline`)}
                    </span>
                    <DatePicker
                      value={dateValue}
                      onChange={(value) => {
                        setDateValue(value);
                      }}
                      name={"annotation-deadline"}
                      format={"dd/MM/yyyy"}
                    />
                  </div>

                  <br />
                  <textarea
                    className="form-control"
                    type="textarea"
                    maxLength="125"
                    placeholder={t(`taskPage.annotation.typeComment`)}
                    rows={3}
                    cols={42}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <br />
                  <button
                    className="card-button btn btn-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleEditAnnotation();
                    }}
                  >
                    {t(`general.save`)}
                  </button>
                </>
                /*Edit ends*/
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default withNamespaces()(Annotation);
