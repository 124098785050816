import React from "react";
import uuid from "uuid/v1";
import { withNamespaces } from "react-i18next";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import Spinner from "../../../../components/Spinner";
import { Button } from "react-bootstrap";

const RoleTable = (props) => {
  const {
    t,
    roles,
    isLoading,
    setShowModal,
    showAddButton = true,
    icon,
    action,
  } = props;

  const buildActionsColumn = (_, row, {}) => (
    <i className={`${icon} table-action-icon`} onClick={() => action(row)} />
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "description",
      text: t("admin.role.list.description"),
      sort: true,
    },
    {
      dataField: "accao",
      text: t("admin.role.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
    },
  ];

  return (
    <div className="custom-table">
      <ToolkitProvider keyField="id" data={roles} columns={columns} search>
        {(props) => (
          <div>
            <div style={{ paddingBottom: "20px" }}>
              <SearchBar
                {...props.searchProps}
                placeholder={t("admin.role.list.searchInput")}
              />
              {showAddButton && (
                <Button
                  onClick={() => setShowModal(true)}
                  className="card-button"
                  style={{ marginLeft: "30px" }}
                >
                  {t("general.add")}
                </Button>
              )}
            </div>

            <Spinner spinning={isLoading} />
            {!isLoading && (
              <BootstrapTable
                key={uuid()}
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory({
                  sizePerPage: 5,
                  hideSizePerPage: true,
                  hidePageListOnlyOnePage: true,
                })}
                noDataIndication={t("admin.role.list.emptyTable")}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(RoleTable);
