import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import { hideSuccess, hideWarning, hideError } from "./actions";

import { Modal, Button } from "react-bootstrap";

import "./errorModal.scss";
const Messages = (props) => {
  const dispatch = useDispatch();
  const { t, isExternalPage = false } = props;

  let {
    activeSuccess,
    successMessage,
    activeWarning,
    warningMessage,
    activeError,
    errorMessage,
  } = useSelector((state) => state.messagesReducer);

  const [styleSheet, setFormStyleSheet] = useState("");
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const handleOkSuccess = () => {
    dispatch(hideSuccess());
    if (props.afterHideSuccess) {
      props.afterHideSuccess();
    }
  };

  const handleOkWarning = () => {
    dispatch(hideWarning());
    if (props.afterHideWarning) {
      props.afterHideWarning();
    }
  };

  const handleOkError = () => {
    dispatch(hideError());
    if (props.afterHideError) {
      props.afterHideError();
    }
  };

  /*Get extraconfiguration*/
  useEffect(() => {
    if (menu) {
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }

      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        setFormStyleSheet(styleSheet_);
      }
    }
  }, [menu]);

  return (
    <>
      <Modal
        show={activeSuccess}
        onHide={() => handleOkSuccess()}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("messages.success")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className={styleSheet?.badgesMessage ? "badge-success" : ""}
            style={
              styleSheet?.badgesMessage
                ? { borderRadius: "10px", padding: "5px 5px 5px 5px" }
                : { whiteSpace: "pre-line" }
            }
          >
            {t(successMessage)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            className="modal-submit-button"
            onClick={() => handleOkSuccess()}
          >
            Ok
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={activeWarning}
        onHide={() => handleOkWarning()}
        className="error-modal-content"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("messages.warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ whiteSpace: "pre-line" }}>{t(warningMessage)}</p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            className="modal-submit-button"
            onClick={() => handleOkWarning()}
          >
            Ok
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={activeError}
        onHide={() => handleOkError()}
        dialogClassName="custom-modal"
      >
        <Modal.Header className="error-modal-header" closeButton>
          <Modal.Title>{t("messages.error")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className={
              styleSheet?.badgesMessage ? "badge-danger" : "modal-error-message"
            }
            style={
              styleSheet?.badgesMessage
                ? { borderRadius: "10px", padding: "5px 5px 5px 5px" }
                : {}
            }
          >
            {t(errorMessage)}
          </p>
        </Modal.Body>
        <Modal.Footer className="error-modal-footer">
          {/* <Button
            className="modal-submit-button"
            onClick={() => handleOkError()}
          >
            Ok
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(Messages);
