import React, { useEffect, useState, useRef } from "react";
import "../assets/scss/_proposal.scss";
import "../assets/scss/_option.scss";
import { useDispatch, useSelector } from "react-redux";
import ProposalHoursTable from "./ProposalHoursTable";
import ProposalHoursSimulatorData from "./ProposalHoursSimulatorData";
import ProposalSuppliersTable from "./ProposalSuppliersTable";
import ProposalCostsTable from "./ProposalCostsTable";
import ProposalSuppliersCostSimulatorTable from "./ProposalSuppliersCostSimulatorTable";
import ProposalResumeSection from "./ProposalResumeSection";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Textbox from "./Textbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { handlePrint } from "~/utils";

const Proposal = ({ info, workplanIndex }) => {
  const dispatch = useDispatch();

  const { workplans, clients } = useSelector(
    (state) => state.budgetManagementReducer
  );

  function convertIdToName(id, name) {
    if (name === "clients" && clients != null) {
      const client = clients.find((x) => x.idCliente === parseInt(id));
      if (client != null) return client?.nome;
    }
  }
  var [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    var newArray = [];
    for (var i = 0; i < workplans[0].items.length; i++) {
      for (var j = 0; j < workplans[0].items[i].atividades.length; j++) {
        for (
          var k = 0;
          k < workplans[0].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (workplans[0].items[i].atividades[j].opcoes[k].selected) {
            newArray.push(workplans[0].items[i].atividades[j].opcoes[k]);
          }
        }
      }
    }
    if (newArray.length === 0) {
      for (var i = 0; i < workplans[0].items.length; i++) {
        for (var j = 0; j < workplans[0].items[i].atividades.length; j++) {
          for (
            var k = 0;
            k < workplans[0].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              newArray.push(workplans[0].items[i].atividades[j].opcoes[k]);
            }
          }
        }
      }
    }

    setSelectedOptions(newArray);
  }, [selectedOptions.length === 0]);

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  const componentRef = useRef(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
 
  function getProjectName() {
    const version = workplans[workplanIndex]?.version;
    var versionString = "";
    if (version >= 10) {
      versionString = "" + version;
    } else {
      versionString = "0" + version;
    }

    return (
      workplans[workplanIndex]?.projectName +
      "." +
      versionString +
      "/" +
      (workplans[workplanIndex]?.dataIdentDaLead).substring(0, 4)
    );
  }

  return (
    <div>
      <div ref={componentRef}>
        <div style={{ paddingBottom: "30px" }}></div>
        <div className="liftworld-propsoal-input-wrapper">
          <div className="liftworld-text-wrapper-proposal">Cliente</div>
          <div className="liftworld-normal-text-wrapper">
            {convertIdToName(
              workplans[workplanIndex].nomeClienteProjeto,
              "clients"
            )}
          </div>
        </div>
        <div className="liftworld-propsoal-input-wrapper">
          <div className="liftworld-text-wrapper-proposal">Proposta</div>
          <div className="liftworld-normal-text-wrapper">
            {workplans[workplanIndex].nomeProposta}
          </div>
        </div>
        <div className="liftworld-propsoal-input-wrapper">
          <div className="liftworld-text-wrapper-proposal">Nº Proposta</div>
          <div className="liftworld-normal-text-wrapper">
            {getProjectName()}
          </div>
        </div>
        <div className="liftworld-proposal-wrapper">
          <div className="liftworld-proposal-main-tables-wrapper">
            <div className="liftworld-proposal-hours-wrapper">
              <div className="liftworld-table-title-wrapper">HORAS</div>
              <ProposalHoursTable
                selectedOptions={selectedOptions}
                workplanIndex={workplanIndex}
              />
            </div>
            <div className="liftworld-proposal-hours-wrapper">
              <div className="liftworld-table-title-wrapper">FORNECEDORES</div>
              <ProposalSuppliersTable workplanIndex={workplanIndex} />
            </div>
            <div className="liftworld-proposal-hours-wrapper">
              <div className="liftworld-table-title-wrapper">
                CUSTOS E DESPESAS
              </div>
              <ProposalCostsTable workplanIndex={workplanIndex} />
            </div>
          </div>
          {false && (
            <div className="liftworld-simulator-data-wrapper">
              <div className="liftworld-proposal-hours-wrapper">
                <div className="liftworld-table-title-wrapper">
                  DADOS DO SIMULADOR
                </div>
                <ProposalHoursSimulatorData />
                <div className="liftowrld-simulator-table-wrapper">
                  <ProposalSuppliersCostSimulatorTable />
                </div>
                <div className="liftowrld-simulator-table-wrapper">
                  <ProposalSuppliersCostSimulatorTable />
                </div>
              </div>
            </div>
          )}
        </div>
        <ProposalResumeSection workplanIndex={workplanIndex} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "30px",
        }}
      >
        <SimpleButton
          text={"Imprimir"}
          variant={"liftworld-button-primary"}
          onClick={() =>
            handlePrint(componentRef.current, setIsGeneratingImage)
          }
        />
      </div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isGeneratingImage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default Proposal;
