import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import uuid from "uuid/v1";
import { toLocaleString } from "~/utils";
import "~/assets/css/icons.css";
import "~/assets/css/styles.css";

const VAULT_VALUE = "960DCE5B-37B5-4258-96DC-17883A1B49D9";

const MfilesTable = ({ t }) => {
  const { mfiles } = useSelector((state) => state.mfilesReducer);

  const documents = mfiles.map((file) => {
    file.id = uuid();
    return file;
  });

  const generateDetailLink = (row) => {
    const id = row.objectGUID.slice(1, -1);
    return `http://mfiles.uniksystem.pt/Default.aspx?#${VAULT_VALUE}/object/${id}/latest`;
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }
  const buildActionsColumn = (_, row, {}) => (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip("Detalhe do documento")}
      >
        <a
          className="icon-data-capture table-action-icon"
          href={generateDetailLink(row)}
          target="blank"
        />
      </OverlayTrigger>
    </>
  );

  const columns = [
    {
      dataField: "title",
      text: "Nome",
    },
    {
      dataField: "created",
      text: "Criado",
      formatter: (_, row, {}) => {
        if (row.created !== null && row.created !== undefined)
          return toLocaleString(row.created);
      },
    },
    {
      dataField: "actions",
      text: "Acção",
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  return (
    <div>
      <br />
      <div className="custom-table">
        <BootstrapTable
          keyField="id"
          data={documents}
          columns={columns}
          noDataIndication={t("datacapture.general.dataNotFound")}
          pagination={paginationFactory(pagination)}
          hover
          striped
        />
      </div>
    </div>
  );
};

export default withNamespaces()(MfilesTable);
