import React from "react";
import Zoom from "react-medium-image-zoom";
const DocumentViewer = React.memo(({ base64, isImage }) => {


    const generateImageView = () => {
        return "data:image/jpg;base64, " + base64;
      };

      const generatePdfView = () => {
        function createPdfBlob(datadoc) {
          const base64String = atob(datadoc);
          const bytes = new Uint8Array(base64String.length);
          for (let i = 0; i < base64String.length; i++) {
            bytes[i] = base64String.charCodeAt(i);
          }
          return new Blob([bytes.buffer], { type: "application/pdf" });
        }
    
        const datadoc = base64;
        const pdfBlob = createPdfBlob(datadoc);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return pdfUrl;
      };
  return (
    base64 && (
      <>
        <br />
        {isImage ? (
          <div style={{ textAlignLast: "center" }}>
            <Zoom>
              <img
                src={generateImageView()}
                style={{ width: "auto", height: "435px" }}
                alt="Document"
              />
            </Zoom>
          </div>
        ) : (
          <iframe
            id="pdf-document"
            title="pdf-document"
            src={generatePdfView()}
            width="100%"
            height="500px"
          />
        )}
      </>
    )
  );
});
export default DocumentViewer;
