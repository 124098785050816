import React, { useEffect, useState, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import oauth from "~/utils/oauth";
import { useInterval } from "~/utils/useInterval.js";
import TabButtonWrapper from "~/pages/Easypay/components/TabButtonWrapper";
import TabButton from "../../components/TabButton";
import iconAlert from "~/pages/Easypay/assets/img/icons/icon-alert.png";
import uuid from "uuid/v1";
import Bullet from "~/pages/Easypay/components/Bullet";
import Button from "../../components/SimpleButton";
import { useHistory } from "react-router-dom";
import ProvaDeVida from "../../components/ProvaDeVida";
import Spinner from "~/pages/Easypay/components/Spinner";

import {
  getAliceProcess,
  generateAliceLink,
  updateRepCmd,
  getSaml,
  getAtributes,
  getAliceToken,
  setAliceUserID,
  setRepId,
  getReplegId,
  setUpdateCmdErro
} from "~/store/ducks/onboarding/actionTypes";

import Text from "../../components/Text";

const RepLegPDA = (props) => {
  const ids = props?.match?.params?.id;
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState([]);
  const [id, setId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [aliceDone, setAliceDone] = useState(false);
  const link = useRef("");

  const listOfAtributes = [
    { value: "NIC", label: "NIC" },
    { value: "NomeCompleto", label: "Nome Completo" },
    { value: "NIF", label: "NIF" },
    { value: "DocType", label: "Doc Type" },
    { value: "MoradaXML", label: "Morada XML" },
  ];

  const { user } = useSelector((state) => state.globalReducer);

  const {
    isLoadingGetSaml,
    getSamlResponse,
    getAtributesResponse,
    aliceUserID,
    replegId,
    aliceLink,
    updateCmdErro
  } = useSelector((state) => state.onboardingReducer);

  const testCmd = 
  {
    pvNome: "Nome",
    pvMetodo: "chaveMovel",
    pvTipoDocumento: "R",
    pvNumDocumento: "12345678",
    pvNif: "12345678",
    id: "2025",
    isProc : true
  }
  useEffect(() => {
    if (getAtributesResponse) {
      dispatch(
        updateRepCmd({
          pvNome: getAtributesResponse.NomeCompleto,
          pvMetodo: "chaveMovel",
          pvTipoDocumento: getAtributesResponse.tipoDeVia,
          pvNumDocumento: getAtributesResponse.nic,
          pvNif: getAtributesResponse.NIF,
          id: parseInt(ids.split("::")[1]),
          isProc : ids?.split("::")[2] === "true" ? true : false
        })
      );
    }
  }, [getAtributesResponse]);

  useEffect(() => {
    if (updateCmdErro != null) {
      if(!updateCmdErro)
      {
        createTabsCmd();
      }
      else
      {
        createTabsCmdErro()
      }
    }
  }, [updateCmdErro]);

  useInterval(() => {
    dispatch(getReplegId(ids?.split("::")[1]));
  }, 1000 * 5);

  useEffect(() => {
    if (replegId === 1) {
      createTabsAlice();
      setAliceDone(true);
    }
  }, [replegId]);

  const bulletCMDList = [
    "Será redirecionado para autenticacao.gov.pt.",
    "Complete todos os passos solicitados.",
    "No final do processo, volte a este portal.",
  ];
  const bulletAliceList = [
    "Capture o documento escolhido.",
    "Tire uma selfie.",
    "No final do processo, volte a este portal.",
  ];

  function createTabs() {
    var newArray = [];
    newArray.push(
      <TabButton
        text={"Chave-Móvel Digital"}
        index={0}
        name={"Chave-Móvel Digital"}
        key={uuid()}
      >
        {createBodyCMD()}
      </TabButton>
    );
    newArray.push(
      <TabButton text={"Outro"} index={1} name={"Outro"} key={uuid()}>
        {createBodyAlice()}
      </TabButton>
    );
    setTabs([newArray]);
  }

  function createTabsCmd() {
    var newArray = [];
    newArray.push(
      <TabButton
        text={"Chave-Móvel Digital"}
        index={0}
        name={"Chave-Móvel Digital"}
        key={uuid()}
      >
        <ProvaDeVida text="Prova de vida realizada! Pode continuar com o processo" status="success" />
      </TabButton>
    );
    newArray.push(
      <TabButton text={"Outro::disabled"} index={1} name={"Outro"} key={uuid()}>
        {createBodyAlice()}
      </TabButton>
    );
    setTabs([newArray]);
  }

  
  function createTabsCmdErro() {
    var newArray = [];
    newArray.push(
      <TabButton
        text={"Chave-Móvel Digital"}
        index={0}
        name={"Chave-Móvel Digital"}
        key={uuid()}
      >
        <ProvaDeVida text="Erro ao realizar prova de vida!!" status="error" />
        <Button
            text={"Tentar novamente"}
            variant={"easypay-button-primary"}
            onClick={createTabs}
          />
      </TabButton>
    );
    newArray.push(
      <TabButton text={"Outro::disabled"} index={1} name={"Outro"} key={uuid()}>
        {createBodyAlice()}
      </TabButton>
    );
    setTabs([newArray]);
  }

  function createTabsAlice() {
    var newArray = [];
    newArray.push(
      <TabButton
        text={"Chave-Móvel Digital::disabled"}
        index={0}
        name={"Chave-Móvel Digital"}
        key={uuid()}
      >
        <ProvaDeVida text="Prova de vida realizada! Pode continuar com o processo" status="success" />
      </TabButton>
    );
    newArray.push(
      <TabButton text={"Outro"} index={1} name={"Outro"} key={uuid()}>
        <ProvaDeVida text="Prova de vida realizada! Pode continuar com o processo" status="success" />
      </TabButton>
    );
    setTabs([newArray]);
  }

  useEffect(() => {
    oauth.set(true, "WINDOWSLOGIN", true);
    createTabs();
  }, []);

  useEffect(() => {
    if (aliceLink != null) {
      link.current = aliceLink;
    }
  }, [aliceLink]);

  let payload = { url: `${window.location.href}?cmd-user-id=` };

  useEffect(() => {
    if (getSamlResponse) {
      document.getElementById("submitSamlReqBtnId").click();
    }
  }, [getSamlResponse]);

  useEffect(() => {
    if (window.location.href.includes("?cmd-user-id=")) {
      if (window.location.href.split("?cmd-user-id=")[1] !== "") {
        dispatch(
          getAtributes({
            id: window.location.href.split("?cmd-user-id=")[1],
          })
        );
      }
      window.location.href = window.location.href.split("?cmd-user-id=")[0];
    }
  }, []);

  useEffect(() => {
    dispatch(
      generateAliceLink(
        parseInt(ids.split("::")[0]),
        2,
        parseInt(ids.split("::")[1]),
        ids.split("::")[2]
      )
    );
  }, [user]);

  function cmdFunction() {
    if (Object.keys(payload).length > 1) {
      dispatch(getSaml(payload));
    }
  }

  const updateAllAtributes = (value, list) => {
    if (value === 1) {
      listOfAtributes.forEach((atr) => {
        list[atr.value] = value;
      });
    } else {
      listOfAtributes.forEach((atr) => {
        delete list[atr.value];
      });
    }
  };

  useEffect(() => {
    updateAllAtributes(1, payload);
  }, []);

  function aliceFunction() {
    if (!link) return;
    const array = link.current.split("/>");

    window.open(array[1], "_self", "noopener,noreferrer");
  }

  useEffect(() => {
    if (!aliceLink) return;
    const array = aliceLink?.split("/>");
    const arr = array[1]?.split("::unik::");
    if (arr && arr.length > 2) {
      setId(arr[arr.length - 2]);
      setOrgId(arr[arr.length - 1]);
    }
  }, [aliceLink]);

  useEffect(() => {
    if (id && orgId) {
      dispatch(getAliceToken(id, orgId));
    }
  }, [id, orgId]);

  function createBodyCMD() {
    return (
      <div>
        <Text
          text={
            "Este método só é possível com cartão de cidadão Português ou Cartão de Residência e PIN da Chave Móvel Digital.::card-text-light-small-padding"
          }
        />
        <Text text={"Este processo consiste em 3 passos ::card-text"} />
        <Bullet list={bulletCMDList} />
        <div>
          <Button
            text={"Iniciar Prova de Vida"}
            variant={"easypay-button-primary"}
            onClick={cmdFunction}
          />
        </div>
      </div>
    );
  }

  function createBodyAlice() {
    return (
      <div>
        <Text
          text={
            "Este método vai ser realizado pela Alicebiometrics.::card-text-light-small-padding"
          }
          align={"center"}
        />
        <Text text={"Este processo consiste em 3 passos ::card-text"} />
        <Bullet list={bulletAliceList} />
        <div>
          <Button
            text={"Iniciar Prova de Vida"}
            variant={"easypay-button-primary"}
            onClick={aliceFunction}
          />
        </div>
      </div>
    );
  }

  const [show, setShow] = useState(false);

  useInterval(() => {
    setShow(true);
  }, 1000 * 5);

  return (
    <div style={{ overflowY: "scroll", width: "100%" }}>
      {!show ? (
        <Spinner spinning={show === false} />
      ) : (
        <>
          <div className="easypay-content">
            <div className="easypay-card-wrapper">
              <Text text={"Prova de Vida::header-3"} align="center" />
              <Text
                text={
                  "Tenha consigo o seu telemóvel com acesso ao microfone e câmera para verificarmos a sua identidade. Escolha uma das seguintes opções.::body-xl"
                }
                align="center"
              />
              {
                <TabButtonWrapper
                  active={aliceDone ? "Outro" : "Chave-Móvel Digital"}
                >
                  {tabs}
                </TabButtonWrapper>
              }
            </div>
          </div>
          <form
            id="samlFormId"
            action="https://preprod.autenticacao.gov.pt/fa/Default.aspx"
            method="POST"
          >
            <input type="hidden" name="SAMLRequest" value={getSamlResponse} />
            <input
              type="submit"
              id="submitSamlReqBtnId"
              style={{ display: "none" }}
            />
          </form>
        </>
      )}
    </div>
  );
};
export default withNamespaces()(RepLegPDA);
