import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { COMPLIANCE_MIDDLEWARE } from "~/utils/constants";
import { TabsKey } from "../../utils/TabsEnum";
import ApplicationCard from "./ApplicationCard";


const ComplianceComponent = ({ tabKey }) => {
  const dispatch = useDispatch();

  const { isComplianceON, loadingCompliance } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(actions.pingMiddlewareApplication(COMPLIANCE_MIDDLEWARE));
      const payloadIFlow = {
        url: "/main.jsp",
        application: {
          context: "Compliance",
        },
      };
      dispatch(actions.pingIFlowBasedApplication(payloadIFlow));
    }
  }, [tabKey]);

  return (
    <ApplicationCard
      name="Compliance"
      status={isComplianceON}
      loading={loadingCompliance}
      showMW={true}
    />
  );
};

export default ComplianceComponent;
