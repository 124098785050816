import React from "react";
import { Button } from "react-bootstrap";
import removeIcon from "~/assets/img/icons/icon-remove.png";

const RemoveButton = ({ disabled, text, onClick, type }) => {
  return (
    <Button
      disabled={disabled}
      variant="outline-primary"
      onClick={onClick}
      className="custom-remove-button"
      type={type ? type : "button"}
    >
      <img src={removeIcon} alt="remove" />
      {text}
    </Button>
  );
};

export default RemoveButton;
