import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "~/pages/Easypay/assets/css/styles.css";
import iconWarning from "~/pages/Easypay/assets/img/icons/icon-warning.png";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import "~/pages/Easypay/assets/scss/_backofficeEasypay.scss";
import Button from "~/pages/Easypay/components/Button";
import BackOfficeInput from "~/pages/Easypay/components/BackOfficeInputDocs";
import Header from "~/pages/Easypay/components/Header";
import Table from "~/pages/Easypay/components/Table";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import Spinner from "~/pages/Easypay/components/Spinner";
import iconGreenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import iconError from "~/pages/Easypay/assets/img/icons/icon-error-filled.png";
import iconWaiting from "~/pages/Easypay/assets/img/icons/icon-waiting.png";
import Modal from "~/pages/Easypay/components/Modal";
import uuid from "uuid/v1";
import {
  getAllUsers,
  getAllDocuments,
  updateDocument,
  setDocument
} from "~/store/ducks/onboarding/actionTypes";
const devLogConsole = require("~/utils/devLog");
const BackofficeOrganization = ({ t, application }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { users, isLoadingGetAllUsers, documents,document } = useSelector(
    (state) => state.onboardingReducer
  );

  var isFirstTime = true;

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [docIndex, setDocIndex] = useState();

  useEffect(() => {
    if (isFirstTime) {
      window.scrollTo(0, 0);
      isFirstTime = false;
    }
    dispatch(getAllDocuments());
  }, []);

  useEffect(() => {
    if (document != null) {
      dispatch(getAllDocuments());
      dispatch(setDocument(null))
    }
  }, [document]);

  useEffect(() => {
    var docsArray = [];
        if(documents.length)
    {
      devLogConsole(documents)
      for(var k = 0; k < documents.length;k++)
      {
        if(documents[k].isPDA != 1 && documents[k].nomeDocumento)
        {
          docsArray.push(documents[k]);
        }
      }
    }
    setFilteredList(docsArray);
  }, [users, documents]);

  const filterUsers = (list) =>
    list.filter((user) => user?.account?.lead === 0);

  const [filteredList, setFilteredList] = useState([]);

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const calcDays = (cell) => {
    if (!cell) {
      return "";
    }
    const modifiedDate = new Date(cell);
    const currentDate = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = currentDate.getTime() - modifiedDate.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  function textFormatter(cell) {
    return <div>{cell}</div>;
  }

  function nameFormatter(cell) {
    if (
      filteredList === null ||
      filteredList === undefined ||
      filteredList?.length === 0
    )
      return "";
    var currentIndex;
    for (var i = 0; i < filteredList.length; i++) {
      if (filteredList[i].id === cell) {
        currentIndex = i;
        break;
      }
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="easypay-backoffice-document-table-item-doc-title">
          {filteredList[i].nomeCompleto}
        </div>
        <div className="easypay-backoffice-document-table-item-doc-desc">
          {filteredList[i].descDocumento}
        </div>
      </div>
    );
  }

  const fileNameFormatter = (cell) => {
    if (cell === "" || cell === undefined) return "";
    if (
      filteredList === null ||
      filteredList === undefined ||
      filteredList?.length === 0
    )
      return "";

    var currentIndex;
    for (var i = 0; i < filteredList.length; i++) {
      if (filteredList[i].id === cell) {
        currentIndex = i;
        break;
      }
    }

    return (
      <div
        className="easypay-backoffice-document-table-item-doc-img-name-wrapper"
        onClick={() => {
          history.push({
            pathname: `/uniksystem/easypay/backoffice-document-preview-page/${filteredList[i]?.id}`,
            docId: filteredList[i]?.id,
          });
        }}
      >
        <div className="easypay-text-icon-check">
          <img src={iconDocument} alt="Icon Check" />
        </div>
        <div className="easypay-backoffice-document-table-item-doc-name">
          {filteredList[i]?.nomeDocumento}
        </div>
      </div>
    );
  };

  function changeDocState(index) {
    setDocIndex(index);
    setIsModalShowing(true);
  }

  const stateFormatter = (cell) => {
    var currentIndex;
    if (
      filteredList === null ||
      filteredList === undefined ||
      filteredList?.length === 0
    )
      return "";
    for (var i = 0; i < filteredList.length; i++) {
      if (filteredList[i].id === cell) {
        currentIndex = i;
        break;
      }
    }

    if (filteredList[currentIndex].estadoDocumento === 0) {
      return (
        <div
          className="easypay-text-icon-check-documents"
          onClick={() => changeDocState(currentIndex)}
        >
          <img src={iconWaiting} alt="Icon Check" />
        </div>
      );
    } else if (filteredList[currentIndex].estadoDocumento === -1) {
      return (
        <div className="easypay-text-icon-check-documents">
          <img src={iconError} alt="Icon Check" />
        </div>
      );
    } else {
      return (
        <div className="easypay-text-icon-check-documents">
          <img src={iconGreenCheckmark} alt="Icon Check" />
        </div>
      );
    }
  };

  const statusFormatter = (cell) => {
    const index = filteredList.findIndex((object) => {
      return object.id === cell;
    });
    const currentDoc = filteredList[index];

    if (currentDoc?.estadoDocumento === -1) {
      return <img src={iconWarning} />;
    }
    return "";
  };

  function hourFormatter(cell) {
    return <div>{cell?.substring(11, 16)}</div>;
  }

  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: statusFormatter,
      headerStyle: { width: "40px", whiteSpace: "normal" },
    },
    {
      dataField: "id",
      text: t("bpm.onboarding.backoffice.user.labelDocument"),
      sort: true,
      formatter: nameFormatter,
      sortValue: (cell) => parseInt(cell),
    },
    {
      dataField: "id",
      text: t("bpm.onboarding.backoffice.user.labelFileName"),
      sort: true,
      formatter: fileNameFormatter,
      sortValue: (cell) => parseInt(cell),
    },
    {
      dataField: "idCliente",
      text: "ID User",
      sort: true,
      formatter: textFormatter,
      sortValue: (cell) => parseInt(cell),
    },
    {
      dataField: "ibanNome",
      text: t("bpm.onboarding.backoffice.user.labelName"),
      sort: true,
      formatter: textFormatter,
      sortValue: (cell) => parseInt(cell),
    },
    {
      dataField: "createdDate",
      text: t("bpm.onboarding.backoffice.administration.tableCreationDate"),
      sort: true,
      formatter: dateFormatter,
      headerStyle: { whiteSpace: "normal" },
    },
    {
      dataField: "modifiedDate",
      text: t("bpm.onboarding.backoffice.user.labelHour"),
      sort: true,
      formatter: hourFormatter,
      headerStyle: { whiteSpace: "normal" },
    },
    {
      dataField: "id",
      text: t("bpm.onboarding.backoffice.administration.headerState"),
      sort: false,
      formatter: stateFormatter,
      headerStyle: { whiteSpace: "normal" },
    },
  ];

  const [tableSize, setTableSize] = useState(10);

  const [filters, setFilters] = useState([
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
  ]);

  //filters all the data using the filters, this runs anytime the filter is changed and has a different value from previous state
  const filterData = (data, filter) => {
    const validFilters = filter.filter((e) => e.value !== "");
    if (validFilters.length > 0) {
      const filteredData = data.filter((user) => {
        //Looping in each user for all filters. returns false if at least one filter value is not found for better performance
        for (let i = 0; i < validFilters.length; i++) {
          if (
            validFilters[i].value.length === 2 &&
            typeof validFilters[i].value === "object"
          ) {
            //Date input takes multiple values
            const userDate = new Date(
              user[validFilters[i].type[0]?.split(".")[1]]
            ).getTime();

            const startDate = validFilters[i].value[0];
            const endDate = validFilters[i].value[1];

            if (userDate) {
              if (startDate <= userDate && userDate <= endDate) {
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else if (validFilters[i].type.length === 4) {
            //Search input takes multiple types
            const userParam1 = user[validFilters[i].type[0]]?.toString();
            const userParam2 = user[validFilters[i].type[1]]?.toString();
            const userParam3 = user[validFilters[i].type[2]]?.toString();
            const userParam4 = user[validFilters[i].type[3]]?.toString();
            if (
              !userParam1
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase()) &&
              !userParam2
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase()) &&
              !userParam3
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase()) &&
              !userParam4
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase())
            ) {
              return false;
            }
          } else {
            if (
              user[validFilters[i].type[0]]?.toString().toUpperCase() !==
              validFilters[i].value.toString().toUpperCase()
            ) {
              return false; //case where user does not have at least one filter value (going to return false for data.filter function)
            }
          }
          if (i === validFilters.length - 1) {
            return true;
          }
        }
        return false;
      });
      return filteredData;
    } else {
      return data; //in case there's no filter applied returns data unfiltered
    }
  };

  //these are called when an input from /BackOfficeInput is changed, they alter the filters
  const setFiltersHandler = (filter, filterNum) => {
    if (filters[filterNum]?.value !== filter.value) {
      setFilters((prev) => {
        const newFilter = [...prev];
        newFilter[filterNum] = filter;
        return newFilter;
      });
    }
  };

  function approveReproveDocument(operation) {
    if (operation) {
      documents[docIndex].estadoDocumento = 1;
    } else {
      documents[docIndex].estadoDocumento = -1;
    }
    setIsModalShowing(false);
    dispatch(updateDocument(documents[docIndex]));
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant={"easypay-button-primary easypay-success"}
            text={t(`bpm.onboarding.backoffice.administration.buttonApprove`)}
            onclick={() => {
              approveReproveDocument(true);
            }}
          />
          <Button
            variant={"easypay-button-primary easypay-error"}
            text={t(`bpm.onboarding.backoffice.administration.buttonFail`)}
            onclick={() => {
              approveReproveDocument(false);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Modal
        className="easypay-modal"
        showModal={isModalShowing}
        setShowModal={setIsModalShowing}
        headerContent={"Pretende aprovar ou reprovar o documento ?"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-backoffice-content easypay-backoffice-tab">
        <TabSeparatorWrapper defaultActive={"tab3"}>
          <TabSeparator
            text="Leads"
            name="tab1"
            redirect={"/#/uniksystem/onboarding/backoffic-organization/leads"}
          />
          <TabSeparator
            text="Onboarding"
            name="tab2"
            redirect={
              "/#/uniksystem/onboarding/backoffic-organization/onboarding"
            }
          ></TabSeparator>
          <TabSeparator text="Documentos" name="tab3">
            <BackOfficeInput
              firstDropdownLabel={t(
                "bpm.onboarding.backoffice.administration.tableAccount"
              )}
              filterDate={"documentos.createdDate"}
              firstDropdownFilter={"estadoDocumento"}
              tableData={filterData(filteredList, filters)}
              onSetFilters={setFiltersHandler}
              savedFilters={filters}
              key={"leads-input"}
              disabled={isLoadingGetAllUsers && Object.keys(users).length === 0}
            />
            <Table
              sort={{ dataField: "documents.id", order: "desc" }}
              docs={filterData(filteredList, filters)}
              columns={columns}
              tableSize={tableSize}
              setTableSize={setTableSize}
              key="onboarding-table"
            />
          </TabSeparator>
          <TabSeparator
            text="Preçário"
            name="tab4"
            redirect={
              "/#/uniksystem/onboarding/backoffic-organization/precario"
            }
          ></TabSeparator>
        </TabSeparatorWrapper>
        <Spinner
          spinning={Object.keys(users).length === 0 && isLoadingGetAllUsers}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(BackofficeOrganization);
