import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import Modal from "~/pages/Easypay/components/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { postADDCRUDCollaborators } from "~/store/ducks/budgetManagementCRUD/actionTypes";
import { getCRUDCollaborators } from "~/store/ducks/budgetManagementCRUD/actionTypes";

const IntegrationCollaboratorsConfigureTable = ({
  t,
  usersToConfigure,
  collaboratorsData,
  setTextColaboradores,
  setUsersToConfigure,
}) => {
  const { collaborators_CRUD_ADD } = useSelector(
    (state) => state.budgetManagementCRUDReducer
  );
  const dispatch = useDispatch();
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [disableButton, setDisableButton] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenWhenFail, setisModalOpenWhenFail] = useState(false);
  const [isModalOpenWithMessage, setisModalOpenWithMessage] = useState(false);
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [empresaSelected, setEmpresaSelected] = useState("");

  useEffect(() => {
    setTextColaboradores(t(`budgetManagement.integrationcolaborators.toConfi`));
  }, []);

  const handleBack = () => {
    setUsersToConfigure([]);
    dispatch(getCRUDCollaborators());
  };

  const handleAccordionToggle = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Fecha o item do accordion se ele já estiver aberto
    } else {
      setOpenIndex(index); // Abre o item do accordion
    }
  };

  const handleChangeColorAccordion = (eventKey) => {
    setSelectedAccordion(eventKey === selectedAccordion ? null : eventKey);
  };

  const handleClickToSelect = (index) => {
    setDisableButton((prevSelectedUsers) => {
      const updatedSelectedUsers = { ...prevSelectedUsers };
      if (updatedSelectedUsers[index]) {
        delete updatedSelectedUsers[index];
      } else {
        updatedSelectedUsers[index] = true;
      }
      return updatedSelectedUsers;
    });

    if (selectedIndex === index) {
      setSelectedIndex(null); // Fecha o item do accordion se ele já estiver aberto
    } else {
      setSelectedIndex(index); // Abre o item do accordion
    }

    let nomeInput = document.getElementById(`nome-input-${index}`).value;
    let numFuncInput = document.getElementById(
      `num-funcionario-input-${index}`
    ).value;
    let precoCompraInput = document.getElementById(
      `preco-compra-input-${index}`
    ).value;
    let precoVendaInput = document.getElementById(
      `preco-venda-input-${index}`
    ).value;
    let idCategoriaInput = document.getElementById(
      `categoria-dropdown-${index}`
    ).value;
    let idEquipaInput = document.getElementById(
      `equipa-dropdown-${index}`
    ).value;
    let idDepartamentoInput = document.getElementById(
      `departamento-dropdown-${index}`
    ).value;
    let empresainput = document.getElementById(
      `empresa-dropdown-${index}`
    ).value;

    if (
      !nomeInput ||
      !numFuncInput ||
      !precoCompraInput ||
      !precoVendaInput ||
      !idCategoriaInput ||
      !idEquipaInput ||
      !idDepartamentoInput ||
      !empresainput
    ) {
      setisModalOpenWhenFail(true);
      setTimeout(() => {
        setisModalOpenWhenFail(false);
      }, 2000);
      return; // Retorna se algum campo estiver vazio
    }
    var obj = document.getElementById("select-" + index);
    if (
      obj?.innerText ===
      t(`budgetManagement.integrationcolaborators.btnChange.alterar`)
    ) {
      obj.innerText = t(
        `budgetManagement.integrationcolaborators.btnChange.confirm`
      );
    } else {
      obj.innerText = t(
        `budgetManagement.integrationcolaborators.btnChange.alterar`
      );
    }
    const user = {
      nome: nomeInput,
      numFuncionario: numFuncInput,
      precoCompra: parseFloat(precoCompraInput),
      precoVenda: parseFloat(precoVendaInput),
      idCategoria: parseFloat(idCategoriaInput),
      idEquipa: parseFloat(idEquipaInput),
      idDepartamento: parseFloat(idDepartamentoInput),
      empresa: empresainput,
      ativo: true,
    };

    if (selectedUsers[index]) {
      const updatedUsers = { ...selectedUsers };
      delete updatedUsers[index];
      setSelectedUsers(updatedUsers);
    } else {
      setSelectedUsers({ ...selectedUsers, [index]: user });
    }
  };

  const handleSubmit = () => {
    if (Object.keys(selectedUsers).length > 0) {
      const payload = {
        newCollaborators: Object.values(selectedUsers),
      };
      dispatch(postADDCRUDCollaborators(payload));
      setisModalOpenWithMessage(true);
    } else {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 2000);
    }
  };

  function dropdownOptionsCategorias(empresaValue) {
    return collaboratorsData.configuration.categorias
      .filter((categoria) => categoria.empresa === empresaValue)
      .map((categoria) => (
        <option key={categoria.id} value={categoria.id}>
          {categoria.descricao}
        </option>
      ));
  }

  function dropdownOptionsEquipas(empresaValue) {
    return collaboratorsData.configuration.equipas
      .filter((equipa) => equipa.empresa === empresaValue)
      .map((equipa) => (
        <option key={equipa.id} value={equipa.id}>
          {equipa.descricao}
        </option>
      ));
  }
  function dropdownOptionsDepartamentos(empresaValue) {
    return collaboratorsData.configuration.departamentos
      .filter((departamento) => departamento.empresa === empresaValue)
      .map((departamento) => (
        <option key={departamento.id} value={departamento.id}>
          {departamento.descricao}
        </option>
      ));
  }

  const dropdownOptionsEmpresas = collaboratorsData.configuration.empresas.map(
    (empresa) => (
      <option key={empresa.descricao} value={empresa.descricao}>
        {empresa.descricao}
      </option>
    )
  );
  const handleNomeChange = (value, index) => {
    const updatedUsers = [...usersToConfigure];
    updatedUsers[index].nome = value;
    setUsersToConfigure(updatedUsers);
  };

  const accordionItems = usersToConfigure.map((user, index) => (
    <Card key={index}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={index.toString()}
        onClick={() => handleAccordionToggle(index)}
        style={{ backgroundColor: openIndex === index ? "#6d6d6d26" : "" }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <strong style={{ padding: "10px", width: "25%" }}>
            {t(`budgetManagement.integrationcolaborators.funcNum`)}:{" "}
            {user.usrNumFuncionario}
          </strong>
          <label style={{ padding: "10px", width: "25%" }}>
            {t(`budgetManagement.integrationcolaborators.empresaCod`)}:{" "}
            {user.codEmpresa}
          </label>
          <label style={{ padding: "10px", width: "auto" }}>
            {t(`budgetManagement.integrationcolaborators.nome`)}: {user.nome}
          </label>
        </div>
        <span className="ml-auto float-right" style={{ alignSelf: "center" }}>
          {openIndex === index ? <FaCaretUp /> : <FaCaretDown />}
        </span>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="inputsLiftCrud">
            <label htmlFor={`nome-input-${index}`} className="labelsLiftCrud">
              {t(`budgetManagement.integrationcolaborators.nome`)}:
            </label>
            <input
              id={`nome-input-${index}`}
              className="form-control"
              type="text"
              value={user?.nome}
              onChange={(event) => handleNomeChange(event.target.value, index)}
              disabled={selectedUsers[index]}
              required
            />
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`num-funcionario-input-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.usrNumFunc`)}:
            </label>
            <input
              id={`num-funcionario-input-${index}`}
              className="form-control"
              type="text"
              value={user.usrNumFuncionario}
              disabled={true}
            />
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`empresa-dropdown-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.empresa`)}:
            </label>
            <select
              id={`empresa-dropdown-${index}`}
              className="form-control select-custom"
              disabled={selectedUsers[index]}
              onChange={(e) => setEmpresaSelected(e.target.value)}
            >
              <option value={""}>
                {t(`budgetManagement.integrationcolaborators.select`)}
              </option>
              {dropdownOptionsEmpresas}
            </select>
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`preco-compra-input-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.precoCompa`)}:
            </label>
            <input
              id={`preco-compra-input-${index}`}
              className="form-control"
              type="number"
              step="0.01"
              disabled={selectedUsers[index]}
              required
            />
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`preco-venda-input-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.precoVenda`)}:
            </label>
            <input
              id={`preco-venda-input-${index}`}
              className="form-control"
              type="number"
              step="0.01"
              disabled={selectedUsers[index]}
              required
            />
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`categoria-dropdown-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.categoria`)}:
            </label>
            <select
              id={`categoria-dropdown-${index}`}
              className="form-control select-custom"
              disabled={
                selectedUsers[index] ||
                (document.getElementById(`empresa-dropdown-${index}`) &&
                  document.getElementById(`empresa-dropdown-${index}`).value ===
                    "")
              }
            >
              <option value={""}>
                {document.getElementById(`empresa-dropdown-${index}`)?.value ===
                ""
                  ? t(`budgetManagement.integrationcolaborators.selectEmpresa`)
                  : t(`budgetManagement.integrationcolaborators.select`)}
              </option>
              {dropdownOptionsCategorias(
                document.getElementById(`empresa-dropdown-${index}`)?.value
              )}
            </select>
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`equipa-dropdown-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.equipa`)}:
            </label>
            <select
              id={`equipa-dropdown-${index}`}
              className="form-control select-custom"
              disabled={
                selectedUsers[index] ||
                (document.getElementById(`empresa-dropdown-${index}`) &&
                  document.getElementById(`empresa-dropdown-${index}`).value ===
                    "")
              }
            >
              <option value={""}>
                {document.getElementById(`empresa-dropdown-${index}`)?.value ===
                ""
                  ? t(`budgetManagement.integrationcolaborators.selectEmpresa`)
                  : t(`budgetManagement.integrationcolaborators.select`)}
              </option>
              {dropdownOptionsEquipas(
                document.getElementById(`empresa-dropdown-${index}`)?.value
              )}
            </select>
          </div>
          <div className="inputsLiftCrud">
            <label
              htmlFor={`departamento-dropdown-${index}`}
              className="labelsLiftCrud"
            >
              {t(`budgetManagement.integrationcolaborators.departamento`)}:
            </label>
            <select
              id={`departamento-dropdown-${index}`}
              className="form-control select-custom"
              disabled={
                selectedUsers[index] ||
                (document.getElementById(`empresa-dropdown-${index}`) &&
                  document.getElementById(`empresa-dropdown-${index}`).value ===
                    "")
              }
            >
              <option value={""}>
                {document.getElementById(`empresa-dropdown-${index}`)?.value ===
                ""
                  ? t(`budgetManagement.integrationcolaborators.selectEmpresa`)
                  : t(`budgetManagement.integrationcolaborators.select`)}
              </option>
              {dropdownOptionsDepartamentos(
                document.getElementById(`empresa-dropdown-${index}`)?.value
              )}
            </select>
          </div>
          <div style={{ display: "flex", justifyContent: "end", width: "90%" }}>
            <Button
              id={`select-${index}`}
              style={{
                marginTop: "10px",
                height: "10px",
              }}
              className="card-button"
              onClick={() => handleClickToSelect(index)}
            >
              {t(`budgetManagement.integrationcolaborators.btnChange.confirm`)}
            </Button>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  ));

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.integrationcolaborators.confirmUser`)}
            </div>
          </div>
        </div>
      </div>
    );
  }
  function createBodywhenFail() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.integrationcolaborators.insertInfo`)}
            </div>
          </div>
        </div>
      </div>
    );
  }
  function createBodyMessage() {
    return (
      <>
        {Object.keys(collaborators_CRUD_ADD).length > 0 && (
          <div
            className="lift-modal-body-wrapper"
            style={{ minWidth: "400px" }}
          >
            <div
              className="easypay-prova-de-vida-wrapper"
              style={{ width: "100%" }}
            >
              <div
                className="easypay-prova-de-vida-text"
                style={{ marginBottom: "30px" }}
              >
                <Accordion style={{ width: "100%" }}>
                  {collaborators_CRUD_ADD.collaborators.filter(
                    (collaborator) => collaborator.savedInDb
                  ).length > 0 && (
                    <Card>
                      <Card.Header
                        style={{
                          backgroundColor:
                            selectedAccordion === "savedCollaborators"
                              ? "rgba(109, 109, 109, 0.15)"
                              : "",
                        }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="savedCollaborators"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            textDecoration: "none",
                            borderBottom: "none",
                          }}
                          onClick={() =>
                            handleChangeColorAccordion("savedCollaborators")
                          }
                        >
                          {t(
                            `budgetManagement.integrationcolaborators.savedCol`
                          )}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="savedCollaborators">
                        <Card.Body>
                          <ul>
                            {collaborators_CRUD_ADD.collaborators
                              .filter((collaborator) => collaborator.savedInDb)
                              .map((collaborator) => (
                                <li key={collaborator.numFuncionario}>
                                  {collaborator.numFuncionario}
                                </li>
                              ))}
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )}

                  {collaborators_CRUD_ADD.collaborators.filter(
                    (collaborator) => !collaborator.savedInDb
                  ).length > 0 && (
                    <Card>
                      <Card.Header
                        style={{
                          backgroundColor:
                            selectedAccordion === "unsavedCollaborators"
                              ? "rgba(109, 109, 109, 0.15)"
                              : "",
                        }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="unsavedCollaborators"
                          onClick={() =>
                            handleChangeColorAccordion("unsavedCollaborators")
                          }
                        >
                          {t(
                            `budgetManagement.integrationcolaborators.notsavedCol`
                          )}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="unsavedCollaborators">
                        <Card.Body>
                          <ul>
                            {collaborators_CRUD_ADD.collaborators
                              .filter((collaborator) => !collaborator.savedInDb)
                              .map((collaborator) => (
                                <li key={collaborator.numFuncionario}>
                                  {collaborator.numFuncionario}:{" "}
                                  {t(
                                    `budgetManagement.integrationcolaborators.error`
                                  )}
                                </li>
                              ))}
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )}
                </Accordion>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{ marginBottom: "10px" }}
                  className="card-button"
                  onClick={() => handleBack()}
                >
                  {t(`budgetManagement.integrationcolaborators.btnVoltar`)}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Modal showModal={isModalOpen} bodyContent={createBody()} />
      <Modal
        showModal={isModalOpenWhenFail}
        bodyContent={createBodywhenFail()}
      />
      <Modal
        showModal={isModalOpenWithMessage}
        bodyContent={createBodyMessage()}
      />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ marginBottom: "10px" }}
          className="card-button"
          onClick={() => handleBack()}
        >
          {t(`budgetManagement.integrationcolaborators.btnVoltar`)}
        </Button>
      </div>
      <Accordion>{accordionItems}</Accordion>
      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
      >
        <Button
          style={{ marginBottom: "10px" }}
          className="card-button"
          onClick={() => handleSubmit()}
        >
          {t(`budgetManagement.integrationcolaborators.save`)}
        </Button>
      </div>
    </>
  );
};

export default withNamespaces()(IntegrationCollaboratorsConfigureTable);
