import React, { useState, useEffect } from "react";

const CreateTableTextInput = (props) => {
  const { id, name, value, linha, onBlur, size, maxLength, fontSize } = props;

  const [inputValue, setValue] = useState("");

  useEffect(() => {
    if (value) setValue(value);
  }, [value]);

  return (
    <td id={id}>
      <input
        className="form-control"
        name={name}
        type="text"
        size={size}
        style={{ width: "90%", maxHeight: "44px", fontSize: `${fontSize}px` }}
        maxLength={maxLength}
        value={inputValue}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          onBlur(linha, 66);
        }}
      />
    </td>
  );
};
export default CreateTableTextInput;
