import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_replegmodal.scss";
import BenefInput from "./BenefInput";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import CustomButton from "./Button";
import Input from "./Input";
import Link from "./Link";
import Modal from "./Modal";
import RadioButtonItem from "./RadioButtonItem";
import Text from "./Text";
import BenefModalDocComponent from "./BenefModalDocComponent";

function BenefModalContent({
  title,
  isModalShowing,
  setIsModalShowing,
  info,
  variant,
  t,
  isEni,
}) {
  var contents;
  const [currentDocTypeTab, setCurrentDocTypeTab] = useState(
    t(`bpm.onboarding.backoffice.user.labelCitizenCard`)
  );
  const [benefName, setBenefName] = useState(info.get("Nome").value);
  const [benefNIF, setBenefNIF] = useState(
    info.get("bpm.onboarding.backoffice.user.labelNIF").value
  );
  const [benefDirectCapital, setBenefDirectCapital] = useState(
    info.get("bpm.onboarding.backoffice.user.labelDirectCapital").value
  );
  const [benefIndirectCapital, setBenefIndirectCapital] = useState(
    info.get("bpm.onboarding.backoffice.user.labelIndirectCapital").value
  );
  const [benefCompany, setBenefCompany] = useState(
    info.get("bpm.onboarding.backoffice.user.labelCompany").value
  );
  const [benefAddress, setBenefAddress] = useState(
    info.get("bpm.onboarding.backoffice.user.labelAddress").value
  );

  useEffect(() => {
    if (document.dados["accordion_benef_doctipo"]) {
      if (variant === t("bpm.onboarding.backoffice.user.labelIndividual")) {
        document.dados["accordion_benef_doctipo"].value = currentDocTypeTab;
      } else {
        document.dados["accordion_benef_doctipo"].value = "";
        document.dados["accordion_benef_numdocumento"].value = "";
      }
    }
  }, [currentDocTypeTab]);

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editBenef';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function buildInputField(text, value, variable) {
    if (text === t(`bpm.onboarding.backoffice.user.labelName`)) {
      return {
        value: value.split("::")[0],
        obligatory: false,
        variable,
        maxlenght: 9,
        text,
      };
    }
    if (text === t(`bpm.onboarding.backoffice.user.labelIndirectCapital`)) {
      return {
        value: value.split("::")[0],
        obligatory: false,
        variable,
        maxlenght: 9,
        text,
        disabled: isEni,
      };
    }
    return {
      value: value,
      obligatory: false,
      variable,
      maxlenght: 9,
      text,
    };
  }

  function createBody() {
    return (
      <div className="easypay-benef-modal-body-wrapper">
        <div style={{ width: "500px" }}>
          <BenefInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              benefName,
              "accordion_benef_nome"
            )}
            key={"accordion_benef_nome"}
            textInputValue={benefName}
            setTextInputValue={setBenefName}
          ></BenefInput>
        </div>
        <div style={{ width: "500px" }}>
          {variant === "Empresa" ? (
            <BenefInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelNIPC`),
                benefNIF,
                "accordion_benef_nif"
              )}
              key={"accordion_benef_nif"}
              textInputValue={benefNIF}
              setTextInputValue={setBenefNIF}
            ></BenefInput>
          ) : (
            <BenefInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelNIF`),
                benefNIF,
                "accordion_benef_nif"
              )}
              key={"accordion_benef_nif"}
              textInputValue={benefNIF}
              setTextInputValue={setBenefNIF}
            ></BenefInput>
          )}
        </div>
        <div style={{ width: "180px" }}>
          <BenefInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelDirectCapital`),
              benefDirectCapital,
              "accordion_benef_capital"
            )}
            key={"accordion_benef_capital"}
            textInputValue={benefDirectCapital}
            setTextInputValue={setBenefDirectCapital}
          ></BenefInput>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
          }}
        >
          <div style={{ width: "200px", paddingRight: "20px" }}>
            <BenefInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelIndirectCapital`),
                benefIndirectCapital,
                "accordion_benef_capitalind"
              )}
              key={"accordion_benef_capitalind"}
              textInputValue={benefIndirectCapital}
              setTextInputValue={setBenefIndirectCapital}
              disabled={true}
            ></BenefInput>
          </div>
          <div style={{ width: "300px" }}>
            <BenefInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelCompany`),
                benefCompany,
                "accordion_benef_empresa"
              )}
              key={"accordion_benef_empresa"}
              textInputValue={benefCompany}
              setTextInputValue={setBenefCompany}
            ></BenefInput>
          </div>
        </div>
        <div style={{ width: "500px" }}>
          <BenefInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelAddress`),
              benefAddress,
              "accordion_benef_morada"
            )}
            key={"accordion_benef_morada"}
            textInputValue={benefAddress}
            setTextInputValue={setBenefAddress}
          ></BenefInput>
        </div>
        {variant === "Individual" && (
          <BenefModalDocComponent
            docNum={
              info.get("bpm.onboarding.backoffice.user.labelDocumentNumber")
                .value
            }
          />
        )}
        <div
          className="easypay-repleg-modal-footer-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <div
            className="easypay-repleg-modal-link-wrapper"
            style={{ paddingTop: "8px" }}
            onClick={(event) => {
              event.preventDefault();
              setIsModalShowing(false);
            }}
          >
            <Link
              text={t("bpm.onboarding.backoffice.user.buttonCancel")}
              disabledColored={false}
              disabled={false}
              link=""
              key={uuid()}
            />
          </div>
          <div
            className="easypay-repleg-modal-button-wrapper"
            style={{ paddingLeft: "25px" }}
          >
            <CustomButton
              text={t("bpm.onboarding.backoffice.user.buttonAdd")}
              variant={"easypay-button-primary"}
              key={uuid()}
              field={addButtonField}
              id={1}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={false}
            />
          </div>
        </div>
      </div>
    );
  }

  contents = {
    modalTitle: title,
    modalBody: createBody(),
  };

  return (
    <Modal
      className="easypay-modal"
      showModal={isModalShowing}
      setShowModal={setIsModalShowing}
      headerContent={contents.modalTitle}
      bodyContent={contents.modalBody}
      key={uuid()}
    ></Modal>
  );
}

export default withNamespaces()(BenefModalContent);
