import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Spinner from "~/components/Spinner";
import { Button } from "react-bootstrap";
import ModalCatalogo from "./ModalCatalogo";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  getCatalogoFormacao,
  deleteCatalogoFormacao,
  getCatalogoFormacaoDropdowns,
  setDeleteCatalogoFormacaoMessage,
} from "store/ducks/processes/actionTypes";

const AlertMaterial = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CatalogoTable = ({ t }) => {
  const dispatch = useDispatch();

  const {
    catalogoFormacao,
    isLoadingCatalogoFormacao,
    deleteCatalogoFormacaoMessage,
  } = useSelector((state) => state.processesReducer);

  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    dispatch(getCatalogoFormacaoDropdowns());
  }, []);

  useEffect(() => {
    if (deleteCatalogoFormacaoMessage !== "") {
      setShowAlert(true);
      setAlertMessage(t(deleteCatalogoFormacaoMessage));
    }
  }, [deleteCatalogoFormacaoMessage]);

  const handleCreate = () => {
    setOpenModalTemplate(true);
    //dispatch(getTemplateDetail());
  };

  const handleDeleteDialog = (row) => {
    setOpenDialog(true);
    setSelectedTemplate(row);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedTemplate(undefined);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteCatalogoFormacao(selectedTemplate.afrcode));
    setIsLoading(true);
    setTimeout(() => {
      dispatch(getCatalogoFormacao());
      setIsLoading(false);
    }, 2000);
    handleClose();
  };

  const handleOpenModalTemplate = (catalogo) => {
    setSelectedTemplate(catalogo);
    setOpenModalTemplate(true);
    // dispatch(getTemplateDetail(template.id));
  };
  const handleCloseModalTemplate = () => {
    setSelectedTemplate(undefined);
    setOpenModalTemplate(false);
    // dispatch(setTemplateDetail([]));
    // dispatch(setTemplateDocumentsAll([]));
  };

  const handleSaveAndCloseModalTemplate = () => {
    setSelectedTemplate(undefined);
    setOpenModalTemplate(false);
    setIsLoading(true);
    setTimeout(() => {
      dispatch(getCatalogoFormacao());
      setIsLoading(false);
    }, 2000);
    // dispatch(setTemplateDetail([]));
    // dispatch(setTemplateDocumentsAll([]));
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
        <i
          className={`icon-trash table-action-icon`}
          onClick={() => handleDeleteDialog(row)}
        />
        <i
          className={`icon-edit table-action-icon`}
          onClick={() => handleOpenModalTemplate(row)}
        />
      </Stack>
    </>
  );

  const buildAtiveColumn = (_, row, {}) => (
    <>
      {row.situacao === "1" ? (
        <span style={{ color: "green", fontSize: "larger" }}>Ativo</span>
      ) : (
        <span style={{ color: "red", fontSize: "larger" }}>Inativo</span>
      )}
    </>
  );

  const columns = [
    {
      dataField: "afrcode",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "afrdesignacao",
      text: "Designação",
      sort: true,
    },
    {
      dataField: "situacao",
      text: "Estado",
      sort: true,
      formatter: buildAtiveColumn,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
      hidden: false,
    },
    {
      dataField: "accao",
      text: t("portalrh.rhadmin.gestaoTemplates.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
  ];

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setDeleteCatalogoFormacaoMessage(""));
    setShowAlert(false);
    setAlertMessage("");
  };

  const { SearchBar } = Search;
  return (
    <>
      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={catalogoFormacao}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "20px", display: "flex" }}>
                <SearchBar
                  {...props.searchProps}
                  placeholder={t(
                    "portalrh.rhadmin.gestaoTemplates.searchInput"
                  )}
                />
                <div style={{ paddingLeft: "30px" }}>
                  <ButtonAdd
                    text={t("portalrh.rhadmin.gestaoTemplates.add")}
                    onClick={handleCreate}
                  />
                </div>
              </div>

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoadingCatalogoFormacao || isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {!isLoadingCatalogoFormacao && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t(
                    "portalrh.rhadmin.gestaoTemplates.emptyTable"
                  )}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalCatalogo
        openModalTemplate={openModalTemplate}
        handleCloseModalTemplate={handleCloseModalTemplate}
        handleSaveAndCloseModalTemplate={handleSaveAndCloseModalTemplate}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <AlertMaterial
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </AlertMaterial>
      </Snackbar>

      {selectedTemplate && (
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Deseja apagar o catálogo: {selectedTemplate.afrdesignacao}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleClose}
            >
              {t("portalrh.rhadmin.gestaoTemplates.close")}
            </Button>
            <Button
              className="card-button"
              onClick={() => handleDeleteConfirm()}
            >
              {t("portalrh.rhadmin.gestaoTemplates.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withNamespaces()(CatalogoTable);
