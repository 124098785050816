import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { DateTime } from "luxon";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import {
  getPerEvForm,
  getPerEvFormDropdown,
  updatePerEvForm,
  createPerEvForm,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { Col, Form } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";
import ButtonsCreateUpdate from "./ButtonsCreateUpdate";

const RhPerformanceEvForm = ({ rowCollaborator, t }) => {
  const [date, setDate] = useState("");
  const [oldDate, setOldDate] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [comments, setcomments] = useState("");
  const [qualitityList, setQualitityList] = useState([]);
  const [qualititySelected, setQualititySelected] = useState("");
  const [quantityAssessement, setQuantityAssessement] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [performancePage, setPerformancePage] = useState(false);
  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [empCode, setEmpCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const dispatch = useDispatch();

  const { perEvFormData, perEvFormDropdown, isLoadingUpdatePerEvForm } =
    useSelector((state) => state.personalReducer);
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getPerEvFormDropdown());
      dispatch(
        getPerEvForm(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      setIsNew(false);
      setRowClicked([]);
      setPerformancePage(true);
    }
  }, [rowCollaborator, isLoadingUpdatePerEvForm]);

  const handleQualitity = (e) => {
    setQualititySelected(e);
  };

  const handleQuantityEvolution = (e) => {
    setQuantityAssessement(e.target.value);
  };

  const handleDate = (e) => {
    setDate(e);
  };

  const handleComments = (e) => {
    setcomments(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (perEvFormDropdown && Object.keys(perEvFormDropdown).length > 0) {
        setQualitityList(perEvFormDropdown.qualitAssessement);
      }
      if (data?.length > 0) {
        setcomments(data[0].comments);
        setQuantityAssessement(data[0].quantEvalution);

        setQualititySelected(data[0].trainingSheet);

        setEmpCode(data[0].empCode);

        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          } else {
            set(date);
          }
        };
        checkDate(data[0].data, setDate);
        checkDate(data[0].data, setOldDate);
      } else {
        setcomments("");
        setDate();
        setQuantityAssessement("");
        setQualititySelected("");
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(perEvFormData);
    }
  }, [perEvFormData, perEvFormDropdown, rowClicked]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };
  const formatQualitative = (cell) => {
    if (perEvFormDropdown && Object.keys(perEvFormDropdown).length > 0) {
      for (let i = 0; i < perEvFormDropdown?.qualitAssessement?.length; i++) {
        if (cell == perEvFormDropdown.qualitAssessement[i].value) {
          return <p>{perEvFormDropdown.qualitAssessement[i].label}</p>;
        }
      }
    }
  };

  const formatEndData = (cell) => {
    if (cell !== undefined && cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const columns = [
    {
      dataField: "trainingSheet",
      text: t(`portalrh.PerformanceEvForm.QualitAssessment`),
      sort: true,
      formatter: formatQualitative,
      style: { fontSize: "12px" },
    },
    {
      dataField: "quantEvalution",
      text: t(`portalrh.PerformanceEvForm.QuantEvaluation`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "data",
      text: t(`portalrh.PerformanceEvForm.date`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
  ];
  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }
  const cleanInputs = () => {
    setcomments("");
    setDate();
    setQualititySelected("");
    setQuantityAssessement("");
  };

  const performanceObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    quantEvalution: quantityAssessement,
    comments: comments,
    data: date,
    trainingSheet: qualititySelected,
    oldDate: oldDate,
    lepNum: rowCollaborator.funcionarioNum,
    empCode: empCode,
  };


  const updateData = (bollean) => {
    setPerformancePage(bollean);
    setIsClicked(false);
    if (isNew) {
      dispatch(createPerEvForm(performanceObject));
    } else {
      dispatch(updatePerEvForm(performanceObject));
    }
  };

  const createNewData = (bollean) => {
    setPerformancePage(bollean);

    dispatch(createPerEvForm(performanceObject));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      cleanInputs();
      setNewButtonClicked(true);
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };
  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
        <ButtonsCreateUpdate
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            setRowClicked={setRowClicked}
            setIsNew={setIsNew}
            cleanInputs={cleanInputs}
          />
          {/* {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              performancePage={performancePage}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              performancePage={performancePage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
            />
          ) : null} */}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="3" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.PerformanceEvForm.QualitAssessment`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={qualitityList}
              handleSubmit={handleQualitity}
              defaultValue={qualititySelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="3" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.PerformanceEvForm.QuantEvaluation`)}
            </Form.Label>
            <Form.Control
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              value={quantityAssessement == null ? "" : quantityAssessement}
              onChange={handleQuantityEvolution}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.PerformanceEvForm.date`)}
            </Form.Label>
            <DateTimePicker
              value={date}
              onChange={handleDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="12">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.PerformanceEvForm.Comments`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={comments == null ? "" : comments}
                onChange={handleComments}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
        </div>

        <RhTableList
          tableId={"36"}
          columns={columns}
          data={perEvFormData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhPerformanceEvForm);
