import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { getGraphInfoBySelectedItem } from "~/store/ducks/graphs/actionTypes";
import { Row, Col } from "react-bootstrap";
import Chart from "chart.js";
import StatisticsPeriodGroupFilters from "./StatisticsPeriodGroupFilters";
import ChartDataLabels from "chartjs-plugin-datalabels"

const StatisticsPendingTasks = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const { graphInfo } = useSelector((state) => state.graphsReducer);

  useEffect(() => {
    if (Object.keys(graphInfo).length > 0) {
      const myChartRef = myRef.current.getContext("2d");

      // Add the ChartDataLabels plugin to the chart configuration
      const chartConfig = {
        ...graphInfo,
        plugins: [ChartDataLabels],
      };

      new Chart(myChartRef, chartConfig);
    }
  }, [graphInfo]);

  const executeGraph = (startAt, endAt, group) => {
    dispatch(getGraphInfoBySelectedItem());
  };

  const renderGroups = () => {
    return [
      { label: "Grupo 1", value: "1" },
      { label: "Grupo 2", value: "2" },
      { label: "Grupo 3", value: "3" },
      { label: "Grupo 4", value: "4" },
      { label: "Grupo 5", value: "5" },
    ];
  };

  return (
    <Row>
      <Col
        xs={12}
        md={4}
        lg={4}
        style={{padding: "10px" }}
      >
        <StatisticsPeriodGroupFilters
          executeGraph={executeGraph}
          renderGroups={renderGroups}
        />
      </Col>
      <Col
        xs={12}
        md={4}
        lg={4}
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "100%", width: "auto" }}>
          <canvas
            id="myAreaChart"
            ref={myRef}
            width={650}
            height={280}
          ></canvas>
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(StatisticsPendingTasks);
