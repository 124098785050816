export const SAVE_USER_DATA = "simulator@SAVE_USER_DATA";
export const SET_USER_DATA = "simulator@SET_USER_DATA";
export const GET_USER_DATA = "simulator@GET_USER_DATA";
export const GET_COLLABORATOR_BOOLEAN = "simulator@GET_COLLABORATOR_BOOLEAN";
export const PUT_USER_ID = "simulator@PUT_USER_ID";
export const DELETE_USER_DATA = "simulator@DELETE_USER_DATA";
export const SET_COLLABORATOR_DATA = "simulator@SET_COLLABORATOR_DATA";
export const GET_COLLABORATOR_DATA = "simulator@GET_COLLABORATOR_DATA";
export const SAVE_USER_DATA_EXCEL = "simulator@SAVE_USER_DATA_EXCEL";
export const GET_CODES_SIMULATOR = "simulator@GET_CODES_SIMULATOR";
export const SET_CODES_SIMULATOR = "simulator@SET_CODES_SIMULATOR";

export const setUserData = (savedUserData) => {
  return { type: SET_USER_DATA, savedUserData };
};

export const getUserData = (userData) => {
  return { type: GET_USER_DATA, userData };
};
export const saveUserData = (data) => ({
  type: SAVE_USER_DATA,
  data,
});

export const saveUserDataExcel = (data) => ({
  type: SAVE_USER_DATA_EXCEL,
  data,
});

export const getCodesSimulator = () => {
  return { type: GET_CODES_SIMULATOR };
}

export const setCodesSimulator = (codes) => {
  return { type: SET_CODES_SIMULATOR, codes };
}

export const getCollaboratorBoolean = () => {
  return { type: GET_COLLABORATOR_BOOLEAN };
};

export const putUserId = (data, id) => {
  return { type: PUT_USER_ID, data, id };
};

export const deleteUserData = (id) => {
  return { type: DELETE_USER_DATA, id };
};

export const getCollaboratorData = (id, companyId) => {
  return { type: GET_COLLABORATOR_DATA, id, companyId };
};

export const setCollaboratorData = (collaborators) => {
  return { type: SET_COLLABORATOR_DATA, collaborators };
};
