import React, { useEffect, useState, useRef } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import signature from "~/assets/img/icons/signature.svg";
import pdfsample from "../../utils/pdfsample";
import { getSignedPdfs } from "~/store/ducks/onboarding/actionTypes";
import { substituteIflowDocument } from "~/store/ducks/processes/actionTypes";
import { withNamespaces } from "react-i18next";
import oauth from "~/utils/oauth";
import { setSignedPdf } from "~/store/ducks/onboarding/actionTypes";
import SignPdfModal from "../SignPdfModal";
import { FaFileSignature } from "react-icons/fa";
import { SIGNATURE_COORDINATES_X, SIGNATURE_COORDINATES_Y } from "../../utils";
import {
  updateSelectedTosignTableFileComponent,
  setSelectedTosignTableFileComponent,
  setTableFileSignClicked,
  getIflowDocumentTableFileComponent,
  resetIflowDocumentTableFileComponent,
} from "~/store/ducks/processesSignature/actionTypes";
const devLogConsole = require("~/utils/devLog");
const CreateTableFile = (props) => {
  const { t, field, styleSheet, cell, rowIndex, signRef } = props;

  const { signedPdf } = useSelector((state) => state.onboardingReducer);
  const { user } = useSelector((selectorState) => selectorState.globalReducer);

  const {
    selectedToSignTableFileComponent,
    tableFileSignClicked,
    iflowDocumentTableFileComponent,
    isLoadingIflowDocumentTableFileComponent,
  } = useSelector((state) => state.processesSignatureReducer);
  const myRef = useRef(null);

  const dispatch = useDispatch();

  const [showSignModal, setShowSignModal] = useState(false);
  const [documentBlobs, setDocumentBlobs] = useState([]);
  const [base64, setBase64] = useState("");
  const [updateCoordinatesObj, setUpdateCoordinatesObj] = useState(null);

  useEffect(() => {
    const component = myRef.current;
    if (
      selectedToSignTableFileComponent &&
      selectedToSignTableFileComponent.some(
        (i) => i?.file?.id === field?.file?.id
      )
    ) {
      if (component) {
        component.checked = true;
      }
    } else {
      if (component) {
        component.checked = false;
      }
    }
  }, [selectedToSignTableFileComponent, field]);

  /* start sign process*/
  useEffect(() => {
    if (
      Array.isArray(iflowDocumentTableFileComponent) &&
      Array.isArray(selectedToSignTableFileComponent) &&
      iflowDocumentTableFileComponent.length > 0 &&
      selectedToSignTableFileComponent.length > 0 &&
      tableFileSignClicked
    ) {
      let filesReturned = true;
      selectedToSignTableFileComponent.forEach((check) => {
        if (
          !iflowDocumentTableFileComponent.some(
            (i) => i.docid === check?.file?.id
          )
        ) {
          //not all files have returned from iflow yet
          filesReturned = false;
        }
      });

      if (filesReturned) {
        let blobsarr = [];
        if (selectedToSignTableFileComponent.length > 1) {
          iflowDocumentTableFileComponent.forEach((d) => {
            //convert file to Blob
            const file = new Blob([d.file], {
              type: "application/pdf",
            });
            blobsarr.push({
              file: file,
              filename: d.filename.replaceAll("/", "symbolBarra"),
              docid: d.docid,
            });
          });
          setDocumentBlobs(blobsarr);
          setBase64(pdfsample);
          handleShowSignModal();
        } else {
          iflowDocumentTableFileComponent.forEach((d) => {
            //convert file to Blob
            const file = new Blob([d.file], {
              type: "application/pdf",
            });
            //todo find selected
            if (d.docid === selectedToSignTableFileComponent[0]?.file?.id) {
              fileToBase64(file)
                .then((result) => {
                  setBase64(result);
                })
                .catch((e) => Error(e));
            }
            blobsarr.push({
              file: file,
              filename: d.filename.replaceAll("/", "symbolBarra"),
              docid: d.docid,
            });
          });
          setDocumentBlobs(blobsarr);
          handleShowSignModal();
        }
        dispatch(setTableFileSignClicked(false));
      }
    }
  }, [
    iflowDocumentTableFileComponent.length,
    field,
    selectedToSignTableFileComponent.length,
    styleSheet,
    tableFileSignClicked,
    isLoadingIflowDocumentTableFileComponent,
  ]);

  const isHash = (name) => {
    let isUpdatingIflowDoc = false;
    if (
      selectedToSignTableFileComponent.some(
        (item) =>
          item?.file?.name.toLocaleLowerCase() === `${name}`.toLocaleLowerCase()
      )
    ) {
      isUpdatingIflowDoc = true;
    }
    if (isUpdatingIflowDoc) {
      const foundItem = selectedToSignTableFileComponent.find(
        (i) =>
          i?.file?.name.toLocaleLowerCase() === `${name}`.toLocaleLowerCase()
      );
      if (foundItem && foundItem?.file?.id) {
        const id = foundItem?.file?.id;
        if (id && id.includes("hdoc=")) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const getFileName = (docid) => {
    const foundItem = selectedToSignTableFileComponent.find(
      (item) => item?.file?.id === docid
    );
    if (foundItem && foundItem.file) {
      return foundItem.file.name;
    }
  };

  //Substitute the document in IFlow with the signed one
  useEffect(() => {
    const addItem = (id, array) => {
      if (!array.some((i) => i.docid === id)) {
        array.push({ docid: id });
      }
    };
    if (signedPdf && selectedToSignTableFileComponent?.length > 0) {
      //find min cell
      let mincell = selectedToSignTableFileComponent[0]?.cell;
      let minRowIndex = selectedToSignTableFileComponent[0]?.rowIndex;
      selectedToSignTableFileComponent.forEach((item) => {
        if (item.cell < mincell) {
          mincell = item.cell;
          minRowIndex = item.rowIndex;
        }
      });

      if (
        cell === mincell &&
        rowIndex === minRowIndex &&
        selectedToSignTableFileComponent.some(
          (i) => i.cell === mincell && i.rowIndex === minRowIndex
        )
      ) {
        //update iFlow table once
        if (signedPdf.fileList && signedPdf.fileList.length > 0) {
          signedPdf.fileList.forEach((item) => {
            const base64String = item.file;
            if (base64String) {
              //convert base64 to file
              const url = `data:application/pdf;base64,${base64String}`;
              function urltoFile(url, filename, mimeType) {
                return fetch(url)
                  .then(function (res) {
                    return res.arrayBuffer();
                  })
                  .then(function (buf) {
                    return new File([buf], filename, { type: mimeType });
                  });
              }

              const findDocId = (name) => {
                let isUpdatingIflowDoc = false;
                if (
                  selectedToSignTableFileComponent.some(
                    (item) =>
                      item?.file?.name.toLocaleLowerCase() ===
                      `${name}`.toLocaleLowerCase()
                  )
                ) {
                  isUpdatingIflowDoc = true;
                }
                if (isUpdatingIflowDoc) {
                  const foundItem = selectedToSignTableFileComponent.find(
                    (i) =>
                      i?.file?.name.toLocaleLowerCase() ===
                      `${name}`.toLocaleLowerCase()
                  );
                  if (foundItem && foundItem?.file?.id) {
                    const id = foundItem?.file?.id;
                    if (id && id.includes("hdoc=")) {
                      const arr = id.split("hdoc=");
                      return arr[1];
                    } else {
                      return id;
                    }
                  }
                }
                return null;
              };

              const findVariable = (name) => {
                let isUpdatingIflowDoc = false;
                if (
                  selectedToSignTableFileComponent.some(
                    (item) =>
                      item?.file?.name.toLocaleLowerCase() ===
                      `${name}`.toLocaleLowerCase()
                  )
                ) {
                  isUpdatingIflowDoc = true;
                }
                if (isUpdatingIflowDoc) {
                  const foundItem = selectedToSignTableFileComponent.find(
                    (i) =>
                      i?.file?.name.toLocaleLowerCase() ===
                      `${name}`.toLocaleLowerCase()
                  );
                  if (foundItem.variable) {
                    return foundItem?.variable;
                  }
                }
                return null;
              };

              const itemName = getFileName(item.name);
              urltoFile(url, itemName, "application/pdf").then(function (file) {
                const data = new FormData();
                const allFormdata = new FormData(
                  document.getElementsByName("dados")[0]
                );
                /* todo uncomment*/
                const flowId = allFormdata.get("flowid");
                const pid = allFormdata.get("pid");
                const subpid = allFormdata.get("subpid");

                /* test data
                const flowId = "310";
                const pid = "14373";
                const subpid = 1;*/

                //from AppletDocParameters.java
                data.append("file", file);
                data.append("variable", findVariable(itemName));

                data.append("update", "true");
                data.append("NUMASS", "1");

                if (isHash(itemName)) {
                  data.append("hdoc", item.name);
                } else {
                  data.append("docid", item.name);
                }

                data.append("flowid", flowId);
                data.append("pid", pid);
                data.append("subpid", 1);

                const url_ =
                  "/DocumentService?" +
                  "Authorization=" +
                  oauth.getAccessToken();

                if (
                  findDocId(itemName) !== null &&
                  findDocId(itemName) !== undefined
                ) {
                  //to do subpid generelized
                  const updateCoords = {
                    ...updateCoordinatesObj,
                    docid: findDocId(itemName),
                    flowId: flowId,
                    pid: pid,
                    subpid: 1,
                    isHash: isHash(itemName),
                  };
                  dispatch(substituteIflowDocument(url_, data, updateCoords));

                  addItem(item.name, signRef.current);
                }
              });
            }
          });

          dispatch(setSignedPdf({}));
          dispatch(setSelectedTosignTableFileComponent([]));
        }
      }
    }
  }, [signedPdf, field, selectedToSignTableFileComponent]);

  const handleShowSignModal = () => {
    //find min cell
    //let mincell = cell;
    //let minRowIndex = rowIndex;
    let mincell = selectedToSignTableFileComponent[0]?.cell;
    let minRowIndex = selectedToSignTableFileComponent[0]?.rowIndex;
    selectedToSignTableFileComponent.forEach((item) => {
      if (item.cell < mincell) {
        mincell = item.cell;
        minRowIndex = item.rowIndex;
      }
    });
    //find min rowIndex

    if (
      cell === mincell &&
      rowIndex === minRowIndex &&
      selectedToSignTableFileComponent.some(
        (i) => i.cell === mincell && i.rowIndex === minRowIndex
      )
    ) {
      //show only one modal
      setShowSignModal(true);
    }
  };

  const handleCloseSignModal = () => {
    setShowSignModal(false);
    dispatch(setTableFileSignClicked(false));
    dispatch(resetIflowDocumentTableFileComponent());
  };

  //sign document
  async function testSignPdf(
    coordinates,
    pageNumber,
    lastPage,
    reduced,
    givenLocation,
    givenReason,
    rectCoord,
    rectCoordTopLeft,
    rectCoordBottomRight,
    longText,
    stampAll,
    stampImage,
    stampWithoutSign,
    isCustomLogo = false
  ) {
    let payload = [];
    //convert blobs to base64 strings
    devLogConsole(
      "selectedToSign and blobs",
      selectedToSignTableFileComponent,
      documentBlobs
    );
    const promises = documentBlobs.map(async (d) => {
      const result = await fileToBase64(d?.file).catch((e) => Error(e));

      return {
        file: result,
        filename: d.docid, // send docid or hdoc instead of real filename
        id: d.docid,
        stampImage: stampImage,
      };
    });
    const results = await Promise.all(promises);
    if (results) {
      results.forEach((r) => {
        try {
          const part =
            r.file && r.file.split("data:application/pdf;base64,")[1];
          const stampPart = r.stampImage;
          if (part) {
            if (
              Array.isArray(selectedToSignTableFileComponent) &&
              selectedToSignTableFileComponent.some((i) => i?.file?.id === r.id)
            )
              payload.push({
                file: part,
                fileName: r.filename.replace(/\.[^/.]+$/, ""), //without extension
                stampImage: stampPart,
              });
          }
        } catch (e) {
          devLogConsole("error", e);
          devLogConsole("result", r);
        }
      });
    }

    if (payload.length > 0) {
      const queryParams = {
        posX: coordinates.x ? coordinates.x : SIGNATURE_COORDINATES_X,
        posY: coordinates.y ? coordinates.y : SIGNATURE_COORDINATES_Y,
        pagina: pageNumber ? pageNumber : 1,
        ultima: lastPage !== null ? lastPage : false,
        visivel: stampImage && styleSheet?.signatureOrStamp ? false : true,
        reduzido: reduced !== null ? reduced : true,
        givenLocation,
        givenReason,
        additionalText: longText,
        boxLowerLeftX: rectCoord ? rectCoord.x : 0.1,
        boxLowerLeftY: rectCoord ? rectCoord.y : 0.1,
        boxHigerLeftX: rectCoordTopLeft ? rectCoordTopLeft.x : 0.1,
        boxHigherLeftY: rectCoordTopLeft ? rectCoordTopLeft.y : 0.1,
        boxLowerRightX: rectCoordBottomRight ? rectCoordBottomRight.x : 0.1,
        boxLowerRightY: rectCoordBottomRight ? rectCoordBottomRight.y : 0.1,
        stampAll: stampAll,
        stampWithoutSign: stampWithoutSign,
        isCustomLogo: isCustomLogo,
      };
      if (payload.length == 1) {
        setUpdateCoordinatesObj({
          x: coordinates.x ? coordinates.x : SIGNATURE_COORDINATES_X,
          y: coordinates.y ? coordinates.y : SIGNATURE_COORDINATES_Y,
          numpages: pageNumber ? pageNumber : 1,
        });
      }

      dispatch(getSignedPdfs(payload, queryParams));
    }
  }

  const handleSignAll = (e, payload) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      selectedToSignTableFileComponent &&
      selectedToSignTableFileComponent.length > 0
    ) {
      selectedToSignTableFileComponent.forEach((field_) => {
        if (!Array.isArray(field_.file)) {
          const foundItem = field_.file;
          if (foundItem) {
            if (foundItem && foundItem.link_url !== "") {
              const payload = {
                url:
                  foundItem.link_url +
                  "&Authorization=" +
                  oauth.getAccessToken(),
                docid: foundItem.id,
                filename: foundItem.name,
              };

              dispatch(getIflowDocumentTableFileComponent(payload));
            }
          }
        }
      });
      if (selectedToSignTableFileComponent.length > 0) {
        dispatch(setTableFileSignClicked(true));
        dispatch(
          setSelectedTosignTableFileComponent(selectedToSignTableFileComponent)
        );
      }
    } else {
      //one file to sign, without using checkbox
      if (!Array.isArray(field.file)) {
        const foundItem = field.file;
        if (foundItem) {
          if (foundItem && foundItem.link_url !== "") {
            const payload = {
              url:
                foundItem.link_url + "&Authorization=" + oauth.getAccessToken(),
              docid: foundItem.id,
              filename: foundItem.name,
            };
            dispatch(getIflowDocumentTableFileComponent(payload));
          }
        }
      }
      dispatch(setTableFileSignClicked(true));
      dispatch(setSelectedTosignTableFileComponent([payload]));
    }
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const renderFileList = () => {
    let array = field.file;
    if (!Array.isArray(field.file)) {
      array = [field.file];
    }
    const renderItems = () => {
      return array.map((f, index) => {
        const extension = f?.name?.split(".").pop().toLocaleLowerCase();
        return (
          <div
            style={{
              display: "flex",
              justifyContent: array.length > 1 ? "space-between" : "start",
              flexWrap: "nowrap",
            }}
            key={index}
          >
            {f?.tosign === "true" && extension === "pdf" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  marginLeft: "0",
                }}
              >
                <input
                  ref={myRef}
                  type="checkbox"
                  style={{
                    margin: "5px 15px 5px 0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    zIndex: 100,
                  }}
                  onChange={(e) => {
                    {
                      const payload = {
                        ...field,
                        rowIndex: rowIndex,
                        cell: cell,
                      };
                      dispatch(
                        updateSelectedTosignTableFileComponent(
                          payload,
                          e?.target?.checked
                        )
                      );
                    }
                  }}
                />
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip`}>{t("fileInput.toSign")}</Tooltip>
                  }
                >
                  {styleSheet.iconSignature &&
                  styleSheet.iconSignature === "FaFileSignature" ? (
                    <FaFileSignature
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        color: "#24579e",
                      }}
                      onClick={(e) => {
                        const payload = {
                          ...field,
                          rowIndex: rowIndex,
                          cell: cell,
                        };
                        handleSignAll(e, payload);
                      }}
                    />
                  ) : (
                    <img
                      src={signature}
                      style={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        const payload = {
                          ...field,
                          rowIndex: rowIndex,
                          cell: cell,
                        };
                        handleSignAll(e, payload);
                      }}
                    />
                  )}
                </OverlayTrigger>
              </div>
            )}
          </div>
        );
      });
    };

    return (
      <div
        style={{
          width: array.length > 1 ? "500px" : "auto",
          padding: "1px 0px",
        }}
      >
        {renderItems()}
      </div>
    );
  };

  return !field?.file ? (
    <div></div>
  ) : (
    <>
      <Form.Group
        controlId={`formFile-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          flexGrow: "1",
        }}
      >
        <Col
          style={{
            padding: "0px",
            flexGrow: "2",
          }}
        >
          {renderFileList()}
        </Col>
      </Form.Group>
      <SignPdfModal
        showSignModal={showSignModal}
        handleCloseSignModal={handleCloseSignModal}
        testSignPdf={testSignPdf}
        file={base64}
        isLoadingIflowDocument={isLoadingIflowDocumentTableFileComponent}
        styleSheet={styleSheet}
        username={user?.username}
        field={field}
        selectedToSign={selectedToSignTableFileComponent}
      />
    </>
  );
};
export default withNamespaces()(CreateTableFile);
