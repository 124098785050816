import React, { useEffect, useState } from "react";
import {
  Card,
  Jumbotron,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "~/assets/css/icons.css";
import "../AutomatedDataCapture.css";
import { withNamespaces } from "react-i18next";
import {
  toLocaleDateString,
  buildDocNumberColumn,
  buildVendorNameColum,
  buildTotalAmoutColumn,
} from "~/utils";

const MobileCardDataCapture = ({
  row,
  cell,
  rowIndex,
  formatExtraData,
  t,
  buildStatusColumn,
  buildApprovedColumn,
  buildActionsColumn,
  buildDocTypeColumn,
  handleOpenMetadataModal,
}) => {
  return (
    <>
      <Card
        // bsPrefix="card-mini"
        key={rowIndex}
        style={{
          margin: "0 0 15px 0",
          textAlign: "center",
          borderRadius: "25px",
        }}
      >
        <Card.Body>
          <div className="card_container">
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.status")}:</h6>
              </div>
              <Card.Text
                // onClick={handleAction()}
                style={{ backgroundColor: "none" }}
              >
                {row && row?.statusV1 ? buildStatusColumn(cell, row) : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.paymentDate")}:</h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.issue_date
                  ? new Date(row.metadata?.issue_date)
                      .toLocaleString()
                      .slice(0, 10)
                      .replace(/,/g, "")
                  : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.payrollNumber")}:</h6>
              </div>
              <Card.Text>
                {" "}
                {row && row?.metadata?.document_identifier
                  ? buildDocNumberColumn(cell, row)
                  : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.suplier")}: </h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.supplier_name
                  ? buildVendorNameColum(cell, row)
                  : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.totalPayed")}: </h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.amount_total
                  ? buildTotalAmoutColumn(cell, row)
                  : null}
              </Card.Text>
            </div>

            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.filter.aproved")}:</h6>
              </div>
              <Card.Text>
                {row && row?.approved
                  ? buildApprovedColumn(cell, row)
                  : buildApprovedColumn(cell, row)}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.paymentDateSubmitted")}:</h6>
              </div>
              <div>
                <Card.Text>
                  {row && row?.paymentDate
                    ? buildDocTypeColumn(cell, row)
                    : buildDocTypeColumn(cell, row)}
                </Card.Text>
              </div>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.actions")}:</h6>
              </div>
              <div>
                {
                  <i
                    className="icon-detail table-action-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleOpenMetadataModal(row, false);
                    }}
                  />
                }
              </div>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.sepa")}: </h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.sepa ? row.metadata.sepa : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.ccusto")}: </h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.ccusto ? row.metadata.ccusto : null}
              </Card.Text>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default withNamespaces()(MobileCardDataCapture);
