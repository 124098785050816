import React from 'react';
import { Card } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Spinner from "~/components/Spinner";


const ApplicationCard = props => {

  const {
    name,
    showIFlow = false,
    showMW = false,
    status,
    loading,
    cardFooter
  } = props;

  return (
    <Card style={{ width: '18rem', padding:"2rem", borderRadius:"20px"}}>
      <Card.Body >
        <Card.Title>{name}</Card.Title>
        {
          (!loading.iflow && showIFlow) &&
          <>
            <Card.Subtitle className="mb-2 text-muted">Status iFlow</Card.Subtitle>

            <Card.Text>
              <IconContext.Provider value={status?.iflow ? { color: "green" } : { color: "red" }}>
                <>
                  {status?.iflow ? <FaCheckCircle /> : <FaTimesCircle />}
                  <small style={{ marginLeft: 5 }}>
                    {status?.iflow ? 'Ready' : 'Down'}
                  </small>
                </>
              </IconContext.Provider>
              <Spinner spinning={loading.iflow} size="sm" />
            </Card.Text>
          </>
        }
        {
          (!loading.mw && showMW) &&
          <div style={{ marginTop: 30 }}>
            <Card.Subtitle className="mb-2 text-muted">Status MW</Card.Subtitle>
            <Card.Text>
              <IconContext.Provider value={status?.mw ? { color: "green" } : { color: "red" }}>
                <>
                  {status?.mw ? <FaCheckCircle /> : <FaTimesCircle />}
                  <small style={{ marginLeft: 5 }}>
                    {status?.mw ? 'Ready' : 'Down'}
                  </small>
                </>
              </IconContext.Provider>
              <Spinner spinning={loading.mw} size="sm" />
            </Card.Text>
          </div>
        }
        <div style={{ marginTop: 20 }}>
          {cardFooter}
        </div>
      </Card.Body>
    </Card>
  )
}

export default ApplicationCard;
